
import {graphql} from 'react-apollo';
import * as compose from 'lodash.flowright';
import SignUp from './SignUp';
import gql from 'graphql-tag';
const SIGNUP_USER_MUTATION = gql`
  mutation register(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $city: ID
    $userAgreement: Boolean!
    $marketingAgreement: Boolean!
    $confidentialityAgreement: Boolean!
   ) {
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      city: $city
      phoneNumber: $phoneNumber
      userAgreement: $userAgreement
      marketingAgreement: $marketingAgreement
      confidentialityAgreement: $confidentialityAgreement
    )
    {
      token
      user{
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`;

const JoinWithMutation = compose(
graphql(SIGNUP_USER_MUTATION, {
    name: 'register',
  }),
  )(SignUp);
export default JoinWithMutation;