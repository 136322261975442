import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    multiSelect: {
        marginBottom:15,
        '& div': {
          background: '#fff!important',
          paddingTop:'0!important',
          '& input': {
              paddingTop:'8px!important'
          }
        },
      },
}));

export default function FormikNewDropdownInvestType({ textFieldProps, ...props }) {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Autocomplete
            style={{marginTop:10}}
            {...props}
            {...field}
            noOptionsText={'Sonuç Bulunamadı..'}
            className={classes.multiSelect}
            onChange={ (_, value) => setFieldValue(name, value) }
            onBlur={ () => setTouched({ [name]: true }) }
            hiddenLabel="true"
            renderInput={(props ) => <TextField {...props } placeholder={t('Yatırımcı Türü')}  {...textFieldProps} variant="outlined" />}
            >
            </Autocomplete>
  
        {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
            </Typography>
        ) : null}
        </>
    );
  }