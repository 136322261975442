import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from '../../static/img/default-avatar.png';
import FormikTextField from "../../Components/FormikTextField";
import linkedin from '../../static/icon/new-linkedin.svg';
import { FieldArray, Field } from 'formik';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';
import FormikNewDropdown from '../FormikDropdown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useTranslation } from 'react-i18next';
import ImageCrop from "../../Components/ImageCrop";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 32,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  fontLg: {
    fontSize: 14,
  },
  objectCard: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 16,
    padding:16
  },
  AddButton: {
    padding: '12.5px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    minWidth: 175,
    fontSize: 16,
    marginRight: 10,
    marginTop: 15,
    fontWeight: 'normal',
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80px',
    width: '80px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 4,
    border: 'solid 1px #dddddd6b',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '500',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  cardMargin:{
    [theme.breakpoints.up('sm')]: {
      margin:0
    },
    [theme.breakpoints.down('xs')]: {
      padding:24
    },
  }
}));
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        flexWrap: 'wrap',
      };
      
      const thumb = {
        display: 'inline-flex',
        borderRadius: 4,
        border: '1px solid #eaeaea',
        width: 80,
        height: 80,
        padding: 4,
        boxSizing: 'border-box'
      };
      
      const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
      };
      
      const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius:7
      };
      function validateLinkedinUrl(value) {
        if(value !== '' && value !== null){
            let error;
            var pattern = new RegExp( /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ ); // query string
            error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
            return error;
        }
        return false;
    }
    function FounderPart(props){
      const { index, remove } = props;
      const classes = useStyles();
      const [files, setFiles] = React.useState([]);
      const [open, setOpen] = React.useState(true);
      const position = [ 'Kurucu','Yönetici','Çalışan' ]
      const { t } = useTranslation();
      const [openPhoto, setOpenPhoto] = React.useState(false);

      React.useEffect(() => {
      if(props.flag){
        setOpen(false)
        props.setNewFounder(false)
      }
      },[props.flag])

      return (
        <div>
          <Grid xs={12} container spacing={2}>
            <Grid container justify="space-between">
              <Grid item alignItems="center" style={{ marginBottom: 20, display: 'flex' }}>
                {!props.founder.title ||
                !props.founder.nameSurname ||
                !props.founder.position ||
                !props.founder.linkedin ? (
                  <Typography variant="h5" style={{ marginLeft: 5, color: 'rgb(255 186 0)' }}>
                    {props.detail.founders && props.detail.founders[index]
                      ? props.detail.founders[index].nameSurname
                      : 'Profil Bilgileri'}
                  </Typography>
                ) : (
                  <Typography variant="h5" style={{ marginLeft: 5 }}>
                    {props.detail.founders && props.detail.founders[index]
                      ? props.detail.founders[index].nameSurname
                      : 'Profil Bilgileri'}
                  </Typography>
                )}
                {open ? (
                  <ExpandLessIcon
                    onClick={() => setOpen(!open)}
                    style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
                  />
                ) : (
                  <ExpandMoreIcon
                    onClick={() => setOpen(!open)}
                    style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
                  />
                )}
              </Grid>
              <DeleteOutlineIcon style={{ width: 25, cursor: 'pointer' }} onClick={() => remove(index)} />
            </Grid>
            {open && (
              <>
                <Grid item>
                  <Field>
                    {({ field, form, meta }) => (
                      <Dropzone
                        maxSize={20971520}
                        accept={'image/*'}
                        onDrop={(acceptedFiles) =>
                          setFiles(
                            acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })),
                            form.setFieldValue(`founders.${index}.logo`, acceptedFiles),
                            setOpenPhoto(true),
                          )
                        }
                      >
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <section style={{ textAlign: 'center' }}>
                            {typeof form.values.founders[index].logo !== 'string' ? (
                              <div {...getRootProps({ className: classes.baseStyle })}>
                                <input {...getInputProps()} />
                                {!form.values.founders[index].logo ? (
                                  <Grid item container direction="column" justify="center" alignItems="center">
                                    {form.values.founders[index].nameSurname ? (
                                      <Grid container alignItems="center" justify="center">
                                        <Typography variant="h4">
                                          {form.values.founders[index].nameSurname.charAt(0)}
                                        </Typography>
                                        <AddIcon style={{ color: '#777777', fontSize: 20 }} />
                                      </Grid>
                                    ) : (
                                      <>
                                        <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                        <Typography variant="body2" style={{ display: 'flex', color: '#777777' }}>
                                          {t('Fotoğraf')}
                                        </Typography>
                                      </>
                                    )}
                                  </Grid>
                                ) : (
                                  <aside style={thumbsContainer}>
                                    {acceptedFiles[0] && (
                                      <div
                                        id={form.values.founders[index].logo[0].name}
                                        style={thumb}
                                        key={form.values.founders[index].logo[0].name}
                                      >
                                        <div style={thumbInner}>
                                          <img
                                            src={form.values.founders[index].logo[0].preview}
                                            style={img}
                                            alt="file"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {form.values.founders[index].logo[0] && !acceptedFiles[0] && (
                                      <div
                                        id={form.values.founders[index].logo[0].name}
                                        style={thumb}
                                        key={form.values.founders[index].logo[0].name}
                                      >
                                        <div style={thumbInner}>
                                          <img
                                            src={form.values.founders[index].logo[0].preview}
                                            style={img}
                                            alt="file"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </aside>
                                )}
                              </div>
                            ) : (
                              <div {...getRootProps({ className: classes.baseStyle })}>
                                <img
                                  src={form.values.founders[index].logo}
                                  alt="avatar"
                                  style={{ width: '100%', height: '100%' }}
                                />
                              </div>
                            )}
                            {form.values.founders[index].logo && (
                              <Button
                                variant="containedSecondary"
                                style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5, width: 80 }}
                                onClick={() => form.setFieldValue(`founders.${index}.logo`, null)}
                              >
                                {t('Sil')}
                              </Button>
                            )}
                          </section>
                        )}
                      </Dropzone>
                    )}
                  </Field>
                </Grid>
                <Field>
                  {({ field, form, meta }) => (
                    <ImageCrop
                      setFieldValue={form.setFieldValue}
                      files={files}
                      index={index}
                      founder={form.values.founders[index]}
                      setOpenPhoto={setOpenPhoto}
                      openPhoto={openPhoto}
                    />
                  )}
                </Field>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" style={{ width: 'fit-content' }}>
                    {t('Ad - Soyad')}
                  </Typography>
                  <FormikTextField
                    name={`founders.${index}.nameSurname`}
                    placeholder={t('Adınızı ve soyadınızı yazınız')}
                    type="text"
                  />
                  {(props.founder.nameSurname === '' || props.founder.nameSurname === null) && (
                    <Typography
                      style={{
                        color: '#FF0000',
                        fontSize: '12px',
                        marginBottom: 4,
                        marginTop: -18,
                        textAlign: 'left',
                      }}
                    >
                      {t('Lütfen bu alanı doldurunuz.')}
                    </Typography>
                  )}
                </Grid>
                <Grid container spacing={2} style={{ margin: 0 }}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ width: 'fit-content' }}>
                      {t('Pozisyon')}
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name={`founders.${index}.position`}
                      options={position}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ width: 'fit-content' }}>
                      {t('Ünvan')}
                    </Typography>
                    <FormikTextField name={`founders.${index}.title`} placeholder={t('Ünvanınızı yazın')} type="text" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ width: 'fit-content' }}>
                      LinkedIn
                    </Typography>
                    <FormikTextField
                      name={`founders.${index}.linkedin`}
                      validate={validateLinkedinUrl}
                      placeholder="https://linkedin.com/in/"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <hr size="1" style={{ border: '0.2px solid #f5f5f5', marginBottom: 25 }} />
          </Grid>
        </div>
      );
    }    
export default function DetailTab(props) {
        const classes = useStyles();
        const [files, setFiles] = React.useState([]);
        const [newFounder, setNewFounder] = React.useState(false);
        const { t } = useTranslation();
        return (
          <>
            {!props.edit ? (
              <Paper className={classes.paper}>
                <Grid item>
                  {props.detail.founders && (
                    <Grid container spacing={3} className={classes.cardMargin}>
                      {props.detail.founders.map((founder, index) => (
                        <>
                          {props.data ? (
                            <>
                              {props.data.newDetail && props.data.originalDetail && (
                                <>
                                  {JSON.stringify(props.data.newDetail.founders[index]) ===
                                  JSON.stringify(props.data.originalDetail.founders[index]) ? (
                                    <Grid item xs={12} sm={6} lg={3}>
                                      <Grid className={classes.objectCard}>
                                        {founder.logo ? (
                                          <img
                                            src={founder.logo}
                                            alt="avatar"
                                            style={{
                                              maxWidth: 64,
                                              height: 64,
                                              borderRadius: '100%',
                                              marginRight: 16,
                                              objectFit: 'cover',
                                            }}
                                          />
                                        ) : (
                                          <Grid
                                            style={{
                                              maxWidth: 64,
                                              height: 64,
                                              borderRadius: '100%',
                                              marginRight: 16,
                                              display:'flex',
                                              alignItems:'center',
                                              justifyContent:'center',
                                              background:'#003CA5',
                                              width:'100%'
                                            }}
                                          >
                                            <Typography variant="h3" style={{color:'#fff'}}>
                                            {founder.nameSurname && founder.nameSurname.charAt(0)}
                                            </Typography>
                                          </Grid>
                                        )}
                                        <Grid item>
                                          <Typography variant="h5" align="left">
                                            {founder.nameSurname}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            align="left"
                                            style={{
                                              display: 'flex',
                                              alingItems: 'center',
                                              fontSize: 12,
                                              color: '#718096',
                                            }}
                                          >
                                            {founder.title}
                                          </Typography>
                                          <a href={founder.linkedin} target="_blank" style={{ padding: 0 }}>
                                            <Typography
                                              variant="body2"
                                              align="left"
                                              style={{ display: 'flex', alingItems: 'center' }}
                                            >
                                              <img
                                                src={linkedin}
                                                style={{ width: 12, height: 12, marginLeft: 2, marginTop: 3 }}
                                                alt="linkedin"
                                              ></img>{' '}
                                            </Typography>
                                          </a>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid item xs={12} sm={6} lg={3}>
                                      <Grid className={classes.objectCard}>
                                        {founder.logo ? (
                                          <img
                                            src={founder.logo}
                                            alt="avatar"
                                            style={{
                                              maxWidth: 50,
                                              height: 50,
                                              borderRadius: '25%',
                                              marginRight: 20,
                                              objectFit: 'cover',
                                            }}
                                          />
                                        ) : (
                                          <Grid
                                            style={{
                                              maxWidth: 64,
                                              height: 64,
                                              borderRadius: '100%',
                                              marginRight: 16,
                                              display:'flex',
                                              alignItems:'center',
                                              justifyContent:'center',
                                              background:'#003CA5',
                                              width:'100%'
                                            }}
                                          >
                                            <Typography variant="h3" style={{color:'#fff'}}>
                                            {founder.nameSurname && founder.nameSurname.charAt(0)}
                                            </Typography>
                                          </Grid>
                                        )}
                                        <Grid item>
                                          <Typography variant="h5" align="left">
                                            {founder.nameSurname}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            align="left"
                                            style={{
                                              display: 'flex',
                                              alingItems: 'center',
                                              fontSize: 12,
                                              color: '#718096',
                                            }}
                                          >
                                            {founder.title}
                                          </Typography>
                                          <a href={founder.linkedin} target="_blank" style={{ padding: 0 }}>
                                            <Typography
                                              variant="body2"
                                              align="left"
                                              style={{ display: 'flex', alingItems: 'center' }}
                                            >
                                              <img
                                                src={linkedin}
                                                style={{ width: 12, height: 12, marginLeft: 2, marginTop: 3 }}
                                                alt="linkedin"
                                              ></img>{' '}
                                            </Typography>
                                          </a>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <Grid item xs={12} sm={6} lg={3}>
                              <Grid className={classes.objectCard}>
                                {founder.logo ? (
                                  <img
                                    src={founder.logo}
                                    alt="avatar"
                                    style={{
                                      maxWidth: 64,
                                      height: 64,
                                      borderRadius: '100%',
                                      marginRight: 16,
                                      objectFit: 'cover',
                                    }}
                                  />
                                ) : (
                                  <Grid
                                  style={{
                                    maxWidth: 64,
                                    height: 64,
                                    borderRadius: '100%',
                                    marginRight: 16,
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    background:'#003CA5',
                                    width:'100%'
                                  }}
                                >
                                  <Typography variant="h3" style={{color:'#fff'}}>
                                  {founder.nameSurname && founder.nameSurname.charAt(0)}
                                  </Typography>
                                </Grid>
                                )}
                                <Grid item>
                                  <Typography variant="h5" align="left">
                                    {founder.nameSurname}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    align="left"
                                    style={{ display: 'flex', alingItems: 'center', fontSize: 12, color: '#718096' }}
                                  >
                                    {founder.title}
                                  </Typography>
                                  <a href={founder.linkedin} target="_blank" style={{ padding: 0 }}>
                                    <Typography
                                      variant="body2"
                                      align="left"
                                      style={{ display: 'flex', alingItems: 'center' }}
                                    >
                                      <img
                                        src={linkedin}
                                        style={{ width: 12, height: 12, marginLeft: 2, marginTop: 3 }}
                                        alt="linkedin"
                                      ></img>{' '}
                                    </Typography>
                                  </a>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Paper>
            ) : (
              <>
                <Paper className={classes.paper}>
                  <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
                    {t('Takım')}
                  </Typography>
                  <Grid xs={12} item>
                    <FieldArray
                      name="founders"
                      render={({ insert, remove, push, unshift, form }) => (
                        <>
                          <div>
                            {form.values.founders && form.values.founders.length > 0 && (
                              <Grid item xs={12} style={{ paddingTop: 0 }}>
                                {form.values.founders.map((founder, index) => (
                                  <>
                                    <FounderPart
                                      detail={props.detail}
                                      founder={founder}
                                      setNewFounder={setNewFounder}
                                      flag={index + 1 !== form.values.founders.length && newFounder}
                                      remove={remove}
                                      index={index}
                                    />
                                  </>
                                ))}
                              </Grid>
                            )}
                            <Grid xs={12} container justify="center" style={{ marginTop: 20 }}>
                              <Button
                                variant="outlinedPrimary"
                                className={classes.AddButton}
                                onClick={() => {
                                  push({ nameSurname: '', title: '', position: '', linkedin: '', logo: null });
                                  setNewFounder(true);
                                }}
                              >
                                {t('Yeni Kişi Ekle')}
                              </Button>
                            </Grid>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Paper>
              </>
            )}
          </>
        );
}