import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Wizard from "./Wizard.js";
import FormikTextField from "../../Components/FormikTextField";
import {useField, Field} from "formik";
import FormikNewDropdown from "../FormikDropdown";
import FormikMultiSelect from "../../Components/FormikMultiSelect";
import FormikPercentageField from "../../Components/FormikPercentageField";
import FormikSelect from "../FormikSelect";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  sendButton: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    background: "#003CA5",
    cursor: "pointer"
  },
  generalPadding: {
    [theme.breakpoints.up("md")]: {
      padding: 75,
      paddingTop: 20
    }
  },
  formControl: {
    width: "100%",
    "& span": {
      fontSize: 18
    }
  }
}));
function FirstDialog(props) {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  const {onClose, open} = props;

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog maxWidth={"sm"} id="user-type-survey" fullWidth={true} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title" style={{marginTop: 35}}>
        <Typography variant="h3" align="center">
          {t("Sizi Hangisi Tanımlar")}
        </Typography>
      </DialogTitle>
      <Typography variant="body2" align="center" style={{padding: "10px 50px"}}>
        Startup Borsa yatırımcı ve girişimcinin bir araya gelmesini sağlar. Kitlesel fonlama platformu değildir. Uygun
        yatırımcılar ve yatırım kuruluşları ile teknoloji girişimlerinin bir araya gelmesi için çalışır.
      </Typography>
      <List style={{padding: 50, paddingTop: 20}}>
        <ListItem
          style={{marginBottom: 25, cursor: "pointer"}}
          onClick={() => handleListItemClick("Individual Investor")}
        >
          <Grid item xs={9}>
            <Typography variant="h5" gutterBottom={1}>
              {t("Bireysel Yatırımcı")}
            </Typography>
            <Typography variant="body2" style={{color: "#777777"}}>
              {t(
                "Öne çıkan girişimleri keşfetmek, portföy şirketlerime yenilerini eklemek ve yatırım yapmak istiyorum."
              )}
            </Typography>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" xs={3}>
            <Grid container justify="center" alignItems="center" className={classes.sendButton}>
              <KeyboardArrowRightIcon style={{color: "#ffffff"}} />
            </Grid>
          </Grid>
        </ListItem>
        <Grid style={{paddingLeft: 16}} xs={9}>
          <hr size="1" style={{border: "0.2px solid #f5f5f5", marginBottom: 25}} />
        </Grid>
        <ListItem
          style={{marginBottom: 25, cursor: "pointer"}}
          onClick={() => handleListItemClick("Corporate Investor")}
        >
          <Grid item xs={9}>
            <Typography variant="h5" gutterBottom={1}>
              {t("Kurumsal Yatırımcı")}
            </Typography>
            <Typography variant="body2" style={{color: "#777777"}}>
              {t(
                "Bir şirketi temsilen girişimleri keşfetmek, portföy şirketlerime yenilerini eklemek ve yatırım yapmak istiyorum."
              )}
            </Typography>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" xs={3}>
            <Grid container justify="center" alignItems="center" className={classes.sendButton}>
              <KeyboardArrowRightIcon style={{color: "#ffffff"}} />
            </Grid>
          </Grid>
        </ListItem>
        <Grid style={{paddingLeft: 16}} xs={9}>
          <hr size="1" style={{border: "0.2px solid #f5f5f5", marginBottom: 25}} />
        </Grid>
        <ListItem style={{marginBottom: 25, cursor: "pointer"}} onClick={() => handleListItemClick("Shareholder")}>
          <Grid item xs={9}>
            <Typography variant="h5" gutterBottom={1}>
              {t("Girişimci")}
            </Typography>
            <Typography variant="body2" style={{color: "#777777"}}>
              {t(
                "Girişimimi platformda listelemek, güvenilir ve şeffaf yatırımcı ilişkileri kurmak, finansal kaynaklara ve yatırıma erişmek istiyorum"
              )}
            </Typography>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" xs={3}>
            <Grid container justify="center" alignItems="center" className={classes.sendButton}>
              <KeyboardArrowRightIcon style={{color: "#ffffff"}} />
            </Grid>
          </Grid>
        </ListItem>
        <Grid style={{paddingLeft: 16}} xs={9}>
          <hr size="1" style={{border: "0.2px solid #f5f5f5", marginBottom: 25}} />
        </Grid>
        <ListItem style={{marginBottom: 25, cursor: "pointer"}} onClick={() => handleListItemClick("Employee")}>
          <Grid item xs={9}>
            <Typography variant="h5" gutterBottom={1}>
              {t("Çalışan")}
            </Typography>
            <Typography variant="body2" style={{color: "#777777"}}>
              {t(
                "Herhangi bir girişimde ekip üyesi olarak çalışıyor ve ekosistemi ile ilgili güncel bilgilere ulaşmak istiyorum."
              )}
            </Typography>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" xs={3}>
            <Grid container justify="center" alignItems="center" className={classes.sendButton}>
              <KeyboardArrowRightIcon style={{color: "#ffffff"}} />
            </Grid>
          </Grid>
        </ListItem>
        <Grid style={{paddingLeft: 16}} xs={9}>
          <hr size="1" style={{border: "0.2px solid #f5f5f5", marginBottom: 25}} />
        </Grid>
        <ListItem style={{cursor: "pointer"}} onClick={() => handleListItemClick("Other")}>
          <Grid item xs={9} id="other">
            <Typography variant="h5" gutterBottom={1}>
              {t("Diğer")}
            </Typography>
            <Typography variant="body2" style={{color: "#777777"}}>
              {t(
                "Girişimci, Yatırımcı, Çalışan veya Kurumsal şirket temsilcisi değilim. Ekosistemdeki güncel bilgilere ulaşmak istiyorum."
              )}
            </Typography>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" xs={3}>
            <Grid
              onClick={() => handleListItemClick("Other")}
              container
              justify="center"
              alignItems="center"
              className={classes.sendButton}
            >
              <KeyboardArrowRightIcon style={{color: "#ffffff"}} />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Dialog>
  );
}

FirstDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

function SecondDialog(props) {
  const classes = useStyles();
  const {open, onClose} = props;
  const industry = [
    "AI / Yapay Zeka",
    "Akıllı Şehirler",
    "AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik",
    "Atık Yönetimi",
    "Bilişim Teknolojileri",
    "Biyoteknoloji",
    "Blokzinciri",
    "Data Analiz & İş zekası",
    "Donanım",
    "e-ticaret",
    "Eğitim & Eğitim Teknolojileri",
    "Eğlence & Müzik",
    "Etkinlik",
    "Ev hizmetleri ve teknolojileri ",
    "Finans & Bankacılık",
    "Finans Teknolojileri",
    "Gayrimenkul",
    "Geri Dönüşüm",
    "Giyilebilir Teknolojiler",
    "Hukuk Teknolojileri",
    "İnsan Kaynakları & İşe Alım",
    "Kriptopara",
    "Kurumsal Hizmetler",
    "Mikro Mobilite",
    "Moda & Güzellik",
    "Medya",
    "Müşteri İlişkileri Yönetimi",
    "Nesnelerin İnterneti",
    "Oyun",
    "Perakende",
    "Regülasyon Teknolojileri",
    "Reklam & Pazarlama",
    "Robotik Teknolojileri",
    "SaaS",
    "Sağlık Hizmetleri & Sağlık Teknolojileri",
    "Seyahat & Turizm",
    "Siber Güvenlik",
    "Sigorta & Sigorta Teknolojileri",
    "Sosyal Girişimcilik",
    "Sosyal Medya",
    "Spor & Fitness",
    "Tarım & Tarım Teknolojileri",
    "Teknoloji",
    "Telekomünikasyon",
    "Temiz Teknoloji",
    "Teslimat & Lojistik",
    "Topluluk Yönetimi",
    "Tüketici Hizmetleri",
    "Ulaşım",
    "Video & Animasyon",
    "Yazılım",
    "Yenilenebilir Enerji",
    "Yiyecek & İçecek"
  ];
  const role = ["Kurucu", "Yönetici", "Ekip Üyesi"];
  const employeeRole = [
    "Web Geliştirici ",
    "UX / UI Tasarımcısı",
    "Ürün Müdürü",
    "Data Analist",
    "Pazarlama Uzmanı",
    "Satış Sorumlusu",
    "Finans Direktörü",
    "Müşteri memnuniyeti sorumlusu"
  ];
  const hear = ["Arkadaş & Aile", "Sosyal Medya", "Network", "Arama Motoru", "Etkinlik & Konferans", "Diğer"];
  const purpose = [
    "Yatırımcı ilişkileri ",
    "Listeleme",
    "Ekosistemdeki gelişmelerden haberdar olma ",
    "Network sağlama",
    "Yatırım Arama",
    "Diğer"
  ];
  const individualAndCorporatePurpose = [
    "Yatırımcı ilişkileri ",
    "Listeleme",
    "Ekosistemdeki gelişmelerden haberdar olma ",
    "Network sağlama",
    "Girişimlere Ulaşma",
    "Diğer"
  ];
  const shareHolderPurpose = [
    "Yatırımcı ilişkileri ",
    "Listeleme",
    "Ekosistemdeki gelişmelerden haberdar olma ",
    "Network sağlama",
    "Yatırımcı Arama",
    "Diğer"
  ];
  const yesNo = ["Evet", "Hayır"];
  const problem = ["Evet", "Bilmiyorum", "Hayır"];
  const angel = ["0 - $100K", "$100K - $250K", "$250K - $500K ", "$500K+"];
  const plannedInvestment = ["1-3", "3-5", "5-10", "10+"];
  const risk = ["Risk Sever", "Riske Duyarsız", "Riskten Kaçınan"];
  const companyCount = ["1-3", "3-5", "5-7", "7-10", "10-15", "15+"];
  const {t, i18n} = useTranslation();
  const companyDefinition = [
    "Melek Yatırım Ağı",
    "VC",
    "Mikro-VC",
    "Yatırım Şirketi",
    "Aile Ofisi",
    "Portföy Yönetim Şirketi"
  ];
  const investmentAmount = ["0 - $25K", "$25K - $50K", "$50K - $100K", "$100K - $150K ", "$150K - $200K ", "$200K+"];

  return (
    <Dialog maxWidth={"sm"} fullWidth={true} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title" style={{marginTop: 35}}>
        {props.type === "Shareholder" && (
          <Typography variant="h3" align="center">
            {t("Girişimci")}
          </Typography>
        )}
        {props.type === "Employee" && (
          <Typography variant="h3" align="center">
            {t("Çalışan")}
          </Typography>
        )}
        {props.type === "Individual Investor" && (
          <Typography variant="h3" align="center">
            {t("Bireysel Yatırımcı")}
          </Typography>
        )}
        {props.type === "Corporate Investor" && (
          <Typography variant="h3" align="center">
            {t("Kurumsal Yatırımcı")}
          </Typography>
        )}
      </DialogTitle>
      <Grid className={classes.generalPadding}>
        {(props.type === "Shareholder" || props.type === "Employee") && (
          <Wizard
            initialValues={{
              type: props.type,
              companyName: "",
              companyWebsite: "",
              companySector: [],
              companyRole: "",
              investorShareRate: "",
              hasShare: "",
              preferSellOnSB: "",
              legalObstaclesOnSell: "",
              legalObstaclesOnSelldesc: "",
              hearSBFrom: "",
              purposeEnteringSB: ""
            }}
            firstPopup={props.firstPopup}
            setSecondPopupOpen={props.setSecondPopupOpen}
            setFirstPopupOpen={props.setFirstPopupOpen}
            history={props.history}
            onClose={onClose}
          >
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Şirket Adı")}</Typography>
                  <FormikTextField name="companyName" placeholder={t("Şirket Adı")}></FormikTextField>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Şirket Websitesi")}</Typography>
                  <FormikTextField name="companyWebsite" placeholder={t("Şirket Websitesi")}></FormikTextField>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Şirket Sektörü")}</Typography>
                  <Field>
                    {({field, form, meta}) => (
                      <>
                        <Field
                          component={FormikMultiSelect}
                          textFieldProps={{fullWidth: true, variant: "outlined"}}
                          name="companySector"
                          selectedLength={form.values.companySector}
                          options={industry}
                        ></Field>
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Şirketteki Rolunuz Nedir?")}</Typography>
                  {props.type === "Shareholder" && (
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{fullWidth: true, variant: "outlined"}}
                      name="companyRole"
                      options={role}
                    ></Field>
                  )}
                  {props.type === "Employee" && (
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{fullWidth: true, variant: "outlined"}}
                      name="companyRole"
                      options={employeeRole}
                    ></Field>
                  )}
                </Grid>
                {props.type === "Shareholder" && (
                  <Field>
                    {({field, form, meta}) => (
                      <>
                        <Grid item xs={11} style={{marginBottom: 10}}>
                          <Typography variant="h6">{t("Şirket hisselerinin ne kadarına sahipsiniz?")}</Typography>
                          <FormikPercentageField
                            name={`investorShareRate`}
                            maxLen={4}
                            placeholder="Eg: 50%"
                          ></FormikPercentageField>
                        </Grid>
                      </>
                    )}
                  </Field>
                )}
              </Grid>
            </Wizard.Page>
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                {props.type === "Employee" && (
                  <>
                    <Grid item xs={11} style={{marginBottom: 10}}>
                      <Typography variant="h6">{t("Şirkette hisse sahibi misiniz?")}</Typography>
                      <Field>
                        {({form}) => (
                          <FormikSelect
                            handleChange={
                              form.values.hasShare === "Hayır" ? (form.values.investorShareRate = "") : null
                            }
                            name="hasShare"
                            options={yesNo}
                          ></FormikSelect>
                        )}
                      </Field>
                    </Grid>
                    <Field>
                      {({field, form, meta}) =>
                        form.values.hasShare === "Evet" && (
                          <>
                            <Grid item xs={11} style={{marginBottom: 10}}>
                              <Typography variant="h6">{t("Şirket hisselerinin ne kadarına sahipsiniz?")}</Typography>
                              <FormikPercentageField
                                name={`investorShareRate`}
                                maxLen={4}
                                placeholder="Eg: 50%"
                              ></FormikPercentageField>
                            </Grid>
                            <Grid item xs={11} style={{marginBottom: 10}}>
                              <Typography variant="h6">
                                {t(
                                  "Startup Borsa’nın yatırım süreçlerinde sunduğu hizmetlerinden faydalanmak ister misiniz?"
                                )}
                              </Typography>
                              <Field>
                                {({form}) => (
                                  <FormikSelect
                                    handleChange={
                                      form.values.preferSellOnSB === "Hayır"
                                        ? ((form.values.legalObstaclesOnSell = ""),
                                          (form.values.legalObstaclesOnSelldesc = ""))
                                        : null
                                    }
                                    name="preferSellOnSB"
                                    options={yesNo}
                                  ></FormikSelect>
                                )}
                              </Field>
                            </Grid>
                          </>
                        )
                      }
                    </Field>
                    <Field>
                      {({form}) =>
                        form.values.preferSellOnSB === "Evet" && (
                          <Grid item xs={11} style={{marginBottom: 10}}>
                            <Typography variant="h6">
                              {t("Hisselerinizi satmayı düşünürseniz hukuksal engelle karşılaşır mısınız?")}
                            </Typography>
                            <Field>
                              {({form}) => (
                                <FormikSelect
                                  handleChange={
                                    form.values.legalObstaclesOnSell === "Hayır" ||
                                    form.values.legalObstaclesOnSell === "Bilmiyorum"
                                      ? (form.values.legalObstaclesOnSelldesc = "")
                                      : null
                                  }
                                  name="legalObstaclesOnSell"
                                  options={problem}
                                ></FormikSelect>
                              )}
                            </Field>
                          </Grid>
                        )
                      }
                    </Field>
                    <Field>
                      {({form}) =>
                        form.values.legalObstaclesOnSell === "Evet" && (
                          <Grid item xs={11} style={{marginBottom: 10}}>
                            <Typography variant="h6">
                              {t("Karşılaşılabilecek hukuksal engel nedir? Lütfen belirtin.")}
                            </Typography>
                            <FormikTextField
                              name="legalObstaclesOnSelldesc"
                              placeholder="Belirtiniz.."
                            ></FormikTextField>
                          </Grid>
                        )
                      }
                    </Field>
                  </>
                )}
                {props.type === "Shareholder" && (
                  <>
                    <Grid item xs={11} style={{marginBottom: 10}}>
                      <Typography variant="h6">
                        {t("Startup Borsa’nın yatırım süreçlerinde sunduğu hizmetlerinden faydalanmak ister misiniz?")}
                      </Typography>
                      <Field>
                        {({form}) => (
                          <FormikSelect
                            handleChange={
                              form.values.preferSellOnSB === "Hayır"
                                ? ((form.values.legalObstaclesOnSell = ""), (form.values.legalObstaclesOnSelldesc = ""))
                                : null
                            }
                            name="preferSellOnSB"
                            options={yesNo}
                          ></FormikSelect>
                        )}
                      </Field>
                    </Grid>
                    <Field>
                      {({form}) =>
                        form.values.preferSellOnSB === "Evet" && (
                          <Grid item xs={11} style={{marginBottom: 10}}>
                            <Typography variant="h6">
                              {t("Hisselerinizi satmayı düşünürseniz hukuksal engelle karşılaşır mısınız?")}
                            </Typography>
                            <Field>
                              {({form}) => (
                                <FormikSelect
                                  handleChange={
                                    form.values.legalObstaclesOnSell === "Hayır" ||
                                    form.values.legalObstaclesOnSell === "Bilmiyorum"
                                      ? (form.values.legalObstaclesOnSelldesc = "")
                                      : null
                                  }
                                  name="legalObstaclesOnSell"
                                  options={problem}
                                ></FormikSelect>
                              )}
                            </Field>
                          </Grid>
                        )
                      }
                    </Field>
                    <Field>
                      {({form}) =>
                        form.values.legalObstaclesOnSell === "Evet" && (
                          <Grid item xs={11} style={{marginBottom: 10}}>
                            <Typography variant="h6">
                              {t("Karşılaşılabilecek hukuksal engel nedir? Lütfen belirtin.")}
                            </Typography>
                            <FormikTextField
                              name="legalObstaclesOnSelldesc"
                              placeholder="Belirtiniz.."
                            ></FormikTextField>
                          </Grid>
                        )
                      }
                    </Field>
                  </>
                )}
              </Grid>
            </Wizard.Page>
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Startup Borsa’yı nereden duydunuz?")}</Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="hearSBFrom"
                    options={hear}
                  ></Field>
                </Grid>
                {props.type === "Shareholder" && (
                  <Grid item xs={11} style={{marginBottom: 10}}>
                    <Typography variant="h6">{t("Startup Borsa’ya giriş amacınız nedir?")}</Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{fullWidth: true, variant: "outlined"}}
                      name="purposeEnteringSB"
                      options={shareHolderPurpose}
                    ></Field>
                  </Grid>
                )}
                {props.type === "Employee" && (
                  <Grid item xs={11} style={{marginBottom: 10}}>
                    <Typography variant="h6">{t("Startup Borsa’ya giriş amacınız nedir?")}</Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{fullWidth: true, variant: "outlined"}}
                      name="purposeEnteringSB"
                      options={purpose}
                    ></Field>
                  </Grid>
                )}
              </Grid>
            </Wizard.Page>
          </Wizard>
        )}
        {props.type === "Individual Investor" && (
          <Wizard
            initialValues={{
              type: props.type,
              hasEverInvested: "",
              investedCompanyCount: "",
              investmentAmount: "",
              plannedAmountOfAngelInvestment: "",
              legalObstaclesOnSelldesc: "",
              hearSBFrom: "",
              purposeEnteringSB: ""
            }}
            firstPopup={props.firstPopup}
            setSecondPopupOpen={props.setSecondPopupOpen}
            setFirstPopupOpen={props.setFirstPopupOpen}
            history={props.history}
            onClose={onClose}
          >
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Daha önce özel şirketlere yatırım yaptınız mı?")}</Typography>
                  <Field>
                    {({form}) => (
                      <FormikSelect
                        handleChange={
                          form.values.hasEverInvested === "Hayır"
                            ? ((form.values.investedCompanyCount = ""), (form.values.investmentAmount = ""))
                            : null
                        }
                        name="hasEverInvested"
                        options={yesNo}
                      ></FormikSelect>
                    )}
                  </Field>
                </Grid>
                <Field>
                  {({field, form, meta}) =>
                    form.values.hasEverInvested === "Evet" && (
                      <>
                        <Grid item xs={11} style={{marginBottom: 10}}>
                          <Typography variant="h6">{t("Toplamda kaç girişime yatırım yaptınız?")}</Typography>
                          <Field
                            component={FormikNewDropdown}
                            textFieldProps={{fullWidth: true, variant: "outlined"}}
                            name="investedCompanyCount"
                            options={companyCount}
                          ></Field>
                        </Grid>
                        <Grid item xs={11} style={{marginBottom: 10}}>
                          <Typography variant="h6">{t("Toplamda ne kadar yatırım yaptınız?")} </Typography>
                          <Field
                            component={FormikNewDropdown}
                            textFieldProps={{fullWidth: true, variant: "outlined"}}
                            name="investmentAmount"
                            options={investmentAmount}
                          ></Field>
                        </Grid>
                      </>
                    )
                  }
                </Field>
              </Grid>
            </Wizard.Page>
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">
                    {t("Toplam bütçenizin ne kadarını melek yatırım için ayırdınız?")}
                  </Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="plannedAmountOfAngelInvestment"
                    options={angel}
                  ></Field>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Startup Borsa’yı nereden duydunuz?")}</Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="hearSBFrom"
                    options={hear}
                  ></Field>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Startup Borsa’ya giriş amacınız nedir?")}</Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="purposeEnteringSB"
                    options={individualAndCorporatePurpose}
                  ></Field>
                </Grid>
              </Grid>
            </Wizard.Page>
          </Wizard>
        )}
        {props.type === "Corporate Investor" && (
          <Wizard
            initialValues={{
              type: props.type,
              hearSBFrom: "",
              purposeEnteringSB: "",
              corporateName: "",
              corporateDefinition: "",
              corporateRole: "",
              corporateSectors: [],
              corporateReservedToInvest: "",
              corporateRiskPreference: ""
            }}
            firstPopup={props.firstPopup}
            setSecondPopupOpen={props.setSecondPopupOpen}
            setFirstPopupOpen={props.setFirstPopupOpen}
            onClose={onClose}
            history={props.history}
          >
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Kurumunuzun Adı")}</Typography>
                  <FormikTextField name="corporateName" placeholder={t("Kurumunuzun Adı")}></FormikTextField>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Kurumunuzu hangisi en iyi tanımlar?")}</Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="corporateDefinition"
                    options={companyDefinition}
                  ></Field>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Kurumdaki rolünüz nedir?")}</Typography>
                  <FormikTextField name="corporateRole" placeholder={t("Kurumdaki rolünüz nedir?")}></FormikTextField>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Kurumunuzun yoğunlaştığı sektörler nedir?")}</Typography>
                  <Field>
                    {({field, form, meta}) => (
                      <>
                        <Field
                          component={FormikMultiSelect}
                          textFieldProps={{fullWidth: true, variant: "outlined"}}
                          name="corporateSectors"
                          selectedLength={form.values.corporateSectors}
                          options={industry}
                        ></Field>
                      </>
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">
                    {t("Kurum fonunun ne kadarını girişimlere yatırım yapmak için ayırdınız?")}
                  </Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="corporateReservedToInvest"
                    options={angel}
                  ></Field>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">
                    {t("Özel şirketlere yapacağınız yatırımlar için risk tercihiniz nedir?")}
                  </Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="corporateRiskPreference"
                    options={risk}
                  ></Field>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page>
              <Typography variant="body1" align="center" style={{color: "#777777", lineHeight: "2"}}>
                {t("Girmiş olduğunuz veriler hiçbir zaman üçüncü parti kuruluşlar/kişiler ile paylaşılmayacaktır.")}
              </Typography>
              <Grid container justify="center" xs={12} style={{marginTop: 50}}>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Startup Borsa’yı nereden duydunuz?")}</Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="hearSBFrom"
                    options={hear}
                  ></Field>
                </Grid>
                <Grid item xs={11} style={{marginBottom: 10}}>
                  <Typography variant="h6">{t("Startup Borsa’ya giriş amacınız nedir?")}</Typography>
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{fullWidth: true, variant: "outlined"}}
                    name="purposeEnteringSB"
                    options={individualAndCorporatePurpose}
                  ></Field>
                </Grid>
              </Grid>
            </Wizard.Page>
          </Wizard>
        )}
      </Grid>
    </Dialog>
  );
}

SecondDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
export default function Popups(props) {
  const classes = useStyles();
  const [firstPopupOpen, setFirstPopupOpen] = React.useState(false);
  const [secondPopupOpen, setSecondPopupOpen] = React.useState(false);
  const [type, setType] = React.useState();
  const [firstSent, setfirstSent] = React.useState(false);
  const [popUp, setPopUp] = React.useState();
  const MY_PROFILE = gql`
    query myProfile {
      myProfile {
        id
        role
        firstName
        lastName
        seenFirstPopup
      }
    }
  `;

  const {loading, data} = useQuery(MY_PROFILE, {
    fetchPolicy: "network-only",
    variables: {}
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (!firstSent && !data.myProfile.seenFirstPopup) {
          setFirstPopupOpen(true);
        }
      }
    }
  }, [firstPopupOpen, loading, data]);

  const handleClose = value => {
    if (value) {
      if (value === "Other") {
        props.firstPopup({variables: {type: value}}).then(data => {
          if (data.data.firstPopup) {
            setfirstSent(true);
            setFirstPopupOpen(false);
            // if((window.location.href.indexOf("dashboard.startupborsa") > -1) && window.userGuiding){
            //   window.userGuiding.previewGuide(23693, {
            //     checkHistory: true,
            //   });
            // }
          }
        });
      } else {
        setType(value);
        setfirstSent(true);
        setFirstPopupOpen(false);
        setSecondPopupOpen(true);
      }
    }
  };

  const handleClose2 = () => {
    setSecondPopupOpen(false);
  };

  return (
    <div>
      <FirstDialog open={firstPopupOpen} onClose={handleClose} />
      <SecondDialog
        open={secondPopupOpen}
        setFirstPopupOpen={setFirstPopupOpen}
        setSecondPopupOpen={setSecondPopupOpen}
        type={type}
        firstPopup={props.firstPopup}
        onClose={handleClose2}
        history={props.history}
      />
    </div>
  );
}
