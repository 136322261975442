import React from "react"
import {Grid, Typography, Hidden, Button} from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import login from '../../static/img/auth-photo.jpg';
import { loginSchema } from '../../yupSchemas';
import FormikTextField from "../../Components/FormikTextField";
import logo from '../../static/img/startupBorsaLogo.jpg';
import Snackbar from "@material-ui/core/Snackbar";
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import {makeStyles} from "@material-ui/core/styles";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {useAuth} from "../../context/auth";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) =>({
    img: {
          maxWidth: '100%',
          display: 'block'
    },
    link: {
        textDecoration: 'none',
        color: '#777777',
        padding: 0,
        fontWeight: 'normal',
        marginTop: 20

    },
    mainGrid: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between'
    },
    leftGrid: {
        [theme.breakpoints.up('sm')]: {
                width: '100%',
                height: '100%'
        },   
    backgroundImage: "url(" + login + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    },
    logoGrid: {
        height: 'fit-content',
        [theme.breakpoints.up('xl')]: {
            padding:80,
            paddingBottom:0
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
        padding:40
    },
    signUp: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: 30
        },
        marginTop: 20
    },
    loginGrid: {
        flexDirection:'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: 50
        },
    },
    logo: {
        width: 145,
        height: 50,
    },
    firstLogo: {
        width: 450,
        height: 155,
        [theme.breakpoints.down('lg')]: {
            width: 300,
            height: 100,
        },
    },
    welcomeText: {
        color: '#777777',
        [theme.breakpoints.up('xl')]: {
            marginTop: 30
        },
        marginTop: 25
    },
    EmailText: {
        [theme.breakpoints.up('xl')]: {
            marginTop: 50
        },
        marginTop: 30,
    },
    PasswordText: {
        fontWeight: 500
    },
    signLink: {
        fontWeight: 'normal',
        color: '#172b4d',
        padding: 0,
        display: 'flex',
        alignItems:'center',
        fontSize:20
    }
  }));

  export default function SmsVerification(props){

    document.body.style = 'background: #ffffff;margin:0';
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [serverSuccess, setServerSuccess] = React.useState(false);
    const [minutes, setMinutes] = React.useState(3);
    const [seconds, setSeconds] = React.useState(0);
    let myInterval
    const classes = useStyles();
    React.useEffect(() => {
        if(minutes !== '0' ){
                if (seconds > 0) {
                    setTimeout(() => setSeconds(seconds - 1), 1000);
                }
                
                if (seconds === 0) {
                    if(minutes === 0) {
                        setMinutes('0')
                    }
                    else {
                        setTimeout(() => setSeconds(59), 1000);
                        setTimeout(() => setMinutes(minutes - 1), 1000);
                    }
                } 
        }
    },
    [seconds, minutes])

    const [serverMessage, setServerMessage] = React.useState({
        detail: '',
        message: '',
        type: false,
      });
     
    const handleSubmit = variables => {
        setIsSubmitting(true);
        props.history.push(`/dashboard`)
    };   
        return (
        <Grid container style={{height : '100vh'}}>
            <Grid container item lg={6} md={12} xs={12}>
                    <Grid xs={12} container item justify="flex-end" alignItems="center" className={classes.logoGrid}>
                        <img src={logo} alt="startup-borsa" className={classes.logo}></img>
                    </Grid> 
                <Grid sm={12} xl={12} xs={12} alignItems="center" className={classes.loginGrid} container item>
                    <Grid item sm={12} xs={11} className={classes.mainGrid}>
                      <Formik
                      initialValues={{
                        code: '',
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>

                            <Typography variant="h1" align="center">Kodu Gir</Typography>
                            <Typography variant="body1" align="center" className={classes.welcomeText}>Telefonunuza Gelen 6 haneli kodu giriniz</Typography>
                            <Grid container justify="space-between">
                                <Typography variant="body1" className={classes.EmailText} style={{fontWeight:500}}>Kod</Typography>
                                <Typography variant="body1" className={classes.EmailText} style={{fontWeight:500}}>{ minutes }:{ seconds < 10 ? `0${ seconds }` : seconds }</Typography>
                            </Grid>
                            <FormikTextField name="code" type="text" placeholder="6 haneli kodu giriniz" />
                            <Grid item container xs={12} justify="center" style={{marginTop: 40}}> 
                                <Button variant="containedPrimary" style={{fontWeight: 'normal'}} type="submit" disabled={!props.isValid}>Doğrula</Button>
                            </Grid>
                        </Form> 
                         )}
                       </Formik> 

                       <Snackbar autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setServerSuccess(false)} open={serverSuccess} >
                            <Alert severity="error">Girilen Kod Hatalı</Alert>
                        </Snackbar>
                        <Typography className={classes.newAround} style={{marginBottom:40}} variant="body1" align="center">
                        Startup Borsa, bir <strong style={{color:'#003CA5'}}>Gedik Yatırım</strong> kuruluşudur.
                    </Typography>
                    </Grid> 
                </Grid>
            </Grid>
            <Hidden mdDown>
                <Grid item sm={6} xs={12}>
                    <Grid className={classes.leftGrid}></Grid>
                </Grid>
            </Hidden>
        </Grid>
    );}