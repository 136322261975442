import AdminKPI from './AdminKPI'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const CREATE_KPI_CAT = gql`
  mutation adminKPICategoryCreate($title: String!, $order: Int) {
    adminKPICategoryCreate(title: $title, order: $order) 
  }
`;

const CREATE_KPI_SET = gql`
  mutation adminKPISetCreate($title: String!, $order: Int, $description: String , $subtitle: String) {
    adminKPISetCreate(title: $title, order: $order, description: $description, subtitle: $subtitle) 
  }
`;

const CREATE_KPI = gql`
  mutation adminKPICreate($title: String!, $description: String, $categoryId: ID!, $dataFrequency: String!, $order: Int, $dataFormat: String!,) {
    adminKPICreate(title: $title, description: $description, categoryId: $categoryId, dataFrequency: $dataFrequency, order: $order, dataFormat: $dataFormat) 
  }
`;

const CREATE_GA_KPI = gql`
  mutation adminKPIGACreate($title: String!, $description: String, $categoryId: ID!, $dataFrequency: String!, $order: Int, $dataFormat: String!, $metric: String!, $dimension: String!,) {
    adminKPIGACreate(title: $title, description: $description, categoryId: $categoryId, dataFrequency: $dataFrequency, order: $order, dataFormat: $dataFormat, metric: $metric, dimension: $dimension) 
  }
`;

const EDIT_KPI = gql`
  mutation adminKPIEdit($kpiId: ID!, $title: String!, $description: String, $categoryId: ID!, $dataFrequency: String!, $order: Int,  $dataFormat: String!,) {
    adminKPIEdit(kpiId: $kpiId, title: $title, description: $description, categoryId: $categoryId, dataFrequency: $dataFrequency, order: $order, dataFormat: $dataFormat) 
  }
`;

const EDIT_GA_KPI = gql`
  mutation adminKPIGAEdit($kpiId: ID!, $title: String!, $description: String, $categoryId: ID!, $dataFrequency: String!, $order: Int,  $dataFormat: String!, $metric: String!, $dimension: String!,) {
    adminKPIGAEdit(kpiId: $kpiId, title: $title, description: $description, categoryId: $categoryId, dataFrequency: $dataFrequency, order: $order, dataFormat: $dataFormat, metric: $metric, dimension: $dimension) 
  }
`;

const EDIT_KPI_CAT= gql`
  mutation adminKPICategoryEdit($title: String!, $order: Int, $categoryId: ID!) {
    adminKPICategoryEdit(title: $title, order: $order, categoryId: $categoryId) 
  }
`;

const EDIT_KPI_SET= gql`
  mutation adminKPISetEdit($title: String!, $order: Int, $setId: ID!, $description: String , $subtitle: String) {
    adminKPISetEdit(title: $title, order: $order, setId: $setId, description: $description, subtitle: $subtitle,) 
  }
`;

const DELETE_KPI_CAT= gql`
  mutation adminKPICategoryDelete($categoryId: ID!) {
    adminKPICategoryDelete(categoryId: $categoryId) 
  }
`;
const DELETE_KPI_SET= gql`
  mutation adminKPISetDelete($setId: ID!) {
    adminKPISetDelete(setId: $setId) 
  }
`;

const DELETE_KPI= gql`
  mutation adminKPIDelete($kpiId: ID!) {
    adminKPIDelete(kpiId: $kpiId) 
  }
`;

const DELETE_GA_KPI= gql`
  mutation adminKPIGADelete($kpiId: ID!) {
    adminKPIGADelete(kpiId: $kpiId) 
  }
`;

const ADD_KPI_CAT = gql`
  mutation adminKPICategoryAddKPI($categoryId: ID!,$kpiIds: [ID],) {
    adminKPICategoryAddKPI(categoryId: $categoryId, kpiIds: $kpiIds,) 
  }
`;

const ADD_KPI_SET = gql`
  mutation adminKPISetAddKPI($setId: ID!,$kpiIds: [ID],) {
    adminKPISetAddKPI(setId: $setId, kpiIds: $kpiIds,) 
  }
`;

const HANDLE_SUGGEST = gql`
  mutation adminKPISuggestHandle($status: String!,$suggestIds: [ID],) {
    adminKPISuggestHandle(status: $status, suggestIds: $suggestIds,) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_KPI_CAT, {
    name: 'adminKPICategoryCreate',
  }),
  graphql(EDIT_KPI_CAT, {
    name: 'adminKPICategoryEdit',
  }),
  graphql(ADD_KPI_CAT, {
    name: 'adminKPICategoryAddKPI',
  }),
  graphql(DELETE_KPI_CAT, {
    name: 'adminKPICategoryDelete',
  }),
  graphql(CREATE_KPI, {
    name: 'adminKPICreate',
  }),
  graphql(EDIT_KPI, {
    name: 'adminKPIEdit',
  }),
  graphql(DELETE_KPI, {
    name: 'adminKPIDelete',
  }),
  graphql(CREATE_GA_KPI, {
    name: 'adminKPIGACreate',
  }),
  graphql(EDIT_GA_KPI, {
    name: 'adminKPIGAEdit',
  }),
  graphql(DELETE_GA_KPI, {
    name: 'adminKPIGADelete',
  }),
  graphql(CREATE_KPI_SET, {
    name: 'adminKPISetCreate',
  }),
  graphql(EDIT_KPI_SET, {
    name: 'adminKPISetEdit',
  }),
  graphql(DELETE_KPI_SET, {
    name: 'adminKPISetDelete',
  }),
  graphql(ADD_KPI_SET, {
    name: 'adminKPISetAddKPI',
  }),
  graphql(HANDLE_SUGGEST, {
    name: 'adminKPISuggestHandle',
  }),
)(AdminKPI);
export default JoinWithMutation;