import * as compose from 'lodash.flowright';

import AdminSecondaryShareList from './AdminSecondaryShareList';
import { HEADER_LEFT_DRAWER } from '../../queries';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const ADMIN_SET_SECONDARY_SWITCH = gql`
  mutation adminSetSecondaryShareRequestsSwitch($secondaryId: ID!, $status: Boolean!) {
    adminSetSecondaryShareRequestsSwitch(secondaryId: $secondaryId, status: $status) {
      id
      adminStatusSwitch
    }
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(ADMIN_SET_SECONDARY_SWITCH, {
    name: 'adminSetSecondaryShareRequestsSwitch',
  }),
)(AdminSecondaryShareList);
export default JoinWithMutation;
