import FinanceTab from './FinanceTab'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $description: String
    $investmentRaisedAmount: BigInt
    $postMoneyValuation: BigInt
    $investmentNeededDD: String
    $lastRoundDate: String
    $lastRoundInvestment: String
    $stage: String
    $capTableAInvestors: String
    $capTableAFounding: String
    $payrollFA: String
    $marketingFA: String
    $salesFA: String
    $rdFA: String
    $developmentFA: String
    $otherFA: String
) {
  editStartup(
        id: $id
        description: $description
        investmentRaisedAmount: $investmentRaisedAmount
        postMoneyValuation: $postMoneyValuation
        investmentNeededDD: $investmentNeededDD
        lastRoundDate: $lastRoundDate
        lastRoundInvestment: $lastRoundInvestment
        stage: $stage
        capTableAInvestors: $capTableAInvestors
        capTableAFounding: $capTableAFounding
        payrollFA: $payrollFA
        marketingFA: $marketingFA
        salesFA: $salesFA
        developmentFA: $developmentFA
        rdFA: $rdFA
        otherFA: $otherFA
    )
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_STARTUP, {
  name: 'editStartup',
  }),
)(FinanceTab);
export default JoinWithMutation;