import 'moment/locale/tr';

import { Field, Form, Formik } from 'formik';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormikDropdown from '../../Components/FormikDropdown';
import FormikMultiLine from '../../Components/FormikMultiLine';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Input from '@material-ui/core/Input';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import filterIcon from '../../static/icon/filterIcon.svg';
import get from 'lodash/get';
import gql from 'graphql-tag';
import moment from 'moment';
import newCross from '../../static/icon/new-cross.svg';
import newPerson from '../../static/icon/new-person-icon.svg';
import { numberWithPeriods } from '../../helpers';
import { useQuery } from '@apollo/react-hooks';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
    marginTop: 35,
  },
  Debounce: {
    width: '100%',
    maxWidth: '180px',
    fontSize: 16,
    height: '35px',
    color: '#8898aa',
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid #c8c8ca',
    background: 'inherit',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  addButton: {
    fontWeight: 'normal',
    padding: '6.5px 11px',
    fontSize: 13,
    color: '#ffba00',
    marginLeft: 10,
    borderColor: '#ffba00',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#ffba00',
      color: '#fff',
      borderColor: '#ffba00',
    },
  },
  multiSelect2: {
    borderRadius: 4,
    '& div': {
      background: 'none!important',
      paddingRight: '5px!important',
      paddingLeft: '12px',
    },
  },
  multiSelect: {
    maxHeight: 40,
    minWidth: 116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor: '#fff',
    '& div': {
      background: 'none!important',
    },
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop: 30,
    '& textarea': {
      padding: 16,
      paddingRight: 90,
      fontSize: 13,
      lineHeight: 1,
    },
  },
  sendButton: {
    minWidth: 50,
    height: 50,
    borderRadius: '50%',
    background: '#003CA5',
    marginTop: '-80px',
    marginRight: 30,
    cursor: 'pointer',
    '&:hover': {
      background: '#003CA5',
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color: '#8898aa',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color: '#003CA5',
    },
  },
  sticker: {
    marginRight: 10,
    color: '#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  filter: {
    height: 40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: '20px',
    },
  },
  myPaper: {
    padding: 25,
    width: '650px',
  },
  myLabel: {
    fontSize: 14,
  },
  formControl: {
    marginTop: 10,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
  whiteCard: {
    background: '#ffffff',
    borderRadius: '4px',
    padding: 25,
    marginBottom: 50,
    marginTop: 50,
  },
  RightBorder: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid rgb(233, 233, 240)',
    },
  },
  paddingL: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '40px!important',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize: 11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

function NoContactDialog(props) {
  const classes = useStyles();
  const { onClose, open, startupId } = props;

  const handleSubmit = (variables) => {
    variables.reqId = startupId;
    props
      .investorResponseInvestmentRequest({ variables })
      .then((data) => {
        if (data.data.investorResponseInvestmentRequest) {
          onClose(null);
          props.setContactSent(true);
          props.refetch();
        }
      })
      .then(() => {})
      .catch((error) => {
        onClose(null);
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Başvuruyu Reddet
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20 }} xs={10}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            Sizinle iletişime geçmek isteyen girişimin başvurusunu reddetmek için hesap yöneticinizi bilgilendirin ve
            süreci tamamlayın.
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          reqId: '',
          status: 'Rejected',
          responseExplanation: '',
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <>
            <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
              <Grid container justify="space-between" xs={9}>
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ height: 'fit-content' }}>
                        <Typography variant="h6" style={{ width: '100%' }}>
                          Ek Notlarınız
                        </Typography>
                        <FormikMultiLine
                          style={{ width: '100%', marginBottom: 20 }}
                          name="responseExplanation"
                          rows={4}
                          type="text"
                          placeholder="Ek Notlarınız"
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', width: '100%' }}
                          onClick={() => handleSubmit(props.values)}
                        >
                          Reddet
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

function ContactDialog(props) {
  const classes = useStyles();
  const { onClose, open, startupId } = props;
  const method = ['E-posta', 'Telefon'];

  const handleSubmit = (variables) => {
    variables.reqId = startupId;
    props
      .investorResponseInvestmentRequest({ variables })
      .then((data) => {
        if (data.data.investorResponseInvestmentRequest) {
          onClose(null);
          props.setContactSent(true);
          props.refetch();
        }
      })
      .then(() => {})
      .catch((error) => {
        onClose(null);
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          İletişime Geç
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20 }} xs={10}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            Sizinle iletişime geçmek isteyen girişim ile görüşmek için hesap yöneticinizi bilgilendirin ve süreci
            başlatın.
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          reqId: '',
          status: 'Approved',
          communicationMethod: '',
          responseExplanation: '',
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <>
            <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
              <Grid container justify="space-between" xs={9}>
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ height: 'fit-content' }}>
                        <Typography variant="h6" style={{ width: '100%' }}>
                          İletişim Yöntemi
                        </Typography>
                        <Field
                          style={{ width: '100%', marginTop: 10 }}
                          component={FormikDropdown}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="communicationMethod"
                          options={method}
                        ></Field>
                      </Grid>
                      <Grid item container xs={12} style={{ height: 'fit-content' }}>
                        <Typography variant="h6" style={{ width: '100%' }}>
                          Açıklama
                        </Typography>
                        <FormikMultiLine
                          style={{ width: '100%', marginBottom: 20 }}
                          name="responseExplanation"
                          rows={4}
                          type="text"
                          placeholder="Buraya görüşme öncesi ek taleplerinizi ve görüşlerinizi yazabilirsiniz."
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', width: '100%' }}
                          disabled={!props.values.responseExplanation || !props.values.communicationMethod}
                          onClick={() => handleSubmit(props.values)}
                        >
                          Gönder
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

function ExpandingRow(props) {
  const [contact, setContact] = React.useState(false);
  const [noContact, setNoContact] = React.useState(false);
  const [contactSent, setContactSent] = React.useState(false);

  const { row } = props;
  return (
    <>
      <StyledTableRow key={row.id + 1} style={{ background: 'rgb(247, 247, 252)' }}>
        <StyledTableCell style={{ padding: '12px 0px 12px 30px' }} component="th" scope="row"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
      </StyledTableRow>
      <StyledTableRow key={row.id} style={{ background: 'rgb(247, 247, 252)' }}>
        <StyledTableCell style={{ padding: '0px', width: 400 }} component="th" scope="row">
          <Grid
            container
            alignItems="center"
            style={{
              padding: '18px 0px 18px 24px',
              background: '#fff',
              borderBottomLeftRadius: 16,
              borderTopLeftRadius: 16,
              flexWrap: 'nowrap',
            }}
          >
            {row.startup && row.startup.logo ? (
              <img
                src={row.startup.logo}
                alt="logo"
                style={{ width: 35, minWidth: 35, height: 35, borderRadius: '12px', border: 'solid 1px #eaedf3' }}
              />
            ) : (
              <Grid
                style={{
                  width: 35,
                  minWidth: 35,
                  height: 35,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography variant="h5" style={{ color: '#fff' }}>
                  {row.startup && row.startup.title.charAt(0)}
                </Typography>
              </Grid>
            )}
            <Link to={`/girisim/${row.startup.slug}`} style={{ padding: 0 }}>
              <Typography variant="h6" style={{ marginLeft: 16 }}>
                {row.startup && row.startup.title}
              </Typography>
            </Link>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ background: '#fff' }}>
          <Typography variant="body2" align="left">
            {moment(parseInt(row.createdAt)).locale('tr').format('Do MMMM YYYY')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ background: '#fff' }}>
          <Typography variant="h6">
            {row.requiredInvestmentAmount && numberWithPeriods(row.requiredInvestmentAmount)}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ padding: 0 }}>
          {row.status === 'Pending' ? (
            <Grid
              container
              justify="flex-end"
              style={{
                padding: '18px 0px 18px 24px',
                background: '#fff',
                borderBottomRightRadius: 16,
                borderTopRightRadius: 16,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ padding: '12px 24px', marginRight: 24, boxShadow: 'none' }}
                onClick={() => setContact(true)}
              >
                İletişime geç
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: '12px 24px', background: '#fff', color: '#F16063', boxShadow: 'none' }}
                onClick={() => setNoContact(true)}
              >
                Reddet
              </Button>
            </Grid>
          ) : (
            <Grid
              container
              justify="flex-end"
              style={{
                padding: '18px 0px 18px 24px',
                background: '#fff',
                borderBottomRightRadius: 16,
                borderTopRightRadius: 16,
              }}
            >
              <Grid
                item
                style={{
                  height: 36,
                  minWidth: 208,
                  marginRight: 36,
                  borderRadius: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#F0F6FF',
                }}
              >
                <Typography variant="h6" color="primary">
                  Hesap Yöneticisine İletildi
                </Typography>
              </Grid>
            </Grid>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <ContactDialog
        investorResponseInvestmentRequest={props.investorResponseInvestmentRequest}
        startupId={row.id}
        refetch={props.refetch}
        setContactSent={setContactSent}
        open={contact}
        onClose={() => setContact(false)}
      />
      <NoContactDialog
        investorResponseInvestmentRequest={props.investorResponseInvestmentRequest}
        startupId={row.id}
        refetch={props.refetch}
        setContactSent={setContactSent}
        open={noContact}
        onClose={() => setNoContact(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setContactSent(false)}
        open={contactSent}
      >
        <Alert severity="success">İletişim talebiniz alındı.</Alert>
      </Snackbar>
    </>
  );
}
export default function InvestmentRequests(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [sort, setSort] = React.useState([]);
  const [requests, setRequests] = React.useState();
  const [order, setOrder] = React.useState('id__DESC');
  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const ALL_REQUESTS = gql`
    query investorsInvestmentRequests($page: Int, $pageSize: Int, $order: String) {
      investorsInvestmentRequests(pagination: { page: $page, pageSize: $pageSize }, orderBy: [$order]) {
        count
        pageCount
        data {
          id
          status
          startup {
            id
            slug
            logo
            title
            contactMail
          }
          nameSurname
          createdAt
          requiredInvestmentAmount
        }
      }
    }
  `;

  const { loading, data, refetch } = useQuery(ALL_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: { order: order, search: search, page: page, pageSize: pageSize },
  });

  const options = [
    { name: 'A-Z', value: 'startup__title__ASC' },
    { name: 'Z-A', value: 'startup__title__DESC' },
    { name: 'Latest', value: 'id__DESC' },
    { name: 'Oldest', value: 'id__ASC' },
  ];
  const pageSizeOptions = [3, 5, 10, 20, 50];
  const filterOptions = [];

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setRequests(data.investorsInvestmentRequests.data);
        setPageCount(data.investorsInvestmentRequests.pageCount);
        setRequestsCount(data.investorsInvestmentRequests.count);
      }
    }
  }, [data, loading]);

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };
  const handleSort = (event) => {
    setSort(event.target.value);
    setPage(1);
  };
  const handleFilter = (event) => {
    setPage(1);
    setFilter(event.target.value);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
            <Grid item style={{ width: '100%' }}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item style={{ display: 'flex' }}>
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <img src={newPerson} style={{ width: 18, marginTop: 5 }} alt="people" />
                    <Typography variant="h4">Yatırım Başvurularım</Typography>
                  </Breadcrumbs>
                </Grid>
                <Hidden smDown>
                  <Grid item className={classes.filterArea}>
                    <Grid className={classes.filter} onClick={handleClick} item>
                      <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                      <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                        Filtrele
                      </Typography>
                      <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
                    </Grid>
                    <Popover
                      id={id}
                      open={openPop}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      classes={{
                        paper: classes.myPaper,
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Grid container xs={12}>
                        <Grid item xs={4} style={{ borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                          <Typography variant="h5">Filtrele</Typography>
                          <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup onChange={handleFilter} value={filter}>
                              {filterOptions.map((value, i) => {
                                // Return the element. Also pass key
                                return (
                                  <FormControlLabel
                                    classes={{ label: classes.myLabel }}
                                    control={<Radio color="primary" value={value} />}
                                    label={value}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} style={{ paddingLeft: '30px', borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                          <Typography variant="h5">Filtre Seç</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                          <Typography style={{ marginBottom: 18 }} variant="h5">
                            Seçimleriniz
                          </Typography>
                        </Grid>
                        <Grid container justify="flex-end"></Grid>
                      </Grid>
                    </Popover>
                    <FormControl placeholder="Please Select" style={{ width: '100%', maxWidth: '116px' }}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-mutiple-checkbox-label2"
                        id="demo-mutiple-checkbox2"
                        displayEmpty
                        value={sort}
                        className={classes.multiSelect}
                        onChange={handleSort}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props}
                            style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                          />
                        )}
                        input={<Input />}
                        /*   MenuProps={MenuProps} */
                      >
                        <MenuItem value="" disabled>
                          <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                            Sırala
                          </Typography>
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {option.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Hidden>
              </Grid>
              {requests && (
                <Grid container xs={12} style={{ marginTop: 50 }}>
                  <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            <Typography variant="h6" style={{ color: '#8492A6', fontSize: 10 }}>
                              Girişimin Adı
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" style={{ color: '#8492A6', fontSize: 10 }}>
                              Başvuru Tarihi
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" style={{ color: '#8492A6', fontSize: 10 }}>
                              Aranan Yatırım Miktarı
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {requests.map((row) => (
                          <ExpandingRow
                            investorResponseInvestmentRequest={props.investorResponseInvestmentRequest}
                            row={row}
                            refetch={refetch}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {requests.length > 0 ? (
                    <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                      <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                      />
                    </Grid>
                  ) : (
                    <Grid container justify="center">
                      <Typography style={{ margin: '25px 0' }} variant="h5">
                        Başvuru bulunamadı...
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
