
import React, { useRef } from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Link} from 'react-router-dom'
import get from 'lodash/get';
import Button from "@material-ui/core/Button";
import { Form, Formik, Field, useField, FieldArray } from 'formik';
import FormikTextField from "../../Components/FormikShortTextField";
import moment from 'moment'
import 'moment/locale/tr'
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import { DndProvider , useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {stateToHTML} from 'draft-js-export-html'
import update from 'immutability-helper';
import { EditorState } from 'draft-js';
import { RichEditor } from "../../Components/RichEditor/RichEditor";
import ClearIcon from '@material-ui/icons/Clear';
import { stateFromHTML } from 'draft-js-import-html'
import { RichEditorReadOnly } from "../../Components/RichEditorReadOnly/RichEditorReadOnly.js";
import {Line} from 'react-chartjs-2';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  whiteCard:{
    padding:35,
    background:'#fff',
    borderRadius: 4,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    marginBottom:25
  },
  AddButton:{
    padding: '12.5px 0px',
    display:'flex',
    justifyContent:'space-around',
    minWidth:175,
    fontSize:16,
    marginRight:10,
    marginTop:15,
    fontWeight:'normal'
},
  editButton: {
    fontWeight:'normal',
    [theme.breakpoints.up('sm')]: {
      marginRight:25
    },
    [theme.breakpoints.down('md')]: {
      marginTop:30,
    }, 
  }
}));

function SelectPart(props){
  const classes = useStyles();
  const [list, setList] = React.useState();
  const [kpis, setKpis] = React.useState();
  const [listValue, setListValue] = React.useState();

    const GET_LIST = gql`
    query startupReportGetKPIList($listId: ID) {
      startupReportGetKPIList(listId:$listId){
        lists{
          id
          title
        }
        kpis{
          id
          title
        }
      }
    }
    `;

    const { loading, data } = useQuery(GET_LIST, {
        fetchPolicy:'network-only',
        variables: {listId: listValue ? listValue.id : ''},
    });
    React.useEffect(() => {
      if(!loading){
          if(data){
            setList(data.startupReportGetKPIList.lists);
            setKpis(data.startupReportGetKPIList.kpis);
          }
      }
    },
    [data, loading])

  return (
    <Grid container xs={12} spacing={3}>
      {list &&
      <Grid item xs={12} sm={6}>
        <Autocomplete
          style={{ marginTop: 10, background: '#fff' }}
          options={list}
          noOptionsText={'Sonuç Bulunamadı..'}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          value={listValue}
          onChange={(_, value) => setListValue(value)}
          renderInput={(props) => <TextField {...props} label="Liste Seçiniz" variant="outlined" />}
        ></Autocomplete>
      </Grid>
      }
      {kpis && 
      <Grid item xs={12} sm={6}>      
        <Field component={FormikKPIDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`content.${props.index}.kpi`} options={kpis}></Field>
      </Grid>
      }
    </Grid>
  );
}

const FormikKPIDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10, background:'#fff'}}
          {...props}
          {...field}
          options={props.options}
          noOptionsText={'Sonuç Bulunamadı..'}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } label="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

          {meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}


const GraphComponent = ({ con, ...props }) => {
  const classes = useStyles();
  const [graphDataState, setGraphData] = React.useState([]);
  const [graphDateState, setGraphDate] = React.useState([]);

  if (graphDataState.length === 0){
    let graphData = [];
    con.kpiData.forEach((data) => {
      graphData.push(data.reached);
    });
    setGraphData(graphData);
  }

  if (graphDateState.length === 0){
    let graphDate = [];
    con.kpiData.forEach((data) => {
      graphDate.push(moment(parseInt(data.date)).format('Do MMMM YYYY'));
    });  
    setGraphDate(graphDate);
  }

  return (
    <>
    {(graphDataState.length > 0 && graphDateState.length)  &&
        <Grid item xs={12} md={6} style={{padding:25}}>
          <Line
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: false,
                      borderDashOffset: 1,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      borderDash: [2, 2],
                      lineWidth: 2,
                      drawBorder: false,
                    },
                  },
                ],
              },
            }}
            data={{
              labels: graphDateState,
              datasets: [
                {
                  label: 'Alınan Yatırım Miktarı',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: '#172b4d',
                  borderColor: '#ffba00',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#172b4d',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#172b4d',
                  pointHoverBorderColor: '#172b4d',
                  pointHoverBorderWidth: 1,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: graphDataState,
                },
              ],
            }}
          />                    
        </Grid>
      }
  </>
  );
}


const TextCard = ({ id, card, index, moveCard, setFieldValue, handleBlur, ...props }) => {
  const style = {
    border: '1px dashed #003CA5',
    padding: '30px 40px',
    marginBottom: '15px',
    backgroundColor: 'initial',
};

const ItemTypes = {
  CARD: 'card',
}
  const ref = useRef(null);
  const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover(item, monitor) {
          if (!ref.current) {
              return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
              return;
          }
          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();
          // Get vertical middle
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          // Determine mouse position
          const clientOffset = monitor.getClientOffset();
          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return;
          }
          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return;
          }
          // Time to actually perform the action
          moveCard(dragIndex, hoverIndex);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex;
      },
  });
  const [{ isDragging }, drag] = useDrag({
      item: { type: ItemTypes.CARD, id, index },
      collect: (monitor) => ({
          isDragging: monitor.isDragging(),
      }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid item xs={12} ref={ref} style={{ ...style, opacity }}>
      <Grid container justify="space-between">
        <Typography variant="h5" color="primary">
          Metin Bloğu
        </Typography>
        <ClearIcon
          onClick={() => props.setCards(props.cards.splice(index - 1, 1))}
          style={{
            cursor: 'pointer',
            fontSize: 20,
            marginTop: -15,
            marginRight: -30,
          }}
        />
      </Grid>
      <FormikTextField name={`content.${index}.title`} type="text" placeholder="Rapor Adı" />
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <RichEditor
          editorState={card.text}
          onChange={setFieldValue}
          name={`content.${index}.text`}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
}

export default function ReportCreate(props){
    const classes = useStyles();
    const [list, setList] = React.useState();
    const [detail, setDetail] = React.useState();
    const [create, setCreate] = React.useState(true);
    const [editor, setEditor] = React.useState(EditorState.createEmpty());
    const options = ['Aylık', '3 Aylık', '6 Aylık'];
    const style = {
      border: '1px dashed #003CA5',
      padding: '30px 40px',
      marginBottom: '15px',
      backgroundColor: 'initial',
    };

    const style2 = {
      border: '1px dashed #ffba00',
      padding: '30px 40px',
      marginBottom: '15px',
      backgroundColor: 'initial',
    };

    const [cards, setCards] = React.useState([
  ]);

  const moveCard = React.useCallback((dragIndex, hoverIndex) => {
    const dragCard = cards[dragIndex];
    setCards(update(cards, {
        $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
        ],
    }));
  }, [cards]);

  const setIndex = () => {
    return cards.length;
  };
    
    const GET_LIST_INFO = gql`
    query startupReportList($id: ID) {
      startupReportList(id:$id){
        data{
              id
              title
              startDate
              dataFrequency
              reminderFrequency
              groups{
                  id
                  title
              }
              persons{
                  id
                  getName
              }
            }
      }
    }
    `;
      const { loading:loadingList, data:dataList} = useQuery(GET_LIST_INFO, {
        fetchPolicy:'network-only',
        variables: {id:props.match.params.id},
    });

    React.useEffect(() => {
      if(!loadingList){
        if(dataList){
          setList(dataList.startupReportList.data);
        }
      }
    },
    [loadingList,dataList])

    const handleSubmit = variables => {

      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
      });

      props.startupReportPreview({ variables })
        .then(data => {
          if (data.data.startupReportPreview) {
            variables.content.forEach(element => {
              if(element.type === 'text'){
                element.text = EditorState.createWithContent(stateFromHTML(element.text));
              }
            });
            data.data.startupReportPreview.content && data.data.startupReportPreview.content.forEach(con => {
              if (con.type === 'text') {
                if (con.text) {
                  let htmlText = con.text;
                  if (typeof con.text === 'string') {
                    con.text = EditorState.createWithContent(stateFromHTML(htmlText));
                  }
                } else {
                  con.text = new EditorState.createEmpty();
                }
              }
            })
            setDetail(data.data.startupReportPreview);
            setCreate(false)
          } 
        })
    };

    const handleDraft = variables => {

      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
      });

      variables.status = 'Draft'
      props.startupReportCreate({ variables })
        .then(data => {
          if (data.data.startupReportCreate) {
            props.history.push('/raporlarım')
          } 
        })
    };

    const handleShare = variables => {

      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
      });

      variables.status = 'Sent'
      props.startupReportCreate({ variables })
        .then(data => {
          if (data.data.startupReportCreate) {
            props.history.push('/raporlarım')
          } 
        })
    };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Formik
              initialValues={{
                listId: props.match.params.id,
                sendingText: '',
                content: [],
              }}
            >
              {(props) => (
                <Grid
                  className={'main-container'}
                  xs={12}
                  style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
                >
                  <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 50 }}>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                      Rapor Ekle
                    </Typography>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      {create ? (
                        <>
                          <Link
                            style={{ padding: 0, marginRight: 20 }}
                            to={{
                              pathname: `/raporlarım/`,
                            }}
                          >
                            <Button
                              style={{ fontWeight: 'normal', fontSize: 14, width: '100%', marginRight: 20 }}
                              variant="outlinedSecondary"
                            >
                              Vazgeç
                            </Button>
                          </Link>
                          <Button
                            variant="containedPrimary"
                            onClick={() => handleSubmit(props.values)}
                            style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170 }}
                          >
                            Ön İzleme
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="outlinedSecondary"
                            onClick={() => setCreate(true)}
                            style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170, marginRight: 20 }}
                          >
                            Düzenle
                          </Button>
                          <Button
                            variant="outlinedSecondary"
                            onClick={() => handleDraft(props.values)}
                            style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170, marginRight: 20 }}
                          >
                            Taslak Kaydet
                          </Button>
                          <Button
                            variant="containedPrimary"
                            onClick={() => handleShare(props.values)}
                            style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170, marginRight: 20 }}
                          >
                            Paylaş
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Form style={{ width: '100%' }}>
                    {list && (
                      <Grid xs={12} container direction="column" className={classes.whiteCard}>
                        <Typography variant="h5">Rapor Özeti</Typography>
                        <Grid xs={12} container style={{ marginTop: 25 }}>
                          <Grid item xs={6} md={2}>
                            <Typography variant="h6">Gönderim Sıklığı</Typography>
                            <Typography variant="body2" style={{ marginTop: 10 }}>
                              {list[0].dataFrequency}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <Typography variant="h6">Hatırlatma Sıklığı</Typography>
                            <Typography variant="body2" style={{ marginTop: 10 }}>
                              {list[0].reminderFrequency}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <Typography variant="h6">Başlangıç Tarihi</Typography>
                            <Typography variant="body2" style={{ marginTop: 10 }}>
                              {moment(parseInt(list[0].startDate)).locale('tr').format('Do MMMM YYYY')}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <Typography variant="h6">Gruplar</Typography>
                            <Typography variant="body2" style={{ marginTop: 10 }}>
                              {list[0].groups &&
                                list[0].groups.map((group, index) =>
                                  list[0].groups.length === index + 1 ? group.title : group.title + ',',
                                )}
                            </Typography>
                          </Grid>
                          {!create && (
                            <Grid xs={12} container style={{ marginTop: 25 }}>
                              <Grid item xs={12}>
                                <Typography variant="h6">Gönderim Metni</Typography>
                                <Typography variant="body2" style={{ marginTop: 10 }}>
                                  {detail.sendingText}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {create ? (
                      <Grid xs={12} container spacing={3}>
                        <Grid xs={12} item style={{ marginTop: 15 }}>
                          <Typography variant="h6">
                            Gönderim metni
                          </Typography>
                          <FormikMultiLine
                            style={{ background: '#fff', width: '100%', marginBottom: 20 }}
                            name="sendingText"
                            rows={4}
                            type="text"
                            placeholder="Açıklama.."
                          />
                        </Grid>
                        <Grid container xs={12} style={{ padding: 12 }}>
                          <FieldArray
                            name="content"
                            render={({ insert, remove, push, unshift, form }) => (
                              <>
                                <Grid item style={{ display: 'flex', marginBottom: 20 }}>
                                  <Button
                                    variant="containedPrimary"
                                    onClick={() => setCards(cards.concat({ id:setIndex(), title: '', text: editor, type: 'text' }))}
                                    className={classes.AddButton}
                                  >
                                    Metin Ekle
                                  </Button>
                                  <Button
                                    variant="outlinedPrimary"
                                    style={{
                                      borderColor: '#ffba00',
                                      background: '#fff',
                                      marginLeft: 20,
                                      color: '#ffba00',
                                    }}
                                    onClick={() => push({ title: '', text: editor, kpi: '', type: 'graph' })}
                                    className={classes.AddButton}
                                  >
                                    Veri Bloğu Ekle
                                  </Button>
                                </Grid>
                                      <>
                                        <DndProvider backend={HTML5Backend}>
                                          {cards.map((card, index) => (
                                            <TextCard
                                              key={card.id}
                                              editor={editor}
                                              index={card.id}
                                              id={card.id}
                                              cards={cards}
                                              setCards={setCards}
                                              setFieldValue={props.setFieldValue}
                                              handleBlur={props.handleBlur}
                                              card={card}
                                              moveCard={moveCard}
                                            />
                                          ))}
                                        </DndProvider>
                                      </>
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid xs={12} container style={{ marginTop: 30 }} className={classes.whiteCard}>
                        <Typography variant="h5" style={{ width: '100%', marginBottom: 20 }}>
                          Rapor İçeriği
                        </Typography>
                        {detail.content && (
                          <>
                            {detail.content.map((con) =>
                              con.type === 'text' ? (
                                <Grid item xs={12}>
                                  <Typography variant="h6">{con.title}</Typography>
                                  <RichEditorReadOnly editorState={con.text} />
                                </Grid>
                              ) : (
                                <GraphComponent con={con} />
                              ),
                            )}
                          </>
                        )}
                      </Grid>
                    )}
                  </Form>
                </Grid>
              )}
            </Formik>
          </Grid>
        </main>
      </>
    );
}