import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import AdminStartups from './AdminStartups';

const CREATE_STARTUP_ADMIN = gql`
  mutation adminCreateStartup {
    adminCreateStartup
  }
`;
const ADD_NOTE = gql`
  mutation adminStartupAddNote($id: ID!, $note: String!) {
    adminStartupAddNote(id: $id, note: $note) 
  }
`;
const ADD_NOTE_USER = gql`
    mutation adminAddNoteOnUser($id: ID!, $note: String!) {
        adminAddNoteOnUser(id: $id, note: $note)
    }
`;

const DELETE_STARTUP = gql`
  mutation adminDeleteStartup($id: ID!) {
    adminDeleteStartup(id: $id) 
  }
`;
const HANDLE_STARTUP_IS_FEATURED = gql`
  mutation adminHandleStartupIsFeatured($startupId: ID!, $isFeatured: Boolean!) {
      adminHandleStartupIsFeatured(startupId: $startupId, isFeatured: $isFeatured) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_STARTUP_ADMIN, {
    name: 'adminCreateStartup',
  }),
  graphql(DELETE_STARTUP, {
    name: 'adminDeleteStartup',
  }),
  graphql(ADD_NOTE, {
    name: 'adminStartupAddNote',
  }),
  graphql(ADD_NOTE_USER, {
    name: 'adminAddNoteOnUser',
  }),
  graphql(HANDLE_STARTUP_IS_FEATURED, {
    name: 'adminHandleStartupIsFeatured',
  }),
)(AdminStartups);
export default JoinWithMutation;