import ReportEdit from './ReportEdit'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const EDIT_REPORT = gql`
  mutation startupReportEdit($id: ID!, $sendingText: String!, $status: String!,  $content: [startupReportContentInput]) {
    startupReportEdit(id: $id, sendingText: $sendingText, status: $status, content: $content) 
  }
`;

const CREATE_PREVIEW = gql`
  mutation startupReportPreview($listId: ID!, $id:ID, $sendingText: String!,  $content: [startupReportContentInput]) {
    startupReportPreview(listId: $listId, id: $id, sendingText: $sendingText, content: $content){
      id
      title
      sendingText
      content{
          type
          title
          text
          kpiData{
            date
            reached
          }
        }
    }
  }
`;

const EDIT_REPORT_LIST = gql`
  mutation startupReportListEdit($id: ID!,$title: String!, $startDate: String!, $dataFrequency: String!, $isPublic: String!, $personIds: [ID], $groupIds: [ID]) {
    startupReportListEdit(id: $id,title: $title, startDate: $startDate, dataFrequency: $dataFrequency, isPublic: $isPublic, personIds: $personIds , groupIds: $groupIds) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(EDIT_REPORT, {
    name: 'startupReportEdit',
  }),
  graphql(CREATE_PREVIEW, {
    name: 'startupReportPreview',
  }),
  graphql(EDIT_REPORT_LIST, {
    name: 'startupReportListEdit',
  }),
)(ReportEdit);
export default JoinWithMutation;