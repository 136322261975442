import CommentTab from './CommentTab'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const COMMENT_STARTUP = gql`
  mutation commentOnStartup(
    $startupId: ID!
    $comment: String!
  ) {
  commentOnStartup(
        startupId: $startupId
        comment: $comment
    )
  }
`;
const JoinWithMutation = compose(
  graphql(COMMENT_STARTUP, {
  name: 'commentOnStartup',
  }),
)(CommentTab);
export default JoinWithMutation;