import {withRouter} from "react-router-dom";
import React from "react";

const RouteChangeTracker = ({history}) => {
  const unlisten = history.listen((location, action) => {
    if (action === "PUSH") {
      if (localStorage.getItem("utm_campaign")) {
        location.search = `${location.search ? location.search + "&" : "?"}utm_campaign=${localStorage.getItem(
          "utm_campaign"
        )}`;

        unlisten();

        history.push(location);
      }

      if (localStorage.getItem("utm_medium")) {
        location.search = `${location.search ? location.search + "&" : "?"}utm_medium=${localStorage.getItem(
          "utm_medium"
        )}`;

        unlisten();

        history.push(location);
      }

      if (localStorage.getItem("utm_source")) {
        location.search = `${location.search ? location.search + "&" : "?"}utm_source=${localStorage.getItem(
          "utm_source"
        )}`;

        unlisten();

        history.push(location);
      }
    } else unlisten();
  });
  return <div />;
};

export default withRouter(RouteChangeTracker);
