import AgreementStage from './AgreementStage';
import CompleteProfileStage from './CompleteProfileStage';
import MainFormStage from './MainFormStage';
import PendingResultStage from './PendingResultStage';
import React from 'react';
import UploadDocumentsStage from './UploadDocumentsStage';

function FormSteps({ step, ...props }) {
  if (!step) return <CompleteProfileStage startup={props.startup} />;
  switch (step) {
    case 0:
      return <CompleteProfileStage startup={props.startup} />;
    case 1:
      return (
        <MainFormStage
          setFormStep={props.setFormStep}
          isAdmin={props.isAdmin}
          formData={props.formData}
          setDisableNextButton={props.setDisableNextButton}
          formikRef={props.formikRef}
          succesfullyEditedSnackbar={props.succesfullyEditedSnackbar}
          setSuccesfullyEditedSnackbar={props.setSuccesfullyEditedSnackbar}
        />
      );
    case 2:
      return (
        <UploadDocumentsStage
          registerNewspaperDocs={props.registerNewspaperDocs}
          mainContractDocs={props.mainContractDocs}
          taxPlateDocs={props.taxPlateDocs}
          operatingCertificateDocs={props.operatingCertificateDocs}
          setRegisterNewspaperDocs={props.setRegisterNewspaperDocs}
          setMainContractDocs={props.setMainContractDocs}
          setTaxPlateDocs={props.setTaxPlateDocs}
          setOperatingCertificateDocs={props.setOperatingCertificateDocs}
          setDisableNextButton={props.setDisableNextButton}
          succesfullyEditedSnackbar={props.succesfullyEditedSnackbar}
          setSuccesfullyEditedSnackbar={props.setSuccesfullyEditedSnackbar}
        />
      );
    case 3:
      return <AgreementStage />;
    case 4:
      return <PendingResultStage />;
  }
}

export default FormSteps;
