import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import GreenTag from '../greenTag.js';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  startupCard: {
    background: '#ffffff',
    minHeight: '310px',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    padding: 36,
    [theme.breakpoints.down('lg')]: {
      padding: 24,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
    },
  },
  titleBar: {
    width: 'fit-content',
    marginLeft: 8,
  },
  startupGrid: {
    height: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
  values: {
    color: '#28b1e7',
    wordBreak: 'break-word',
    marginTop: 5,
  },
  startupLogo: {
    width: 70,
    height: 70,
    [theme.breakpoints.down('lg')]: {
      width: 60,
      height: 60,
    },
    background: '#423eff',
    borderRadius: '10px',
  },
  img: {
    width: 45,
    height: 45,
    objectFit: 'contain',
    borderRadius: '12px',
  },
  Title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: 'left',
    color: '#172b4d',
    wordBreak: 'break-word',
  },
  favIcon: {
    cursor: 'pointer',
  },
  desc: {
    color: '#425466',
    wordBreak: 'break-word',
    fontSize: 14,
  },
  model: {
    color: '#777777',
    fontSize: 18,
    [theme.breakpoints.down('lg')]: {
      fontSize: 13,
    },
  },
}));

function RemoveFavDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, id } = props;

  const handleSubmit = () => {
    props.removeFavorite({ variables: { startupId: id } }).then((data) => {
      if (data.data.removeFavorite) {
        refetch();
        onClose(null);
        props.setNotFavorited(true);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Favoriden Kaldır
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            Bu şirketi favorilerinizden kaldırıyorsunuz. Bu işlemi gerçekleştirmek istediğinizden emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={6} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, minWidth:'90px', marginTop: 20 }}
                onClick={() => onClose(null)}
                variant="outlinedSecondary"
                fullWidth
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  marginTop: 20,
                  background: '#ff5757',
                  minWidth:'90px',
                  '&:hover': { background: '#ff5757' },
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
                fullWidth
              >
                Kaldır
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

RemoveFavDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default function StartupCard(props) {
  const [fav, setFav] = React.useState(null);
  const [isFavorited, setIsFavorited] = React.useState(null);
  const [remove, setRemove] = React.useState(false);
  const [notFavorited, setNotFavorited] = React.useState(false);

  const addFavorite = (id) => {
    props.addFavorite({ variables: { startupId: id } }).then((data) => {
      if (data.data.addFavorite) {
        props.refetch();
        setIsFavorited(true);
      }
    });
  };

  React.useEffect(() => {
    if (fav === null) {
      setFav(props.isFavorited);
    }
  }, [fav]);

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  const classes = useStyles();
  return (
    <Grid item md={6} lg={4} sm={6} xs={12} className={classes.startupGrid}>
      <Grid item container direction="column" justify="end" xs={12} className={classes.startupCard}>
        <RemoveFavDialog
          setNotFavorited={setNotFavorited}
          removeFavorite={props.removeFavorite}
          id={props.id}
          refetch={props.refetch}
          open={remove}
          onClose={() => setRemove(false)}
        />
        <Grid container direction="column" style={{ flexGrow: 1, minHeight: 263 }} justify="space-between">
          <Grid item>
            <Grid xs={12} container item justify="end">
              <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
                <Grid item>
                  {props.logo ? (
                    <img src={props.logo} className={classes.img} alt="logo" style={{ objectFit: 'contain' }}></img>
                  ) : (
                    <Grid
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#003CA5',
                      }}
                    >
                      <Typography variant="h4" style={{ color: '#fff' }}>
                        {props.name && props.name.charAt(0)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Link>
              <Grid container direction="column" className={classes.titleBar} xs>
                <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
                  <Typography variant="h5" align="left">
                    {props.name}
                  </Typography>
                </Link>
                {props.industry && (
                  <Typography variant="body2" align="left" style={{ fontSize: 12, color: '#425466' }}>
                    {props.industry}
                  </Typography>
                )}
                <Grid container spacing={1} style={{ marginTop: 4 }}>
                  {props.businessModelArray &&
                    props.businessModelArray.length > 0 &&
                    props.businessModelArray.map((model) => (
                      <Grid item>
                        <GreenTag title={model} />
                      </Grid>
                    ))}
                  {props.country && (
                    <Grid item>
                      <GreenTag title={props.country.title} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* {!props.isFavorited && <Typography style={{marginRight: 8}}>Favoriye Ekle</Typography>} */}
              {props.isFavorited ? (
                <StarIcon
                  onClick={() => setRemove(true)}
                  style={{ color: '#F9AB00', cursor: 'pointer', fontSize: 32 }}
                  className={classes.favIcon}
                />
              ) : (
                <StarIcon
                  onClick={() => addFavorite(props.id)}
                  className={classes.favIcon}
                  style={{ color: '#dddddd', cursor: 'pointer', fontSize: 32 }}
                />
              )}
            </Grid>
            {props.desc && props.desc !== '' ? (
              <Grid item style={{ marginTop: 20 }}>
                <Typography variant="body2" align="left" className={classes.desc}>
                  {truncateString(props.desc, 161)}
                </Typography>
              </Grid>
            ) : (
              <Grid item style={{ marginTop: 20 }}>
                <Typography variant="body2" align="left" className={classes.desc}>
                  {props.oneliner && truncateString(props.oneliner, 161)}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container justify="center" style={{ marginTop: 20 }}>
            <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
              <Button variant="outlinedPrimary"> Keşfet </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setIsFavorited(false)}
        open={isFavorited}
      >
        <Alert severity="success">Şirket favorilerinize eklendi.</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setNotFavorited(false)}
        open={notFavorited}
      >
        <Alert severity="success">Şirket favorilerinizden çıkartıldı.</Alert>
      </Snackbar>
    </Grid>
  );
}
