import gql from 'graphql-tag';

export const ALL_ACTIVE_INVESTMENT_SPRINT_PAGINATED = gql`
  query allActiveInvestmentSprintsPaginated(
    $orderBy: [String]
    $search: String
    $page: Int
    $pageSize: Int
    $minTicketSize: [String]
  ) {
    allActiveInvestmentSprintsPaginated(
      orderBy: $orderBy
      search: $search
      pagination: { page: $page, pageSize: $pageSize }
      filter: { minTicketSize: $minTicketSize }
    ) {
      count
      pageCount
      sprints {
        id
        sprintClosingDate
        sprintCurrencySymbol
        minTicketSize
        requiredInvestmentAmount
        currentInvestmentAmount
        currentCommitmentAmount
        oldAdditionalCommitment
        status
        startup {
          id
          slug
          title
          logo
          description
          oneliner
          foundationDate
          isFavorited
          businessModelArray
          industry
          city {
            id
            title
          }
          country {
            id
            title
          }
        }
      }
    }
  }
`;

export const ADMIN_ALL_INVESTMENT_SPRINT_PAGINATED = gql`
  query adminAllInvestmentSprintsPaginated(
    $orderBy: [String]
    $search: String
    $page: Int
    $pageSize: Int
    $status: [String]
  ) {
    adminAllInvestmentSprintsPaginated(
      orderBy: $orderBy
      search: $search
      pagination: { page: $page, pageSize: $pageSize }
      filter: { status: $status }
    ) {
      count
      pageCount
      sprints {
        id
        minTicketSize
        sprintCurrencySymbol
        sprintClosingDate
        requiredInvestmentAmount
        currentInvestmentAmount
        currentCommitmentAmount
        oldAdditionalCommitment
        status
        startup {
          id
          slug
          title
          logo
          description
          oneliner
          foundationDate
          isFavorited
          businessModelArray
          industry
          city {
            id
            title
          }
          country {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_FEATURED_INVESTMENT_SPRINTS = gql`
  query getFeaturedInvestmentSprintsPaginated($orderBy: [String], $search: String, $page: Int, $pageSize: Int) {
    getFeaturedInvestmentSprintsPaginated(
      orderBy: $orderBy
      search: $search
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      count
      pageCount
      sprints {
        id
        isFeatured
        sprintClosingDate
        sprintCurrencySymbol
        minTicketSize
        requiredInvestmentAmount
        currentInvestmentAmount
        currentCommitmentAmount
        oldAdditionalCommitment
        status
        startup {
          id
          slug
          title
          logo
          description
          oneliner
          foundationDate
          isFavorited
          businessModelArray
          industry
          city {
            id
            title
          }
          country {
            id
            title
          }
        }
      }
    }
  }
`;
