import { Grid, Typography } from '@material-ui/core';

import React from 'react';

function AgreementStage() {
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      <Grid item style={{ marginTop: 32 }}>
        <Typography variant="h1" style={{ fontWeight: '600' }}>
          Sözleşme Onayı
        </Typography>
      </Grid>
      <Grid item style={{ margin: '44px 32px', height: 362, overflowY: 'auto' }}>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8, textAlign: 'center' }}>
          Protokol
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          İşbu protokol ile bir tarafta Altayçeşme Mah. Çamlı Sk. Ofis Park İş Merkezi Apt. No:21/45 Maltepe/İstanbul
          adresinde mukim Startup Bilişim Teknoloji Danışmanlık Anonim Şirketi (Şirket) ile Girişimci, Şirket’in kurduğu
          ve işleticisi olduğu Platform üzerinden Yatırımcılarla iletişime geçilmesine ilişkin aşağıdaki hüküm ve
          koşulları kabul etmektedir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          1. Tanımlar Girişimci; Platform’a Üye olan Yatırımcılar hakkında bilgi almak ve Yatırımcılar ile iletişim
          kurmak için Platform’da hesap açan ve Platform’un sunduğu Hizmetlerden yararlanan gerçek veya halka açık
          olmama koşulu ile tüzel kişiyi, Gizlilik Politikası ve Kişisel Veriler Metni; Platform’da bulunan ve
          Kullanıcılar’ın onayladığı, Kullanıcılar’ın verilerinin ne şekilde ve ne amaçla işlendiğinin ve gizlilik
          politikalarının detaylandırıldığı metni, Hesap; Üye veya yetkili kişi için Şirket tarafından oluşturulan ve bu
          kişilerin bilgilerinin yer aldığı alan olan kullanıcı hesabını, Hizmet(ler); Bu Sözleşme’de ve Platform’da
          belirtilen hizmetlerin biri, birkaçı veya tamamını, Kullanıcı; Platform’a erişim sağlayan veya Hizmetler’den
          yararlanan Üye ve Ziyaretçi’nin ayrı ayrı birini, ikisini veya tamamını, Platform; Şirket’in sahibi olduğu
          https://www.startupborsa.com veya Şirket tarafından belirlenen başka bir alan adı altındaki internet sitesini
          ve elektronik muhteviyatını, Ücretlendirme; Şirket ve Girişimci arasında işbu protokol kapsamında imzalanacak
          olan, Şirket’in sunmuş olduğu hizmet karşılığında Platform üzerinden iletişime geçilen yatırımcılardan temin
          edilen yatırım miktarına göre hesaplanan bedelleri gösterir metni, Üye; Platform’a üye olarak Hizmetler’den
          yararlanan Girişimci veya Yatırımcı’nın ayrı ayrı birini veya ikisini, İfade eder.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2. Genel
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2.1. Girişimci; yatırım ihtiyacını karşılamak amacıyla Platform üzerinden Yatırımcılara duyuracağı yatırım
          talebi (“Yatırım Talebi”) ile başlayacak olan süreçte Yatırım Talebi ile ilgilenebilecek olan Yatırımcıları
          Girişimci’ye yönlendirmesi amacıyla Yatırım Talebi’nin yayımlanmasından itibaren Şirket’e 3 (üç) aylık bir
          süre (“Yatırım Süreci”) verecektir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2.2. Girişimci; Yatırım Süreci boyunca iletişim kuracağı ilgili Yatırımcılar ile yatırım öncesi tüm hazırlık
          müzakerelerini Platform üzerinden yürüteceğini, aynı şekilde, Yatırım Süreci’nde, yatırımın hazırlık ve
          müzakere aşamasından tamamlanmasına kadar geçecek olan tüm aşamalarında Yatırımcılar ile yapacağı görüşmeleri,
          pazarlıkları ve yatırıma ilişkin gerekli tüm iletişimi bu Platform üzerinden yapacağını, bu süreçte,
          Yatırımcı(lar) ile olan pazarlıkları ve müzakereleri hiçbir şekilde bu Platform haricinde yürütmeyeceğini ve
          aynı zamanda bu sürecin hiçbir aşamasında Şirket’i ve/veya Platform’u devre dışı bırakmayacağını kabul, beyan
          ve taahhüt etmektedir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2.3. Girişimci, Platform üzerinden iletişim kurduğu tüm Yatırımcılara Platform aracılığı ile ulaşabildiğinin
          bilincinde hareket etmektedir. Şirket’in aracı rolü dikkate alınarak, Girişimci, onay vermiş olduğu
          Ücretlendirme Metni’ne istinaden Platform üzerinden anlaştığı yatırımcılar ile olumlu bir şekilde yapılması
          kararlaştırılan Toplam Yatırım Tutarı üzerinden Şirket’in belli bir komisyon bedeli alacağını kabul ve beyan
          etmektedir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2.4. Girişimci, Yatırım Süreci’nde talep ettiği yatırım tutarının belli bir kısmını, Yatırım Süreci sırasında
          Platform haricinden iletişim kurduğu ve yatırım müzakereleri yaptığı bir yatırımcıdan (“Platform Harici
          Yatırımcı”) almış olduğu bir teklif (“Platform Harici Yatırım”) ile karşılayacak ise, bu halde Girişimci,
          ilgili tutarı karşılayacak olan Platform Harici Yatırımcı’yı da Platform’a yönlendirecek ve Şirket’in
          gerçekleşecek olan toplam yatırım bedeli üzerinden alacağı komisyon ücreti hesaplanırken Platform Harici
          Yatırım’ın da dikkate alınacağını Platform Harici Yatırımcı’ya bildirecektir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2.5. Ancak, Girişimci'nin, Yatırım Süreci’nin başlamasından önce Platform Harici Yatırımcı’dan bir yatırım
          taahhüdü veya bu yönde bir teklif almış olması halinde, bu durumu Şirket’in kabul edeceği şekilde ispatlaması
          halinde, Şirket’in toplam yatırım bedelinden alacağı komisyonun hesaplamasında Platform Harici Yatırım tutarı
          dikkate alınmayacaktır.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          2.6. Şirket tarafından Girişimciden gelecek talep doğrultusunda; yatırım raporu hazırlanması, yatırım sunumu
          (pitch deck) hazırlanması/iyileştirilmesi, sermaye artışı sürecinde ihtiyaç duyulan belgelere ilişkin
          yönlendirme yapılması gibi nitelik, kapsam ve şartları Şirket tarafından belirlenecek ek hizmetler (Ek
          Hizmetler) sunulabilir. Yatırım Sürecinin olumlu sonuçlanması ve 2.3’üncü madde uyarınca belirlenecek komisyon
          ücretinin ödenmesi halinde bu Ek Hizmetler için herhangi bir hizmet bedeli alınmayacak olup, komisyon
          ücretinin ödenmemesi halinde Şirket’in ayrıca fatura edeceği hizmet bedeli tutarları verilen Ek Hizmetler için
          Girişimciden tahsil edilecektir. Ek Hizmet tutarları Şirket tarafından Girişimciye hizmet alımı öncesinde
          bildirilecek ve Girişimci’nin onayını müteakiben faturalandırma işlemi yapılacaktır.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          3. Yatırım Sürecine İlişkin Diğer Taahhütler
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          3.1. Müzakere ettiği yatırımcılardan birinden (“Kabul Edilen Yatırımcı”) ilgili yatırım işlemi için bir
          yatırım teklifi (“İlk Teklif”) alması halinde,
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          a) Girişimci; Kabul Edilen Yatırımcı haricinde Platforma üye olan başka bir yatırımcıyı (“Diğer
          Yatırımcı(lar)”) aynı veya farklı koşullarda başka bir yatırım ve/veya pay devir işlemi (“Diğer İşlem”) için
          teşvik etmeyeceğini, Diğer İşlem hazırlığı veya Diğer İşlem’in gerçekleşmesi için görüşme yapmayacağını,
          müzakere etmeyeceğini veya İlk Teklif’i kabul etmesinin ardından, Diğer Yatırımcılar’dan herhangi birinin
          Diğer İşlem’e ilişkin müzakere yürütülmesi teklifini veya Diğer İşlem’e ilişkin yatırımın yapılmasına ilişkin
          teklifini kabul etmeyeceğini;
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          b) Aynı şekilde, Girişimci’nin Kabul Edilen Yatırımcı’nın teklif ettiği yatırımının başarılı bir şekilde
          tamamlanması için iyi niyet ve hakkaniyet kurallarına göre hareket edeceğini, Yatırımcı ile term sheet (ön
          protokol) hazırlayarak imzalayacağını, Girişimci tarafından tamamlanması gereken tüm işlemleri yerine
          getireceğini ve tüm çabasını bu yönde sarf edeceğini ve bu yönde gerekli adımları atacağını, yatırım süreci
          sonuçlanana kadar Şirket’i düzenli olarak bilgilendireceğini kabul, beyan ve taahhüt etmektedir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          c) Kabul Edilen Yatırımcı’nın İlk Teklif’i kabul edildikten sonra, Girişimci’nin Diğer Yatırımcılar ile Diğer
          İşlem’i yapmak için görüşmesi, müzakere etmesi ve ardından Diğer Yatırımcılar’dan herhangi birinin Diğer İşlem
          için teklif vermesi (“İkinci Teklif”) ve Girişimci’nin ise İkinci Teklif’i kabul etmesi halinde, Kabul Edilen
          Yatırımcı’nın İlk Teklif için yapmış olduğu müzakereler sürecinde çeşitli masraflar yaptığını, Kabul Edilen
          Yatırımcı’yı önemli bir ölçüde zarara uğratabileceğini ve Kabul Edilen Yatırımcı’nın tüm meydana gelen
          zararından doğrudan sorumlu olacağını kabul, beyan ve taahhüt etmektedir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          4. Girişimcinin Yatırım Teklifini Reddetme Koşulları
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          4.1. Girişimci, Platform üzerinden duyurduğu yatırım talebine ilişkin ilgili yatırımcılardan aldığı yatırım
          teklifini ancak aşağıda sayılan hallerde reddetme imkânına sahip olacaktır:
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          (a) Girişimci’nin, Yatırımcı ile aynı sektörde, Yatırımcı ile rekabet düzeyinde faaliyet gösteren veya
          Yatırımcı ile rekabet düzeyinde aynı faaliyet içerisinde bulunan başka bir şirkette çalışması,
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          (b) Girişimci’nin (a) maddesinde belirtilen şirkette pay sahibi olması veya bu şirketin yönetim organında yer
          alması,
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          (c) Girişimci’nin (a) maddesinde belirtilen şirket ile yakın dereceden ilişkili olması,
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          (d) Yatırımcının 5237 sayılı Türk Ceza Kanunu uyarınca bir suçtan dolayı hüküm giymiş olması veya bir suçtan
          dolayı hakkında soruşturma açılmış olması.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}></Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          4.2. Girişimci, yukarıdaki belirtilen sebepler dışında yatırımcının (“Reddedilen Yatırımcı”) yatırım teklifini
          reddetmesi halinde (“Reddedilen Yatırım”), Reddedilen Yatırımcı ile 12 aylık süre boyunca Platform haricinden
          Reddedilen Yatırım ile aynı veya farklı koşullarda herhangi bir yatırım, pay devri veya benzeri bir sonuç
          doğurabilecek herhangi bir işlem için yatırım sürecine girmeyeceğini, buna ilişkin müzakerelerde
          bulunmayacağını veya buna ilişkin iletişim kurmayacağını taahhüt eder. Girişimcinin, bu süre zarfında
          Reddettiği Yatırımcı ile aynı veya benzer koşullardaki bir yatırım turu için tekrar görüşme talebi olursa,
          Girişimci bu talebi Platform’a iletecektir.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          5. Şirket tarafından, Girişimcinin; yukarıda belirtilen yükümlülüklerinden herhangi birisine bir aykırılığı
          tespit edilirse, Şirket’in Girişimci’yi Platform üyeliğinden süresiz olarak çıkarma hakkının, sair mevzu
          tazmin haklarının saklı olduğunu kabul eder.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          6. Şirket tarafından, Girişimcinin; yukarıda belirtilen yükümlülüklerinden herhangi birine aykırılık tespit
          edilirse, Şirketin Girişimci’yi Platform üyeliğinden süresiz olarak çıkarma hakkını, sair mevzu tazmin
          haklarının saklı olduğunu kabul eder. Aynı şekilde Girişimci, Şirket’in mevzuata aykırı eylemde bulunduğunu
          tespit ederse veya işbu Sözleşme hükümlerinden bir veya birkaçına dair aykırılığını tespit ederse derhal
          sözleşmeyi haklı nedenle fesih hakkına sahiptir. Girişimci Sözleşmeyi haklı nedenle feshederse 4.2. maddesi
          ile bağlı olmayacaktır.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          7. Bu sözleşme girişimcinin Platforma kayıtlı olduğu sürece yürürlükte olup, yürürlük tarihinden sonra
          Sözleşme kendiliğinden son bulur. Girişimci Platform’dan dilediği zaman ayrılma hakkına sahip olup bu hususu
          noter kanalı ile Şirket’e bildirmesi ile işbu sözleşme başkaca ihtara veya ihbara gerek olmaksızın
          kendiliğinden son bulur.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8 }}>
          8. Bu sözleşmeden kaynaklanan veya bu sözleşmeyle ilişkili olan tüm uyuşmazlıklar, İstanbul Tahkim Merkezi
          Tahkim Kuralları uyarınca nihai olarak tahkim yoluyla çözümlenecektir. Tahkim yeri İstanbul’dur. Tahkim dili
          Türkçe’dir. Hakem sayısı 1 (bir) olacaktır.
        </Typography>
        <Typography variant="h5" style={{ fontSize: 13, fontWeight: 400, marginBottom: 8, textAlign: 'center' }}>
          Startup Bilişim Teknoloji Danışmanlık Anonim Şirketi
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AgreementStage;
