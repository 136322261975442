import { AuthContext, useAuth } from './context/auth';
import { Route as RRRoute, Redirect, Switch } from 'react-router-dom';
import React, { Fragment } from 'react';

import AdminCode from './Pages/AdminCode';
import AdminComments from './Pages/AdminComments';
import AdminCountryCity from './Pages/AdminCountryCity';
import AdminDrafts from './Pages/AdminDrafts';
import AdminFAQ from './Pages/AdminFAQ';
import AdminInvestOnStartup from './Pages/AdminInvestOnStartup';
import AdminInvestmentRequests from './Pages/AdminInvestmentRequests';
import AdminInvestors from './Pages/AdminInvestors';
import AdminKPI from './Pages/AdminKPI';
import AdminSecondaryShareList from './Pages/AdminSecondaryShareList';
import AdminStartupInvestmentRequests from './Pages/AdminStartupInvestmentRequests';
import AdminStartups from './Pages/AdminStartups';
import AdminUsers from './Pages/AdminUsers';
import Dashboard from './Pages/Dashboard';
import DashboardAdmin from './Pages/DashboardAdmin';
import DiscoverInvestors from './Pages/DiscoverInvestors';
import DiscoverStartups from './Pages/DiscoverStartups';
import DraftProfile from './Pages/DraftProfile';
import EditKPI from './Pages/EditKPI';
import FAQ from './Pages/FAQ';
import FavoriteStartups from './Pages/FavoriteStartups';
import FollowedStartups from './Pages/FollowedStartups';
import Followers from './Pages/Followers';
import ForgotPassword from './Pages/ForgotPassword';
import FourOwlFour from './Pages/FourOwlFour';
import GetInvestment from './Pages/GetInvestment';
import { Helmet } from 'react-helmet';
import Invest from './Pages/Invest';
import InvestmentRequests from './Pages/InvestmentRequests';
import InvestorProfile from './Pages/InvestorProfileShow';
import InvestorProfileCreate from './Pages/InvestorProfile';
import InvestorProfileOther from './Pages/InvestorProfileOther';
import InvestorProfileShowAdmin from './Pages/InvestorProfileShowAdmin';
import { LanguageContext } from './context/language';
import ListKPI from './Pages/ListKPI';
import ListKPIGA from './Pages/ListKPIGA';
import ListKPIParasut from './Pages/ListKPIParasut';
import Login from './Pages/Login';
import MyInvestmentOffer from './Pages/MyInvestmentOffer';
import MyInvestmentPortfolio from './Pages/MyInvestmentPortfolio';
import NewHeader from './Components/NewHeader';
import Notifications from './Pages/Notifications';
import ReportCreate from './Pages/ReportCreate';
import ReportDetail from './Pages/ReportDetail';
import ReportEdit from './Pages/ReportEdit';
import ReportList from './Pages/ReportList';
import ReportRequests from './Pages/ReportRequests';
import ReportShow from './Pages/ReportShow';
import ReportShowInvestor from './Pages/ReportShowInvestor';
import SetPassword from './Pages/SetPassword';
import Settings from './Pages/Settings';
import SignUp from './Pages/SignUp';
import SmsVerification from './Pages/SmsVerification';
import StartupLanding from './Pages/StartupLanding';
import StartupProfile from './Pages/StartupProfile';
import StartupProfilePublic from './Pages/StartupProfilePublic';
import StartupShare from './Pages/StartupShare';
import Test from './Pages/Test';
import VerifyEmail from './Pages/VerifyEmail';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import withTracker from './withTracker.js';
import Inbox from "./Pages/Inbox";
import { TempConversationProvider } from './context/tempConversation';

const renderComponent = DefaultComponent => {
  return DefaultComponent;
};

function Route({render, ...rest}) {
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const { loading, data } = LoggedInProfile();
  const userId = get(data, 'myProfile.id');
  const authRole = get(data, 'myProfile.role');
  const startupId = get(data, 'myProfile.startup.id');
  const userProfileId = get(data, 'myProfile.userProfileId');
  const ugCreatedReport = get(data, 'myProfile.ugCreatedReport');
  const getInvestorProfileStatus = get(data, 'myProfile.getInvestorProfileStatus');
  const startup = get(data, 'myProfile.startup');
  const getRole = get(data, 'myProfile.getRole');
  const startupSlug = get(data, 'myProfile.startup.slug');
  const isInvestor = get(data, 'myProfile.onBoardInfo.isInvestor');
  const firstName = get(data, 'myProfile.firstName');
  const lastName = get(data, 'myProfile.lastName');
  const currency = get(data, 'myProfile.currency');
  const [showSave, setShowSave] = React.useState('');
  // make this true to debug rendered routes
  if (process.env.NODE_ENV !== "production" && false) {
    console.log("Rendered:", rest);
  }
  return (
    <RRRoute
      {...rest}
      showSave={showSave}
      setShowSave={setShowSave}
      authToken={authToken}
      authRole={authRole}
      userId={userId}
      getRole={getRole}
      startupId={startupId}
      ugCreatedReport={ugCreatedReport}
      startup={startup}
      startupSlug={startupSlug}
      userProfileId={userProfileId}
      isInvestor={isInvestor}
      getInvestorProfileStatus={getInvestorProfileStatus}
      firstName={firstName}
      lastName={lastName}
      currency={currency}
      render={props => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        return render({
          authToken,
          authRole,
          currency,
          userId,
          startupId,
          startupSlug,
          firstName,
          lastName,
          startup,
          getRole,
          isInvestor,
          userProfileId,
          ugCreatedReport,
          setShowSave,
          showSave,
          getInvestorProfileStatus,
          ...props,
        });
      }}
    />
  );
}

function checkUserRole(route, role) {
  if (route === "InvestorRoute") {
    if (role === "Investor") {
      return true;
    }
  } else if (route === "AdminRoute") {
    if (role === "SuperAdmin" || role === "Admin") {
      return true;
    }
  } else if (route === "SuperAdminRoute") {
    if (role === "SuperAdmin") {
      return true;
    }
  }

  return false;
}

function AdminRoute({ component: Component, ...rest }) {
  const { loading, data, refetch, error } = LoggedInProfile();
  refetch().catch(() => <Redirect to="/" />);
  const authRole = get(data, 'myProfile.role');
  if (authRole === 'Member' && !window.location.pathname.includes('admin')) {
    return <MemberRoute path={rest.path} component={Component} />;
  }
  const userId = get(data, "myProfile.id");
  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (!error && checkUserRole('AdminRoute', authRole)) {
          return (
            <Fragment>
              {window.location.pathname !== '/verification' && window.location.pathname !== '/verify-email/' ? (
                <div style={{ display: 'flex' }}>
                  <>
                    {renderComponent(<NewHeader />)}
                    {/* <Helmet>
                      <script type='text/javascript'>{`!function(){var t=window;if('function'!=typeof t.InfosetChat){var n=document,e=function(){e.c(arguments)};e.q=[],e.c=function(t){e.q.push(t)},t.InfosetChat=e;function a(){var t=n.createElement('script');t.type='text/javascript',t.async=!0,t.src='https://cdn.infoset.app/chat/icw.js';var e=n.getElementsByTagName('script')[0];e.parentNode.insertBefore(t,e)}t.attachEvent?t.attachEvent('onload',a):t.addEventListener('load',a,!1)}}();
                        InfosetChat('boot',{widget:{apiKey:'KDGPYo2KKTvNXHZfq82NogCuupU1TNohzyqhZHXl'},visitor: {
                          firstName: "${get(data, 'myProfile.firstName')}",
                          lastName: "${get(data, 'myProfile.lastName')}",
                          phone: "${get(data, 'myProfile.phoneNumber')}",
                          email: "${get(data, 'myProfile.email')}",
                          company: "${get(data, 'myProfile.startup.title')}"
                          }});`}
                      </script>
                      */}
                    <Helmet>
                      <script type="text/javascript">
                        {`
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', 'UA-176581614-1');
                          gtag('set', {'user_id':${get(data, "myProfile.id")}});
                        `}
                      </script>
                    </Helmet>
                  </>

                  <Component {...props} />
                </div>
              ) : (
                <Component {...props} />
              )}
            </Fragment>
          );
        }
        return <Redirect to={`/?next=${window.location.pathname}`} />;
      }}
    />
  );
}

function MemberRoute({ component: Component, ...rest }) {
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');
  const startup = get(data, 'myProfile.startup');
  const seenFirstPopup = get(data, 'myProfile.seenFirstPopup');
  const getInvestorProfileStatus = get(data, 'myProfile.getInvestorProfileStatus');
  const [roleChange, setRoleChange] = React.useState(get(data, 'myProfile.getRole'));
  const RenderedComponent = Component;
  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (rest.path === "/verification" && seenFirstPopup) {
          return <Redirect to="/404" />;
        }
        if (rest.path === "/girisim-yarat" && startup) {
          return <Redirect to="/404" />;
        }
        if (authRole === "Member" || authRole === "Admin" || authRole === "SuperAdmin") {
          return (
            <Fragment>
              {window.location.pathname !== '/verification' && window.location.pathname !== '/verify-email/' ? (
                <div style={{ display: 'flex' }}>
                  <>
                    {renderComponent(<NewHeader {...props} setRoleChange={setRoleChange} />)}

                    {/* <Helmet>
                      <script type='text/javascript'>{`!function(){var t=window;if('function'!=typeof t.InfosetChat){var n=document,e=function(){e.c(arguments)};e.q=[],e.c=function(t){e.q.push(t)},t.InfosetChat=e;function a(){var t=n.createElement('script');t.type='text/javascript',t.async=!0,t.src='https://cdn.infoset.app/chat/icw.js';var e=n.getElementsByTagName('script')[0];e.parentNode.insertBefore(t,e)}t.attachEvent?t.attachEvent('onload',a):t.addEventListener('load',a,!1)}}();
                        InfosetChat('boot',{widget:{apiKey:'KDGPYo2KKTvNXHZfq82NogCuupU1TNohzyqhZHXl'},visitor: {
                          firstName: "${get(data, 'myProfile.firstName')}",
                          lastName: "${get(data, 'myProfile.lastName')}",
                          phone: "${get(data, 'myProfile.phoneNumber')}",
                          email: "${get(data, 'myProfile.email')}",
                          company: "${get(data, 'myProfile.startup.title')}"
                          }});`}
                      </script>
                      */}
                    <Helmet>
                      <script type="text/javascript">
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'UA-176581614-1');
                        gtag('set', {'user_id':${get(data, "myProfile.id")}});
                      `}
                      </script>
                    </Helmet>
                  </>
                  <RenderedComponent {...props} roleChange={roleChange} setRoleChange={setRoleChange} />
                </div>
              ) : (
                <RenderedComponent {...props} roleChange={roleChange} setRoleChange={setRoleChange} />
              )}
            </Fragment>
          );
        }
        return <Redirect to="/" />;
      }}
    />
  );
}
function AuthenticationRoute({component: Component, ...rest}) {
  const {loading, data} = LoggedInProfile();
  const authRole = get(data, "myProfile.role");
  const userId = get(data, "myProfile.id");
  return (
    <Route
      {...rest}
      render={props => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (!userId) {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}

function RedirectWithUserRole({ next, ...rest }) {
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');

  function getRedirectRoute() {
    if (authRole === "Member") {
      return "/dashboard";
    } else if (authRole === "Admin" || authRole === "SuperAdmin") {
      return "/dashboard-admin";
    }
    if (next) {
      return '/login/?next=' + next;
    }
    return "/login";
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authRole !== '' ? (
          <Redirect to={{ pathname: getRedirectRoute(), state: props.location.state }} {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function LoggedInProfile() {
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const MY_PROFILE = gql`
    query myProfile {
      myProfile {
        id
        role
        getRole
        firstName
        lastName
        ugCreatedReport
        email
        phoneNumber
        getInvestorProfileStatus
        userProfileId
        onBoardInfo {
          isInvestor
        }
        startup {
          id
          title
          oneliner
          logo
          businessModelArray
          profileFillRate
          website
          currency
          slug
        }
      }
    }
  `;
  return useQuery(MY_PROFILE, {
    fetchPolicy: 'network-only',
    variables: {},
  });
}
function Routes() {
  const {data} = LoggedInProfile();
  const [next, setNext] = React.useState();
  const [isStaging, setIsStaging] = React.useState(window.location.href.indexOf('dashboard.startupborsa') < 0);
  if (window.location.href.includes('next') && !next) {
    setNext(window.location.href.split('=')[1], 'href');
  }
  const authRole = get(data, "myProfile.role");
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const globalLanguage = localStorage.getItem("tr_lang") ? localStorage.getItem("tr_lang") : "tr";
  const setToken = data => {
    localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, data);
  };
  const setLanguage = data => {
    localStorage.setItem("tr_lang", data);
  };
  return (
    <AuthContext.Provider value={{authToken, setAuthToken: setToken}}>
      <LanguageContext.Provider value={{globalLanguage, setGlobalLanguage: setLanguage}}>
        <TempConversationProvider>
          <Switch>
            <Route path="/404" component={FourOwlFour} />
            <Route path="/test" component={Test} />
            <Route path="/set-password" component={SetPassword} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/verify-email" component={VerifyEmail} />
            {!authToken && <Route path="/yatirimci/:slug" component={InvestorProfileOther} />}
            {!authToken && <Route path="/girisim/:slug" component={StartupProfilePublic} />}

            <AuthenticationRoute path="/login" component={Login} />
            <AuthenticationRoute path="/sign-up" component={SignUp} />

            {/*ADMIN AND SUPERADMIN*/}
            <AdminRoute path="/test" component={Test} />
            <AdminRoute path="/dashboard-admin" component={DashboardAdmin} />
            <AdminRoute path="/dashboard" component={Dashboard} />
            <AdminRoute path="/admin/girisimler" component={withTracker(AdminStartups)} />
            <AdminRoute path="/admin/kod-gönder" component={withTracker(AdminCode)} />
            <AdminRoute path="/admin/yorumlar" component={withTracker(AdminComments)} />
            <AdminRoute path="/admin/kullanicilar" component={withTracker(AdminUsers)} />
            <AdminRoute path="/admin/yatirimcilar" component={withTracker(AdminInvestors)} />
            <AdminRoute path="/admin/yatirim-basvurulari" component={withTracker(AdminInvestmentRequests)} />
            <AdminRoute path="/admin/yatirim-turlari" component={withTracker(Invest)} />
            <AdminRoute path="/admin/yatirim-turu/:id" component={withTracker(AdminStartupInvestmentRequests)} />
            <AdminRoute path="/admin/yatirimci-arayanlar/:id" component={withTracker(AdminInvestOnStartup)} />
            <AdminRoute path="/admin/yatirimci/:slug" component={withTracker(InvestorProfileShowAdmin)} />
            <AdminRoute path="/admin/draftlar" component={AdminDrafts} />
            <AdminRoute path="/admin/kpi" component={AdminKPI} />
            {/*<AdminRoute path="/admin/hisse-liste" component={AdminSecondaryShareList} />*/}
            <AdminRoute path="/verification" component={SmsVerification} />
            <AdminRoute path="/admin/SSS" component={AdminFAQ} />
            <AdminRoute path="/admin/ulke-sehir" component={AdminCountryCity} />
            <AdminRoute path="/girisimler" component={DiscoverStartups} />
            <AdminRoute path="/yatirimcilar" component={DiscoverInvestors} />
            <AdminRoute path="/yatirimci/:slug" component={InvestorProfileOther} />
            <AdminRoute path="/girisim/:slug" component={StartupProfile} />
            <AdminRoute path="/draft-edit/:id" component={DraftProfile} />
            <AdminRoute path="/draft/:id" component={DraftProfile} />
            <AdminRoute path="/favorilerim" component={FavoriteStartups} />
            <AdminRoute path="/tekliflerim" component={MyInvestmentOffer} />
            <AdminRoute path="/portfoyum" component={MyInvestmentPortfolio} />

            <AdminRoute path="/yatirimci-profilim" component={withTracker(InvestorProfile)} />
            <AdminRoute path="/yatirimci-profili-yarat" component={InvestorProfileCreate} />
            <AdminRoute path="/basvurular" component={withTracker(InvestmentRequests)} />
            <AdminRoute path="/kisilerim" component={Followers} />
            <AdminRoute path="/ayarlar" component={Settings} />
            <AdminRoute path="/takip-ettiklerim" component={FollowedStartups} />
            {isStaging ? <AdminRoute path="/verilerim/ga" component={ListKPIGA} /> : null}
            {isStaging ? <AdminRoute path="/verilerim/parasut" component={ListKPIParasut} /> : null}
            <AdminRoute path="/verilerim" component={ListKPI} />
            <AdminRoute path="/veri-duzenle/:id" component={EditKPI} />
            <AdminRoute path="/bildirimler" component={Notifications} />
            <AdminRoute path="/rapor-ekle/:id" component={ReportCreate} />
            <AdminRoute path="/raporlarim" component={ReportList} />
            <AdminRoute path="/rapor-detay/:id" component={ReportDetail} />
            <AdminRoute path="/rapor-duzenle/:id" component={ReportEdit} />
            <AdminRoute path="/rapor-goruntule/:id" component={ReportShow} />
            <AdminRoute path="/rapor-icerigi/:id" component={ReportShowInvestor} />
            <AdminRoute path="/rapor-talepleri" component={ReportRequests} />
            <AdminRoute path="/girisim-yarat" component={StartupLanding} />
            <AdminRoute path="/SSS" component={FAQ} />
            <AdminRoute path="/mesajlar/:conversationId?" component={Inbox} />

            {/*MEMBER*/}
            <MemberRoute path="/verification" component={SmsVerification} />
            <MemberRoute path="/bildirimler" component={Notifications} />
            <MemberRoute path="/dashboard" component={withTracker(Dashboard)} />
            <MemberRoute path="/girisimler" component={withTracker(DiscoverStartups)} />
            <MemberRoute path="/girisim/:slug" component={withTracker(StartupProfile)} />
            <MemberRoute path="/yatirimci-profili-yarat" component={withTracker(InvestorProfileCreate)} />
            <MemberRoute path="/yatirimci-profilim" component={withTracker(InvestorProfile)} />
            <MemberRoute path="/yatirimci-arayanlar/one-cikan-girisimler" component={withTracker(Invest)} />
            <MemberRoute path="/yatirimci-arayanlar" component={withTracker(Invest)} />
            <MemberRoute path="/favorilerim" component={withTracker(FavoriteStartups)} />
            <MemberRoute path="/basvurular" component={withTracker(InvestmentRequests)} />
            <MemberRoute path="/ayarlar" component={Settings} />
            <MemberRoute path="/takip-ettiklerim" component={FollowedStartups} />
            <MemberRoute path="/SSS" component={FAQ} />
            <MemberRoute path="/mesajlar:conversationId?" component={Inbox} />
            <MemberRoute path="/yatirim-turu" component={withTracker(GetInvestment)} />
            {/*<MemberRoute path="/hisse-al-sat" component={withTracker(StartupShare)} />*/}

            <RedirectWithUserRole exact path="/" next={next} />
            <Route component={FourOwlFour} />
          </Switch>
        </TempConversationProvider>
      </LanguageContext.Provider>
    </AuthContext.Provider>
  );
}

export default Routes;
