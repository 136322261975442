/* Source : 
 - https://codesandbox.io/s/72py4jlll6?file=/src/index.js:0-2841 
 - https://www.npmjs.com/package/react-image-crop
*/

/*
 Notes : 

 -If image will not showing as a preview, the "directlyUpdate" props should be use
 - directlyUpdate can be updateCommunity, updateUser, updateProject etc.


*/

import 'react-image-crop/dist/ReactCrop.css';
import { set } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

class ImageCrop extends PureComponent {
  state = {
    crop: {
      unit: '%',
      width: 100,
      aspect:1
    },
    error: false,
    imageUrl: '',
    openDialog: false,
    src: null,
    newFile: null
  };

  onSelectFile = async file => {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      await reader.readAsDataURL(file);
  };

  srcFunc = async (file) => {
   await this.onSelectFile(file)
   return this.state.src
};

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      const base64Image = canvas.toDataURL('image/jpeg');
      this.setState({ imageUrl: base64Image });
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));

          return;
        }
        blob.name = fileName;
        this.setState({ newFile: blob });
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.state.crop = {
      unit: '%',
      width: 100,
      aspect:1
    }
    this.props.setOpenPhoto(false)
  };

  saveImg = croppedImage => {
    let file;
    if(this.props.profilePhoto){
      file = new File([this.state.newFile], `${this.props.profilePhoto[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`profilePhoto.0`, file)
    }
    if(this.props.backgroundPhoto){
      file = new File([this.state.newFile], `${this.props.backgroundPhoto[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`backgroundPhoto.0`, file)
    }
    else if(this.props.logo){
      file = new File([this.state.newFile], `${this.props.logo[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`logo.0`, file)
    }
    else if(this.props.founder){
      file = new File([this.state.newFile], `${this.props.founder.logo[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`founders.${this.props.index}.logo.0`, file)
    }
    else if(this.props.competitor){
      file = new File([this.state.newFile], `${this.props.competitor.logo[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`competitors.${this.props.index}.logo.0`, file)
    }
    else if(this.props.customer){
      file = new File([this.state.newFile], `${this.props.customer.logo[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`customers.${this.props.index}.logo.0`, file)
    }
    else if(this.props.investor){
      file = new File([this.state.newFile], `${this.props.investor.logo[0].name}`, {type:"image/jpeg"});
      file.preview = croppedImage;
      this.props.setFieldValue(`investors.${this.props.index}.logo.0`, file)
    }
    this.props.setOpenPhoto(false)
  };

  openFileUpload = () => {
    document.getElementById('imageSelect').click();
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const DialogTitle = withStyles(styles)(props => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    const DialogActions = withStyles(theme => ({
      root: {
        margin: 0,
        padding: '8px 24px',
      },
    }))(MuiDialogActions);

    return (
      <Grid>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.openPhoto}
          maxWidth={'md'}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Fotoğrafı Düzenle
          </DialogTitle>
          <DialogContent
            style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}
          >
            <div>
              
              {this.props.files.length > 0 && 
              <>
              {this.srcFunc(this.props.files[0]) && 
              <ReactCrop
                src={this.state.src}
                circularCrop={this.props.circularCrop}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              }
              </>
            }

              {/*   {croppedImageUrl && (
                <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
              )} */}
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              onClick={() => this.saveImg(croppedImageUrl)}
              color="primary"
            >
              Uygula
            </Button>
          </DialogActions>
        </Dialog>
        {/* <div>
          <input
            id={this.props.id ? this.props.id : 'imageSelect'}
            type="file"
            accept="image/*"
            onChange={event => {
              this.onSelectFile(event);
            }}
            onClick={event => {
              event.target.value = null;
            }}
          />
        </div> */}
      </Grid>
    );
  }
}
export default ImageCrop
