import { Grid, Hidden, Typography } from '@material-ui/core';

import GenericStepper from '../../../Components/GenericStepper';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  leftContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginTop: 210,
    },
  },
  middleLine: {
    width: 0,
    marginRight: 84,
    height: '100%',
    border: '1px solid #EDF2F7',
    borderRadius: 50,
  },
}));
function PendingResultStage() {
  const classes = useStyles();
  return (
    <Grid container>
      {/*Left Side*/}
      <Grid xs={12} md={6} item container className={classes.leftContainer}>
        <Grid item style={{ marginBottom: 30 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
            <circle cx="58" cy="58" r="58" fill="#F0F5FF" />
            <path
              d="M51 58L55.6667 62.6667L65 53.3333M79 58C79 60.7578 78.4568 63.4885 77.4015 66.0364C76.3461 68.5842 74.7993 70.8992 72.8492 72.8492C70.8992 74.7993 68.5842 76.3461 66.0364 77.4015C63.4885 78.4568 60.7578 79 58 79C55.2422 79 52.5115 78.4568 49.9636 77.4015C47.4158 76.3461 45.1008 74.7993 43.1508 72.8492C41.2007 70.8992 39.6539 68.5842 38.5985 66.0364C37.5432 63.4885 37 60.7578 37 58C37 52.4305 39.2125 47.089 43.1508 43.1508C47.089 39.2125 52.4305 37 58 37C63.5695 37 68.911 39.2125 72.8492 43.1508C76.7875 47.089 79 52.4305 79 58Z"
              stroke="#003CA5"
              stroke-width="4.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Grid>
        <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Typography variant="h1" style={{ fontSize: 28, fontWeight: 'bold' }}>
            Talebiniz İletildi
          </Typography>
          <Typography variant="span" style={{ fontSize: 16 }}>
            Yatırım turu bilgileriniz hesap <br /> yöneticisine iletilmiştir. Hesap yöneticimiz <br /> en kısa sürede
            sizinle iletişime geçecektir.
          </Typography>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid item className={classes.middleLine}></Grid>
      </Hidden>
      {/*Stepper*/}
      <Grid xs={12} md item container justify="center">
        <GenericStepper
          header="Süreç"
          description="Startup Borsa, yatırım ile yolculuğunu büyütmeyi hedefleyen teknoloji girişimlerinin yatırımcılara erişim sağlamalarına yardımcı olur ve iletişim sürecini destekler."
          stepsJsonArray={[
            { isFinished: true, title: 'Şirket Profilini Doldur ve Listelen', text:'Girişimlerin uygun yatırımcıya erişebilmesi için profilinin doluluk oranının yüksek olması gerekir.' },
            { isFinished: false, title: 'Yatırım Turu Bilgilerini Ekle', text:'Yatırımcıların tur hakkındaki bilgilere erişebilmesi için detayları girin.' },
            { isFinished: false, title: 'Hesap Yöneticisi ile Görüş', text:'Uygun yatırımcılara erişebilmen ve rehberlik için temsilcimiz size ulaşacaktır.' },
            { isFinished: false, title: 'Sıradaki Yatırımcınla Tanış', text:'Yatırım arayan girişimlerde yer alarak öne çık ve sıradaki yatırımcınla tanış.' },
          ]}
          bottomJSX={
            <Typography
              variant="h5"
              style={{
                width: '100%',
                fontSize: 14,
                color: '#A0AEC0',
                fontStyle: 'normal',
                fontWeight: '600',
                textAlign: 'center',
                fontFeatureSettings: 'salt on',
              }}
            >
              Sorularınız mı var?
              <a type="button" style={{ fontSize: 14, color: '#003CA5', fontWeight: '600' }}>
                Bizimle iletişime geçin
              </a>
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
}

export default PendingResultStage;
