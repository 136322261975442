import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import AdminDrafts from './AdminDrafts';

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
)(AdminDrafts);
export default JoinWithMutation;