
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import {DebounceInput} from 'react-debounce-input';
import newSortingIcon from '../../static/icon/newSortingIcon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import 'moment/locale/tr'
import moment from 'moment'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField } from 'formik';
import FormikTextField from "../../Components/FormikTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import { Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Popover from '@material-ui/core/Popover';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Image from '../../static/img/404.png';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newEye from '../../static/icon/new-eye.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import filterIcon from '../../static/icon/filterIcon.svg';
import { useTranslation } from 'react-i18next';
import newCross from '../../static/icon/new-cross.svg';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  TabsGeneral:{
    background: '#fff',
    borderTopLeftRadius:16,
    borderTopRightRadius:16,
    paddingLeft:30
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  multiSelect: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  multiSelect2: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1"
    },
  },
  CheckText: {
    wordBreak:'break-all',
    marginBottom:10,
    '& span': {
      fontSize:15,
      lineHeight:1.2
    }
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filterPadding: {
    paddingLeft:'30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft:'0',
      marginTop:10
    },
  },
  filter: {
    height:40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myPaper2: {
    padding:25,
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    padding: 25,
    marginBottom:50,
  },
  tabPaper: {
    marginTop:50,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:10,
    padding: '16px 0px 16px 24px',
  },
  body: {
    fontSize: 14,
    padding: '16px 0px 16px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

const FormikStartupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10,minHeight:80}}
          {...props}
          {...field}
          options={props.options}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder={'Lütfen Seçin'}  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

function AddStartupDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;
  const [startups2, setStartups2] = React.useState();
  const [create, setCreate] = React.useState(false);

  const handleSubmit = variables => {
    variables.startupId = variables.startupId ? variables.startupId.id : null
    props
      .createStartupFollowRequest({ variables })
      .then((data) => {
        if (data.data.createStartupFollowRequest) {
          props.refetch();
          onClose(null);
          props.setReportSent(true);
        } else {
          props.setReportError(true);
        }
      })
      .then(() => {})
      .catch((error) => {
        props.setReportError(true);
        onClose(null);
      });
  };

  
  const ALL_STARTUPS = gql`
  query allStartupsPaginate($page: Int, $pageSize: Int) {
      allStartupsPaginate(pagination:{page:$page, pageSize:$pageSize}){
          count
          pageCount
          startups{
              id
              title
          }
      }
  }
  `;
    const { loading, data, refetch} = useQuery(ALL_STARTUPS, {
      fetchPolicy:'network-only',
      variables: {page:1, pageSize:1000 },
  });

  React.useEffect(() => {
    if(!loading){
        if(data){
            setStartups2(data.allStartupsPaginate.startups);
        }
    }
},
[data, loading])

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding:'30px 30px 0 0'}}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor:'pointer' }}/>
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Rapor Talep Et
        </Typography>

      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20 }} xs={10}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            Görüntüleme onayı hakkında buraya gelecek iki satırlık yazı alanı.
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          startupId: '',
          title: '',
          website: '',
          email: '',
          nameSurname: '',
          note: '',
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <>
            <Grid container xs={12} justify="center">
              <Grid
                container
                style={{
                  borderRadius: 4,
                  backgroundColor: '#f4f9ff',
                  marginBottom: 20,
                  padding: 10,
                  width: 'fit-content',
                }}
              >
                <Grid item>
                  {!create ? (
                    <Button variant="containedPrimary" style={{ padding: '11.5px 37px', fontSize: 16 }}>
                      Şirket Seç
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setCreate(false)}
                      style={{ backgroundColor: '#f4f9ff', padding: '11.5px 37px', fontSize: 16, fontWeight: 500 }}
                    >
                      Şirket Seç
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {create ? (
                    <Button variant="containedPrimary" style={{ padding: '11.5px 36px', fontSize: 16 }}>
                      Şirket Ekle
                    </Button>
                  ) : (
                    <Button
                      onClick={() => (setCreate(true), props.values.startupId = null)}
                      style={{ backgroundColor: '#f4f9ff', padding: '11.5px 36px', fontSize: 16 }}
                    >
                      Şirket Ekle
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
              <Grid container justify="space-between" xs={9}>
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      {create ? (
                        <>
                          <Grid item container xs={12}>
                            <Typography variant="h6" style={{ width: '100%' }}>
                              Şirket Adı
                            </Typography>
                            <FormikTextField
                              name="title"
                              style={{ width: '100%', marginBottom: 20 }}
                              type="text"
                              placeholder="Şirket Adı"
                            />
                          </Grid>
                          <Grid item container xs={12} style={{ height: 'fit-content' }}>
                            <Typography variant="h6" style={{ width: '100%' }}>
                              Şirket Web Sitesi
                            </Typography>
                            <FormikTextField
                              name="website"
                              style={{ width: '100%', marginBottom: 20 }}
                              type="text"
                              placeholder="https://"
                            />
                          </Grid>
                          <Grid item container xs={12} style={{ height: 'fit-content' }}>
                            <Typography variant="h6" style={{ width: '100%' }}>
                              Şirket Yetkilisi Adı Soyadı
                            </Typography>
                            <FormikTextField
                              name="nameSurname"
                              style={{ width: '100%', marginBottom: 20 }}
                              type="text"
                              placeholder="Lütfen Doldurunuz"
                            />
                          </Grid>
                          <Grid item container xs={12} style={{ height: 'fit-content' }}>
                            <Typography variant="h6" style={{ width: '100%' }}>
                              Şirket Yetkilisi Emaili
                            </Typography>
                            <FormikTextField
                              name="email"
                              style={{ width: '100%', marginBottom: 20 }}
                              type="email"
                              placeholder="E-mailinizi giriniz"
                            />
                          </Grid>
                        </>
                      ) : (
                        startups2 && (
                          <Grid xs={12} style={{ marginBottom: 35 }} item>
                            <Typography variant="h6" style={{ fontWeight: 500 }}>
                              Şirket Adı
                            </Typography>
                            <Field
                              component={FormikStartupDropdown}
                              style={{ background: '#fff', marginTop: 10 }}
                              textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                              name="startupId"
                              options={startups2}
                            ></Field>
                          </Grid>
                        )
                      )}
                      <Grid item container xs={12} style={{ height: 'fit-content' }}>
                        <Typography variant="h6" style={{ width: '100%' }}>
                          Kısa Not
                        </Typography>
                        <FormikMultiLine
                          style={{ width: '100%', marginBottom: 20 }}
                          name="note"
                          rows={4}
                          type="text"
                          placeholder="Açıklama.."
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', width: '100%' }}
                          onClick={() => handleSubmit(props.values)}
                        >
                          Gönder
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

AddStartupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function ExpandingRow(props){
  const [open,setOpen] = React.useState(false);
  const { startup } = props;

  return (
    <>
      <StyledTableRow key={startup.startup.title}>
        <StyledTableCell component="th" scope="row">
          {startup.startup.logo ? (
            <img
              src={startup.startup.logo}
              alt="logo"
              style={{
                width: 32,
                height: 32,
                borderRadius: '4px',
                marginRight: 20,
                objectFit: 'cover',
                border: 'solid 1px #eaedf3',
              }}
            />
          ) : (
            <Grid
              style={{
                width: 32,
                height: 32,
                borderRadius: '4px',
                display: 'flex',
                marginRight: 20,
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
              }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                {startup.startup.title && startup.startup.title.charAt(0)}
              </Typography>
            </Grid>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
            <Typography variant="h6" >
              <Link to={`girisim/${startup.startup.slug}`} style={{ fontSize: 14, fontWeight:500, padding:0 }}>
                {startup.startup.title}
              </Link>
            </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{startup.startup.website}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{startup.startup.industry ? startup.startup.industry[0] : '-'}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          {startup.status === 'Approved' && (
            <Typography style={{ color: '#28d09a' }} variant="body2">
              Onaylanmış
            </Typography>
          )}
          {startup.status === 'Pending' && (
            <Typography style={{ color: '#ffba00' }} variant="body2">
              Onay Bekleyen
            </Typography>
          )}
          {startup.status === 'Rejected' && (
            <Typography style={{ color: '#ff5757' }} variant="body2">
              Reddedilmiş
            </Typography>
          )}
        </StyledTableCell>
        <StyledTableCell onClick={() => setOpen(!open)} align="left">
          {startup.status === 'Approved' &&
            (open ? (
              <ExpandLessIcon color="primary" style={{ cursor: 'pointer', transition: '0.5s' }} />
            ) : (
              <ExpandMoreIcon color="primary" style={{ cursor: 'pointer', transition: '0.5s' }} />
            ))}
        </StyledTableCell>
      </StyledTableRow>

      {open &&
        startup.startup.requestedReports &&
        startup.startup.requestedReports.map((report, index) => (
          <>
            <StyledTableRow style={{ background: '#fff' }} key={report.title}>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography variant="h6" color="primary" style={{ fontSize: 14 }}>
                  {index + 1}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography variant="h6" style={{ fontSize: 14 }}>
                  {report.title}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left"></StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Link style={{ padding: 0 }} to={`/rapor-icerigi/${report.id}`}>
                  <Typography
                    color="primary"
                    align="left"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    variant="body2"
                  >
                    Raporu Görüntüle
                  </Typography>
                </Link>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left"></StyledTableCell>
            </StyledTableRow>
          </>
        ))}
    </>
  );
}

function ExpandingRowList(props){
  const [open,setOpen] = React.useState(false);
  const { list } = props;

  return(
    <>
     <StyledTableRow key={list.id}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{list.title}</Typography>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{list.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{list.dataFrequency}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{moment(parseInt(list.startDate)).locale('tr').format('Do MMMM YYYY')}</Typography></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell onClick={() => setOpen(!open)} align="left">{open ?<ExpandLessIcon color="primary" style={{cursor:'pointer',transition:'0.5s'}} /> : <ExpandMoreIcon color="primary"  style={{cursor:'pointer',transition:'0.5s'}} />}</StyledTableCell>                                  
      </StyledTableRow>
      {open && list.items.map((item) => (
      <>
      <StyledTableRow style={{background:'#fff'}} key={item.title}>
        <StyledTableCell style={{border:'none'}} align="left"><Typography variant="h6" color="primary" style={{fontSize:14}}>{item.title}</Typography></StyledTableCell>
        <StyledTableCell style={{border:'none'}} align="left"></StyledTableCell>
        <StyledTableCell style={{border:'none'}} align="left"></StyledTableCell>
        <StyledTableCell style={{border:'none'}} align="left">
          <Link style={{padding:0}} to={`/rapor-icerigi/${item.id}`}>
            <Typography color="primary" style={{textDecoration:'underline',cursor:'pointer'}} variant="body2">Raporu Görüntüle</Typography>
          </Link>
        </StyledTableCell>
        <StyledTableCell style={{border:'none'}} align="left"></StyledTableCell>  
      </StyledTableRow>
      </>
      ))
      }
    </>
  )
}

export default function FollowedStartups(props){
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageList, setPageList] = React.useState(1);
    const [pageCountList, setPageCountList] = React.useState(1);
    const [pageSizeList, setPageSizeList] = React.useState(6);
    const [startups, setStartups] = React.useState();
    const [lists, setLists] = React.useState();
    const [search, setSearch] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addStartup, setAddStartup] = React.useState(false);
    const [reportSent, setReportSent] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [orderFollowed, setOrderFollowed] = React.useState("");
    const [sortFollowed, setSortFollowed] = React.useState([]);
    const [orderInvestorReport, setOrderInvestorReport] = React.useState("");
    const [sortInvestorReport, setSortInvestorReport] = React.useState([]);
    const [filter, setFilter] = React.useState("Statüsüne Göre");
    const [status, setStatus] = React.useState([]);
    const [sector, setSector] = React.useState([]);
    const [reportError, setReportError] = React.useState(false);
    const { t } = useTranslation();
    const [anchorElSort, setAnchorElSort] = React.useState(null);

    const handleClickSort = (event) => {
      setAnchorElSort(event.currentTarget);
    };

    const handleCloseSort = () => {
      setAnchorElSort(null);
    };

    const openPopSort = Boolean(anchorElSort);
    const idSort = openPopSort ? 'simple-popover' : undefined;

    const optionsFollowed = [
      {name:'A-Z', value:'startup__title__DESC'},
      {name:'Z-A', value:'startup__title__ASC'},
    ];
    const optionsInvestorReport = [
      {name:'Rapor Adına Göre A’dan Z’ye', value:'title__ASC'},
      {name:'Rapor Adına Göre Z’den A’ya', value:'title__DESC'},
    ];

    const filterOptions = ['Statüsüne Göre', 'Sektörüne Göre'];
    const sectorOptions = [
      'AI / Yapay Zeka',
      'Akıllı Şehirler',
      'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
      'Atık Yönetimi',
      'Bilişim Teknolojileri',
      'Biyoteknoloji',
      'Blokzinciri',
      'Data Analiz & İş zekası',
      'Donanım',
      'e-ticaret',
      'Eğitim & Eğitim Teknolojileri',
      'Eğlence & Müzik',
      'Etkinlik',
      'Ev hizmetleri ve teknolojileri ',
      'Finans & Bankacılık',
      'Finans Teknolojileri',
      'Gayrimenkul',
      'Geri Dönüşüm',
      'Giyilebilir Teknolojiler',
      'Hukuk Teknolojileri',
      'İnsan Kaynakları & İşe Alım',
      'Kriptopara',
      'Kurumsal Hizmetler',
      'Mikro Mobilite',
      'Moda & Güzellik',
      'Medya',
      'Müşteri İlişkileri Yönetimi',
      'Nesnelerin İnterneti',
      'Oyun',
      'Perakende',
      'Regülasyon Teknolojileri',
      'Reklam & Pazarlama',
      'Robotik Teknolojileri',
      'SaaS',
      'Sağlık Hizmetleri & Sağlık Teknolojileri',
      'Seyahat & Turizm',
      'Siber Güvenlik',
      'Sigorta & Sigorta Teknolojileri',
      'Sosyal Girişimcilik',
      'Sosyal Medya',
      'Spor & Fitness',
      'Tarım & Tarım Teknolojileri',
      'Teknoloji',
      'Telekomünikasyon',
      'Temiz Teknoloji',
      'Teslimat & Lojistik',
      'Topluluk Yönetimi',
      'Tüketici Hizmetleri',
      'Ulaşım',
      'Video & Animasyon',
      'Yazılım',
      'Yenilenebilir Enerji',
      'Yiyecek & İçecek',
    ];
    const statusOptions = [{title:'Onay bekleyen', value:'Pending'}, {title:'Onaylanan', value:'Approved'}, {title:'Onaylanmayan', value:'Rejected'}];

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleOpenAddStartup = () => {
      setAddStartup(true);
    };
    const handleCloseAddStartup = (value) => {
      setAddStartup(false);
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleFilter = (event) => {
      setPage(1);
      setFilter(event.target.value);
    };
  
    const handleStatus = (event) => {
      setPage(1);
      if (status.includes(event.target.value)){
        let a = status.filter(e => e !== event.target.value);
        setStatus(a);
       }
       else{
        setStatus([...status, event.target.value]);
       }
    };
  
    const handleSector = (event) => {
      setPage(1);
      if (sector.includes(event.target.value)){
        let a = sector.filter(e => e !== event.target.value);
        setSector(a);
       }
       else{
        setSector([...sector, event.target.value]);
       }
    };
    
  
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    const ALL_STARTUPS = gql`
    query getFollowedStartups($order:String, $searchStartup: String, $page: Int, $pageSize: Int, $status: [String], $sector: [String]) {
        getFollowedStartups(orderBy:[$order], searchStartup: $searchStartup, pagination:{page:$page, pageSize:$pageSize}, filter:{status:$status ,sector:$sector}){
            count
            pageCount
            data{
              status
              startup{
                id
                title
                slug
                logo
                industry
                website
                requestedReports{
                  title
                  id
                }
              }
            }
        }
    }
    `;
      const { loading, data, refetch} = useQuery(ALL_STARTUPS, {
        fetchPolicy:'network-only',
        variables: {page:page, pageSize:pageSize, searchStartup:search, order: orderFollowed, status: status, sector: sector },
    });

    const ALL_LISTS = gql`
    query investorReportList($order:String, $page: Int, $pageSize: Int) {
      investorReportList(orderBy:[$order], pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            data{
                id
                title
                dataFrequency
                startDate
                items{
                  id
                  title
                  status
                }
            }
        }
    }
    `;
      const { loading:loadingList, data:dataList, refetch:refetchList} = useQuery(ALL_LISTS, {
        fetchPolicy:'network-only',
        variables: {page:pageList, pageSize:pageSizeList, order: orderInvestorReport },
    });
    React.useEffect(() => {
        if(!loading){
            if(data){
                setStartups(data.getFollowedStartups.data);
                setPageCount(data.getFollowedStartups.pageCount)
            }
        }
        if(!loadingList){
          if(dataList){
              setLists(dataList.investorReportList.data);
              setPageCountList(dataList.investorReportList.pageCount)
          }
      }
      if(startups){
        setShow(true)
      }

    },
  [data, loading, loadingList, dataList,startups])

  const handlePageChange = (event, value) => {
    window.scrollTo(0,0);
    setPage(value);
  };

  const handleSortFollowed = event => {
    setSortFollowed(event.target.value);
    setPage(1);
  };
  const handleSortInvestorReport = event => {
    setSortInvestorReport(event.target.value);
    setPageList(1);
  };
  const handlePageChangeList = (event, value) => {
    window.scrollTo(0,0);
    setPageList(value);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          {show && (
            <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
              <Grid
                className={'main-container'}
                xs={12}
                style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
              >
                {(startups && startups.length !== 0) || (lists && lists.length !== 0) ? (
                  <Grid item style={{ width: '100%', padding: '0 10px' }}>
                    <Grid container justify="space-between" alignItems="center">
                      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <img src={newEye} style={{ width: 20, marginTop: 5 }} alt="eye" />
                        <Typography variant="h4">Takip Ettiklerim</Typography>
                      </Breadcrumbs>
                      {value === 0 && (
                        <Grid item className={classes.filterArea}>
                          <Hidden smDown>
                            <DebounceInput
                              class="inputTypeSearch"
                              name="search"
                              value={search}
                              placeholder={t('Ara')}
                              className={classes.Debounce}
                              minLength={2}
                              debounceTimeout={800}
                              onChange={handleSearch}
                            />
                          </Hidden>
                          {value === 0 ? (
                            <>
                              <Grid className={classes.filter} onClick={handleClick} item>
                                <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                                <Hidden smDown>
                                  <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                    {t('Filtrele')}
                                  </Typography>
                                  <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                                </Hidden>
                              </Grid>
                              <Popover
                                id={id}
                                open={openPop}
                                anchorEl={anchorEl}
                                style={{ marginTop: 11 }}
                                onClose={handleClose}
                                classes={{
                                  paper: classes.myPaper,
                                }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <Grid container xs={12}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    style={{
                                      borderRight: '1px solid rgb(0 0 0 / 5%)',
                                      maxHeight: 250,
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Typography variant="h5">{t('Filtreler')}</Typography>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                      <RadioGroup onChange={handleFilter} value={filter}>
                                        {filterOptions.map((value, i) => {
                                          // Return the element. Also pass key
                                          return (
                                            <FormControlLabel
                                              classes={{ label: classes.myLabel }}
                                              control={<Radio color="primary" value={value} />}
                                              label={value}
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    className={classes.filterPadding}
                                    style={{
                                      borderRight: '1px solid rgb(0 0 0 / 5%)',
                                      maxHeight: 250,
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Typography variant="h5">{t('Filtre Seç')}</Typography>
                                    {filter === 'Statüsüne Göre' && (
                                      <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup onChange={handleStatus} value={status}>
                                          {statusOptions.map((value, i) => {
                                            return (
                                              <FormControlLabel
                                                className={classes.CheckText}
                                                control={
                                                  <Checkbox
                                                    classes={{ root: classes.myCheckbox }}
                                                    color="primary"
                                                    onChange={handleStatus}
                                                    checked={status.includes(value.value)}
                                                    name="filter4-1"
                                                    value={value.value}
                                                  />
                                                }
                                                label={value.title}
                                              />
                                            );
                                          })}
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                    {filter === 'Sektörüne Göre' && (
                                      <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup onChange={handleSector} value={sector}>
                                          {sectorOptions.map((value, i) => {
                                            return (
                                              <FormControlLabel
                                                className={classes.CheckText}
                                                control={
                                                  <Checkbox
                                                    classes={{ root: classes.myCheckbox }}
                                                    color="primary"
                                                    onChange={handleSector}
                                                    checked={sector.includes(value)}
                                                    name="filter4-1"
                                                    value={value}
                                                  />
                                                }
                                                label={value}
                                              />
                                            );
                                          })}
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    className={classes.filterPadding}
                                    style={{ maxHeight: 250, overflowY: 'auto' }}
                                  >
                                    <Typography style={{ marginBottom: 18 }} variant="h5">
                                      {t('Filtre Seçimlerim')}
                                    </Typography>
                                    {status && (
                                      <>
                                        {status.map((value, i) => {
                                          return (
                                            <Typography style={{ fontWeight: 'normal'}} variant="h6">
                                              <FormControlLabel
                                                className={classes.CheckText}
                                                control={
                                                  <Checkbox
                                                    classes={{ root: classes.myCheckbox }}
                                                    color="primary"
                                                    onChange={handleStatus}
                                                    checked={status.includes(value)}
                                                    name="filter4-1"
                                                    value={value}
                                                  />
                                                }
                                                
                                                label={(value === 'Pending' && 'Onay Bekleyen') || (value === 'Approved' && 'Onaylanmış') || (value === 'Rejected' && 'Onaylanmayan')}
                                              />
                                            </Typography>
                                          );
                                        })}
                                      </>
                                    )}
                                    {sector && (
                                      <>
                                        {sector.map((value, i) => {
                                          return (
                                            <Typography style={{ fontWeight: 'normal' }} variant="h6">
                                              <FormControlLabel
                                                className={classes.CheckText}
                                                control={
                                                  <Checkbox
                                                    classes={{ root: classes.myCheckbox }}
                                                    color="primary"
                                                    onChange={handleSector}
                                                    checked={sector.includes(value)}
                                                    name="filter4-1"
                                                    value={value}
                                                  />
                                                }
                                                label={value}
                                              />
                                            </Typography>
                                          );
                                        })}
                                      </>
                                    )}
                                  </Grid>
                                  <Grid container justify="flex-end"></Grid>
                                </Grid>
                              </Popover>
                                <Grid
                                  className={classes.filter}
                                  onClick={handleClickSort}
                                  item
                                  style={{ marginLeft: 15 }}
                                >
                                  <img src={newSortingIcon} style={{ width: 15 }} alt="filter" />
                                  <Hidden smDown>
                                    <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                      {t('Sırala')}
                                    </Typography>
                                    <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                                  </Hidden>
                                </Grid>
                                <Popover
                                  id={idSort}
                                  open={openPopSort}
                                  anchorEl={anchorElSort}
                                  style={{ marginTop: 11 }}
                                  onClose={handleCloseSort}
                                  classes={{
                                    paper: classes.myPaper2,
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      maxHeight: 250,
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Typography variant="h5">{t('Sırala')}</Typography>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                      <RadioGroup onChange={handleSortFollowed} value={sortFollowed}>
                                        {optionsFollowed.map((option, i) => {
                                          // Return the element. Also pass key
                                          return (
                                            <FormControlLabel
                                              classes={{ label: classes.myLabel }}
                                              control={
                                                <Radio
                                                  color="primary"
                                                  value={option.value}
                                                  onClick={() => setOrderFollowed(option.value)}
                                                />
                                              }
                                              label={option.name}
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                </Popover>                           
                            </>
                          ) : (
                            <Hidden smDown>
                              <FormControl placeholder="Please Select" style={{ width: '100%', maxWidth: '116px' }}>
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  displayEmpty
                                  value={sortInvestorReport}
                                  className={classes.multiSelect2}
                                  onChange={handleSortInvestorReport}
                                  IconComponent={(props) => (
                                    <ExpandMoreIcon
                                      {...props}
                                      style={{
                                        color: '#8898aa',
                                        marginLeft: 15,
                                        cursor: 'pointer',
                                        position: 'absolute',
                                      }}
                                    />
                                  )}
                                  input={<Input />}
                                  /*   MenuProps={MenuProps} */
                                >
                                  <MenuItem value="" disabled>
                                    <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                      {t('Sırala')}
                                    </Typography>
                                  </MenuItem>
                                  {optionsInvestorReport.map((option) => (
                                    <MenuItem
                                      key={option.name}
                                      value={option.value}
                                      onClick={() => setOrderInvestorReport(option.value)}
                                    >
                                      <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                        {option.name}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Hidden>
                          )}
                          <Hidden smDown>
                            <Button
                              style={{
                                fontWeight: 'normal',
                                padding: '11.5px 15px',
                                fontSize: 14,
                                width: '100%',
                                marginLeft: 15,
                              }}
                              onClick={handleOpenAddStartup}
                              variant="containedPrimary"
                            >
                              {t('Rapor Talep Et')}
                            </Button>
                          </Hidden>
                          <AddStartupDialog
                            refetch={refetch}
                            createStartupFollowRequest={props.createStartupFollowRequest}
                            open={addStartup}
                            setReportError={setReportError}
                            setReportSent={setReportSent}
                            onClose={handleCloseAddStartup}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Snackbar
                      autoHideDuration={5000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      onClose={() => setReportError(false)}
                      open={reportError}
                    >
                      <Alert severity="error">Rapor Talebiniz Zaten Gönderildi.</Alert>
                    </Snackbar>
                    <Snackbar
                      autoHideDuration={5000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      onClose={() => setReportSent(false)}
                      open={reportSent}
                    >
                      <Alert severity="success">Rapor Talebiniz İletildi</Alert>
                    </Snackbar>
                    <Grid container xs={12}>
                      <AppBar className={classes.tabPaper} position="static" color="default" style={{ zIndex: '1' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          className={classes.TabsGeneral}
                        >
                          <Tab label={t('Bilgi Paylaşan Şirketler')} {...a11yProps(0)} style={{ fontWeight: 600 }} />
                          <Tab label={t('Benimle Paylaşılan Raporlar')} {...a11yProps(1)} style={{ fontWeight: 600 }} />
                        </Tabs>
                      </AppBar>
                      <TabPanel style={{ width: '100%' }} value={value} index={0}>
                        {startups && startups.length !== 0 ? (
                          <Grid container xs={12} className={classes.whiteCard}>
                            <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell align="left">{t('LOGO')}</StyledTableCell>
                                    <StyledTableCell>{t('ŞİRKET ADI')}</StyledTableCell>
                                    <StyledTableCell align="left">{t('WEB SİTESİ')}</StyledTableCell>
                                    <StyledTableCell align="left">{t('SEKTÖR')}</StyledTableCell>
                                    <StyledTableCell align="left">{t('DURUM')}</StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {startups.map((startup) => (
                                    <ExpandingRow startup={startup} />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <Grid container justify="flex-end">
                              <Grid item style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                                <Pagination
                                  count={pageCount}
                                  page={page}
                                  onChange={handlePageChange}
                                  variant="outlined"
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container xs={12} className={classes.whiteCard}>
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                {t('Bilgi Paylaşan Şirket Bulunamadı...')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </TabPanel>
                      <TabPanel style={{ width: '100%' }} value={value} index={1}>
                        {lists && lists.length !== 0 ? (
                          <Grid container xs={12} className={classes.whiteCard}>
                            <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>{t('RAPOR ADI')}</StyledTableCell>
                                    <StyledTableCell>{t('ŞİRKET ADI')}</StyledTableCell>
                                    <StyledTableCell align="left">{t('GÖNDERİM SIKLIĞINI')}</StyledTableCell>
                                    <StyledTableCell align="left">{t('YAYINLANMA TARİHİ')}</StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {lists.map((list) => (
                                    <ExpandingRowList list={list} />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Grid container style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountList}
                                page={pageList}
                                onChange={handlePageChangeList}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container xs={12} className={classes.whiteCard}>
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                {t('Sizinle Paylaşılan Rapor Bulunamadı...')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </TabPanel>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justify="center">
                    <Grid item container direction="column" xs={6} alignItems="center">
                      <img src={Image} alt="404" style={{ width: '40%' }} />
                      <Typography variant="h2" style={{ marginTop: '25px' }} align="center">
                        {t('Henüz takip ettiğiniz bir girişim yok.')}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: '35px', color: '#777777', marginBottom: '15px' }}
                        align="center"
                      >
                        {t(
                          'Startup Borsa üzerinden ilginizi çeken girişimlerden rapor talep edebilir, girişimlerin güncel durumlarını ve  performansını takip edebilirsiniz.',
                        )}
                      </Typography>
                      <Link to="/girisimler">
                        <Button
                          style={{
                            fontWeight: 'normal',
                            fontSize: 14,
                            width: '100%',
                            marginRight: 20,
                            padding: '11.5px 30px',
                          }}
                          variant="containedPrimary"
                        >
                          {t('Girişimleri Keşfet')}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </main>
      </>
    );
}