
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import gql from 'graphql-tag';
import Pagination from "@material-ui/lab/Pagination";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import filterIcon from '../../static/icon/filterIcon.svg';
import moment from 'moment'
import 'moment/locale/tr'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { Link } from 'react-router-dom'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {  
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }, 
  },
  cardGrid: {
      marginTop:35
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  multiSelect: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  multiSelect2:{
    borderRadius:4,
    '& div': {
      background: 'none!important',
      paddingRight:'5px!important',
      paddingLeft:'5px'
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color:'#003CA5',
    },
  },
  sticker: {
    marginRight:10,
    color:'#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom:10
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filter: {
    height:40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myLabel: {
    fontSize:14
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'2px',
    padding: 25,
    marginBottom:50,
    marginTop:50,
  },
  tabPaper: {
    marginTop:50,
    [theme.breakpoints.up('md')]: {
      paddingLeft:50
    },
  },
  addButton: {
    marginTop:10,
    padding:'10.5px 19px',
    fontSize:13,
    fontWeight:'normal',
    color:'#ff5757',
    border:'1px solid #ff5757',
    backgroundColor : "#fff",
    "&:hover" : {
      backgroundColor : "#fff",
      color:'#ff5757',
      borderColor:'#ff5757',
    }
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop:30,
    '& textarea': {
        padding: 16,
        paddingRight:90,
        fontSize:13,
        lineHeight:1
      },
  },
  sendButton:{
      minWidth:50,
      height:50,
      borderRadius:'50%',
      background:'#003CA5',
      marginTop:'-80px',
      marginRight:30,
      cursor:'pointer',
      "&:hover" : {
          background:'#003CA5',
        }
  },
  exportButton:{
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    marginLeft:10,
    border:'1px solid #003CA5',
    borderRadius:3,
    display:'flex',
    alignItems:'center',
    color:'#003CA5',
    "&:hover" : {
      backgroundColor : "#003CA5",
      color:'#fff',
      borderColor:'#003CA5',
    }
  },
  checkBackground: {
    "&:hover" : {backgroundColor:'#0055ff1f'}
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#0055ff1f',
    color: '#003CA5',
    padding: '8px 40px',
  },
  body: {
    fontSize: 14,
    borderBottom:'none',
    padding: '12px 0px 12px 40px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7f7f7',
    },
  },
}))(TableRow);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ExpandingRow(props){
  const classes = useStyles();
  const { row, refetch, selectedRequests, setSelectedRequests } = props;

  const sendStatus= (value) => {
    if (value !== ''){
      props.startupHandleStartupFollow({ variables: { ids: [row.id], status:value } })
        .then(data => {
          if (data.data.startupHandleStartupFollow) {
            refetch()
            if(value==="Approved"){
              props.setIsAccepted(true)
            }
          }
        });
    }
  };
  const handleRequests = (event) => {
    if (selectedRequests.includes(event.target.value)){
      let a = selectedRequests.filter(e => e !== event.target.value);
      setSelectedRequests(a);
     }
     else{
      setSelectedRequests([...selectedRequests, event.target.value]);
     }
  };
  return (
    <>
      <StyledTableRow key={row.id}>
        <StyledTableCell align="left" style={{ paddingLeft: 10, width: 30 }}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup onChange={handleRequests} value={selectedRequests}>
              <FormControlLabel
                className="dropdown"
                control={
                  <Checkbox
                    classes={{ root: classes.myCheckbox }}
                    color="primary"
                    onChange={handleRequests}
                    checked={selectedRequests.includes(row.id.toString())}
                    name="filter4-1"
                    value={row.id.toString()}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </StyledTableCell>
        <StyledTableCell style={{ padding: '12px 0px 12px 0px', minWidth: 170 }} component="th" scope="row">
          <Grid container alignItems="center">
            {row.user.profilePhoto ? (
              <img
                src={row.user.profilePhoto}
                alt="logo"
                style={{ width: 35, height: 35, marginRight: 10, borderRadius: '25%', border: 'solid 1px #eaedf3' }}
              />
            ) : (
              <Grid
                style={{
                  width: 35,
                  height: 35,
                  borderRadius: '25%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                  marginRight: 10,
                }}
              >
                <Typography variant="h3" style={{ color: '#fff' }}>
                  {row.user.firstName && row.user.firstName.charAt(0)}
                </Typography>
              </Grid>
            )}
            <Typography variant="h6">
              {row.user.firstName} {row.user.lastName}
            </Typography>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ paddingLeft: 0, maxWidth: 350 }}>
          <Typography variant="body2">{row.note}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2" style={{ color: '#8898aa' }}>
            {' '}
            {moment(row.date).locale('tr').format('Do MMMM YYYY')}
          </Typography>
        </StyledTableCell>
        {row.status === 'Approved' && (
          <StyledTableCell align="center">
            <Typography color="primary" variant="h6">
              Onaylandı
            </Typography>
          </StyledTableCell>
        )}
        {row.status === 'Rejected' && (
          <StyledTableCell align="center">
            <Typography style={{ color: '#ff5757' }} variant="h6">
              Reddedildi
            </Typography>
          </StyledTableCell>
        )}
        {row.status === 'Pending' && (
          <StyledTableCell align="right">
            <Grid container direction="column" style={{ alignItems: 'center' }}>
              <Button
                style={{ padding: '10.5px 20px', fontSize: 13, fontWeight: 'normal', marginRight: 40 }}
                onClick={() => {
                  sendStatus('Approved');
                }}
                variant="containedPrimary"
              >
                Onayla
              </Button>
              <Button
                className={classes.addButton}
                style={{ fontWeight: 'normal', marginRight: 40 }}
                onClick={() => {
                  sendStatus('Rejected');
                }}
                variant="outlinedPrimary"
              >
                Reddet
              </Button>
            </Grid>
          </StyledTableCell>
        )}
      </StyledTableRow>
    </>
  );
}

export default function ReportRequests(props){
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [sort, setSort] = React.useState([]);
    const [requests, setRequests] = React.useState();
    const [order, setOrder] = React.useState("createdAt__DESC");
    const [search, setSearch] = React.useState("");
    const [filter, setFilter] = React.useState('Statüye Göre');
    const [status, setStatus] = React.useState(["Pending"]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedRequests, setSelectedRequests] = React.useState([]);
    const [selectedAll, setSelectedAll] = React.useState(false);
    const [isAccepted, setIsAccepted] = React.useState(false);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const sendAll= (value) => {
      if (value !== '' && selectedRequests.length > 0){
        props.startupHandleStartupFollow({ variables: { ids: selectedRequests, status:value } })
          .then(data => {
            if (data.data.startupHandleStartupFollow) {
              setSelectedRequests([])
              refetch()
            }
          });
      }
    };

    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    const ALL_REQUESTS = gql`
    query startupGetFollowRequest($orderBy:String, $status:[String], $page: Int, $pageSize: Int) {
      startupGetFollowRequest(orderBy:[$orderBy], filter : { status : $status}, pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            data{
                id
                note
                user{
                  profilePhoto
                  firstName
                  lastName
                }
                status
            }
        }
    }
    `;
    const { loading, data, refetch} = useQuery(ALL_REQUESTS, {
        fetchPolicy:'network-only',
        variables: {orderBy:order, status:status, search:search, page:page, pageSize:pageSize},
    });
    const options = [
      {name:'Latest', value:'createdAt__DESC'},
      {name:'Oldest', value:'createdAt__ASC'},
    ];
    const statusOptions = ['Pending','Rejected','Approved']
    const filterOptions = ['Statüye Göre'];
    React.useEffect(() => {
        if(!loading){
            if(data){
                setRequests(data.startupGetFollowRequest.data);
                setPageCount(data.startupGetFollowRequest.pageCount)
            }
        }
    },
  [data, loading])

  const handlePageChange = (event, value) => {
    window.scrollTo(0,0);
    setSelectedRequests([])
    setSelectedAll(false)
    setPage(value);
  };
  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };
  const handleFilter = (event) => {
    setPage(1);
    setFilter(event.target.value);
  };
  const handleStatus = (event) => {
    setPage(1);
    if (status.includes(event.target.value)){
      let a = status.filter(e => e !== event.target.value);
      setStatus(a);
     }
     else{
      setStatus([...status, event.target.value]);
     }
  };
  const handleAll = (event) => {
    setSelectedRequests([])
    if(selectedAll === false){
        let requestsIds= requests.map(a => a.id.toString())
        setSelectedRequests(requestsIds)
        setSelectedAll(true)
    }
    else{
      setSelectedAll(false)
      setSelectedRequests([])
    }

  };
    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
            >
              <Grid item style={{ width: '100%' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <InsertDriveFileOutlinedIcon style={{ fontSize: 20, marginTop: 5 }} />
                    <Link to="/raporlarim" style={{ padding: 0 }}>
                      <Typography variant="h5" style={{ color: '#718096' }}>
                        Raporlarım
                      </Typography>
                    </Link>
                    <Typography variant="h5">Rapor Talepleri</Typography>
                  </Breadcrumbs>
                  <Hidden smDown>
                    <Grid item className={classes.filterArea}>
                      <Grid className={classes.filter} onClick={handleClick} item>
                        <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                        <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                          Filtrele
                        </Typography>
                        <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
                      </Grid>
                      <Popover
                        id={id}
                        open={openPop}
                        style={{ marginTop: 11 }}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        classes={{
                          paper: classes.myPaper,
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Grid container xs={12}>
                          <Grid item xs={4} style={{ borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                            <Typography variant="h5">Filtrele</Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <RadioGroup onChange={handleFilter} value={filter}>
                                {filterOptions.map((value, i) => {
                                  // Return the element. Also pass key
                                  return (
                                    <FormControlLabel
                                      classes={{ label: classes.myLabel }}
                                      control={<Radio color="primary" value={value} />}
                                      label={value}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} style={{ paddingLeft: '30px', borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                            <Typography variant="h5">Filtre Seç</Typography>
                            {filter === 'Statüye Göre' && (
                              <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup onChange={handleStatus} value={status}>
                                  {statusOptions.map((value, i) => {
                                    return (
                                      <FormControlLabel
                                        className="dropdown"
                                        control={
                                          <Checkbox
                                            classes={{ root: classes.myCheckbox }}
                                            color="primary"
                                            onChange={handleStatus}
                                            checked={status.includes(value)}
                                            name="filter4-1"
                                            value={value}
                                          />
                                        }
                                        label={value}
                                      />
                                    );
                                  })}
                                </FormGroup>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                            <Typography style={{ marginBottom: 5 }} variant="h5">
                              Seçimleriniz
                            </Typography>
                            {status && (
                              <>
                                {status.map((value, i) => {
                                  return (
                                    <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                      {value}
                                    </Typography>
                                  );
                                })}
                              </>
                            )}
                          </Grid>
                          <Grid container justify="flex-end"></Grid>
                        </Grid>
                      </Popover>
                      <FormControl placeholder="Lütfen Seçiniz" style={{ width: '100%', maxWidth: '116px' }}>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          displayEmpty
                          value={sort}
                          className={classes.multiSelect}
                          onChange={handleSort}
                          IconComponent={(props) => (
                            <ExpandMoreIcon
                              {...props}
                              style={{ color: '#8898aa', marginLeft: 5, cursor: 'pointer', position: 'relative' }}
                            />
                          )}
                          input={<Input />}
                          /*   MenuProps={MenuProps} */
                        >
                          <MenuItem value="" disabled>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              Sırala
                            </Typography>
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                {option.name}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Hidden>
                </Grid>
                {requests && (
                  <>
                    {requests.length > 0 ? (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell style={{ paddingRight: 0, paddingLeft: 20 }}>
                                  <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      <FormControlLabel
                                        className="dropdown"
                                        control={
                                          <Checkbox
                                            style={{ padding: 0 }}
                                            className={classes.checkBackground}
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon style={{ color: '#0055ff' }} />}
                                            onChange={handleAll}
                                            checked={selectedAll}
                                            name="filter4-1"
                                            value={selectedAll}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </StyledTableCell>
                                <StyledTableCell align="left" style={{ paddingLeft: 0 }}>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Gönderen{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left" style={{ paddingLeft: 0 }}>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Kısa Not{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell style={{ paddingRight: 0 }} align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Gönderim Tarihi{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ minWidth: 165 }}>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Onay / Red{' '}
                                  </Typography>
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {requests.map((row) => (
                                <ExpandingRow
                                  setIsAccepted={setIsAccepted}
                                  selectedRequests={selectedRequests}
                                  setSelectedRequests={setSelectedRequests}
                                  startupHandleStartupFollow={props.startupHandleStartupFollow}
                                  row={row}
                                  refetch={refetch}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {requests.length > 0 ? (
                          <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="space-between">
                            <Grid item style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                              <Button
                                style={{
                                  padding: '10.5px 20px',
                                  fontSize: 13,
                                  fontWeight: 'normal',
                                  marginBottom: 10,
                                  marginRight: 15,
                                }}
                                onClick={() => {
                                  sendAll('Approved');
                                }}
                                variant="containedPrimary"
                              >
                                Seçilenleri Onayla
                              </Button>
                              <Button
                                className={classes.addButton}
                                style={{ marginBottom: 10, marginRight: 15, marginTop: 0 }}
                                onClick={() => {
                                  sendAll('Rejected');
                                }}
                                variant="outlinedPrimary"
                              >
                                Seçilenleri Reddet
                              </Button>
                            </Grid>
                            <Pagination
                              count={pageCount}
                              page={page}
                              onChange={handlePageChange}
                              variant="outlined"
                              color="primary"
                            />
                          </Grid>
                        ) : (
                          <Grid container justify="center">
                            <Typography style={{ margin: '25px 0' }} variant="h5">
                              Bekleyen Talep Bulunamadı...
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <Grid container justify="center">
                          <Typography style={{ margin: '25px 0' }} variant="h5">
                            Bekleyen Talep Bulunamadı...
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setIsAccepted(false)}
            open={isAccepted}
          >
            <Alert severity="success">Kişilerime Eklendi. Rapor Gönderebilirsiniz</Alert>
          </Snackbar>
        </main>
      </>
    );
}