import React from "react"
import {Grid, Typography, Hidden, Button} from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import login from '../../static/img/login.jpg';
import FormikTextField from "../../Components/FormikShortTextField";
import logo from '../../static/img/login-logo.png';
import { setPasswordSchema } from '../../yupSchemas';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import {makeStyles} from "@material-ui/core/styles";
import createBrowserHistory from 'history/createBrowserHistory';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) =>({
    img: {
          maxWidth: '100%',
          display: 'block'
    },
    link: {
        textDecoration: 'none',
        color: '#777777',
        padding: 0,
        fontWeight: 'normal',
        marginTop: 20

    },
    leftGrid: {
    [theme.breakpoints.up('sm')]: {
            width: '100%',
            height: '100%'
        },   
    backgroundImage: "url(" + login + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
      },
    logoGrid: {
        height: 'fit-content',
        [theme.breakpoints.up('xl')]: {
            paddingTop: 60,
            paddingRight: 60
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
        paddingTop: 30,
        paddingRight: 30
    },
    newAround: {
        [theme.breakpoints.up('xl')]: {
            marginTop: 110
        },
        marginTop: 25
    },
    signUp: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: 30
        },
        marginTop: 20
    },
    loginGrid: {
        flexDirection:'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: 50
        },
    },
    logo: {
        width: 120,
    },
    loginText: {
        fontSize: 40,
        fontWeight: 'bold',
        lineHeight:  1.35,
        color: '#172b4d',

    },
    welcomeText: {
        color: '#777777',
        [theme.breakpoints.up('xl')]: {
            marginTop: 40
        },
        marginTop: 25
    },
    EmailText: {
        [theme.breakpoints.up('xl')]: {
            marginTop: 50
        },
        marginTop: 30,
        fontWeight: 500
    },
    PasswordText: {
        marginTop: 15,
        fontWeight: 500
    }
  }));

  export default function SetPassword(props){

    const history = createBrowserHistory({forceRefresh:true});
    document.body.style = 'background: #ffffff;margin:0';
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [serverSuccess, setServerSuccess] = React.useState(false);
    const [serverMessage, setServerMessage] = React.useState({
        detail: '',
        message: '',
        type: false,
      });
    const handleSubmit = async variablesAll => {
        localStorage.clear();
        let params = new URLSearchParams(props.history.location.search);
        setIsSubmitting(true);
        const { ...variables } = variablesAll;
        variables.code = params.get("code")
        props.setPasswordStartupContact({ variables })
          .then(data => {
            if (data.data.setPasswordStartupContact) {
                    history.push("/login");

            } else {
                setServerMessage({
                    detail: get(data, 'errors[0].functionError.detail', ' '),
                    message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
                    type: false,
                  });
                  setServerSuccess(true);
            }
          }).then(() => {
                setIsSubmitting(false);
            }).catch(error => {
                setServerMessage({
                    message: 'Unexpected Error',
                    type: false,
                  });
                  setServerSuccess(true);
          });
      };   
        const classes = useStyles();
        const preventDefault = (event) => event.preventDefault();
        return (
        <Grid container style={{height : '100vh'}}>
            <Hidden mdDown>
                <Grid item sm={6} xs={12}>
                    <Grid className={classes.leftGrid}></Grid>
                </Grid>
            </Hidden>
            <Grid container item lg={6} md={12} xs={12}>
                <Grid xs={12} container item justify="flex-end" className={classes.logoGrid}>
                    <img src={logo} alt="angel-effect" className={classes.logo}></img>
                </Grid>
                <Grid sm={12} xl={12} xs={12} alignItems="center" className={classes.loginGrid} container item>
                    <Grid item sm={12} xs={11}>
                      <Formik
                      initialValues={{
                        passwordAgain: '',
                        password: '',
                        code:'',
                      }}
                      validationSchema={setPasswordSchema}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Typography className={classes.loginText}>Şifreni Değiştir</Typography>
                            <Typography className={classes.EmailText}>Şifre</Typography>
                            <FormikTextField name="password" type="password" placeholder="Email Address" />
                            <Typography className={classes.PasswordText}>Şifre Onayı</Typography>
                            <FormikTextField name="passwordAgain" type="password" placeholder="Password" />
                            <Grid item container xs={12} justify="center" style={{marginTop: 40}}> 
                                <Button variant="containedPrimary" style={{fontWeight: 'normal'}} type="submit" disabled={!props.isValid || (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)}>Gönder</Button>
                            </Grid>
                        </Form> 
                         )}
                       </Formik> 

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );}