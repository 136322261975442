import { Typography } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fieldToTextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    marginTop: 0,
            borderRadius: 6,

    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
  },
  multiSelect: {
    marginBottom: 0,
    borderRadius: 6,

    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    '& div': {
      background: 'none!important',
      paddingTop: '0!important',
      borderRadius: 6,

      '& input': {
        paddingTop: '8px!important',
        borderRadius: 6,

        '&::placeholder':{
          color: '#6d6b6b',
        }
      },
    },
  },
}));

export default function FormikMultiSelect({ textFieldProps, ...props }) {
  const [meta] = useField(props);
  const { t, i18n } = useTranslation();
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        multiple
        style={{ marginTop: 10 }}
        {...props}
        {...field}
        disableCloseOnSelect
        getOptionDisabled={(option) => props.selectedLength && props.selectedLength.length === 5}
        noOptionsText={'Sonuç Bulunamadı..'}
        hiddenLabel="true"
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props} {...textFieldProps} variant="outlined" />}
      ></Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}
