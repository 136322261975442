
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import get from 'lodash/get';
import newPerson from '../../static/icon/new-person-icon.svg';
import dumbIcon from '../../static/icon/dumb.svg';
import sureIcon from '../../static/icon/sure-icon.svg';
import inviteIcon from '../../static/icon/invite-icon.svg';
import linkedinLogo from '../../static/icon/linkedin-investor-profile.svg';
import showInvestorAccordion from '../../static/icon/show-investor-accordion.svg';
import hideInvestorAccordion from '../../static/icon/hide-investor-accordion.svg';
import twitterLogo from '../../static/icon/twitter-investor-icon.svg';
import organizationEndIcon from '../../static/icon/organization-end-icon.svg';
import ExitEndIcon from '../../static/icon/exit-end-icon.svg';
import FormikMultiSelect from "../../Components/FormikMultiSelectIndustry";
import attachmentEndIcon from '../../static/icon/attachment-end-icon.svg';
import roleEndIcon from '../../static/icon/role-end-icon.svg';
import editIcon from '../../static/icon/edit-investor-icon.svg';
import worldIcon from '../../static/icon/world-icon.svg';
import plusIcon from '../../static/icon/plus-investor.svg';
import penIcon from '../../static/icon/investor-pen.svg';
import approvedInvestor from '../../static/icon/approved-investor.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Field, Formik, Form, useField, useFormikContext } from 'formik';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import FormikTextField from "../../Components/FormikShortTextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import newCross from '../../static/icon/new-cross.svg';
import DatePicker, { registerLocale } from "react-datepicker";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import tr from "date-fns/locale/tr";
import moment from 'moment'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import websiteEndIcon from '../../static/icon/website-end-icon.svg';
import GreenTag from "../../Components/greenTag";
import FormikProvinceDropdown from "../../Components/FormikProvinceDropdown";
import FormikDropdownInvestType from "../../Components/FormikDropdownInvestType";
import FormikMultiLine from "../../Components/FormikMultiLine";
import { DebounceInput } from 'react-debounce-input';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import selectedKpi from '../../static/icon/selected-kpi.svg';
import newPlusBlue from '../../static/icon/new-plus-blue.svg';
import dumbIcon2 from '../../static/icon/dumb-icon-2.svg';
import Dropzone from 'react-dropzone';
import ImageCrop from "../../Components/ImageCrop";
import ImageCropBackground from "../../Components/ImageCropBackground";
import CloudUpload from '../../static/icon/cloud-upload.svg';
registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  companyGrid: {
    padding: '16px',
    borderRadius: '6px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#fff',
    marginBottom: 16
  },
  whiteGrid: {
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    marginBottom: 32,
  },
  profileEditIcon: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    position: 'absolute',
    background: '#fff',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  mainContainer: {
    width: 100,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    padding: '0px 15px 0 24px',
    maxHeight: 600,
    [theme.breakpoints.down('sm')]: {
      padding: '16',
    },
  },
  editPhotoRight: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '58px!important',
      paddingLeft: '32px!important',
      marginTop: -36
    },
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '180px',
    width: '100%',
    justifyContent: 'center',
    borderRadius: 16,
    border: '2px dashed #003CA5',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '500',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  baseStyle2: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '112px',
    width: '112px',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#003CA5',
    color: '#fff',
    fontWeight: '600',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    position: 'relative'
  },
  mobilePadding: {
    padding: '200px',
    paddingTop: 32,
    paddingBottom: 32,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      paddingTop: 16,
    },
  },
  search: {
    position: 'relative',
    width: '100%',
  },
  searchBox: {
    width: '100%',
    maxWidth: 350,
    minWidth: 125,
    background: '#ffffff',
    borderRadius: 6,
    marginTop: 5,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#7c7c7c',
  },
  Debounce: {
    width: '100%',
    fontSize: 13,
    height: '50px',
    fontFamily: 'inherit',
    outline: 'none',
    border: 'none',
    background: '#FFFFFF',
    color: '#718096',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    padding: '26px 56px 26px 0',
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    borderRadius: 6,
    '&:focus': {
      border: '1px solid #003CA5',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  uploadGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 80,
    height: 80,
    background: '#E4EEFF',
    marginBottom: 16
  },
  blueGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 116,
    height: 116,
    background: '#F0F5FF',
    borderRadius: '50%',
    marginBottom: 24
  },
  backgroundImg: {
    objectFit: 'fill',
    height: 180,
    borderRadius: 16,
    width: '100%',
    position: 'relative'
  },
  backgroundSection: {
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    },
  },
  profileImg: {
    objectFit: 'cover',
    height: 112,
    width: 112,
    borderRadius: '50%',
  },
  profileGrid: {
    marginLeft: 30,
    marginTop: -75,
    border: '5px solid #fff',
    borderRadius: '50%',
    height: 'max-content',
    position: 'relative'
  },
  portfoyCompany: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '32px',
      borderLeft: '1px solid rgba(160, 174, 192, 0.15)'
    },
  },
  profileSection: {
    textAlign: 'left',
    width: '100%',
    position: 'relative',
    marginTop: -65,
    marginLeft: 35,
    [theme.breakpoints.down('sm')]: {
      marginTop: 35,
      marginLeft: 16,
    },
  },
}));

const FormikCheckBox = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <FormControlLabel style={{ marginRight: 0 }} control={<Checkbox {...field} checked={field.value} color="primary" />} />
      {label}
    </>
  );
};

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

const handleTypename = (values) => {
  values = values.map(({ __typename, ...array }) => array)
  return values
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      style={{ zIndex: 999 }}
      {...props}
      maxDate={new Date()}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
          autocomplete="off"
          style={{
            width: '100%',
            padding: '13px',
            borderRadius: 3,
            border: '1px solid rgb(221 221 221 / 56%)',
            boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
            marginTop: 10
          }}
          type="text"
          placeholder={'mm'} />
      }
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

function validateLinkedinUrl(value) {
  if (value !== '' && value) {
    let error;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?');// query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}
function validateLinkedinUrl2(value) {
  if (value !== '' && value) {
    let error;
    var pattern = new RegExp(/(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/); // query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}

function EditCareerDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, setSuccess, careerItem, careerList, adminEditInvestorProfileSettings, refetch, index, investor } = props;

  const handleSubmit = variables => {
    let list = careerList ? careerList : []
    list.splice(index, 1, variables)
    adminEditInvestorProfileSettings({ variables: { career: list, id: investor.id } }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        setSuccess(true);
        refetch(true);
        onClose(null);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4" style={{ marginBottom: 20 }}>
          {t('Deneyimi Düzenle')}
        </Typography>
        <Typography align="center" variant="body2" style={{ marginTop: 25 }}>
          {t('Geçmiş deneyimlerinizin tamamını StartupBorsa ile paylaşın')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              company: careerItem.company,
              jobTitle: careerItem.jobTitle,
              startDate: careerItem.startDate,
              endDate: careerItem.endDate,
              isPresent: careerItem.isPresent
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="company"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Ünvan"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="jobTitle"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirket Adı"
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap', marginBottom: 10 }}>
                        <FormikCheckBox
                          name="isPresent"
                          type="Checkbox"
                          label={
                            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                              {t("Şu an bu pozisyonda çalışıyorum")}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ marginBottom: 35 }}>
                        <Typography variant="h6" style={{ display: 'flex' }}>
                          {t('Başlama Tarihi')}
                        </Typography>
                        <DatePickerField
                          name='startDate'
                        />
                      </Grid>
                      {!props.values.isPresent &&
                        <Grid item container xs={12} style={{ marginBottom: 35 }}>
                          <Typography variant="h6" style={{ display: 'flex' }}>
                            {t('Bitiş Tarihi')}
                          </Typography>
                          <DatePickerField
                            name='endDate'
                            minDate={props.values.startDate}
                          />
                        </Grid>
                      }
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            disabled={!props.values.company || !props.values.jobTitle || !props.values.startDate}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Düzenle')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function EditProfileDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, refetch, adminEditInvestorProfileSettings, city, type, setSuccess, investor } = props;

  const handleSubmit = variables => {
    variables.id = investor.id;
    variables.cityId = variables.cityId?.id
    adminEditInvestorProfileSettings({ variables }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        setSuccess(true);
        refetch(true);
        onClose(null);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h3" style={{ marginBottom: 20 }}>
          {t('Profil Bilgilerini Düzenle')}
        </Typography>
        <Typography align="center" variant="body2" style={{ marginTop: 25 }}>
          {t('İnsanların sizi daha yakından tanıyabilmesi için bilgilerinizi paylaşın')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              website: investor.website ? investor.website : '',
              socialLinkedIn: investor.socialLinkedIn ? investor.socialLinkedIn : '',
              socialTwitter: investor.socialTwitter ? investor.socialTwitter : '',
              cityId: investor.cityId ? investor.cityId : '',
              companyName: investor.companyName ? investor.companyName : '',
              companyRole: investor.companyRole ? investor.companyRole : '',
              investmentType: investor.investmentType ? investor.investmentType : '',
              biography: investor.biography ? investor.biography : '',
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12}>
                        <FormikTextField name="website" type="text" placeholder="Websitesi" validate={validateLinkedinUrl} endIcon={websiteEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField name="socialLinkedIn" type="text" placeholder="Linkedin" validate={validateLinkedinUrl2} endIcon={attachmentEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap' }}>
                        <FormikTextField name="socialTwitter" type="text" placeholder="Twitter" validate={validateLinkedinUrl} endIcon={attachmentEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField name="companyName" type="text" placeholder="Mevcut Şirket" endIcon={organizationEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField name="companyRole" type="text" placeholder="Mevcut Ünvan" endIcon={roleEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item xs={12}>
                        {city &&
                          <Field
                            component={FormikProvinceDropdown}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="cityId"
                            options={city}
                          >

                          </Field>
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          style={{ width: '100%', marginTop: 10 }}
                          component={FormikDropdownInvestType}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="investmentType"
                          options={type}
                        ></Field>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikMultiLine name="biography" rows={8} type="text" placeholder="Hakkında" />
                      </Grid>
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Kaydet')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function EditInvestDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, refetch, industry, adminEditInvestorProfileSettings, setSuccess, investor } = props;

  const stage = [
    'Fikir aşaması',
    'MVP',
    'Erken Aşama',
    'Büyüme'
  ];

  const handleSubmit = variables => {
    if (typeof variables.exitedCompanyCount !== 'undefined') {
      variables.exitedCompanyCount = parseInt(variables.exitedCompanyCount);
    }
    else{
      variables.exitedCompanyCount = null
    }
    
    if (typeof variables.portfolioCompanyCount !== 'undefined') {
      variables.portfolioCompanyCount = parseInt(variables.portfolioCompanyCount);
    }
    else{
      variables.portfolioCompanyCount = null
    }
    variables.id = investor.id;
    adminEditInvestorProfileSettings({ variables }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        setSuccess(true);
        refetch(true);
        onClose(null);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h3" style={{ marginBottom: 20 }}>
          {t('Yatırım Bilgilerini Düzenle')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              interestedStage: investor.interestedStage ? investor.interestedStage : [],
              interestedIndustries: investor.interestedIndustries ? investor.interestedIndustries : [],
              portfolioCompanyCount: (investor.portfolioCompanyCount || typeof investor.portfolioCompanyCount === 'number') ? investor.portfolioCompanyCount : null,
              exitedCompanyCount: (investor.exitedCompanyCount || typeof investor.exitedCompanyCount === 'number') ? investor.exitedCompanyCount : null,
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item xs={12}>
                        {industry.length > 0 && (
                          <Field
                            component={FormikMultiSelect}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="interestedStage"
                            options={stage}
                          ></Field>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {industry.length > 0 && (
                          <Field
                            component={FormikMultiSelect}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="interestedIndustries"
                            options={industry}
                          ></Field>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField name="portfolioCompanyCount" type="number" placeholder="Portföy Şirket Sayısı" min={0} endIcon={organizationEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField name="exitedCompanyCount" type="text" placeholder="Exit yapan şirket sayısı" endIcon={ExitEndIcon} style={{ paddingBottom: 0 }} />
                      </Grid>
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Kaydet')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function AddCareerDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, adminEditInvestorProfileSettings, careerList, setSuccess, refetch, investor } = props;

  const handleSubmit = variables => {
    let list = careerList ? careerList : [];
    list.push(variables)
    adminEditInvestorProfileSettings({ variables: { career: list, id: investor.id } }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        setSuccess(true);
        refetch(true);
        onClose(null);
      }
    });
    onClose(null);
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4" style={{ marginBottom: 20 }}>
          {t('Deneyim Ekle')}
        </Typography>
        <Typography align="center" variant="body2" style={{ marginTop: 25 }}>
          {t('Geçmiş deneyimlerinizin tamamını StartupBorsa ile paylaşın')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              company: '',
              jobTitle: '',
              startDate: '',
              endDate: '',
              isPresent: false
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="company"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Ünvan"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="jobTitle"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirket Adı"
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap', marginBottom: 10 }}>
                        <FormikCheckBox
                          name="isPresent"
                          type="Checkbox"
                          label={
                            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                              {t("Şu an bu pozisyonda çalışıyorum")}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ marginBottom: 35 }}>
                        <Typography variant="h6" style={{ display: 'flex' }}>
                          {t('Başlama Tarihi')}
                        </Typography>
                        <DatePickerField
                          name='startDate'
                        />
                      </Grid>
                      {!props.values.isPresent &&
                        <Grid item container xs={12} style={{ marginBottom: 35 }}>
                          <Typography variant="h6" style={{ display: 'flex' }}>
                            {t('Bitiş Tarihi')}
                          </Typography>
                          <DatePickerField
                            name='endDate'
                            minDate={props.values.startDate}
                          />
                        </Grid>
                      }
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            disabled={!props.values.company || !props.values.jobTitle || !props.values.startDate}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Ekle')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function InviteStartupDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, setSuccess } = props;

  const handleSubmit = variables => {
    props.investorInviteStartup({ variables }).then((data) => {
      if (data.data.investorInviteStartup) {
        setSuccess(true)
        onClose(null);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={inviteIcon} alt="invite-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2" style={{ marginBottom: 20 }}>
          {t('Şirketi Davet Et')}
        </Typography>
        <Typography align="center" variant="body1" style={{ marginTop: 25 }}>
          {t('Bu şirketi StartupBorsa’ya davet etmek istediğinizden emin misiniz?')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              title: '',
              website: '',
              contactNameSurname: '',
              contactMail: '',
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="title"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirketin Adı"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="website"
                          style={{ width: '100%' }}
                          type="text"
                          validate={validateLinkedinUrl}
                          placeholder="Şirketin Websitesi"
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="contactNameSurname"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirket Yetkilisinin Adı Soyadı"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="contactMail"
                          style={{ width: '100%' }}
                          type="email"
                          placeholder="Şirket Yetkilisinin Mail Adresi"
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%', padding: '12px 43px' }}
                            disabled={!props.values.title || !props.values.website || !props.values.contactNameSurname || !props.values.contactMail}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Davet Et')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function CareerPart(props) {
  const { index, careerItem, careerList, setRemove, setRemoveItem, setSuccess, refetch, investor } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Grid xs={12} container style={{ marginBottom: 15 }} justify="space-between">
        <Grid item>
          <Typography variant="h6" align="left" style={{ marginBottom: 4 }}>
            {careerItem.company},
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: 4 }}>
            {careerItem.jobTitle}
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: 4 }}>
            {careerItem.startDate && moment(careerItem.startDate).format('MMMM YYYY')} - {careerItem.isPresent ? 'Şu Anda' : (careerItem.endDate && moment(careerItem.endDate).format('MMMM YYYY'))}
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <img src={dumbIcon} alt="dumb-icon" style={{ cursor: 'pointer', marginRight: 24 }} onClick={() => {
            setRemove(true);
            setRemoveItem(careerItem);
          }} />
          <img src={editIcon} alt="edit-icon" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
        </Grid>
        <EditCareerDialog open={open} setSuccess={setSuccess} refetch={refetch} onClose={() => setOpen(false)} careerItem={careerItem} index={index} careerList={careerList} adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings} investor={investor} />
      </Grid>
    </div>
  );
}

function PortfolioPart(props) {
  const classes = useStyles();
  const { index, portfolioItem, setRemoveCompany, setRemoveItem } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Grid xs={12} container style={{ marginBottom: 15 }} justify="space-between" className={classes.companyGrid}>
        <Grid xs style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Grid xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            {portfolioItem.logo ?
              <img src={portfolioItem.logo} style={{ width: 45, minWidth:45, height: '45px', borderRadius: 12 }} />
              :
              <Grid
                style={{
                  width: 45,
                  height: 45,
                  minWidth:45,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography variant="h4" style={{ color: '#fff' }}>
                  {portfolioItem.title && portfolioItem.title.charAt(0)}
                </Typography>
              </Grid>
            }
            <Typography variant="h5" align="left" style={{ marginBottom: 4, marginLeft: 16, maxWidth: 210 }}>
              {portfolioItem.title}
            </Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <Typography variant="body1" align="left" style={{ marginBottom: 4, maxWidth: 210 }}>
              {portfolioItem.sector}
            </Typography>
          </Grid>
        </Grid>

        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <img src={dumbIcon} alt="dumb-icon" onClick={() => {
            setRemoveCompany(true);
            setRemoveItem(portfolioItem);
          }} style={{ cursor: 'pointer', marginRight: 24 }} />
          <img src={approvedInvestor} alt="approved-icon" />
        </Grid>
      </Grid>
    </div>
  );
}

function RemoveDialog(props) {
  const classes = useStyles();
  const { onClose, open, careerList, refetch, setSuccess, removeItem, adminEditInvestorProfileSettings, investor } = props;

  const handleSubmit = () => {
    let list = careerList.filter((x) => x !== removeItem);
    adminEditInvestorProfileSettings({ variables: { career: list, id: investor.id } }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        setSuccess(true);
        refetch(true);
        onClose(null);
      }
    });
    onClose();
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={onClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={sureIcon} alt="sure-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2">
          Deneyimi Kaldır
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            {removeItem.company} deneyiminizi kaldırmak istediğinize emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }}
                onClick={() => {
                  onClose(null);
                }}
                variant="outlinedSecondary"
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  width: '100%',
                  marginTop: 20,
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
              >
                Deneyimi Kaldır
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function RemoveDialogCompany(props) {
  const classes = useStyles();
  const { onClose, open, selected, refetch, setSuccess, removeItem, adminEditInvestorProfileSettings, investor } = props;

  const handleSubmit = () => {
    let list = selected.filter((x) => x.id !== removeItem.id);
    const ids = [];

    if (list && list.length > 0) {
      list.forEach((data) => {
        ids.push(data.id);
      });
    }
    adminEditInvestorProfileSettings({ variables: { portfoliosIds: ids, id: investor.id } }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        setSuccess(true);
        refetch(true);
        onClose(null);
      }
    });
    onClose();
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={onClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={sureIcon} alt="sure-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2">
          Şirketi Kaldır
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            {removeItem.title} şirketini kaldırmak istediğinize emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }}
                onClick={() => {
                  onClose(null);
                }}
                variant="outlinedSecondary"
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  width: '100%',
                  marginTop: 20,
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
              >
                Şirketi Kaldır
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function CompanyDialog(props) {
  const classes = useStyles();
  const { onClose, open, adminEditInvestorProfileSettings, selected, refetch, setInvite, setSuccess, investor } = props;
  const [search, setSearch] = React.useState("");
  const [companyList, setCompanyList] = React.useState(selected);
  const [openPopover, setOpenPopover] = React.useState(true);

  const handleClickSearch = () => {
    setOpenPopover(true);
  };

  const handleDelete = (item) => {
    setCompanyList(companyList.filter((x) => x.id !== item.id))
  };

  const HandleSearch = event => {
    setSearch(event.target.value);
    getData()
  };

  const handleClickAway = () => {
    setSearch("");
    setOpenPopover(false);
  };

  const SEARCH_STARTUPS = gql`
  query topStartupSearch($search: String){
        topStartupSearch(search:$search){
        id
        title
        oneliner
        logo
        sector
        slug
        website
        businessModelArray
        country {
          id
          title
        }
      }
  }
  `;

  const [getData, { loading: loadingSearch, data: dataSearch }] = useLazyQuery(SEARCH_STARTUPS, {
    fetchPolicy: 'network-only',
    variables: { search: search },
  });

  const handleSubmit = () => {
    const ids = [];
    if (companyList && companyList.length > 0) {
      companyList.forEach((data) => {
        ids.push(data.id);
      });
    }
    adminEditInvestorProfileSettings({ variables: { portfoliosIds: ids, id: investor.id } }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        onClose(true);
        setSuccess(true);
        refetch(true)
      }
      else {

      }
    })
  }

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={onClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={sureIcon} alt="sure-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2">
          Portföyünüze Şirket Ekleyin
          </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777', marginBottom: 32 }}>
            İnsanların sizi daha yakından tanıyabilmesi için bilgilerinizi paylaşın
            </Typography>
          <Grid container justify="center">
            <Grid item style={{ marginBottom: 24, width: '100%', maxWidth: 350 }}>
              <Typography variant="h6" style={{ width: 'fit-content', marginBottom: 10, color: '#425466' }}>
                Portföye Şirket Ekle
              </Typography>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={organizationEndIcon} alt="icon" />
                    </Grid>
                  </div>
                  <DebounceInput
                    class="inputTypeSearch"
                    name="search"
                    value={search}
                    placeholder={'Şirket Adını Giriniz'}
                    className={classes.Debounce}
                    minLength={2}
                    debounceTimeout={200}
                    onChange={HandleSearch}
                    autoComplete="off"
                    onClick={handleClickSearch}
                  />
                </div>
              </ClickAwayListener>
              {openPopover && search && dataSearch && dataSearch.topStartupSearch && (
                <Grid className={classes.searchBox} xs={3}>
                  <List component="nav" aria-label="secondary mailbox folders" style={{ padding: 16, borderRadius: 6, boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)' }}>
                    {dataSearch.topStartupSearch.map((searchResult) => (
                      <>
                        {searchResult.status !== null && (
                          <Grid>
                            {companyList.find(x => x.id === searchResult.id) ?
                              <ListItem
                                button
                                onClick={() => {
                                  setCompanyList(companyList.filter((x) => x.id !== searchResult.id));
                                  setSearch("")}}
                                style={{ minHeight: 50, background: '#E7F4FF' }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={organizationEndIcon} alt="icon" />
                                  </Grid>
                                  <Grid item style={{ marginLeft: 10 }}>
                                    <Typography
                                      variant="h6"
                                      style={{ fontSize: 15, fontWeight: 500 }}
                                    >
                                      {truncateString(searchResult.title, 30)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs style={{ textAlign: 'right' }}>
                                    <img src={selectedKpi} alt="selected" />
                                  </Grid>
                                </Grid>
                              </ListItem>
                              :
                              <ListItem
                                button
                                onClick={() => setCompanyList([...companyList, searchResult])}
                                style={{ minHeight: 50 }}
                              >
                                <Grid container alignItems="center">
                                  <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={organizationEndIcon} alt="icon" />
                                  </Grid>
                                  <Grid item style={{ marginLeft: 10 }}>
                                    <Typography
                                      variant="h6"
                                      style={{ fontSize: 15, fontWeight: 500 }}
                                    >
                                      {truncateString(searchResult.title, 30)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            }
                          </Grid>
                        )}
                      </>
                    ))}
                    {dataSearch.topStartupSearch.length === 0 && (
                      <>
                        <ListItem
                          button
                          style={{ minHeight: 50, background: '#E7F4FF', marginBottom: 8 }}
                        >
                          <Grid container alignItems="center">
                            <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <img src={organizationEndIcon} alt="icon" />
                            </Grid>
                            <Grid item style={{ marginLeft: 10 }}>
                              <Typography
                                variant="h6"
                                style={{ fontSize: 15, fontWeight: 500 }}
                              >
                                Sonuç Bulunamadı
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => setInvite(true)}
                          style={{ minHeight: 32, boxShadow: ' 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)', borderRadius: 4 }}
                        >
                          <Grid container alignItems="center" justify="center">
                            <Typography variant="h6" align="center" color="primary" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                              <img src={newPlusBlue} alt="new-plus" style={{ marginRight: 8 }} />
                              Davet Et
                            </Typography>
                          </Grid>
                        </ListItem>
                      </>
                    )}
                  </List>
                </Grid>
              )}
            </Grid>
            <Grid container justify="center">
              <Grid item style={{ width: '100%', maxWidth: 350 }}>
                <Typography variant="h6" align="left" style={{ marginBottom: 10, color: '#425466' }}>
                  Şirketler
                </Typography>
                {!companyList || companyList.length === 0 ?
                  <Grid container className={classes.companyGrid}>
                    <Grid item style={{ width: 45, height: 45, minWidth:45, background: 'rgb(0 60 165 / 32%)', opacity: '0.15', borderRadius: 12 }} />
                    <Grid item style={{ marginLeft: 12 }}>
                      <Grid item style={{ width: 202, height: '13px', background: 'rgb(0 60 165 / 32%)', opacity: '0.15', borderRadius: 12, marginBottom: 12 }} />
                      <Grid item style={{ width: 116, height: '13px', background: 'rgb(0 60 165 / 32%)', opacity: '0.15', borderRadius: 12 }} />
                    </Grid>
                  </Grid>
                  :
                  companyList.map((select) => (
                    <Grid container className={classes.companyGrid}>
                      {select.logo ?
                        <img src={select.logo} style={{ width: 45, minWidth:45, height: '100%', borderRadius: 12 }} />
                        :
                        <Grid
                          style={{
                            width: 45,
                            height: 45,
                            minWidth:45,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#003CA5',
                          }}
                        >
                          <Typography variant="h4" style={{ color: '#fff' }}>
                            {select.title && select.title.charAt(0)}
                          </Typography>
                        </Grid>
                      }
                      <Grid item style={{ marginLeft: 16 }}>
                        <Typography
                          variant="h6"
                          align="left"
                          style={{ fontSize: 15, fontWeight: 500 }}
                        >
                          {truncateString(select.title, 25)}
                        </Typography>
                        <Typography
                          variant="body2"
                          align="left"
                        >
                          {truncateString(select.sector, 30)}
                        </Typography>
                        <Grid container spacing={1} style={{ marginTop: 4 }}>
                          {select.businessModelArray && select.businessModelArray.length > 0 &&
                            select.businessModelArray.map((model) => (
                              <Grid item>
                                <GreenTag title={model} />
                              </Grid>
                            ))
                          }
                          {select.country && (
                            <Grid item>
                              <GreenTag title={select.country.title} />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs style={{ textAlign: 'right' }}>
                        <img src={dumbIcon2} onClick={() => {
                          handleDelete(select)
                        }} alt="dumb-icon" style={{ cursor: 'pointer' }} />
                      </Grid>
                    </Grid>
                  ))
                }
              </Grid>
              <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlinedSecondary"
                    style={{ fontWeight: 'normal', width: '100%' }}
                    onClick={() => {
                      onClose();
                      setCompanyList(selected);
                    }}
                  >
                    Vazgeç
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="containedPrimary"
                    style={{ fontWeight: 'normal', width: '100%' }}
                    disabled={!companyList || companyList.length === 0}
                    onClick={() => handleSubmit()}
                  >
                    Kaydet
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function https(value) {
  if(!value.includes('http'))
  {
    const prefix = 'https://';
    if (value.substr(0, prefix.length) !== prefix)
    {
      value = prefix + value;
    }
    return value
  }
  else{
    return value
  }
}

export default function InvestorProfileShowAdmin(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editPhoto, setEditPhoto] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [openPhoto, setOpenPhoto] = React.useState(false);
  const [addCareer, setAddCareer] = React.useState(false);
  const [filesProfile, setFilesProfile] = React.useState([]);
  const [investor, setInvestor] = React.useState();
  const [openPhotoProfile, setOpenPhotoProfile] = React.useState(false);
  const [city, setCity] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [remove, setRemove] = React.useState(false);
  const [removeCompany, setRemoveCompany] = React.useState(false);
  const [removeItem, setRemoveItem] = React.useState();
  const [invite, setInvite] = React.useState(false);
  const [openCompany, setOpenCompany] = React.useState(false);
  const [editProfile, setEditProfile] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [successInvite, setSuccessInvite] = React.useState(false);
  const [careerShow, setCareerShow] = React.useState(false);
  const [companyShow, setCompanyShow] = React.useState(false);
  const [editInvestInfo, setEditInvestInfo] = React.useState(false);
  const [careerList, setCareerList] = React.useState();

  const stage = [
    'Fikir aşaması',
    'MVP',
    'Erken Aşama',
    'Büyüme'
  ];

  const type = [
    "Melek Yatırımcı",
    "Venture Capital",
    "Private Equity",
    "Hızlandırma Programı",
    "Yatırım Ortaklığı",
    "Micro VC",
    "Melek Yatırım Ağı",
    "Kuluçka Merkezi",
    "Yatırım Bankası",
    "Aile Yatırım Şirketi",
    "Borç Finansmanı",
    "Paylaşımlı Ofis",
    "Fon Sepeti Fonu",
    "Risk Fonu",
    "Devlet Kurumu",
    "Üniversite Programı",
    "Girişimcilik Programı",
    "İkincil Pazar Yatırımcısı",
    "Sendikasyon Yatırımı"
  ];

  const industry = [
    'AI / Yapay Zeka',
    'Akıllı Şehirler',
    'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
    'Atık Yönetimi',
    'Bilişim Teknolojileri',
    'Biyoteknoloji',
    'Blokzinciri',
    'Data Analiz & İş zekası',
    'Donanım',
    'e-ticaret',
    'Eğitim & Eğitim Teknolojileri',
    'Eğlence & Müzik',
    'Etkinlik',
    'Ev hizmetleri ve teknolojileri ',
    'Finans & Bankacılık',
    'Finans Teknolojileri',
    'Gayrimenkul',
    'Geri Dönüşüm',
    'Giyilebilir Teknolojiler',
    'Hukuk Teknolojileri',
    'İnsan Kaynakları & İşe Alım',
    'Kriptopara',
    'Kurumsal Hizmetler',
    'Mikro Mobilite',
    'Moda & Güzellik',
    'Müşteri İlişkileri Yönetimi',
    'Nesnelerin İnterneti',
    'Oyun',
    'Perakende',
    'Regülasyon Teknolojileri',
    'Reklam & Pazarlama',
    'Robotik Teknolojileri',
    'SaaS',
    'Sağlık Hizmetleri & Sağlık Teknolojileri',
    'Seyahat & Turizm',
    'Siber Güvenlik',
    'Sigorta & Sigorta Teknolojileri',
    'Sosyal Girişimcilik',
    'Sosyal Medya',
    'Spor & Fitness',
    'Tarım & Tarım Teknolojileri',
    'Teknoloji',
    'Telekomünikasyon',
    'Temiz Teknoloji',
    'Teslimat & Lojistik',
    'Topluluk Yönetimi',
    'Tüketici Hizmetleri',
    'Ulaşım',
    'Video & Animasyon',
    'Yazılım',
    'Yenilenebilir Enerji',
    'Yiyecek & İçecek',
  ];

  const INVESTOR_PROFILE = gql`
  query investorDetail($slug:String!){
    investorDetail(slug:$slug){
      id
      firstName
      lastName
      profilePhoto
      backgroundPhoto
      interestedIndustries
      biography
      website
      socialLinkedIn
      city{
        id 
        title
      }
      socialTwitter
      companyName
      companyRole
      portfolioCompanyCount
      investmentType
      exitedCompanyCount
      interestedStage
      career{
        company
        jobTitle
        startDate
        endDate
        isPresent
      }
      portfolios{
        id
        title
        oneliner
        logo
        sector
        slug
        website
        businessModelArray
        country {
          id
          title
        }
      }
    }
  }
  `;

  const { loading: loading, data: data, refetch } = useQuery(INVESTOR_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { slug: props.match.params.slug },
  });

  const ALL_CITIES = gql`
  query allCities($country:String) {
      allCities(country:$country){
          id
          title
      }        
  }
  `;

  const { loading: loading2, data: data2 } = useQuery(ALL_CITIES, {
    variables: { country: 'TR' },
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (data.investorDetail) {
          if (data.investorDetail.socialLinkedin) {
            data.investorDetail.socialLinkedin = https(data.investorDetail.socialLinkedin)
          }
          if (data.investorDetail.socialTwitter) {
            data.investorDetail.socialTwitter = https(data.investorDetail.socialTwitter)
          }
          if (data.investorDetail.website) {
            data.investorDetail.website = https(data.investorDetail.website)
          }
          if (data.investorDetail.career !== [] && data.investorDetail.career) {
            data.investorDetail.career = handleTypename(data.investorDetail.career);
            data.investorDetail.career.forEach((car) => {
              if(car.startDate){
                car.startDate = moment(car.startDate).format('yyyy/MM');
              }
              if(car.endDate){
                car.endDate = moment(car.endDate).format('yyyy/MM');
              }
            });
            setCareerList(data.investorDetail.career);
          }
          if (data.investorDetail.city) {
            data.investorDetail.cityId = data.investorDetail.city
          }
          setInvestor(data.investorDetail);
          if (data.investorDetail.portfolios) {
            setSelected(data.investorDetail.portfolios);
          }
        }
      }

    }
    if (!loading2) {
      if (data2) {
        if (data2.allCities.length > 0) {
          setCity(data2.allCities);
        }
        else {
          setCity([{ id: 1, title: 'İzmir' }, { id: 2, title: 'İstanbul' }]);
        }
      }
    }
  },
    [data, loading, data2, loading2]);

  const thumb = {
    display: 'inline-flex',
    borderRadius: 16,
    width: '100%',
    height: 180,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    width: '100%',
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: 16,
    objectFit: 'fill',
  };

  const thumbsContainer2 = {
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
    flexWrap: 'wrap',
  };

  const thumb2 = {
    display: 'inline-flex',
    borderRadius: '50%',
    width: 112,
    height: 112,
    boxSizing: 'border-box'
  };

  const thumbInner2 = {
    display: 'flex',
    width: '100%',
    overflow: 'hidden'
  };

  const img2 = {
    display: 'block',
    width: 112,
    height: 112,
    borderRadius: '50%',
    objectFit: 'cover',
    border: '5px solid #fff',
  };

  const handleSubmit = (variables) => {
    if (variables.profilePhoto !== null) {
      if (typeof variables.profilePhoto === 'string' && variables.profilePhoto !== '') {
        variables.profilePhoto = null
      }
    }
    if (variables.backgroundPhoto !== null) {
      if (typeof variables.backgroundPhoto === 'string' && variables.backgroundPhoto !== '') {
        variables.backgroundPhoto = null
      }
    }
    variables.id = investor.id;
    props.adminEditInvestorProfileSettings({ variables }).then((data) => {
      if (data.data.adminEditInvestorProfileSettings) {
        refetch(true);
        setEditPhoto(false);
      }
      else {

      }
    })
  }

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccess(false)}
            open={success}
          >
            <Alert severity="success">Başarıyla düzenlendi.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccessInvite(false)}
            open={successInvite}
          >
            <Alert severity="success">Başarıyla davet edildi.</Alert>
          </Snackbar>
          <Grid
            container
            xs={12}
            style={{ marginTop: 145, height: 'fit-content', maxWidth: 1350 }}
          >
            <Breadcrumbs style={{padding:'0 25px', marginTop:-15}} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <img src={newPerson} style={{ width: 14 }} alt="people" />
              <Typography variant="h4">Yatırımcı Profili</Typography>
            </Breadcrumbs>
            <Grid container className={classes.mobilePadding}>
              {investor &&
                <>
                  <Grid xs={12} className={classes.whiteGrid}>
                    {editPhoto &&
                      <Formik
                        initialValues={{
                          backgroundPhoto: investor.backgroundPhoto ? investor.backgroundPhoto : null,
                          profilePhoto: investor.profilePhoto ? investor.profilePhoto : null
                        }}
                        onSubmit={(values) => {
                          handleSubmit(values)
                        }}
                      >
                        {(props) => (
                          <Form>
                            <Field>
                              {({ field, form, meta }) => (
                                <Dropzone
                                  maxSize={20971520}
                                  accept={'image/*'}
                                  onDrop={(acceptedFiles) =>
                                    setFiles(
                                      acceptedFiles.map((file) =>
                                        Object.assign(file, { preview: URL.createObjectURL(file) }),
                                      ),
                                      form.setFieldValue(`backgroundPhoto`, acceptedFiles),
                                      setOpenPhoto(true),
                                    )
                                  }
                                >
                                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                                    <section className={classes.backgroundSection}>
                                      {investor.backgroundPhoto && (
                                        <Button
                                          variant="containedSecondary"
                                          style={{
                                            padding: '4.5px 15px',
                                            fontSize: 14,
                                            marginTop: 40,
                                            width: 90,
                                            top: 16,
                                            right: 16,
                                            position: 'absolute'
                                          }}
                                          onClick={() => form.setFieldValue(`backgroundPhoto`, '')}
                                        >
                                          Sil
                                        </Button>
                                      )}
                                      {form.values.backgroundPhoto !== investor.backgroundPhoto ? (
                                        <Button
                                          variant="containedPrimary"
                                          style={{
                                            padding: '4.5px 15px',
                                            fontSize: 14,
                                            marginTop: 5,
                                            width: 90,
                                            top: 16,
                                            right: 16,
                                            position: 'absolute'
                                          }}
                                          onClick={() => handleSubmit(props.values)}
                                        >
                                          Kaydet
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outlinedSecondary"
                                          style={{
                                            padding: '4.5px 15px',
                                            fontSize: 14,
                                            marginTop: 5,
                                            width: 90,
                                            top: 16,
                                            right: 16,
                                            position: 'absolute'
                                          }}
                                          onClick={() => setEditPhoto(false)}
                                        >
                                          Vazgeç
                                        </Button>
                                      )}
                                      {typeof form.values.backgroundPhoto !== 'string' || form.values.backgroundPhoto === '' ? (
                                        !form.values.backgroundPhoto ? (
                                          <div {...getRootProps({ className: classes.baseStyle })}>
                                            <input {...getInputProps()} />
                                            <Grid container justify="center">
                                              <Grid item className={classes.uploadGrid}>
                                                <img src={CloudUpload} alt="cloud-upload" />
                                              </Grid>
                                            </Grid>
                                            <Grid item container direction="column" justify="center" alignItems="center">
                                              <Typography variant="h5" style={{ display: 'flex' }}>
                                                Kapak fotoğrafı <strong style={{ color: '#003CA5', margin: '0 5px' }}> yükle </strong> veya <strong style={{ color: '#003CA5', margin: '0 5px' }}> seç </strong>
                                              </Typography>
                                            </Grid>
                                          </div>
                                        ) : (
                                          <div style={{ border: 'none' }} {...getRootProps({ className: classes.baseStyle })}>
                                            <input {...getInputProps()} />
                                            <Grid container>
                                              {form.values.backgroundPhoto && (
                                                <div
                                                  id={form.values.backgroundPhoto[0].name}
                                                  style={thumb}
                                                  key={form.values.backgroundPhoto[0].name}
                                                >
                                                  <div style={thumbInner}>
                                                    <img src={form.values.backgroundPhoto[0].preview} style={img} alt="file" />
                                                  </div>
                                                </div>
                                              )}
                                            </Grid>
                                          </div>
                                        )
                                      ) : (
                                        <div style={{ border: 'none' }} {...getRootProps({ className: classes.baseStyle })}>
                                          <img
                                            src={form.values.backgroundPhoto}
                                            alt="avatar"
                                            style={{ width: '100%', height: '100%', borderRadius: 16, objectFit: 'fill' }}
                                          />
                                        </div>
                                      )}
                                    </section>
                                  )}
                                </Dropzone>
                              )}
                            </Field>
                            <Field>
                              {({ field, form, meta }) => (
                                <Dropzone
                                  maxSize={20971520}
                                  accept={'image/*'}
                                  onDrop={(acceptedFiles) =>
                                    setFilesProfile(
                                      acceptedFiles.map((file) =>
                                        Object.assign(file, { preview: URL.createObjectURL(file) }),
                                      ),
                                      form.setFieldValue(`profilePhoto`, acceptedFiles),
                                      setOpenPhotoProfile(true),
                                    )
                                  }
                                >
                                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                                    <section className={classes.profileSection}>
                                      {typeof form.values.profilePhoto !== 'string' || form.values.profilePhoto === '' ? (
                                        <div {...getRootProps({ className: classes.baseStyle2 })}>
                                          <input {...getInputProps()} />
                                          <Grid className={classes.profileEditIcon}>
                                            <img src={penIcon} alt="pen-icon" />
                                          </Grid>
                                          {!form.values.profilePhoto ? (
                                            <Grid item container direction="column" justify="center" alignItems="center" style={{ position: 'relative' }}>
                                              <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 36 }}>
                                                {investor.firstName.charAt(0)}
                                              </Typography>
                                            </Grid>
                                          ) : (
                                            <Grid container>
                                              {form.values.profilePhoto && (
                                                <div
                                                  id={form.values.profilePhoto[0].name}
                                                  style={thumb2}
                                                  key={form.values.profilePhoto[0].name}
                                                >
                                                  <div style={thumbInner2}>
                                                    <img src={form.values.profilePhoto[0].preview} style={img2} alt="file" />
                                                  </div>
                                                </div>
                                              )}
                                            </Grid>
                                          )}
                                        </div>
                                      ) : (
                                        <aside style={thumbsContainer2}>
                                          {form.values.profilePhoto && (
                                            <div
                                              id={form.values.profilePhoto}
                                              style={thumb2}
                                              key={form.values.profilePhoto}
                                            >
                                              <div style={thumbInner2}>
                                                <img src={form.values.profilePhoto} style={img2} alt="file" />
                                              </div>
                                            </div>
                                          )}
                                        </aside>
                                      )}
                                      <Grid container>
                                        <Grid item style={{ minWidth: 95, display: 'flex', flexDirection: 'column' }}>
                                          {form.values.profilePhoto && (
                                            <Button
                                              variant="containedSecondary"
                                              style={{
                                                padding: '4.5px 15px',
                                                fontSize: 14,
                                                marginTop: 10,
                                                marginLeft: 10,
                                                width: 90,
                                              }}
                                              onClick={() => form.setFieldValue(`profilePhoto`, '')}
                                            >
                                              Sil
                                            </Button>
                                          )}
                                          {form.values.profilePhoto !== investor.profilePhoto && (
                                            <>
                                              <Button
                                                variant="containedPrimary"
                                                style={{
                                                  padding: '4.5px 15px',
                                                  fontSize: 14,
                                                  marginTop: 10,
                                                  marginLeft: 10,
                                                  width: 90,
                                                }}
                                                onClick={() => handleSubmit(props.values)}
                                              >
                                                Kaydet
                                            </Button>
                                              <Button
                                                variant="outlinedSecondary"
                                                style={{
                                                  padding: '4.5px 15px',
                                                  fontSize: 14,
                                                  marginTop: 10,
                                                  marginLeft: 10,
                                                  width: 90,
                                                }}
                                                onClick={() => setEditPhoto(false)}
                                              >
                                                Vazgeç
                                            </Button>
                                            </>
                                          )}
                                          {form.values.profilePhoto === investor.profilePhoto && (
                                            <Button
                                              variant="outlinedSecondary"
                                              style={{
                                                padding: '4.5px 15px',
                                                fontSize: 14,
                                                marginTop: 10,
                                                marginLeft: 10,
                                                width: 90,
                                              }}
                                              onClick={() => setEditPhoto(false)}
                                            >
                                              Vazgeç
                                            </Button>
                                          )}
                                        </Grid>
                                        <Grid xs={12} md justify="space-between" className={classes.editPhotoRight} style={investor.backgroundPhoto ? { display: 'flex', padding: 20, flexWrap: 'wrap' } : { display: 'flex', padding: 20, flexWrap: 'wrap' }}>
                                          <Grid item>
                                            <Grid item style={{ display: 'flex' }}>
                                              <Typography variant="h3" style={{ display: 'flex', marginRight: 8 }}>
                                                {investor.firstName} {investor.lastName}
                                              </Typography>
                                              {investor.investmentType && <GreenTag title={investor.investmentType} />}
                                            </Grid>
                                            <Typography variant="body2" style={{ color: '#718096' }}>
                                              {investor.companyRole}
                                            </Typography>
                                            <Typography variant="body2" style={{ color: '#718096', marginTop: 2 }}>
                                              {investor.city && investor.city.title}/Türkiye
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} md="auto" style={{ textAlign: 'right' }}>
                                            {investor.socialLinkedIn &&
                                              <a href={investor.socialLinkedIn} style={{ padding: 0 }} target="_blank">
                                                <img src={linkedinLogo} alt="linkedin" />
                                              </a>
                                            }
                                            {investor.socialTwitter &&
                                              <a href={investor.socialTwitter} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                                                <img src={twitterLogo} alt="twitter" />
                                              </a>
                                            }
                                            {investor.website &&
                                              <a href={investor.website} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                                                <img src={worldIcon} alt="website" />
                                              </a>
                                            }
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </section>
                                  )}
                                </Dropzone>
                              )}
                            </Field>
                            <Field>
                              {({ field, form, meta }) => (
                                <ImageCrop
                                  setFieldValue={form.setFieldValue}
                                  files={filesProfile}
                                  profilePhoto={form.values.profilePhoto}
                                  setOpenPhoto={setOpenPhotoProfile}
                                  openPhoto={openPhotoProfile}
                                />
                              )}
                            </Field>
                            <Field>
                              {({ field, form, meta }) => (
                                <ImageCropBackground
                                  setFieldValue={form.setFieldValue}
                                  files={files}
                                  backgroundPhoto={form.values.backgroundPhoto}
                                  setOpenPhoto={setOpenPhoto}
                                  openPhoto={openPhoto}
                                />
                              )}
                            </Field>
                          </Form>
                        )}
                      </Formik>
                    }
                    {investor.backgroundPhoto && !editPhoto &&
                      <Grid container style={{ position: 'relative' }}>
                        <img src={investor.backgroundPhoto} alt="background" className={classes.backgroundImg} />
                        <Grid className={classes.profileEditIcon} onClick={() => setEditPhoto(true)} style={{ top: 16, right: 16, width: 40, height: 40 }}>
                          <img src={penIcon} alt="pen-icon" />
                        </Grid>
                      </Grid>
                    }
                    {!editPhoto &&
                      <Grid container>
                        {investor.profilePhoto ?
                          <Grid item className={classes.profileGrid} style={!investor.backgroundPhoto ? { marginTop: 24, width: 112, height: 112 } : {}}>
                            <img src={investor.profilePhoto} alt="background" className={classes.profileImg} style={!investor.backgroundPhoto ? { width: 112, height: 112 } : {}} />
                            <Grid onClick={() => setEditPhoto(true)} className={classes.profileEditIcon} style={!investor.backgroundPhoto ? { right: -12 } : {}}>
                              <img src={penIcon} alt="pen-icon" />
                            </Grid>
                          </Grid>
                          :
                          <Grid item className={classes.profileGrid} style={investor.backgroundPhoto ? { background: '#003CA5', width: 112, height: 112, display: 'flex', alignItems: 'center', justifyContent: 'center' } : { background: '#003CA5', width: 112, height: 112, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
                            <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 36 }}>
                              {investor.firstName.charAt(0)}
                            </Typography>
                            <Grid className={classes.profileEditIcon} onClick={() => setEditPhoto(true)}>
                              <img src={penIcon} alt="pen-icon" />
                            </Grid>
                          </Grid>
                        }
                        <Grid xs={12} md justify="space-between" style={investor.backgroundPhoto ? { display: 'flex', padding: 20, flexWrap: 'wrap' } : { display: 'flex', padding: 20, marginTop: 24, flexWrap: 'wrap' }}>
                          <Grid item>
                            <Grid item style={{ display: 'flex' }}>
                              <Typography variant="h3" style={{ display: 'flex', marginRight: 8 }}>
                                {investor.firstName} {investor.lastName}
                              </Typography>
                              {investor.investmentType && <GreenTag title={investor.investmentType} />}
                            </Grid>
                            <Typography variant="body2" style={{ color: '#718096' }}>
                              {investor.companyRole}
                            </Typography>
                            <Typography variant="body2" style={{ color: '#718096', marginTop: 2 }}>
                              {investor.city && investor.city.title}/Türkiye
                        </Typography>
                          </Grid>
                          <Grid item xs={12} md="auto" style={{ textAlign: 'right' }}>
                            {investor.socialLinkedIn &&
                              <a href={investor.socialLinkedIn} style={{ padding: 0 }} target="_blank">
                                <img src={linkedinLogo} alt="linkedin" />
                              </a>
                            }
                            {investor.socialTwitter &&
                              <a href={investor.socialTwitter} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                                <img src={twitterLogo} alt="twitter" />
                              </a>
                            }
                            {investor.website &&
                              <a href={investor.website} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                                <img src={worldIcon} alt="website" />
                              </a>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                    <Grid container style={{ padding: 40 }}>
                      <Grid container justify="space-between" alignItems="center">
                        {investor.biography && investor.biography.length > 0 &&
                          <Typography align="left" variant="h5">
                            {t('Hakkında')}
                          </Typography>
                        }
                        <img src={editIcon} alt="edit-icon" style={{ cursor: 'pointer' }} onClick={() => setEditProfile(true)} />
                      </Grid>
                      <Typography align="left" variant="body2" style={{ marginTop: 8 }}>
                        {investor.biography}
                      </Typography>
                    </Grid>
                    <InviteStartupDialog open={invite} setSuccess={setSuccessInvite} onClose={() => setInvite(false)} investorInviteStartup={props.investorInviteStartup} />
                    <AddCareerDialog investor={investor} open={addCareer} careerList={careerList} refetch={refetch} setSuccess={setSuccess} onClose={() => setAddCareer(false)} adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings} />
                    <EditProfileDialog open={editProfile} investor={investor} refetch={refetch} city={city} type={type} setSuccess={setSuccess} onClose={() => setEditProfile(false)} adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings} />
                    <EditInvestDialog open={editInvestInfo} investor={investor} refetch={refetch} industry={industry} setSuccess={setSuccess} onClose={() => setEditInvestInfo(false)} adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings} />
                  </Grid>
                  <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px' }}>
                    <Grid container justify="space-between">
                      <Typography align="left" variant="h4">
                        {t('Yatırım Bilgileri')}
                      </Typography>
                      <img src={editIcon} alt="edit-icon" style={{ cursor: 'pointer' }} onClick={() => setEditInvestInfo(true)} />
                    </Grid>
                    <Grid container style={{ marginTop: 16 }}>
                      {(investor.exitedCompanyCount || (typeof investor.exitedCompanyCount === 'number')) &&
                        <Grid item xs={12} md={6}>
                          <Typography align="left" variant="body1" style={{ marginBottom: 12 }}>
                            {t('Exit yapan şirketler')}
                          </Typography>

                          <GreenTag title={investor.exitedCompanyCount} />
                        </Grid>
                      }
                      {(investor.portfolioCompanyCount || (typeof investor.portfolioCompanyCount === 'number')) &&
                        <Grid item xs={12} md={6} className={classes.portfoyCompany}>
                          <Typography align="left" variant="body1" style={{ marginBottom: 12 }}>
                            {t('Portföy Şirket Sayısı')}
                          </Typography>
                          <GreenTag title={investor.portfolioCompanyCount} />
                        </Grid>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                    </Grid>
                    <Grid container style={{ marginTop: 16 }}>
                      <Grid item xs={12}>
                        {investor.interestedIndustries && investor.interestedIndustries.length > 0 &&
                          <Grid container justify="space-between" alignItems="center">
                            <Typography align="left" variant="body1">
                              {t('İlgilenilen Sektörler')}
                            </Typography>
                          </Grid>
                        }
                        {investor.interestedIndustries && investor.interestedIndustries.length > 0 &&
                          <Grid container xs={12} sm spacing={1} style={{ marginTop: 10 }}>
                            {investor.interestedIndustries.map((ind, index) => (
                              <Grid item>
                                <GreenTag title={ind} />
                              </Grid>
                            ))}
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  {removeItem &&
                    <RemoveDialog
                      removeItem={removeItem}
                      open={remove}
                      onClose={() => setRemove(false)}
                      investor={investor}
                      adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings}
                      refetch={refetch}
                      setSuccess={setSuccess}
                      careerList={careerList}
                    />
                  }
                  {removeItem &&
                    <RemoveDialogCompany
                      investor={investor}
                      removeItem={removeItem}
                      investor={investor}
                      open={removeCompany}
                      onClose={() => setRemoveCompany(false)}
                      adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings}
                      refetch={refetch}
                      setSuccess={setSuccess}
                      selected={selected}
                    />
                  }
                  <CompanyDialog
                    investor={investor}
                    open={openCompany}
                    onClose={() => setOpenCompany(false)}
                    adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings}
                    selected={selected}
                    setSelected={setSelected}
                    setSuccess={setSuccess}
                    refetch={refetch}
                    setInvite={setInvite}
                  />
                  {!careerList || (careerList && careerList.length) < 5 ?
                    <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px' }}>
                      <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                        <Typography align="left" variant="h4">
                          {t('Deneyimler')}
                        </Typography>
                        <img src={plusIcon} alt="plus-icon" style={{ cursor: 'pointer' }} onClick={() => setAddCareer(true)} />
                      </Grid>
                      <>
                        {careerList && careerList.length > 0 && careerList.sort(function compare(a, b) {
                          var dateA = new Date(a.startDate);
                          var dateB = new Date(b.startDate);
                          return dateB - dateA;
                        }).map((careerItem, index) => (
                          <>
                            <CareerPart
                              setRemove={setRemove}
                              investor={investor}
                              setRemoveItem={setRemoveItem}
                              careerItem={careerItem}
                              careerList={careerList}
                              setSuccess={setSuccess}
                              index={index}
                              refetch={refetch}
                              adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings}
                            />
                            <Grid item xs={12}>
                              <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                    :
                    careerShow ?
                      <>
                        <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                            <Typography align="left" variant="h4">
                              {t('Deneyimler')}
                            </Typography>
                            <img src={plusIcon} alt="plus-icon" style={{ cursor: 'pointer' }} onClick={() => setAddCareer(true)} />
                          </Grid>
                          <>
                            {careerList && careerList.length > 0 && careerList.sort(function compare(a, b) {
                              var dateA = new Date(a.startDate);
                              var dateB = new Date(b.startDate);
                              return dateB - dateA;
                            }).map((careerItem, index) => (
                              <>
                                <CareerPart
                                  setRemove={setRemove}
                                  setRemoveItem={setRemoveItem}
                                  investor={investor}
                                  careerItem={careerItem}
                                  careerList={careerList}
                                  setSuccess={setSuccess}
                                  index={index}
                                  refetch={refetch}
                                  adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings}

                                />
                                <Grid item xs={12}>
                                  <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                </Grid>
                              </>
                            ))}
                          </>
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32 }}>
                          <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 10 }} onClick={() => setCareerShow(false)}>
                            <img src={hideInvestorAccordion} alt="hide-accordion" style={{ marginRight: 8 }} />
                            Gizle
                        </Typography>
                        </Grid>
                      </>
                      :
                      <>
                        <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                            <Typography align="left" variant="h4">
                              {t('Deneyimler')}
                            </Typography>
                            <img src={plusIcon} alt="plus-icon" style={{ cursor: 'pointer' }} onClick={() => setAddCareer(true)} />
                          </Grid>
                          <>
                            {careerList && careerList.length > 0 && careerList.sort(function compare(a, b) {
                              var dateA = new Date(a.startDate);
                              var dateB = new Date(b.startDate);
                              return dateB - dateA;
                            }).map((careerItem, index) => (
                              index < 4 &&
                              <>
                                <CareerPart
                                  setRemove={setRemove}
                                  setRemoveItem={setRemoveItem}
                                  investor={investor}
                                  careerItem={careerItem}
                                  setSuccess={setSuccess}
                                  careerList={careerList}
                                  index={index}
                                  refetch={refetch}
                                  adminEditInvestorProfileSettings={props.adminEditInvestorProfileSettings}
                                />
                                <Grid item xs={12}>
                                  <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                </Grid>
                              </>
                            ))}
                          </>
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32 }}>
                          <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 10 }} onClick={() => {
                            setCareerShow(true);
                            setCompanyShow(false);
                          }}>
                            <img src={showInvestorAccordion} alt="show-accordion" style={{ marginRight: 8 }} />
                            Tümünü Göster
                          </Typography>
                        </Grid>
                      </>
                  }
                  {selected && selected.length < 5 ?
                    <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px' }}>
                      <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                        <Typography align="left" variant="h4">
                          {t('Portföyündeki Şirketler')}
                        </Typography>
                        <img src={plusIcon} alt="plus-icon" onClick={() => setOpenCompany(true)} style={{ cursor: 'pointer' }} />
                      </Grid>
                      <>
                        {selected && selected.length > 0 && selected.map((portfolioItem, index) => (
                          <>
                            <PortfolioPart
                              portfolioItem={portfolioItem}
                              index={index}
                              investor={investor}
                              setRemoveCompany={setRemoveCompany}
                              setRemoveItem={setRemoveItem}
                            />
                            <Grid item xs={12}>
                              <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                    :
                    companyShow ?
                      <>
                        <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                            <Typography align="left" variant="h4">
                              {t('Portföyündeki Şirketler')}
                            </Typography>
                            <img src={plusIcon} alt="plus-icon" style={{ cursor: 'pointer' }} />
                          </Grid>
                          <>
                            {selected && selected.length > 0 && selected.map((portfolioItem, index) => (
                              <>
                                <PortfolioPart
                                  portfolioItem={portfolioItem}
                                  index={index}
                                  investor={investor}
                                  setRemoveCompany={setRemoveCompany}
                                  setRemoveItem={setRemoveItem}
                                />
                                <Grid item xs={12}>
                                  <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                </Grid>
                              </>
                            ))}
                          </>
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32 }}>
                          <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 10 }} onClick={() => setCompanyShow(false)}>
                            <img src={hideInvestorAccordion} alt="hide-accordion" style={{ marginRight: 8 }} />
                            Gizle
                        </Typography>
                        </Grid>
                      </>
                      :
                      <>
                        <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                            <Typography align="left" variant="h4">
                              {t('Portföyündeki Şirketler')}
                            </Typography>
                            <img src={plusIcon} alt="plus-icon" style={{ cursor: 'pointer' }} />
                          </Grid>
                          <>
                            {selected && selected.length > 0 && selected.map((portfolioItem, index) => (
                              index < 4 &&
                              <>
                                <PortfolioPart
                                  portfolioItem={portfolioItem}
                                  index={index}
                                  investor={investor}
                                  setRemoveCompany={setRemoveCompany}
                                  setRemoveItem={setRemoveItem}
                                />
                                <Grid item xs={12}>
                                  <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                </Grid>
                              </>
                            ))}
                          </>
                        </Grid>
                        <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32 }}>
                          <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 10 }} onClick={() => {
                            setCareerShow(false);
                            setCompanyShow(true);
                          }}>
                            <img src={showInvestorAccordion} alt="show-accordion" style={{ marginRight: 8 }} />
                            Tümünü Göster
                          </Typography>
                        </Grid>
                      </>
                  }
                </>
              }
              <Grid container justify="center" style={{ marginTop: 32 }}>
                <Typography variant="body2" align="center" style={{ color: '#718096' }}>
                  Startup Borsa, bir <a href="https://www.gedik.com/" target="_blank" style={{fontWeight:600, padding:0, color:'rgb(113, 128, 150)', fontSize:16}}>Gedik Yatırım</a> kuruluşudur.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}