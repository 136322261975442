import InvestorProfileOther from './InvestorProfileOther'
import { graphql } from 'react-apollo';
import { HEADER_LEFT_DRAWER } from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const SEND_REQUEST = gql`
mutation startupSendInvestmentRequest(
  $investorId: ID!,
  $nameSurname: String,
  $website: String,
  $requiredInvestmentAmount: String,
  $preInvestmentAssessment: String,
  $isReceivedCommitment: String,
  $minTicketSize: String,
  $tourClosingDate: String
) 
{
  startupSendInvestmentRequest(
    investorId:$investorId,
    nameSurname:$nameSurname,
    website:$website, 
    isReceivedCommitment:$isReceivedCommitment, 
    requiredInvestmentAmount:$requiredInvestmentAmount,
    preInvestmentAssessment:$preInvestmentAssessment,
    minTicketSize:$minTicketSize, 
    tourClosingDate:$tourClosingDate,

  )
}
`;

const SEND_MAIL = gql`
  mutation investorShareProfileWithMail($slug: String!, $mail: String!) {
    investorShareProfileWithMail(slug: $slug, mail: $mail) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(SEND_REQUEST, {
    name: 'startupSendInvestmentRequest',
  }),
  graphql(SEND_MAIL, {
    name: 'investorShareProfileWithMail',
  }),
)(InvestorProfileOther);
export default JoinWithMutation;