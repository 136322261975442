import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    borderRadius: '6px',
    padding: '2px 10px',
    width: 'max-content',
  },
  text: {
    fontSize: 10,
  },
}));

export default function ColorfulTag({ backgroundColor, textColor, title, children }) {
  const classes = useStyles();

  return (
    <Grid container item className={classes.mainGrid} style={{ backgroundColor: backgroundColor }}>
      {children ? (
        children
      ) : (
        <Typography variant="h5" align="center" className={classes.text} style={{ color: textColor }}>
          {title}
        </Typography>
      )}
    </Grid>
  );
}
