import React, {createContext, useContext, useState} from "react";

const TempConversationContext = createContext();

export function useTempConversation() {
  return useContext(TempConversationContext);
}

const defaultData = {
  temporaryConversation: false,
  receiverUser: null
};

export function TempConversationProvider({children, ...other}) {
  const [data, setData] = useState(defaultData);

  function createTemporaryConversation(receiverUser) {
    setData({
      temporaryConversation: true,
      receiverUser
    });
  }

  function endTemporaryConversation() {
    setData(defaultData);
  }

  const value = {
    ...data,
    createTemporaryConversation,
    endTemporaryConversation
  };

  return <TempConversationContext.Provider value={value}>{children}</TempConversationContext.Provider>;
}
