import "./scroll.css";

import Button from "@material-ui/core/Button";
import CreateConversation from "../CreateConversation";
import GreenTag from "../greenTag.js";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import MaxLineTypography from "../../Components/MaxLineTypography/MaxLineTypography.js";
import React from "react";
import Typography from "@material-ui/core/Typography";
import linkedin from "../../static/icon/new-linkedin.svg";
import {makeStyles} from "@material-ui/core/styles";
import twitter from "../../static/icon/new-twitter.svg";
import worldIcon from "../../static/icon/blue-world-icon.svg";

const useStyles = makeStyles(theme => ({
  startupCard: {
    background: "#ffffff",
    minHeight: "381px",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    padding: 36,
    [theme.breakpoints.down("lg")]: {
      padding: 24
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "0"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24
    }
  },
  titleBar: {
    width: "fit-content",
    marginLeft: 8,
  },
  startupGrid: {
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important"
    }
  },
  values: {
    color: "#28b1e7",
    wordBreak: "break-word",
    marginTop: 5
  },
  startupLogo: {
    width: 70,
    height: 70,
    [theme.breakpoints.down("lg")]: {
      width: 60,
      height: 60
    },
    background: "#423eff",
    borderRadius: "10px"
  },
  img: {
    width: 45,
    height: 45,
    objectFit: "contain",
    borderRadius: "12px"
  },
  Title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: "left",
    color: "#172b4d",
    wordBreak: "break-word"
  },
  favIcon: {
    cursor: "pointer"
  },
  desc: {
    color: "#425466",
    wordBreak: "break-word",
    fontSize: 14
  },
  model: {
    color: "#777777",
    fontSize: 18,
    [theme.breakpoints.down("lg")]: {
      fontSize: 13
    }
  },
  socialMargin: {
    marginLeft: 5,
    marginTop: 16,
    minHeight: 26.650
  }
}));

export default function InvestorCard(props) {
  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const classes = useStyles();
  return (
    <Grid item md={6} lg={4} sm={6} xs={12} className={classes.startupGrid}>
      <Grid item container direction="column" justify="end" xs={12} className={classes.startupCard}>
        <Grid container direction="column" style={{flexGrow: 1, minHeight: 356}} justify="space-between">
          <Grid item>
            <Grid xs={12} container item justify="end">
              <Link
                style={{padding: 0}}
                to={
                  props.userProfileId && props.userProfileId === props.investor.id
                    ? `/yatirimci-profilim`
                    : `/yatirimci/${props.investor.slug}`
                }
              >
                <Grid item>
                  {props.investor.profilePhoto ? (
                    <img
                      src={props.investor.profilePhoto}
                      className={classes.img}
                      alt="logo"
                      style={{objectFit: "contain"}}
                    ></img>
                  ) : (
                    <Grid
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#003CA5"
                      }}
                    >
                      <Typography variant="h4" style={{color: "#fff"}}>
                        {props.investor.firstName && props.investor.firstName.charAt(0)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Link>
              <Grid container direction="column" className={classes.titleBar} xs>
                <Link
                  style={{padding: 0}}
                  to={
                    props.userProfileId && props.userProfileId === props.investor.id
                      ? `/yatirimci-profilim`
                      : `/yatirimci/${props.investor.slug}`
                  }
                >
                  <MaxLineTypography variant="h5" maxLine={1}>
                    <Typography variant="h5" align="left">
                      {props.investor.firstName} {props.investor.lastName}
                    </Typography>
                  </MaxLineTypography>
                </Link>
                {props.investor.companyRole ? (
                  <MaxLineTypography variant="h5" maxLine={2}>
                    <Typography variant="body2" align="left" style={{fontSize: 12, color: "#425466"}}>
                      {props.investor.companyRole}
                    </Typography>
                  </MaxLineTypography>
                ):(
                  <MaxLineTypography variant="h5" maxLine={2}>
                    <Typography variant="body2" align="left" style={{fontSize: 12, color: "#425466"}}>
                    ‏‏‎ ‎
                    </Typography>
                  </MaxLineTypography>
                )}
                <Grid className={classes.socialMargin} item style={{display: "flex", alignItems: "center"}}>
                  {props.investor.socialLinkedIn && (
                    <a href={props.investor.socialLinkedIn} style={{padding: 0, marginRight: 20}} target="_blank">
                      <img src={linkedin} alt={linkedin} style={{width: 16}} />
                    </a>
                  )}
                  {props.investor.socialTwitter && (
                    <a href={props.investor.socialTwitter} style={{padding: 0, marginRight: 20}} target="_blank">
                      <img src={twitter} alt={twitter} style={{width: 18, marginTop: 5}} />
                    </a>
                  )}
                  {props.investor.website && (
                    <a
                      href={
                        props.investor.website.match(/^https?:/)
                          ? props.investor.website
                          : "//" + props.investor.website
                      }
                      style={{padding: 0, marginTop: 3}}
                      target="_blank"
                    >
                      <img src={worldIcon} alt={worldIcon} style={{width: 18}} />
                    </a>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="scroll-class" style={{marginTop: 4}}>
              {props.investor.investmentType && (
                <Grid item>
                  <GreenTag title={props.investor.investmentType} />
                </Grid>
              )}
              {props.investor?.interestedIndustries?.length + props.investor?.interestedStage?.length > 1 && (
                <Grid item style={{display: "flex", alignItems: "center"}}>
                  <Typography variant="h6" style={{fontSize: 12, fontWeight: 700, color: "#718096"}}>
                    +{props.investor.interestedIndustries.length + props.investor.interestedStage.length}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {props.investor.biography && props.investor.biography !== "" && (
              <Grid item style={{marginTop: 20}}>
                <Typography variant="body2" align="left" className={classes.desc}>
                  {truncateString(props.investor.biography, 120)}
                </Typography>
              </Grid>
            )}
          </Grid>
          {props.userProfileId && props.userProfileId === props.investor.id ? (
            <Grid container justify="center" style={{marginTop: 20}}>
              <Link style={{padding: 0}} to={`/yatirimci-profilim`}>
                <Button variant="outlinedPrimary"> Profilim </Button>
              </Link>
            </Grid>
          ) : (
            <Grid
              container
              justify="center"
              style={{marginTop: 20, width: "100%"}}
              alignItems="stretch"
              direction="column"
              alignContent="center"
            >
              <Link style={{padding: 0, width: "fit-content"}} to={`/yatirimci/${props.investor.slug}`}>
                <Button variant="outlinedPrimary"> Profili Görüntüle </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
