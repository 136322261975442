import {ALL_CONVERSATIONS, ALL_MESSAGES} from "../../../queries";
import {Button, Grid, InputAdornment, TextField, makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import {useHistory} from 'react-router-dom'

import {CREATE_CONVERSATION, CREATE_MESSAGE} from "../../../mutations";
import {useMutation} from "react-apollo";
import { useTempConversation } from "../../../context/tempConversation";

const useStyles = makeStyles(theme => ({
  adornedEnd: {
    paddingRight: 8
  },
  iconButton: {
    padding: "8px 32px"
  },
  notchedOutline: {
    border: "unset",
    height: 60
  }
}));

export default function SendMessageInput({userId, receivedUserId, conversationId, temporaryConversation}) {
  const classes = useStyles();
  const history = useHistory();
  const {endTemporaryConversation} = useTempConversation();
  const [message, setMessage] = useState("");
  const [createMessage, {data, loading, error}] = useMutation(CREATE_MESSAGE, {
    refetchQueries: [
      {
        query: ALL_MESSAGES
      },
      {
        query: ALL_CONVERSATIONS
      }
    ]
  });

  const [createConversation, {loading:loadingCreateConversation, error:errorCreateConversation}] = useMutation(CREATE_CONVERSATION, {
    onCompleted: (data) => {
      endTemporaryConversation();
      handlecreateMessage(data.createConversation.id);
      history.push("/mesajlar/-2");
    },
    update: (cache, {data}) => {
      console.log("burada")
      const allConversations = cache.readQuery({
        query: ALL_CONVERSATIONS,  
      });
      console.log(allConversations);
      console.log(data);
      data.createConversation.users = data.createConversation.users.filter((user) => user.id !== userId);
      cache.writeQuery({
        query: ALL_CONVERSATIONS,
        data: {allConversations: [data.createConversation].concat(allConversations.allConversations)}
      })
    }
  });

  const handleEnter = () =>{
    if(temporaryConversation){
      createConversation({
        variables: {usersIds: [userId, receivedUserId]},
        refetchQueries: [{query: ALL_CONVERSATIONS}],
      })
    }
    else{
      handlecreateMessage(conversationId);
    }
  };

  const handlecreateMessage = (conversationId) => {
    if (message.trim()) {
      setMessage("");
      createMessage({variables: {message: message, conversationId: conversationId}}).then(() => {
        setTimeout(function () {
          var objDiv = document.getElementById("text-screen");
          if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }, 70);
      });
    }
  };

  return (
    <Grid container>
      <TextField
        fullWidth
        placeholder="Mesaj..."
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleEnter();
          }
        }}
        InputProps={{
          classes: {
            adornedEnd: classes.adornedEnd,
            notchedOutline: classes.notchedOutline
          },
          endAdornment: (
            <InputAdornment position="end">
              <Button
                className={classes.iconButton}
                variant="contained"
                disabled={!message.trim() || loadingCreateConversation || errorCreateConversation}
                color="primary"
                onClick={() => handleEnter()}
              >
                Gönder
              </Button>
            </InputAdornment>
          ),
          style: {
            border: "unset"
          }
        }}
        variant="outlined"
        value={message}
        onChange={e => !loadingCreateConversation && setMessage(e.target.value)}
      />
    </Grid>
  );
}
