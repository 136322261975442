import * as yup from 'yup';

const phoneRegExp = /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;

export const loginSchema = yup.object().shape({
  email: yup.string().email('Geçersiz bir e-posta adresi girdiniz.').required('Bu alanı boş bırakamazsınız.'),

  password: yup.string().required('Bu alanı boş bırakamazsınız.'),
});

export const personSchema = yup.object().shape({
  email: yup.string().email('Geçersiz bir e-posta adresi girdiniz.').required('Bu alanı boş bırakamazsınız.'),

  nameSurname: yup.string().required('Bu alanı boş bırakamazsınız.'),

  isPermitted: yup.boolean().oneOf([true], 'error'),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().required('Bu alanı boş bırakamazsınız.'),
});
export const setPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Şifre Gerekli')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@.$!%*#?&]{8,}$/,
      'Şifreniz en az 8 haneden oluşmalı ve rakam, büyük ve küçük harf içermelidir.',
    ),
  passwordAgain: yup
    .string()
    .required('Şifre Onayı Gerekli')
    .oneOf([yup.ref('password'), null], 'Şifreniz eşleşmeli'),
});
export const signupSchema = yup.object().shape({
  email: yup.string().email('Geçersiz bir e-posta adresi girdiniz.').required('Bu alanı boş bırakamazsınız.'),

  firstName: yup.string().required('Bu alanı boş bırakamazsınız.'),

  lastName: yup.string().required('Bu alanı boş bırakamazsınız.'),

  phoneNumber: yup.string().required('Bu alanı boş bırakamazsınız.'),

  city: yup.string().required('Bu alanı boş bırakamazsınız.'),

  password: yup
    .string()
    .required('Şifre Gerekli')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Şifreniz en az 8 haneden oluşmalı ve rakam, büyük ve küçük harf içermelidir.',
    ),

  passwordConfirm: yup
    .string()
    .required('Şifre Onayı Gerekli')
    .oneOf([yup.ref('password'), null], 'Girdiğiniz şifreler eşleşmiyor. Lütfen tekrar deneyin.'),

  userAgreement: yup.boolean().oneOf([true], 'Sisteme kayıt olabilmek için sözleşmeleri onaylamanız gerekmektedir.'),

  confidentialityAgreement: yup
    .boolean()
    .oneOf([true], 'Sisteme kayıt olabilmek için sözleşmeleri onaylamanız gerekmektedir.'),
});

export const changePasswordSchema = yup.object().shape({
  newPassword1: yup
    .string()
    .required('Şifre Gerekli')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Şifreniz en az 8 haneden oluşmalı ve rakam, büyük ve küçük harf içermelidir.',
    ),

  newPassword2: yup
    .string()
    .required('Şifre Onayı Gerekli')
    .oneOf([yup.ref('newPassword1'), null], 'Şifreniz eşleşmeli'),
});

export const detailSchema = yup.object().shape({
  detailRevenueModels: yup.array().of(
    yup.object().shape({
      title: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
      description: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
      revenuePercentage: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    }),
  ),
});

export const popupSchema = [
  yup.object().shape({
    hasEverInvested: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Individual Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    corporateName: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Corporate Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    corporateDefinition: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Corporate Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    corporateRole: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Corporate Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    corporateSectors: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Corporate Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    companyName: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    companyWebsite: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    companySector: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    companyRole: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    investorShareRate: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
  }),
  yup.object().shape({
    corporateReservedToInvest: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Corporate Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    corporateRiskPreference: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Corporate Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    interestedSector: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Individual Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    plannedAmountOfAngelInvestment: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Individual Investor',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
  }),
  yup.object().shape({
    purposeEnteringSB: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    hearSBFrom: yup
      .string()
      .nullable()
      .when('type', {
        is: 'Shareholder',
        then: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
        otherwise: yup.string().nullable().notRequired(),
      }),
  }),
  yup.object().shape({
    purposeEnteringSB: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    hearSBFrom: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  }),
];

export const investorProfileSchema = [
  yup.object().shape({
    investmentType: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    cityId: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  }),
  yup.object().shape({
    portfolioCompanyCount: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    exitedCompanyCount: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    interestedStage: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    interestedIndustries: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  }),
  yup.object().shape({}),
];

export const kpiSchema = yup.object().shape({
  title: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  dataFrequency: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  categoryId: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
});

export const reportSchema = yup.object().shape({
  title: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  dataFrequency: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  startDate: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  // personIds: yup.array().required('Gerekli'),
  // groupIds: yup.array().required('Gerekli'),
});
export const reportSchemaForKpiList = yup.object().shape({
  dataFrequency: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  startDate: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  personIds: yup.array().when('isPublic', {
    is: 'Hayır',
    then: yup.array().required('Bu alanı boş bırakamazsınız.'),
    otherwise: yup.array().notRequired(),
  }),
  groupIds: yup.array().when('personIds', {
    is: [],
    then: yup.array().required('Bu alanı boş bırakamazsınız.'),
    otherwise: yup.array().notRequired(),
  }),
  // personIds: yup.array().required('Gerekli'),
  // groupIds: yup.array().required('Gerekli'),
});

export const reportEditSchema = yup.object().shape({
  content: yup.array().of(
    yup.object().shape({
      title: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
    }),
  ),
});

export const startupCreateSchema = yup.object().shape({
  website: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  stage: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  oneliner: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  sector: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  currency: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
});

export const startupInviteSchema = yup.object().shape({
  website: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  contactMail: yup.string().email('Geçersiz bir e-posta adresi girdiniz.').required('Bu alanı boş bırakamazsınız.'),
  contactNameSurname: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
});

export const investorInvestToStartup1 = yup.object().shape({
  communicationMethod: yup.string('lütfen seçiminizi yapınız.').nullable().required('Bu alanı boş bırakamazsınız.'),
  investmentAmount: yup
    .number()
    .required('Bu alanı boş bırakamazsınız.')
    .min(yup.ref('minTicketSize'), 'Yatırım talebi minimum yatırım miktarından küçük olamaz'),
});
export const investorInvestToStartup2 = yup.object().shape({
  adress: yup.string().required('Bu alanı boş bırakamazsınız.'),
  telephone: yup
    .string()
    .required('Bu alanı boş bırakamazsınız.')
    .matches(/^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/, 'Lütfen geçerli bir telefon giriniz.'),
  email: yup.string().email('Geçersiz bir e-posta adresi girdiniz.').required('Bu alanı boş bırakamazsınız.'),
});

export const sharesSchema = yup.object().shape({
  currencySymbol: yup.string().required('Bu alanı boş bırakamazsınız.'),
  contactPreference: yup.string().required('Bu alanı boş bırakamazsınız.'),
  companyName: yup.string().required('Bu alanı boş bırakamazsınız.'),
  companyWebsite: yup.string().required('Bu alanı boş bırakamazsınız.'),
  companyAuthorityFullname: yup.string().required('Bu alanı boş bırakamazsınız.'),
  companyAuthorityEmail: yup
    .string()
    .email('Geçersiz bir e-posta adresi girdiniz.')
    .required('Bu alanı boş bırakamazsınız.'),

  minAmount: yup
    .number()
    .required('Bu alanı boş bırakamazsınız.')
    .max(yup.ref('maxAmount'), 'Minimum tutar maksimum tutardan büyük olamaz'),
  maxAmount: yup
    .number()
    .required('Bu alanı boş bırakamazsınız.')
    .min(yup.ref('minAmount'), 'Maksimum tutar minimum tutardan küçük olamaz'),
});

export const sharesSchemaListed = yup.object().shape({
  currencySymbol: yup.string().required('Bu alanı boş bırakamazsınız.'),
  contactPreference: yup.string().required('Bu alanı boş bırakamazsınız.'),
  minAmount: yup
    .number()
    .required('Bu alanı boş bırakamazsınız.')
    .max(yup.ref('maxAmount'), 'Minimum tutar maksimum tutardan büyük olamaz'),
  maxAmount: yup
    .number()
    .required('Bu alanı boş bırakamazsınız.')
    .min(yup.ref('minAmount'), 'Maksimum tutar minimum tutardan küçük olamaz'),
});

export const investmentSprintFormSchema = yup.object().shape({
  sprintClosingDate: yup.date().required('Bu alanı boş bırakamazsınız.'),
  minTicketSize: yup
    .number('Lütfen sayısal bir değer girin')
    .positive('Lütfen pozitif bir sayı girin')
    .required('Bu alanı boş bırakamazsınız.'),
  requiredInvestmentAmount: yup
    .number('Lütfen sayısal bir değer girin')
    .positive('Lütfen pozitif bir sayı girin')
    //.min(yup.ref('minRequiredInvestmentAmount'), "Bu alan Min Aranan Yatırım Miktarı'ndan fazla olmalıdır.")
    .required('Bu alanı boş bırakamazsınız.'),
  /*minRequiredInvestmentAmount: yup
    .number('Lütfen sayısal bir değer girin')
    .positive('Lütfen pozitif bir sayı girin')
    .required('Bu alanı boş bırakamazsınız.'),*/
  /*anticipatedEvaluation: yup
    .number('Lütfen sayısal bir değer girin')
    .positive('Lütfen pozitif bir sayı girin')
    .min(yup.ref('oldEvaluation'), "Bu alan Yatırım Öncesi Değerleme'den fazla olmalıdır.")
    .required('Bu alanı boş bırakamazsınız.'),*/
  oldEvaluation: yup
    .number('Lütfen sayısal bir değer girin')
    .positive('Lütfen pozitif bir sayı girin')
    .required('Bu alanı boş bırakamazsınız.'),
  hasReceivedCommitment: yup.string().required('Bu alanı boş bırakamazsınız.'),
  sprintCurrencySymbol: yup.string().nullable().required('Bu alanı boş bırakamazsınız.'),
  oldAdditionalCommitment: yup.number().when('hasReceivedCommitment', {
    is: true,
    then: yup.number().required('Bu alanı boş bırakamazsınız.'),
  }),
});
