import { TextField, Typography, Grid } from '@material-ui/core';
import { useField } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>({
  inputField: {
        width: '100%',
        borderRadius: 6,
        marginTop: 0,
        boxShadow:'0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
        '&::placeholder':{
          color: '#B5B5BD',
        }
    },
}))


export default function FormikTextField({ label, ...props }) {
  const [showPassword] = React.useState(false);
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
    <Grid style={{width:'100%'}}>
      <TextField
        variant="outlined"
        className={classes.inputField}
        {...field}
        {...props}
        error={meta.touched && meta.error}
        placeholder={props.placeholder}
        type={props.type}
        InputProps={{ inputProps: { min: props.min }, 
        endAdornment:
          props.endIcon && 
          <InputAdornment position="end">
            <img src={props.endIcon} alt="end-icon" />
          </InputAdornment> }}
      />
      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 0, textAlign:'left'}}>
          {meta.error}
        </Typography>
      ) : null}
      </Grid>
    </>
  );
}
