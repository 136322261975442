import ReportList from './ReportList'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const DELETE_REPORT = gql`
  mutation startupReportDelete($id:ID!) {
    startupReportDelete(id: $id) 
  }
`;

const CREATE_REPORT_LIST = gql`
  mutation startupReportListCreate($title: String!, $startDate: String!, $dataFrequency: String!, $isPublic: String!, $personIds: [ID], $groupIds: [ID]) {
    startupReportListCreate(title: $title, startDate: $startDate, dataFrequency: $dataFrequency, isPublic: $isPublic,  personIds: $personIds , groupIds: $groupIds) 
  }
`;

const CREATE_LIST_ITEM = gql`
  mutation startupReportItemCreate($listId: ID!) {
    startupReportItemCreate(listId: $listId) 
  }
`;

const EDIT_REPORT_LIST = gql`
  mutation startupReportListEdit($id: ID!,$title: String!, $startDate: String!, $dataFrequency: String!, $isPublic: String!, $personIds: [ID], $groupIds: [ID]) {
    startupReportListEdit(id: $id,title: $title, startDate: $startDate, dataFrequency: $dataFrequency, isPublic: $isPublic, personIds: $personIds , groupIds: $groupIds) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_REPORT_LIST, {
    name: 'startupReportListCreate',
  }),
  graphql(CREATE_LIST_ITEM, {
    name: 'startupReportItemCreate',
  }),
  graphql(EDIT_REPORT_LIST, {
    name: 'startupReportListEdit',
  }),
  graphql(DELETE_REPORT, {
    name: 'startupReportDelete',
  }),
)(ReportList);
export default JoinWithMutation;