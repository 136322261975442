import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import ListKPIParasut from './ListKPIParasut';

const PA_EDIT_LIST = gql`
  mutation kpiParasut ($listId: ID, $kpiParasutIds: [ID!]) {
      kpiParasut(listId: $listId, kpiParasutIds: $kpiParasutIds) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(PA_EDIT_LIST, {
    name: 'kpiParasut',
  }),
)(ListKPIParasut);
export default JoinWithMutation;