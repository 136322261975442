
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import get from 'lodash/get';
import InvestorLanding from '../../static/img/investor-profile-landing.jpg';
import bluePen from '../../static/icon/blue-pen.svg';
import CloudUpload from '../../static/icon/cloud-upload.svg';
import newPerson from '../../static/icon/profile-menu-icon.svg';
import websiteEndIcon from '../../static/icon/website-end-icon.svg';
import organizationEndIcon from '../../static/icon/organization-end-icon.svg';
import ExitEndIcon from '../../static/icon/exit-end-icon.svg';
import attachmentEndIcon from '../../static/icon/attachment-end-icon.svg';
import roleEndIcon from '../../static/icon/role-end-icon.svg';
import dumbIcon from '../../static/icon/dumb.svg';
import dumbIcon2 from '../../static/icon/dumb-icon-2.svg';
import sureIcon from '../../static/icon/sure-icon.svg';
import { MY_PROFILE } from '../../queries';
import worldIcon from '../../static/icon/world-icon.svg';
import inviteIcon from '../../static/icon/invite-icon.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Wizard from './Wizard.js';
import Hidden from '@material-ui/core/Hidden';
import { Field, FieldArray, Formik, Form, useField, useFormikContext } from 'formik';
import Dropzone from 'react-dropzone';
import ImageCrop from "../../Components/ImageCrop";
import ImageCropBackground from "../../Components/ImageCropBackground";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import FormikTextField from "../../Components/FormikShortTextField";
import FormikProvinceDropdown from "../../Components/FormikProvinceDropdown";
import FormikDropdownStage from "../../Components/FormikDropdownStage";
import FormikDropdownInvestType from "../../Components/FormikDropdownInvestType";
import FormikMultiLine from "../../Components/FormikMultiLine";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import newCross from '../../static/icon/new-cross.svg';
import DatePicker, { registerLocale } from "react-datepicker";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import tr from "date-fns/locale/tr";
import FormikMultiSelect from "../../Components/FormikMultiSelectIndustry";
import moment from 'moment'
import { DebounceInput } from 'react-debounce-input';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import selectedKpi from '../../static/icon/selected-kpi.svg';
import GreenTag from '../../Components/greenTag.js';
import newPlusBlue from '../../static/icon/new-plus-blue.svg';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  companyGrid: {
    padding: '16px',
    borderRadius: '6px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#fff',
    marginBottom: 16
  },
  whiteGrid: {
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    marginBottom: 25,
    padding: '16px 40px'
  },
  yatırımButton: {
    fontWeight: 'normal',
    padding: '11.5px 47px',
    fontSize: 12,
    marginTop: 16
  },
  socialLink: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 25
    },
    color: '#003CA5'
  },
  companyCard: {
    padding: 16,
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    borderRadius: 16,
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    }
  },
  whiteCard: {
    minHeight: 230,
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    textAlign: 'center',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    padding: 50,
    [theme.breakpoints.down('lg')]: {
      padding: 35,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
      padding: 25,
    },
  },
  whiteCardEdit: {
    minHeight: 230,
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    textAlign: 'center',
    padding: 50,
    paddingTop: 30,
    [theme.breakpoints.down('lg')]: {
      padding: 35,
      paddingTop: 30
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
      marginBottom: 50,
      padding: 25,
    },
  },
  profileSection: {
    textAlign: 'left',
    width: '100%',
    position: 'relative',
    marginTop: -65,
    marginLeft: 35,
    [theme.breakpoints.down('sm')]: {
      marginTop: 35,
      marginLeft: 16,
    },
  },
  backgroundSection: {
    textAlign: 'center',
    width: '100%',
    position: 'relative',
    paddingRight: 25,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0
    },
  },
  penGrid: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    width: 32,
    height: 32,
    zIndex: 2,
    borderRadius: '50%'
  },
  mainContainer: {
    width: 100,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    padding: '0px 15px 0 24px',
    maxHeight: 600,
    [theme.breakpoints.down('sm')]: {
      padding: '16',
    },
  },
  mobilePadding: {
    padding: '65px',
    paddingTop: 32,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      paddingTop: 16,
    },
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '180px',
    width: '100%',
    justifyContent: 'center',
    borderRadius: 16,
    border: '2px dashed #003CA5',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '500',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  baseStyle2: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '120px',
    width: '120px',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#003CA5',
    color: '#fff',
    fontWeight: '600',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    position: 'relative'
  },
  backgroundPadding: {
    padding: '0 180px',
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  uploadGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 80,
    height: 80,
    background: '#E4EEFF',
    marginBottom: 16
  },
  addCareer: {
    cursor: 'pointer',
    border: '1px dashed #003CA5',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
  },
  startupLogo: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)'
  },
  nameGrid: {
    marginTop: -30,
    marginLeft: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: 0,
    },
    fontSize: 20
  },
  selected: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  search: {
    position: 'relative',
    width: '100%',
  },
  searchBox: {
    width: '100%',
    maxWidth: 350,
    minWidth: 125,
    background: '#ffffff',
    borderRadius: 6,
    marginTop: 5,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#7c7c7c',
  },
  Debounce: {
    width: '100%',
    fontSize: 13,
    height: '50px',
    fontFamily: 'inherit',
    outline: 'none',
    border: 'none',
    background: '#FFFFFF',
    color: '#718096',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    padding: '26px 56px 26px 0',
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    borderRadius: 6,
    '&:focus': {
      border: '1px solid #003CA5',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  blueGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 116,
    height: 116,
    background: '#F0F5FF',
    borderRadius: '50%',
    marginBottom: 24
  }
}));

const FormikCheckBox = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <FormControlLabel style={{ marginRight: 0 }} control={<Checkbox {...field} checked={field.value} color="primary" />} />
      {label}
    </>
  );
};

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

const handleTypename = (values) => {
  values = values.map(({ __typename, ...array }) => array)
  return values
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      style={{ zIndex: 999 }}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      maxDate={new Date()}
      locale="tr"
      customInput={
        <input
          autocomplete="off"
          style={{
            width: '100%',
            padding: '13px',
            borderRadius: 3,
            border: '1px solid rgb(221 221 221 / 56%)',
            boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
            marginTop: 10
          }}
          type="text"
          placeholder={'mm'} />
      }
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

function validateLinkedinUrl(value) {
  if (value !== '' && value) {
    let error;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?');// query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}
function validateLinkedinUrl2(value) {
  if (value !== '' && value) {
    let error;
    var pattern = new RegExp(/linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/); // query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}

function EditCareerDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, index, careerItem, form } = props;

  const handleSubmit = variables => {
    form.setFieldValue(`career.${index}`, variables)
    onClose(null);
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h2" style={{ marginBottom: 20 }}>
          {t('Deneyim Ekle')}
        </Typography>
        <Typography align="center" variant="body1" style={{ marginTop: 25, fontSize:16 }}>
          {t('Geçmiş deneyimlerinizin tamamını StartupBorsa ile paylaşın')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              company: careerItem.company,
              jobTitle: careerItem.jobTitle,
              startDate: careerItem.startDate,
              endDate: careerItem.endDate,
              isPresent: careerItem.isPresent
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="company"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Ünvan"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="jobTitle"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirket Adı"
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap', marginBottom: 10 }}>
                        <FormikCheckBox
                          name="isPresent"
                          type="Checkbox"
                          label={
                            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                              {t("Şu an bu pozisyonda çalışıyorum")}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ marginBottom: 35 }}>
                        <Typography variant="h6" style={{ display: 'flex' }}>
                          {t('Başlama Tarihi')}
                        </Typography>
                        <DatePickerField
                          name='startDate'
                        />
                      </Grid>
                      {!props.values.isPresent &&
                        <Grid item container xs={12} style={{ marginBottom: 35 }}>
                          <Typography variant="h6" style={{ display: 'flex' }}>
                            {t('Bitiş Tarihi')}
                          </Typography>
                          <DatePickerField
                            name='endDate'
                            minDate={props.values.startDate}
                          />
                        </Grid>
                      }
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            disabled={!props.values.company || !props.values.jobTitle || !props.values.startDate}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Ekle')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function AddCareerDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, push } = props;

  const handleSubmit = variables => {
    push(variables);
    onClose(null);
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h2" style={{ marginBottom: 20 }}>
          {t('Deneyim Ekle')}
        </Typography>
        <Typography align="center" variant="body1" style={{ marginTop: 25, fontSize:16 }}>
          {t('Geçmiş deneyimlerinizin tamamını StartupBorsa ile paylaşın')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              company: '',
              jobTitle: '',
              startDate: '',
              endDate: '',
              isPresent: false
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="company"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Ünvan"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="jobTitle"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirket Adı"
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap', marginBottom: 10 }}>
                        <FormikCheckBox
                          name="isPresent"
                          type="Checkbox"
                          label={
                            <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                              {t("Şu an bu pozisyonda çalışıyorum")}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ marginBottom: 35 }}>
                        <Typography variant="h6" style={{ display: 'flex' }}>
                          {t('Başlama Tarihi')}
                        </Typography>
                        <DatePickerField
                          name='startDate'
                        />
                      </Grid>
                      {!props.values.isPresent &&
                        <Grid item container xs={12} style={{ marginBottom: 35 }}>
                          <Typography variant="h6" style={{ display: 'flex' }}>
                            {t('Bitiş Tarihi')}
                          </Typography>
                          <DatePickerField
                            name='endDate'
                            minDate={props.values.startDate}
                          />
                        </Grid>
                      }
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            disabled={!props.values.company || !props.values.jobTitle || !props.values.startDate}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Ekle')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function InviteStartupDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, setSuccess } = props;

  const handleSubmit = variables => {
    props.investorInviteStartup({ variables }).then((data) => {
      if (data.data.investorInviteStartup) {
        setSuccess(true)
        onClose(null);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={inviteIcon} alt="invite-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2" style={{ marginBottom: 20 }}>
          {t('Şirketi Davet Et')}
        </Typography>
        <Typography align="center" variant="body1" style={{ marginTop: 25 }}>
          {t('Bu şirketi StartupBorsa’ya davet etmek istediğinizden emin misiniz?')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              title: '',
              website: '',
              contactNameSurname: '',
              contactMail: '',
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="title"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirketin Adı"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="website"
                          style={{ width: '100%' }}
                          type="text"
                          validate={validateLinkedinUrl}
                          placeholder="Şirketin Websitesi"
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ marginBottom: 15 }}>
                        <FormikTextField
                          name="contactNameSurname"
                          style={{ width: '100%' }}
                          type="text"
                          placeholder="Şirket Yetkilisinin Adı Soyadı"
                        />
                      </Grid>
                      <Grid item container xs={12}>
                        <FormikTextField
                          name="contactMail"
                          style={{ width: '100%' }}
                          type="email"
                          placeholder="Şirket Yetkilisinin Mail Adresi"
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginBottom: 20, height: 'fit-content' }}>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="outlinedSecondary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            onClick={() => onClose()}
                          >
                            {t('Vazgeç')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%', padding: '12px 43px' }}
                            disabled={!props.values.title || !props.values.website || !props.values.contactNameSurname || !props.values.contactMail}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {t('Davet Et')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function CareerPart(props) {
  const { index, remove, careerItem, form } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Grid xs={12} container style={{ marginBottom: 15 }} justify="space-between">
        <Grid item>
          <Typography variant="h6" align="left" style={{ marginBottom: 4 }}>
            {careerItem.company},
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: 4 }}>
            {careerItem.jobTitle}
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: 4 }}>
            {careerItem.startDate && moment(careerItem.startDate).format('MMMM YYYY')} - {careerItem.isPresent ? 'Şu Anda' : (careerItem.endDate && moment(careerItem.endDate).format('MMMM YYYY'))}
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <img onClick={() => remove(index)} src={dumbIcon} alt="dumb-icon" style={{ cursor: 'pointer', marginRight: 24 }} />
          <Typography variant="h6" color="primary" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
            Düzenle
          </Typography>
        </Grid>
        <EditCareerDialog open={open} onClose={() => setOpen(false)} form={form} careerItem={careerItem} index={index} />
      </Grid>
    </div>
  );
}

function RemoveDialog(props) {
  const classes = useStyles();
  const { onClose, open, selected, setSelected, removeItem } = props;

  const handleSubmit = () => {
    setSelected(selected.filter((x) => x.id !== removeItem.id));
    onClose();
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={onClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={sureIcon} alt="sure-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2">
          Şirketi Kaldır
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777', fontSize:16 }}>
            {removeItem.title} şirketini portföyünüzden kaldırmak istediğinize emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }}
                onClick={() => {
                  onClose(null);
                }}
                variant="outlinedSecondary"
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  width: '100%',
                  marginTop: 20,
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
              >
                Şirketi Kaldır
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}


export default function InvestorProfile(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [openPhoto, setOpenPhoto] = React.useState(false);
  const [addCareer, setAddCareer] = React.useState(false);
  const [filesProfile, setFilesProfile] = React.useState([]);
  const [investor, setInvestor] = React.useState();
  const [openPhotoProfile, setOpenPhotoProfile] = React.useState(false);
  const [city, setCity] = React.useState();
  const [search, setSearch] = React.useState("");
  const [openPopover, setOpenPopover] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [myProfile, setMyProfile] = React.useState(null);
  const [remove, setRemove] = React.useState(false);
  const [removeItem, setRemoveItem] = React.useState();
  const [invite, setInvite] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [wizardError, setWizardError] = React.useState("");

  const SEARCH_STARTUPS = gql`
  query topStartupSearch($search: String){
        topStartupSearch(search:$search){
        id
        title
        oneliner
        logo
        sector
        slug
        website
        businessModelArray
        country {
          id
          title
        }
      }
  }
  `;

  const [getData, { loading: loadingSearch, data: dataSearch }] = useLazyQuery(SEARCH_STARTUPS, {
    fetchPolicy: 'network-only',
    variables: { search: search },
  });

  const handleClickSearch = () => {
    setOpenPopover(true);
  };

  const HandleSearch = event => {
    setSearch(event.target.value);
    getData()
  };

  const handleClickAway = () => {
    setSearch("");
    setOpenPopover(false);
  };

  const stage = [
    'Fikir aşaması',
    'MVP',
    'Erken Aşama',
    'Büyüme'
  ];

  const type = [
    "Melek Yatırımcı",
    "Venture Capital",
    "Private Equity",
    "Hızlandırma Programı",
    "Yatırım Ortaklığı",
    "Micro VC",
    "Melek Yatırım Ağı",
    "Kuluçka Merkezi",
    "Yatırım Bankası",
    "Aile Yatırım Şirketi",
    "Borç Finansmanı",
    "Paylaşımlı Ofis",
    "Fon Sepeti Fonu",
    "Risk Fonu",
    "Devlet Kurumu",
    "Üniversite Programı",
    "Girişimcilik Programı",
    "İkincil Pazar Yatırımcısı",
    "Sendikasyon Yatırımı"
  ];

  const industry = [
    'AI / Yapay Zeka',
    'Akıllı Şehirler',
    'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
    'Atık Yönetimi',
    'Bilişim Teknolojileri',
    'Biyoteknoloji',
    'Blokzinciri',
    'Data Analiz & İş zekası',
    'Donanım',
    'e-ticaret',
    'Eğitim & Eğitim Teknolojileri',
    'Eğlence & Müzik',
    'Etkinlik',
    'Ev hizmetleri ve teknolojileri ',
    'Finans & Bankacılık',
    'Finans Teknolojileri',
    'Gayrimenkul',
    'Geri Dönüşüm',
    'Giyilebilir Teknolojiler',
    'Hukuk Teknolojileri',
    'İnsan Kaynakları & İşe Alım',
    'Kriptopara',
    'Kurumsal Hizmetler',
    'Mikro Mobilite',
    'Moda & Güzellik',
    'Müşteri İlişkileri Yönetimi',
    'Nesnelerin İnterneti',
    'Oyun',
    'Perakende',
    'Regülasyon Teknolojileri',
    'Reklam & Pazarlama',
    'Robotik Teknolojileri',
    'SaaS',
    'Sağlık Hizmetleri & Sağlık Teknolojileri',
    'Seyahat & Turizm',
    'Siber Güvenlik',
    'Sigorta & Sigorta Teknolojileri',
    'Sosyal Girişimcilik',
    'Sosyal Medya',
    'Spor & Fitness',
    'Tarım & Tarım Teknolojileri',
    'Teknoloji',
    'Telekomünikasyon',
    'Temiz Teknoloji',
    'Teslimat & Lojistik',
    'Topluluk Yönetimi',
    'Tüketici Hizmetleri',
    'Ulaşım',
    'Video & Animasyon',
    'Yazılım',
    'Yenilenebilir Enerji',
    'Yiyecek & İçecek',
  ];

  const INVESTOR_PROFILE = gql`
  query myInvestorProfile{
    myInvestorProfile{
      id
      firstName
      lastName
      profilePhoto
      backgroundPhoto
      interestedIndustries
      biography
      website
      socialLinkedIn
      city{
        id 
        title
      }
      socialTwitter
      companyName
      companyRole
      portfolioCompanyCount
      investmentType
      exitedCompanyCount
      interestedStage
      career{
        company
        jobTitle
        startDate
        endDate
        isPresent
      }
      portfolios{
        id
        title
        oneliner
        logo
        sector
        slug
        website
        businessModelArray
        country {
          id
          title
        }
      }
    }
  }
  `;

  const { loading: loading, data: data, refetch } = useQuery(INVESTOR_PROFILE, {
    fetchPolicy: 'network-only',
    variables: {},
  });
  const { loading: loadingMyProfile, data: dataMyProfile } = useQuery(MY_PROFILE, {
    variables: {},
  });

  const ALL_CITIES = gql`
  query allCities($country:String) {
      allCities(country:$country){
          id
          title
      }        
  }
  `;

  const { loading: loading2, data: data2 } = useQuery(ALL_CITIES, {
    variables: { country: 'TR' },
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (data.myInvestorProfile) {
          if(!data.myInvestorProfile.interestedIndustries){
            data.myInvestorProfile.interestedIndustries = []
          }
          if(!data.myInvestorProfile.interestedStage){
            data.myInvestorProfile.interestedStage = []
          }
          if (data.myInvestorProfile.career !== [] && data.myInvestorProfile.career) {
            data.myInvestorProfile.career.forEach((car) => {
              if(car.startDate){
                car.startDate = moment(car.startDate).format('yyyy/MM');
              }
              if(car.endDate){
                car.endDate = moment(car.endDate).format('yyyy/MM');
              }
            });
            data.myInvestorProfile.career = handleTypename(data.myInvestorProfile.career)
          }
          if (data.myInvestorProfile.city) {
            data.myInvestorProfile.cityId = data.myInvestorProfile.city
          }
          setInvestor(data.myInvestorProfile);
          if (data.myInvestorProfile.portfolios) {
            setSelected(handleTypename(data.myInvestorProfile.portfolios));
          }
        }
      }

    }
    if (!loading2) {
      if (data2) {
        if (data2.allCities.length > 0) {
          setCity(data2.allCities);
        }
        else {
          setCity([{ id: 1, title: 'İzmir' }, { id: 2, title: 'İstanbul' }]);
        }
      }
    }
    if (!loadingMyProfile) {
      if (dataMyProfile) {
        if (dataMyProfile.myProfile) {
          setMyProfile(dataMyProfile.myProfile);
        }
      }
    }
  },
    [data, loading, data2, loading2, loadingMyProfile, dataMyProfile]);

  const thumb = {
    display: 'inline-flex',
    borderRadius: 16,
    width: '100%',
    height: 180,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    width: '100%',
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: 16,
    objectFit: 'fill',
  };

  const thumbsContainer2 = {
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
    flexWrap: 'wrap',
  };

  const thumb2 = {
    display: 'inline-flex',
    borderRadius: '50%',
    width: 120,
    height: 120,
    boxSizing: 'border-box'
  };

  const thumbInner2 = {
    display: 'flex',
    width: '100%',
    overflow: 'hidden'
  };

  const img2 = {
    display: 'block',
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid
            className={classes.mainContainer}
            xs={12}
            style={{ marginTop: 145, height: 'fit-content', maxWidth: 1350 }}
          >
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <img src={newPerson} style={{ width: 14, marginTop:5 }} alt="people" />
              <Typography variant="h4">Yatırımcı Profili Oluştur</Typography>
            </Breadcrumbs>
            <Grid container className={classes.mobilePadding}>
              {!edit && !investor ?
                <Grid xs={12} className={classes.whiteCard}>
                  <img src={InvestorLanding} alt="investor-landing" style={{ width: '100%', maxWidth: '600px', marginTop: 32 }} />
                  <Typography variant="h6" style={{ marginTop: 24, width:'100%' }}>
                    Girişimlerin ve diğer yatırımcıların size ulaşabilmesi için <br /> StartupBorsa’da bir profil oluşturabilirsiniz.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => {
                    setEdit(true);
                    setInvestor({
                      firstName: '',
                      lastName: '',
                      profilePhoto: null,
                      backgroundPhoto: null,
                      interestedIndustries: (myProfile && myProfile.interestedIndustries) ? myProfile.interestedIndustries : [],
                      biography: '',
                      website: '',
                      socialLinkedIn: '',
                      cityId: (myProfile && myProfile.city) ? myProfile.city : '',
                      socialTwitter: '',
                      companyName: '',
                      companyRole: '',
                      portfolioCompanyCount: null,
                      investmentType: '',
                      exitedCompanyCount: null,
                      interestedStage: [],
                      career: null
                    });
                  }
                  } style={{ width: '100%', maxWidth: 316, marginTop: 40 }}>
                    Profil Oluştur
                  </Button>
                </Grid>
                :
                <Grid xs={12} className={classes.whiteCardEdit}>
                  <Wizard
                    initialValues={investor}
                    myInvestorProfileSettings={props.myInvestorProfileSettings}
                    setWizardError={setWizardError}
                    selected={selected}
                    history={props.history}
                  >
                    <Wizard.Page>
                      <Grid container justify="center" style={{ borderBottom: '2px solid #EDF2F7', marginBottom: 45 }}>
                        <Grid item style={{ display: 'flex', marginRight: 50, borderBottom: '2px solid #003CA5', paddingBottom: 20 }}>
                          <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14, marginRight: 5 }}>
                            01
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14 }}>
                              Profil Bilgileri
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item style={{ display: 'flex', marginRight: 50 }}>
                          <Typography variant="h6" style={{ color: '#718096', fontSize: 14, marginRight: 5 }}>
                            02
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#718096', fontSize: 14 }}>
                              Yatırım Detayları
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item style={{ display: 'flex', marginRight: 50 }}>
                          <Typography variant="h6" style={{ color: '#718096', fontSize: 14, marginRight: 5 }}>
                            03
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#718096', fontSize: 14 }}>
                              Portföy Şirketleri
                            </Typography>
                          </Hidden>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid container className={classes.backgroundPadding}>
                          <Field>
                            {({ field, form, meta }) => (
                              <Dropzone
                                maxSize={20971520}
                                accept={'image/*'}
                                onDrop={(acceptedFiles) =>
                                  setFiles(
                                    acceptedFiles.map((file) =>
                                      Object.assign(file, { preview: URL.createObjectURL(file) }),
                                    ),
                                    form.setFieldValue(`backgroundPhoto`, acceptedFiles),
                                    setOpenPhoto(true),
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <section className={classes.backgroundSection}>
                                    {form.values.backgroundPhoto && (
                                      <Button
                                        variant="containedSecondary"
                                        style={{
                                          padding: '4.5px 15px',
                                          fontSize: 14,
                                          marginTop: 5,
                                          width: 90,
                                          top: 5,
                                          right: 36,
                                          position: 'absolute'
                                        }}
                                        onClick={() => form.setFieldValue(`backgroundPhoto`, '')}
                                      >
                                        Sil
                                      </Button>
                                    )}
                                    {typeof form.values.backgroundPhoto !== 'string' || form.values.backgroundPhoto === '' ? (
                                      !form.values.backgroundPhoto ? (
                                        <div {...getRootProps({ className: classes.baseStyle })}>
                                          <input {...getInputProps()} />
                                          <Grid container justify="center">
                                            <Grid item className={classes.uploadGrid}>
                                              <img src={CloudUpload} alt="cloud-upload" />
                                            </Grid>
                                          </Grid>
                                          <Grid item container direction="column" justify="center" alignItems="center">
                                            <Typography variant="h5" style={{ display: 'flex' }}>
                                              Kapak fotoğrafı <strong style={{ color: '#003CA5', margin: '0 5px' }}> yükle </strong> veya <strong style={{ color: '#003CA5', margin: '0 5px' }}> seç </strong>
                                            </Typography>
                                          </Grid>
                                        </div>
                                      ) : (
                                        <div style={{ border: 'none' }} {...getRootProps({ className: classes.baseStyle })}>
                                          <input {...getInputProps()} />
                                          <Grid container>
                                            {form.values.backgroundPhoto && (
                                              <div
                                                id={form.values.backgroundPhoto[0].name}
                                                style={thumb}
                                                key={form.values.backgroundPhoto[0].name}
                                              >
                                                <div style={thumbInner}>
                                                  <img src={form.values.backgroundPhoto[0].preview} style={img} alt="file" />
                                                </div>
                                              </div>
                                            )}
                                          </Grid>
                                        </div>
                                      )
                                    ) : (
                                      <div style={{ border: 'none' }} {...getRootProps({ className: classes.baseStyle })}>
                                        <img
                                          src={form.values.backgroundPhoto}
                                          alt="avatar"
                                          style={{ width: '100%', height: '100%', borderRadius: 16, objectFit: 'cover' }}
                                        />
                                      </div>
                                    )}
                                  </section>
                                )}
                              </Dropzone>
                            )}
                          </Field>
                          <Field>
                            {({ field, form, meta }) => (
                              <Dropzone
                                maxSize={20971520}
                                accept={'image/*'}
                                onDrop={(acceptedFiles) =>
                                  setFilesProfile(
                                    acceptedFiles.map((file) =>
                                      Object.assign(file, { preview: URL.createObjectURL(file) }),
                                    ),
                                    form.setFieldValue(`profilePhoto`, acceptedFiles),
                                    setOpenPhotoProfile(true),
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <section className={classes.profileSection}>
                                    {typeof form.values.profilePhoto !== 'string' || form.values.profilePhoto === '' ? (
                                      <div {...getRootProps({ className: classes.baseStyle2 })}>
                                        <input {...getInputProps()} />
                                        {!form.values.profilePhoto ? (
                                          <Grid item container direction="column" justify="center" alignItems="center">
                                            <Grid className={classes.penGrid}>
                                              <img src={bluePen} alt="blue-pen" />
                                            </Grid>
                                            <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 36 }}>
                                              {investor.firstName.charAt(0)}
                                            </Typography>
                                          </Grid>
                                        ) : (
                                          <Grid container>
                                            {form.values.profilePhoto && (
                                              <div
                                                id={form.values.profilePhoto[0].name}
                                                style={thumb2}
                                                key={form.values.profilePhoto[0].name}
                                              >
                                                <div style={thumbInner2}>
                                                  <img src={form.values.profilePhoto[0].preview} style={img2} alt="file" />
                                                </div>
                                              </div>
                                            )}
                                          </Grid>
                                        )}
                                      </div>
                                    ) : (
                                      <aside style={thumbsContainer2}>
                                        {form.values.profilePhoto && (
                                          <div
                                            id={form.values.profilePhoto}
                                            style={thumb2}
                                            key={form.values.profilePhoto}
                                          >
                                            <div style={thumbInner2}>
                                              <img src={form.values.profilePhoto} style={img2} alt="file" />
                                            </div>
                                          </div>
                                        )}
                                      </aside>
                                    )}
                                    <Grid container>
                                      <Grid item style={{ minWidth: 95 }}>
                                        {form.values.profilePhoto && (
                                          <Button
                                            variant="containedSecondary"
                                            style={{
                                              padding: '4.5px 15px',
                                              fontSize: 14,
                                              marginTop: 10,
                                              marginLeft: 15,
                                              width: 90,
                                            }}
                                            onClick={() => form.setFieldValue(`profilePhoto`, '')}
                                          >
                                            Sil
                                          </Button>
                                        )}
                                      </Grid>
                                      <Typography variant="h5" className={classes.nameGrid}>
                                        {investor.firstName} {investor.lastName}
                                      </Typography>
                                    </Grid>
                                  </section>
                                )}
                              </Dropzone>
                            )}
                          </Field>
                          <Grid container xs={12} spacing={3} style={{ marginTop: 50 }}>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="website" type="text" placeholder="Websitesi" validate={validateLinkedinUrl} endIcon={websiteEndIcon} style={{ paddingBottom: 0 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="companyName" type="text" placeholder="Mevcut Şirket" endIcon={organizationEndIcon} style={{ paddingBottom: 0 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                style={{ width: '100%', marginTop: 10 }}
                                component={FormikDropdownInvestType}
                                textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                name="investmentType"
                                options={type}
                              ></Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="socialLinkedIn" type="text" placeholder="Linkedin" validate={validateLinkedinUrl2} endIcon={attachmentEndIcon} style={{ paddingBottom: 0, marginTop: 10 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="companyRole" type="text" placeholder="Mevcut Ünvan" endIcon={roleEndIcon} style={{ paddingBottom: 0 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="socialTwitter" type="text" placeholder="Twitter" validate={validateLinkedinUrl} endIcon={attachmentEndIcon} style={{ paddingBottom: 0 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {city &&
                                <Field
                                  component={FormikProvinceDropdown}
                                  textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                  name="cityId"
                                  options={city}
                                >

                                </Field>
                              }
                            </Grid>
                            <Grid item xs={12}>
                              <FormikMultiLine name="biography" rows={8} type="text" placeholder="Hakkında" />
                            </Grid>
                            <Grid item xs={12}>
                              <FieldArray
                                name="career"
                                render={({ insert, remove, push, unshift, form }) => (
                                  <>
                                    {form.values.career && form.values.career.length > 0 && (
                                      <Grid item xs={12} className={classes.whiteGrid}>
                                        <Typography variant="h5" align="left" style={{ marginBottom: 16 }}>Deneyimler</Typography>
                                        {form.values.career.sort(function compare(a, b) {
                                          var dateA = new Date(a.startDate);
                                          var dateB = new Date(b.startDate);
                                          return dateB - dateA;
                                        }).map((careerItem, index) => (
                                          <CareerPart
                                            form={form}
                                            careerItem={careerItem}
                                            remove={remove}
                                            index={index}
                                          />
                                        ))}
                                      </Grid>
                                    )}
                                    <Grid className={classes.addCareer} onClick={() => setAddCareer(true)}>
                                      <Typography variant="h6" color="primary">
                                        Deneyim Ekle
                                    </Typography>
                                    </Grid>
                                    <AddCareerDialog open={addCareer} onClose={() => setAddCareer(false)} push={push} />
                                  </>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Field>
                          {({ field, form, meta }) => (
                            <ImageCropBackground
                              setFieldValue={form.setFieldValue}
                              files={files}
                              backgroundPhoto={form.values.backgroundPhoto}
                              setOpenPhoto={setOpenPhoto}
                              openPhoto={openPhoto}
                            />
                          )}
                        </Field>
                        <Field>
                          {({ field, form, meta }) => (
                            <ImageCrop
                              setFieldValue={form.setFieldValue}
                              files={filesProfile}
                              profilePhoto={form.values.profilePhoto}
                              setOpenPhoto={setOpenPhotoProfile}
                              openPhoto={openPhotoProfile}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Wizard.Page>
                    <Wizard.Page>
                      <Grid container justify="center" style={{ borderBottom: '2px solid #EDF2F7', marginBottom: 45 }}>
                        <Grid item style={{ display: 'flex', marginRight: 50 }}>
                          <Typography variant="h6" style={{ color: '#718096', fontSize: 14, marginRight: 5 }}>
                            01
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#718096', fontSize: 14 }}>
                              Profil Bilgileri
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item style={{ display: 'flex', marginRight: 50, borderBottom: '2px solid #003CA5', paddingBottom: 20 }}>
                          <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14, marginRight: 5 }}>
                            02
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14 }}>
                              Yatırım Detayları
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item style={{ display: 'flex', marginRight: 50 }}>
                          <Typography variant="h6" style={{ color: '#718096', fontSize: 14, marginRight: 5 }}>
                            03
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#718096', fontSize: 14 }}>
                              Portföy Şirketleri
                            </Typography>
                          </Hidden>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid container className={classes.backgroundPadding}>
                          <Field>
                            {({ field, form, meta }) => (
                              <Dropzone
                                maxSize={20971520}
                                accept={'image/*'}
                                onDrop={(acceptedFiles) =>
                                  setFiles(
                                    acceptedFiles.map((file) =>
                                      Object.assign(file, { preview: URL.createObjectURL(file) }),
                                    ),
                                    form.setFieldValue(`backgroundPhoto`, acceptedFiles),
                                    setOpenPhoto(true),
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <section className={classes.backgroundSection}>
                                    {form.values.backgroundPhoto && (
                                      <Button
                                        variant="containedSecondary"
                                        style={{
                                          padding: '4.5px 15px',
                                          fontSize: 14,
                                          marginTop: 5,
                                          width: 90,
                                          top: 5,
                                          right: 36,
                                          position: 'absolute'
                                        }}
                                        onClick={() => form.setFieldValue(`backgroundPhoto`, '')}
                                      >
                                        Sil
                                      </Button>
                                    )}
                                    {typeof form.values.backgroundPhoto !== 'string' || form.values.backgroundPhoto === '' ? (
                                      !form.values.backgroundPhoto ? (
                                        <div {...getRootProps({ className: classes.baseStyle })}>
                                          <input {...getInputProps()} />
                                          <Grid container justify="center">
                                            <Grid item className={classes.uploadGrid}>
                                              <img src={CloudUpload} alt="cloud-upload" />
                                            </Grid>
                                          </Grid>
                                          <Grid item container direction="column" justify="center" alignItems="center">
                                            <Typography variant="h5" style={{ display: 'flex' }}>
                                              Kapak fotoğrafı <strong style={{ color: '#003CA5', margin: '0 5px' }}> yükle </strong> veya <strong style={{ color: '#003CA5', margin: '0 5px' }}> seç </strong>
                                            </Typography>
                                          </Grid>
                                        </div>
                                      ) : (
                                        <div style={{ border: 'none' }} {...getRootProps({ className: classes.baseStyle })}>
                                          <input {...getInputProps()} />
                                          <Grid container>
                                            {form.values.backgroundPhoto && (
                                              <div
                                                id={form.values.backgroundPhoto[0].name}
                                                style={thumb}
                                                key={form.values.backgroundPhoto[0].name}
                                              >
                                                <div style={thumbInner}>
                                                  <img src={form.values.backgroundPhoto[0].preview} style={img} alt="file" />
                                                </div>
                                              </div>
                                            )}
                                          </Grid>
                                        </div>
                                      )
                                    ) : (
                                      <div style={{ border: 'none' }} {...getRootProps({ className: classes.baseStyle })}>
                                        <img
                                          src={form.values.backgroundPhoto}
                                          alt="avatar"
                                          style={{ width: '100%', height: '100%', borderRadius: 16, objectFit: 'cover' }}
                                        />
                                      </div>
                                    )}
                                  </section>
                                )}
                              </Dropzone>
                            )}
                          </Field>
                          <Field>
                            {({ field, form, meta }) => (
                              <Dropzone
                                maxSize={20971520}
                                accept={'image/*'}
                                onDrop={(acceptedFiles) =>
                                  setFilesProfile(
                                    acceptedFiles.map((file) =>
                                      Object.assign(file, { preview: URL.createObjectURL(file) }),
                                    ),
                                    form.setFieldValue(`profilePhoto`, acceptedFiles),
                                    setOpenPhotoProfile(true),
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <section className={classes.profileSection}>
                                    {typeof form.values.profilePhoto !== 'string' || form.values.profilePhoto === '' ? (
                                      <div {...getRootProps({ className: classes.baseStyle2 })}>
                                        <input {...getInputProps()} />
                                        {!form.values.profilePhoto ? (
                                          <Grid item container direction="column" justify="center" alignItems="center">
                                            <Grid className={classes.penGrid}>
                                              <img src={bluePen} alt="blue-pen" />
                                            </Grid>
                                            <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 36 }}>
                                              {investor.firstName.charAt(0)}
                                            </Typography>
                                          </Grid>
                                        ) : (
                                          <Grid container>
                                            {form.values.profilePhoto && (
                                              <div
                                                id={form.values.profilePhoto[0].name}
                                                style={thumb2}
                                                key={form.values.profilePhoto[0].name}
                                              >
                                                <div style={thumbInner2}>
                                                  <img src={form.values.profilePhoto[0].preview} style={img2} alt="file" />
                                                </div>
                                              </div>
                                            )}
                                          </Grid>
                                        )}
                                      </div>
                                    ) : (
                                      <aside style={thumbsContainer2}>
                                        {form.values.profilePhoto && (
                                          <div
                                            id={form.values.profilePhoto}
                                            style={thumb2}
                                            key={form.values.profilePhoto}
                                          >
                                            <div style={thumbInner2}>
                                              <img src={form.values.profilePhoto} style={img2} alt="file" />
                                            </div>
                                          </div>
                                        )}
                                      </aside>
                                    )}
                                    <Grid container>
                                      <Grid item style={{ minWidth: 95 }}>
                                        {form.values.profilePhoto && (
                                          <Button
                                            variant="containedSecondary"
                                            style={{
                                              padding: '4.5px 15px',
                                              fontSize: 14,
                                              marginTop: 10,
                                              marginLeft: 15,
                                              width: 90,
                                            }}
                                            onClick={() => form.setFieldValue(`profilePhoto`, '')}
                                          >
                                            Sil
                                          </Button>
                                        )}
                                      </Grid>
                                      <Typography variant="h5" className={classes.nameGrid}>
                                        {investor.firstName} {investor.lastName}
                                      </Typography>
                                    </Grid>
                                  </section>
                                )}
                              </Dropzone>
                            )}
                          </Field>
                          <Grid container xs={12} spacing={3} style={{ marginTop: 50 }}>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="portfolioCompanyCount" type="number" placeholder="Portföy Şirket Sayısı" min={0} endIcon={organizationEndIcon} style={{ paddingBottom: 0 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormikTextField name="exitedCompanyCount" type="text" placeholder="Exit yapan şirket sayısı" endIcon={ExitEndIcon} style={{ paddingBottom: 0 }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {stage.length > 0 && ( 
                              <Field
                                style={{ width: '100%', marginTop: 10 }}
                                component={FormikDropdownStage}
                                textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                name="interestedStage"
                                options={stage}
                              ></Field>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {industry.length > 0 && (
                                <Field
                                  component={FormikMultiSelect}
                                  textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                  name="interestedIndustries"
                                  options={industry}
                                ></Field>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Field>
                          {({ field, form, meta }) => (
                            <ImageCropBackground
                              setFieldValue={form.setFieldValue}
                              files={files}
                              backgroundPhoto={form.values.backgroundPhoto}
                              setOpenPhoto={setOpenPhoto}
                              openPhoto={openPhoto}
                            />
                          )}
                        </Field> */}
                        <Field>
                          {({ field, form, meta }) => (
                            <ImageCrop
                              setFieldValue={form.setFieldValue}
                              files={filesProfile}
                              profilePhoto={form.values.profilePhoto}
                              setOpenPhoto={setOpenPhotoProfile}
                              openPhoto={openPhotoProfile}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Wizard.Page>
                    <Wizard.Page>
                      <Grid container justify="center" style={{ borderBottom: '2px solid #EDF2F7', marginBottom: 45 }}>
                        <Grid item style={{ display: 'flex', marginRight: 50 }}>
                          <Typography variant="h6" style={{ color: '#718096', fontSize: 14, marginRight: 5 }}>
                            01
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#718096', fontSize: 14 }}>
                              Profil Bilgileri
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item style={{ display: 'flex', marginRight: 50 }}>
                          <Typography variant="h6" style={{ color: '#718096', fontSize: 14, marginRight: 5 }}>
                            02
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#718096', fontSize: 14 }}>
                              Yatırım Detayları
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item style={{ display: 'flex', marginRight: 50, borderBottom: '2px solid #003CA5', paddingBottom: 20 }}>
                          <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14, marginRight: 5 }}>
                            03
                          </Typography>
                          <Hidden smDown>
                            <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14 }}>
                              Portföy Şirketleri
                            </Typography>
                          </Hidden>
                        </Grid>
                      </Grid>
                      <Grid container justify="center">
                        <Grid item style={{ marginBottom: 24, width: '100%', maxWidth: 350 }}>
                          <Typography variant="h6" style={{ width: 'fit-content', marginBottom: 10, color: '#425466' }}>
                            Portföye Şirket Ekle
                          </Typography>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <div className={classes.search}>
                              <div className={classes.searchIcon}>
                                <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <img src={organizationEndIcon} alt="icon" />
                                </Grid>
                              </div>
                              <DebounceInput
                                class="inputTypeSearch"
                                name="search"
                                value={search}
                                placeholder={'Şirket Adını Giriniz'}
                                className={classes.Debounce}
                                minLength={2}
                                debounceTimeout={200}
                                onChange={HandleSearch}
                                autoComplete="off"
                                onClick={handleClickSearch}
                              />
                            </div>
                          </ClickAwayListener>
                          {openPopover && search && dataSearch && dataSearch.topStartupSearch && (
                            <Grid className={classes.searchBox} xs={3}>
                              <List component="nav" aria-label="secondary mailbox folders" style={{ padding: 16, borderRadius: 6, boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)' }}>
                                {dataSearch.topStartupSearch.map((searchResult) => (
                                  <>
                                    {searchResult.status !== null && (
                                      <Grid>
                                        {selected.includes(searchResult) ?
                                          <ListItem
                                            button
                                            onClick={() => {
                                              setSelected(selected.filter((x) => x.id !== searchResult.id));
                                              setSearch("");
                                            }
                                            }
                                            style={{ minHeight: 50, background: '#E7F4FF' }}
                                          >
                                            <Grid container alignItems="center">
                                              <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={organizationEndIcon} alt="icon" />
                                              </Grid>
                                              <Grid item style={{ marginLeft: 10 }}>
                                                <Typography
                                                  variant="h6"
                                                  style={{ fontSize: 15, fontWeight: 500 }}
                                                >
                                                  {truncateString(searchResult.title, 30)}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs style={{ textAlign: 'right' }}>
                                                <img src={selectedKpi} alt="selected" />
                                              </Grid>
                                            </Grid>
                                          </ListItem>
                                          :
                                          <ListItem
                                            button
                                            onClick={() => setSelected([...selected, searchResult])}
                                            style={{ minHeight: 50 }}
                                          >
                                            <Grid container alignItems="center">
                                              <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={organizationEndIcon} alt="icon" />
                                              </Grid>
                                              <Grid item style={{ marginLeft: 10 }}>
                                                <Typography
                                                  variant="h6"
                                                  style={{ fontSize: 15, fontWeight: 500 }}
                                                >
                                                  {truncateString(searchResult.title, 30)}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </ListItem>
                                        }
                                      </Grid>
                                    )}
                                  </>
                                ))}
                                {dataSearch.topStartupSearch.length === 0 && (
                                  <>
                                    <ListItem
                                      button
                                      style={{ minHeight: 50, background: '#E7F4FF', marginBottom: 8 }}
                                    >
                                      <Grid container alignItems="center">
                                        <Grid item style={{ border: '0.4px solid #B5B5BD', borderRadius: 4, width: 26, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <img src={organizationEndIcon} alt="icon" />
                                        </Grid>
                                        <Grid item style={{ marginLeft: 10 }}>
                                          <Typography
                                            variant="h6"
                                            style={{ fontSize: 15, fontWeight: 500 }}
                                          >
                                            Sonuç Bulunamadı
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </ListItem>
                                    <ListItem
                                      button
                                      onClick={() => setInvite(true)}
                                      style={{ minHeight: 32, boxShadow: ' 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)', borderRadius: 4 }}
                                    >
                                      <Grid container alignItems="center" justify="center">
                                        <Typography variant="h6" align="center" color="primary" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                          <img src={newPlusBlue} alt="new-plus" style={{ marginRight: 8 }} />
                                          Davet Et
                                        </Typography>
                                      </Grid>
                                    </ListItem>
                                  </>
                                )}
                              </List>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container justify="center">
                          <Grid item style={{ width: '100%', maxWidth: 350 }}>
                            <Typography variant="h6" align="left" style={{ marginBottom: 10, color: '#425466' }}>
                              Şirketler
                            </Typography>
                            {!selected || selected.length === 0 ?
                              <Grid container className={classes.companyGrid}>
                                <Grid item style={{ width: 45, height: 45, background: 'rgb(0 60 165 / 32%)', opacity: '0.15', borderRadius: 12 }} />
                                <Grid item style={{ marginLeft: 12 }}>
                                  <Grid item style={{ width: 202, height: '13px', background: 'rgb(0 60 165 / 32%)', opacity: '0.15', borderRadius: 12, marginBottom: 12 }} />
                                  <Grid item style={{ width: 116, height: '13px', background: 'rgb(0 60 165 / 32%)', opacity: '0.15', borderRadius: 12 }} />
                                </Grid>
                              </Grid>
                              :
                              selected.map((select) => (
                                <Grid container className={classes.companyGrid}>
                                  {select.logo ?
                                    <img src={select.logo} style={{ width: 45, height: '100%', borderRadius: 12 }} />
                                    :
                                    <Grid
                                    style={{
                                      width: 45,
                                      height: 45,
                                      borderRadius: '12px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      background: '#003CA5',
                                    }}
                                  >
                                    <Typography variant="h4" style={{ color: '#fff' }}>
                                      {select.title && select.title.charAt(0)}
                                    </Typography>
                                  </Grid>
                                  }
                                  <Grid item style={{ marginLeft: 16 }}>
                                    <Typography
                                      variant="h6"
                                      align="left"
                                      style={{ fontSize: 16, fontWeight: 500 }}
                                    >
                                      {truncateString(select.title, 25)}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      align="left"
                                    >
                                      {truncateString(select.sector, 30)}
                                    </Typography>
                                    <Grid container spacing={1} style={{ marginTop: 4 }}>
                                      {select.businessModelArray && select.businessModelArray.length > 0 &&
                                          select.businessModelArray.map((model) => (
                                            <Grid item>
                                              <GreenTag title={model} />
                                            </Grid>
                                          ))
                                      }
                                      {select.country && (
                                        <Grid item>
                                          <GreenTag title={select.country.title} />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid item xs style={{ textAlign: 'right' }}>
                                    <a style={{ padding: 0 }} href={select.website} target="_blank">
                                      <img src={worldIcon} alt="dumb-icon" style={{ marginRight: 8 }} />
                                    </a>
                                    <img src={dumbIcon2} onClick={() => {
                                      setRemove(true);
                                      setRemoveItem(select);
                                    }} alt="dumb-icon" style={{ cursor: 'pointer' }} />
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </Grid>
                          {removeItem &&
                            <RemoveDialog
                              removeItem={removeItem}
                              open={remove}
                              onClose={() => setRemove(false)}
                              selected={selected}
                              setSelected={setSelected}
                            />
                          }
                        </Grid>
                      </Grid>
                    </Wizard.Page>
                  </Wizard>
                  <InviteStartupDialog open={invite} setSuccess={setSuccess} onClose={() => setInvite(false)} investorInviteStartup={props.investorInviteStartup} />
                  <Snackbar
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => setSuccess(false)}
                    open={success}
                  >
                    <Alert severity="success">Başarıyla davet edildi.</Alert>
                  </Snackbar>
                  <Snackbar
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => setWizardError("")}
                    open={wizardError !== ""}
                  >
                    <Alert severity="error">{wizardError}</Alert>
                  </Snackbar>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}