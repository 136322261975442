import {CssBaseline} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import createHistory from "history/createBrowserHistory";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import RouteChangeTracker from "./Components/RouteChangeTracker";
import Routes from "./Routes";
import theme from "./theme";

function App() {
  const history = createHistory();
  const firstParams = new URLSearchParams(history.location.search);
  const utmCampaign = firstParams.get("utm_campaign") || null;
  const utmMedium = firstParams.get("utm_medium") || null;
  const utmSource = firstParams.get("utm_source") || null;

  React.useEffect(() => {
    localStorage.removeItem("utm_campaign");
    localStorage.removeItem("utm_medium");
    localStorage.removeItem("utm_source");

    if (utmCampaign) localStorage.setItem("utm_campaign", utmCampaign);
    if (utmMedium) localStorage.setItem("utm_medium", utmMedium);
    if (utmSource) localStorage.setItem("utm_source", utmSource);

    return () => {
      localStorage.removeItem("utm_campaign");
      localStorage.removeItem("utm_medium");
      localStorage.removeItem("utm_source");
    };
  }, []);

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
        <RouteChangeTracker history={history} />
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
