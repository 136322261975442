import { Typography } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fieldToTextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>({
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  multiSelect: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
}))


export default function FormikMultiSelectIndustry({ textFieldProps, ...props }) {
    const [meta] = useField(props);
    const { t, i18n } = useTranslation();
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
            multiple
            style={{marginTop:10}}
            {...props}
            {...field}
            disableCloseOnSelect
            getOptionDisabled={option => props.selectedLength && props.selectedLength.length === 5}
            noOptionsText={'Sonuç Bulunamadı..'}
            hiddenLabel="true"
            className={classes.multiSelect}
            onChange={ (_, value) => setFieldValue(name, value) }
            onBlur={ () => setTouched({ [name]: true }) }
            renderInput={(props ) => <TextField {...props } placeholder={t('İlgilenilen sektörler')}  {...textFieldProps} variant="outlined" />}
            >
            </Autocomplete>

        {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
            </Typography>
        ) : null}
        </>
    );
}
