import { graphql } from 'react-apollo';
import SmsVerification from './SmsVerification';
import gql from 'graphql-tag';

const AUTHENTICATE_MUTATION = gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
      user{
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`;

const JoinWithMutation = graphql(AUTHENTICATE_MUTATION, {
  name: 'authenticate',
})(SmsVerification);
export default JoinWithMutation;