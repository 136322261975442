
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import {DebounceInput} from 'react-debounce-input';
import DeleteIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField } from 'formik';
import FormikTextField from "../../Components/FormikShortTextField";
import editSvg from'../../static/img/new.svg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import newSortingIcon from '../../static/icon/newSortingIcon.svg';
import { personSchema } from '../../yupSchemas';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import newCross from '../../static/icon/new-cross.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Image from '../../static/img/404.png';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newPeople from '../../static/icon/new-people.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import filterIcon from '../../static/icon/filterIcon.svg';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  kpiCat: {
    padding: '10px 20px',
    cursor: 'pointer',
    "&:hover" : {
      backgroundColor:'rgb(244, 249, 255)'
    }
  },
  TabsGeneral:{
    background: '#fff',
    borderTopLeftRadius:16,
    borderTopRightRadius:16,
    paddingLeft:30
  },
  mobileFont: {
    fontWeight: 'normal',
    color: '#172b4d',
    padding: 0,
    textDecoration: 'underline',
    display:'flex',
    alignItems:'center',
    fontSize:15
},
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  multiSelect: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  multiSelect2: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1"
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  selected: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  filter: {
    height:40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myPaper2: {
    padding:25,
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    padding: 25,
    marginBottom:50,
  },
  searchBox: {
    width:'100%',
    background:'#ffffff',
    borderRadius:2,
    marginTop:5,
    boxShadow:'1px 1px 2px 1px rgba(201,201,201,1)',
    maxHeight: 130,
    overflowY:'auto',
  },
  tabPaper: {
    marginTop:50,
  },
  CheckText: {
    wordBreak:'break-all',
    marginBottom:10,
    '& span': {
      fontSize:15,
      lineHeight:1.2
    }
  },
}));

const FormikCheckBox = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormControlLabel style={{marginRight: 0}} control={<Checkbox {...field}  color="primary" />} />
                                      {label}
    </>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:11,
    padding: '16px 0px 16px 24px',
  },
  body: {
    fontSize: 14,
    padding: '16px 0px 16px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);
function AddPersonDialog(props) {
  const classes = useStyles();
  const { onClose, open, groups } = props;
  const { t } = useTranslation();

  const FormikGroupDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
            style={{marginTop:10,minHeight:80}}
            {...props}
            {...field}
            multiple
            disableCloseOnSelect
            noOptionsText={'Sonuç Bulunamadı..'}
            options={props.options}
            getOptionLabel={(option) => option.title}
            hiddenLabel="true"
            className={classes.multiSelect}
            onChange={(_, value) => setFieldValue(name, value)}
            onBlur={ () => setTouched({ [name]: true }) }
            renderInput={(props ) => <TextField {...props } placeholder={t("Lütfen Seçiniz")}   {...textFieldProps} variant="outlined" />}
            >
            </Autocomplete>
  
        {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
            </Typography>
        ) : null}
        </>
    );
  }
  

  const handleSubmit = variables => {
    let tempSp = []

    variables.groupIds.forEach(element => {
      tempSp.push(element.id)
    });
    variables.groupIds = tempSp
    props.startupPersonCreate({ variables })
      .then(data => {
        if (data.data.startupPersonCreate) {
          props.refetch();
          props.setPersonAdded(true);
          onClose(null);
        }
        else{
            props.setServerSuccess(true);
        } 
      }).then(() => {
      }).catch(error => {
        props.setServerSuccess(true);
        onClose(null);
      });
  };

  const handleClose = () => {
    onClose(null);
  };
  const preventDefault = (event) => event.preventDefault();

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding:'30px 30px 0 0'}}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor:'pointer' }}/>
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          {t('Kişi Ekleyin')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20 }} xs={10}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            {t('Raporlarınızı kolaylıkla paylaşmak için kişileri ekleyebilir ve kişi grupları oluşturabilirsiniz.')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              nameSurname: '',
              email: '',
              groupIds: [],
              isPermitted: false,
            }}
            validationSchema={personSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12}>
                      <Typography variant="h6" style={{ width: '100%' }}>
                        {t('Adı Soyadı')}
                      </Typography>
                      <FormikTextField
                        name="nameSurname"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder={t('Adı Soyadı')}
                      />
                    </Grid>
                    <Grid item container xs={12} style={{ height: 'fit-content' }}>
                      <Typography variant="h6" style={{ width: '100%' }}>
                        {t('E-posta adresi')}
                      </Typography>
                      <FormikTextField
                        name="email"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Lütfen Doldurunuz"
                      />
                    </Grid>
                    <Grid item container xs={12} style={{ height: 'fit-content' }}>
                      <Typography variant="h6" style={{ width: '100%', marginBottom: 10 }}>
                        {t('Gruplar')}
                      </Typography>
                      {groups && (
                        <Field
                          style={{ width: '100%', marginBottom: 20 }}
                          component={FormikGroupDropdown}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="groupIds"
                          options={groups}
                        ></Field>
                      )}
                    </Grid>
                    <Grid item container xs={12} style={{ height: 'fit-content', flexWrap: 'noWrap' }}>
                      <FormikCheckBox
                        name="isPermitted"
                        type="Checkbox"
                        label={
                          <Typography onClick={preventDefault} className={classes.mobileFont}>
                            {t('Bu kişiyi eklemek için kişiden gerekli onayı aldım.')}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ margin: '20px 0', height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        disabled={
                          !props.isValid ||
                          (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)
                        }
                        onClick={() => handleSubmit(props.values)}
                      >
                        {t('Ekle')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddPersonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddGroupDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;
  const { t } = useTranslation();

  const handleSubmit = variables => {
    props.startupGroupCreate({ variables })
      .then(data => {
        if (data.data.startupGroupCreate) {
          props.refetchGroup();
          onClose(null);
        } 
      }).then(() => {})
      .catch((error) => {
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding:'30px 30px 0 0'}}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor:'pointer' }}/>
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
        {t('Grup Ekleyin')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20 }} xs={10}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
        {t('Raporlarınızı kolaylıkla paylaşmak için kişi grupları oluşturabilirsiniz.')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              title: '',
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12}>
                        <Typography variant="h6" style={{ width: '100%' }}>
                        {t('Grup Adı')}
                        </Typography>
                        <FormikTextField
                          name="title"
                          style={{ width: '100%', marginBottom: 20 }}
                          type="text"
                          placeholder="Grup Adı"
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', width: '100%' }}
                          onClick={() => handleSubmit(props.values)}
                        >
                        {t('Ekle')}  
                        </Button>
                      </Grid>
                    </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddGroupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditGroupDialog(props) {
  const classes = useStyles();
  const { onClose, open, group } = props;
  const { t } = useTranslation();

  const handleSubmit = variables => {
    props.startupGroupEdit({ variables })
      .then(data => {
        if (data.data.startupGroupEdit) {
          props.refetchGroup();
          props.refetch();
          props.setEdited(true)
          onClose(null);
        } 
      }).then(() => {})
      .catch((error) => {
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">
        {t('Grup Düzenle')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20 }} xs={10}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
          {t('Daha önce oluşturmuş olduğunuz grupta değişiklikler yapabilir ve yeni kişiler ekleyebilirsiniz.')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              groupId: group.id,
              title: group.title,
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12}>
                        <Typography variant="h6" style={{ width: '100%' }}>
                        {t('Grup Adı')}  
                        </Typography>
                        <FormikTextField
                          name="title"
                          style={{ width: '100%', marginBottom: 20 }}
                          type="text"
                          placeholder={t('Grup Adı')} 
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', width: '100%' }}
                          onClick={() => handleSubmit(props.values)}
                        >
                        {t('Düzenle')}
                        </Button>
                      </Grid>
                    </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditGroupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
function RemoveDialog(props) {
  const classes = useStyles();
  const { onClose, open, handleClose, personId, groupId } = props;

  const handleSubmit = () => {
    if(personId){
      props.startupPersonDelete({ variables: { personId:personId } })
      .then(data => {
        if (data.data.startupPersonDelete) {
          props.refetch();
          props.refetchGroup();
          onClose();
        }
      }).then(() => {})
      .catch((error) => {
      });
    }
    if(groupId){
      props.startupGroupDelete({ variables: { groupId:groupId } })
      .then(data => {
        if (data.data.startupGroupDelete) {
          props.refetch();
          props.refetchGroup();
          onClose();
        }
      }).then(() => {})
      .catch((error) => {
      });
    }
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            {personId && <Typography align="center" variant="body1" style={{color:'#777777'}}>Bu kişiyi silmek istediğinizden emin misiniz? </Typography>}
            {groupId && <Typography align="center" variant="body1" style={{color:'#777777'}}>Bu grubu silmek istediğinizden emin misiniz? </Typography>}
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => {onClose(null);handleClose()}} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Dialog>
  );
}

RemoveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddGroupPersonDialog(props) {
  const classes = useStyles();
  const { onClose, open, group, refetchGroup, refetch } = props;
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(group.persons);
  const [people, setPeople] = React.useState([]);
  const { t } = useTranslation();

const SEARCH_PEOPLE= gql`
query getStartupPersonsSearch ($search: String){
    getStartupPersonsSearch (search:$search){
      id
      getName
      getProfilePhoto
    }
}
`;
const GET_PEOPLE = gql`
query startupPersonList($page: Int, $pageSize: Int, $search: String) {
  startupPersonList(pagination:{page:$page, pageSize:$pageSize}, search:$search ){
    count
    pageCount
    data{
      id
      getName
      getProfilePhoto
    }
  }
}
`;
const { loading:loadingPeople , data:dataPeople , refetch:refetchPeople } = useQuery(GET_PEOPLE, {
    fetchPolicy:"network-only",
    variables: {page:1, pageSize:1000},
});

React.useEffect(() => {
  if(!loadingPeople){
    if(dataPeople){
      setPeople(dataPeople.startupPersonList.data);
    }
  }
},
[loadingPeople,dataPeople])

const[getData, { loading:loadingSearch, data:dataSearch } ]= useLazyQuery(SEARCH_PEOPLE, {
  fetchPolicy:'network-only',
  variables: {search:search},
});

  const handleSearch = (event) => {
    setSearch(event.target.value);
    getData()
  };

  const handleClose = () => {
    onClose(null);
  };

  const sendList= (value) => {
    if (value !== []){
      props.startupGroupEdit({ variables: { personIds:value, groupId:group.id } })
        .then(data => {
          if (data.data.startupGroupEdit) {
            refetchGroup();
            refetch();
            props.setGroupAdded(true);
            onClose()
          }
        }).then(() => {})
        .catch((error) => {
        });
    }
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="center" xs={12} style={{ padding: 35 }}>
        <Typography variant="h4" style={{ fontWeight: '500' }}>
          {group.title}
        </Typography>
        <Grid container justify="center" style={{ marginTop: 35 }}>
          <Grid container xs={12} md={5} className={classes.search}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: 10 }}>
                {t('Kişi Ara')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DebounceInput
                class="inputTypeSearch"
                name="search"
                value={search}
                style={{ maxWidth: '100%' }}
                className={classes.Debounce}
                minLength={2}
                debounceTimeout={800}
                onChange={handleSearch}
              />
              {search && dataSearch && (
                <Grid className={classes.searchBox}>
                  <List component="nav" aria-label="secondary mailbox folders">
                    {dataSearch.getStartupPersonsSearch.map((searchResult) => (
                      <Grid>
                        {selected.some((e) => e.id === searchResult.id) ? (
                          <ListItem
                            onClick={() => setSelected(selected.filter((x) => x.id !== searchResult.id))}
                            button
                            style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                          >
                            <Grid container>
                              <Typography variant="body1" color="primary">
                                {searchResult.getName}
                              </Typography>
                            </Grid>
                          </ListItem>
                        ) : (
                          <ListItem
                            onClick={() => setSelected([...selected, searchResult])}
                            button
                            style={{ minHeight: 50 }}
                          >
                            <Grid container>
                              <Typography variant="body1" color="primary">
                                {searchResult.getName}
                              </Typography>
                            </Grid>
                          </ListItem>
                        )}
                      </Grid>
                    ))}
                    {dataSearch.getStartupPersonsSearch.length === 0 && (
                      <ListItem>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <Typography align="center" variant="body1">
                            {t('Aradığın Kişiyi bulamadık')}
                          </Typography>
                        </Grid>
                      </ListItem>
                    )}
                  </List>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 35 }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              borderRight: 'solid 1px #eeeeee',
              borderLeft: 'solid 1px #eeeeee',
              borderBottom: 'solid 1px #eeeeee',
              marginBottom: 20,
            }}
          >
            <Typography align="center" color="primary" variant="h6" style={{ marginBottom: 10 }}>
              {t('Kişi Listesi')}
            </Typography>
            <Grid item xs={12} style={{ borderTop: 'solid 1px #eeeeee', maxHeight: 250, overflowY: 'auto' }}>
              {people.map((detail) => (
                <>
                  {selected.some((e) => e.id === detail.id) ? (
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      style={{ backgroundColor: 'rgb(244, 249, 255)' }}
                      className={classes.kpiCat}
                      onClick={() => setSelected(selected.filter((x) => x.id !== detail.id))}
                    >
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        {detail.getProfilePhoto ? (
                          <img
                            src={detail.getProfilePhoto}
                            alt="logo"
                            style={{
                              width: 35,
                              height: 35,
                              borderRadius: '50%',
                              marginRight: 10,
                              objectFit: 'cover',
                              border: 'solid 1px #eaedf3',
                            }}
                          />
                        ) : (
                          <Grid
                            style={{
                              width: 35,
                              height: 35,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: '#003CA5',
                              marginRight: 10,
                            }}
                          >
                            <Typography variant="h5" style={{ color: '#fff' }}>
                              {detail.getName && detail.getName.charAt(0)}
                            </Typography>
                          </Grid>
                        )}
                        <Typography variant="body2">{detail.getName}</Typography>
                      </Grid>
                      <AddCircleOutlineIcon style={{ fontSize: 18 }} />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      className={classes.kpiCat}
                      onClick={() => setSelected([...selected, detail])}
                    >
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        {detail.getProfilePhoto ? (
                          <img
                            src={detail.getProfilePhoto}
                            alt="logo"
                            style={{
                              width: 35,
                              height: 35,
                              borderRadius: '50%',
                              marginRight: 10,
                              objectFit: 'cover',
                              border: 'solid 1px #eaedf3',
                            }}
                          />
                        ) : (
                          <Grid
                            style={{
                              width: 35,
                              height: 35,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: '#003CA5',
                              marginRight: 10,
                            }}
                          >
                            <Typography variant="h5" style={{ color: '#fff' }}>
                              {detail.getName && detail.getName.charAt(0)}
                            </Typography>
                          </Grid>
                        )}
                        <Typography variant="body2">{detail.getName}</Typography>
                      </Grid>
                      <AddCircleOutlineIcon style={{ fontSize: 18 }} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              borderRight: 'solid 1px #eeeeee',
              borderLeft: 'solid 1px #eeeeee',
              marginBottom: 20,
              borderBottom: 'solid 1px #eeeeee',
            }}
          >
            <Typography align="center" color="primary" variant="h6" style={{ marginBottom: 10 }}>
              {t('Seçili Kişiler')}
            </Typography>
            <Grid item xs={12} style={{ borderTop: 'solid 1px #eeeeee', maxHeight: 250, overflowY: 'auto' }}>
              {selected.map((select) => (
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  className={classes.selected}
                  onClick={() => setSelected(selected.filter((x) => x.id !== select.id))}
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    {select.getProfilePhoto ? (
                      <img
                        src={select.getProfilePhoto}
                        alt="logo"
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: '50%',
                          marginRight: 10,
                          objectFit: 'cover',
                          border: 'solid 1px #eaedf3',
                        }}
                      />
                    ) : (
                      <Grid
                        style={{
                          width: 35,
                          height: 35,
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#003CA5',
                          marginRight: 10,
                        }}
                      >
                        <Typography variant="h3" style={{ color: '#fff' }}>
                          {select.getName && select.getName.charAt(0)}
                        </Typography>
                      </Grid>
                    )}
                    <Typography variant="body2">{select.getName}</Typography>
                  </Grid>
                  <DeleteIcon style={{ fontSize: 18, color: '#ff5757' }} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Button
              variant="outlinedSecondary"
              style={{
                fontWeight: 'normal',
                marginTop: 35,
                padding: '10.5px 50px',
                fontSize: 16,
                marginRight: 15,
              }}
              onClick={() => (onClose(null), setSelected(group.persons))}
            >
              {t('Vazgeç')}
            </Button>
            <Button
              variant="containedPrimary"
              style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
              onClick={() => sendList(selected.map((a) => a.id))}
            >
              {t('Kaydet')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddGroupPersonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


function ExpandingRowGroup(props){
  const classes = useStyles();
  const [showEditGroup, setShowEditGroup] = React.useState(false);
  const [showAddGroup, setShowAddGroup] = React.useState(false);

  const deleteGroup = (value) => {
    props.setGroupId(value);
    props.setRemove(true);
  };


  const { group } = props;
  return(
    <>
     <StyledTableRow key={group.title}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{group.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{group.count}</Typography></StyledTableCell>
        <StyledTableCell align="left" style={{maxWidth:250}}>
          <Typography variant="body2">
          {group.persons && group.persons.map((person, index) => (
            index + 1 === group.persons.length ?
            person.getName :
            person.getName + ' , '
          ))}
          </Typography>
          </StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
          <Grid style={{cursor:'pointer'}} onClick={() => setShowAddGroup(true)}><AddCircleOutlineIcon alt="sticker" style={{marginRight:5,fontSize:25,color:'rgb(58 54 54 / 87%)'}} className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowEditGroup(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}} alt="sticker" className={classes.sticker} /></Grid>
            {group.count === 0 && 
              <Grid style={{cursor:'pointer'}} onClick={() => deleteGroup(group.id)}><DeleteIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
            }
          </Grid>
        </StyledTableCell>
        <EditGroupDialog setEdited={props.setEdited} startupGroupEdit={props.startupGroupEdit} group={group} refetch={props.refetch} refetchGroup={props.refetchGroup} open={showEditGroup} onClose={() => setShowEditGroup(false)}/>
        <AddGroupPersonDialog setEdited={props.setEdited} setGroupAdded={props.setGroupAdded} startupGroupEdit={props.startupGroupEdit} refetch={props.refetch} group={group} refetchGroup={props.refetchGroup} open={showAddGroup} onClose={() => setShowAddGroup(false)}/>                            
      </StyledTableRow>
    </>
  )
}

export default function Followers(props){
    const classes = useStyles();
    const [pagePeople, setPagePeople] = React.useState(1);
    const [pageCountPeople, setPageCountPeople] = React.useState(1);
    const [pageSizePeople, setPageSizePeople] = React.useState(6);
    const [pageGroup, setPageGroup] = React.useState(1);
    const [pageCountGroup, setPageCountGroup] = React.useState(1);
    const [pageSizeGroup, setPageSizeGroup] = React.useState(6);
    const [serverSuccess, setServerSuccess] = React.useState(false);
    const [people, setPeople] = React.useState();
    const [groups, setGroup] = React.useState();
    const [searchPeople, setSearchPeople] = React.useState("");
    const [searchGroup, setSearchGroup] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addPeople, setAddPeople] = React.useState(false);
    const [addPerson, setAddPerson] = React.useState(false);
    const [addGroup, setAddGroup] = React.useState(false);
    const [edited, setEdited] = React.useState(false);
    const [orderPeople, setOrderPeople] = React.useState("");
    const [sortPeople, setSortPeople] = React.useState([]);
    const [orderGroup, setOrderGroup] = React.useState("");
    const [groupAdded, setGroupAdded] = React.useState(false);
    const [remove, setRemove] = React.useState(false);
    const [personId, setPersonId] = React.useState();
    const [groupId, setGroupId] = React.useState();
    const [sortGroup, setSortGroup] = React.useState([]);
    const [filter, setFilter] = React.useState("Grubuna Göre");
    const [groupIds, setGroupIds] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const { t } = useTranslation();
    const [anchorElSortPeople, setAnchorElSortPeople] = React.useState(null);
    const [anchorElSortGroup, setAnchorElSortGroup] = React.useState(null);

    const handleClickSortPeople = (event) => {
      setAnchorElSortPeople(event.currentTarget);
    };

    const handleCloseSortPeople = () => {
      setAnchorElSortPeople(null);
    };

    const openPopSortPeople = Boolean(anchorElSortPeople);
    const idSortPeople = openPopSortPeople ? 'simple-popover' : undefined;

    const handleClickSortGroup = (event) => {
      setAnchorElSortGroup (event.currentTarget);
    };

    const handleCloseSortGroup  = () => {
      setAnchorElSortGroup (null);
    };

    const openPopSortGroup  = Boolean(anchorElSortGroup );
    const idSortGroup  = openPopSortGroup  ? 'simple-popover' : undefined;

    const optionsPeople = [
      {name:'A-Z', value:'name__ASC'},
      {name:'Z-A', value:'name__DESC'},
      {name:'Grup İsmi A-Z', value:'groups__title__DESC'},
      {name:'Grup İsmi Z-A', value:'groups__title__ASC'},
    ];

    const optionsGroup = [
      {name:'A-Z', value:'title__ASC'},
      {name:'Z-A', value:'title__DESC'},
    ];

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleOpenAddPeople= () => {
      setAddPeople(true);
    };
    const handleCloseAddPeople = (value) => {
      setAddPeople(false);
    };
    const filterOptions = ['Grubuna Göre'];
  
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    const ALL_PEOPLE = gql`
    query startupPersonList($order:String, $search: String, $page: Int, $pageSize: Int, $groupIds: [ID]) {
      startupPersonList(orderBy:[$order], search:$search, pagination:{page:$page, pageSize:$pageSize}, filter:{groupIds:$groupIds}){
            count
            pageCount
              data{
                id
                getName
                getEmail
                getProfilePhoto
                groups{
                  title
                }
            }
        }
    }
    `;
      const { loading, data, refetch} = useQuery(ALL_PEOPLE, {
        fetchPolicy:'network-only',
        variables: {search:searchPeople, page:pagePeople, pageSize:pageSizePeople, order:orderPeople, groupIds:groupIds },
    });

    const ALL_GROUP = gql`
    query startupGroupList($order:String, $search: String, $page: Int, $pageSize: Int) {
      startupGroupList(orderBy:[$order], search:$search, pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
              data{
                id
                title
                count
                persons{
                  id
                  getName
                }
            }
        }
    }
    `;
      const { loading:loadingGroup , data:dataGroup , refetch:refetchGroup } = useQuery(ALL_GROUP, {
        fetchPolicy:'network-only',
        variables: {search:searchGroup, page:1, pageSize:100, order:orderGroup },
    });
    React.useEffect(() => {
        if(!loading){
            if(data){
                setPeople(data.startupPersonList.data);
                setPageCountPeople(data.startupPersonList.pageCount)
            }
        }
        if(!loadingGroup){
          if(dataGroup){
              setGroup(dataGroup.startupGroupList.data);
              setPageCountGroup(dataGroup.startupGroupList.pageCount)
          }
        }
        if (groups || people){
          setShow(true)
        }
    },
  [data, loading, loadingGroup, dataGroup])

  const handlePageChangePeople = (event, value) => {
    window.scrollTo(0,0);
    setPagePeople(value);
  };
  const handleSortPeople = event => {
    setSortPeople(event.target.value);
    setPagePeople(1);
  };
  const handleSortGroup = event => {
    setSortGroup(event.target.value);
    setPageGroup(1);
  };

  const handleSearchPeople = event => {
    setSearchPeople(event.target.value);
    setPagePeople(1);
  };

  const handlePageChangeGroup= (event, value) => {
    window.scrollTo(0,0);
    setPageGroup(value);
  };

  const handleSearchGroup = event => {
    setSearchGroup(event.target.value);
    setPageGroup(1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (event) => {
    setPagePeople(1);
    setFilter(event.target.value);
  };

  const handleGroup = (event) => {
    setPagePeople(1);
    if (groupIds.includes(event.target.value)){
      let a = groupIds.filter(e => e !== event.target.value);
      setGroupIds(a);
     }
     else{
      setGroupIds([...groupIds, event.target.value]);
     }
  };

  const deletePeople = (value) => {
    setPersonId(value);
    setRemove(true);
  };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <RemoveDialog
              startupPersonDelete={props.startupPersonDelete}
              startupGroupDelete={props.startupGroupDelete}
              personId={personId}
              groupId={groupId}
              refetch={refetch}
              refetchGroup={refetchGroup}
              open={remove}
              onClose={() => setRemove(false)}
            />
            {show && (
              <Grid
                className={'main-container'}
                xs={12}
                style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
              >
                <Snackbar
                  autoHideDuration={5000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => setAddPerson(false)}
                  open={addPerson}
                >
                  <Alert severity="success">Kişi eklendi.</Alert>
                </Snackbar>
                <Snackbar
                  autoHideDuration={5000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => setEdited(false)}
                  open={edited}
                >
                  <Alert severity="success">Yaptığınız değişiklikler kaydedildi.</Alert>
                </Snackbar>
                <Snackbar
                  autoHideDuration={5000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => setGroupAdded(false)}
                  open={groupAdded}
                >
                  <Alert severity="success">Seçtiğiniz kişiler gruba eklendi.</Alert>
                </Snackbar>
                <AddPersonDialog
                  refetch={refetch}
                  setPersonAdded={setAddPerson}
                  groups={groups}
                  setServerSuccess={setServerSuccess}
                  startupPersonCreate={props.startupPersonCreate}
                  open={addPeople}
                  onClose={handleCloseAddPeople}
                />
                {(people && people.length !== 0) || (groups && groups.length !== 0) ? (
                  <Grid item style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="center">
                      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <img src={newPeople} style={{ width: 16 }} alt="people" />
                        <Typography variant="h4">Kişiler</Typography>
                      </Breadcrumbs>
                      <Grid item className={classes.filterArea}>
                        <Hidden smDown>
                          {value === 0 ? (
                            <DebounceInput
                              class="inputTypeSearch"
                              name="search"
                              value={searchPeople}
                              placeholder="Ara..."
                              className={classes.Debounce}
                              minLength={2}
                              debounceTimeout={800}
                              onChange={handleSearchPeople}
                            />
                          ) : (
                            <DebounceInput
                              class="inputTypeSearch"
                              name="search"
                              value={searchGroup}
                              placeholder="Ara..."
                              className={classes.Debounce}
                              minLength={2}
                              debounceTimeout={800}
                              onChange={handleSearchGroup}
                            />
                          )}
                          {value === 0 ? (
                            <>
                              <Grid className={classes.filter} onClick={handleClick} item>
                                <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                                <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                  {t('Filtrele')}
                                </Typography>
                                <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
                              </Grid>
                              <Popover
                                id={id}
                                open={openPop}
                                anchorEl={anchorEl}
                                style={{ marginTop: 11 }}
                                onClose={handleClose}
                                classes={{
                                  paper: classes.myPaper,
                                }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <Grid container xs={12}>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      borderRight: '1px solid rgb(0 0 0 / 5%)',
                                      maxHeight: 250,
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Typography variant="h5">Filtreler</Typography>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                      <RadioGroup onChange={handleFilter} value={filter}>
                                        {filterOptions.map((value, i) => {
                                          // Return the element. Also pass key
                                          return (
                                            <FormControlLabel
                                              classes={{ label: classes.myLabel }}
                                              control={<Radio color="primary" value={value} />}
                                              label={value}
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      paddingLeft: '30px',
                                      borderRight: '1px solid rgb(0 0 0 / 5%)',
                                      maxHeight: 250,
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Typography variant="h5">{t('Filtre Seç')} </Typography>
                                    {groups && filter === 'Grubuna Göre' && (
                                      <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup onChange={handleGroup} value={groupIds}>
                                          {groups.map((value, i) => {
                                            return (
                                              <FormControlLabel
                                                className={classes.CheckText}
                                                control={
                                                  <Checkbox
                                                    classes={{ root: classes.myCheckbox }}
                                                    color="primary"
                                                    onChange={handleGroup}
                                                    checked={groupIds.includes(value.id.toString())}
                                                    name="filter4-1"
                                                    value={value.id}
                                                  />
                                                }
                                                label={value.title}
                                              />
                                            );
                                          })}
                                        </FormGroup>
                                      </FormControl>
                                    )}
                                  </Grid>
                                  <Grid container justify="flex-end"></Grid>
                                </Grid>
                              </Popover>
                              <Grid
                                  className={classes.filter}
                                  onClick={handleClickSortPeople}
                                  item
                                  style={{ marginRight:0 }}
                                >
                                  <img src={newSortingIcon} style={{ width: 15 }} alt="filter" />
                                  <Hidden smDown>
                                    <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                      {t('Sırala')}
                                    </Typography>
                                    <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                                  </Hidden>
                                </Grid>
                                <Popover
                                  id={idSortPeople}
                                  open={openPopSortPeople}
                                  anchorEl={anchorElSortPeople}
                                  style={{ marginTop: 11 }}
                                  onClose={handleCloseSortPeople}
                                  classes={{
                                    paper: classes.myPaper2,
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      maxHeight: 250,
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Typography variant="h5">{t('Sırala')}</Typography>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                      <RadioGroup onChange={handleSortPeople} value={sortPeople}>
                                        {optionsPeople.map((option, i) => {
                                          // Return the element. Also pass key
                                          return (
                                            <FormControlLabel
                                              classes={{ label: classes.myLabel }}
                                              control={
                                                <Radio
                                                  color="primary"
                                                  value={option.value}
                                                  onClick={() => setOrderPeople(option.value)}
                                                />
                                              }
                                              label={option.name}
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                </Popover>              
                            </>
                          ) : (<>
                            <Grid
                            className={classes.filter}
                            onClick={handleClickSortGroup}
                            item
                            style={{ marginRight:0 }}
                          >
                            <img src={newSortingIcon} style={{ width: 15 }} alt="filter" />
                            <Hidden smDown>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                {t('Sırala')}
                              </Typography>
                              <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                            </Hidden>
                          </Grid>
                          <Popover
                            id={idSortGroup}
                            open={openPopSortGroup}
                            anchorEl={anchorElSortGroup}
                            style={{ marginTop: 11 }}
                            onClose={handleCloseSortGroup}
                            classes={{
                              paper: classes.myPaper2,
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{
                                maxHeight: 250,
                                overflowY: 'auto',
                              }}
                            >
                              <Typography variant="h5">{t('Sırala')}</Typography>
                              <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup onChange={handleSortGroup} value={sortGroup}>
                                  {optionsGroup.map((option, i) => {
                                    // Return the element. Also pass key
                                    return (
                                      <FormControlLabel
                                        classes={{ label: classes.myLabel }}
                                        control={
                                          <Radio
                                            color="primary"
                                            value={option.value}
                                            onClick={() => setOrderGroup(option.value)}
                                          />
                                        }
                                        label={option.name}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Popover>  
                          </>            
                          )}
                        </Hidden>
                        <Button
                          style={{
                            fontWeight: 'normal',
                            padding: '11.5px 15px',
                            marginLeft: 15,
                            fontSize: 14,
                            width: '100%',
                            marginRight: 20,
                          }}
                          onClick={handleOpenAddPeople}
                          variant="containedSecondary"
                        >
                          <AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} />{' '}
                          {t('Yeni Kişi Ekle')}
                        </Button>
                        <Button
                          style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%' }}
                          variant="containedPrimary"
                          onClick={() => setAddGroup(true)}
                        >
                          <AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} />
                          {t('Yeni Grup Ekle')}
                        </Button>
                        <AddGroupDialog
                          refetchGroup={refetchGroup}
                          startupGroupCreate={props.startupGroupCreate}
                          open={addGroup}
                          onClose={() => setAddGroup(false)}
                        />
                      </Grid>
                    </Grid>
                    {people && (
                      <Grid container xs={12}>
                        <AppBar className={classes.tabPaper} position="static" style={{ zIndex: 1 }} color="default">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            className={classes.TabsGeneral}
                          >
                            <Tab
                              label="Kişiler"
                              {...a11yProps(0)}
                              onClick={() => setSearchGroup('')}
                              style={{ fontWeight: 600 }}
                            />
                            <Tab
                              label="Gruplar"
                              {...a11yProps(1)}
                              onClick={() => setSearchPeople('')}
                              style={{ fontWeight: 600 }}
                            />
                          </Tabs>
                        </AppBar>
                        <TabPanel style={{ width: '100%' }} value={value} index={0}>
                          <Grid container xs={12} className={classes.whiteCard}>
                            <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell align="left">
                                      <Typography variant="h6" color="primary">
                                        {t('Ad Soyad')}
                                      </Typography>
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <Typography variant="h6" color="primary">
                                        {t('E-Posta')}
                                      </Typography>
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                      <Typography variant="h6" color="primary">
                                        {t('Grup Adı')}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {people.map((person) => (
                                    <StyledTableRow key={person.getName}>
                                      <StyledTableCell component="th" scope="row">
                                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                                          {person.getProfilePhoto ? (
                                            <img
                                              src={person.getProfilePhoto}
                                              alt="logo"
                                              style={{
                                                width: 35,
                                                height: 35,
                                                borderRadius: '50%',
                                                marginRight: 10,
                                                objectFit: 'cover',
                                                border: 'solid 1px #eaedf3',
                                              }}
                                            />
                                          ) : (
                                            <Grid
                                              style={{
                                                width: 35,
                                                height: 35,
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: '#003CA5',
                                                marginRight: 10,
                                              }}
                                            >
                                              <Typography variant="h5" style={{ color: '#fff' }}>
                                                {person.getName && person.getName.charAt(0)}
                                              </Typography>
                                            </Grid>
                                          )}
                                          <Typography variant="h6" style={{ fontSize: 14 }}>
                                            {person.getName}
                                          </Typography>
                                        </Grid>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <Typography variant="body2">{person.getEmail}</Typography>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <Typography variant="body2">
                                          {person.groups &&
                                            person.groups.map((group, index) =>
                                              index + 1 === person.groups.length ? group.title : group.title + ' , ',
                                            )}
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <DeleteIcon
                                          onClick={() => deletePeople(person.id)}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Grid container style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountPeople}
                                page={pagePeople}
                                onChange={handlePageChangePeople}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>
                        <TabPanel style={{ width: '100%' }} value={value} index={1}>
                          {groups && groups.length > 0 ? (
                            <Grid container xs={12} className={classes.whiteCard}>
                              <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell align="left">
                                        <Typography variant="h6" color="primary">
                                          {t('Grup Adı')}
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <Typography variant="h6" color="primary">
                                          {t('Katılımcı Sayısı')}
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        <Typography variant="h6" color="primary">
                                          {t('Grup Üyeleri')}
                                        </Typography>
                                      </StyledTableCell>
                                      <StyledTableCell align="left"></StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {groups.map((group) => (
                                      <ExpandingRowGroup
                                        refetch={refetch}
                                        setEdited={setEdited}
                                        setGroupId={setGroupId}
                                        setRemove={setRemove}
                                        setGroupAdded={setGroupAdded}
                                        startupGroupEdit={props.startupGroupEdit}
                                        refetchGroup={refetchGroup}
                                        group={group}
                                        startupGroupDelete={props.startupGroupDelete}
                                      />
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Grid container style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                                <Pagination
                                  count={pageCountGroup}
                                  page={pageGroup}
                                  onChange={handlePageChangeGroup}
                                  variant="outlined"
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container justify="center">
                              <Grid item container direction="column" xs={6} alignItems="center">
                                <img src={Image} alt="404" style={{ width: '40%' }} />
                                <Typography variant="h2" style={{ marginTop: '25px' }} align="center">
                                  {t('Henüz hiç grup oluşturmadınız.')}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{ marginTop: '35px', color: '#777777', marginBottom: '15px' }}
                                  align="center"
                                >
                                  {t(
                                    'Raporlarınızı kolaylıkla paylaşmak için kişi ekleyebilir ve kişi grupları oluşturabilirsiniz.',
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </TabPanel>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid container justify="center">
                    <Grid item container direction="column" xs={6} alignItems="center">
                      <img src={Image} alt="404" style={{ width: '40%' }} />
                      <Typography variant="h2" style={{ marginTop: '25px' }} align="center">
                        {t('Henüz kimseyi kişilerim listenize eklemediniz.')}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: '35px', color: '#777777', marginBottom: '15px' }}
                        align="center"
                      >
                        {t(
                          'Kişilerim listenize kişi veya kişi grupları ekleyerek raporlarınızı kolaylıkla paylaşabilirsiniz. ',
                        )}
                      </Typography>
                      <Button
                        onClick={handleOpenAddPeople}
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          marginRight: 20,
                          padding: '11.5px 30px',
                        }}
                        variant="containedPrimary"
                      >
                        {t('Yeni Kişi Ekle')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Snackbar
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => setServerSuccess(false)}
                  open={serverSuccess}
                >
                  <Alert severity="error">Kayıtlı bir mail adresi girildi.</Alert>
                </Snackbar>
              </Grid>
            )}
          </Grid>
        </main>
      </>
    );
}