import { Field, FieldArray } from 'formik';
import { numberWithPeriods, resolveCurrencySymbol } from '../../helpers';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DocsPhoto from '../../static/icon/DocIcon.svg';
import Dropzone from 'react-dropzone';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormikMultiLine from '../../Components/FormikMultiLine';
import FormikNumberField from '../../Components/FormikNumberField';
import FormikPercentageField from '../../Components/FormikPercentageField';
import FormikSelect from '../FormikSelect';
import FormikTextField from '../../Components/FormikTextField';
import Grid from '@material-ui/core/Grid';
import ImageCrop from '../../Components/ImageCrop';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import apple from '../../static/img/apple.png';
import { defaults } from 'react-chartjs-2';
import google from '../../static/img/google.png';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import newPptx from '../../static/icon/PptxIcon.svg';
import newXlsx from '../../static/icon/XlsxIcon.svg';
import { useTranslation } from 'react-i18next';
import worldIcon from '../../static/icon/blue-world-icon.svg';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  pointerEvents: 'none',
  flexWrap: 'wrap',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 4,
  border: '1px solid #eaeaea',
  width: 80,
  height: 80,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
  borderRadius: 7,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  paper2: {
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80px',
    width: '80px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 4,
    border: 'solid 1px #dddddd6b',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '500',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  docsObject: {
    padding: 16,
    borderRadius: 16,
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
  },
  fontLg: {
    fontSize: 16,
  },
  objectCard: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRoot: {
    boxShadow: 'none',
    border: '1px solid #8898aa',
    borderRadius: 4,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
  },
  AddButton: {
    padding: '12.5px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    minWidth: 175,
    fontSize: 16,
    marginRight: 10,
    marginTop: 15,
    fontWeight: 'normal',
  },
}));

defaults.global.defaultFontFamily = 'OpenSans';
defaults.global.defaultFontColor = '#8898aa';

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

function CompetitorPart(props) {
  const { index, remove } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  const [openPhoto, setOpenPhoto] = React.useState(false);

  React.useEffect(() => {
    if (props.flag) {
      setOpen(false);
      props.setNewCompetitor(false);
    }
  }, [props.flag]);

  function validateLinkedinUrl(value) {
    if (value !== '' && value !== null) {
      let error;
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?',
      ); // query string
      error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
      return error;
    }
    return false;
  }

  return (
    <div>
      <Grid xs={12} container spacing={2} style={{ marginBottom: 15 }}>
        <Grid container justify="space-between">
          <Grid item alignItems="center" style={{ marginBottom: 20, display: 'flex' }}>
            {!props.competitor.title || !props.competitor.link ? (
              <Typography variant="h5" style={{ marginLeft: 5, color: 'rgb(255 186 0)' }}>
                {props.detail.competitors && props.detail.competitors[index]
                  ? props.detail.competitors[index].title
                  : 'Benzer Şirket Bilgileri'}
              </Typography>
            ) : (
              <Typography variant="h5" style={{ marginLeft: 5 }}>
                {props.detail.competitors && props.detail.competitors[index]
                  ? props.detail.competitors[index].title
                  : 'Benzer Şirket Bilgileri'}
              </Typography>
            )}
            {open ? (
              <ExpandLessIcon
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
              />
            )}
          </Grid>
          <DeleteOutlineIcon style={{ width: 25, cursor: 'pointer' }} onClick={() => remove(index)} />
        </Grid>
        {open && (
          <>
            <Grid item>
              <Field>
                {({ field, form, meta }) => (
                  <Dropzone
                    maxSize={20971520}
                    accept={'image/*'}
                    onDrop={(acceptedFiles) =>
                      setFiles(
                        acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })),
                        form.setFieldValue(`competitors.${index}.logo`, acceptedFiles),
                        setOpenPhoto(true),
                      )
                    }
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                      <section style={{ textAlign: 'center' }}>
                        {typeof form.values.competitors[index].logo !== 'string' ? (
                          <div {...getRootProps({ className: classes.baseStyle })}>
                            <input {...getInputProps()} />
                            {!form.values.competitors[index].logo ? (
                              <Grid item container direction="column" justify="center" alignItems="center">
                                {form.values.competitors[index].title ? (
                                  <Grid container alignItems="center" justify="center">
                                    <Typography variant="h4">
                                      {form.values.competitors[index].title.charAt(0)}
                                    </Typography>
                                    <AddIcon style={{ color: '#777777', fontSize: 20 }} />
                                  </Grid>
                                ) : (
                                  <>
                                    <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                    <Typography variant="body2" style={{ display: 'flex', color: '#777777' }}>
                                      {t('Logo')}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            ) : (
                              <aside style={thumbsContainer}>
                                {acceptedFiles[0] && (
                                  <div
                                    id={form.values.competitors[index].logo[0].name}
                                    style={thumb}
                                    key={form.values.competitors[index].logo[0].name}
                                  >
                                    <div style={thumbInner}>
                                      <img
                                        src={form.values.competitors[index].logo[0].preview}
                                        style={img}
                                        alt="file"
                                      />
                                    </div>
                                  </div>
                                )}
                                {form.values.competitors[index].logo[0] && !acceptedFiles[0] && (
                                  <div
                                    id={form.values.competitors[index].logo[0].name}
                                    style={thumb}
                                    key={form.values.competitors[index].logo[0].name}
                                  >
                                    <div style={thumbInner}>
                                      <img
                                        src={form.values.competitors[index].logo[0].preview}
                                        style={img}
                                        alt="file"
                                      />
                                    </div>
                                  </div>
                                )}
                              </aside>
                            )}
                          </div>
                        ) : (
                          <div {...getRootProps({ className: classes.baseStyle })}>
                            <img
                              src={form.values.competitors[index].logo}
                              alt="avatar"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        )}
                        {form.values.competitors[index].logo && (
                          <Button
                            variant="containedSecondary"
                            style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5, width: 80 }}
                            onClick={() => form.setFieldValue(`competitors.${index}.logo`, null)}
                          >
                            {t('Sil')}
                          </Button>
                        )}
                      </section>
                    )}
                  </Dropzone>
                )}
              </Field>
            </Grid>
            <Field>
              {({ field, form, meta }) => (
                <ImageCrop
                  setFieldValue={form.setFieldValue}
                  files={files}
                  index={index}
                  competitor={form.values.competitors[index]}
                  setOpenPhoto={setOpenPhoto}
                  openPhoto={openPhoto}
                />
              )}
            </Field>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" style={{ width: 'fit-content' }}>
                {t('Şirket Adı')}
              </Typography>
              <FormikTextField name={`competitors.${index}.title`} placeholder="Şirket adını yazınız" type="text" />
            </Grid>
            <Grid item xs={12} md>
              <Typography variant="h6" style={{ width: 'fit-content' }}>
                {t('Şirket Websitesi')}
              </Typography>
              <FormikTextField
                name={`competitors.${index}.link`}
                validate={validateLinkedinUrl}
                placeholder="https://"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <hr size="1" style={{ border: '0.2px solid #f5f5f5', marginBottom: 25 }} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default function ProductTab(props) {
  const classes = useStyles();
  const yesNo = ['Evet', 'Hayır'];
  const [newCompetitor, setNewCompetitor] = React.useState(false);
  const { t } = useTranslation();

  function validateLinkedinUrl(value) {
    if (value !== '' && value !== null) {
      let error;
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
      return error;
    }
    return false;
  }

  return (
    <>
      {!props.edit ? (
        <Paper className={classes.paper2}>
          <Grid container>
            <Grid container xs={12}>
              {props.detail.productsProblemsSolutions && (
                <>
                  <Grid item style={{ width: '100%' }}>
                    <Typography variant="h5" align="left">
                      {t('Problem ve Çözüm')}
                    </Typography>
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.productsProblemsSolutions ===
                            props.data.originalDetail.productsProblemsSolutions ? (
                              <Typography
                                className={classes.fontLg}
                                variant="body1"
                                align="left"
                                style={{ width: 'fit-content', marginTop: 20 }}
                              >
                                {props.detail.productsProblemsSolutions}
                              </Typography>
                            ) : (
                              <Typography
                                className={classes.fontLg}
                                variant="body1"
                                align="left"
                                style={{
                                  width: 'fit-content',
                                  marginTop: 20,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.productsProblemsSolutions}
                              </Typography>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography
                        className={classes.fontLg}
                        variant="body1"
                        align="left"
                        style={{ width: 'fit-content', marginTop: 20 }}
                      >
                        {props.detail.productsProblemsSolutions}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                  </Grid>
                </>
              )}
              {props.detail.productsDifferences && (
                <>
                  <Grid item style={{ width: '100%' }}>
                    <Typography variant="h5" align="left">
                      {t('Farklılaşma')}
                    </Typography>
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.productsDifferences ===
                            props.data.originalDetail.productsDifferences ? (
                              <Typography
                                className={classes.fontLg}
                                variant="body1"
                                align="left"
                                style={{ width: 'fit-content', marginTop: 20 }}
                              >
                                {props.detail.productsDifferences}
                              </Typography>
                            ) : (
                              <Typography
                                className={classes.fontLg}
                                variant="body1"
                                align="left"
                                style={{
                                  width: 'fit-content',
                                  marginTop: 20,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.productsDifferences}
                              </Typography>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography
                        className={classes.fontLg}
                        variant="body1"
                        align="left"
                        style={{ width: 'fit-content', marginTop: 20 }}
                      >
                        {props.detail.productsDifferences}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                  </Grid>
                </>
              )}
            </Grid>
            {(props.detail.webApp || props.detail.androidApp || props.detail.iosApp) && (
              <>
                <Grid container spacing={3} xs={12}>
                  <Grid item style={{ width: '100%' }}>
                    <Typography variant="h5" align="left">
                      {t('Uygulama Linkleri')}
                    </Typography>
                  </Grid>
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.webApp === props.data.originalDetail.webApp ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                Web App:
                              </Typography>
                              {props.detail.webApp && (
                                <a href={props.detail.webApp} target="_blank" style={{ padding: 0 }}>
                                  <Typography
                                    variant="h5"
                                    align="left"
                                    className={classes.fontLg}
                                    style={{ color: '#118DFF' }}
                                  >
                                    {truncateString(props.detail.webApp, 20)}
                                  </Typography>
                                </a>
                              )}
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                Web App:
                              </Typography>
                              {props.detail.webApp && (
                                <a href={props.detail.webApp} target="_blank" style={{ padding: 0 }}>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className={classes.fontLg}
                                    style={{
                                      background: '#0055ff12',
                                      padding: '0 5px',
                                      borderRadius: 4,
                                      color: '#118DFF',
                                    }}
                                  >
                                    {truncateString(props.detail.webApp, 20)}
                                  </Typography>
                                </a>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.webApp && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                          variant="h5"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          Web App:
                        </Typography>
                        {props.detail.webApp && (
                          <a href={props.detail.webApp} target="_blank" style={{ padding: 0 }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#118DFF' }}
                            >
                              {truncateString(props.detail.webApp, 20)}
                            </Typography>
                          </a>
                        )}
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.iosApp === props.data.originalDetail.iosApp ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                App Store:
                              </Typography>
                              {props.detail.iosApp && (
                                <a href={props.detail.iosApp} style={{ padding: 0, marginLeft: 10 }} target="_blank">
                                  <img src={apple} alt="apple"></img>
                                </a>
                              )}
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                App Store:
                              </Typography>
                              {props.detail.iosApp && (
                                <a href={props.detail.iosApp} style={{ padding: 0, marginLeft: 10 }} target="_blank">
                                  <img src={apple} alt="apple"></img>
                                </a>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.iosApp && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          App Store:
                        </Typography>
                        {props.detail.iosApp && (
                          <a href={props.detail.iosApp} style={{ padding: 0, marginLeft: 10 }} target="_blank">
                            <img src={apple} alt="apple"></img>
                          </a>
                        )}
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.androidApp === props.data.originalDetail.androidApp ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                Play Store:
                              </Typography>
                              {props.detail.androidApp && (
                                <a
                                  href={props.detail.androidApp}
                                  style={{ padding: 0, marginLeft: 10 }}
                                  target="_blank"
                                >
                                  <img src={google} alt="google"></img>
                                </a>
                              )}
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                Play Store:
                              </Typography>
                              {props.detail.androidApp && (
                                <a
                                  href={props.detail.androidApp}
                                  style={{ padding: 0, marginLeft: 10 }}
                                  target="_blank"
                                >
                                  <img src={google} alt="google"></img>
                                </a>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.androidApp && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          Play Store:
                        </Typography>
                        {props.detail.androidApp && (
                          <a href={props.detail.androidApp} style={{ padding: 0, marginLeft: 10 }} target="_blank">
                            <img src={google} alt="google"></img>
                          </a>
                        )}
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            {(props.detail.targetedRegion ||
              props.detail.targetedTotalMarketSize ||
              props.detail.totalMarketSize ||
              props.detail.detailMarketAnalysisNote) && (
              <>
                <Grid container xs={12}>
                  <Grid container>
                    <Typography variant="h5" style={{ width: 'fit-content' }}>
                      {t('Detay Pazar Analizi')}
                    </Typography>
                  </Grid>
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.targetedRegion === props.data.originalDetail.targetedRegion ? (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              lg={4}
                              style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                {t('Hedeflenen Bölge')} :
                              </Typography>
                              <Typography variant="h5" align="left" className={classes.fontLg}>
                                {props.detail.targetedRegion}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              lg={4}
                              style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                {t('Hedeflenen Bölge')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.targetedRegion}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.targetedRegion && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('Hedeflenen Bölge')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg}>
                          {props.detail.targetedRegion}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.targetedTotalMarketSize ===
                          props.data.originalDetail.targetedTotalMarketSize ? (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              lg={4}
                              style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                {t('Hedeflenen Pazar Büyüklüğü')}:
                              </Typography>
                              <Typography variant="h5" align="left" className={classes.fontLg}>
                                {props.detail.targetedTotalMarketSize}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              lg={4}
                              style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                {t('Hedeflenen Pazar Büyüklüğü')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.targetedTotalMarketSize}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.targetedTotalMarketSize && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('Hedeflenen Pazar Büyüklüğü')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg}>
                          {props.detail.targetedTotalMarketSize}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.totalMarketSize === props.data.originalDetail.totalMarketSize ? (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              lg={4}
                              style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                {t('Toplam Pazar Büyüklüğü')}:
                              </Typography>
                              <Typography variant="h5" align="left" className={classes.fontLg}>
                                {resolveCurrencySymbol(props.detail.currency)}
                                {props.detail.totalMarketSize &&
                                  numberWithPeriods(parseInt(props.detail.totalMarketSize))}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              lg={4}
                              style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0', marginRight: 10 }}
                              >
                                {t('Toplam Pazar Büyüklüğü')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {resolveCurrencySymbol(props.detail.currency)}
                                {props.detail.totalMarketSize &&
                                  numberWithPeriods(parseInt(props.detail.totalMarketSize))}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.totalMarketSize && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('Toplam Pazar Büyüklüğü')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg}>
                          {resolveCurrencySymbol(props.detail.currency)}
                          {props.detail.totalMarketSize && numberWithPeriods(parseInt(props.detail.totalMarketSize))}
                        </Typography>
                      </Grid>
                    )
                  )}
                  <Grid item style={{ width: '100%' }}>
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.detailMarketAnalysisNote ===
                            props.data.originalDetail.detailMarketAnalysisNote ? (
                              <Typography
                                className={classes.fontLg}
                                variant="body1"
                                align="left"
                                style={{ width: 'fit-content', marginTop: 20 }}
                              >
                                {props.detail.detailMarketAnalysisNote}
                              </Typography>
                            ) : (
                              <Typography
                                className={classes.fontLg}
                                variant="body1"
                                align="left"
                                style={{
                                  width: 'fit-content',
                                  marginTop: 20,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.detailMarketAnalysisNote}
                              </Typography>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography
                        className={classes.fontLg}
                        variant="body1"
                        align="left"
                        style={{ width: 'fit-content', marginTop: 20 }}
                      >
                        {props.detail.detailMarketAnalysisNote}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            {props.detail.competitors && props.detail.competitors.length > 0 && (
              <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
                {t('Benzer İş Modeline Sahip Şirketler')}
              </Typography>
            )}
            {props.detail.competitors && props.detail.competitors.length > 0 && (
              <>
                <Grid container spacing={3}>
                  {props.detail.competitors.map((competitor, index) => (
                    <>
                      {props.data ? (
                        <>
                          {props.data.newDetail && props.data.originalDetail && (
                            <>
                              {JSON.stringify(props.data.newDetail.competitors[index]) ===
                              JSON.stringify(props.data.originalDetail.competitors[index]) ? (
                                <Grid item xs={12} sm={6} lg={4} className={classes.objectCard}>
                                  {competitor.logo ? (
                                    <img
                                      src={competitor.logo}
                                      alt="avatar"
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Grid
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#003CA5',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {competitor.title && competitor.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{ textAlign: 'left' }}>
                                    <Typography variant="h6" align="left" style={{ marginBottom: 5 }}>
                                      {competitor.title}
                                    </Typography>
                                    <a href={competitor.link} target="_blank" style={{ padding: 0 }}>
                                      <img src={worldIcon} alt="world-icon" />
                                    </a>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className={classes.objectCard}
                                  style={{ background: '#0055ff12' }}
                                >
                                  {competitor.logo ? (
                                    <img
                                      src={competitor.logo}
                                      alt="avatar"
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Grid
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#003CA5',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {competitor.title && competitor.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{ textAlign: 'left' }}>
                                    <Typography variant="h6" align="left" style={{ marginBottom: 5 }}>
                                      {competitor.title}
                                    </Typography>
                                    <a href={competitor.link} target="_blank" style={{ padding: 0 }}>
                                      <img src={worldIcon} alt="world-icon" />
                                    </a>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <Grid item xs={12} sm={6} lg={4} className={classes.objectCard}>
                          {competitor.logo ? (
                            <img
                              src={competitor.logo}
                              alt="avatar"
                              style={{
                                maxWidth: 60,
                                height: 60,
                                borderRadius: '4px',
                                marginRight: 20,
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <Grid
                              style={{
                                maxWidth: 60,
                                height: 60,
                                borderRadius: '4px',
                                marginRight: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#003CA5',
                                width: '100%',
                              }}
                            >
                              <Typography variant="h3" style={{ color: '#fff' }}>
                                {competitor.title && competitor.title.charAt(0)}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item style={{ textAlign: 'left' }}>
                            <Typography variant="h6" align="left" style={{ marginBottom: 5 }}>
                              {competitor.title}
                            </Typography>
                            <a href={competitor.link} target="_blank" style={{ padding: 0 }}>
                              <img src={worldIcon} alt="world-icon" />
                            </a>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            {(props.detail.hasPotentialExitStrategy ||
              props.detail.plannedExitDate ||
              props.detail.potentialBuyers) && (
              <>
                <Grid container xs={12}>
                  <Grid container>
                    <Typography variant="h5" style={{ width: 'fit-content' }}>
                      {t('Çıkış Stratejisi')}
                    </Typography>
                  </Grid>
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.hasPotentialExitStrategy ===
                          props.data.originalDetail.hasPotentialExitStrategy ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Potansiyel Çıkış Stratejisi Var Mı?')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.hasPotentialExitStrategy === 'Evet' ? 'Var' : 'Yok'}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Potansiyel Çıkış Stratejisi Var Mı?')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.hasPotentialExitStrategy}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.hasPotentialExitStrategy && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Potansiyel Çıkış Stratejisi Var Mı?')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.hasPotentialExitStrategy}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.plannedExitDate === props.data.originalDetail.plannedExitDate ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Hedeflenen Çıkış Tarihi')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.plannedExitDate}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Hedeflenen Çıkış Tarihi')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.plannedExitDate}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.plannedExitDate && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Hedeflenen Çıkış Tarihi')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.plannedExitDate}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.potentialBuyers === props.data.originalDetail.potentialBuyers ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Potansiyel Alıcılar')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.potentialBuyers}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Potansiyel Alıcılar')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.potentialBuyers}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.potentialBuyers && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Potansiyel Alıcılar')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.potentialBuyers}
                        </Typography>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            {props.detail.documents && props.detail.documents.length > 0 && (
              <>
                <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
                  {t('Dokümanlar')}
                </Typography>
                <Grid container spacing={3}>
                  {props.detail.documents.map((document, index) => (
                    <>
                      {props.data ? (
                        <>
                          {props.data.newDetail && props.data.originalDetail && (
                            <>
                              {JSON.stringify(props.data.newDetail.documents[index]) ===
                              JSON.stringify(props.data.originalDetail.documents[index])
                                ? document.file && (
                                    <Grid item xs={12} md={6} lg={3} className={classes.objectCard}>
                                      <Grid container alignItems="center" className={classes.docsObject}>
                                        <a href={document.file} download style={{ display: 'flex', padding: 0 }}>
                                          <Grid
                                            item
                                            style={{
                                              padding: 10,
                                              border: '1px solid rgb(237 242 247 / 23%)',
                                              boxSizing: 'border-box',
                                              borderRadius: 10,
                                              marginRight: 16,
                                            }}
                                          >
                                            {document.extension && document.extension === '.pptx' ? (
                                              <img src={newPptx} alt="newPptx" />
                                            ) : document.extension && document.extension === '.xlsx' ? (
                                              <img src={newXlsx} alt="newXlsx" />
                                            ) : (
                                              <img src={DocsPhoto} alt="DocsPhoto" />
                                            )}
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="h6" align="left" style={{ color: '#118DFF' }}>
                                              {document.title}
                                              {document.extension}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              align="left"
                                              style={{ marginTop: 6, fontSize: 12 }}
                                            >
                                              {document.date && moment(document.date).format('DD MMMM, YYYY')}{' '}
                                              {document.fileSize && '-'}{' '}
                                              {document.fileSize &&
                                                (parseInt(document.fileSize) / (1024 * 1024)).toFixed(2)}
                                              {document.fileSize && 'Mb'}
                                            </Typography>
                                          </Grid>
                                        </a>
                                      </Grid>
                                    </Grid>
                                  )
                                : document.file && (
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      lg={3}
                                      className={classes.objectCard}
                                      style={{ background: '#0055ff12' }}
                                    >
                                      <Grid container alignItems="center" className={classes.docsObject}>
                                        <a href={document.file} download style={{ display: 'flex', padding: 0 }}>
                                          <Grid
                                            item
                                            style={{
                                              padding: 10,
                                              border: '1px solid rgb(237 242 247 / 23%)',
                                              boxSizing: 'border-box',
                                              borderRadius: 10,
                                              marginRight: 16,
                                            }}
                                          >
                                            {document.extension && document.extension === '.pptx' ? (
                                              <img src={newPptx} alt="newPptx" />
                                            ) : document.extension && document.extension === '.xlsx' ? (
                                              <img src={newXlsx} alt="newXlsx" />
                                            ) : (
                                              <img src={DocsPhoto} alt="DocsPhoto" />
                                            )}
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="h6" align="left" style={{ color: '#118DFF' }}>
                                              {document.title}
                                              {document.extension}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              align="left"
                                              style={{ marginTop: 6, fontSize: 12 }}
                                            >
                                              {document.date && moment(document.date).format('DD MMMM, YYYY')}{' '}
                                              {document.fileSize && '-'}{' '}
                                              {document.fileSize &&
                                                (parseInt(document.fileSize) / (1024 * 1024)).toFixed(2)}
                                              {document.fileSize && 'Mb'}
                                            </Typography>
                                          </Grid>
                                        </a>
                                      </Grid>
                                    </Grid>
                                  )}
                            </>
                          )}
                        </>
                      ) : (
                        document.file && (
                          <Grid item xs={12} md={6} lg={3} className={classes.objectCard}>
                            <Grid container alignItems="center" className={classes.docsObject}>
                              <a href={document.file} download style={{ display: 'flex', padding: 0 }}>
                                <Grid
                                  item
                                  style={{
                                    padding: 10,
                                    border: '1px solid rgb(237 242 247 / 23%)',
                                    boxSizing: 'border-box',
                                    borderRadius: 10,
                                    marginRight: 16,
                                  }}
                                >
                                  {document.extension && document.extension === '.pptx' ? (
                                    <img src={newPptx} alt="newPptx" />
                                  ) : document.extension && document.extension === '.xlsx' ? (
                                    <img src={newXlsx} alt="newXlsx" />
                                  ) : (
                                    <img src={DocsPhoto} alt="DocsPhoto" />
                                  )}
                                </Grid>
                                <Grid item>
                                  <Typography variant="h6" align="left" style={{ color: '#118DFF' }}>
                                    {document.title}
                                    {document.extension}
                                  </Typography>
                                  <Typography variant="body2" align="left" style={{ marginTop: 6, fontSize: 12 }}>
                                    {document.date && moment(document.date).format('DD MMMM, YYYY')}{' '}
                                    {document.fileSize && '-'}{' '}
                                    {document.fileSize && (parseInt(document.fileSize) / (1024 * 1024)).toFixed(2)}
                                    {document.fileSize && 'Mb'}
                                  </Typography>
                                </Grid>
                              </a>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper className={classes.paper2}>
            <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
              {t('Ürün & Servis')}
            </Typography>
            <Grid xs={12} item style={{ marginBottom: 30 }}>
              <Typography variant="h6" style={{ width: 'fit-content' }}>
                {t('Problem & Çözüm')}
              </Typography>
              <FormikMultiLine
                name="productsProblemsSolutions"
                rows={4}
                type="text"
                placeholder={t('Ürün hangi probleme nasıl çözüm sunuyor?')}
              />
            </Grid>
            <Grid xs={12} item style={{ marginBottom: 30 }}>
              <Typography variant="h6" style={{ width: 'fit-content' }}>
                {t('Farklılaşma')}
              </Typography>
              <FormikMultiLine
                name="productsDifferences"
                rows={4}
                type="text"
                placeholder={t('Ürünü pazardaki diğer ürünlerden farklı kılan nedir?')}
              />
            </Grid>
            <Grid xs={12} container spacing={2} style={{ margin: 0 }}>
              <Field>
                {({ field, form, meta }) => (
                  <Grid item xs={12} md={4}>
                    <Typography align="left" variant="h6">
                      {t('Ürünün uygulaması var mı?')}
                    </Typography>
                    <FormikSelect
                      name="hasApplication"
                      handleChange={
                        form.values.hasApplication === 'Hayır'
                          ? ((form.values.webApp = null), (form.values.iosApp = null), (form.values.androidApp = null))
                          : null
                      }
                      options={yesNo}
                    ></FormikSelect>
                  </Grid>
                )}
              </Field>
            </Grid>
            <Grid xs={12} container spacing={2} style={{ margin: 0 }}>
              <Field>
                {({ field, form, meta }) => (
                  <>
                    {form.values.hasApplication === 'Evet' && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Typography align="left" variant="h6">
                            {t('Web App Link')}
                          </Typography>
                          <FormikTextField
                            name="webApp"
                            type="text"
                            validate={validateLinkedinUrl}
                            placeholder="https://"
                          ></FormikTextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography align="left" variant="h6">
                            {t('App Store')}
                          </Typography>
                          <FormikTextField
                            name="iosApp"
                            type="text"
                            validate={validateLinkedinUrl}
                            placeholder="App Store linkinizi yazınız"
                          ></FormikTextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography align="left" variant="h6">
                            {t('Google Play Store')}
                          </Typography>
                          <FormikTextField
                            name="androidApp"
                            type="text"
                            validate={validateLinkedinUrl}
                            placeholder="Google Play Store linkinizi yazınız"
                          ></FormikTextField>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Field>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
              {t('Detay Pazar Analizi')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography align="left" variant="h6">
                  {t('Hedeflenen Pazar')}
                </Typography>
                <FormikTextField name="targetedMarket" type="text"></FormikTextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left" variant="h6">
                  {t('Toplam Pazar Büyüklüğü')}
                </Typography>
                <FormikNumberField
                  name={`totalMarketSize`}
                  onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                  maxLen={15}
                ></FormikNumberField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left" variant="h6">
                  {t('Hedeflenen Pazar Hacmi (%)')}
                </Typography>
                <FormikPercentageField name={`targetedTotalMarketSize`} maxLen={4}></FormikPercentageField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="left" variant="h6">
                  {t('Hedeflenen Bölge')}
                </Typography>
                <FormikTextField name="targetedRegion" type="text"></FormikTextField>
              </Grid>
              <Grid xs={12} item style={{ marginBottom: 30 }}>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Ek Notlar')}
                </Typography>
                <FormikMultiLine
                  name="detailMarketAnalysisNote"
                  rows={4}
                  type="text"
                  placeholder={t(
                    'Yukarıdaki bilgilere ek olarak eklemek istediğiniz noktalar varsa bu alana ekleyebilirsiniz.',
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
              {t('Benzer İş Modeline Sahip Şirketler')}
            </Typography>
            <Grid xs={12} item>
              <FieldArray
                name="competitors"
                render={({ insert, remove, push, unshift, form }) => (
                  <>
                    <div>
                      {form.values.competitors && form.values.competitors.length > 0 && (
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          {form.values.competitors.map((competitor, index) => (
                            <CompetitorPart
                              competitor={competitor}
                              detail={props.detail}
                              setNewCompetitor={setNewCompetitor}
                              flag={index + 1 !== form.values.competitors.length && newCompetitor}
                              remove={remove}
                              index={index}
                            />
                          ))}
                        </Grid>
                      )}
                      <Grid xs={12} container justify="center" style={{ marginTop: 20 }}>
                        <Button
                          variant="outlinedPrimary"
                          className={classes.AddButton}
                          onClick={() => {
                            push({ title: '', link: '', logo: null });
                            setNewCompetitor(true);
                          }}
                        >
                          {t('Benzer Şirket Ekle')}
                        </Button>
                      </Grid>
                    </div>
                  </>
                )}
              />
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid xs={12} container spacing={2} style={{ margin: 0 }}>
              <Grid item xs={12}>
                <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 15 }}>
                  {t('Çıkış Stratejisi')}
                </Typography>
              </Grid>
              <Field>
                {({ field, form, meta }) => (
                  <Grid item xs={12} md={4}>
                    <Typography align="left" variant="h6">
                      {t('Potansiyel çıkış stratejiniz var mı?')}
                    </Typography>
                    <FormikSelect
                      handleChange={
                        form.values.hasPotentialExitStrategy === 'Hayır'
                          ? ((form.values.plannedExitDate = null), (form.values.potentialBuyers = null))
                          : null
                      }
                      name="hasPotentialExitStrategy"
                      options={yesNo}
                    ></FormikSelect>
                  </Grid>
                )}
              </Field>
              <Field>
                {({ field, form, meta }) => (
                  <>
                    {form.values.hasPotentialExitStrategy === 'Evet' && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Typography align="left" variant="h6">
                            {t('Hedeflenen çıkış tarihi')}
                          </Typography>
                          <FormikTextField name="plannedExitDate" type="text" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography align="left" variant="h6">
                            {t('Potansiyel alıcılar kimler?')}
                          </Typography>
                          <FormikTextField name="potentialBuyers" placeholder="Yazınız" type="text" />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Field>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid xs={12} item>
              <Grid container alignItems="center">
                <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 10, marginRight: 10 }}>
                  {t('Dokümanlar')}
                </Typography>
                <Typography variant="body2" style={{ width: 'fit-content', marginBottom: 10, color: '#777777' }}>
                  {t('Doküman olarak sadece bu türleri ekleyebilirsiniz. (jpg, jpeg, png, gif, pdf, pptx, xls, xlsx)')}
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant="body2" align="left" style={{ width: 'fit-content', marginBottom: 30 }}>
                  *Ekleyeceğiniz dokümanlar, Startup Borsa’da yer alan diğer kullanıcılar tarafından görülebilir.
                  <br />
                  *Doküman yüklemeniz zorunlu değildir ancak bu dokümanlar sayesinde yatırımcıların ilgilisini çekebilir
                  ve girişiminizi ön plana çıkabilirsiniz.
                </Typography>
              </Grid>
              <FieldArray
                name="documents"
                render={({ insert, remove, push, unshift, form }) => (
                  <>
                    <div>
                      {form.values.documents.length > 0 && (
                        <Grid container xs={12} spacing={2}>
                          {form.values.documents.map((news, index) => (
                            <Grid item xs={12} md={6}>
                              <Grid container justify="space-between">
                                <Typography variant="h6" style={{ width: 'fit-content', marginBottom: 10 }}>
                                  {form.values.documents[index].title}
                                </Typography>
                                {(form.values.documents[index].file || form.values.documents[index].onlyFile) && (
                                  <Button
                                    variant="containedSecondary"
                                    style={{ padding: '4.5px 15px', fontSize: 14, width: 60, height: 30 }}
                                    onClick={() => {
                                      form.setFieldValue(`documents.${index}.file`, null);
                                      form.setFieldValue(`documents.${index}.onlyFile`, null);
                                      form.setFieldValue(`documents.${index}.extension`, null);
                                      form.setFieldValue(`documents.${index}.fileSize`, null);
                                      form.setFieldValue(`documents.${index}.date`, null);
                                    }}
                                  >
                                    {t('Sil')}
                                  </Button>
                                )}
                              </Grid>
                              <Field>
                                {({ field, form, meta }) => (
                                  <div>
                                    <Dropzone
                                      maxSize={20971520}
                                      onDrop={(acceptedFiles) =>
                                        form.setFieldValue(`documents.${index}.file`, acceptedFiles)
                                      }
                                    >
                                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <section>
                                          <div
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              padding: 13,
                                              border: '1px solid #DDDDDD',
                                            }}
                                            {...getRootProps({ className: classes.baseStyle })}
                                          >
                                            <input {...getInputProps()} />
                                            {!form.values.documents[index].file ? (
                                              <>
                                                <Typography variant="h6" style={{ display: 'flex' }}>
                                                  {t('Dosya Seç')}
                                                </Typography>
                                              </>
                                            ) : (
                                              <>
                                                {typeof form.values.documents[index].file !== 'string' ? (
                                                  <Typography variant="h6" align="left">
                                                    {form.values.documents[index].file[0] &&
                                                      form.values.documents[index].file[0].name}
                                                    {t(' Yüklendi')}
                                                  </Typography>
                                                ) : (
                                                  <Typography variant="h6" align="left">
                                                    {form.values.documents[index].file &&
                                                      form.values.documents[index].file
                                                        .split('/')
                                                        .pop()
                                                        .replace('.png', '')}
                                                    {t(' Yüklendi')}
                                                  </Typography>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                )}
                              </Field>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </div>
                  </>
                )}
              />
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}
