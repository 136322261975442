import {Button, Grid, withStyles} from "@material-ui/core";
import {Formik, Form} from "formik";
import {withRouter} from "react-router";
import React, {Fragment} from "react";
import get from "lodash/get";
import {popupSchema} from "../../yupSchemas";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minHeight: "35vh",
    width: "100%"
  },
  progresText: {
    backgroundColor: "white",
    bottom: 0,
    justifyContent: "flex-end",

    position: "fixed",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center"
    }
  },

  root: {
    padding: 0
  }
});
class Wizard extends React.Component {
  static Page = ({children}) => children;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      submitting: false,
      serverSuccess: false,
      page: 0,
      values: props.initialValues,
      serverMessage: {
        detail: "",
        message: "",
        type: false
      }
    };
  }

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = async variablesAll => {
    const {...variables} = variablesAll;
    if (variables.investorShareRate) {
      variables.investorShareRate = parseInt(variables.investorShareRate);
    } else {
      variables.investorShareRate = null;
    }
    this.props.firstPopup({variables}).then(data => {
      if (data.data.firstPopup) {
        if (variables.type === "Individual Investor") {
          this.props.onClose();
          this.props.history.push("/yatirimci-profili-yarat/?utm_profile_type=individual-investor");
        } else if (variables.type === "Corporate Investor") {
          this.props.onClose();
          this.props.history.push("/yatirimci-profili-yarat/?utm_profile_type=corporate-investor");
        } else if (variables.type === "Shareholder") {
          this.props.onClose();
          this.props.history.push("/dashboard/?utm_profile_type=founder");
        } else if (variables.type === "Employee") {
          this.props.onClose();
          this.props.history.push("/dashboard/?utm_event=employee");
        } else {
          this.props.onClose();
        }
      } else {
        this.props.setSecondPopupOpen(false);
      }
    });
  };
  handleNext = validateForm => {
    this.setState({page: this.state.page + 1});
  };

  handleBack = () => {
    this.props.setSecondPopupOpen(false);
    this.props.setFirstPopupOpen(true);
  };

  render() {
    const {children, classes} = this.props;
    const {values, page} = this.state;
    const activePage = React.Children.toArray(children)[page];
    const pageCount = React.Children.toArray(children).length - 1; // toArray removes null values count doesn't
    const isLastPage = page === pageCount;
    return (
      <Fragment>
        <Formik
          initialValues={values}
          enableReinitialize={true}
          validate={this.validate}
          validateOnMount={true}
          onSubmit={this.handleSubmit}
          render={({values, handleSubmit, isSubmitting, handleReset, isValid, validateForm}) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
              {activePage}
              <Grid container xs={12}>
                {isLastPage ? (
                  <Grid item container xs={12} justify="center" style={{marginTop: 30}}>
                    <Button
                      onClick={() => this.setState({page: this.state.page - 1})}
                      disabled={!isValid}
                      variant="outlinedSecondary"
                      style={{fontWeight: "normal", marginRight: "25px"}}
                    >
                      Geri
                    </Button>
                    <Button
                      variant="containedPrimary"
                      style={{fontWeight: "normal"}}
                      disabled={!isValid}
                      onClick={() => handleSubmit(values)}
                    >
                      {" "}
                      Onayla{" "}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item container xs={12} justify="center" style={{marginTop: 30}}>
                    {page !== 0 ? (
                      <Button
                        onClick={() => this.setState({page: this.state.page - 1})}
                        disabled={!isValid}
                        variant="outlinedSecondary"
                        style={{fontWeight: "normal", marginRight: "25px"}}
                      >
                        Geri
                      </Button>
                    ) : (
                      <Button
                        onClick={() => this.handleBack()}
                        variant="outlinedSecondary"
                        style={{fontWeight: "normal", marginRight: "25px"}}
                      >
                        Geri
                      </Button>
                    )}
                    <Button
                      variant="containedPrimary"
                      disabled={!isValid}
                      style={{fontWeight: "normal"}}
                      onClick={() => this.handleNext(validateForm)}
                    >
                      {" "}
                      Devam Et{" "}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, {withTheme: true})(Wizard));
