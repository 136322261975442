import React from 'react';
import Image from '../../static/img/404.png';
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 150,
    height: 50,
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
  },
  toolbar: {
    padding: '0px 100px',
  },
  applicationCard: {
    background: '#ffffff',
    marginTop: 100,
    marginBottom: 50,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 100,
    },
  },
}));
function FourOwlFour(props) {
  const classes = useStyles();
  return (
          <div>
            <Grid container justify="center">
                        <Grid item container direction="column" xs={6} style={{marginTop:'50px'}} alignItems="center">
                                <img src={Image} alt="404" style={{width:'40%'}}/>
                                <Typography variant="h2" style={{marginTop:'25px'}} align="center">
                                  Aradığınız Sayfa Bulunamadı
                                </Typography>
                                
                                  <Typography variant="body1" style={{marginTop:'35px',color:'#777777',marginBottom:'15px'}} align="center">
                                  Aradığınız sayfa geliştirme aşamasında ya da silinmiş olabilir. Size yardımcı olabilmemiz için “Destek Al” kısmından sistem yöneticilerine mesaj gönderebilirsiniz. Bir önceki sayfaya geri dönmek için lütfen "Anasayfaya Dön"'e tıklayın.
                                  </Typography>
                                <Link to="/">
                                    <Button
                                    style={{ fontWeight: 'normal', fontSize: 14, width: '100%', marginRight: 20, padding:'11.5px 30px' }}
                                    variant="containedPrimary"
                                    >
                                    Anasayfaya Dön
                                </Button>
                                </Link>
                        </Grid>
                </Grid>
          </div>
  );
}

export default withStyles({ withTheme: true })((FourOwlFour));
