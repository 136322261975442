import 'moment/locale/tr';

import { Field, Form, Formik } from 'formik';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import FormikDropdown from '../../Components/FormikDropdown';
import FormikNumberField2 from '../../Components/FormikNumberField2';
import FormikTextField from '../../Components/FormikShortTextField';
import GenericStepper from '../../Components/GenericStepper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MuiAlert from '@material-ui/lab/Alert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import newBag from '../../static/icon/new-bag.svg';
import { sharesSchema } from '../../yupSchemas';
import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  startupLogo: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
  },
  img: {
    width: 70,
    height: 70,
    objectFit: 'cover',
    border: '1px solid #f3f3f3',
    borderRadius: '5px',
    boxShadow: '-1px -1px 5px 2px #f3f3f3',
  },
  Title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: 'left',
    color: '#172b4d',
    wordBreak: 'break-word',
  },
  selected: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  search: {
    position: 'relative',
    width: '100%',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
  },
  searchBox: {
    top: 40,
    width: '100%',
    minWidth: 125,
    background: '#ffffff',
    borderRadius: 2,
    marginTop: 5,
    boxShadow: '1px 1px 2px 1px rgba(201,201,201,1)',
    maxHeight: 130,
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      minWidth: '-webkit-fill-available',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '-webkit-fill-available',
    },
  },
  backgroundPadding: {
    padding: '0 180px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  whiteCardEdit: {
    minHeight: 230,
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    textAlign: 'center',
    padding: 50,
    paddingTop: 30,
    [theme.breakpoints.down('lg')]: {
      padding: 35,
      paddingTop: 30,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
      marginBottom: 50,
      padding: 25,
    },
  },
}));

export default function StartupShare(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState();
  const [firstTab, setFirstTab] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [succesTab, setSuccessTab] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  const currency = ['Türk Lirası', 'İngiliz Sterlini', 'ABD Doları', 'Euro'];

  const SEARCH_STARTUPS = gql`
    query topStartupSearch($search: String) {
      topStartupSearch(search: $search) {
        id
        title
        oneliner
        logo
        sector
        slug
        website
        users {
          getFullName
          email
        }
      }
    }
  `;

  const [getData, { loading: loadingSearch, data: dataSearch }] = useLazyQuery(SEARCH_STARTUPS, {
    fetchPolicy: 'network-only',
    variables: { search: search },
  });

  function validateLinkedinUrl(value) {
    if (value !== '' && value !== null) {
      let error;
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      error = !pattern.test(value) ? 'Not valid URL.' : false;
      return error;
    }
    return false;
  }

  const handleSubmit = (variables) => {
    variables.title = search;
    if (variables.currency === 'Türk Lirası') {
      variables.currencySymbol = '₺';
    } else if (variables.currency === 'İngiliz Sterlini') {
      variables.currencySymbol = '£';
    } else if (variables.currency === 'ABD Doları') {
      variables.currencySymbol = '$';
    } else variables.currency = '€';

    variables.requestType = firstTab ? 'Buy' : 'Sell';
    variables.onListedStartup = !!selected;
    if (!!selected) {
      variables.startupId = selected.id;
    }

    variables.minAmount = parseInt(variables.minAmount);
    variables.maxAmount = parseInt(variables.maxAmount);
    /* variables.companyAuthorityFullname = startupFounder;
    variables.companyAuthorityEmail = startupMail;
    variables.companyWebsite = startupWebsite;
    variables.companyName = search; */

    props
      .createSecondaryShareRequest({ variables })
      .then(() => {
        setServerSuccess(true);
        setSuccessTab(true);
      })
      .catch((error) => {
        setServerError(true);
        console.log(error);
      });
  };

  const HandleSearch = (value, setFieldValue) => {
    setSearch(value);
    setFieldValue('companyName', value);
    getData();
    setSelected(null);
  };

  const handleActive = () => {
    if (window.gist) {
      window.gist.chat('open');
    }
  };

  const communicationMethod = ['Email', 'Telefon', 'Yüz yüze'];

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: 'rgb(247, 247, 252)', justifyContent: 'center', height: '100%' }}>
          <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'min-content', maxWidth: 1320 }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <img src={newBag} style={{ width: 18, marginTop: 3, marginLeft: '15px' }} alt="bag" />
              <Typography variant="h4">{t('Şirket Ekle')}</Typography>
            </Breadcrumbs>

            <Grid xs={12} style={{ margin: '15px' }} className={classes.whiteCardEdit}>
            
              <Grid container xs={12} justify="center" style={{ marginBottom: 20 }}>

                <Grid container justify="start" style={{ borderBottom: '2px solid #EDF2F7', marginBottom: 45 }}>
                  {firstTab ? (
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        marginRight: 50,
                        borderBottom: '2px solid #003CA5',
                        paddingBottom: 20,
                      }}
                    >
                      <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14, cursor: 'pointer' }}>
                        Hisse Al
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item style={{ display: 'flex', marginRight: 50 }}>
                      <Typography
                        onClick={() => (setFirstTab(true), setSuccessTab(false))}
                        variant="h6"
                        style={{ color: 'rgb(113, 128, 150)', fontSize: 14, cursor: 'pointer' }}
                      >
                        Hisse Al
                      </Typography>
                    </Grid>
                  )}
                  {!firstTab ? (
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        marginRight: 50,
                        borderBottom: '2px solid #003CA5',
                        paddingBottom: 20,
                      }}
                    >
                      <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14, cursor: 'pointer' }}>
                        Hisse Sat
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item style={{ display: 'flex', marginRight: 50 }}>
                      <Typography
                        onClick={() => (setFirstTab(false),setSuccessTab(false))}
                        variant="h6"
                        style={{ color: 'rgb(113, 128, 150)', fontSize: 14, cursor: 'pointer' }}
                      >
                        Hisse Sat
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid container>
                  <Grid container className={classes.backgroundPadding}></Grid>
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ marginBottom: 50, marginTop: 35 }} xs={12}>
                <Grid container xs={12} justify="space-between" spacing={10}>
                  <Snackbar
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => setServerSuccess(false)}
                    open={serverSuccess}
                  >
                    <Alert severity="success">Hisse işlemi talebiniz alındı.</Alert>
                  </Snackbar>
                  <Snackbar
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => setServerError(false)}
                    open={serverError}
                  >
                    <Alert severity="success">Hisse işlemi talebiniz Başarısız oldu.</Alert>
                  </Snackbar>
                  {succesTab ? (
              <Grid
                item
                container
                justify="center"
                style={{ flexDirection: 'column' }}
                xs={12}
                md={7}
                className={classes.whiteCardEdit}
              >
                <Grid item style={{ marginBottom: 30 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
                    <circle cx="58" cy="58" r="58" fill="#F0F5FF" />
                    <path
                      d="M51 58L55.6667 62.6667L65 53.3333M79 58C79 60.7578 78.4568 63.4885 77.4015 66.0364C76.3461 68.5842 74.7993 70.8992 72.8492 72.8492C70.8992 74.7993 68.5842 76.3461 66.0364 77.4015C63.4885 78.4568 60.7578 79 58 79C55.2422 79 52.5115 78.4568 49.9636 77.4015C47.4158 76.3461 45.1008 74.7993 43.1508 72.8492C41.2007 70.8992 39.6539 68.5842 38.5985 66.0364C37.5432 63.4885 37 60.7578 37 58C37 52.4305 39.2125 47.089 43.1508 43.1508C47.089 39.2125 52.4305 37 58 37C63.5695 37 68.911 39.2125 72.8492 43.1508C76.7875 47.089 79 52.4305 79 58Z"
                      stroke="#003CA5"
                      stroke-width="4.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>
                <Grid
                  item
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
                >
                  <Typography variant="h1" style={{ fontSize: 28, fontWeight: 'bold' }}>
                    Talebiniz İletildi
                  </Typography>
                  <Typography variant="span" style={{ fontSize: 16 }}>
                    Hisse talebiniz Account <br /> Manager’ınıza iletilmiştir. Bir sonraki aşama <br /> olan
                    Hesap Yöneticisi Onay’ı için <br /> bildirimlerinizi kontrol edebilirsiniz.
                  </Typography>
                </Grid>
                <Grid item container style={{ marginTop: 64, alignItems: 'center', justifyContent: 'center' }}>
                 
                  <Button onClick={()=>history.go(0)} variant="outlinedPrimary">Tamam</Button>
                 
                  <Link to="/girisimler">
                    <Button variant="containedPrimary">Diğer Girişimleri Keşfet</Button>
                  </Link>
                </Grid>
              </Grid>
              
            ) : (
              <>
                  <Grid item xs={12} md={6}>
                    <Grid item xs>
                      <Typography
                        variant="h5"
                        style={{ color: '#00000', width: '100%', marginBottom: 30, fontSize: 28 }}
                      >
                        {firstTab ? t('Hisse Al') : t('Hisse Sat')}
                      </Typography>
                    </Grid>
                    <Formik
                      initialValues={{
                        currencySymbol: '',
                        contactPreference: '',
                        minAmount: '',
                        maxAmount: '',
                        companyName: '',
                        companyWebsite: '',
                        companyAuthorityFullname: '',
                        companyAuthorityEmail: '',
                      }}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                      validationSchema={sharesSchema}
                    >
                      {(formikProps) => (
                        <Form>
                          {console.log(formikProps)}
                          <Field>
                            {({ form: { setFieldValue } }) => (
                              <Grid container xs={12}>
                                <Grid
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  container
                                  spacing={2}
                                >
                                  <Grid xs={12} md={6} item style={{ marginBottom: 24, marginTop: 30 }}>
                                    <Grid style={{ position: 'relative' }} container>
                                      <TextField
                                        fullWidth
                                        value={search}
                                        placeholder={t('Şirket Adı')}
                                        minLength={2}
                                        name="companyName"
                                        inputProps={{ 'aria-label': 'description' }}
                                        onChange={(e) => HandleSearch(e.target.value, setFieldValue)}
                                        variant="outlined"
                                      />

                                      {search && dataSearch && dataSearch.topStartupSearch.length > 0 ? (
                                        <Grid className={classes.searchBox} xs={3}>
                                          {!selected && (
                                            <List component="nav" aria-label="secondary mailbox folders">
                                              {dataSearch.topStartupSearch.map((searchResult) => (
                                                <>
                                                  {searchResult.status !== null && (
                                                    <Grid>
                                                      <ListItem
                                                        onClick={() => {
                                                          setFieldValue('companyName', searchResult.title);
                                                          setFieldValue('companyWebsite', searchResult.website);
                                                          setFieldValue(
                                                            'companyAuthorityFullname',
                                                            searchResult.users[0]?.getFullName,
                                                          );
                                                          setFieldValue(
                                                            'companyAuthorityEmail',
                                                            searchResult.users[0]?.email,
                                                          );
                                                          setSelected(searchResult);
                                                        }}
                                                        button
                                                        style={{ minHeight: 50 }}
                                                      >
                                                        <Grid container alignItems="center">
                                                          {searchResult.logo ? (
                                                            <img
                                                              src={searchResult.logo}
                                                              alt="rocket"
                                                              className={classes.startupLogo}
                                                            />
                                                          ) : (
                                                            <Grid
                                                              style={{
                                                                width: 30,
                                                                height: 30,
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                background: '#003CA5',
                                                              }}
                                                            >
                                                              <Typography variant="h3" style={{ color: '#fff' }}>
                                                                {searchResult.title && searchResult.title.charAt(0)}
                                                              </Typography>
                                                            </Grid>
                                                          )}
                                                          <Grid item style={{ marginLeft: 10 }}>
                                                            <Typography
                                                              variant="body1"
                                                              color="primary"
                                                              style={{ fontSize: 15, fontWeight: 500 }}
                                                            >
                                                              {searchResult.title}
                                                            </Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </ListItem>
                                                    </Grid>
                                                  )}
                                                </>
                                              ))}
                                            </List>
                                          )}
                                        </Grid>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Şirket adını girin.')}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid direction="row" justifyContent="center" alignItems="center" container spacing={3}>
                                  <Grid xs={12} md={6} item>
                                    <FormikTextField
                                      style={{
                                        margin: '-15px !important',
                                        padding: '-15px !important',
                                        boxShadow:
                                          '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                                        border: 0,
                                        borderRadius: 6,
                                      }}
                                      name="companyWebsite"
                                      type="text"
                                      //validate={validateLinkedinUrl}
                                      disabled={!!selected}
                                      style={{ backgroundColor: !!selected && 'gainsboro' }}
                                      //value={startupWebsite}
                                      //onChange={HandleSearchWebsite}
                                      placeholder="Şirket Websitesi"
                                    ></FormikTextField>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Şirket websitesini girin.')}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <FormikTextField
                                      style={{
                                        margin: '-15px !important',
                                        padding: '-15px !important',
                                        boxShadow:
                                          '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                                        border: 0,
                                        borderRadius: 6,
                                      }}
                                      name="companyAuthorityFullname"
                                      type="text"
                                      disabled={!!selected}
                                      style={{ backgroundColor: !!selected && 'gainsboro' }}
                                      //value={startupFounder}
                                      //onChange={HandleSearchFounder}
                                      placeholder="Şirket Yetkilisi Adı Soyadı"
                                    ></FormikTextField>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Şirket yetkilisi adı ve soyadını girin.')}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <FormikTextField
                                      style={{
                                        margin: '-15px !important',
                                        padding: '-15px !important',
                                        boxShadow:
                                          '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                                        border: 0,
                                        borderRadius: 6,
                                      }}
                                      name="companyAuthorityEmail"
                                      type="text"
                                      //value={startupMail}
                                      disabled={!!selected}
                                      style={{ backgroundColor: !!selected && 'gainsboro' }}
                                      //onChange={HandleSearchMail}
                                      placeholder="Şirket Yetkilisi E-posta Adresi"
                                    ></FormikTextField>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Şirket yetkilisi e-posta adresini girin.')}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={6} item>
                                    <Field
                                      variant="outlined"
                                      style={{ width: '100%', marginTop: 0 }}
                                      component={FormikDropdown}
                                      textFieldProps={{
                                        fullWidth: true,
                                        variant: 'outlined',
                                        placeholder: 'İletişim Yöntemi',
                                      }}
                                      name="contactPreference"
                                      options={communicationMethod}
                                      //onChange={(e) => setContactTypeSelect(e.target.value)}
                                    ></Field>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Hesap yöneticinizin sizinle iletişime geçeceği iletişim yöntemini seçin.')}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Field
                                      style={{ width: '100%', marginTop: 0 }}
                                      component={FormikDropdown}
                                      textFieldProps={{
                                        fullWidth: true,
                                        variant: 'outlined',
                                        placeholder: 'Para Birimi',
                                      }}
                                      name="currencySymbol"
                                      options={currency}
                                    ></Field>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('İşlem yapmak istedeğiniz para birimini belirleyin.')}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <FormikNumberField2
                                      style={{
                                        margin: '-15px !important',
                                        //padding: '-15px !important',
                                        boxShadow:
                                          '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                                        border: 0,
                                        borderRadius: 6,
                                      }}
                                      name="minAmount"
                                      type="number"
                                      required
                                      //validate={validateLinkedinUrl}
                                      placeholder="Minimum Tutar"
                                    ></FormikNumberField2>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Minimum para tutarını girin.')}
                                    </Typography>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <FormikNumberField2
                                      style={{
                                        //marginTop: '-15px !important',
                                        //padding: '-15px !important',
                                        boxShadow:
                                          '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                                        border: 0,
                                        borderRadius: 6,
                                      }}
                                      name="maxAmount"
                                      type="number"
                                      required
                                      //validate={validateLinkedinUrl}
                                      placeholder="Maksimum Tutar"
                                    ></FormikNumberField2>
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <Typography
                                      style={{
                                        width: '100%',
                                        color: '#7A7A9D',
                                        fontSize: 11,
                                        color: '#425466',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        textAlign: 'left',
                                        fontFeatureSettings: 'salt on',
                                      }}
                                    >
                                      {t('Maksimum para tutarını girin.')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid xs={12} md={12} justify="center" item>
                                  <Button
                                    variant="containedPrimary"
                                    style={{
                                      fontWeight: 'normal',
                                      padding: '12px 40px',
                                      margin: '48px 0px',
                                    }}
                                    disabled={!(formikProps.isValid && formikProps.dirty)}
                                    type="submit"
                                  >
                                    {firstTab ? t('Hisse Al') : t('Hisse Sat')}
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </Field>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                  </>
            )}
                  <Grid item xs={12} md={5}>
                    <GenericStepper
                      header="Yatırım Adımları"
                      description="Yatırım talebiniz gerçek bir yatırıma dönüşene kadar aşağıdaki adımları takip edeceğiz."
                      stepsJsonArray={[
                        { isFinished: true, title: 'Yatırım Teklifi' },
                        { isFinished: false, title: 'Yatırım Teklifinin Kabul Edilmesi' },
                        { isFinished: false, title: 'Sözleşme Sürecinin Tamamlanması' },
                        { isFinished: false, title: 'Yatırım Miktarının Yollanması' },
                        { isFinished: false, title: 'Girişimin Hissedarı Olmak' },
                      ]}
                      bottomJSX={
                        <Typography
                          variant="h5"
                          style={{
                            width: '100%',
                            fontSize: 14,
                            color: '#A0AEC0',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            textAlign: 'center',
                            fontFeatureSettings: 'salt on',
                          }}
                        >
                          Sorularınız mı var?
                          <a
                            type="button"
                            onClick={handleActive}
                            style={{ fontSize: 14, cursor: 'pointer', color: '#003CA5', fontWeight: '600' }}
                          >
                            Bizimle iletişime geçin
                          </a>
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
