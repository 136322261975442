import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    backgroundColor: '#F0F5FF',
    zIndex: '998',
  },
  stepperBlue: {
    backgroundColor: '#003CA5',
    border: 0,
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    zIndex: '998',
  },
  stepperLine: {
    width: '8px',
    borderRadius: 50,
    height: '430px',
    [theme.breakpoints.down('xs')]: {
      height: '500px',
    },

    backgroundColor: '#F0F5FF',
    position: 'absolute',
    marginLeft: '14px',
    marginTop: '-20px',
    zIndex: '1',
  },
  subtitle: {
    marginBottom: 24,
    marginTop: 24,
    color: '#425466',
    fontSize: '16px',
  },
  title: {
    color: '#27272E',
    fontSize: '28px',
  },
  stepperBorder: {
    paddingLeft: 37,
    borderLeft: '1px solid #cfcfcf',
    [theme.breakpoints.down('md')]: {
      padding: 15,
      borderLeft: 'none',
    },
  },
}));

function Stepper(props) {
  const classes = useStyles();

  return (
    <div className={classes.stepperBorder}>
      {/************************ Stepper ***********************************/}

      <Typography className={classes.title} align="center" variant="h2">
        Süreç
      </Typography>
      <Typography className={classes.subtitle} variant="h6" align="center">
        Startup Borsa yatırım arayan girişimlere erişim sağlamaya yardımcı olur ve iletişim sürecini destekler.
      </Typography>
      <div className={classes.stepperLine}></div>

      <Grid container xs style={{ marginBottom: 30 }}>
        <Grid item className={classes.stepperBlue}>
          <Typography variant="h6" style={{ color: '#fff' }}>
            {props.firstStep}
          </Typography>
        </Grid>
        <Grid xs>
          <Typography
            variant="h6"
            style={{ textAlign: 'start', fontWeight: '600', fontSize: '14px', width: '100%', marginBottom: 5 }}
          >
            Girişimleri İncele
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: '#718096',
              textAlign: 'start',
              fontWeight: 'normal',
              fontSize: '12px',
              width: '100%',
            }}
          >
            Türkiye'nin önde gelen teknoloji girişimlerinin buluşma noktası olan Startup Borsa'da girişimleri incele.
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs style={{ marginBottom: 30 }}>
        <Grid item className={classes.stepper}>
          <Typography variant="h6" style={{ color: '#003CA5' }}>
            2
          </Typography>
        </Grid>
        <Grid xs>
          <Typography
            variant="h6"
            style={{ textAlign: 'start', fontWeight: '600', fontSize: '14px', width: '100%', marginBottom: 5 }}
          >
            Yatırım Turundaki Girişimleri Gör
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: '#718096',
              textAlign: 'start',
              fontWeight: 'normal',
              fontSize: '12px',
              width: '100%',
            }}
          >
            Yatırım turunda olan girişimlere eriş ve tur hakkında bilgiye sahip ol.
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs style={{ marginBottom: 30 }}>
        <Grid item className={classes.stepper}>
          <Typography variant="h6" style={{ color: '#003CA5' }}>
            3
          </Typography>
        </Grid>
        <Grid xs>
          <Typography
            variant="h6"
            style={{ textAlign: 'start', fontWeight: '600', fontSize: '14px', width: '100%', marginBottom: 5 }}
          >
            Talebini İlet
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: '#718096',
              textAlign: 'start',
              fontWeight: 'normal',
              fontSize: '12px',
              width: '100%',
            }}
          >
            Yatırım turuna dahil olmak istediğiniz girişimlere katılmak istediğiniz tutar ile birlikte talebini ilet.
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs style={{ marginBottom: 30 }}>
        <Grid item className={classes.stepper}>
          <Typography variant="h6" style={{ color: '#003CA5' }}>
            4
          </Typography>
        </Grid>
        <Grid xs>
          <Typography
            variant="h6"
            style={{ textAlign: 'start', fontWeight: '600', fontSize: '14px', width: '100%', marginBottom: 5 }}
          >
            Girişimi Takip Et
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: '#718096',
              textAlign: 'start',
              fontWeight: 'normal',
              fontSize: '12px',
              width: '100%',
            }}
          >
            Yatırımcısı olduğunuz Startup Borsa üzerinden raporlama özelliğiyle gelişimini takip et.
          </Typography>
        </Grid>
      </Grid>

      {/************************ Stepper END ***********************************/}
      <Grid item xs>
        <Typography
          variant="h5"
          style={{
            width: '100%',
            marginTop: 90,
            fontSize: 14,
            color: '#A0AEC0',
            fontStyle: 'normal',
            fontWeight: '600',
            textAlign: 'center',
            fontFeatureSettings: 'salt on',
          }}
        >
          Sorularınız mı var?
          <a
            type="button"
            onClick={() => {
              if (window.gist) {
                window.gist.chat('open');
              }
            }}
            style={{ fontSize: 14, color: '#003CA5', fontWeight: '600', cursor: 'pointer' }}
          >
            İletişime geç
          </a>
        </Typography>
      </Grid>
    </div>
  );
}

export default Stepper;
