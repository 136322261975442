import {makeStyles, withStyles} from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import get from "lodash/get";
import gql from "graphql-tag";
import newEye from "../../static/icon/new-eye.svg";
import {useQuery} from "@apollo/react-hooks";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth
    },
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  root: {
    display: "flex"
  },
  cardGrid: {
    marginTop: 35
  },
  TabsGeneral: {
    background: "#fff",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingLeft: 30
  },
  Debounce: {
    width: "100%",
    maxWidth: "180px",
    fontSize: 16,
    height: "35px",
    color: "#8898aa",
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    border: "none",
    outline: "none",
    borderBottom: "1px solid #c8c8ca",
    background: "inherit",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      height: "40px"
    }
  },
  multiSelect: {
    marginBottom: 15,
    "& div": {
      background: "none!important",
      paddingTop: "0!important",
      "& input": {
        paddingTop: "8px!important"
      }
    }
  },
  ListItemText: {
    "& span": {
      fontSize: "16px",
      color: "#8898aa"
    }
  },
  filterArea: {
    display: "flex",
    alignItems: "flex-end"
  },
  ButtonMobile: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    }
  },
  filter: {
    height: 40,
    padding: "0 15px",
    border: "1px solid rgb(221 221 221)",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      marginRight: "20px"
    }
  },
  myPaper: {
    padding: 25,
    width: "650px"
  },
  myLabel: {
    fontSize: 14
  },
  formControl: {
    marginTop: 10
  },
  myCheckbox: {
    padding: "5px 9px"
  },
  whiteCard: {
    background: "#ffffff",
    borderRadius: "4px",
    padding: 25,
    marginBottom: 50
  },
  tabPaper: {
    marginTop: 50
  },
  sticker: {
    marginRight: 10,
    color: "#636973",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10
    }
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#FAFAFB",
    color: "#8492A6",
    fontSize: 11,
    padding: "16px 24px"
  },
  body: {
    fontSize: 14,
    borderBottom: "none",
    padding: "12px 0px 12px 24px"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {}
}))(TableRow);

const AntSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

function ExpandingRow(props) {
  const classes = useStyles();
  const {item, index} = props;

  const handleActionChecked = event => {
    props
      .adminHandleInvestmentRequestAction({variables: {reqId: item.id, adminTookAction: event.target.checked}})
      .then(data => {
        if (data.data.adminHandleInvestmentRequestAction) {
          props.refetch();
        } else {
        }
      })
      .then(() => {})
      .catch(error => {});
  };
  return (
    <>
      {item && (
        <StyledTableRow key={index + 1}>
          <StyledTableCell align="left">
            <Typography variant="h6" style={{fontSize: 14}}>
              {item.investor && item.investor.firstName} {item.investor && item.investor.lastName}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body2">{item.startup && item.startup.title}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body2">{item.responseExplanation}</Typography>
          </StyledTableCell>
          {item.status === "Approved" && (
            <StyledTableCell align="left">
              <Typography variant="body2">{item.communicationMethod}</Typography>
            </StyledTableCell>
          )}
          <StyledTableCell align="left">
            <Typography variant="body2">{item.investor && item.investor.email}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body2">{item.investor && item.investor.phoneNumber}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left" style={{paddingLeft: 22, minWidth: 75}}>
            <Grid container alignItems="center">
              <AntSwitch checked={item.adminTookAction} onChange={handleActionChecked} name="adminTookAction" />
            </Grid>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
}

export default function AdminInvestmentRequests(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(20);
  const [status, setStatus] = React.useState(["Approved"]);
  const [requests, setRequests] = React.useState();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;

  const GET_INVESTMENT_REQUESTS = gql`
    query adminAllInvestmentRequestsPaginate($page: Int, $pageSize: Int, $status: [String]) {
      adminAllInvestmentRequestsPaginate(pagination: {page: $page, pageSize: $pageSize}, filter: {status: $status}) {
        count
        pageCount
        data {
          id
          startup {
            id
            title
          }
          investor {
            id
            firstName
            lastName
            phoneNumber
            email
          }
          status
          communicationMethod
          responseExplanation
          adminTookAction
        }
      }
    }
  `;
  const {loading, data, refetch} = useQuery(GET_INVESTMENT_REQUESTS, {
    fetchPolicy: "network-only",
    variables: {page: page, pageSize: pageSize, status: status}
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setRequests(data.adminAllInvestmentRequestsPaginate.data);
        setPageCount(data.adminAllInvestmentRequestsPaginate.pageCount);
      }
    }
  }, [data, loading]);

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
        })}
      >
        <Grid container style={{background: "#f7f7fc", justifyContent: "center", minHeight: "100vh"}}>
          <Grid className={"main-container"} xs={12} style={{marginTop: 140, height: "fit-content", maxWidth: 1320}}>
            <Grid item style={{width: "100%"}}>
              <Grid container justify="space-between" alignItems="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <img src={newEye} style={{width: 18, marginTop: 8}} alt="people" />
                  <Typography variant="h4">Yatırım Başvuruları</Typography>
                </Breadcrumbs>
              </Grid>
              {requests && (
                <Grid container xs={12}>
                  <AppBar style={{zIndex: 0}} className={classes.tabPaper} position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="rgb(247, 247, 252)"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className={classes.TabsGeneral}
                    >
                      <Tab
                        label="Onaylananlar"
                        {...a11yProps(0)}
                        onClick={() => setStatus(["Approved"])}
                        style={{fontWeight: 600}}
                      />
                      <Tab
                        label="Reddedilenler"
                        {...a11yProps(1)}
                        onClick={() => setStatus(["Rejected"])}
                        style={{fontWeight: 600}}
                      />
                    </Tabs>
                  </AppBar>
                  <TabPanel style={{width: "100%"}} value={value} index={0}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{boxShadow: "none"}} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Kişi{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Başvuran girişim{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Ek not{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  İletişim Tercihi{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Email{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Telefon{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Statü{" "}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {requests && (
                            <TableBody>
                              {requests.map((request, index) => (
                                <ExpandingRow
                                  index={index}
                                  item={request}
                                  refetch={refetch}
                                  adminHandleInvestmentRequestAction={props.adminHandleInvestmentRequestAction}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <Grid container justify="flex-end">
                        <Grid item style={{marginTop: 10, padding: 0}} justify="flex-end">
                          {status && status[0] === "Approved" && (
                            <Pagination
                              count={pageCount}
                              page={page}
                              onChange={handlePageChange}
                              variant="outlined"
                              color="primary"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel style={{width: "100%"}} value={value} index={1}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{boxShadow: "none"}} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Kişi{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Başvuran girişim{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Ek not{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Email{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Telefon{" "}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {" "}
                                  Statü{" "}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {requests && (
                            <TableBody>
                              {requests.map((request, index) => (
                                <ExpandingRow
                                  index={index}
                                  item={request}
                                  refetch={refetch}
                                  adminHandleInvestmentRequestAction={props.adminHandleInvestmentRequestAction}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <Grid container justify="flex-end">
                        <Grid item style={{marginTop: 10, padding: 0}} justify="flex-end">
                          {status && status[0] === "Rejected" && (
                            <Pagination
                              count={pageCount}
                              page={page}
                              onChange={handlePageChange}
                              variant="outlined"
                              color="primary"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
