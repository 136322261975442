import "moment/locale/tr";

import {Field, Form, Formik} from "formik";

import Button from "@material-ui/core/Button";
import {Dialog} from "@material-ui/core";
import FormikDropdown from "../../Components/FormikDropdown";
import FormikNumberField2 from "../../Components/FormikNumberField2";
import GenericStepper from "../../Components/GenericStepper";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import gql from "graphql-tag";
import {makeStyles} from "@material-ui/core/styles";
import {sharesSchemaListed} from "../../yupSchemas";
import {useMutation} from "react-apollo";
import {useTranslation} from "react-i18next";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  Title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: "left",
    color: "#172b4d",
    wordBreak: "break-word"
  },
  selected: {
    padding: "10px 20px",
    cursor: "pointer"
  },
  backgroundPadding: {
    padding: "0 180px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px"
    }
  },
  whiteCardEdit: {
    background: "#ffffff",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgb(237, 242, 247)",
    textAlign: "center",
    padding: 50,
    paddingTop: 30,
    [theme.breakpoints.down("lg")]: {
      padding: 35,
      paddingTop: 30
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "0",
      marginBottom: 50,
      padding: 25
    }
  },
  mainContainer: {
    height: "%100",
    [theme.breakpoints.up("sm")]: {
      maxHeight: 800
    }
  },
  mobilHisse: {
    flexDirection: "column",
    padding: "50px 105px 0px 105px",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      padding: 0,
    }
  },
}));

const CREATE_SECONDARY_SHARE_REQUEST = gql`
  mutation createSecondaryShareRequest(
    $requestType: String!
    $onListedStartup: Boolean!
    $startupId: ID
    $currencySymbol: String!
    $contactPreference: String!
    $minAmount: Int!
    $maxAmount: Int!
  ) {
    createSecondaryShareRequest(
      requestType: $requestType
      onListedStartup: $onListedStartup
      startupId: $startupId
      currencySymbol: $currencySymbol
      contactPreference: $contactPreference
      minAmount: $minAmount
      maxAmount: $maxAmount
    ) {
      id
    }
  }
`;

function MakeSecondaryShareRequestPopup({openWithType, setOpenWithType, startupId}) {
  const classes = useStyles();
  const {t} = useTranslation();

  //states
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(openWithType);
  const [succesTab, setSuccessTab] = React.useState(false);

  //effect
  React.useEffect(() => {
    setSelectedTab(openWithType);
    setSuccessTab(false);
    setServerSuccess(false);
  }, [openWithType]);

  //gql
  const [createSecondaryShareRequest] = useMutation(CREATE_SECONDARY_SHARE_REQUEST);

  //helpers
  const currency = ["Türk Lirası", "İngiliz Sterlini", "ABD Doları", "Euro"];
  const communicationMethod = ["Email", "Telefon", "Yüz yüze"];

  const handleSubmit = variables => {
    if (variables.currency === "Türk Lirası") {
      variables.currencySymbol = "₺";
    } else if (variables.currency === "İngiliz Sterlini") {
      variables.currencySymbol = "£";
    } else if (variables.currency === "ABD Doları") {
      variables.currencySymbol = "$";
    } else variables.currency = "€";

    variables.requestType = selectedTab;
    variables.onListedStartup = true;
    variables.startupId = startupId;

    variables.minAmount = parseInt(variables.minAmount);
    variables.maxAmount = parseInt(variables.maxAmount);

    createSecondaryShareRequest({variables})
      .then(() => {
        setServerSuccess(true);
        setSuccessTab(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleActive = () => {
    if (window.gist) {
      window.gist.chat("open");
    }
  };

  if (!!!openWithType) return null;

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={() => setOpenWithType(false)} open={!!openWithType}>
      <Grid container className={classes.mainContainer}>
        {succesTab ? (
          <Grid
            item
            container
            justify="center"
            style={{flexDirection: "column"}}
            xs={12}
            md={7}
            className={classes.whiteCardEdit}
          >
            <Grid item style={{marginBottom: 30}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
                <circle cx="58" cy="58" r="58" fill="#F0F5FF" />
                <path
                  d="M51 58L55.6667 62.6667L65 53.3333M79 58C79 60.7578 78.4568 63.4885 77.4015 66.0364C76.3461 68.5842 74.7993 70.8992 72.8492 72.8492C70.8992 74.7993 68.5842 76.3461 66.0364 77.4015C63.4885 78.4568 60.7578 79 58 79C55.2422 79 52.5115 78.4568 49.9636 77.4015C47.4158 76.3461 45.1008 74.7993 43.1508 72.8492C41.2007 70.8992 39.6539 68.5842 38.5985 66.0364C37.5432 63.4885 37 60.7578 37 58C37 52.4305 39.2125 47.089 43.1508 43.1508C47.089 39.2125 52.4305 37 58 37C63.5695 37 68.911 39.2125 72.8492 43.1508C76.7875 47.089 79 52.4305 79 58Z"
                  stroke="#003CA5"
                  stroke-width="4.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Grid>
            <Grid item style={{display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
              <Typography variant="h1" style={{fontSize: 28, fontWeight: "bold"}}>
                Talebiniz İletildi
              </Typography>
              <Typography variant="span" style={{fontSize: 16}}>
                Yatırım Arama talebiniz Account <br /> Manager’ınıza iletilmiştir. Bir sonraki aşama <br /> olan Hesap
                Yöneticisi Onay’ı için <br /> bildirimlerinizi kontrol edebilirsiniz.
              </Typography>
            </Grid>
            <Grid item container style={{marginTop: 64, alignItems: "center", justifyContent: "center"}}>
              <Button variant="outlinedPrimary" onClick={() => setOpenWithType(false)}>
                Tamam
              </Button>
              <Link to="/girisimler">
                <Button variant="containedPrimary">Diğer Girişimleri Keşfet</Button>
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={7} className={classes.whiteCardEdit}>
            <Grid container xs={12} justify="center" style={{marginBottom: 20}}>
              <Grid container justify="start" style={{borderBottom: "2px solid #EDF2F7", marginBottom: 45}}>
                {selectedTab === "Buy" ? (
                  <Grid
                    item
                    style={{display: "flex", marginRight: 50, borderBottom: "2px solid #003CA5", paddingBottom: 20}}
                  >
                    <Typography variant="h6" style={{color: "#003CA5", fontSize: 14, cursor: "pointer"}}>
                      Hisse Al
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item style={{display: "flex", marginRight: 50}}>
                    <Typography
                      onClick={() => setSelectedTab("Buy")}
                      variant="h6"
                      style={{color: "rgb(113, 128, 150)", fontSize: 14, cursor: "pointer"}}
                    >
                      Hisse Al
                    </Typography>
                  </Grid>
                )}
                {selectedTab === "Sell" ? (
                  <Grid
                    item
                    style={{display: "flex", marginRight: 50, borderBottom: "2px solid #003CA5", paddingBottom: 20}}
                  >
                    <Typography variant="h6" style={{color: "#003CA5", fontSize: 14, cursor: "pointer"}}>
                      Hisse Sat
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item style={{display: "flex", marginRight: 50}}>
                    <Typography
                      onClick={() => setSelectedTab("Sell")}
                      variant="h6"
                      style={{color: "rgb(113, 128, 150)", fontSize: 14, cursor: "pointer"}}
                    >
                      Hisse Sat
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid container className={classes.backgroundPadding}></Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              className={classes.mobilHisse}
              item
              container
              alignItems="center"
              justify="center"
            >
              <Grid item style={{marginBottom: 16}}>
                <Typography variant="h5" style={{color: "#00000", fontSize: 28}}>
                  {selectedTab === "Buy" ? t("Hisse Al") : t("Hisse Sat")}
                </Typography>
              </Grid>
              <Grid item style={{marginBottom: 30}}>
                <Typography variant="span" style={{color: "#425466", fontSize: 24}}>
                  {selectedTab === "Buy"
                    ? t("Hisse alımını daha kolay ve hızlı hale getirecek bir yazı yazılabilir")
                    : t(
                        "Hangi hisseyi ya da nasıl aldığını belirtecek ve kullanıcıyı bilgilendirecek yazılar yazabilir"
                      )}
                </Typography>
              </Grid>
              <Grid item>
                <Formik
                  initialValues={{
                    currencySymbol: "",
                    contactPreference: "",
                    minAmount: "",
                    maxAmount: ""
                  }}
                  onSubmit={values => {
                    handleSubmit(values);
                  }}
                  validationSchema={sharesSchemaListed}
                >
                  {formikProps => (
                    <Form>
                      <Grid container xs={12}>
                        <Grid direction="row" justifyContent="center" alignItems="center" container spacing={3}>
                          <Grid xs={12} md={6} item>
                            <Field
                              variant="outlined"
                              style={{width: "100%", marginTop: 0}}
                              component={FormikDropdown}
                              textFieldProps={{
                                fullWidth: true,
                                variant: "outlined",
                                placeholder: "İletişim Yöntemi"
                              }}
                              name="contactPreference"
                              options={communicationMethod}
                            ></Field>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                width: "100%",
                                color: "#7A7A9D",
                                fontSize: 11,
                                color: "#425466",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textAlign: "left",
                                fontFeatureSettings: "salt on"
                              }}
                            >
                              {t("Hesap yöneticinizin sizinle iletişime geçeceği iletişim yöntemini seçin.")}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Field
                              style={{width: "100%", marginTop: 0}}
                              component={FormikDropdown}
                              textFieldProps={{
                                fullWidth: true,
                                variant: "outlined",
                                placeholder: "Para Birimi"
                              }}
                              name="currencySymbol"
                              options={currency}
                            ></Field>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                width: "100%",
                                color: "#7A7A9D",
                                fontSize: 11,
                                color: "#425466",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textAlign: "left",
                                fontFeatureSettings: "salt on"
                              }}
                            >
                              {t("İşlem yapmak istediğiniz para birimini belirleyin.")}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <FormikNumberField2
                              style={{
                                margin: "-15px !important",
                                //padding: '-15px !important',
                                boxShadow:
                                  "0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important",
                                border: 0,
                                borderRadius: 6
                              }}
                              name="minAmount"
                              type="number"
                              required
                              placeholder="Minimum Tutar Girin"
                            ></FormikNumberField2>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                width: "100%",
                                color: "#7A7A9D",
                                fontSize: 11,
                                color: "#425466",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textAlign: "left",
                                fontFeatureSettings: "salt on"
                              }}
                            >
                              {t("Minimum para tutarını girin.")}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <FormikNumberField2
                              style={{
                                boxShadow:
                                  "0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important",
                                border: 0,
                                borderRadius: 6
                              }}
                              name="maxAmount"
                              type="number"
                              required
                              placeholder="Maksimum Tutar Girin"
                            ></FormikNumberField2>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                width: "100%",
                                color: "#7A7A9D",
                                fontSize: 11,
                                color: "#425466",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                textAlign: "left",
                                fontFeatureSettings: "salt on"
                              }}
                            >
                              {t("Maksimum para tutarını girin.")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid xs={12} md={12} justify="center" item>
                          <Button
                            variant="containedPrimary"
                            style={{
                              fontWeight: "normal",
                              padding: "12px 40px",
                              margin: "48px 0px"
                            }}
                            disabled={!(formikProps.isValid && formikProps.dirty)}
                            type="submit"
                          >
                            {selectedTab === "Buy" ? t("Hisse Al") : t("Hisse Sat")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item container justify="center" xs={12} md={5} style={{padding: "40px 58px"}}>
          <GenericStepper
            header="Yatırım Adımları"
            description="Yatırım talebiniz gerçek bir yatırıma dönüşene kadar aşağıdaki adımları takip edeceğiz."
            stepsJsonArray={[
              {isFinished: true, title: "Yatırım Teklifi"},
              {isFinished: false, title: "Yatırım Teklifinin Kabul Edilmesi"},
              {isFinished: false, title: "Sözleşme Sürecinin Tamamlanması"},
              {isFinished: false, title: "Yatırım Miktarının Yollanması"},
              {isFinished: false, title: "Girişimin Hissedarı Olmak"}
            ]}
            bottomJSX={
              <Typography
                variant="h5"
                style={{
                  width: "100%",
                  fontSize: 14,
                  color: "#A0AEC0",
                  fontStyle: "normal",
                  fontWeight: "600",
                  textAlign: "center",
                  fontFeatureSettings: "salt on"
                }}
              >
                Sorularınız mı var?
                <a
                  type="button"
                  onClick={handleActive}
                  style={{fontSize: 14, cursor: "pointer", color: "#003CA5", fontWeight: "600"}}
                >
                  Bizimle iletişime geçin
                </a>
              </Typography>
            }
          />
        </Grid>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{vertical: "top", horizontal: "center"}}
          onClose={() => setServerSuccess(false)}
          open={serverSuccess}
        >
          <Alert severity="success">Hisse işlemi talebiniz alındı.</Alert>
        </Snackbar>
      </Grid>
    </Dialog>
  );
}

export default MakeSecondaryShareRequestPopup;
