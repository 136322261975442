import {Button, Grid, withStyles} from "@material-ui/core";
import {Form, Formik} from "formik";
import React, {Fragment} from "react";
import {withRouter} from "react-router";
import {investorProfileSchema} from "../../yupSchemas";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    minHeight: "35vh",
    width: "100%"
  },
  bottomBar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 999,
    background: "#fff",
    padding: "8px 70px",
    boxShadow: "0px -4px 4px #F7F7FC;"
  },
  progresText: {
    backgroundColor: "white",
    bottom: 0,
    justifyContent: "flex-end",

    position: "fixed",
    zIndex: 1,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center"
    }
  },

  root: {
    padding: 0
  }
});
class Wizard extends React.Component {
  static Page = ({children}) => children;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      submitting: false,
      serverSuccess: false,
      page: 0,
      values: props.initialValues,
      serverMessage: {
        detail: "",
        message: "",
        type: false
      }
    };
  }

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = async () => {
    const ids = [];

    if (this.props.selected && this.props.selected.length > 0) {
      this.props.selected.forEach(data => {
        ids.push(data.id);
      });
    }

    this.props.myInvestorProfileSettings({variables: {portfoliosIds: ids, isFinished: true}}).then(data => {
      if (data.data.myInvestorProfileSettings) {
        this.props.history.push("/yatirimci-profilim/?utm_event=add-investor-profile");
      } else {
      }
    });
  };

  handleNext = (validateForm, variablesAll) => {
    const {...variables} = variablesAll;
    if (typeof variables.exitedCompanyCount !== "undefined") {
      variables.exitedCompanyCount = parseInt(variables.exitedCompanyCount);
    } else {
      variables.exitedCompanyCount = null;
    }
    if (typeof variables.portfolioCompanyCount !== "undefined") {
      variables.portfolioCompanyCount = parseInt(variables.portfolioCompanyCount);
    } else {
      variables.portfolioCompanyCount = null;
    }
    if (variables.cityId) {
      variables.cityId = variables.cityId.id;
    }
    if (variables.profilePhoto !== null) {
      if (typeof variables.profilePhoto === "string" && variables.profilePhoto !== "") {
        variables.profilePhoto = null;
      }
    }
    if (variables.backgroundPhoto !== null) {
      if (typeof variables.backgroundPhoto === "string" && variables.backgroundPhoto !== "") {
        variables.backgroundPhoto = null;
      }
    }
    this.props.myInvestorProfileSettings({variables}).then(data => {
      if (data.data.myInvestorProfileSettings) {
        this.setState({page: this.state.page + 1});
      } else {
      }
    });
  };

  handleBack = () => {
    this.props.setSecondPopupOpen(false);
    this.props.setFirstPopupOpen(true);
  };

  render() {
    const {children, classes, setWizardError} = this.props;
    const {values, page} = this.state;
    const activePage = React.Children.toArray(children)[page];
    const pageCount = React.Children.toArray(children).length - 1; // toArray removes null values count doesn't
    const isLastPage = page === pageCount;
    return (
      <Fragment>
        <Formik
          initialValues={values}
          validationSchema={investorProfileSchema[page]}
          enableReinitialize={true}
          validate={this.validate}
          validateOnMount={true}
          onSubmit={this.handleSubmit}
          render={({values, handleSubmit, isSubmitting, handleReset, isValid, validateForm}) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
              {activePage}
              <Grid container xs={12}>
                {isLastPage ? (
                  <Grid container justify="flex-end" className={classes.bottomBar}>
                    <Button
                      onClick={() => this.setState({page: this.state.page - 1})}
                      // disabled={!isValid}
                      variant="outlinedSecondary"
                      style={{fontWeight: "normal", marginRight: "25px", padding: "12px 24px"}}
                    >
                      Geri
                    </Button>
                    <Button
                      variant="containedPrimary"
                      style={{fontWeight: "normal", padding: "12px 24px"}}
                      disabled={!isValid}
                      onClick={() => handleSubmit()}
                    >
                      {" "}
                      Onayla{" "}
                    </Button>
                  </Grid>
                ) : (
                  <Grid container justify="flex-end" className={classes.bottomBar}>
                    {page !== 0 && (
                      <Button
                        onClick={() => this.setState({page: this.state.page - 1})}
                        // disabled={!isValid}
                        variant="outlinedSecondary"
                        style={{fontWeight: "normal", padding: "12px 24px", marginRight: "25px"}}
                      >
                        Geri
                      </Button>
                    )}
                    <Button
                      variant="containedPrimary"
                      disabled={!isValid}
                      style={{fontWeight: "normal", padding: "12px 24px"}}
                      onClick={() => {
                        validateForm(values).then(r => {
                          if (Object.keys(r).length === 0) {
                            this.handleNext(validateForm, values);
                          } else {
                            if (Object.keys(r)[0] === "investmentType") {
                              setWizardError("Yatırımcı Türü boş bırakılamaz!");
                            } else if (Object.keys(r)[0] === "cityId") {
                              setWizardError("Şehir boş bırakılamaz!");
                            } else if (Object.keys(r)[0] === "portfolioCompanyCount") {
                              setWizardError("Portföy Şirket Sayısı boş bırakılamaz!");
                            } else if (Object.keys(r)[0] === "exitedCompanyCount") {
                              setWizardError("Exit yapan şirket sayısı boş bırakılamaz!");
                            } else if (Object.keys(r)[0] === "interestedStage") {
                              setWizardError("İlgilenilen aşamalar boş bırakılamaz!");
                            } else if (Object.keys(r)[0] === "interestedIndustries") {
                              setWizardError("İlgilenilen sektörler boş bırakılamaz!");
                            }
                          }
                        });
                      }}
                    >
                      {" "}
                      İlerle{" "}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, {withTheme: true})(Wizard));
