import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import AdminUsers from './AdminUsers';

const ADD_NOTE = gql`
  mutation adminAddNoteOnUser($id: ID!, $note: String!) {
    adminAddNoteOnUser(id: $id, note: $note) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(ADD_NOTE, {
    name: 'adminAddNoteOnUser',
  }),
)(AdminUsers);
export default JoinWithMutation;