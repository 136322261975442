import { useState, useEffect } from "react";
import { useField } from "formik";

export default function useFastField(propsOrFieldName) {
  const INTERVAL = 50;
  const [field, meta, helpers] = useField(propsOrFieldName);
  const [value, setValue] = useState(field.value);
  const oldValue = field.value
  const { onBlur, onChange } = field;
  const [fieldChangeEvent, setFieldChangeEvent] = useState();

  useEffect(() => {
    setValue(oldValue)
  },
  [oldValue])

  field.value = value;

  field.onChange = e => {
    if (e && e.target) {
      if(e.currentTarget){
        setValue(e.currentTarget.value);
        e.persist();
      }
      else{
        setValue(e.target.value);
      }    
      setFieldChangeEvent(e);
    }
  };

  field.onBlur = e => {
    onChange(e);
    onBlur(e);
  };

  helpers.setValue = value => {
    setValue(value);
  };

  return [field, meta, helpers];
}