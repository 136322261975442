import ApplyForInvestment from './ApplyForInvestment';
import { GET_CREATE_INVESTMENT_SPRINT_DETAIL } from '../../queries';
import GetInvestment from './GetInvestment';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

function DecideComponent(props) {
  const { data, loading } = useQuery(GET_CREATE_INVESTMENT_SPRINT_DETAIL);
  const hasASprint = data?.getCreateInvestmentSprintDetail?.status === 'Active';

  if (loading) return <div>Loading...</div>;

  return hasASprint ? (
    <GetInvestment {...props} />
  ) : (
    <ApplyForInvestment {...props} createSprintData={data?.getCreateInvestmentSprintDetail} />
  );
}
export default DecideComponent;
