import {
  ADMIN_ALL_INVESTMENT_SPRINT_PAGINATED,
  ALL_ACTIVE_INVESTMENT_SPRINT_PAGINATED,
  GET_FEATURED_INVESTMENT_SPRINTS
} from "./investQueries";
import {Link, useLocation} from "react-router-dom";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {DebounceInput} from "react-debounce-input";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import InvestToStartupCard from "../../Components/InvestToStartupCard";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Pagination from "@material-ui/lab/Pagination";
import Popover from "@material-ui/core/Popover";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import SeeMoreArrowSvg from "../../assets/svgs/SeeMoreArrow.svg";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import filterIcon from "../../static/icon/filterIcon.svg";
import get from "lodash/get";
import {makeStyles} from "@material-ui/core/styles";
import newHome from "../../static/icon/new-home-blue.svg";
import newSortingIcon from "../../static/icon/newSortingIcon.svg";
import {resolveStatusText} from "../../helpers";
import {useQuery} from "@apollo/react-hooks";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  root: {
    display: "flex"
  },
  cardGrid: {
    margin: 0,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: 35
    }
  },
  Debounce: {
    width: "100%",
    maxWidth: "180px",
    fontSize: 16,
    height: "35px",
    color: "#8898aa",
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    border: "none",
    outline: "none",
    borderBottom: "1px solid #c8c8ca",
    background: "inherit",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      height: "40px"
    }
  },
  multiSelect: {
    maxHeight: 40,
    minWidth: 116,
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)",
    borderRadius: "6px",
    borderColor: "#fff",
    "& div": {
      background: "none!important"
    }
  },
  ListItemText: {
    "& span": {
      fontSize: "16px",
      color: "#8898aa",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1"
    }
  },
  filterArea: {
    display: "flex",
    alignItems: "flex-end"
  },
  filterPadding: {
    paddingLeft: "30px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      marginTop: 10
    }
  },
  headingPadding: {
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0"
    }
  },
  filter: {
    height: 40,
    padding: "0 15px",
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center"
  },
  CheckText: {
    wordBreak: "break-all",
    marginBottom: 10,
    "& span": {
      fontSize: 15,
      lineHeight: 1.2
    }
  },
  myPaper: {
    padding: 25,
    width: "450px"
  },
  myPaper2: {
    padding: 25
  },
  myLabel: {
    fontSize: 14
  },
  formControl: {
    marginTop: 10
  },
  myCheckbox: {
    padding: "5px 9px"
  },
  subtitle: {
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#425466",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "85%"
    }
  }
}));

export default function Invest(props) {
  const {t} = useTranslation();
  const classes = useStyles();

  //Router
  const matchedUrlSplit = useLocation()?.pathname?.split("/");
  const isFeaturedPage = matchedUrlSplit[2] === "one-cikan-girisimler";
  const params = new URLSearchParams(props.history.location.search);
  //

  const [page, setPage] = React.useState(parseInt(params.get("page")) ? Math.max(parseInt(params.get("page")), 1) : 1);
  const [pageCount, setPageCount] = React.useState(1);
  const [edit, setEdit] = React.useState(false);
  const [sort, setSort] = React.useState([]);
  //this state is named incorrectly, it is actually the investment sprints array
  const [startups, setStartups] = React.useState([]);
  const [order, setOrder] = React.useState("id__DESC");
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState("Statüye Göre");
  const [status, setStatus] = React.useState(["Pending", "Rejected", "Active", "Finished"]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const isAdmin = props.authRole === "Admin" || props.authRole === "SuperAdmin";

  const handleClickSort = event => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const openPopSort = Boolean(anchorElSort);
  const idSort = openPopSort ? "simple-popover" : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;

  //normal page
  const {loading, data, refetch} = useQuery(ALL_ACTIVE_INVESTMENT_SPRINT_PAGINATED, {
    //fetchPolicy: 'network-only',
    variables: {
      orderBy: [order],
      search: search,
      page: page,
      pageSize: 8
    },
    skip: isAdmin
  });
  //admin page
  const {loading: adminLoading, data: adminData} = useQuery(ADMIN_ALL_INVESTMENT_SPRINT_PAGINATED, {
    variables: {
      search: search,
      page: page,
      pageSize: 8,
      status: status
    },
    skip: !isAdmin
  });
  //Featured top 2
  const {data: featuredSprintsTopRowRaw} = useQuery(GET_FEATURED_INVESTMENT_SPRINTS, {
    variables: {
      pageSize: 2
    },
    skip: isFeaturedPage
  });
  const featuredSprintsTopRow = featuredSprintsTopRowRaw?.getFeaturedInvestmentSprintsPaginated?.sprints;

  //Featured Page
  const {loading: featuredSprintsPageLoading, data: featuredSprintsPageData} = useQuery(
    GET_FEATURED_INVESTMENT_SPRINTS,
    {
      variables: {
        orderBy: [order],
        search: search,
        page: page,
        pageSize: 8
      },
      skip: !isFeaturedPage
    }
  );

  const options = [
    {name: "A-Z", value: "startup__title__ASC"},
    {name: "Z-A", value: "startup__title__DESC"},
    {name: "Kapanma tarihi", value: "sprintClosingDate__DESC"}
  ];

  React.useEffect(() => {
    if (isAdmin) {
      if (!adminLoading) {
        if (adminData) {
          setStartups(adminData.adminAllInvestmentSprintsPaginated.sprints);
          setPageCount(adminData.adminAllInvestmentSprintsPaginated.pageCount);
        }
      }
    } else if (isFeaturedPage) {
      if (!featuredSprintsPageLoading) {
        if (featuredSprintsPageData) {
          setStartups(featuredSprintsPageData.getFeaturedInvestmentSprintsPaginated.sprints);
          setPageCount(featuredSprintsPageData.getFeaturedInvestmentSprintsPaginated.pageCount);
        }
      }
    } else {
      if (!loading) {
        if (data) {
          setStartups(data.allActiveInvestmentSprintsPaginated.sprints);
          setPageCount(data.allActiveInvestmentSprintsPaginated.pageCount);
        }
      }
    }
  }, [data, loading, adminData, adminLoading, isFeaturedPage, featuredSprintsPageData, featuredSprintsPageLoading]);

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    props.history.push("/yatirimci-arayanlar?&page=" + value);
    setEdit(true);
    setPage(value);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };

  const handleFilter = event => {
    setPage(1);
    setFilter(event.target.value);
  };

  const handleStatus = event => {
    setPage(1);
    if (status.includes(event.target.value)) {
      let a = status.filter(e => e !== event.target.value);
      setStatus(a);
    } else {
      setStatus([...status, event.target.value]);
    }
  };

  let isInvestor = props.getInvestorProfileStatus;
  const statusOptions = ["Pending", "Rejected", "Active", "Finished"];
  const filterOptions = ["Statüye Göre"];

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
        })}
      >
        <Grid container style={{background: "#f7f7fc", justifyContent: "center", minHeight: "100vh"}}>
          <Grid className={"main-container"} xs={12} style={{marginTop: 140, height: "fit-content", maxWidth: 1320}}>
            <Grid item style={{width: "100%"}}>
              <Grid container justify="space-between" alignItems="center" className={classes.headingPadding}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <img src={newHome} style={{width: 18, marginTop: 3}} alt="bag" />
                  <Typography variant="h4">Yatırımcı Arayanlar</Typography>
                </Breadcrumbs>
                <Grid item className={classes.filterArea}>
                  {!isAdmin && (
                    <Hidden smDown>
                      <DebounceInput
                        class="inputTypeSearch"
                        name="search"
                        value={search}
                        placeholder={t("Ara")}
                        className={classes.Debounce}
                        minLength={2}
                        debounceTimeout={100}
                        onChange={handleSearch}
                      />
                    </Hidden>
                  )}
                  {!isAdmin ? (
                    <>
                      <Grid className={classes.filter} onClick={handleClickSort} item style={{marginLeft: 15}}>
                        <img src={newSortingIcon} style={{width: 15}} alt="filter" />
                        <Hidden smDown>
                          <Typography style={{fontSize: 12, marginLeft: 8}} variant="h6">
                            {t("Sırala")}
                          </Typography>
                          <ExpandMoreIcon style={{color: "#8898aa", marginLeft: 10, fontSize: 20}} />
                        </Hidden>
                      </Grid>
                      <Popover
                        id={idSort}
                        open={openPopSort}
                        anchorEl={anchorElSort}
                        style={{marginTop: 11}}
                        onClose={handleCloseSort}
                        classes={{
                          paper: classes.myPaper2
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{borderRight: "1px solid rgb(0 0 0 / 5%)", maxHeight: 250, overflowY: "auto"}}
                        >
                          <Typography variant="h5">{t("Sırala")}</Typography>
                          <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup onChange={handleSort} value={sort}>
                              {options.map((option, i) => {
                                // Return the element. Also pass key
                                return (
                                  <FormControlLabel
                                    classes={{label: classes.myLabel}}
                                    control={
                                      <Radio
                                        color="primary"
                                        value={option.value}
                                        onClick={() => setOrder(option.value)}
                                      />
                                    }
                                    label={option.name}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Popover>
                    </>
                  ) : (
                    <Hidden smDown>
                      <Grid item className={classes.filterArea}>
                        <Grid className={classes.filter} onClick={handleClick} item>
                          <img src={filterIcon} style={{width: 12}} alt="filter" />
                          <Typography style={{fontSize: 12, marginLeft: 8}} variant="h6">
                            Filtrele
                          </Typography>
                          <ExpandMoreIcon style={{color: "#8898aa", marginLeft: 15}} />
                        </Grid>
                        <Popover
                          id={id}
                          open={openPop}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          style={{marginTop: 11}}
                          classes={{
                            paper: classes.myPaper
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                          }}
                        >
                          <Grid container xs={12}>
                            <Grid item xs={6} style={{borderRight: "1px solid rgb(0 0 0 / 5%)"}}>
                              <Typography variant="h5">Filtrele</Typography>
                              <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup onChange={handleFilter} value={filter}>
                                  {filterOptions.map((value, i) => {
                                    // Return the element. Also pass key
                                    return (
                                      <FormControlLabel
                                        classes={{label: classes.myLabel}}
                                        control={<Radio color="primary" value={value} />}
                                        label={value}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{borderRight: "1px solid rgb(0 0 0 / 5%)"}}>
                              <Typography variant="h5">Filtre Seç</Typography>
                              {filter === "Statüye Göre" && (
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <FormGroup onChange={handleStatus} value={status}>
                                    {statusOptions.map((value, i) => {
                                      return (
                                        <FormControlLabel
                                          className="dropdown"
                                          control={
                                            <Checkbox
                                              classes={{root: classes.myCheckbox}}
                                              color="primary"
                                              onChange={handleStatus}
                                              checked={status.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={resolveStatusText(value)}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                </FormControl>
                              )}
                            </Grid>

                            <Grid container justify="flex-end"></Grid>
                          </Grid>
                        </Popover>
                      </Grid>
                    </Hidden>
                  )}
                </Grid>
              </Grid>
              {!isAdmin && !isInvestor ? (
                <>
                  <Grid container style={{background: "#f7f7fc", justifyContent: "center", minHeight: "100vh"}}>
                    <Grid
                      className={"main-container"}
                      xs={12}
                      style={{marginTop: 140, height: "fit-content", maxWidth: 1320}}
                    >
                      <Grid
                        container
                        spacing={3}
                        style={{display: "flex", alignItems: "center", alignSelf: "center", justifyContent: "center"}}
                      >
                        <Grid item align="center" xs={12}>
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "28px",
                              lineHeight: "38px",
                              textAlign: "center",
                              color: "#27272E"
                            }}
                            variant="caption"
                            /*component="div"*/ color="textSecondary"
                          >
                            {t("Yatırımcı Profili Oluştur")}
                          </Typography>
                        </Grid>
                        <Grid item align="center" xs={12}>
                          <Typography className={classes.subtitle} color="textSecondary">
                            {
                              "Yatırım turundaki girişimleri görebilmeniz için yatırımcı profili oluşturmanız gerekmektedir."
                            }
                          </Typography>
                        </Grid>
                        <Grid item align="center" sm={12}>
                          <Link to={`/yatirimci-profili-yarat`}>
                            <Button
                              variant="containedPrimary"
                              style={{borderRadius: 6, fontSize: 12, fontWeight: "600", padding: "13px 24px"}}
                            >
                              {t("Profil Oluştur")}
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  {(startups || featuredSprintsTopRow) && (
                    <Grid
                      className={classes.cardGrid}
                      style={{marginTop: isFeaturedPage && -15}}
                      container
                      spacing={5}
                      xs={12}
                    >
                      {!isAdmin && !isFeaturedPage && featuredSprintsTopRow && featuredSprintsTopRow.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h5" style={{fontWeight: 700, display: "inline"}}>
                              Öne Çıkanlar
                            </Typography>
                            <Link to={`/yatirimci-arayanlar/one-cikan-girisimler`}>
                              <Typography
                                variant="h5"
                                style={{fontWeight: 500, color: "#425466", marginLeft: 20, display: "inline"}}
                              >
                                Tümünü Gör ({featuredSprintsTopRowRaw?.getFeaturedInvestmentSprintsPaginated?.count}
                                ) <img src={SeeMoreArrowSvg} width={18} height={8} />
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid className={classes.cardGrid} style={{marginTop: -25}} container spacing={5} xs={12}>
                            {featuredSprintsTopRow.slice(0, 2).map(startup => (
                              <InvestToStartupCard
                                isFeatured={true}
                                name={startup.startup.title}
                                logo={startup.startup.logo}
                                industry={startup.startup.industry}
                                city={startup.startup.city}
                                country={startup.startup.country}
                                refetch={refetch}
                                desc={startup.startup.description}
                                oneliner={startup.startup.oneliner}
                                foundationDate={startup.startup.foundationDate}
                                isFavorited={startup.startup.isFavorited}
                                businessModelArray={startup.startup.businessModelArray}
                                edit={edit}
                                setEdit={setEdit}
                                id={startup.startup.id}
                                slug={startup.startup.slug}
                                amountOfInvestmentSought={startup.requiredInvestmentAmount}
                                currentInvestmentAmount={
                                  startup.currentInvestmentAmount + startup.oldAdditionalCommitment
                                }
                                currencySymbol={startup.sprintCurrencySymbol}
                                minTicketSize={startup.minTicketSize}
                                sprintClosingDate={startup.sprintClosingDate}
                                status={startup.status}
                                isAdmin={isAdmin}
                              />
                            ))}
                          </Grid>
                        </>
                      )}
                      {!isAdmin && !isFeaturedPage && (
                        <Grid item xs={12} style={{marginTop: 16, paddingBottom: 16}}>
                          <Typography variant="h5" style={{fontWeight: 700, display: "inline"}}>
                            Tüm Girişimler
                          </Typography>
                        </Grid>
                      )}
                      {startups.map(startup => (
                        <InvestToStartupCard
                          isFeatured={startup?.isFeatured}
                          name={startup.startup.title}
                          logo={startup.startup.logo}
                          industry={startup.startup.industry}
                          city={startup.startup.city}
                          country={startup.startup.country}
                          refetch={refetch}
                          desc={startup.startup.description}
                          oneliner={startup.startup.oneliner}
                          foundationDate={startup.startup.foundationDate}
                          isFavorited={startup.startup.isFavorited}
                          businessModelArray={startup.startup.businessModelArray}
                          edit={edit}
                          setEdit={setEdit}
                          id={startup.startup.id}
                          slug={startup.startup.slug}
                          amountOfInvestmentSought={startup.requiredInvestmentAmount}
                          currentInvestmentAmount={startup.currentInvestmentAmount + startup.oldAdditionalCommitment}
                          currencySymbol={startup.sprintCurrencySymbol}
                          minTicketSize={startup.minTicketSize}
                          sprintClosingDate={startup.sprintClosingDate}
                          status={startup.status}
                          isAdmin={isAdmin}
                        />
                      ))}
                      {startups.length > 0 ? (
                        <Grid container item style={{margin: "30px 0", padding: 0}} justify="center">
                          <Pagination
                            count={pageCount}
                            page={page}
                            onChange={handlePageChange}
                            variant="outlined"
                            color="primary"
                          />
                        </Grid>
                      ) : (
                        !featuredSprintsTopRow?.length > 0 && (
                          <Grid container justify="center">
                            <Typography style={{margin: "25px 0"}} variant="h5">
                              {t("Girişim bulunamadı...")}
                            </Typography>
                          </Grid>
                        )
                      )}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
