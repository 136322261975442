import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import StartupLanding from './StartupLanding';

const CREATE_STARTUP = gql`
  mutation createStartup( $logo:Upload, $title: String!, $website: String!, $stage: String!, $currency: String!, $sector: String, $businessModelArray: [String], $oneliner: String!,) {
    createStartup(logo:$logo, title:$title, website:$website, stage:$stage, currency:$currency, sector:$sector, businessModelArray:$businessModelArray, oneliner:$oneliner)
  }
`;

const INVITE_STARTUP = gql`
mutation investorInviteStartup($title: String!, $website: String!, $contactNameSurname: String!, $contactMail: String!,) {
  investorInviteStartup(title:$title, website:$website, contactNameSurname:$contactNameSurname, contactMail:$contactMail)
}
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_STARTUP, {
    name: 'createStartup',
  }),
  graphql(INVITE_STARTUP, {
    name: 'investorInviteStartup',
  }),
)(StartupLanding);
export default JoinWithMutation;