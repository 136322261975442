import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import order from '../../static/img/order.png';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Popups from '../../Components/Popups';
import get from 'lodash/get';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import theme from '../../theme';
import DatePicker from 'react-datepicker';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink } from 'react-csv';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  blueGrid: {
    padding: '24px',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background:'#fff'
  },
  whiteGrid: {
    background:'#ffffff',
    borderRadius:'4px',
    marginBottom:25,
  },
  yatırımButton: {
    fontWeight: 'normal',
    padding: '11.5px 47px',
    fontSize: 12,
    marginTop:16
  },
  threeGrid: {
    padding:'30px 0',
    borderRadius:'16px',
    marginTop:20,
    minHeight:120,
  },
  gridLink: {
    display:'flex',
    alignItems:'center',
    marginTop:25,
    padding:0
  },
  canvasContainer: {
    height: '60vh',
    width: '100%'
  },
  exportButton:{
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    marginLeft:10,
    border:'1px solid #003CA5',
    borderRadius:3,
    display:'flex',
    alignItems:'center',
    color:'#003CA5',
    "&:hover" : {
      backgroundColor : "#003CA5",
      color:'#fff',
      borderColor:'#003CA5',
    }
  },
  companyCard:{
    padding:16,
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    borderRadius:16,
    marginBottom:24,
    "&:last-child" : {
      marginBottom:0,
    }
  },
  gridImage: { 
    backgroundImage: "url(" + order + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: '100%',
  },
  rightWhite: {
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    padding: 25,
    height: 'fit-content',
  },
  companyPhoto: {
    width: '60px',
    height: '60px',
    borderRadius: '4px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #eaedf3',
    marginRight:15
  },
  mainContainer:{
    width: 100,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    padding:'0px 20px 0 20px',
    [theme.breakpoints.down('sm')]: {
        padding:'0'
    },
  },
  center: {
    [theme.breakpoints.down('sm')]: {
        justifyContent:'center',
        padding:'8px 20px!important;'
    },
    height:'min-content'
  },
  noMargin:{
    [theme.breakpoints.down('xs')]: {
        margin:0
    },
  }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#FAFAFB',
      color: '#8492A6',
      fontSize:11,
      padding: '16px 24px',
    },
    body: {
      fontSize: 14,
      borderBottom:'none',
      padding: '12px 0px 12px 24px',
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
    },
  }))(TableRow);


const footer = (tooltipItems) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem) {
    sum += tooltipItem.parsed.y;
  });
  return 'Sum: ' + sum;
};
export default function DashboardAdmin(props){
    const classes = useStyles();
    const [barDataStartupTotal, setBarDataStartupTotal] = React.useState();
    const [barDataUserTotal, setBarDataUserTotal] = React.useState();
    const [barDataDate, setBarDataDate] = React.useState();
    const [baruserShareholdersDate, setBaruserShareholdersDate] = React.useState();
    const [baruserCorinvestorsDate, setBaruserCorinvestorsDate] = React.useState();
    const [baruserIndinvestorsDate, setBaruserIndinvestorsDate] = React.useState();
    const [baruserEmployeesDate, setBaruserEmployeesDate] = React.useState();
    const [baruserOthersDate, setBaruserOthersDate] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [hide, setHide] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [dashboardDataInfo, setDashboardDataInfo] = React.useState(null);
    const [usersCount, setUsersCount] = React.useState();
    const [chartCsvData, setChartCsvData] = React.useState();
    const [chartStartDate, setChartStartDate] = React.useState(moment().subtract(1, 'months').toDate());
    const [chartEndDate, setChartEndDate] = React.useState(new Date());

    const startups = [
        {title:'Pingaing', owner:'Samet Özkale', buyRequest:'+$2.500', sellRequest:'+$2.500'},
        {title:'Pingaing', owner:'Samet Özkale', buyRequest:'+$2.500', sellRequest:'+$2.500'},
        {title:'Pingaing', owner:'Samet Özkale', buyRequest:'+$2.500', sellRequest:'+$2.500'},
        {title:'Pingaing', owner:'Samet Özkale', buyRequest:'+$2.500', sellRequest:'+$2.500'},
    ];

    function getUserFullName(first, last) {
        let f = first ? first : ''
        let l = last ? ' '+ last : ''
        return f + l
      }

    const ALL_USERS = gql`
    query adminUsersPaginate( $page: Int, $pageSize: Int) {
        adminUsersPaginate(pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            users{
                id
                firstName
                lastName
                profilePhoto
                socialLinkedIn
                phoneNumber
                email
                country{id, title}
                city{id, title}
                isAdmin
                onBoardInfo{
                  isShareHolder
                  isInvestor
                  investorType
                  companyName
                }
            }
        }
    }
    `;
      const { loading, data, refetch} = useQuery(ALL_USERS, {
        fetchPolicy:'network-only',
        variables: {page:1, pageSize:4},
    });

    React.useEffect(() => {
        if(!loading){
            if(data){
                setUsers(data.adminUsersPaginate.users);
                setUsersCount(data.adminUsersPaginate.count)
            }
        }
    },
  [data, loading])
    const DASHBOARD_DATAS = gql`
    query dashboardAdminDatas {
        dashboardAdminDatas{
          usersTotal
          usersShareholder
          usersCorInvestor
          usersIndInvestor
          usersEmployee
          usersOther
          weeklyUsersCount
          startupCount
          startupActiveCount
          startupDraftCount
          startupKPIUsedCount
          startupReportUsedCount
        }
    }
    `;
      const { loading: datas_loading, data: datas_data} = useQuery(DASHBOARD_DATAS, {
        fetchPolicy:'network-only',
    });

    React.useEffect(() => {
        if(!datas_loading){
            if(datas_data){
                setDashboardDataInfo(datas_data.dashboardAdminDatas);
            }
        }
    },
  [datas_data, datas_loading])
    const DASHBOARD_BAR_DATAS = gql`
    query dashboardChartDatas( $startDate: String!, $endDate: String!) {
        dashboardChartDatas(startDate:$startDate, endDate:$endDate) {
            startDate
            endDate
            data {
                date
                startupTotal
                userTotal
                userShareholders
                userCorinvestors
                userIndinvestors
                userEmployees
                userOthers
            }
        }
    }
    `;
      const { loading: datas_chart_loading, data: datas_chart_data} = useQuery(DASHBOARD_BAR_DATAS, {
        fetchPolicy:'network-only',
        variables:{startDate: chartStartDate, endDate: chartEndDate}
    });

    React.useEffect(() => {
        if(!datas_chart_loading){
            if(datas_chart_data && datas_chart_data.dashboardChartDatas.data){
              let csv = [];
              let e = [];
              let f = [];
              let g = [];
              let h = [];
              let j = [];
              let k = [];
              let l = [];
              let i = [];
              datas_chart_data.dashboardChartDatas.data.forEach((bar, index) => {
                let temp = {
                  date: moment(parseInt(bar.date)).format("YYYY/MM/DD"),
                  startupTotal: bar.startupTotal ? bar.startupTotal : 0,
                  userTotal: bar.userTotal ? bar.userTotal : 0,
                  userShareholders: bar.userShareholders ? bar.userShareholders : 0,
                  userCorinvestors: bar.userCorinvestors ? bar.userCorinvestors : 0,
                  userIndinvestors: bar.userIndinvestors ? bar.userIndinvestors : 0,
                  userEmployees: bar.userEmployees ? bar.userEmployees : 0,
                  userOthers: bar.userOthers ? bar.userOthers : 0,
                }
                e.push(temp.date);
                f.push(temp.startupTotal);
                g.push(temp.userTotal);
                h.push(temp.userShareholders);
                j.push(temp.userCorinvestors);
                k.push(temp.userIndinvestors);
                l.push(temp.userEmployees);
                i.push(temp.userOthers);
                csv.push(temp)
              });
              setBarDataDate(e);
              setBarDataStartupTotal(f);
              setBarDataUserTotal(g);
              setBaruserShareholdersDate(h);
              setBaruserCorinvestorsDate(j);
              setBaruserIndinvestorsDate(k);
              setBaruserEmployeesDate(l);
              setBaruserOthersDate(i);
              setChartCsvData(csv)
            }
        }
    },
  [datas_chart_data, datas_chart_loading])

  function printDocument() {
    // const text = document.getElementById('dateGrid');
    // text.style.display = 'block';
    const doc = new jsPDF("p", "mm", "a4");
    const width = doc.internal.pageSize.getWidth() * 0.94;
    const height = doc.internal.pageSize.getHeight() / 3;
    const input = document.getElementById('divToPrint');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.text("Günlük Istatistikler | "+ chartStartDate.toISOString().split('T')[0] + " : "+
        chartEndDate.toISOString().split('T')[0],
        10, doc.internal.pageSize.getHeight() * 0.05);
      pdf.addImage(imgData, 'JPEG', doc.internal.pageSize.getWidth() * 0.03,
        doc.internal.pageSize.getHeight() * 0.07, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save((new Date()).toISOString() + "_sb_data.pdf");
    });
    // text.style.display = 'none';
  };
    return (
      <>
        <Popups />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={classes.mainContainer}
              xs={12}
              style={{ marginTop: 140, height: 'min-content', maxWidth: 1350 }}
            >
              <Grid container xs={12} style={{
                marginBottom: "30px",
                background: '#ffffff',
                borderRadius: '16px',
                boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
                filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
                padding: 25,
                height: 'fit-content'
              }}>
                <Grid container justify="space-between" >
                  <Grid xs={12} sm={6}>
                    <Typography>Günlük İstatistikler</Typography>
                  </Grid>
                  <Grid item style={{ display: 'flex' }} xs={12} sm={6}>
                    <Grid item style={{ display: 'flex' }} >
                      <Typography style={{fontSize:14}}>Start Date: </Typography>
                      <DatePicker
                        style={{ zIndex: 999 }}
                        {...props}
                        selected={chartStartDate}
                        maxDate={new Date()}
                        locale="tr"
                        customInput={
                          <input
                            autocomplete="off"
                            style={{
                              width: '100%',
                              padding: '13px',
                              borderRadius: 3,
                              border: '1px solid rgb(221 221 221 / 56%)',
                              boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
                              // marginTop: 10
                            }}
                            type="text"
                            placeholder={'mm'} />
                        }
                        dateFormat="dd/MM/yyyy"
                        onChange={val => {
                          setChartStartDate(val)
                        }}
                      />
                    </Grid>
                    <Grid item style={{ display: 'flex', paddingLeft:'10px' }} >
                      <Typography style={{fontSize:14}}>End Date: </Typography>
                      <DatePicker
                        style={{ zIndex: 999 }}
                        {...props}
                        selected={chartEndDate}
                        maxDate={new Date()}
                        minDate={chartStartDate}
                        locale="tr"
                        customInput={
                          <input
                            autocomplete="off"
                            style={{
                              width: '100%',
                              padding: '13px',
                              borderRadius: 3,
                              border: '1px solid rgb(221 221 221 / 56%)',
                              boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
                              // marginTop: 10
                            }}
                            type="text"
                            placeholder={'mm'} />
                        }
                        dateFormat="dd/MM/yyyy"
                        onChange={val => {
                          setChartEndDate(val)
                        }}
                      />
                    </Grid>
                    {chartCsvData &&
                    <CSVLink
                      data={chartCsvData}
                      filename={(new Date()).toISOString() + '_sb_data.csv'}
                      target="_blank"
                    >
                      <GetAppIcon color="primary"  />
                    </CSVLink>
                    }
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => printDocument()} >
                      <PictureAsPdfIcon />
                    </IconButton>

                  </Grid>
                </Grid>
                <Grid id="divToPrint" className={classes.canvasContainer}>
                  <Line
                        type="line"
                        data={{
                          labels: barDataDate,
                          datasets: [
                            {
                              label: 'Yeni Startup',
                              data: barDataStartupTotal,
                              fill: false,
                              backgroundColor: 'rgb(255, 99, 132)',
                              borderColor: 'rgba(255, 99, 132, 0.9)',
                              pointStyle: 'triangle',
                              pointRadius: 5,
                            },
                            {
                              label: 'Yeni Üye',
                              data: barDataUserTotal,
                              fill: false,
                              backgroundColor: 'rgb(54, 162, 235)',
                              borderColor: 'rgba(54, 162, 235, 0.9)',
                              pointStyle: 'rect',
                              pointRadius: 5,
                            },
                            {
                              label: 'Yeni Girişimci',
                              data: baruserShareholdersDate,
                              fill: false,
                              backgroundColor: 'rgb(44,160,26)',
                              borderColor: 'rgba(44,160,26, 0.9)',
                              // pointStyle: 'crossRot',
                              pointRadius: 5,
                            },
                            {
                              label: 'Yeni Kurumsal Yatırımcı',
                              data: baruserCorinvestorsDate,
                              fill: false,
                              backgroundColor: 'rgb(255,123,0)',
                              borderColor: 'rgba(255,123,0, 0.9)',
                              pointStyle: 'star',
                              pointRadius: 5,
                            },
                            {
                              label: 'Yeni Bireysel Yatırımcı',
                              data: baruserIndinvestorsDate,
                              fill: false,
                              backgroundColor: 'rgb(0,177,179)',
                              borderColor: 'rgba(0,177,179, 0.9)',
                              pointStyle: 'line',
                              pointRadius: 5,
                            },
                            {
                              label: 'Yeni Çalışan',
                              data: baruserEmployeesDate,
                              fill: false,
                              backgroundColor: 'rgb(255,222,99)',
                              borderColor: 'rgba(255,222,99, 0.9)',
                              pointStyle: 'dash',
                              pointRadius: 5,
                            },
                            {
                              label: 'Yeni Diğer Üyeler',
                              data: baruserOthersDate,
                              fill: false,
                              backgroundColor: 'rgb(144,99,255)',
                              borderColor: 'rgba(144,99,255, 0.9)',
                              pointStyle: 'rectRot',
                              pointRadius: 5,
                            },
                          ],
                        }}
                        options={{

                          cornerRadius: 20,
                          layout: { padding: 0 },
                          legend: { display: true },
                          maintainAspectRatio: false,
                          animation:{duration: 1400},
                          scales: {
                            xAxes: [
                              {
                                barThickness: 12,
                                maxBarThickness: 10,
                                barPercentage: 0.5,
                                categoryPercentage: 0.5,
                                ticks: {
                                  fontColor: theme.palette.text.secondary
                                },
                                gridLines: {
                                  display: false,
                                  drawBorder: false
                                }
                              }
                            ],
                            yAxes: [
                              {
                                ticks: {
                                  fontColor: theme.palette.text.secondary,
                                  beginAtZero: true,
                                  min: 0
                                },
                                gridLines: {
                                  borderDash: [2],
                                  borderDashOffset: [2],
                                  color: theme.palette.divider,
                                  drawBorder: false,
                                  zeroLineBorderDash: [2],
                                  zeroLineBorderDashOffset: [2],
                                  zeroLineColor: theme.palette.divider
                                }
                              }
                            ]
                          },
                          tooltips: {
                            backgroundColor: theme.palette.background.default,
                            bodyFontColor: theme.palette.text.secondary,
                            borderColor: theme.palette.divider,
                            borderWidth: 1,
                            enabled: true,
                            footerFontColor: theme.palette.text.secondary,
                            intersect: false,
                            mode: 'index',
                            position: 'nearest',
                            titleFontColor: theme.palette.text.primary
                          }
                        }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} className={classes.noMargin} spacing={3}>
                <Grid
                  style={{ height: 'min-content', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                  item
                  sm={12}
                  md={8}
                >
                  <Grid container xs={12} spacing={2} style={{ height: 'min-content', margin: 0, padding: '0 4px' }}>
                    {dashboardDataInfo ? (
                      <>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#28d09a', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.usersTotal}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Toplam
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Üye
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{background: '#003CA5', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.usersIndInvestor}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Girişimci
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Üye
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#ffba00', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.usersIndInvestor}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Bireysel
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Yatırımcı
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#2db9ff', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.usersCorInvestor}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Kurumsal
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Yatırımcı
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#ff5757', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.usersEmployee}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Çalışan
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Üye
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#c440ff', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.usersOther}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Diğer
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Üye
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  <Grid container xs={12} spacing={2} style={{ height: 'min-content', margin: 0, padding: '0 4px' }}>
                    {dashboardDataInfo ? (
                      <>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#815bfa', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.startupCount}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Platforma
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Eklenen
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{background: '#36731f', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.startupDraftCount}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Draftta 
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Bekleyen
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#5ba1eb', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.startupActiveCount}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Listelenen
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Girişim
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#5aae97', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.weeklyUsersCount}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Haftalık
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Kullanıcı
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#f7cd00', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.startupKPIUsedCount}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                KPI
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Kullanan
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={6} lg={2} style={{ height: 'min-content' }}>
                          <Grid className={classes.threeGrid} style={{ background: '#ad6833', marginTop: -8 }} item sm={12}>
                            <Grid container alignItems="center" direction="column" justify="center">
                              <Typography style={{ color: '#ffffff' }} variant="h4">
                                {dashboardDataInfo.startupReportUsedCount}
                              </Typography>
                              <Typography style={{ color: '#ffffff', marginTop: 10 }} variant="body2">
                                Rapor
                              </Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }} variant="h5">
                                Yollayan
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  <Hidden xsDown>
                    <Grid style={{ padding: '0 12px', marginTop: 20 }} xs={12}>
                      <Grid item className={classes.whiteCard} style={{ padding: 15, background: '#fff' }}>
                        <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    #{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    İsim Soyisim{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Telefon{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Email{' '}
                                  </Typography>
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {users.map((row) => (
                                <StyledTableRow key={row.name}>
                                  <StyledTableCell style={{ padding: '12px 0px 12px 30px' }} component="th" scope="row">
                                    {row.profilePhoto ? (
                                      <img
                                        src={row.profilePhoto}
                                        alt="logo"
                                        style={{
                                          width: 35,
                                          height: 35,
                                          borderRadius: '50%',
                                          border: 'solid 1px #eaedf3',
                                        }}
                                      />
                                    ) : (
                                      <Grid
                                      style={{
                                        width: 35,
                                        height: 35,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#003CA5',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {row.firstName && row.firstName.charAt(0)}
                                      </Typography>
                                    </Grid>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                                      {getUserFullName(row.firstName, row.lastName)}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography variant="body2">{row.phoneNumber ? row.phoneNumber : '-'}</Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography variant="body2">{row.email ? row.email : '-'}</Typography>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Grid container justify="flex-end" alignItems="center" style={{ marginTop: 15 }}>
                          <Link style={{ padding: 0, display: 'flex', alignItems: 'center' }} to="/admin/kullanicilar">
                            <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14 }}>
                              Tümünü Gör
                            </Typography>
                            <ArrowRightAltIcon style={{ color: '#003CA5' }} />
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid className={classes.center} style={{ width: '100%' }} item sm={12} md={4}>
                  <Grid className={classes.rightWhite} xs={12}>
                    <Grid container alignItems="center" justify="space-between" style={{ marginBottom: 24 }}>
                      <Typography variant="h5" style={{ marginRight: 5}}>
                        Son Alım İstekleri({4})
                      </Typography>
                      <Link style={{ padding: 0, display: 'flex', alignItems: 'center' }} to="/girisimler">
                        <Typography variant="h6" style={{ color: '#003CA5' }}>
                          Tümünü Gör
                        </Typography>
                      </Link>
                    </Grid>
                    {startups.map((startup) => (
                      <>
                        <Grid container alignItems="center" justify="space-between" className={classes.companyCard}>
                          <Grid item>
                            <Typography variant="h5" style={{ wordBreak: 'break-all', fontSize: 16 }} align="left">
                              {startup.title}
                            </Typography>
                            <Typography variant="body2" align="left">
                              {startup.owner}
                            </Typography>
                          </Grid>
                          <Grid item container alignItems="center" style={{ width: 'fit-content' }}>
                            <Typography
                              variant="h5"
                              style={{ wordBreak: 'break-all', fontSize: 16, color: '#003CA5' }}
                              align="left"
                            >
                              {startup.buyRequest}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    <Grid container justify="center" alignItems="center">
                    </Grid>
                  </Grid>
                  <Grid className={classes.rightWhite} style={{ margin: '30px 0' }} xs={12}>
                    <Grid container alignItems="center" justify="space-between" style={{ marginBottom: 24 }}>
                      <Typography variant="h5" style={{ marginRight: 5}}>
                        Son Satış İstekleri({4})
                      </Typography>
                      <Link style={{ padding: 0, display: 'flex', alignItems: 'center' }} to="/girisimler">
                        <Typography variant="h6" style={{ color: '#003CA5' }}>
                          Tümünü Gör
                        </Typography>
                      </Link>
                    </Grid>
                    {startups.map((startup) => (
                      <>
                        <Grid container alignItems="center" justify="space-between" className={classes.companyCard}>
                          <Grid item>
                            <Typography variant="h5" style={{ wordBreak: 'break-all', fontSize: 16 }} align="left">
                              {startup.title}
                            </Typography>
                            <Typography variant="body2" align="left">
                              {startup.owner}
                            </Typography>
                          </Grid>
                          <Grid item container alignItems="center" style={{ width: 'fit-content' }}>
                            <Typography
                              variant="h5"
                              style={{ wordBreak: 'break-all', fontSize: 16, color: '#ff5757' }}
                              align="left"
                            >
                              {startup.sellRequest}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </>
    );
}