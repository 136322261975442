
import { useField } from 'formik';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
    multiSelect: {
        maxHeight: '50px',
        '& div': {
          background: 'none!important',
        },
    },
}));

export default function FormikDropdown ({ label, ...props }) {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
        <Grid style={{ minHeight: 75 }}>
          <FormControl placeholder="Lütfen Seçiniz" style={{ width: '100%', marginTop: '-9px' }}>
          <InputLabel id="demo-simple-select-helper-label" style={{color:'rgb(119 119 119 / 55%)',fontSize:16}}>Lütfen Seçiniz</InputLabel>
            <Select
              hiddenLabel="true"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              {...field}
              {...props}
              className={classes.multiSelect}
              input={<Input />}
            >
              {props.options.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemText style={{ textAlign: 'left' }} primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
    }