import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import 'moment/locale/tr';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import FormikTextField from '../../Components/FormikShortTextField';
import { signupSchema } from '../../yupSchemas';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import tr from 'date-fns/locale/tr';
import { getCurrentPeriod } from '../../helpers';
import ReactPhoneInput from 'react-phone-input-2';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormikNewDropdown from '../../Components/FormikDropdown';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';

registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  multiSelect2: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  Debounce: {
    width : '100%',
    fontSize:16,
    height:'50px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    outline:'none',
    border:'1px solid #c8c8ca',
    padding:10,
    borderRadius:4,
    background:'inherit',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  textColorP:{
    color:'#003CA5',
    borderColor:'#003CA5',
    borderRadius:4,
    marginRight:20,
    '&$selected': {
      color:'#ffffff!important',
      background:'#003ca5'
    }
  },
  cardGrid: {
    marginTop: 35,
  },
  myLabel: {
    fontSize: 14,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
  tabPaper: {
    marginTop: 50,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
    },
  },
  borderRight: {
    [theme.breakpoints.up('md')]: {
      borderRight:'solid 1px #c3d1e0'
    },
  },
  checkBackground: {
    '&:hover': { backgroundColor: '#0055ff1f' },
  },
  kpiCat: {
    padding: '10px 20px',
    cursor: 'pointer',
    "&:hover" : {
      backgroundColor:'rgb(244, 249, 255)'
    }
  },
  tabRoot:{
    minHeight:40,
    padding:'9px 12px',
    border:'1px solid',
    fontWeight:500,
    '&$selected': {
      color:'#fff',
      background:'#003ca5'
    }
  },
  selected: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  search:{
    [theme.breakpoints.up('md')]: {
      padding:'0 50px'
    },
  },
  searchBox: {
    width:'100%',
    background:'#ffffff',
    borderRadius:2,
    marginTop:5,
    boxShadow:'1px 1px 2px 1px rgba(201,201,201,1)',
    maxHeight: 130,
    overflowY:'auto',
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop:30,
    '& textarea': {
        padding: 16,
        paddingRight:90,
        fontSize:13,
        lineHeight:1
      },
  },
  kpiImg: {
    objectFit:'contain',
    width:'100%',
    maxWidth:300
  },
  table: {
    boxShadow: 'none',
  },
  kpiImage:{
    marginBottom:20,
    minHeight: '270px',
    objectFit:'contain',
    width:'100%'
  },
  mobileMargin:{
    [theme.breakpoints.down('xs')]: {
      marginBottom:20
    }
  },
  wrap:{
    flexDirection:'row',
  }
}));

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
        autocomplete="off"
        style={{
          width:'100%',
          padding:'13px',
          borderRadius: 3,
          border:'1px solid rgb(221 221 221 / 56%)',
          boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
          marginTop:10
        }}
        type="text"
        placeholder={'mm'} />
      }
      maxDate={getCurrentPeriod(props.dataFrequency).date}
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};


const FormikCheckBox = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <FormControlLabel style={{ marginRight: 0 }} control={<Checkbox {...field} checked={field.value} color="primary" />} />
      {label}
    </>
  );
};
export default function ListKPIGA(props){
  const classes = useStyles();
  const { t } = useTranslation();
  const [kpiList, setKpiList] = React.useState([]);
  const [gaDataList, setGADataList] = React.useState([]);
  const [gaViewsList, setGAViewsList] = React.useState([]);
  const [gaPropertyList, setGAPropertyList] = React.useState([]);
  const [error, setError] = React.useState('');
  const [serverInfo, setServerInfo] = React.useState(false);

  const KPI_LIST = gql`
    query getStartupKPILists {
      getStartupKPILists {
        id
        title
      }
    }
  `;

  const { loading, data, refetch: refetchKPI } = useQuery(KPI_LIST, {
    fetchPolicy: 'network-only',
    variables: {},
  });
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setKpiList(data.getStartupKPILists);
      }
    }
  }, [data, loading]);

  const GA_VIEW_LIST = gql`
    query getUserGoogleAnalyticsViews {
        getUserGoogleAnalyticsViews {
        id
        name
        property{ 
            id 
            name 
            view{
                id 
                name
            }
        }
      }
    }
  `;

  const { loading: loadingViewList, data: dataViewList } = useQuery(GA_VIEW_LIST, {
    fetchPolicy: 'network-only',
    variables: {},
  });
  React.useEffect(() => {
    if (!loadingViewList) {
      if (dataViewList) {
        setGADataList(dataViewList.getUserGoogleAnalyticsViews);

      }
    }
  }, [dataViewList, loadingViewList]);


  function getOptionsForProperty(accountId) {
    for (let x of gaDataList){
      if (x.name === accountId){
        setGAPropertyList(x.property)
        return x.property.map(x => x.name)
      }
    }
    return []
  }
  function getOptionsForViews(propertyId) {
    for (let x of gaPropertyList){
      if (x.name === propertyId){
        setGAViewsList(x.view)
        return x.view.map(x => x.name)
      }
    }
    return []
  }
  const handleSubmit = async variablesAll => {
    const { ...variables } = variablesAll;
    variables.accountId = null
    variables.propertyId = null
    let viewId
    let listId
    for (let x of gaViewsList){
      if (x.name === variables.viewId){
        viewId = x.id
      }
    }
    for (let x of kpiList){
      if (x.title === variables.listId){
        listId = x.id
      }
    }
    variables.viewId = viewId
    variables.listId = listId

    props.kpiGoogleAnalytics({ variables })
      .then(data => {
        if (data.data.kpiGoogleAnalytics) {
          props.history.push(`/verilerim`);
        }
      }).catch(async (error) => {
        if (error.message === "GraphQL error: No refresh token is set."){
          setError("Google Oturum süresi dolmuş, tekrar login olmaya yönlendiriliyorsunuz.")
          setServerInfo(true)
          await new Promise(r => setTimeout(r, 6000));
          window.location.assign("/auth/google/?listId="+listId);
        }else{
          setError(error.message)
          setServerInfo(true)
        }
    });
  }
  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        {!loading && (

          <Grid xs={12} alignItems="center" className={classes.loginGrid} container item>
              <Grid className={classes.mainGrid} item sm={12} xs={11}>
                <Formik
                  initialValues={{
                    accountId: null,
                    propertyId: null,
                    viewId: null,
                    listId: '',
                  }}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {(props) => (
                    <Form>

                      <Grid container xs={12} item spacing={3} style={{ margin: '100px 0 0 0' }}>
                        <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                          <Typography variant="h6">
                            Account
                            <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                          </Typography>
                          <Field
                            component={FormikNewDropdown}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="accountId"
                            options={gaDataList.map(x => x.name)}
                          ></Field>
                        </Grid>
                        {props.values.accountId ? (
                          <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                            <Typography variant="h6">
                              Property
                              <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                            </Typography>
                            <Field
                              component={FormikNewDropdown}
                              textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                              name="propertyId"
                              options={getOptionsForProperty(props.values.accountId)}
                            ></Field>
                          </Grid>
                        ) : null}
                        {props.values.propertyId ? (
                          <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                            <Typography variant="h6">
                              View
                              <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                            </Typography>
                            <Field
                              component={FormikNewDropdown}
                              textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                              name="viewId"
                              options={getOptionsForViews(props.values.propertyId)}
                            ></Field>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid container xs={12} item spacing={3} style={{ margin: '30px 0 0 0' }}>
                        <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                          <Typography variant="h6">
                            List
                            <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                          </Typography>
                          <Field
                            component={FormikNewDropdown}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="listId"
                            options={kpiList.map(x => x.title)}
                          ></Field>
                        </Grid>
                      </Grid>
                      <Button
                        variant="containedPrimary"
                        className={classes.editButton}
                        style={{ fontWeight: 'normal' }}
                        disabled={!props.isValid}
                        onClick={() => handleSubmit(props.values)}
                      >
                        {' '}
                        Kaydet{' '}
                      </Button>

                    </Form>
                  )}
                </Formik>
                <Snackbar autoHideDuration={6000} onClose={() => setServerInfo(false)} open={serverInfo}>
                  <Alert severity="error">{error}</Alert>
                </Snackbar>
              </Grid>

          </Grid>
        )}
      </main>
    </>
  );
}