import NewHeader from './NewHeader'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const CREATE_STARTUP = gql`
  mutation createStartup {
    createStartup
  }
`;

const VERIFY_MAIL = gql`
  mutation resendVerifyMail {
    resendVerifyMail
  }
`;

const CHANGE_ROLE = gql`
  mutation changeRole(
    $role: String!
    ) 
    {
    changeRole(
      role: $role
    )
  }
`;

const JoinWithMutation = compose(
  graphql(CREATE_STARTUP, {
    name: 'createStartup',
  }),
  graphql(CHANGE_ROLE, {
    name: 'changeRole',
  }),
  graphql(VERIFY_MAIL, {
    name: 'resendVerifyMail',
  }),
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
)(NewHeader);
export default JoinWithMutation;