import gql from "graphql-tag";
import {FRAGMENT_MESSAGES} from "./fragments";
export const MESSAGES_SUBSCRIPTION = gql`
  subscription newMessages {
    Message {
      ...MessageFields
    }
  }
  ${FRAGMENT_MESSAGES.message}
`;
