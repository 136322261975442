import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import AdminInvestors from './AdminInvestors';

const ACCESITY = gql`
  mutation adminHandleInvestorProfileAccessity($investorId: ID!, $status: String!) {
    adminHandleInvestorProfileAccessity(investorId: $investorId, status: $status) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(ACCESITY, {
    name: 'adminHandleInvestorProfileAccessity',
  }),
)(AdminInvestors);
export default JoinWithMutation;