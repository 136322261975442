import React, { useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import { FormControl, FormControlLabel, FormGroup, Grid, Input, MenuItem, Popover, Radio, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from '@material-ui/core/RadioGroup';
import filterIcon from '../static/icon/filterIcon.svg';
import Select from "@material-ui/core/Select";


const useStyles = makeStyles((theme)=>({
    root: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        }, 
    },
    filterArea: {
        display:'flex',
        alignItems:'flex-end',
    },
    filter: {
        height:40,
        padding: '0 15px',
        background: '#FFFFFF',
        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
        borderRadius: '6px',
        display:'flex',
        alignItems:'center',
        cursor:'pointer',
        justifyContent:'center',
        [theme.breakpoints.up('lg')]: {
            marginRight:'20px',
        },
    },
    myPaper: {
        padding:25,
        width:'650px'
    },
    myLabel: {
        fontSize:14
    },
    myCheckbox:{
        padding:'5px 9px'
    },
}));


/**
 * 
 * @param {object} props 
 * @param {string[]} props.filterOptions
 * @param {object} props.filterValues - the keys should be filter options and the values should be a list of corresponding filter values
 * @param {string[]} props.filterValues.*
 * @param {object} props.filterLabels - the keys should be filter options and the values should be a list of corresponding filter labels
 * @param {string[]} props.filterLabels.*
 * @param {(value: object)=>any} props.onChange
 * @returns 
 */
export default function FilterButton(props){
    const classes = useStyles(props);

    const [openPop, setOpenPop] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState(props.initialFilter);
    const [values, setValues] = useState(props.intialValues);

   
    const id = openPop ? 'simple-popover' : undefined;

    const filterOptions = props.filterOptions;
    const filterValues = props.filterValues;


    const handlePop = (event)=>{
        setOpenPop(true);
        setAnchorEl(event.currentTarget);
    };

    const handleFilter = (event)=>{
        setFilter(event.target.value);
    };

    const handleValueChange = (event)=>{
        if(values[filter]){
            if (values[filter].includes(event.target.value)){
                const a = values[filter].filter(e => e !== event.target.value);
                const newValue = {...values, [filter]: a};
                setValues(newValue);
                props.onChange(newValue);
            }
            else{
                const newValue = {...values, [filter]: [...values[filter], event.target.value]};
                setValues(newValue);
                props.onChange(newValue);
            }
        }
    }


    return (
        <Grid item className={classes.filterArea}>
            <Grid className={classes.filter} onClick={handlePop} item>
                <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                    Filtrele
                </Typography>
                <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
            </Grid>
            
            <Popover
            id={id}
            open={openPop}
            anchorEl={anchorEl}
            onClose={()=>setOpenPop(false)}
            style={{marginTop:11}}
            classes={{
                paper: classes.myPaper,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            >
                
                <Grid container xs={12}>
                    <Grid item xs={4} style={{ borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                        <Typography variant="h5">Filtrele</Typography>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup onChange={handleFilter} value={filter}>
                                {filterOptions.map((value, i) => {
                                    // Return the element. Also pass key
                                    return (
                                        <FormControlLabel
                                            classes={{ label: classes.myLabel }}
                                            control={<Radio color="primary" value={value} />}
                                            label={value}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '30px', borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                        <Typography variant="h5">Filtre Seç</Typography>
                        {
                            filterValues && props.filterLabels && filterValues[filter] && props.filterLabels[filter] &&
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup onChange={handleValueChange} value={values[filter]}>
                                    {
                                        filterValues[filter].map( (value, i) => (
                                            <FormControlLabel
                                            className="dropdown"
                                            control={
                                                <Checkbox
                                                classes={{ root: classes.myCheckbox }}
                                                color="primary"
                                                checked={values[filter].includes(value)}
                                                name="filter4-1"
                                                value={value}
                                                />
                                            }
                                            label={props.filterLabels[filter][i]}
                                            />
                                        ))
                                    }
                                </FormGroup>
                            </FormControl>
                        }                   
                    </Grid>
                    
                    <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                        <Typography style={{ marginBottom: 5 }} variant="h5">
                            Seçimleriniz
                        </Typography>
                    {values[filter] && (
                        <>
                        {values[filter].map((value, i) => {
                            return (
                            <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                {value}
                            </Typography>
                            );
                        })}
                        </>
                    )}
                    </Grid>
                    <Grid container justify="flex-end"></Grid>
                </Grid> 
            </Popover>
        </Grid>
    );
}