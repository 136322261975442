import Followers from './Followers'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const CREATE_PERSON = gql`
  mutation startupPersonCreate($nameSurname: String!, $email: String!, $groupIds: [ID],$isPermitted: Boolean) {
    startupPersonCreate(nameSurname: $nameSurname, email: $email, groupIds: $groupIds, isPermitted:$isPermitted ) 
  }
`;

const CREATE_GROUP = gql`
  mutation startupGroupCreate($title: String!) {
    startupGroupCreate(title: $title) 
  }
`;

const DELETE_PERSON = gql`
  mutation startupPersonDelete($personId: ID!) {
    startupPersonDelete(personId: $personId) 
  }
`;

const DELETE_GROUP = gql`
  mutation startupGroupDelete($groupId: ID!) {
    startupGroupDelete(groupId: $groupId) 
  }
`;

const EDIT_GROUP = gql`
  mutation startupGroupEdit($groupId: ID!, $title: String, $personIds:[ID]) {
    startupGroupEdit(groupId: $groupId, title: $title, personIds: $personIds) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_PERSON, {
    name: 'startupPersonCreate',
  }),
  graphql(DELETE_PERSON, {
    name: 'startupPersonDelete',
  }),
  graphql(CREATE_GROUP, {
    name: 'startupGroupCreate',
  }),
  graphql(DELETE_GROUP, {
    name: 'startupGroupDelete',
  }),
  graphql(EDIT_GROUP, {
    name: 'startupGroupEdit',
  }),
)(Followers);
export default JoinWithMutation;