import {useMutation, useQuery} from "@apollo/react-hooks";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Field, Form, Formik} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import FormikDropdown from "../../Components/FormikDropdown";
import FormikNumberField from "../../Components/FormikNumberField";
import {formatDashedDate, numberWithPeriods, resolveCurrencySymbol} from "../../helpers";
import {INVESTOR_SEND_STARTUP_INVESTMENT_REQUEST} from "../../mutations";
import {MY_PROFILE} from "../../queries";
import {investorInvestToStartup1} from "../../yupSchemas";
import GreenTag from "../greenTag.js";
import Stepper from "./Stepper";
import Bush from "./yatirim_bg.png";

const CustomDialogActions = withStyles(theme => ({
  root: {
    justifyContent: "center"
  }
}))(DialogActions);

const useStyles = makeStyles(theme => ({
  img: {
    width: 45,
    height: 45,
    objectFit: "contain",
    borderRadius: "12px"
  },
  paperPopUp: {
    padding: 12,
    textAlign: "center",
    fontWeight: 600,
    foınstSize: 9,
    color: "#8492A6",
    height: "100%",
    width: "100%",
    boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    borderRadius: "8px"
  },
  h1: {
    color: "#118DFF",
    marginBottom: "0"
  },
  cardGrid: {
    height: "auto"
  },
  popupInvestmentCard: {
    marginTop: "16px",
    marginBottom: "16px",
    width: "100%",
    height: "250px",
    margin: "0 auto",
    backgroundImage: `url(${Bush})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      width: "0px",
      height: "0px",
      display: "none"
    }
  },
  popupPaper: {
    //margin: '0 auto',
    //width: '70%',
    //height: '55%',
    textAlign: "center",

    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginLeft: 32,
    marginRight: 32,
    display: "flex",
    marginBottom: 32,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  avatar: {
    marginTop: "2%",
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "4px",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  formControl: {
    width: "50%"
  },
  titleBar: {
    width: "fit-content",
    marginLeft: 8
  },
  popupContainer: {
    padding: "40px 72px 40px 72px",
    [theme.breakpoints.down("md")]: {
      padding: "15px 10px 40px 10px"
    }
  },
  checkbox: {
    margin: "0 auto",
    fontSize: "72px",
    color: "#003CA5",
    marginTop: "2%",
    marginBottom: "2%",
    backgroundColor: "#F0F5FF",
    borderRadius: "50%",
    width: 116,
    height: 116,
    textAlign: "center"
  }
}));

export default function ToInvestPopup(props) {
  //const [open, setOpenInvest] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [amountWillingToPay, setAmountWillingToPay] = React.useState(0);
  const {t} = useTranslation();

  const classes = useStyles();
  const {setOpenInvest, openInvest} = props;
  //mutations and queries
  const [investorSendInvReq] = useMutation(INVESTOR_SEND_STARTUP_INVESTMENT_REQUEST);

  const {data: userData} = useQuery(MY_PROFILE);
  const history = useHistory();
  const {location} = history;

  const handleSubmitStartupInvestmentRequest = async values => {
    const {error} = await investorSendInvReq({
      variables: {
        startupId: props.id,
        email: values.email,
        telephone: values.telephone,
        adress: values.adress,
        communicationMethod: values.communicationMethod,
        investmentAmount: parseInt(values.investmentAmount)
      }
    });

    if (error) console.log(error);

    setOpenInvest(false);

    location.search = `${location.search ? location.search + "&" : "?"}utm_event=new-fr-request`;
    history.push(location);

    setOpen3(true);
  };

  const handleClose = () => {
    setOpenInvest(false);
    setOpen3(false);
  };

  const handleClickOpen = () => {
    setOpenInvest(true);
  };

  function getDateFunc() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const stage = ["Email", "Telefon", "Yüz yüze"];

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <>
      {props.minTicketSize && (
        <Formik
          initialValues={{
            communicationMethod: "",
            investmentAmount: "",
            email: "",
            telephone: "",
            adress: "",
            minTicketSize: parseInt(props.minTicketSize)
          }}
          onSubmit={values => {
            //setAmountWillingToPay(values.investmentAmount);
            //setContactTypeSelect(values.communicationMethod);
            handleSubmitStartupInvestmentRequest(values);
            /*setOpenInvest(false);
            setOpen2(true);*/
          }}
          validationSchema={investorInvestToStartup1}
        >
          {({formik, errors}) =>
            openInvest && (
              <Dialog
                open={openInvest}
                //TransitionComponent={Transition}
                //keepMounted
                onClose={() => setOpenInvest(false)}
                aria-labelledby="alert-1"
                aria-describedby="alert-1"
                fullWidth
                maxWidth="lg"
              >
                {" "}
                <Form>
                  <Grid container className={classes.popupContainer} spacing={0}>
                    <Grid item xs={12} md={7} style={{marginTop: "16px"}}>
                      <DialogContent style={{overflowY: "hidden" /* paddingLeft: '10%' paddingRight: '10%'*/}}>
                        <Grid xs={12} container item justify="end" style={{marginBottom: "16px"}}>
                          <Link style={{padding: 0}} to={`/girisim/${props.slug}`}>
                            <Grid item>
                              {props.logo ? (
                                <img
                                  src={props.logo}
                                  className={classes.img}
                                  alt="logo"
                                  style={{objectFit: "contain"}}
                                ></img>
                              ) : (
                                <Grid
                                  style={{
                                    width: 45,
                                    height: 45,
                                    borderRadius: "12px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#003CA5"
                                  }}
                                >
                                  <Typography variant="h4" style={{color: "#fff"}}>
                                    {props.name && props.name.charAt(0)}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Link>

                          <Grid container direction="column" className={classes.titleBar} xs>
                            <Link style={{padding: 0}} to={`/girisim/${props.slug}`}>
                              <Typography variant="h5" align="left">
                                {props.name}
                              </Typography>
                            </Link>
                            {props.industry && (
                              <Typography variant="body2" align="left" style={{fontSize: 12, color: "#425466"}}>
                                {props.industry[0]}
                              </Typography>
                            )}
                            <Grid container spacing={1} style={{marginTop: 4}}>
                              {props.businessModelArray &&
                                props.businessModelArray.length > 0 &&
                                props.businessModelArray.map(model => (
                                  <Grid item>
                                    <GreenTag title={model} />
                                  </Grid>
                                ))}
                              {props.country && (
                                <Grid item>
                                  <GreenTag title={props.country.title} />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Divider style={{marginBottom: "16px"}} />

                        <Grid container justify="center" spacing={3} style={{marginBottom: "12px"}}>
                          <Grid justifyContent="center" item xs={12} md={4}>
                            <Paper className={classes.paperPopUp}>
                              ARANAN YATIRIM TUTARI
                              <h1 className={classes.h1}>
                                {(props.amountOfInvestmentSought &&
                                  resolveCurrencySymbol(props.currencySymbol) +
                                    numberWithPeriods(props.amountOfInvestmentSought)) ||
                                  ""}
                              </h1>
                            </Paper>
                          </Grid>

                          <Grid className={classes.cardGrid} justifyContent="center" item xs={12} md={4}>
                            <Paper className={classes.paperPopUp}>
                              MINIMUM YATIRIM TUTARI
                              <h1 className={classes.h1}>
                                {(props.minTicketSize &&
                                  resolveCurrencySymbol(props.currencySymbol) +
                                    numberWithPeriods(props.minTicketSize)) ||
                                  ""}
                              </h1>
                            </Paper>
                          </Grid>

                          <Grid className={classes.cardGrid} justifyContent="center" item xs={12} md={4}>
                            <Paper className={classes.paperPopUp}>
                              TUR KAPANIŞ TARİHİ
                              <h1 className={classes.h1}>{formatDashedDate(props.sprintClosingDate) || "Tarih Yok"}</h1>
                            </Paper>
                          </Grid>
                          <Grid className={classes.cardGrid} justifyContent="center" item xs={12} md={5}>
                            <Paper className={classes.paperPopUp}>
                              TOPLANAN TAAHHÜT TUTARI
                              <h1 className={classes.h1}>
                                {(props.currentInvestmentAmount !== undefined &&
                                  resolveCurrencySymbol(props.currencySymbol) +
                                    numberWithPeriods(props.currentInvestmentAmount)) ||
                                  ""}
                              </h1>
                            </Paper>
                          </Grid>

                          <Grid className={classes.cardGrid} justifyContent="center" item xs={12} md={4}>
                            <Paper className={classes.paperPopUp}>
                              KALAN TAAHHÜT TUTARI
                              <h1 className={classes.h1}>
                                {(props.amountOfInvestmentSought !== undefined &&
                                  props.currentInvestmentAmount !== undefined &&
                                  resolveCurrencySymbol(props.currencySymbol) +
                                    numberWithPeriods(
                                      Math.max(0, props.amountOfInvestmentSought - props.currentInvestmentAmount)
                                    )) ||
                                  ""}
                              </h1>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid container direction="row">
                          <Grid item xs={12} md={6}>
                            <FormControl style={{minWidth: "100%"}} className={classes.formControl}>
                              {errors.communicationMethod ? (
                                <>
                                  <Field
                                    variant="outlined"
                                    style={{width: "100%", marginTop: 0}}
                                    component={FormikDropdown}
                                    textFieldProps={{
                                      error: true,
                                      fullWidth: true,
                                      variant: "outlined",
                                      placeholder: "İletişim Yöntemi"
                                    }}
                                    name="communicationMethod"
                                    options={stage}
                                    //onChange={(e) => setContactTypeSelect(e.target.value)}
                                  ></Field>
                                  <Typography style={{color: "red", fontSize: 12, paddingTop: 10}}>
                                    {errors.communicationMethod}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Field
                                    variant="outlined"
                                    style={{width: "100%", marginTop: 0}}
                                    component={FormikDropdown}
                                    textFieldProps={{
                                      fullWidth: true,
                                      variant: "outlined",
                                      placeholder: "İletişim Yöntemi"
                                    }}
                                    name="communicationMethod"
                                    options={stage}
                                    //onChange={(e) => setContactTypeSelect(e.target.value)}
                                  ></Field>
                                </>
                              )}
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} align="center" style={{paddingLeft: 16}}>
                            <Typography style={{display: "inline-block", textAlign: "left"}}>
                              Hesap yöneticinizin sizinle iletişime geçeceği iletişim yöntemini ifade eder.
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider style={{marginTop: "16px"}} />

                        <Typography style={{textAlign: "center", marginTop: "16px"}} variant="h4">
                          Dahil Olmak İstediğiniz Tutarı Giriniz
                        </Typography>

                        <FormikNumberField
                          style={{margin: "16px 0 16px 0"}}
                          onKeyUp={e => setAmountWillingToPay(e.target.value)}
                          fullWidth
                          name="investmentAmount"
                          id="outlined-basic"
                          variant="outlined"
                          elevation={3}
                          type="number"
                        />

                        <Grid className={classes.popupInvestmentCard} xs={12}>
                          <Grid container direction="row">
                            <Grid item xs={6}>
                              <Typography variant="h5" style={{marginTop: "20px", marginLeft: "24px"}}>
                                <svg
                                  width="106"
                                  height="36"
                                  viewBox="0 0 106 36"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M94.655 5.09765H92.2118V10.5114C91.6493 11.1969 90.9814 11.566 90.3662 11.566C89.2237 11.566 88.5558 10.8453 88.5558 9.50948V5.09765H86.1125V9.73798C86.1125 12.1812 87.589 13.7807 89.8037 13.7807C90.7529 13.7807 91.5263 13.4643 92.2118 12.9194V13.517H94.655V5.09765Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M101.626 4.92188C100.677 4.92188 99.8857 5.16795 99.2353 5.5898V5.09765H96.7921V17.5773H99.2353V13.0249C99.8857 13.4467 100.677 13.6928 101.626 13.6928C103.946 13.6928 105.809 11.7593 105.809 9.31613C105.809 6.83777 103.946 4.92188 101.626 4.92188ZM101.222 11.6187C100.501 11.6187 99.7802 11.3726 99.2353 10.652V7.9627C99.7802 7.22447 100.501 6.97839 101.222 6.97839C102.487 6.97839 103.401 8.06816 103.401 9.31613C103.401 10.5465 102.487 11.6187 101.222 11.6187Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M18.0264 0.252273L13.4881 9.38305C12.9298 10.5176 11.543 10.9679 10.4084 10.3916L14.9468 1.2608C15.5231 0.126207 16.8918 -0.324028 18.0264 0.252273Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M25.5714 10.3901L21.033 1.25934C20.4747 0.124742 19.088 -0.325493 17.9534 0.250808L22.4917 9.38158C23.068 10.5162 24.4548 10.9844 25.5714 10.3901Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M17.9717 35.7485L22.5101 26.6177C23.0683 25.4831 24.4551 25.0329 25.5897 25.6092L21.0513 34.74C20.475 35.8746 19.0883 36.3248 17.9717 35.7485Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M10.4084 25.5918L14.9468 34.7406C15.5051 35.8752 16.8918 36.3254 18.0264 35.7491L13.4881 26.6183C12.9298 25.4837 11.543 25.0155 10.4084 25.5918Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M0.250808 17.9736L9.38158 22.512C10.5162 23.0703 10.9664 24.457 10.3901 25.5916L1.25934 21.0352C0.124742 20.4769 -0.325493 19.0902 0.250808 17.9736Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M10.3901 10.4097L1.25934 14.948C0.124742 15.5063 -0.325493 16.8931 0.250808 18.0277L9.38158 13.4893C10.5162 12.931 10.9844 11.5443 10.3901 10.4097Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M35.7478 18.0277L26.617 13.4893C25.4824 12.931 25.0322 11.5443 25.6085 10.4097L34.7393 14.948C35.8738 15.5243 36.3241 16.8931 35.7478 18.0277Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M25.59 25.5731L34.7208 21.0347C35.8554 20.4764 36.3057 19.0897 35.7293 17.9551L26.5986 22.4935C25.482 23.0698 25.0137 24.4565 25.59 25.5731Z"
                                    fill="#0054E8"
                                  />
                                  <path
                                    d="M42.7175 10.5835C42.8933 12.9739 44.7389 13.7649 46.6899 13.7649C48.8343 13.7649 50.4866 12.8157 50.4866 10.9174C50.4866 9.33549 49.3089 8.68514 47.9379 8.43906L46.4087 8.19298C45.6353 8.05237 45.4068 7.7184 45.4068 7.40202C45.4068 7.01532 45.8111 6.68136 46.6548 6.68136C47.4633 6.68136 48.1488 6.98017 48.2016 7.73598L50.4338 7.7184C50.2581 5.3455 48.0961 4.85334 46.6548 4.85334C44.4928 4.85334 43.0163 5.89039 43.0163 7.63052C43.0163 9.19487 44.2116 9.89795 45.4947 10.1265L47.2876 10.4253C47.85 10.5307 48.1313 10.7944 48.1313 11.1283C48.1313 11.5678 47.5512 11.849 46.7075 11.849C45.7408 11.849 45.0904 11.4975 44.9498 10.601L42.7175 10.5835Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M58.3578 11.2865C57.8832 11.4096 57.3911 11.515 56.9341 11.515C56.0376 11.515 55.4576 11.0756 55.4576 10.1265V7.13836H58.4984V5.08185H55.4576V2.07617H53.032V5.08185H51.2567V7.13836H53.032V10.144C53.032 12.3236 54.3678 13.7649 56.4067 13.7649C57.1977 13.7649 57.6547 13.6946 58.6742 13.3782L58.3578 11.2865Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M68.5609 5.08185H66.1176V5.574C65.4673 5.15215 64.6763 4.90608 63.7272 4.90608C61.407 4.90608 59.5263 6.82197 59.5263 9.30034C59.5263 11.7435 61.407 13.677 63.7272 13.677C64.6763 13.677 65.4673 13.4309 66.1176 13.0091V13.5012H68.5609V5.08185ZM64.1314 11.6029C62.8483 11.6029 61.9519 10.5307 61.9519 9.30034C61.9519 8.05237 62.8483 6.96259 64.1314 6.96259C64.8521 6.96259 65.5728 7.20867 66.1176 7.9469V10.6362C65.5728 11.3568 64.8521 11.6029 64.1314 11.6029Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M73.1534 5.08185H70.7102V13.5012H73.1534V8.47421C73.8565 7.70082 74.7881 7.31413 76.1063 7.2614V4.94123C74.8584 4.94123 73.9092 5.32792 73.1534 6.03101V5.08185Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M83.9853 11.2865C83.5107 11.4096 83.0185 11.515 82.5615 11.515C81.6651 11.515 81.085 11.0756 81.085 10.1265V7.13836H84.1259V5.08185H81.085V2.07617H78.6594V5.08185H76.8841V7.13836H78.6594V10.144C78.6594 12.3236 79.9953 13.7649 82.0342 13.7649C82.8252 13.7649 83.2822 13.6946 84.3016 13.3782L83.9853 11.2865Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M48.1313 22.4831C47.1821 22.4831 46.3911 22.7292 45.7408 23.151V18.5986H43.2976V31.0783H45.7408V30.5861C46.3911 31.008 47.1821 31.2541 48.1313 31.2541C50.4514 31.2541 52.3146 29.3382 52.3146 26.8598C52.3146 24.4166 50.4514 22.4831 48.1313 22.4831ZM47.727 29.1975C47.0063 29.1975 46.2857 28.9515 45.7408 28.2132V25.5239C46.2857 24.8033 47.0063 24.5572 47.727 24.5572C48.9925 24.5572 49.9065 25.6294 49.9065 26.8598C49.9065 28.1078 48.9925 29.1975 47.727 29.1975Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M58.1216 22.4831C55.5202 22.4831 53.6043 24.3287 53.6043 26.8774C53.6043 29.4436 55.5202 31.3419 58.1216 31.3419C60.7054 31.3419 62.6037 29.4436 62.6037 26.8774C62.6037 24.3287 60.7054 22.4831 58.1216 22.4831ZM58.1216 29.1624C56.8385 29.1624 56.0124 28.1429 56.0124 26.8774C56.0124 25.6294 56.8385 24.6627 58.1216 24.6627C59.3872 24.6627 60.1957 25.6294 60.1957 26.8774C60.1957 28.1429 59.3872 29.1624 58.1216 29.1624Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M66.6307 22.6589H64.1875V31.0783H66.6307V26.0513C67.3338 25.2779 68.2653 24.8912 69.5836 24.8384V22.5183C68.3356 22.5183 67.3865 22.905 66.6307 23.608V22.6589Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M70.2675 28.1605C70.4432 30.551 72.2888 31.3419 74.2399 31.3419C76.3843 31.3419 78.0365 30.3928 78.0365 28.4945C78.0365 26.9125 76.8589 26.2622 75.4879 26.0161L73.9587 25.77C73.1853 25.6294 72.9568 25.2954 72.9568 24.9791C72.9568 24.5924 73.361 24.2584 74.2047 24.2584C75.0133 24.2584 75.6988 24.5572 75.7515 25.313L77.9838 25.2954C77.808 22.9225 75.646 22.4304 74.2047 22.4304C72.0428 22.4304 70.5663 23.4674 70.5663 25.2076C70.5663 26.7719 71.7615 27.475 73.0446 27.7035L74.8375 28.0023C75.4 28.1078 75.6812 28.3714 75.6812 28.7054C75.6812 29.1448 75.1012 29.426 74.2575 29.426C73.2907 29.426 72.6404 29.0745 72.4998 28.1781L70.2675 28.1605Z"
                                    fill="#379FFF"
                                  />
                                  <path
                                    d="M88.2492 22.6589H85.806V23.151C85.1556 22.7292 84.3647 22.4831 83.4155 22.4831C81.0953 22.4831 79.2146 24.399 79.2146 26.8774C79.2146 29.3206 81.0953 31.2541 83.4155 31.2541C84.3647 31.2541 85.1556 31.008 85.806 30.5861V31.0783H88.2492V22.6589ZM83.8198 29.18C82.5367 29.18 81.6402 28.1078 81.6402 26.8774C81.6402 25.6294 82.5367 24.5396 83.8198 24.5396C84.5404 24.5396 85.2611 24.7857 85.806 25.5239V28.2132C85.2611 28.9339 84.5404 29.18 83.8198 29.18Z"
                                    fill="#379FFF"
                                  />
                                </svg>
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography
                                variant="h5"
                                style={{textAlign: "right", marginTop: "20px", marginRight: "24px", color: "#118DFF"}}
                              >
                                <strong style={{color: "#003CA5"}}>Tarih: </strong> {getDateFunc()}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Typography
                            align="center"
                            variant="h3"
                            style={{paddingBottom: "16px", fontSize: 16, fontWeight: 600, color: "#003CA5"}}
                          >
                            <strong>Talep Bilgileriniz</strong>
                          </Typography>

                          <Paper elevation={3} className={classes.popupPaper}>
                            <Grid container>
                              <Grid container xs={12} align="center" justify="center">
                                <Avatar className={classes.avatar} src={userData?.myProfile?.profilePhoto} />

                                <Typography variant="h5" style={{paddingTop: "1%", marginTop: "2%", color: "#003CA5"}}>
                                  {userData?.myProfile?.firstName} {userData?.myProfile?.lastName}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} align="center" justify="center">
                                <Link style={{padding: 0}} to={`/girisim/${props.slug}`}>
                                  <Typography variant="h5" align="center">
                                    <u style={{color: "#118DFF"}}>{props.name}</u> isimli girişime
                                  </Typography>
                                </Link>
                              </Grid>
                              <Grid item xs={12} align="center" justify="center">
                                <Typography variant="h4" style={{color: "#118DFF"}}>
                                  {resolveCurrencySymbol(props.currencySymbol) + numberWithPeriods(amountWillingToPay)}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} style={{padding: 5}} align="center" justify="center">
                                <Divider />

                                <Typography>Yatırım yapmak istediğini belirtti.</Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                        <CustomDialogActions>
                          <Button onClick={handleClose} variant="outlinedSecondary">
                            {t("Vazgeç")}
                          </Button>
                          <Button type="submit" color="primary" variant="containedPrimary">
                            {t("Gönder")}
                          </Button>
                        </CustomDialogActions>
                      </DialogContent>
                    </Grid>

                    {/************************ Stepper ***********************************/}
                    <Grid item sm={12} md={5} style={{marginTop: "30px"}}>
                      <Stepper firstStep={1} />
                    </Grid>
                  </Grid>
                </Form>
              </Dialog>
            )
          }
        </Formik>
      )}
      {/*Pop Up 3*/}
      {open3 && (
        <Dialog
          open={open3}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <Grid container sm={12} spacing={3}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} md={7} style={{marginBottom: "50px"}}>
                  <Grid className={classes.checkbox}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17 24L21.6667 28.6667L31 19.3333M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                        stroke="#003CA5"
                        stroke-width="4.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Grid>

                  <Typography variant="h2" style={{textAlign: "center", marginTop: "2%"}}>
                    Talep alındı
                  </Typography>
                  <Grid style={{width: "50%", marginLeft: "25%", marginBottom: 60}}>
                    <Typography style={{textAlign: "center"}}>
                      Talebiniz hesap yöneticinize iletilmiştir. <br /> En kısa zamanda belirlediğiniz iletişim
                      yöntemiyle sizinle iletişime geçecektir.
                    </Typography>
                  </Grid>
                  <CustomDialogActions style={{alignItems: "stretch"}}>
                    <Button onClick={handleClose} variant="outlinedSecondary">
                      Tamam
                    </Button>
                    <Link style={{padding: 0}} to={`/yatirimci-arayanlar`}>
                      <Button onClick={handleClose} color="primary" variant="containedPrimary">
                        Diğer şirketleri gör
                      </Button>
                    </Link>
                  </CustomDialogActions>
                </Grid>

                {/************************ Stepper ***********************************/}
                <Grid item sm={12} md={5} style={{marginTop: "30px"}}>
                  <Stepper firstStep={"✓"} />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
