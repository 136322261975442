import {Breadcrumbs, Grid, makeStyles, Typography} from "@material-ui/core";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clsx from "clsx";
import get from "lodash/get";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-apollo";
import {useRouteMatch} from "react-router-dom";
import {useTempConversation} from "../../context/tempConversation";
import {ALL_CONVERSATIONS, ALL_MESSAGES} from "../../queries";
import {MESSAGES_SUBSCRIPTION} from "../../subscriptions";
import LeftMenu from "./Components/LeftMenu";
import MessageContainer from "./Components/MessageContainer";
import "./style.css";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth
    },
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  whiteCard: {
    background: "#ffffff",
    borderRadius: "2px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    marginBottom: 50,
    marginTop: 36,
    borderRadius: theme.shape.borderRadius * 4,
    overflow: "hidden",
    boxShadow: "0px 0px 10px 0px #0000000D",
    height: "calc(100vh - 262px)",
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 196px)",
      marginTop: 10,
      marginBottom: 10
    }
  },
  leftMenu: {
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      display: ({selectedConversation}) => (selectedConversation ? "none" : "flex")
    }
  }
}));

export default function Inbox(props) {
  const match = useRouteMatch();
  const {endTemporaryConversation, receiverUser} = useTempConversation();
  // const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {data: allConversationsData, loading: allConversationsLoading, error: allConversationsError} = useQuery(
    ALL_CONVERSATIONS
  );
  const {data: allMessagesData, loading: allMessagesLoading, error: allMessagesError, subscribeToMore} = useQuery(
    ALL_MESSAGES
  );

  const [selectedConversation, setSelectedConversation] = useState(receiverUser);
  const classes = useStyles({selectedConversation});
  const {
    params: {conversationId}
  } = match;

  // ws update
  useEffect(() => {
    subscribeToMore({
      document: MESSAGES_SUBSCRIPTION,
      variables: {id: props.userId},
      updateQuery: (prev, {subscriptionData}) => {
        if (!subscriptionData.data) return prev;
        setTimeout(function () {
          var objDiv = document.getElementById("text-screen");
          if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }, 200);

        const newMessage = subscriptionData.data.Message;
        return Object.assign({}, prev, {
          allMessages: [...prev.allMessages, newMessage]
        });
      }
    });
  }, []);

  useEffect(() => {
    if (conversationId && !allConversationsLoading) {
      // filter allConversation data by conversationId and set the filtered data as selectedConversation
      if (Number(conversationId) === -1) {
        //pass
      } else if (Number(conversationId) === -2) {
        setSelectedConversation(allConversationsData.allConversations[0]);
      } else {
        endTemporaryConversation();
        let filteredConversation = allConversationsData.allConversations.filter(
          conversation => conversation.id === conversationId
        );
        setTimeout(function () {
          var objDiv = document.getElementById("text-screen");
          if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }, 200);
        setSelectedConversation(filteredConversation[0]);
      }
    } else {
      endTemporaryConversation();
    }
  }, [allConversationsData, conversationId]);

  if (allConversationsLoading || allConversationsError || allMessagesLoading || allMessagesError) return null;

  let filteredMessages = allMessagesData.allMessages.filter(
    message => message.conversation.id === selectedConversation?.id
  );

  // get unread messages
  const allConversationsWithUnread = allConversationsData.allConversations.map(conversation => {
    let unreadMessageNumber = 0;
    allMessagesData.allMessages.map(message => {
      if (message.conversation.id === conversation.id) {
        if (get(message, "unread", []).find(u => u.id === props.userId)) {
          unreadMessageNumber += 1;
        }
      }
    });

    const hasUnreadMessage = unreadMessageNumber > 0;

    return {...conversation, hasUnreadMessage, unreadMessageNumber};
  });

  const unreadConversationNumber = allConversationsWithUnread.filter(conversation => conversation.hasUnreadMessage)
    .length;

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
      })}
    >
      <Grid container justify="center" style={{backgroundColor: "rgb(247, 247, 252)"}}>
        <Grid className={"main-container"} xs={12} style={{marginTop: 140, maxWidth: 1320}}>
          <Grid item style={{width: "100%"}}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item style={{display: "flex"}}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <MailOutlinedIcon style={{fontSize: 20, marginTop: 8, color: "rgb(160, 174, 192)"}} />
                  <Typography variant="h4">Mesajlar</Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} className={classes.whiteCard}>
            <Grid item className={classes.leftMenu}>
              <LeftMenu
                data={allConversationsWithUnread} // allConversations data
                selectedConversation={selectedConversation}
                setSelectedConversation={setSelectedConversation}
                unreadConversationNumber={unreadConversationNumber}
              />
            </Grid>
            <Grid item xs>
              <MessageContainer
                selectedConversation={selectedConversation}
                setSelectedConversation={setSelectedConversation}
                selectedAllMessages={filteredMessages}
                userId={props.userId}
                // openDeleteDialog={() => setDeleteDialogOpen(true)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        conversationId={selectedConversation?.id}
      /> */}
    </main>
  );
}
