
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import Popups from './Popups';
import gql from 'graphql-tag';

const FIRST_POPUP = gql`
  mutation firstPopup(
    $type: String!
    $companyName: String
    $companyWebsite: String
    $companySector: [String]
    $companyRole: String
    $investorShareRate: Int
    $hasShare: String
    $hasEverInvested: String
    $investedCompanyCount: String
    $investmentAmount: String
    $plannedAmountOfAngelInvestment: String
    $riskPreference: String
    $plannedInvCompAmouInTwo: String
    $plannedExitYears: String
    $preferSellOnSB: String
    $legalObstaclesOnSell: String
    $legalObstaclesOnSelldesc: String
    $hearSBFrom: String
    $purposeEnteringSB: String
    $corporateName: String
    $corporateDefinition: String
    $corporateRole: String
    $corporateSectors: [String]
    $corporateReservedToInvest: String
    $corporateRiskPreference: String
    $corpPlannedInvCompAmouInTwo: String
    $corpPlannedExitYear: String
 ){
  firstPopup
    (
        type: $type
        companyName: $companyName
        companyWebsite: $companyWebsite
        companySector: $companySector
        companyRole: $companyRole
        investorShareRate: $investorShareRate
        hasShare: $hasShare
        hasEverInvested: $hasEverInvested
        investedCompanyCount: $investedCompanyCount
        investmentAmount: $investmentAmount
        plannedAmountOfAngelInvestment: $plannedAmountOfAngelInvestment
        riskPreference: $riskPreference
        plannedInvCompAmouInTwo: $plannedInvCompAmouInTwo
        plannedExitYears: $plannedExitYears
        preferSellOnSB: $preferSellOnSB
        legalObstaclesOnSell: $legalObstaclesOnSell
        legalObstaclesOnSelldesc: $legalObstaclesOnSelldesc
        hearSBFrom: $hearSBFrom
        purposeEnteringSB: $purposeEnteringSB
        corporateName: $corporateName
        corporateDefinition: $corporateDefinition
        corporateRole: $corporateRole
        corporateSectors: $corporateSectors
        corporateReservedToInvest: $corporateReservedToInvest
        corporateRiskPreference: $corporateRiskPreference
        corpPlannedInvCompAmouInTwo: $corpPlannedInvCompAmouInTwo
        corpPlannedExitYear: $corpPlannedExitYear
    )
  }
`;
const JoinWithMutation = compose(
  graphql(FIRST_POPUP, {
  name: 'firstPopup',
  }),
)(Popups);
export default JoinWithMutation;