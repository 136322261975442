import MyInvestmentPortfolio from './MyInvestmentPortfolio'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const REQUEST_SEND = gql`
  mutation createStartupFollowRequest($startupId: ID, $website: String, $nameSurname: String, $email: String, $note: String ) {
    createStartupFollowRequest(startupId: $startupId, website: $website, nameSurname: $nameSurname, email: $email, note: $note) 
  }
`;


const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(REQUEST_SEND, {
    name: 'createStartupFollowRequest',
  }),
)(MyInvestmentPortfolio);
export default JoinWithMutation;