
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import PersonalSettings from '../../Components/PersonalSettings';
import DocumentSettings from '../../Components/DocumentSettings';
import PrivacySettings from '../../Components/PrivacySettings';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import get from 'lodash/get';
import { MY_PROFILE } from '../../queries';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newSettings from '../../static/icon/new-settings.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
    marginTop: 50,
    [theme.breakpoints.down('md')]: {
      marginTop: 50,
      margin: 0,
    },
  },
  whiteCard: {
    minHeight: 230,
    background: '#ffffff',
    borderRadius: '4px',
    padding: 50,
    [theme.breakpoints.down('lg')]: {
      padding: 35,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
      padding: 25,
    },
  },
  whiteGrid: {
    height: 'fit-content',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
  TabsGeneral: {
    background: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  imgGrid: {
    borderRadius: '10px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #eaedf3',
    maxWidth: 80,
    maxHeight: 80,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 60,
      maxHeight: 60,
    },
  },
  titleBar: {
    width: 'fit-content',
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 25,
    },
  },
  titleFlex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 25,
    },
  },
  Title: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: 'left',
    color: '#172b4d',
    wordBreak: 'break-word',
  },
  model: {
    color: '#777777',
    width: '100%',
    fontSize: 16,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      width: 'fit-content',
    },
  },
  oneliner: {
    color: '#777777',
  },
  img: {
    width: 80,
    height: 80,
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
  tabGrid: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 25,
    },
  },
  editButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      marginRight: 25,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
export default function Settings(props){
    const classes = useStyles();
    const [detail, setDetail] = React.useState({});
    const [value, setValue] = React.useState(0);
    const [isSent, setIsSent] = React.useState(false);

    const { loading, data, refetch} = useQuery(MY_PROFILE, {
        variables: {},
    });

    const handleTypename = (values) => {
      values = values.map(({__typename,  ...array})  => array)
      return values 
    };

    React.useEffect(() => {
        if(!loading){
            if(data){
              if(!data.myProfile.profilePhoto){
                data.myProfile.profilePhoto = ''
              }
              if(!data.myProfile.interestedIndustries){
                data.myProfile.interestedIndustries = []
              }
              if(data.myProfile.documents !== [] && data.myProfile.documents){
                data.myProfile.documents = handleTypename(data.myProfile.documents)
              }
              if(!data.myProfile.documents || get(data.myProfile.documents, 'length', 0) === 0){
                data.myProfile.documents = [
                  {
                    title:null,
                    file:null
                  }
                ]
              }

              setDetail(data.myProfile);
            }
        }
    },
    [data, loading])
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
            <>
                  <main
                  className={clsx(classes.content, {
                      [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
                  })}
                  >
                  {detail &&  
                  <Grid container style={{background:'#f7f7fc',justifyContent:'center', minHeight: '100vh'}}>
                        <Grid className={"main-container"} xs={12} style={{marginTop:140,height:'fit-content',maxWidth:1320}}>
                            <Grid item style={{width:'100%'}}>
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  style={{marginBottom:'35px'}}>
                                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                    <img src={newSettings} style={{ width: 18, marginTop:5 }} alt="settings" />
                                    <Typography variant="h4">Ayarlarım</Typography>
                                  </Breadcrumbs>
                                </Grid>
                                <AppBar position="static" color="default">
                                  <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    className={classes.TabsGeneral}
                                  >
                                    <Tab label="Bireysel" {...a11yProps(0)} style={{fontWeight:600}} />
                                    <Tab label="Güvenlik" {...a11yProps(1)} style={{fontWeight:600}} />
                                    {/*<Tab label="Doküman" {...a11yProps(2)} />*/}
                                  </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                  <PersonalSettings {...props} refetch={refetch} setIsSent={setIsSent} detail={detail} />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                  <PrivacySettings {...props} refetch={refetch} setIsSent={setIsSent} detail={detail} />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                  <DocumentSettings {...props} refetch={refetch} setIsSent={setIsSent} detail={detail}/>
                                </TabPanel>
                            </Grid>                           
                        </Grid>
                        <Snackbar autoHideDuration={5000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setIsSent(false)} open={isSent} >
                          <Alert severity="success">Yaptığınız değişiklikler kaydedildi.</Alert>
                        </Snackbar>
                    </Grid>
                    }
            </main>        
        </>
    )
}