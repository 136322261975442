import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DashboardIcon from '../../static/icon/dashboard.svg';
import FormikTextField from "../FormikTextField";
import { FieldArray, Field, Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Dropzone from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>({
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            marginBottom: '30px',
            padding: 50,
            [theme.breakpoints.down('sm')]: {
                minHeight: '0',
                padding: 25,
            },
        },
        fontLg:{
            fontSize:14
        },
        objectCard:{
            display:'flex',
            alignItems:'center'
        },
        AddButton:{
            padding: '12.5px 34px',
            fontSize:16,
            marginRight:10,
            fontWeight:'normal'
        },
        baseStyle: {
            flex: 1,
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            alignItems: 'center',
            height: '80px',
            width:'90px',
            justifyContent: 'center',
            borderWidth: 2,
            borderRadius: 4,
            border: 'solid 1px #dddddd6b',
            backgroundColor: '#ffffff',
            color: '#172b4d',
            fontWeight: '500',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
    }));
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        flexWrap: 'wrap',
      };
      
      const thumb = {
        display: 'inline-flex',
        borderRadius: 4,
        border: '1px solid #eaeaea',
        width: 90,
        height: 80,
        padding: 4,
        boxSizing: 'border-box'
      };
      
      const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
      };
      
      const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius:7
      };
        export default function DocumentSettings(props) {
            const classes = useStyles();
            const [edit, setEdit] = React.useState(false);
            const [submitting, setSubmitting] = React.useState(false);
            const { t } = useTranslation();

            const handleSubmit = async variablesAll => {
                setSubmitting(true);
                const { ...variables } = variablesAll;
                if(variables.profilePhoto !== null){
                if(typeof variables.profilePhoto === 'string' && variables.profilePhoto !== ''){
                    variables.profilePhoto = null
                }
                }
                props.mySettings({ variables })
                .then(data => {
                    if (data.data.mySettings) {
                        setSubmitting(false);
                        setEdit(false)
                        props.setIsSent(true);
                        props.refetch()
                    } 
                    else {
        
                    }
                })
            };

            const DocumentType = [
                "P&L", 
                "Business Plan", 
                "Pitch Deck", 
                "Diğer" 
            ]
            const [files, setFiles] = React.useState([]);
            return (
              <>
                {!edit ? (
                  <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 15 }}>
                        {t('Dokümanlarım')} 
                        </Typography>
                        <Typography
                          className={classes.fontLg}
                          variant="body1"
                          align="left"
                          style={{ width: 'fit-content', color: '#777777' }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                          been the industry’s standard dummy text ever since the.
                        </Typography>
                        <Grid
                          container
                          justify="flex-start"
                          alignItems="center"
                          style={{ marginBottom: 35, marginTop: 20 }}
                        >
                          <Button variant="outlinedPrimary" className={classes.AddButton} onClick={() => setEdit(true)}>
                            Doküman Yükle
                          </Button>
                          <Typography variant="body2" style={{ color: '#8898aa' }}>
                            Maks. Dosya Boyutu 10MB
                          </Typography>
                        </Grid>
                        {props.detail.documents && props.detail.documents.length !== 0 && (
                          <>
                            <Typography variant="h6" style={{ width: 'fit-content', marginBottom: 30 }}>
                              Yüklediğim Dokümanlar
                            </Typography>
                            <Grid container spacing={3} style={{ marginBottom: 50 }}>
                              {props.detail.documents.map((document) => (
                                <>
                                  {document.file && (
                                    <Grid item xs={12} className={classes.objectCard}>
                                      <Grid container alignItems="center">
                                        <a href={document.file} download style={{ display: 'flex', padding: 0 }}>
                                          <img src={DashboardIcon} alt="DashboardIcon" style={{ marginRight: 10 }} />
                                          <Typography variant="h6" align="left" style={{ color: '#003CA5' }}>
                                            {document.title}
                                          </Typography>
                                        </a>
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              ))}
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 15 }}>
                          Yasal Dokümanlarım
                        </Typography>
                        <Typography
                          className={classes.fontLg}
                          variant="body1"
                          align="left"
                          style={{ width: 'fit-content', color: '#777777', marginBottom: 30 }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                          been the industry’s standard dummy text ever since the.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <>
                    <Paper className={classes.paper}>
                      <Grid xs={12} item>
                        {Object.keys(props.detail).length > 0 && (
                          <Formik
                            initialValues={props.detail}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                              handleSubmit(values);
                            }}
                          >
                            {(props) => (
                              <Form style={{ width: '100%' }}>
                                <FieldArray
                                  name="documents"
                                  render={({ insert, remove, push, unshift, form }) => (
                                    <>
                                      <div>
                                        <Grid xs={12} container style={{ marginBottom: 20 }}>
                                          {form.values.documents.length > 0 && (
                                            <>
                                              {form.values.documents.map((news, index) => (
                                                <Button
                                                  variant="containedPrimary"
                                                  className={classes.AddButton}
                                                  onClick={() => remove(index)}
                                                >
                                                  {index + 1}. Doküman{' '}
                                                  <ClearIcon style={{ color: '#ffffff', fontSize: 19 }} />{' '}
                                                </Button>
                                              ))}
                                            </>
                                          )}
                                          <Button
                                            variant="outlinedPrimary"
                                            className={classes.AddButton}
                                            onClick={() => unshift({ title: '', file: null })}
                                          >
                                            Doküman Ekle
                                          </Button>
                                        </Grid>
                                        {form.values.documents.length > 0 && (
                                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            {form.values.documents.map((news, index) => (
                                              <div>
                                                <Grid xs={12} container spacing={2} style={{ marginBottom: 15 }}>
                                                  <>
                                                    <Grid item xs={12} md={6}>
                                                      <Typography variant="h6" style={{ width: 'fit-content' }}>
                                                        Doküman Adı
                                                      </Typography>
                                                      <FormikTextField
                                                        name={`documents.${index}.title`}
                                                        placeholder="Doküman Adı Girin"
                                                        type="text"
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                      <Typography
                                                        variant="h6"
                                                        style={{ width: 'fit-content', marginBottom: 10 }}
                                                      >
                                                        Dosya Yükle
                                                      </Typography>
                                                      <Field>
                                                        {({ field, form, meta }) => (
                                                          <div>
                                                            <Dropzone
                                                              maxSize={20971520}
                                                              onDrop={(acceptedFiles) =>
                                                                form.setFieldValue(
                                                                  `documents.${index}.file`,
                                                                  acceptedFiles,
                                                                )
                                                              }
                                                            >
                                                              {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                                <section>
                                                                  <div
                                                                    style={{
                                                                      width: '100%',
                                                                      height: '100%',
                                                                      padding: 13,
                                                                      border: '1px solid #DDDDDD',
                                                                    }}
                                                                    {...getRootProps({ className: classes.baseStyle })}
                                                                  >
                                                                    <input {...getInputProps()} />
                                                                    {!form.values.documents[index].file ? (
                                                                      <>
                                                                        <Typography
                                                                          variant="h6"
                                                                          style={{ display: 'flex' }}
                                                                        >
                                                                          Dosya Seç
                                                                        </Typography>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {typeof form.values.documents[index].file !==
                                                                        'string' ? (
                                                                          <Typography variant="h6" align="left">
                                                                            {form.values.documents[index].file[0].name}{' '}
                                                                            Yüklendi{' '}
                                                                          </Typography>
                                                                        ) : (
                                                                          <Typography variant="h6" align="left">
                                                                            {form.values.documents[index].file
                                                                              .split('/')
                                                                              .pop()
                                                                              .replace('.png', '')}{' '}
                                                                            Yüklendi
                                                                          </Typography>
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </div>
                                                                </section>
                                                              )}
                                                            </Dropzone>
                                                          </div>
                                                        )}
                                                      </Field>
                                                    </Grid>
                                                  </>
                                                </Grid>
                                              </div>
                                            ))}
                                          </Grid>
                                        )}
                                      </div>
                                    </>
                                  )}
                                />
                                <Grid container justify="flex-end" style={{ marginTop: 20 }}>
                                  {submitting ? (
                                    <Button
                                      variant="containedPrimary"
                                      className={classes.editButton}
                                      disabled
                                      style={{ fontWeight: 'normal' }}
                                    >
                                      <CircularProgress
                                        style={{
                                          color: '#ffffff',
                                          width: '25px',
                                          height: '25px',
                                          marginRight: '10px',
                                          marginBottom: 0,
                                        }}
                                      />{' '}
                                      Yükleniyor..
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="containedPrimary"
                                      className={classes.editButton}
                                      style={{ fontWeight: 'normal' }}
                                      onClick={() => handleSubmit(props.values)}
                                    >
                                      {' '}
                                      Kaydet{' '}
                                    </Button>
                                  )}
                                </Grid>
                              </Form>
                            )}
                          </Formik>
                        )}
                      </Grid>
                    </Paper>
                  </>
                )}
              </>
            );
    }