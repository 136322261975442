import {Button} from "@material-ui/core";
import React, {useEffect} from "react";
import {useLazyQuery, useQuery} from "react-apollo";
import {useHistory, withRouter} from "react-router-dom";
import {useTempConversation} from "../../context/tempConversation";
import {ALL_CONVERSATIONS, GET_USER} from "../../queries";

function CreateConversation({receivedUserId, userId, ...rest}) {
  const history = useHistory();
  const {createTemporaryConversation} = useTempConversation();

  const {data: allConversationsData, loading: allConversationsLoading, error: allConversationsError} = useQuery(
    ALL_CONVERSATIONS
  );

  const [getUser, {data: userData, loading: userLoading, error: userError}] = useLazyQuery(GET_USER, {
    variables: {
      id: receivedUserId
    }
  });

  useEffect(() => {
    if (userData) {
      createTemporaryConversation(userData.getUser);
      history.push("/mesajlar/-1");
    }
  }, [userData]);

  const handleCreateAction = () => {
    const existsConversation = allConversationsData.allConversations.filter(conversation =>
      conversation.users.some(user => user.id === receivedUserId)
    );

    if (existsConversation.length > 0) {
      history.push(`/mesajlar/${existsConversation[0].id}`);
    } else {
      getUser();
    }
  };

  if (allConversationsLoading || allConversationsError || userError) return null;

  return (
    <Button variant="outlinedPrimary" onClick={handleCreateAction} disabled={userLoading} {...rest}>
      Mesaj Gönder
    </Button>
  );
}

export default withRouter(CreateConversation);
