import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import ListKPI from './ListKPI';

const SEND_SUGGESTION = gql`
  mutation kpiSuggest($text: String!) {
    kpiSuggest(text: $text) 
  }
`;

const GA_EDIT_LIST = gql`
  mutation kpiGoogleAnalytics ($viewId: String!, $listId: ID, $kpiGoogleIds: [ID!]) {
    kpiGoogleAnalytics(viewId: $viewId, listId: $listId, kpiGoogleIds: $kpiGoogleIds) 
  }
`;

const CREATE_KPI = gql`
  mutation kpiCreate ($preparedId: ID, $listIds:[ID]) {
    kpiCreate(preparedId: $preparedId, listIds: $listIds) 
  }
`;

const UPDATE_LIST = gql`
  mutation kpiListEdit ($listId: ID!, $kpiIds:[ID!]) {
    kpiListEdit(listId: $listId, kpiIds: $kpiIds) 
  }
`;

const DELETE_KPI_LIST = gql`
  mutation kpiListDelete ($listId: ID!) {
    kpiListDelete(listId: $listId) 
  }
`;

const EDIT_KPI_LIST = gql`
  mutation kpiListChangeName ($listId: ID!, $title: String!) {
    kpiListChangeName(listId: $listId, title: $title) 
  }
`;

const DELETE_KPI_CAT = gql`
  mutation kpiCategoryDelete ($categoryId: ID!) {
    kpiCategoryDelete(categoryId: $categoryId) 
  }
`;


const DELETE_KPI = gql`
  mutation kpiDelete ($kpiId: ID!) {
    kpiDelete(kpiId: $kpiId) 
  }
`;

const SHARE_KPI = gql`
  mutation startupKPIToReport ($title: String!, $kpiId: ID, $categoryId: ID, $listId: ID, $startDate: String!, $dataFrequency: String!, $isPublic: String!, $personIds: [ID], $groupIds: [ID]) {
    startupKPIToReport(title: $title, kpiId: $kpiId, categoryId: $categoryId, listId: $listId, startDate: $startDate, dataFrequency: $dataFrequency, isPublic: $isPublic, personIds: $personIds, groupIds: $groupIds) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(SEND_SUGGESTION, {
    name: 'kpiSuggest',
  }),
  graphql(SHARE_KPI, {
    name: 'startupKPIToReport',
  }),
  graphql(CREATE_KPI, {
    name: 'kpiCreate',
  }),
  graphql(DELETE_KPI_LIST, {
    name: 'kpiListDelete',
  }),
  graphql(DELETE_KPI, {
    name: 'kpiDelete',
  }),
  graphql(DELETE_KPI_CAT, {
    name: 'kpiCategoryDelete',
  }),
  graphql(UPDATE_LIST, {
    name: 'kpiListEdit',
  }),
  graphql(EDIT_KPI_LIST, {
    name: 'kpiListChangeName',
  }),
  graphql(GA_EDIT_LIST, {
    name: 'kpiGoogleAnalytics',
  }),
)(ListKPI);
export default JoinWithMutation;