import 'moment/locale/tr';
import './tableCss.css';

import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Popover,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { getFormattedDateFromUnix, numberWithPeriods, resolveCurrencySymbol } from '../../helpers';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import ColorfulTag from '../../Components/ColorfulTag';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormikMultiLine from '../../Components/FormikMultiLine';
import Image from '../../static/img/404.png';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Right from '../../static/icon/arrow-right.svg';
import clsx from 'clsx';
import filterIcon from '../../static/icon/filterIcon.svg';
import get from 'lodash/get';
import { getSectorsArray } from './getSectorsArray';
import gql from 'graphql-tag';
import moment from 'moment';
import newCross from '../../static/icon/new-cross.svg';
import newHome from '../../static/icon/new-home-blue.svg';
import newSortingIcon from '../../static/icon/newSortingIcon.svg';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },

  CheckText: {
    wordBreak: 'break-all',
    marginBottom: 10,
    '& span': {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  filterPadding: {
    paddingLeft: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      marginTop: 10,
    },
  },
  filter: {
    height: 40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  myPaper: {
    padding: 25,
    width: '650px',
  },
  myPaper2: {
    padding: 25,
  },
  myLabel: {
    fontSize: 14,
  },
  formControl: {
    marginTop: 10,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
  whiteCard: {
    background: '#ffffff',
    //borderRadius: '4px',
    padding: 25,
    marginBottom: 50,
  },
  table: {
    border: 'none',
    //boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderCollapse: 'separate',
    //borderSpacing: '0px 12px',
  },
  tableBody: {
    /* boxShadow: '0 0 0 1px red',
    borderRadius: '50px',
    overflow: 'hidden'*/
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: '#8492A6',
    fontSize: 10,
    padding: '16px 0px 16px 24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0.06em',
    textTransform: 'uppercase',
  },
  body: {
    fontSize: 14,
    padding: '16px 24px 16px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    //backgroundColor: '#fff',
  },
}))(TableRow);
const StyledTableHead = withStyles((theme) => ({
  root: {
    //marginTop: '50px !important',
    border: 'none',
  },
}))(TableHead);

function ExpandingRow(props) {
  const { request } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [report, setReport] = React.useState(false);
  const [alreadyReport, setAlreadyReport] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setReport(false);
  };

  const handleSubmit = (variables) => {
    variables.startupId = props?.request?.startup?.id;
    props
      .createStartupFollowRequest({ variables })
      .then((data) => {
        if (data.data.createStartupFollowRequest) {
          handleClose();
        } else {
        }
      })
      .then(() => {})
      .catch((error) => {
        if (error.message === 'GraphQL error: Already exists.') {
          setAlreadyReport(true);
        }
        handleClose();
      });
  };

  return (
    <>
      <StyledTableRow className="rowFirstTr" key={request.startup.title}>
        <StyledTableCell scope="row">
          <Grid container style={{ alignItems: 'center' }}>
            {request.startup.logo ? (
              <img
                src={request.startup.logo}
                alt="logo"
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  marginRight: 20,
                  objectFit: 'cover',
                  border: 'solid 1px #eaedf3',
                }}
              />
            ) : (
              <Grid
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  display: 'flex',
                  marginRight: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography className="tdItem" variant="h5" style={{ color: '#fff' }}>
                  {request.startup.title && request.startup.title.charAt(0)}
                </Typography>
              </Grid>
            )}
            <Link to={`/girisim/${request.startup.slug}`}>
              <Typography className="tdItem" variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
                {request.startup.title}
              </Typography>
            </Link>
          </Grid>
        </StyledTableCell>

        <StyledTableCell align="left">
          <Typography className="tdItem" variant="body2">
            <ColorfulTag
              backgroundColor="#DEFFEE"
              textColor="#66CB9F"
              title={request.startup.sector ? request.startup.sector : '-'}
            ></ColorfulTag>
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="left">
          <Typography className="tdItem" variant="body2">
            {request.startup.website}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="left">
          <Typography className="tdItem" variant="body2">
            {getFormattedDateFromUnix(request.createdAt)}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography className="tdItem" variant="body2">
            {request.investmentAmount
              ? resolveCurrencySymbol(request.currentSprint?.sprintCurrencySymbol) +
                numberWithPeriods(request.investmentAmount)
              : '-'}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography className="tdItem" variant="body2">
            {'-'}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography className="tdValuationItem" variant="body2">
            {request.startup.lastValuation
              ? resolveCurrencySymbol(request.currentSprint?.sprintCurrencySymbol) +
                numberWithPeriods(request.startup.lastValuation)
              : '-'}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
      {!open ? (
        <>
          <StyledTableRow className="rowSecondTr">
            <StyledTableCell colSpan={7}>
              <Collapse
                style={{
                  boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
                  borderRadius: 16,
                  //height: 60
                }}
                in={!open}
                timeout="auto"
                unmountOnExit
              >
                <Box margin={1}>
                  <Grid container justify="center" align="center">
                    {/* <Typography
                      align="center"
                      variant="h6"
                      style={{
                        position: 'relative',
                        top: '-10px',
                        left: 5,
                        width: 22,
                        height: 22,
                        borderRadius: '100%',
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: 'center',
                        backgroundColor: '#FF9B28',
                        color: '#fff',
                        textAlign: 'center',
                      }}
                    >
                      {'1'}
                    </Typography> */}
                    <Typography
                      align="center"
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M8 10H11M8 14H11M5 10H5.01M5 14H5.01"
                          stroke="#118DFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 14,
                        position: 'relative',
                        fontWeight: 500,
                        textAlign: 'center',
                        color: '#425466',
                      }}
                      align="center"
                      gutterBottom
                      component="div"
                    >
                      Girişimin güncel raporlarını gör
                    </Typography>
                  </Grid>
                </Box>
              </Collapse>
            </StyledTableCell>
          </StyledTableRow>
        </>
      ) : (
        <>
          <StyledTableRow className="rowSecondTr">
            {' '}
            <StyledTableCell colSpan={8}>
              <Collapse
                style={{
                  boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
                  borderRadius: 16,
                  //height: 60
                }}
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Box margin={1}>
                  <Grid container justify="center" align="center">
                    <Typography
                      align="center"
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M8 10H11M8 14H11M5 10H5.01M5 14H5.01"
                          stroke="#118DFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 14,
                        position: 'relative',
                        fontWeight: 500,
                        textAlign: 'center',
                        color: '#425466',
                      }}
                      align="center"
                      gutterBottom
                      component="div"
                    >
                      Girişimin güncel raporlarını gör
                    </Typography>
                  </Grid>
                  {props?.request?.startup?.publicReports && props?.request?.startup?.publicReports.length > 0 ? (
                    <>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow style={{ background: '#FAFAFB' }}>
                              <StyledTableCell style={{ backgroundColor: '#FAFAFB' }}>
                                <Typography variant="h6" style={{ color: '#8492A6', fontSize: 10 }}>
                                  {' '}
                                  Rapor Adı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell style={{ backgroundColor: '#FAFAFB' }} align="left">
                                <Typography variant="h6" style={{ color: '#8492A6', fontSize: 10 }}>
                                  {' '}
                                  Yayın Tarihi{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell style={{ backgroundColor: '#FAFAFB' }} align="left">
                                <Typography variant="h6" style={{ color: '#8492A6', fontSize: 10 }}>
                                  {' '}
                                  Rapor Açıklaması{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell style={{ backgroundColor: '#FAFAFB' }} align="left"></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props?.request?.startup?.publicReports.map((report) => (
                              <StyledTableRow style={{ background: '#fff' }} key={report.title}>
                                <StyledTableCell style={{ border: 'none' }} align="left">
                                  <Typography variant="h6" style={{ fontSize: 12 }}>
                                    {report.title}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell style={{ border: 'none' }} align="left">
                                  <Typography variant="h6" style={{ fontSize: 14 }}>
                                    {moment(parseInt(report.sentDate)).format('Do-MM-YYYY')}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell style={{ border: 'none', maxWidth: 500 }} align="left">
                                  <Typography variant="h6" style={{ fontSize: 12 }}>
                                    {report.sendingText}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell style={{ border: 'none' }} align="left">
                                  {props?.request?.startup?.id && props?.request?.startup?.id === props?.request?.id ? (
                                    <Link style={{ padding: 0, display: 'flex' }} to={`/rapor-goruntule/${report.id}`}>
                                      <Typography
                                        style={{ cursor: 'pointer', color: '#118DFF', fontSize: 12 }}
                                        variant="h6"
                                      >
                                        Raporu Görüntüle
                                      </Typography>
                                      <img src={Right} alt="Right" style={{ width: 15, marginLeft: 10 }} />
                                    </Link>
                                  ) : (
                                    <Link style={{ padding: 0, display: 'flex' }} to={`/rapor-icerigi/${report.id}`}>
                                      <Typography
                                        style={{ cursor: 'pointer', color: '#118DFF', fontSize: 12 }}
                                        variant="h6"
                                      >
                                        Raporu Görüntüle
                                      </Typography>
                                      <img src={Right} alt="Right" style={{ width: 15, marginLeft: 10 }} />
                                    </Link>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Grid
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      container
                      spacing={3}
                      style={{
                        height: '-webkit-fill-available',
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Grid
                          container
                          style={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 24,
                          }}
                        >
                          <div
                            style={{ width: '80px', height: '80px', backgroundColor: '#FFF3E5', borderRadius: '100%' }}
                          ></div>
                          <svg
                            style={{ height: '24px', position: 'absolute' }}
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.3333 19.3333H14V14H12.6667M14 8.66667H14.0133M26 14C26 15.5759 25.6896 17.1363 25.0866 18.5922C24.4835 20.0481 23.5996 21.371 22.4853 22.4853C21.371 23.5996 20.0481 24.4835 18.5922 25.0866C17.1363 25.6896 15.5759 26 14 26C12.4241 26 10.8637 25.6896 9.4078 25.0866C7.95189 24.4835 6.62902 23.5996 5.51472 22.4853C4.40042 21.371 3.5165 20.0481 2.91345 18.5922C2.31039 17.1363 2 15.5759 2 14C2 10.8174 3.26428 7.76516 5.51472 5.51472C7.76516 3.26428 10.8174 2 14 2C17.1826 2 20.2348 3.26428 22.4853 5.51472C24.7357 7.76516 26 10.8174 26 14Z"
                              stroke="#FF9B28"
                              stroke-width="2.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography
                          style={{
                            fontSize: 16,
                            position: 'relative',
                            fontWeight: 600,
                            textAlign: 'center',
                            color: '#FF9B28',
                          }}
                          align="center"
                        >
                          {t('Rapor bulunmadı.')}
                        </Typography>
                      </Grid>
                      <Grid item align="center" justify="center" xs={12} md={12}>
                        <Typography
                          style={{
                            fontSize: 14,
                            position: 'relative',
                            fontWeight: 'normal',
                            textAlign: 'center',
                            color: '#425466',
                            width: 280,
                          }}
                          align="center"
                        >
                          {t('Girişim henüz rapor oluşturadığı için görüntülenemiyor.Rapor talep edebilirsiniz.')}
                        </Typography>
                      </Grid>
                      <Grid item align="center" justify="center" xs={12} md={12}>
                        {}
                        <Button
                          style={{
                            color: '#27272E',
                          }}
                          variant="outlinedSecondary"
                          onClick={() => setReport(true)}
                        >
                          {t('Rapor Talep Et')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Collapse>{' '}
            </StyledTableCell>
          </StyledTableRow>
        </>
      )}

      <StyledTableRow className="rowExpandTr">
        <StyledTableCell
          padding="none"
          style={{ backgroundColor: '#F0F5FF' }}
          colSpan={8}
          onClick={() => setOpen(!open)}
          align="left"
        >
          {open ? (
            <>
              <Grid container justify="center" align="center">
                <Typography>
                  <ExpandLessIcon color="primary" style={{ cursor: 'pointer', transition: '0.5s' }} />
                </Typography>

                <Typography
                  style={{
                    fontWeight: 600,
                    color: '#003CA5',
                    fontSize: '10px',
                  }}
                >
                  KAPAT
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justify="center" align="center">
                <Typography>
                  <ExpandMoreIcon color="primary" style={{ cursor: 'pointer', transition: '0.5s' }} />
                </Typography>

                <Typography
                  style={{
                    fontWeight: 600,
                    color: '#003CA5',
                    fontSize: '10px',
                  }}
                >
                  AÇ
                </Typography>
              </Grid>
            </>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <Grid container style={{ height: 24 }}></Grid>

      {/* {open && (
        <>
          {' '}
          <>
            {' '}
            <StyledTableRow>
              {' '}
              <StyledTableCell colSpan={8}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Typography variant="h6" align="center" gutterBottom component="div">
                      History2
                    </Typography>
                    
                  </Box>
                </Collapse>{' '}
              </StyledTableCell>
            </StyledTableRow> */}
      {/* <Grid style={{ height: 60, }} direction="row" justifyContent="center" alignItems="center" container>
              <Grid item direction="row" justifyContent="center" alignItems="center" xs={12}>
                <Typography
                  variant="h6"
                  style={{ fontSize: 14, position: 'relative', fontWeight: 500, textAlign: 'center', color: '#425466' }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      position: 'absolute',
                      top: '-6px',
                      left: 5,
                      width: 22,
                      height: 22,
                      borderRadius: '100%',
                      fontSize: 14,
                      fontWeight: 600,
                      textAlign: 'center',
                      backgroundColor: '#FF9B28',
                      color: '#fff',
                    }}
                  >
                    {'1'}
                  </Typography>
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M8 10H11M8 14H11M5 10H5.01M5 14H5.01"
                      stroke="#118DFF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>              Girişimin güncel raporlarını gör

                </Typography>
              </Grid>
            </Grid>{' '} */}
      {/* </>
        </>
      )}*/}
      <Dialog
        style={{ padding: 20 }}
        fullWidth={true}
        maxWidth="xs"
        classes={{ paper: classes.myDialog }}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={report}
      >
        <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
          <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Grid>
        <DialogTitle id="simple-dialog-title">
          <Typography align="center" variant="h4">
            Rapor Talep Et
          </Typography>
        </DialogTitle>
        <Grid container justify="center" xs={12}>
          <Grid item style={{ marginBottom: 20 }} xs={10}>
            <Typography align="center" variant="body1" style={{ color: '#777777' }}>
              Girişim hakkında merak ettiğiniz diğer detaylar için rapor talep edebilirsiniz.
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            startupId: '',
            note: '',
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <>
              <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
                <Grid container justify="space-between" xs={9}>
                  <Form>
                    <Grid xs={12} justify="center">
                      <Grid item container alignItems="flex-end">
                        <Grid item container xs={12} style={{ height: 'fit-content' }}>
                          <Typography variant="h6" style={{ width: '100%' }}>
                            Talep Açıklaması
                          </Typography>
                          <FormikMultiLine
                            style={{ width: '100%', marginBottom: 20 }}
                            name="note"
                            rows={4}
                            type="text"
                            placeholder="Merak ettiğiniz ve/veya raporda görmek istediğiniz içerik hakkında açıklama"
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          justify="center"
                          style={{ marginBottom: 20, height: 'fit-content' }}
                        >
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', width: '100%' }}
                            disabled={!props.values.note}
                            onClick={() => handleSubmit(props.values)}
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setAlreadyReport(false)}
        open={alreadyReport}
      >
        <Alert severity="error">Daha önce gönderilmiş rapor talebiniz bulunmaktadır!</Alert>
      </Snackbar>
    </>
  );
}
ExpandingRow.propTypes = {
  onClose: PropTypes.func.isRequired,
  open2: PropTypes.bool.isRequired,
};
const MY_ALL_INVESTMENT_REQUESTS = gql`
  query myPortfolioInvestmentsPaginated {
    myPortfolioInvestmentsPaginated {
      data {
        id
        status
        createdAt
        investmentAmount
        startup {
          id
          slug
          title
          logo
          sector
          website
          lastValuation
          publicReports {
            id
            title
            sendingText
            sentDate
          }
        }
        currentSprint {
          id
          sprintCurrencySymbol
        }
      }
    }
  }
`;

export default function MyInvestmentPortfolio(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [lists, setLists] = React.useState();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderFollowed, setOrderFollowed] = React.useState('');
  const [sortFollowed, setSortFollowed] = React.useState([]);
  const [filter, setFilter] = React.useState('Statüsüne Göre');
  const [status, setStatus] = React.useState([]);
  const [sector, setSector] = React.useState([]);
  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const { data } = useQuery(MY_ALL_INVESTMENT_REQUESTS);
  const allMyRequests = data?.myPortfolioInvestmentsPaginated?.data;
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const openPopSort = Boolean(anchorElSort);
  const idSort = openPopSort ? 'simple-popover' : undefined;

  const optionsFollowed = [
    { name: 'A-Z', value: 'request__title__DESC' },
    { name: 'Z-A', value: 'request__title__ASC' },
  ];

  const filterOptions = ['Statüsüne Göre', 'Sektörüne Göre'];
  const sectorOptions = getSectorsArray();
  const statusOptions = [
    { title: 'Onay bekleyen', value: 'Pending' },
    { title: 'Onaylanan', value: 'Approved' },
    { title: 'Onaylanmayan', value: 'Rejected' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = (event) => {
    setFilter(event.target.value);
  };

  const handleStatus = (event) => {
    if (status.includes(event.target.value)) {
      let a = status.filter((e) => e !== event.target.value);
      setStatus(a);
    } else {
      setStatus([...status, event.target.value]);
    }
  };

  const handleSector = (event) => {
    if (sector.includes(event.target.value)) {
      let a = sector.filter((e) => e !== event.target.value);
      setSector(a);
    } else {
      setSector([...sector, event.target.value]);
    }
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const handleSortFollowed = (event) => {
    setSortFollowed(event.target.value);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
            {true ? (
              <Grid item style={{ width: '100%', padding: '0 10px' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <img src={newHome} style={{ width: 20, marginTop: 5 }} alt="eye" />
                    <Typography variant="h4">Portfoyüm</Typography>
                  </Breadcrumbs>
                  {value === 0 && (
                    <Grid item className={classes.filterArea}>
                      <>
                        <Grid className={classes.filter} onClick={handleClick} item>
                          <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                          <Hidden smDown>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {t('Filtrele')}
                            </Typography>
                            <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                          </Hidden>
                        </Grid>
                        <Popover
                          id={id}
                          open={openPop}
                          anchorEl={anchorEl}
                          style={{ marginTop: 11 }}
                          onClose={handleClose}
                          classes={{
                            paper: classes.myPaper,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Grid container xs={12}>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{
                                borderRight: '1px solid rgb(0 0 0 / 5%)',
                                maxHeight: 250,
                                overflowY: 'auto',
                              }}
                            >
                              <Typography variant="h5">{t('Filtreler')}</Typography>
                              <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup onChange={handleFilter} value={filter}>
                                  {filterOptions.map((value, i) => {
                                    // Return the element. Also pass key
                                    return (
                                      <FormControlLabel
                                        classes={{ label: classes.myLabel }}
                                        control={<Radio color="primary" value={value} />}
                                        label={value}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              className={classes.filterPadding}
                              style={{
                                borderRight: '1px solid rgb(0 0 0 / 5%)',
                                maxHeight: 250,
                                overflowY: 'auto',
                              }}
                            >
                              <Typography variant="h5">{t('Filtre Seç')}</Typography>
                              {filter === 'Statüsüne Göre' && (
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <FormGroup onChange={handleStatus} value={status}>
                                    {statusOptions.map((value, i) => {
                                      return (
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleStatus}
                                              checked={status.includes(value.value)}
                                              name="filter4-1"
                                              value={value.value}
                                            />
                                          }
                                          label={value.title}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                </FormControl>
                              )}
                              {filter === 'Sektörüne Göre' && (
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <FormGroup onChange={handleSector} value={sector}>
                                    {sectorOptions.map((value, i) => {
                                      return (
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleSector}
                                              checked={sector.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={value}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              className={classes.filterPadding}
                              style={{ maxHeight: 250, overflowY: 'auto' }}
                            >
                              <Typography style={{ marginBottom: 18 }} variant="h5">
                                {t('Filtre Seçimlerim')}
                              </Typography>
                              {status && (
                                <>
                                  {status.map((value, i) => {
                                    return (
                                      <Typography style={{ fontWeight: 'normal' }} variant="h6">
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleStatus}
                                              checked={status.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={
                                            (value === 'Pending' && 'Onay Bekleyen') ||
                                            (value === 'Approved' && 'Onaylanmış') ||
                                            (value === 'Rejected' && 'Onaylanmayan')
                                          }
                                        />
                                      </Typography>
                                    );
                                  })}
                                </>
                              )}
                            </Grid>
                            <Grid container justify="flex-end"></Grid>
                          </Grid>
                        </Popover>
                        <Grid className={classes.filter} onClick={handleClickSort} item style={{ marginLeft: 15 }}>
                          <img src={newSortingIcon} style={{ width: 15 }} alt="filter" />
                          <Hidden smDown>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {t('Sırala')}
                            </Typography>
                            <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                          </Hidden>
                        </Grid>
                        <Popover
                          id={idSort}
                          open={openPopSort}
                          anchorEl={anchorElSort}
                          style={{ marginTop: 11 }}
                          onClose={handleCloseSort}
                          classes={{
                            paper: classes.myPaper2,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              maxHeight: 250,
                              overflowY: 'auto',
                            }}
                          >
                            <Typography variant="h5">{t('Sırala')}</Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <RadioGroup onChange={handleSortFollowed} value={sortFollowed}>
                                {optionsFollowed.map((option, i) => {
                                  // Return the element. Also pass key
                                  return (
                                    <FormControlLabel
                                      classes={{ label: classes.myLabel }}
                                      control={
                                        <Radio
                                          color="primary"
                                          value={option.value}
                                          onClick={() => setOrderFollowed(option.value)}
                                        />
                                      }
                                      label={option.name}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Popover>
                      </>
                    </Grid>
                  )}
                </Grid>
                <Grid container xs={12}>
                  {allMyRequests && allMyRequests.length > 0 ? (
                    <Grid
                      container
                      style={{ marginTop: 82, marginBottom: 50 }}
                      xs={12} /*className={classes.whiteCard}*/
                    >
                      <TableContainer style={{ boxShadow: 'none' }}>
                        <Table className={classes.table} aria-label="customized table">
                          <StyledTableHead>
                            <TableRow>
                              <StyledTableCell>{t('GİRİŞİMİN ADI')}</StyledTableCell>
                              <StyledTableCell align="left">{t('SEKTÖR')}</StyledTableCell>
                              <StyledTableCell align="left">{t('WEB SİTESİ')}</StyledTableCell>
                              <StyledTableCell align="left">{t('TALEP TARİHİ')}</StyledTableCell>
                              <StyledTableCell align="center">{t('TALEP TUTARI')}</StyledTableCell>
                              <StyledTableCell align="center">{t('HİSSE ORANI')}</StyledTableCell>
                              <StyledTableCell align="center">{t('GÜNCEL DEĞERLEME')}</StyledTableCell>
                            </TableRow>
                          </StyledTableHead>
                          <Grid container style={{ height: 24 }}></Grid>
                          <TableBody className={classes.tableBody}>
                            {allMyRequests.map((request) => (
                              <ExpandingRow onClose={handleClose} {...props} request={request} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : (
                    <Grid container xs={12} className={classes.whiteCard}>
                      <Grid container justify="center">
                        <Typography style={{ margin: '25px 0' }} variant="h5">
                          {t('Yatırım Portfoyünüz Bulunamadı...')}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container justify="center">
                <Grid item container direction="column" xs={6} alignItems="center">
                  <img src={Image} alt="404" style={{ width: '40%' }} />
                  <Typography variant="h2" style={{ marginTop: '25px' }} align="center">
                    {t('Henüz oluşturulmuş bir portfoyünüz yok.')}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ marginTop: '35px', color: '#777777', marginBottom: '15px' }}
                    align="center"
                  >
                    {t('Startup Borsa üzerinden ilginizi çeken girişimlere yatırım yapabilirsiniz.')}
                  </Typography>
                  <Link to="/yatirimci-arayanlar">
                    <Button
                      style={{
                        fontWeight: 'normal',
                        fontSize: 14,
                        width: '100%',
                        marginRight: 20,
                        padding: '11.5px 30px',
                      }}
                      variant="containedPrimary"
                    >
                      {t('Yatırım Yap')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </main>
    </>
  );
}
