import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Form, Formik, Field, FieldArray  } from 'formik';
import FormikTextField from "../../Components/FormikTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import ImageCrop from "../../Components/ImageCrop";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) =>({
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            marginBottom: '30px',
            padding: 50,
            [theme.breakpoints.down('sm')]: {
                minHeight: '0',
                padding: 25,
            },
        },
        fontLg:{
            fontSize:14
        },
        baseStyle: {
            flex: 1,
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            alignItems: 'center',
            height: '80px',
            width:'80px',
            justifyContent: 'center',
            borderWidth: 2,
            borderRadius: 4,
            border: 'solid 1px #dddddd6b',
            backgroundColor: '#ffffff',
            color: '#172b4d',
            fontWeight: '500',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
    }));

    function validateLinkedinUrl(value) {
        if(value !== '' && value !== null){
            let error;
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
            return error;
        }
        return false;
    }

export default function PersonalSettings(props) {
        const classes = useStyles();
        const [files, setFiles] = React.useState([]);
        const [submitting, setSubmitting] = React.useState(false);
        const [openPhoto, setOpenPhoto] = React.useState(false);
        const [src, setSrc] = React.useState(false);
        const { t } = useTranslation();

        const MyCheckbox = ({ field, form, label, ...rest }) => {
            const { name, value: formikValue } = field;
            const { setFieldValue } = form;
          
            const handleChange = event => {
              const values = formikValue || [];
              const index = values.indexOf(rest.value);
              if (index === -1) {
                values.push(rest.value);
              } else {
                values.splice(index, 1);
              }
              setFieldValue(name, values);
            };
          
            return (
              <label>
                <input
                  type="checkbox"
                  onChange={handleChange}
                  checked={formikValue.indexOf(rest.value) !== -1}
                  {...rest}
                />
                <span>{label}</span>
              </label>
            );
          };

        const handleSubmit = async variablesAll => {
            setSubmitting(true);
            const { ...variables } = variablesAll;
            if(variables.profilePhoto !== null){
              if(typeof variables.profilePhoto === 'string' && variables.profilePhoto !== ''){
                variables.profilePhoto = null
              }
            }
            props.mySettings({ variables })
              .then(data => {
                if (data.data.mySettings) {
                    setSubmitting(false);
                    props.setIsSent(true);
                    props.refetch()
                } 
                else {
      
                }
              })
          };
          
          const onSelectFile = e => {
              const reader = new FileReader();
              reader.addEventListener('load', () => setSrc({ src: reader.result }));
          };
         
        const industry = [
          'AI / Yapay Zeka',
          'Akıllı Şehirler',
          'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
          'Atık Yönetimi',
          'Bilişim Teknolojileri',
          'Biyoteknoloji',
          'Blokzinciri',
          'Data Analiz & İş zekası',
          'Donanım',
          'e-ticaret',
          'Eğitim & Eğitim Teknolojileri',
          'Eğlence & Müzik',
          'Etkinlik',
          'Ev hizmetleri ve teknolojileri ',
          'Finans & Bankacılık',
          'Finans Teknolojileri',
          'Gayrimenkul',
          'Geri Dönüşüm',
          'Giyilebilir Teknolojiler',
          'Hukuk Teknolojileri',
          'İnsan Kaynakları & İşe Alım',
          'Kriptopara',
          'Kurumsal Hizmetler',
          'Mikro Mobilite',
          'Moda & Güzellik',
          'Medya',
          'Müşteri İlişkileri Yönetimi',
          'Nesnelerin İnterneti',
          'Oyun',
          'Perakende',
          'Regülasyon Teknolojileri',
          'Reklam & Pazarlama',
          'Robotik Teknolojileri',
          'SaaS',
          'Sağlık Hizmetleri & Sağlık Teknolojileri',
          'Seyahat & Turizm',
          'Siber Güvenlik',
          'Sigorta & Sigorta Teknolojileri',
          'Sosyal Girişimcilik',
          'Sosyal Medya',
          'Spor & Fitness',
          'Tarım & Tarım Teknolojileri',
          'Teknoloji',
          'Telekomünikasyon',
          'Temiz Teknoloji',
          'Teslimat & Lojistik',
          'Topluluk Yönetimi',
          'Tüketici Hizmetleri',
          'Ulaşım',
          'Video & Animasyon',
          'Yazılım',
          'Yenilenebilir Enerji',
          'Yiyecek & İçecek',
        ];
        const thumbsContainer = {
          display: 'flex',
          flexDirection: 'row',
          pointerEvents: 'none',
          flexWrap: 'wrap',
        };
          
          const thumb = {
            display: 'inline-flex',
            borderRadius: 4,
            border: '1px solid #eaeaea',
            width: 80,
            height: 80,
            padding: 4,
            boxSizing: 'border-box'
          };
          
          const thumbInner = {
            display: 'flex',
            minWidth: 0,
            overflow: 'hidden'
          };
          
          const img = {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit:'contain',
            borderRadius:7
          };
        return (
          <Paper className={classes.paper}>
            {Object.keys(props.detail).length > 0 && (
              <Formik
                initialValues={props.detail}
                enableReinitialize={true}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {(props) => (
                  <Form style={{ width: '100%' }}>
                    <Grid container spacing={2} xs={12}>
                      <Grid container justify="flex-end">
                        {submitting ? (
                          <Button
                            variant="containedPrimary"
                            className={classes.editButton}
                            disabled
                            style={{ fontWeight: 'normal' }}
                          >
                            <CircularProgress
                              style={{
                                color: '#ffffff',
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: 0,
                              }}
                            />{' '}
                            Yükleniyor..
                          </Button>
                        ) : (
                          <Button
                            variant="containedPrimary"
                            className={classes.editButton}
                            style={{ fontWeight: '600' }}
                            onClick={() => handleSubmit(props.values)}
                          >
                            {' '}
                            Kaydet{' '}
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2} xs={12}>
                          <Grid item>
                            <Field>
                              {({ field, form, meta }) => (
                                <Dropzone
                                  maxSize={20971520}
                                  accept={'image/*'}
                                  onDrop={(acceptedFiles) =>
                                    setFiles(
                                      acceptedFiles.map((file) =>
                                        Object.assign(file, { preview: URL.createObjectURL(file) }),
                                      ),
                                      form.setFieldValue(`profilePhoto`, acceptedFiles),
                                      setOpenPhoto(true),
                                    )
                                  }
                                >
                                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                                    <section style={{ textAlign: 'center' }}>
                                      {typeof form.values.profilePhoto !== 'string' ||
                                      form.values.profilePhoto === '' ? (
                                        <div {...getRootProps({ className: classes.baseStyle })}>
                                          <input {...getInputProps()} />
                                          {!form.values.profilePhoto ? (
                                            <Grid
                                              item
                                              container
                                              direction="column"
                                              justify="center"
                                              alignItems="center"
                                            >
                                              <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                              <Typography variant="body2" style={{ display: 'flex', color: '#777777' }}>
                                                {t('Profil Fotoğrafı')}
                                              </Typography>
                                            </Grid>
                                          ) : (
                                            <aside style={thumbsContainer}>
                                              {form.values.profilePhoto && (
                                                <div
                                                  id={form.values.profilePhoto[0].name}
                                                  style={thumb}
                                                  key={form.values.profilePhoto[0].name}
                                                >
                                                  <div style={thumbInner}>
                                                    <img
                                                      src={form.values.profilePhoto[0].preview}
                                                      style={img}
                                                      alt="file"
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </aside>
                                          )}
                                        </div>
                                      ) : (
                                        <div {...getRootProps({ className: classes.baseStyle })}>
                                          <img
                                            src={form.values.profilePhoto}
                                            alt="avatar"
                                            style={{ width: '100%', height: '100%', objectFit:'cover' }}
                                          />
                                        </div>
                                      )}
                                      {form.values.profilePhoto && (
                                        <Button
                                          variant="containedSecondary"
                                          style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5, width: 80 }}
                                          onClick={() => form.setFieldValue(`profilePhoto`, '')}
                                        >
                                          {t('Sil')}
                                        </Button>
                                      )}
                                    </section>
                                  )}
                                </Dropzone>
                              )}
                            </Field>
                          </Grid>
                          <Field>
                            {({ field, form, meta }) => (
                              <ImageCrop
                                setFieldValue={form.setFieldValue}
                                files={files}
                                profilePhoto={form.values.profilePhoto}
                                setOpenPhoto={setOpenPhoto}
                                openPhoto={openPhoto}
                              />
                            )}
                          </Field>
                          <Grid xs={12} md item>
                            <Typography variant="h6" style={{ width: 'fit-content' }}>
                              {t('Ad')}
                            </Typography>
                            <FormikTextField name="firstName" type="text" placeholder="İsim" />
                          </Grid>
                          <Grid xs={12} md item>
                            <Typography variant="h6" style={{ width: 'fit-content' }}>
                              {t('Soyad')}
                            </Typography>
                            <FormikTextField name="lastName" type="text" placeholder="Soyisim" />
                          </Grid>
                          <Grid xs={12} item>
                            <Typography variant="h6" style={{ width: 'fit-content' }}>
                              {t('Deneyimler')}
                            </Typography>
                            <FormikMultiLine
                              name="biography"
                              rows={8}
                              type="text"
                              placeholder={t(
                                'Girişimcilik dünyası ya da dışındaki deneyimlerinizden bahsedebilirsiniz.',
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid xs={12} item style={{ marginBottom: 15 }}>
                          <Typography variant="h6" style={{ width: 'fit-content' }}>
                            {t('Website')}
                          </Typography>
                          <FormikTextField
                            name="website"
                            type="text"
                            validate={validateLinkedinUrl}
                            placeholder="https://"
                          ></FormikTextField>
                        </Grid>
                        <Grid xs={12} item>
                          <Typography variant="h6" style={{ width: 'fit-content' }}>
                            LinkedIn
                          </Typography>
                          <FormikTextField
                            name="socialLinkedIn"
                            type="text"
                            validate={validateLinkedinUrl}
                            placeholder="https://"
                          ></FormikTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 30 }}>
                      <Typography variant="h6" style={{ width: 'fit-content', marginBottom: 20 }}>
                        {t('İlgilenilen sektörler')}
                      </Typography>
                      <FieldArray
                        name="interestedIndustries"
                        render={({ insert, remove, push, unshift, form }) => (
                          <Grid container xs={12} spacing={2}>
                            {industry.map((ind, index) => (
                              <Grid
                                item
                                xs={12}
                                md={3}
                                style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}
                                key={ind}
                              >
                                <Checkbox
                                  name="interestedIndustries"
                                  color="primary"
                                  type="checkbox"
                                  value={ind}
                                  checked={form.values.interestedIndustries.includes(ind)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      push(ind);
                                    } else {
                                      const idx = form.values.interestedIndustries.indexOf(ind);
                                      remove(idx);
                                    }
                                  }}
                                />
                                <Typography variant="body2" align="left">
                                  {ind}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      />
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </Paper>
        );
}