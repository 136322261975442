
import React, { useRef } from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Link} from 'react-router-dom'
import get from 'lodash/get';
import { Field, useField } from 'formik';
import moment from 'moment'
import 'moment/locale/tr'
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import {stateToHTML} from 'draft-js-export-html'
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html'
import { RichEditorReadOnly } from "../../Components/RichEditorReadOnly/RichEditorReadOnly.js";
import {Line} from 'react-chartjs-2';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  whiteCard:{
    padding:35,
    background:'#fff',
    borderRadius: 4,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    marginBottom:25
  },
  AddButton:{
    padding: '12.5px 0px',
    display:'flex',
    justifyContent:'space-around',
    minWidth:175,
    fontSize:16,
    marginRight:10,
    marginTop:15,
    fontWeight:'normal'
},
  editButton: {
    fontWeight:'normal',
    [theme.breakpoints.up('sm')]: {
      marginRight:25
    },
    [theme.breakpoints.down('md')]: {
      marginTop:30,
    }, 
  }
}));

function SelectPart(props){
  const classes = useStyles();
  const [list, setList] = React.useState();
  const [kpis, setKpis] = React.useState();
  const [listValue, setListValue] = React.useState();

    const GET_LIST = gql`
    query startupReportGetKPIList($listId: ID) {
      startupReportGetKPIList(listId:$listId){
        lists{
          id
          title
        }
        kpis{
          id
          title
        }
      }
    }
    `;

    const { loading, data } = useQuery(GET_LIST, {
        fetchPolicy:'network-only',
        variables: {listId: listValue ? listValue.id : ''},
    });
    React.useEffect(() => {
      if(!loading){
          if(data){
            setList(data.startupReportGetKPIList.lists);
            setKpis(data.startupReportGetKPIList.kpis);
          }
      }
    },
    [data, loading])

  return (
    <Grid container xs={12} spacing={3}>
      {list &&
      <Grid item xs={12} sm={6}>
        <Autocomplete
          style={{ marginTop: 10, background: '#fff' }}
          options={list}
          noOptionsText={'Sonuç Bulunamadı..'}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          value={listValue}
          onChange={(_, value) => setListValue(value)}
          renderInput={(props) => <TextField {...props} label="Liste Seçiniz" variant="outlined" />}
        ></Autocomplete>
      </Grid>
      }
      {kpis && 
      <Grid item xs={12} sm={6}>      
        <Field component={FormikKPIDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`content.${props.index}.kpi`} options={kpis}></Field>
      </Grid>
      }
    </Grid>
  );
}

const FormikKPIDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10, background:'#fff'}}
          {...props}
          {...field}
          options={props.options}
          noOptionsText={'Sonuç Bulunamadı..'}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } label="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

          {meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}


const GraphComponent = ({ con2, ...props }) => {
  const [graphDataState, setGraphData] = React.useState([]);
  const [graphDateState, setGraphDate] = React.useState([]);

  if (graphDataState.length === 0){
    let graphData = [];
    con2.kpiData.forEach((data) => {
      graphData.push(data.reached);
    });
    setGraphData(graphData);
  }

  if (graphDateState.length === 0){
    let graphDate = [];
    con2.kpiData.forEach((data) => {
      graphDate.push(moment(data.date).format('Do MMMM YYYY'));
    });  
    setGraphDate(graphDate);
  }

  return (
    <>
    {(graphDataState.length > 0 && graphDateState.length)  &&
        <Grid item xs={12} md={6} style={{padding:25}}>
          <Line
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: false,
                      borderDashOffset: 1,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      borderDash: [2, 2],
                      lineWidth: 2,
                      drawBorder: false,
                    },
                  },
                ],
              },
            }}
            data={{
              labels: graphDateState,
              datasets: [
                {
                  label: 'Alınan Yatırım Miktarı',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: '#172b4d',
                  borderColor: '#ffba00',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#172b4d',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#172b4d',
                  pointHoverBorderColor: '#172b4d',
                  pointHoverBorderWidth: 1,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: graphDataState,
                },
              ],
            }}
          />                    
        </Grid>
      }
  </>
  );
}

export default function ReportEdit(props){
    const classes = useStyles();
    const [list, setList] = React.useState();
    const [detail2, setDetail2] = React.useState();
    const [create, setCreate] = React.useState(false);

    const style = {
      border: '1px dashed #003CA5',
      padding: '30px 40px',
      marginBottom: '15px',
      backgroundColor: 'initial',
    };

    const style2 = {
      border: '1px dashed #ffba00',
      padding: '30px 40px',
      marginBottom: '15px',
      backgroundColor: 'initial',
    };
       
  const GET_DETAIL = gql`
  query startupReportDetail($id:ID!) {
    startupReportDetail(id:$id){
      id
      title
      status
      updatedAt
      sendingText
      content{
        type
        title
        text
        kpi{
            id
            title
        }
        kpiData{
          date
          reached
        }
      }
      list{
        id
        groups{
        id
        title
        }
        dataFrequency
        reminderFrequency
      }
    }
  }
  `;

  const { loading, data } = useQuery(GET_DETAIL, {
      fetchPolicy:'network-only',
      variables: {id:props.match.params.id},
  });
    React.useEffect(() => {
      if(!loading){
        if(data){
          data.startupReportDetail.content.forEach(con => {
            if (con.type === 'text') {
              if (con.text) {
                let htmlText = con.text;
                if (typeof con.text === 'string') {
                  con.text = EditorState.createWithContent(stateFromHTML(htmlText));
                }
              } else {
                con.text = new EditorState.createEmpty();
              }
            }
          })
          setList(data.startupReportDetail.list);
          setDetail2(data.startupReportDetail)
        }
      }
    },
    [loading,data])

    const handleSubmit = variables => {

      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
      });

      props.startupReportPreview({ variables })
        .then(data => {
          if (data.data.startupReportPreview) {
            variables.content.forEach(element => {
              if(element.type === 'text'){
                element.text = EditorState.createWithContent(stateFromHTML(element.text));
              }
            });
            data.data.startupReportPreview.content && data.data.startupReportPreview.content.forEach(con => {
              if (con.type === 'text') {
                if (con.text) {
                  let htmlText = con.text;
                  if (typeof con.text === 'string') {
                    con.text = EditorState.createWithContent(stateFromHTML(htmlText));
                  }
                } else {
                  con.text = new EditorState.createEmpty();
                }
              }
            })
            setDetail2(data.data.startupReportPreview);
            setCreate(false)
          } 
        })
    };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            {list && (
              <Grid
                className={'main-container'}
                xs={12}
                style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
              >
                <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 50 }}>
                  <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                    Rapor Görüntüle
                  </Typography>
                </Grid>
                {list && (
                  <Grid xs={12} container direction="column" className={classes.whiteCard}>
                    <Typography variant="h5">Rapor Özeti</Typography>
                    <Grid xs={12} container style={{ marginTop: 25 }}>
                      <Grid item xs={6} md={2}>
                        <Typography variant="h6">Gönderim Sıklığı</Typography>
                        <Typography variant="body2" style={{ marginTop: 10 }}>
                          {list.dataFrequency}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="h6">Hatırlatma Sıklığı</Typography>
                        <Typography variant="body2" style={{ marginTop: 10 }}>
                          {list.reminderFrequency}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography variant="h6">Başlangıç Tarihi</Typography>
                        <Typography variant="body2" style={{ marginTop: 10 }}>
                          {moment(list.startDate).locale('tr').format('Do MMMM YYYY')}
                        </Typography>
                      </Grid>
                      {!create && (
                        <Grid xs={12} container style={{ marginTop: 25 }}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Gönderim Metni</Typography>
                            <Typography variant="body2" style={{ marginTop: 10 }}>
                              {detail2.sendingText}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid xs={12} container style={{ marginTop: 30 }} className={classes.whiteCard}>
                  <Typography variant="h5" style={{ width: '100%', marginBottom: 20 }}>
                    Rapor İçeriği
                  </Typography>
                  {detail2 && detail2.content && (
                    <>
                      {detail2.content.map((con2) =>
                        con2.type === 'text' ? (
                          <Grid item xs={12}>
                            <Typography variant="h6">{con2.title}</Typography>
                            <RichEditorReadOnly editorState={con2.text} />
                          </Grid>
                        ) : (
                          con2 && <GraphComponent con2={con2} />
                        ),
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </main>
      </>
    );
}