import PrivacySettings from './PrivacySettings'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) 
  {
    changePassword(
      oldPassword:  $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    )
  }
`;

const DELETE_ACCOUNT = gql`
  mutation deleteMyAccount 
  {
    deleteMyAccount
  }
`;

const JoinWithMutation = compose(
  graphql(CHANGE_PASSWORD, {
  name: 'changePassword',
  }),
  graphql(DELETE_ACCOUNT, {
    name: 'deleteMyAccount',
    }),
)(PrivacySettings);
export default JoinWithMutation;