import { TextField, Typography, Grid } from '@material-ui/core';
import { useField } from 'formik';
import useFastField from './useFastField.js'
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>({
  textArea: {
    width: '100%',
    marginTop: 10,
    '& textarea': {
        lineHeight:1.33
    },
},
}))


export default function FormikMultiLine({ label, ...props }) {
  const [showPassword] = React.useState(false);
  const [field, meta] = useFastField(props);
  const classes = useStyles();
  return (
    <>
    <Grid style={{minHeight:95,width:'100%'}}>
      <TextField
        variant="outlined"
        multiline
        className={classes.textArea}
        {...field}
        {...props}
        rows={props.rows}
        error={meta.touched && meta.error}
        placeholder={props.placeholder}
        type={props.type}
      />
      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4,maxWidth:215 }}>
          {meta.error}
        </Typography>
      ) : null}
      </Grid>
    </>
  );
}
