import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import { DebounceInput } from 'react-debounce-input';
import 'moment/locale/tr';
import moment from 'moment';
import SearchIcons from '@material-ui/icons/Search';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import kpiIcon1 from '../../static/icon/kpi-icon-1.svg';
import kpiIcon2 from '../../static/icon/kpi-icon-2.svg';
import kpiIcon3 from '../../static/icon/kpi-icon-3.svg';
import selectedKpi from '../../static/icon/selected-kpi.svg';
import removeKpi from '../../static/icon/remove-kpi.svg';
import analyticsIcon from '../../static/icon/analytics-icon.svg';
import analyticsGeneral from '../../static/img/analytics-general.jpg';
import parasutIcon from '../../static/icon/parasut-icon.svg';
import arrowRight from '../../static/icon/right-icon-white.svg';
import newPerson from '../../static/icon/new-person-icon.svg';
import newPersonWhite from '../../static/icon/new-person-icon-white.svg';
import emptyCheck from '../../static/icon/empty-check-icon.svg';
import whiteCheck from '../../static/icon/white-check-icon.svg';
import newBag from '../../static/icon/new-bag.svg';
import newBagWhite from '../../static/icon/new-bag-white.svg';
import newEye from '../../static/icon/new-eye.svg';
import newEyeWhite from '../../static/icon/new-eye-white.svg';
import newPlusBlue from '../../static/icon/new-plus-blue.svg';
import sbIcon from '../../static/icon/sb-amblem.png';
import gaIcon from '../../static/icon/ga-amblem.svg';
import appBarBottom from '../../static/icon/app-bar-bottom.svg';
import CloudIcon from '../../static/icon/cloud-icon.svg';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Form, Formik, Field, useField, useFormikContext } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormikTextField from '../../Components/FormikShortTextField';
import FormikDropdown from '../../Components/FormikDropdown';
import list from '../../static/icon/white-list.svg';
import chart from '../../static/icon/white-graph.svg';
import FormikSelect from '../../Components/FormikSelect';
import { reportSchemaForKpiList } from '../../yupSchemas';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import tr from 'date-fns/locale/tr';
import { getCurrentPeriod } from '../../helpers';
import { Line } from 'react-chartjs-2';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newCross from '../../static/icon/new-cross.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import process from 'process';
registerLocale('tr', tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  truncate: {
    width: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  whiteGrid: {
    width: '100%',
    background: '#fff',
    padding: '12px 0',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 16,
    marginBottom: 24,
  },
  multiSelect2: {
    marginBottom: 15,
    '& div': {
      background: 'none!important',
      paddingTop: '0!important',
      '& input': {
        paddingTop: '8px!important',
      },
    },
  },
  TabsGeneral: {
    background: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  tableTitle: {
    fontSize: 12,
    color: '#8492A6',
  },
  analyticsCard: {
    background: '#FFFFFF',
    boxShadow: '0px 16px 16px rgba(50, 50, 71, 0.05), 0px 0px 1px #379FFF, 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 8,
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: '0.5s',
  },
  analyticsCenterIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FAFBFD',
    padding: 16,
    borderRadius: '50%',
    width: 48,
    height: 48,
  },
  Debounce: {
    width: '100%',
    fontSize: 16,
    height: '50px',
    color: '#8898aa',
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    outline: 'none',
    border: '1px solid #c8c8ca',
    padding: 10,
    borderRadius: 4,
    background: 'inherit',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  whiteCardTab: {
    background: '#ffffff',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    padding: '32px 16px',
  },
  textColorP: {
    color: '#fff!important',
    borderRadius: 4,
    marginRight: 20,
    '&$selected': {
      color: '#ffffff!important',
      background: '#fff',
    },
  },
  appBarGrid: {
    width: '88%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  flexContainerTab: {
    borderBottom: 'none',
  },
  bottomBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 999,
    background: '#fff',
    padding: '8px 70px',
    boxShadow: '0px -4px 4px #F7F7FC;',
  },
  search2: {
    position: 'relative',
    width: '100%',
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    zIndex: 99999,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#7c7c7c',
  },
  wrapper: {
    flexDirection: 'row',
    fontSize: 12,
  },
  selectedApp: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 20,
  },
  photoCard: {
    background: '#FAFBFD',
    borderRadius: '50%',
    padding: '46px',
    marginTop: '46px',
    marginBottom: 40,
  },
  cardGrid: {
    marginTop: 35,
  },
  defaultSet: {
    padding: 16,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 8,
    cursor: 'pointer',
    minHeight: 180,
  },
  myLabel: {
    fontSize: 14,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
  tabPaper: {
    marginTop: 24,
  },
  borderRight: {
    [theme.breakpoints.up('md')]: {
      borderRight: 'solid 1px #c3d1e0',
    },
  },
  checkBackground: {
    '&:hover': { backgroundColor: '#0055ff1f' },
  },
  kpiCat: {
    padding: '10px 8px',
    paddingRight: 36,
    cursor: 'pointer',
  },
  tabRoot: {
    maxHeight: 35,
    padding: '9px 20px',
    background: '#CBD5E0',
    color: '#fff',
    fontWeight: 500,
    borderRadius: 24,
    '&$selected': {
      color: '#fff',
      background: '#118DFF',
    },
  },
  selected: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  searchBox: {
    width: '100%',
    background: '#ffffff',
    borderRadius: 2,
    marginTop: 5,
    boxShadow: '1px 1px 2px 1px rgba(201,201,201,1)',
    maxHeight: 130,
    overflowY: 'auto',
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop: 30,
    '& textarea': {
      padding: 16,
      paddingRight: 90,
      fontSize: 13,
      lineHeight: 1,
    },
  },
  kpiImg: {
    objectFit: 'contain',
    width: '100%',
    maxWidth: 300,
  },
  table: {
    boxShadow: 'none',
  },
  mobileMargin: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  whiteCard: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '16px',
    padding: 34,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 520,
  },
  inputInput: {
    padding: '26px 56px 26px 0',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    height: 10,
    fontSize: 14,
    fontFamily: 'inherit',
    border: '1px solid #E3EBF2',
    outline: 'none',
    borderRadius: 6,
    width: '100%',
    backgroundColor: '#fff',
    color: '#777777',
  },
  wrap: {
    flexDirection: 'row',
  },
  graphGrid: {
    [theme.breakpoints.up('md')]: {
      padding: '0px 80px 40px 80px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  graphCard: {
    padding: 16,
    border: '1px dashed #CBD5E0',
    borderRadius: 16,
  },
  graphCardFull: {
    padding: 16,
    background: '#FAFBFD',
    borderRadius: 16,
  },
  cloudGrid: {
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: '#F0F5FF',
    marginTop: -12,
  },
  graphButton: {
    borderRadius: 6,
    padding: '12px 20px',
    marginTop: 16,
    marginBottom: 8,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
          autocomplete="off"
          style={{
            width: '100%',
            padding: '13px',
            borderRadius: 3,
            border: '1px solid rgb(221 221 221 / 56%)',
            boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
            marginTop: 10,
          }}
          type="text"
          placeholder={'mm'}
        />
      }
      maxDate={getCurrentPeriod(props.dataFrequency).date}
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

function RemoveDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetchKPI, kpiId, categoryId, listId, handleClose } = props;

  const handleSubmit = () => {
    if (kpiId) {
      props
        .kpiDelete({ variables: { kpiId: kpiId } })
        .then((data) => {
          if (data.data.kpiDelete) {
            refetchKPI();
          }
        })
        .then(() => {})
        .catch((error) => {});
    }
    if (categoryId) {
      props
        .kpiCategoryDelete({ variables: { categoryId: categoryId } })
        .then((data) => {
          if (data.data.kpiCategoryDelete) {
            refetchKPI();
          }
        })
        .then(() => {})
        .catch((error) => {});
    }
    if (listId) {
      props.deleteList(listId);
      onClose(null);
    }
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Sil
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          {listId && (
            <Typography align="center" variant="body1" style={{ color: '#777777' }}>
              Bu listeyi silmek istediğinizden emin misiniz?{' '}
            </Typography>
          )}
          {categoryId && (
            <Typography align="center" variant="body1" style={{ color: '#777777' }}>
              Bu kategoriyi silmek istediğinizden emin misiniz?{' '}
            </Typography>
          )}
          {kpiId && (
            <Typography align="center" variant="body1" style={{ color: '#777777' }}>
              Bu KPI'yı silmek istediğinizden emin misiniz?
            </Typography>
          )}
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }}
                onClick={() => {
                  onClose(null);
                  handleClose();
                }}
                variant="outlinedSecondary"
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  width: '100%',
                  marginTop: 20,
                  background: '#ff5757',
                  '&:hover': { background: '#ff5757' },
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
              >
                Sil
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

RemoveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditListDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetchKPI, listId, title, handleClose } = props;

  const handleSubmit = (variables) => {
    props
      .kpiListChangeName({ variables })
      .then((data) => {
        if (data.data.kpiListChangeName) {
          refetchKPI();
          handleClose();
          props.setListChanged(true);
          onClose(null);
        }
      })
      .then(() => {})
      .catch((error) => {});
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={() => onClose(null)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Liste Düzenle
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              listId: listId,
              title: title,
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12}>
                      <Typography variant="h6" style={{ width: '100%' }}>
                        Liste Adı
                      </Typography>
                      <FormikTextField
                        name="title"
                        style={{ width: '100%', marginBottom: 20 }}
                        type="text"
                        placeholder="Grup Adı"
                      />
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        onClick={() => handleSubmit(props.values)}
                      >
                        Düzenle
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditListDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function KPIDialog(props) {
  const classes = useStyles();
  const { onClose, open, selected, setSelected, selectedGA, setSelectedGA, edit, setEdit } = props;
  const [search, setSearch] = React.useState('');
  const [searchGA, setSearchGA] = React.useState('');
  const [catId, setCatId] = React.useState(1);
  const [catIdGA, setCatIdGA] = React.useState();
  const [defaultList, setDefaultList] = React.useState([]);
  const [defaultListItem, setDefaultListItem] = React.useState();
  const [suggest, setSuggest] = React.useState(false);
  const [cat, setCat] = React.useState([]);
  const [catGA, setCatGA] = React.useState([]);
  const [detail, setDetail] = React.useState([]);
  const [detailGA, setDetailGA] = React.useState([]);
  const [isSuggest, setIsSuggest] = React.useState(false);
  const [valueText, setValueText] = React.useState('');
  const [valueTab, setValueTab] = React.useState(props.dialogPage);
  const [gaAccount, setGaAccount] = React.useState('');
  const [gaProperty, setGaProperty] = React.useState('');
  const [gaView, setGaView] = React.useState(null);
  const [gaListId, setGaListId] = React.useState();
  const [gaViewsList, setGAViewsList] = React.useState([]);
  const [gaPropertyList, setGAPropertyList] = React.useState([]);
  const [error, setError] = React.useState('');
  const [serverInfo, setServerInfo] = React.useState(false);
  const [gaPage, setGaPage] = React.useState(1);
  const { t } = useTranslation();

  const handleChangeText = (event) => {
    setValueText(event.target.value);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const DEFAULT_KPI = gql`
    query getKPIPerformanceSets {
      getKPIPerformanceSets {
        count
        pageCount
        data {
          id
          title
          description
          subtitle
        }
      }
    }
  `;

  const { loading, data, refetch } = useQuery(DEFAULT_KPI, {
    fetchPolicy: 'network-only',
    variables: {},
  });

  const KPI_CAT_GA = gql`
    query getKPIGACategories {
      getKPIGACategories {
        id
        title
      }
    }
  `;

  const { loading: loadingCatGA, data: dataCatGA, refetch: refetchCatGA } = useQuery(KPI_CAT_GA, {
    fetchPolicy: 'network-only',
    variables: {},
  });

  const KPI_DETAIL_GA = gql`
    query getKPIGALists($categoryId: ID!) {
      getKPIGALists(categoryId: $categoryId) {
        id
        title
        description
        category {
          id
          title
        }
        dataFrequency
        dataFormat
      }
    }
  `;
  const { loading: loadingDetailGA, data: dataDetailGA, refetch: refetchDetailGA } = useQuery(KPI_DETAIL_GA, {
    fetchPolicy: 'network-only',
    variables: { categoryId: catIdGA },
  });

  const KPI_CAT = gql`
    query getKPICategories {
      getKPICategories {
        id
        title
      }
    }
  `;

  const { loading: loadingCat, data: dataCat, refetch: refetchCat } = useQuery(KPI_CAT, {
    fetchPolicy: 'network-only',
    variables: {},
  });

  const KPI_DETAIL = gql`
    query getKPILists($categoryId: ID!) {
      getKPILists(categoryId: $categoryId) {
        id
        title
        description
        category {
          id
          title
        }
        dataFrequency
        dataFormat
      }
    }
  `;
  const { loading: loadingDetail, data: dataDetail, refetch: refetchDetail } = useQuery(KPI_DETAIL, {
    fetchPolicy: 'network-only',
    variables: { categoryId: catId },
  });

  const SEARCH_KPI = gql`
    query getKPIListsSearch($search: String) {
      getKPIListsSearch(search: $search) {
        id
        title
      }
    }
  `;
  const [getData, { loading: loadingSearch, data: dataSearch }] = useLazyQuery(SEARCH_KPI, {
    fetchPolicy: 'network-only',
    variables: { search: search },
  });

  const SEARCH_KPI_GA = gql`
  query getKPIGAListsSearch($search: String) {
    getKPIGAListsSearch(search: $search) {
      id
      title
    }
  }
`;
const [getDataGA, { loading: loadingSearchGA, data: dataSearchGA }] = useLazyQuery(SEARCH_KPI_GA, {
  fetchPolicy: 'network-only',
  variables: { search: searchGA },
});

  const EDIT_LIST = gql`
    query getStartupKPIListsKpis($listId: ID!) {
      getStartupKPIListsKpis(listId: $listId) {
        id
        title
      }
    }
  `;
  const { loading: loadingEdit, data: dataEdit, refetch: refetchEdit } = useQuery(EDIT_LIST, {
    fetchPolicy: 'network-only',
    variables: { listId: props.listKPIId },
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setDefaultList(data.getKPIPerformanceSets.data);
      }
    }
    if (!loadingCat) {
      if (dataCat) {
        setCat(dataCat.getKPICategories);
      }
    }
    if (!loadingCatGA) {
      if (dataCatGA) {
        setCatGA(dataCatGA.getKPIGACategories);
        if (dataCatGA.getKPIGACategories && dataCatGA.getKPIGACategories.length > 0 && !catIdGA) {
          setCatIdGA(dataCatGA.getKPIGACategories[0].id);
        }
      }
    }
    if (!loadingEdit) {
      if (dataEdit) {
        if (edit) {
          if (selected.length < 1) {
            setSelected(dataEdit.getStartupKPIListsKpis);
          }
        }
      }
    }
    if (!loadingDetail) {
      if (dataDetail) {
        setDetail(dataDetail.getKPILists);
      }
    }
    if (!loadingDetailGA) {
      if (dataDetailGA) {
        setDetailGA(dataDetailGA.getKPIGALists);
      }
    }
  }, [
    data,
    loading,
    loadingCat,
    dataCat,
    loadingDetail,
    dataDetail,
    dataEdit,
    loadingEdit,
    edit,
    dataCatGA,
    loadingCatGA,
    dataDetailGA,
    loadingDetailGA,
  ]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    getData();
  };

  const handleSearchGA = (event) => {
    setSearchGA(event.target.value);
    getDataGA();
  };

  const handleClose = () => {
    onClose(null);
  };

  function getOptionsForProperty(account) {
    setGaAccount(account);
    setGaProperty('');
    setGaView(null);
    setGAPropertyList();
    setGAViewsList();
    for (let x of props.gA) {
      if (x.name === account) {
        setGAPropertyList(x.property);
      }
    }
  }

  function getOptionsForViews(property) {
    setGaProperty(property);
    setGaView(null);
    setGAViewsList();
    for (let x of gaPropertyList) {
      if (x.name === property) {
        setGAViewsList(x.view);
      }
    }
  }

  const sendNote = (value) => {
    if (value !== '') {
      props
        .kpiSuggest({ variables: { text: value } })
        .then((data) => {
          if (data.data.kpiSuggest) {
            setValueText('');
            setSearch('');
            setSuggest(false);
            setIsSuggest(true);
          }
        })
        .then(() => {})
        .catch((error) => {});
    }
  };

  const sendDefault = (value) => {
    if (value !== '') {
      props
        .kpiCreate({ variables: { preparedId: value } })
        .then((data) => {
          if (data.data.kpiCreate) {
            props.refetchKPI();
            onClose();
            props.setIsAdded(true);
            // if (window.location.href.indexOf('dashboard.startupborsa') > -1 && window.userGuiding) {
            //   window.userGuiding.previewGuide(23706, {
            //     checkHistory: true,
            //   });
            // }
          }
        })
        .then(() => {})
        .catch((error) => {});
    }
  };

  const sendList = (value) => {
    if (value !== []) {
      props
        .kpiCreate({ variables: { listIds: value } })
        .then((data) => {
          if (data.data.kpiCreate) {
            props.refetchKPI();
            onClose();
            props.setIsAdded(true);
            setSelected([]);
            // if (window.location.href.indexOf('dashboard.startupborsa') > -1 && window.userGuiding) {
            //   window.userGuiding.previewGuide(23706, {
            //     checkHistory: true,
            //   });
            // }
          }
        })
        .then(() => {})
        .catch((error) => {});
    }
  };

  const editList = (value) => {
    if (value !== []) {
      props
        .kpiListEdit({ variables: { listId: props.listKPIId, kpiIds: value } })
        .then((data) => {
          if (data.data.kpiListEdit) {
            refetchEdit();
            props.refetchKPI();
            onClose();
            props.setListEdited(true);
            props.setIsAdded(true);
            setSelected([]);
          }
        })
        .then(() => {})
        .catch((error) => {});
    }
  };

  const handleSubmitGa = () => {
    let ids = [];
    selectedGA.map(selected => {
      ids.push(selected.id)
    });

    props
      .kpiGoogleAnalytics({ variables: { viewId: gaView, listId: gaListId, kpiGoogleIds:ids  } })
      .then((data) => {
        if (data.data.kpiGoogleAnalytics) {
          props.refetchKPI();
          onClose();
        }
      })
      .catch(async (error) => {
        if (error.message === 'GraphQL error: No refresh token is set.') {
          setError('Google Oturum süresi dolmuş, tekrar login olmaya yönlendiriliyorsunuz.');
          setServerInfo(true);
          await new Promise((r) => setTimeout(r, 3000));
          window.location.assign('/auth/google/?listId=' + gaListId);
        } else {
          setError(error.message);
          setServerInfo(true);
        }
      });
  };

  return (
    <>
      <Grid container justify="center" xs={12} style={{ padding: 12 }}>
        <Grid container xs={12} justify="center">
          <AppBar className={classes.tabPaper} position="static" style={{ zIndex: 1 }} color="default">
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className={classes.TabsGeneral}
            >
              {!edit && <Tab label="Hazır Performans Setlerini İncele" {...a11yProps(0)} style={{ fontWeight: 600 }} />}
              <Tab label="Performans Seti Oluştur" {...a11yProps(1)} style={{ fontWeight: 600 }} />
              <Tab
                classes={{ wrapper: classes.wrapper }}
                label={
                  <>
                    <img src={analyticsIcon} alt="analytics-icon" style={{ marginRight: 8 }} />
                    Google Analytics
                  </>
                }
                {...a11yProps(2)}
                style={{ fontWeight: 600 }}
              />
              <Tab
                classes={{ wrapper: classes.wrapper }}
                disabled
                label={
                  <>
                    <img src={parasutIcon} alt="parasut-icon" style={{ marginRight: 8 }} />
                    <Typography variant="h6" style={{ color: '#A0AEC0' }}>
                      Paraşüt
                    </Typography>
                    <Typography variant="h6" style={{ color: '#118DFF', marginLeft: 4, fontSize: 8, marginTop: -8 }}>
                      Yakında
                    </Typography>
                  </>
                }
                {...a11yProps(3)}
                style={{ fontWeight: 600 }}
              />
            </Tabs>
          </AppBar>
        </Grid>
        {!edit && (
          <TabPanel style={{ width: '100%' }} value={valueTab} index={0}>
            <Grid item className={classes.whiteCardTab}>
              <Grid container spacing={3}>
                {defaultList && (
                  <>
                    {defaultList.map((defaultList) => (
                      <Grid item xs={12} md={4}>
                        <Grid onClick={() => setDefaultListItem(defaultList.id)} item className={classes.defaultSet}>
                          <Grid container justify="space-between">
                            <Typography color="secondary" variant="body2" style={{ color: '#ffba00', fontSize: 12 }}>
                              # {defaultList.subtitle}
                            </Typography>
                            {defaultList.id === defaultListItem ? (
                              <CheckCircleIcon style={{ color: '#2893F6', transition: '0.5s', fontSize: 20 }} />
                            ) : (
                              <img src={emptyCheck} alt="empty-check" />
                            )}
                          </Grid>
                          <Typography color="primary" variant="h5" style={{ marginTop: '4px' }}>
                            {defaultList.title}
                          </Typography>
                          <Typography variant="body2" style={{ marginTop: '10px', fontSize: 12 }}>
                            {defaultList.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container justify="flex-end" className={classes.bottomBar}>
              <Button
                variant="outlinedSecondary"
                style={{
                  fontWeight: 'normal',
                  width: 95,
                  fontSize: 14,
                  marginRight: 15,
                  borderRadius: 8,
                  color: '#003CA5',
                  borderColor: '#003CA5',
                }}
                onClick={() => onClose(null)}
              >
                {t('Vazgeç')}
              </Button>
              <Button
                variant="containedPrimary"
                style={{ fontWeight: 'normal', width: 95, fontSize: 14, borderRadius: 8 }}
                onClick={() => sendDefault(defaultListItem)}
                disabled={!defaultListItem}
              >
                {edit ? t('Düzenle') : t('Oluştur')}
              </Button>
            </Grid>
          </TabPanel>
        )}
        <TabPanel style={{ width: '100%' }} value={valueTab} index={!edit ? 1 : 0}>
          <Grid item className={classes.whiteCardTab}>
            <>
              {!edit ? (
                <>
                  {!suggest ? (
                    <>
                      <Grid container justify="flex-end">
                        <Grid container xs={12} md={5}>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid container justify="flex-end">
                              <div className={classes.search2}>
                                <div className={classes.searchIcon}>
                                  <SearchIcons />
                                </div>
                                <DebounceInput
                                  class="inputTypeSearch"
                                  name="search"
                                  value={search}
                                  placeholder={t('KPI Ara')}
                                  className={classes.inputInput}
                                  minLength={2}
                                  autoComplete="off"
                                  debounceTimeout={800}
                                  onChange={handleSearch}
                                />
                              </div>
                            </Grid>
                            {search && dataSearch && (
                              <Grid
                                className={classes.searchBox}
                                style={{ position: 'absolute', maxWidth: 300, width: '100%', marginTop: 55 }}
                              >
                                <List component="nav" aria-label="secondary mailbox folders">
                                  {dataSearch.getKPIListsSearch.map((searchResult) => (
                                    <Grid>
                                      {selected.some((e) => e.id === searchResult.id) ? (
                                        <ListItem
                                          onClick={() => setSelected(selected.filter((x) => x.id !== searchResult.id))}
                                          button
                                          style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                                        >
                                          <Grid container>
                                            <Typography variant="body1" color="primary">
                                              {searchResult.title}
                                            </Typography>
                                          </Grid>
                                        </ListItem>
                                      ) : (
                                        <ListItem
                                          onClick={() => setSelected([...selected, searchResult])}
                                          button
                                          style={{ minHeight: 50 }}
                                        >
                                          <Grid container>
                                            <Typography variant="body1" color="primary">
                                              {searchResult.title}
                                            </Typography>
                                          </Grid>
                                        </ListItem>
                                      )}
                                    </Grid>
                                  ))}
                                  {dataSearch.getKPIListsSearch.length === 0 && (
                                    <ListItem>
                                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography align="center" variant="body1">
                                          {t("Aradığın KPI'yı bulamadık.")}
                                        </Typography>
                                        <Button
                                          variant="containedPrimary"
                                          onClick={() => setSuggest(true)}
                                          style={{
                                            fontWeight: 'normal',
                                            marginTop: 15,
                                            padding: '10.5px 50px',
                                            fontSize: 16,
                                          }}
                                        >
                                          {t('KPI Önerisi')}
                                        </Button>
                                      </Grid>
                                    </ListItem>
                                  )}
                                </List>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: 35 }}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{
                            borderRight: '2px solid #EDF2F7',
                            marginBottom: 20,
                          }}
                        >
                          <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 8 }}>
                            {t('Kategori')}
                          </Typography>
                          <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                            {cat.map((cat) => (
                              <>
                                {cat.id === catId ? (
                                  <Grid
                                    container
                                    justify="space-between"
                                    className={classes.kpiCat}
                                    onClick={() => setCatId(cat.id)}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                    >
                                      {cat.title}
                                    </Typography>
                                    <ArrowForwardIosIcon style={{ fontSize: 14, color: '#118DFF' }} />
                                    <Grid container>
                                      <hr
                                        size="1"
                                        style={{
                                          border: '1px solid #EDF2F7',
                                          marginTop: 16,
                                          marginBottom: 0,
                                          width: '100%',
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    justify="space-between"
                                    className={classes.kpiCat}
                                    onClick={() => setCatId(cat.id)}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                    >
                                      {cat.title}
                                    </Typography>
                                    <ArrowForwardIosIcon style={{ fontSize: 14, color: '#718096' }} />
                                    <Grid container>
                                      <hr
                                        size="1"
                                        style={{
                                          border: '1px solid #EDF2F7',
                                          marginTop: 16,
                                          marginBottom: 0,
                                          width: '100%',
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{
                            borderRight: '2px solid #EDF2F7',
                            marginBottom: 20,
                          }}
                        >
                          <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                            {t('KPI Listesi')}
                          </Typography>
                          <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                            {detail.map((detail) => (
                              <>
                                {selected.some((e) => e.id === detail.id) ? (
                                  <Grid
                                    container
                                    justify="space-between"
                                    className={classes.kpiCat}
                                    style={{ paddingLeft: 24 }}
                                    onClick={() => setSelected(selected.filter((x) => x.id !== detail.id))}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                    >
                                      {detail.title}
                                    </Typography>
                                    <img
                                      src={selectedKpi}
                                      alt="selected-kpi"
                                      style={{ marginBottom: 12, marginRight: 4 }}
                                    />
                                    <Grid container>
                                      <hr
                                        size="1"
                                        style={{
                                          border: '1px solid #EDF2F7',
                                          marginTop: 10,
                                          marginBottom: 0,
                                          width: '100%',
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    justify="space-between"
                                    className={classes.kpiCat}
                                    style={{ paddingLeft: 24 }}
                                    onClick={() => setSelected([...selected, detail])}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                    >
                                      {detail.title}
                                    </Typography>
                                    <AddCircleOutlineIcon style={{ fontSize: 24, color: '#425466' }} />
                                    <Grid container>
                                      <hr
                                        size="1"
                                        style={{
                                          border: '1px solid #EDF2F7',
                                          marginTop: 10,
                                          marginBottom: 0,
                                          width: '100%',
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{
                            marginBottom: 20,
                          }}
                        >
                          <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                            {t('Seçili KPI’lar')}
                          </Typography>
                          <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                            {selected.map((select) => (
                              <Grid
                                container
                                justify="space-between"
                                className={classes.selected}
                                onClick={() => setSelected(selected.filter((x) => x.id !== select.id))}
                              >
                                <Typography variant="body2" style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}>
                                  {select.title}
                                </Typography>
                                <img src={removeKpi} alt="remove-kpi" style={{ marginBottom: 12, marginRight: 4 }} />
                                <Grid container>
                                  <hr
                                    size="1"
                                    style={{
                                      border: '1px solid #EDF2F7',
                                      marginTop: 2,
                                      marginBottom: 0,
                                      width: '100%',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid container justify="flex-end" className={classes.bottomBar}>
                              <Button
                                variant="outlinedSecondary"
                                style={{
                                  fontWeight: 'normal',
                                  width: 95,
                                  fontSize: 14,
                                  marginRight: 15,
                                  borderRadius: 8,
                                  color: '#003CA5',
                                  borderColor: '#003CA5',
                                }}
                                onClick={() => onClose(null)}
                              >
                                {t('Vazgeç')}
                              </Button>
                              <Button
                                variant="containedPrimary"
                                style={{ fontWeight: 'normal', width: 95, fontSize: 14, borderRadius: 8 }}
                                onClick={() => sendList(selected.map((a) => a.id))}
                              >
                                {t('Oluştur')}
                              </Button>
                            </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid container justify="center">
                      <Grid item xs={5}>
                        <TextField
                          id="standard-multiline-flexible"
                          multiline
                          placeholder={'KPI önerinizi yazınız…'}
                          className={classes.textArea}
                          rows={8}
                          value={valueText}
                          onChange={handleChangeText}
                        />
                      </Grid>
                      <Grid container justify="center">
                        <Button
                          variant="outlinedSecondary"
                          style={{
                            fontWeight: 'normal',
                            marginTop: 35,
                            padding: '10.5px 50px',
                            fontSize: 16,
                            marginRight: 15,
                          }}
                          onClick={() => setSuggest(false)}
                        >
                          {t('Geri')}
                        </Button>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
                          onClick={() => sendNote(valueText)}
                        >
                          {t('Öneriyi Gönder')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                    <>
                      {!suggest ? (
                        <>
                          <Grid container justify="flex-end">
                            <Grid container xs={12} md={5}>
                              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Grid container justify="flex-end">
                                  <div className={classes.search2}>
                                    <div className={classes.searchIcon}>
                                      <SearchIcons />
                                    </div>
                                    <DebounceInput
                                      class="inputTypeSearch"
                                      name="search"
                                      value={search}
                                      placeholder={t('KPI Ara')}
                                      className={classes.inputInput}
                                      minLength={2}
                                      autoComplete="off"
                                      debounceTimeout={800}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                </Grid>
                                {search && dataSearch && (
                                  <Grid
                                    className={classes.searchBox}
                                    style={{ position: 'absolute', maxWidth: 300, width: '100%', marginTop: 55 }}
                                  >
                                    <List component="nav" aria-label="secondary mailbox folders">
                                      {dataSearch.getKPIListsSearch.map((searchResult) => (
                                        <Grid>
                                          {selected.some((e) => e.id === searchResult.id) ? (
                                            <ListItem
                                              onClick={() =>
                                                setSelected(selected.filter((x) => x.id !== searchResult.id))
                                              }
                                              button
                                              style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                                            >
                                              <Grid container>
                                                <Typography variant="body1" color="primary">
                                                  {searchResult.title}
                                                </Typography>
                                              </Grid>
                                            </ListItem>
                                          ) : (
                                            <ListItem
                                              onClick={() => setSelected([...selected, searchResult])}
                                              button
                                              style={{ minHeight: 50 }}
                                            >
                                              <Grid container>
                                                <Typography variant="body1" color="primary">
                                                  {searchResult.title}
                                                </Typography>
                                              </Grid>
                                            </ListItem>
                                          )}
                                        </Grid>
                                      ))}
                                      {dataSearch.getKPIListsSearch.length === 0 && (
                                        <ListItem>
                                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Typography align="center" variant="body1">
                                              {t("Aradığın KPI'yı bulamadık.")}
                                            </Typography>
                                            <Button
                                              variant="containedPrimary"
                                              onClick={() => setSuggest(true)}
                                              style={{
                                                fontWeight: 'normal',
                                                marginTop: 15,
                                                padding: '10.5px 50px',
                                                fontSize: 16,
                                              }}
                                            >
                                              {t('KPI Önerisi')}
                                            </Button>
                                          </Grid>
                                        </ListItem>
                                      )}
                                    </List>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: 35 }}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{
                                borderRight: '2px solid #EDF2F7',
                                marginBottom: 20,
                              }}
                            >
                              <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 8 }}>
                                {t('Kategori')}
                              </Typography>
                              <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                                {cat.map((cat) => (
                                  <>
                                    {cat.id === catId ? (
                                      <Grid
                                        container
                                        justify="space-between"
                                        className={classes.kpiCat}
                                        onClick={() => setCatId(cat.id)}
                                      >
                                        <Typography
                                          variant="body2"
                                          style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                        >
                                          {cat.title}
                                        </Typography>
                                        <ArrowForwardIosIcon style={{ fontSize: 14, color: '#118DFF' }} />
                                        <Grid container>
                                          <hr
                                            size="1"
                                            style={{
                                              border: '1px solid #EDF2F7',
                                              marginTop: 16,
                                              marginBottom: 0,
                                              width: '100%',
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        container
                                        justify="space-between"
                                        className={classes.kpiCat}
                                        onClick={() => setCatId(cat.id)}
                                      >
                                        <Typography
                                          variant="body2"
                                          style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                        >
                                          {cat.title}
                                        </Typography>
                                        <ArrowForwardIosIcon style={{ fontSize: 14, color: '#718096' }} />
                                        <Grid container>
                                          <hr
                                            size="1"
                                            style={{
                                              border: '1px solid #EDF2F7',
                                              marginTop: 16,
                                              marginBottom: 0,
                                              width: '100%',
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </>
                                ))}
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{
                                borderRight: '2px solid #EDF2F7',
                                marginBottom: 20,
                              }}
                            >
                              <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                                {t('KPI Listesi')}
                              </Typography>
                              <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                                {detail.map((detail) => (
                                  <>
                                    {selected.some((e) => e.id === detail.id) ? (
                                      <Grid
                                        container
                                        justify="space-between"
                                        className={classes.kpiCat}
                                        style={{ paddingLeft: 24 }}
                                        onClick={() => setSelected(selected.filter((x) => x.id !== detail.id))}
                                      >
                                        <Typography
                                          variant="body2"
                                          style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                        >
                                          {detail.title}
                                        </Typography>
                                        <img
                                          src={selectedKpi}
                                          alt="selected-kpi"
                                          style={{ marginBottom: 12, marginRight: 4 }}
                                        />
                                        <Grid container>
                                          <hr
                                            size="1"
                                            style={{
                                              border: '1px solid #EDF2F7',
                                              marginTop: 10,
                                              marginBottom: 0,
                                              width: '100%',
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        container
                                        justify="space-between"
                                        className={classes.kpiCat}
                                        style={{ paddingLeft: 24 }}
                                        onClick={() => setSelected([...selected, detail])}
                                      >
                                        <Typography
                                          variant="body2"
                                          style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                        >
                                          {detail.title}
                                        </Typography>
                                        <AddCircleOutlineIcon style={{ fontSize: 24, color: '#425466' }} />
                                        <Grid container>
                                          <hr
                                            size="1"
                                            style={{
                                              border: '1px solid #EDF2F7',
                                              marginTop: 10,
                                              marginBottom: 0,
                                              width: '100%',
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </>
                                ))}
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{
                                marginBottom: 20,
                              }}
                            >
                              <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                                {t('Seçili KPI’lar')}
                              </Typography>
                              <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                                {selected.map((select) => (
                                  <Grid
                                    container
                                    justify="space-between"
                                    className={classes.selected}
                                    onClick={() => setSelected(selected.filter((x) => x.id !== select.id))}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                    >
                                      {select.title}
                                    </Typography>
                                    <img
                                      src={removeKpi}
                                      alt="remove-kpi"
                                      style={{ marginBottom: 12, marginRight: 4 }}
                                    />
                                    <Grid container>
                                      <hr
                                        size="1"
                                        style={{
                                          border: '1px solid #EDF2F7',
                                          marginTop: 2,
                                          marginBottom: 0,
                                          width: '100%',
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                            <Grid container justify="flex-end" className={classes.bottomBar}>
                              <Button
                                variant="outlinedSecondary"
                                style={{
                                  fontWeight: 'normal',
                                  width: 95,
                                  fontSize: 14,
                                  marginRight: 15,
                                  borderRadius: 8,
                                  color: '#003CA5',
                                  borderColor: '#003CA5',
                                }}
                                onClick={() => onClose(null)}
                              >
                                {t('Vazgeç')}
                              </Button>
                              <Button
                                variant="containedPrimary"
                                style={{ fontWeight: 'normal', width: 95, fontSize: 14, borderRadius: 8 }}
                                onClick={() => editList(selected.map((a) => a.id))}
                              >
                                {t('Düzenle')}
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid container justify="center">
                          <Grid item xs={5}>
                            <TextField
                              id="standard-multiline-flexible"
                              multiline
                              placeholder={'KPI önerinizi yazınız…'}
                              className={classes.textArea}
                              rows={8}
                              value={valueText}
                              onChange={handleChangeText}
                            />
                          </Grid>
                          <Grid container justify="center">
                            <Button
                              variant="outlinedSecondary"
                              style={{
                                fontWeight: 'normal',
                                marginTop: 35,
                                padding: '10.5px 50px',
                                fontSize: 16,
                                marginRight: 15,
                              }}
                              onClick={() => setSuggest(false)}
                            >
                              {t('Geri')}
                            </Button>
                            <Button
                              variant="containedPrimary"
                              style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
                              onClick={() => sendNote(valueText)}
                            >
                              {t('Öneriyi Gönder')}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </>
                </>
              )}
            </>
          </Grid>
        </TabPanel>
        <TabPanel style={{ width: '100%' }} value={valueTab} index={!edit ? 2 : 1}>
          {gaPage === 1 ? (
            <Grid item className={classes.whiteCardTab}>
              {props.gA ? (
                <>
                  <Grid container spacing={4} xs={12} style={{ margin: 0 }}>
                    <Typography variant="h6" style={{ width: '100%', paddingLeft: 16 }}>
                      Account
                    </Typography>
                    {props.gA.map((gAItem) => (
                      <Grid item xs={2}>
                        {gAItem.name !== gaAccount ? (
                          <Grid
                            item
                            xs={12}
                            className={classes.analyticsCard}
                            onClick={() => getOptionsForProperty(gAItem.name)}
                          >
                            <Grid container justify="flex-end">
                              <img src={emptyCheck} alt="empty-check" />
                            </Grid>
                            <Grid item className={classes.analyticsCenterIcon}>
                              <img src={newPerson} alt="new-person" />
                            </Grid>
                            <Typography
                              variant="h6"
                              className={classes.truncate}
                              style={{ marginTop: 8, marginBottom: 16, fontSize: 12 }}
                            >
                              {gAItem.name}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            className={classes.analyticsCard}
                            onClick={() => getOptionsForProperty('')}
                            style={{ background: '#379FFF' }}
                          >
                            <Grid container justify="flex-end">
                              <img src={whiteCheck} alt="white-check" />
                            </Grid>
                            <Grid item className={classes.analyticsCenterIcon} style={{ background: '#2893F7' }}>
                              <img src={newPersonWhite} alt="new-person-white" />
                            </Grid>
                            <Typography
                              variant="h6"
                              className={classes.truncate}
                              style={{ marginTop: 8, marginBottom: 16, color: '#fff', fontSize: 12 }}
                            >
                              {gAItem.name}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={4} xs={12} style={{ margin: 0 }}>
                    {gaPropertyList && gaPropertyList.length > 0 && (
                      <Typography variant="h6" style={{ width: '100%', paddingLeft: 16, marginTop: 24 }}>
                        Property
                      </Typography>
                    )}
                    {gaPropertyList &&
                      gaPropertyList.length > 0 &&
                      gaPropertyList.map((gAProp) => (
                        <Grid item xs={2}>
                          {gAProp.name !== gaProperty ? (
                            <Grid
                              item
                              xs={12}
                              className={classes.analyticsCard}
                              onClick={() => getOptionsForViews(gAProp.name)}
                            >
                              <Grid container justify="flex-end">
                                <img src={emptyCheck} alt="empty-check" />
                              </Grid>
                              <Grid item className={classes.analyticsCenterIcon}>
                                <img src={newBag} alt="new-bag" />
                              </Grid>
                              <Typography
                                variant="h6"
                                className={classes.truncate}
                                style={{ marginTop: 8, marginBottom: 16, fontSize: 12 }}
                              >
                                {gAProp.name}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              className={classes.analyticsCard}
                              onClick={() => getOptionsForViews('')}
                              style={{ background: '#379FFF' }}
                            >
                              <Grid container justify="flex-end">
                                <img src={whiteCheck} alt="white-check" />
                              </Grid>
                              <Grid item className={classes.analyticsCenterIcon} style={{ background: '#2893F7' }}>
                                <img src={newBagWhite} alt="new-bag-white" />
                              </Grid>
                              <Typography
                                className={classes.truncate}
                                variant="h6"
                                style={{ marginTop: 8, marginBottom: 16, color: '#fff', fontSize: 12 }}
                              >
                                {gAProp.name}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                  <Grid container spacing={4} xs={12} style={{ margin: 0 }}>
                    {gaViewsList && gaViewsList.length > 0 && (
                      <Typography variant="h6" style={{ width: '100%', paddingLeft: 16, marginTop: 24 }}>
                        Views
                      </Typography>
                    )}
                    {gaViewsList &&
                      gaViewsList.length > 0 &&
                      gaViewsList.map((gAView) => (
                        <Grid item xs={2}>
                          {gAView.id !== gaView ? (
                            <Grid item xs={12} className={classes.analyticsCard} onClick={() => setGaView(gAView.id)}>
                              <Grid container justify="flex-end">
                                <img src={emptyCheck} alt="empty-check" />
                              </Grid>
                              <Grid item className={classes.analyticsCenterIcon}>
                                <img src={newEye} alt="new-eye" />
                              </Grid>
                              <Typography
                                variant="h6"
                                className={classes.truncate}
                                style={{ marginTop: 8, marginBottom: 16, fontSize: 12 }}
                              >
                                {gAView.name}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              className={classes.analyticsCard}
                              onClick={() => setGaView(null)}
                              style={{ background: '#379FFF' }}
                            >
                              <Grid container justify="flex-end">
                                <img src={whiteCheck} alt="white-check" />
                              </Grid>
                              <Grid item className={classes.analyticsCenterIcon} style={{ background: '#2893F7' }}>
                                <img src={newEyeWhite} alt="new-eye-white" />
                              </Grid>
                              <Typography
                                className={classes.truncate}
                                variant="h6"
                                style={{ marginTop: 8, marginBottom: 16, color: '#fff', fontSize: 12 }}
                              >
                                {gAView.name}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                  <Grid container spacing={4} xs={12} style={{ margin: 0 }}>
                    {props.kpiList && props.kpiList.length > 0 && (
                      <Typography variant="h6" style={{ width: '100%', paddingLeft: 16, marginTop: 24 }}>
                        Lists
                      </Typography>
                    )}
                    {props.kpiList &&
                      props.kpiList.length > 0 &&
                      props.kpiList.map((gAList) => (
                        <Grid item xs={2}>
                          {gAList.id !== gaListId ? (
                            <Grid item xs={12} className={classes.analyticsCard} onClick={() => setGaListId(gAList.id)}>
                              <Grid container justify="flex-end">
                                <img src={emptyCheck} alt="empty-check" />
                              </Grid>
                              <Grid item className={classes.analyticsCenterIcon}>
                                <MenuIcon />
                              </Grid>
                              <Typography
                                variant="h6"
                                className={classes.truncate}
                                style={{ marginTop: 8, marginBottom: 16, fontSize: 12 }}
                              >
                                {gAList.title}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              className={classes.analyticsCard}
                              onClick={() => setGaListId()}
                              style={{ background: '#379FFF' }}
                            >
                              <Grid container justify="flex-end">
                                <img src={whiteCheck} alt="white-check" />
                              </Grid>
                              <Grid item className={classes.analyticsCenterIcon} style={{ background: '#2893F7' }}>
                                <MenuIcon style={{ color: '#fff' }} />
                              </Grid>
                              <Typography
                                className={classes.truncate}
                                variant="h6"
                                style={{ marginTop: 8, marginBottom: 16, color: '#fff', fontSize: 12 }}
                              >
                                {gAList.title}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                  <Grid container justify="flex-end" className={classes.bottomBar}>
                    <Button
                      variant="outlinedSecondary"
                      style={{
                        fontWeight: 'normal',
                        width: 95,
                        fontSize: 14,
                        marginRight: 15,
                        borderRadius: 8,
                        color: '#003CA5',
                        borderColor: '#003CA5',
                      }}
                      onClick={() => onClose(null)}
                    >
                      {t('Vazgeç')}
                    </Button>
                    <Button
                      variant="containedPrimary"
                      style={{ fontWeight: 'normal', width: 95, fontSize: 14, borderRadius: 8 }}
                      disabled={!gaView}
                      onClick={() => setGaPage(2)}
                    >
                      {edit ? t('Düzenle') : t('Devam')}
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container justify="center">
                    <img src={analyticsGeneral} alt="analytics-general" style={{ marginTop: 100 }} />
                  </Grid>
                  <Typography variant="h5" align="center" style={{ marginTop: 24 }}>
                    Yeni bir performans seti oluşturmak için verilerinizi
                    <br />
                    Google Analytics ile sekronize edebilirsiniz.
                  </Typography>
                  <Grid container justify="center" style={{ marginBottom: 24 }}>
                    <a href={'/auth/google/?listId=' + props.listKPIId} style={{ padding: 0 }}>
                      <Button
                        variant="containedPrimary"
                        style={{
                          fontWeight: 'normal',
                          marginTop: 35,
                          padding: '10.5px 100px',
                          fontSize: 16,
                          background: '#FF9B28',
                        }}
                      >
                        {t('Hemen Başla')}
                      </Button>
                    </a>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <Grid item className={classes.whiteCardTab}>
              <>
                {!edit ? (
                  <>
                    {!suggest ? (
                      <>
                        <Grid container justify="flex-end">
                          <Grid container xs={12} md={5}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Grid container justify="flex-end">
                                <div className={classes.search2}>
                                  <div className={classes.searchIcon}>
                                    <SearchIcons />
                                  </div>
                                  <DebounceInput
                                    class="inputTypeSearch"
                                    name="search"
                                    value={searchGA}
                                    placeholder={t('KPI Ara')}
                                    className={classes.inputInput}
                                    minLength={2}
                                    autoComplete="off"
                                    debounceTimeout={800}
                                    onChange={handleSearchGA}
                                  />
                                </div>
                              </Grid>
                              {searchGA && dataSearchGA && (
                                <Grid
                                  className={classes.searchBox}
                                  style={{ position: 'absolute', maxWidth: 300, width: '100%', marginTop: 55 }}
                                >
                                  <List component="nav" aria-label="secondary mailbox folders">
                                    {dataSearchGA.getKPIGAListsSearch.map((searchResult) => (
                                      <Grid>
                                        {selectedGA.some((e) => e.id === searchResult.id) ? (
                                          <ListItem
                                            onClick={() =>
                                              setSelectedGA(selectedGA.filter((x) => x.id !== searchResult.id))
                                            }
                                            button
                                            style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                                          >
                                            <Grid container>
                                              <Typography variant="body1" color="primary">
                                                {searchResult.title}
                                              </Typography>
                                            </Grid>
                                          </ListItem>
                                        ) : (
                                          <ListItem
                                            onClick={() => setSelectedGA([...selectedGA, searchResult])}
                                            button
                                            style={{ minHeight: 50 }}
                                          >
                                            <Grid container>
                                              <Typography variant="body1" color="primary">
                                                {searchResult.title}
                                              </Typography>
                                            </Grid>
                                          </ListItem>
                                        )}
                                      </Grid>
                                    ))}
                                    {dataSearchGA.getKPIGAListsSearch.length === 0 && (
                                      <ListItem>
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                          <Typography align="center" variant="body1">
                                            {t("Aradığın KPI'yı bulamadık.")}
                                          </Typography>
                                          <Button
                                            variant="containedPrimary"
                                            onClick={() => setSuggest(true)}
                                            style={{
                                              fontWeight: 'normal',
                                              marginTop: 15,
                                              padding: '10.5px 50px',
                                              fontSize: 16,
                                            }}
                                          >
                                            {t('KPI Önerisi')}
                                          </Button>
                                        </Grid>
                                      </ListItem>
                                    )}
                                  </List>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 35 }}>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{
                              borderRight: '2px solid #EDF2F7',
                              marginBottom: 20,
                            }}
                          >
                            <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 8 }}>
                              {t('Kategori')}
                            </Typography>
                            <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                              {catGA.map((catGA) => (
                                <>
                                  {catGA.id === catIdGA ? (
                                    <Grid
                                      container
                                      justify="space-between"
                                      className={classes.kpiCat}
                                      onClick={() => setCatIdGA(catGA.id)}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                      >
                                        {catGA.title}
                                      </Typography>
                                      <ArrowForwardIosIcon style={{ fontSize: 14, color: '#118DFF' }} />
                                      <Grid container>
                                        <hr
                                          size="1"
                                          style={{
                                            border: '1px solid #EDF2F7',
                                            marginTop: 16,
                                            marginBottom: 0,
                                            width: '100%',
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      container
                                      justify="space-between"
                                      className={classes.kpiCat}
                                      onClick={() => setCatIdGA(catGA.id)}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                      >
                                        {catGA.title}
                                      </Typography>
                                      <ArrowForwardIosIcon style={{ fontSize: 14, color: '#718096' }} />
                                      <Grid container>
                                        <hr
                                          size="1"
                                          style={{
                                            border: '1px solid #EDF2F7',
                                            marginTop: 16,
                                            marginBottom: 0,
                                            width: '100%',
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{
                              borderRight: '2px solid #EDF2F7',
                              marginBottom: 20,
                            }}
                          >
                            <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                              {t('KPI Listesi')}
                            </Typography>
                            <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                              {detailGA.map((detailGA) => (
                                <>
                                  {selectedGA.some((e) => e.id === detailGA.id) ? (
                                    <Grid
                                      container
                                      justify="space-between"
                                      className={classes.kpiCat}
                                      style={{ paddingLeft: 24 }}
                                      onClick={() => setSelectedGA(selectedGA.filter((x) => x.id !== detailGA.id))}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                      >
                                        {detailGA.title}
                                      </Typography>
                                      <img
                                        src={selectedKpi}
                                        alt="selected-kpi"
                                        style={{ marginBottom: 12, marginRight: 4 }}
                                      />
                                      <Grid container>
                                        <hr
                                          size="1"
                                          style={{
                                            border: '1px solid #EDF2F7',
                                            marginTop: 10,
                                            marginBottom: 0,
                                            width: '100%',
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      container
                                      justify="space-between"
                                      className={classes.kpiCat}
                                      style={{ paddingLeft: 24 }}
                                      onClick={() => setSelectedGA([...selectedGA, detailGA])}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                      >
                                        {detailGA.title}
                                      </Typography>
                                      <AddCircleOutlineIcon style={{ fontSize: 24, color: '#425466' }} />
                                      <Grid container>
                                        <hr
                                          size="1"
                                          style={{
                                            border: '1px solid #EDF2F7',
                                            marginTop: 10,
                                            marginBottom: 0,
                                            width: '100%',
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            style={{
                              marginBottom: 20,
                            }}
                          >
                            <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                              {t('Seçili KPI’lar')}
                            </Typography>
                            <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                              {selectedGA.map((selectGA) => (
                                <Grid
                                  container
                                  justify="space-between"
                                  className={classes.selected}
                                  onClick={() => setSelectedGA(selectedGA.filter((x) => x.id !== selectGA.id))}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                  >
                                    {selectGA.title}
                                  </Typography>
                                  <img src={removeKpi} alt="remove-kpi" style={{ marginBottom: 12, marginRight: 4 }} />
                                  <Grid container>
                                    <hr
                                      size="1"
                                      style={{
                                        border: '1px solid #EDF2F7',
                                        marginTop: 2,
                                        marginBottom: 0,
                                        width: '100%',
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Grid container justify="center">
                        <Grid item xs={5}>
                          <TextField
                            id="standard-multiline-flexible"
                            multiline
                            placeholder={'KPI önerinizi yazınız…'}
                            className={classes.textArea}
                            rows={8}
                            value={valueText}
                            onChange={handleChangeText}
                          />
                        </Grid>
                        <Grid container justify="center">
                          <Button
                            variant="outlinedSecondary"
                            style={{
                              fontWeight: 'normal',
                              marginTop: 35,
                              padding: '10.5px 50px',
                              fontSize: 16,
                              marginRight: 15,
                            }}
                            onClick={() => setSuggest(false)}
                          >
                            {t('Geri')}
                          </Button>
                          <Button
                            variant="containedPrimary"
                            style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
                            onClick={() => sendNote(valueText)}
                          >
                            {t('Öneriyi Gönder')}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                      <>
                        {!suggest ? (
                          <>
                            <Grid container justify="flex-end">
                              <Grid container xs={12} md={5}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Grid container justify="flex-end">
                                    <div className={classes.search2}>
                                      <div className={classes.searchIcon}>
                                        <SearchIcons />
                                      </div>
                                      <DebounceInput
                                        class="inputTypeSearch"
                                        name="search"
                                        value={searchGA}
                                        placeholder={t('KPI Ara')}
                                        className={classes.inputInput}
                                        minLength={2}
                                        autoComplete="off"
                                        debounceTimeout={800}
                                        onChange={handleSearchGA}
                                      />
                                    </div>
                                  </Grid>
                                  {searchGA && dataSearchGA && (
                                    <Grid
                                      className={classes.searchBox}
                                      style={{ position: 'absolute', maxWidth: 300, width: '100%', marginTop: 55 }}
                                    >
                                      <List component="nav" aria-label="secondary mailbox folders">
                                        {dataSearchGA.getKPIGAListsSearch.map((searchResult) => (
                                          <Grid>
                                            {selectedGA.some((e) => e.id === searchResult.id) ? (
                                              <ListItem
                                                onClick={() =>
                                                  setSelectedGA(selectedGA.filter((x) => x.id !== searchResult.id))
                                                }
                                                button
                                                style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                                              >
                                                <Grid container>
                                                  <Typography variant="body1" color="primary">
                                                    {searchResult.title}
                                                  </Typography>
                                                </Grid>
                                              </ListItem>
                                            ) : (
                                              <ListItem
                                                onClick={() => setSelectedGA([...selectedGA, searchResult])}
                                                button
                                                style={{ minHeight: 50 }}
                                              >
                                                <Grid container>
                                                  <Typography variant="body1" color="primary">
                                                    {searchResult.title}
                                                  </Typography>
                                                </Grid>
                                              </ListItem>
                                            )}
                                          </Grid>
                                        ))}
                                        {dataSearchGA.getKPIGAListsSearch.length === 0 && (
                                          <ListItem>
                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                              <Typography align="center" variant="body1">
                                                {t("Aradığın KPI'yı bulamadık.")}
                                              </Typography>
                                              <Button
                                                variant="containedPrimary"
                                                onClick={() => setSuggest(true)}
                                                style={{
                                                  fontWeight: 'normal',
                                                  marginTop: 15,
                                                  padding: '10.5px 50px',
                                                  fontSize: 16,
                                                }}
                                              >
                                                {t('KPI Önerisi')}
                                              </Button>
                                            </Grid>
                                          </ListItem>
                                        )}
                                      </List>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 35 }}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{
                                  borderRight: '2px solid #EDF2F7',
                                  marginBottom: 20,
                                }}
                              >
                                <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 8 }}>
                                  {t('Kategori')}
                                </Typography>
                                <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                                  {catGA.map((catGA) => (
                                    <>
                                      {catGA.id === catIdGA ? (
                                        <Grid
                                          container
                                          justify="space-between"
                                          className={classes.kpiCat}
                                          onClick={() => setCatIdGA(catGA.id)}
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                          >
                                            {catGA.title}
                                          </Typography>
                                          <ArrowForwardIosIcon style={{ fontSize: 14, color: '#118DFF' }} />
                                          <Grid container>
                                            <hr
                                              size="1"
                                              style={{
                                                border: '1px solid #EDF2F7',
                                                marginTop: 16,
                                                marginBottom: 0,
                                                width: '100%',
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <Grid
                                          container
                                          justify="space-between"
                                          className={classes.kpiCat}
                                          onClick={() => setCatIdGA(catGA.id)}
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                          >
                                            {catGA.title}
                                          </Typography>
                                          <ArrowForwardIosIcon style={{ fontSize: 14, color: '#718096' }} />
                                          <Grid container>
                                            <hr
                                              size="1"
                                              style={{
                                                border: '1px solid #EDF2F7',
                                                marginTop: 16,
                                                marginBottom: 0,
                                                width: '100%',
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{
                                  borderRight: '2px solid #EDF2F7',
                                  marginBottom: 20,
                                }}
                              >
                                <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                                  {t('KPI Listesi')}
                                </Typography>
                                <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                                  {detailGA.map((detailGA) => (
                                    <>
                                      {selectedGA.some((e) => e.id === detailGA.id) ? (
                                        <Grid
                                          container
                                          justify="space-between"
                                          className={classes.kpiCat}
                                          style={{ paddingLeft: 24 }}
                                          onClick={() => setSelectedGA(selectedGA.filter((x) => x.id !== detailGA.id))}
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                          >
                                            {detailGA.title}
                                          </Typography>
                                          <img
                                            src={selectedKpi}
                                            alt="selected-kpi"
                                            style={{ marginBottom: 12, marginRight: 4 }}
                                          />
                                          <Grid container>
                                            <hr
                                              size="1"
                                              style={{
                                                border: '1px solid #EDF2F7',
                                                marginTop: 10,
                                                marginBottom: 0,
                                                width: '100%',
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <Grid
                                          container
                                          justify="space-between"
                                          className={classes.kpiCat}
                                          style={{ paddingLeft: 24 }}
                                          onClick={() => setSelectedGA([...selectedGA, detailGA])}
                                        >
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: 12, fontWeight: 600, color: '#718096' }}
                                          >
                                            {detailGA.title}
                                          </Typography>
                                          <AddCircleOutlineIcon style={{ fontSize: 24, color: '#425466' }} />
                                          <Grid container>
                                            <hr
                                              size="1"
                                              style={{
                                                border: '1px solid #EDF2F7',
                                                marginTop: 10,
                                                marginBottom: 0,
                                                width: '100%',
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{
                                  marginBottom: 20,
                                }}
                              >
                                <Typography variant="h5" style={{ marginBottom: 24, paddingLeft: 24 }}>
                                  {t('Seçili KPI’lar')}
                                </Typography>
                                <Grid item xs={12} style={{ maxHeight: 250, overflowY: 'auto' }}>
                                  {selectedGA.map((selectGA) => (
                                    <Grid
                                      container
                                      justify="space-between"
                                      className={classes.selected}
                                      onClick={() => setSelectedGA(selectedGA.filter((x) => x.id !== selectGA.id))}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{ fontSize: 12, fontWeight: 600, color: '#118DFF' }}
                                      >
                                        {selectGA.title}
                                      </Typography>
                                      <img
                                        src={removeKpi}
                                        alt="remove-kpi"
                                        style={{ marginBottom: 12, marginRight: 4 }}
                                      />
                                      <Grid container>
                                        <hr
                                          size="1"
                                          style={{
                                            border: '1px solid #EDF2F7',
                                            marginTop: 2,
                                            marginBottom: 0,
                                            width: '100%',
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Grid container justify="center">
                            <Grid item xs={5}>
                              <TextField
                                id="standard-multiline-flexible"
                                multiline
                                placeholder={'KPI önerinizi yazınız…'}
                                className={classes.textArea}
                                rows={8}
                                value={valueText}
                                onChange={handleChangeText}
                              />
                            </Grid>
                            <Grid container justify="center">
                              <Button
                                variant="outlinedSecondary"
                                style={{
                                  fontWeight: 'normal',
                                  marginTop: 35,
                                  padding: '10.5px 50px',
                                  fontSize: 16,
                                  marginRight: 15,
                                }}
                                onClick={() => setSuggest(false)}
                              >
                                {t('Geri')}
                              </Button>
                              <Button
                                variant="containedPrimary"
                                style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
                                onClick={() => sendNote(valueText)}
                              >
                                {t('Öneriyi Gönder')}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </>
                  </>
                )}
              </>
              <Grid container justify="flex-end" className={classes.bottomBar}>
                <Button
                  variant="outlinedSecondary"
                  style={{
                    fontWeight: 'normal',
                    width: 95,
                    fontSize: 14,
                    marginRight: 15,
                    borderRadius: 8,
                    color: '#003CA5',
                    borderColor: '#003CA5',
                  }}
                  onClick={() => setGaPage(1)}
                >
                  {t('Geri')}
                </Button>
                <Button
                  variant="containedPrimary"
                  style={{ fontWeight: 'normal', width: 95, fontSize: 14, borderRadius: 8 }}
                  disabled={!selectedGA || selectedGA.length === 0}
                  onClick={() => handleSubmitGa()}
                >
                  {edit ? t('Düzenle') : t('Ekle')}
                </Button>
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </Grid>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setIsSuggest(false)}
        open={isSuggest}
      >
        <Alert severity="success">KPI öneriniz yönetici onayına iletildi. </Alert>
      </Snackbar>
      <Snackbar autoHideDuration={6000} onClose={() => setServerInfo(false)} open={serverInfo}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    padding: '16px 24px',
    boxShadow: 'inset 0px -1px 0px #EDF2F7',
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid #fff',
    padding: '16px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    boxShadow: 'inset 0px -1px 0px #EDF2F7',
  },
}))(TableRow);

const StyledTableRow2 = withStyles((theme) => ({
  root: {
    backgroundColor: '#F0F5FF',
    borderBottom: '1px solid #fff',
  },
}))(TableRow);

const StyledTableRowEmpty = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: '6px 0',
  },
}))(TableRow);

function DeleteKPIPopup(props) {
  const classes = useStyles();
  const { id, listId, anchorEl, kpi, handleClose, openedPopoverId } = props;
  const [share, setShare] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ShareDialog
        setShareBar={props.setShareBar}
        kpiId={kpi.id}
        title={kpi.title}
        open={share}
        handleClose2={handleClose}
        startupKPIToReport={props.startupKPIToReport}
        onClose={() => setShare(false)}
        groups={props.groups}
        people={props.people}
      />
      <Popover
        id={id}
        open={openedPopoverId === kpi.id}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.myPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid item xs={12}>
          <ListItem style={{ cursor: 'pointer' }} button>
            <Link
              style={{ padding: 0 }}
              to={{
                pathname: `/veri-duzenle/${listId}`,
                state: { focusKpi: kpi.id, focusFrequency: kpi.dataFrequency },
              }}
            >
              <Grid style={{ width: 'fit-content' }} container>
                <Typography style={{ width: 'fit-content' }} variant="body2">
                  {t('Düzenle')}
                </Typography>
              </Grid>
            </Link>
          </ListItem>
          <ListItem onClick={() => setShare(true)} style={{ cursor: 'pointer' }} button>
            <Grid style={{ width: 'fit-content' }} container>
              <Typography style={{ width: 'fit-content' }} variant="body2">
                {t('Paylaş')}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem onClick={() => setRemove(true)} style={{ cursor: 'pointer' }} button>
            <Grid style={{ width: 'fit-content' }} container>
              <Typography style={{ width: 'fit-content' }} variant="body2">
                {t('Sil')}
              </Typography>
            </Grid>
          </ListItem>
          <RemoveDialog
            kpiDelete={props.kpiDelete}
            kpiId={kpi.id}
            refetchKPI={props.refetchKPI}
            open={remove}
            onClose={() => setRemove(false)}
            handleClose={handleClose}
          />
        </Grid>
      </Popover>
    </>
  );
}

function ExpandingRow(props) {
  const [open, setOpen] = React.useState(true);
  const [openedPopoverId, setOpenedPopoverId] = React.useState(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [share, setShare] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const { t } = useTranslation();

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick = (event, id) => {
    setOpenedPopoverId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const openPop2 = Boolean(anchorEl2);
  const id2 = openPop2 ? 'simple-popover' : undefined;

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const { kpiCategories, listId } = props;
  return (
    <>
      <StyledTableRowEmpty key={kpiCategories.title}>
        <StyledTableCell component="th" scope="row" style={{ padding: '8px 0' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding: '8px 0' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding: '8px 0' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding: '8px 0' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding: '8px 0' }}></StyledTableCell>
        <StyledTableCell align="right" style={{ padding: '8px 0' }}></StyledTableCell>
      </StyledTableRowEmpty>
      <StyledTableRow2 key={kpiCategories.title}>
        <StyledTableCell component="th" scope="row" style={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
          <Typography variant="h6" style={{ fontSize: 12 }} color="primary">
            {kpiCategories.title}
          </Typography>
          <MoreVertIcon color="primary" onClick={handleClick2} style={{ fontSize: 20, cursor: 'pointer' }} />
        </StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="right">
          {open ? (
            <ExpandLessIcon
              onClick={() => setOpen(!open)}
              color="primary"
              style={{ cursor: 'pointer', transition: '0.5s', fontSize: 24, marginLeft: 10 }}
            />
          ) : (
            <ExpandMoreIcon
              color="primary"
              onClick={() => setOpen(!open)}
              style={{ cursor: 'pointer', transition: '0.5s', fontSize: 24, marginLeft: 10 }}
            />
          )}
        </StyledTableCell>
        <ShareDialog
          setShareBar={props.setShareBar}
          categoryId={kpiCategories.id}
          title={kpiCategories.title}
          open={share}
          handleClose2={handleClose}
          startupKPIToReport={props.startupKPIToReport}
          onClose={() => setShare(false)}
          groups={props.groups}
          people={props.people}
        />
        <Popover
          id={id2}
          open={openPop2}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          classes={{
            paper: classes.myPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid item xs={12}>
            <ListItem onClick={() => setShare(true)} style={{ cursor: 'pointer' }}>
              <Grid style={{ width: 'fit-content' }} container>
                <Typography style={{ width: 'fit-content' }} variant="body2">
                  {t('Paylaş')}
                </Typography>
              </Grid>
            </ListItem>
            <ListItem onClick={() => setRemove(true)} style={{ cursor: 'pointer' }}>
              <Grid style={{ width: 'fit-content' }} container>
                <Typography style={{ width: 'fit-content' }} variant="body2">
                  {t('Sil')}
                </Typography>
              </Grid>
            </ListItem>
            <RemoveDialog
              kpiCategoryDelete={props.kpiCategoryDelete}
              categoryId={kpiCategories.id}
              handleClose={handleClose2}
              refetchKPI={props.refetchKPI}
              open={remove}
              onClose={() => setRemove(false)}
            />
          </Grid>
        </Popover>
      </StyledTableRow2>
      {open && (
        <>
          {kpiCategories.kpis.map((kpi) => (
            <StyledTableRow key={kpiCategories.title}>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography variant="h6" style={{ fontSize: 12, display: 'flex' }}>
                  {kpi.type === 'SB' && <img src={sbIcon} alt="sb-icon" style={{ width: 24, marginRight: 16 }} />}
                  {kpi.type === 'GA' && <img src={gaIcon} alt="ga-icon" style={{ marginRight: 16 }} />}
                  {kpi.title}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography variant="body2" style={{ fontSize: 12 }}>
                  {kpi.dataFormat}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography variant="body2" style={{ fontSize: 12 }}>
                  {kpi.dataFrequency}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography variant="body2" style={{ fontSize: 12 }}>
                  {moment(parseInt(kpi.updatedAt)).locale('tr').format('Do MMMM YYYY')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ border: 'none' }} align="left">
                <Typography style={{ fontSize: 12, color: '#118DFF', cursor: 'pointer' }} variant="h6">
                  <Link
                    style={{ fontSize: 12, color: '#118DFF', cursor: 'pointer', fontWeight: 600 }}
                    to={{
                      pathname: `/veri-duzenle/${listId}`,
                      state: { focusKpi: kpi.id, focusFrequency: kpi.dataFrequency },
                    }}
                  >
                    {t('Veri Ekle')}
                  </Link>
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right" style={{ width: '160px' }}>
                <MoreVertIcon
                  onClick={(event) => handleClick(event, kpi.id)}
                  style={{ color: '#8898aa', fontSize: 20, cursor: 'pointer' }}
                />
              </StyledTableCell>
              <DeleteKPIPopup
                setShareBar={props.setShareBar}
                groups={props.groups}
                people={props.people}
                startupKPIToReport={props.startupKPIToReport}
                id={id}
                listId={listId}
                kpi={kpi}
                refetchKPI={props.refetchKPI}
                kpiDelete={props.kpiDelete}
                handleClose={handleClose}
                openedPopoverId={openedPopoverId}
                anchorEl={anchorEl}
                onClose={handleClose}
              />
            </StyledTableRow>
          ))}
        </>
      )}
    </>
  );
}

const GraphComponent = ({ kpi, ...props }) => {
  const classes = useStyles();
  const [graphDataState, setGraphData] = React.useState([]);
  const [graphTargetState, setGraphTarget] = React.useState([]);
  const [graphDateState, setGraphDate] = React.useState([]);

  if (graphDataState.length === 0) {
    let graphData = [];
    kpi.datas.forEach((data) => {
      graphData.push(data.reached);
    });
    setGraphData(graphData);
  }

  if (graphTargetState.length === 0) {
    let graphData = [];
    kpi.datas.forEach((data) => {

      graphData.push(data.target);
    });
    setGraphTarget(graphData);
  }

  if (graphDateState.length === 0) {
    let graphDate = [];
    kpi.datas.forEach((data) => {
      graphDate.push(moment(parseInt(data.date)).format('Do MMMM YYYY'));
    });
    setGraphDate(graphDate);
  }

  return (
    <>
      {graphDataState.length > 0 && graphDateState.length && (
        <Line
          width={100}
          height={150}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    display: false,
                    borderDashOffset: 1,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    borderDash: [2, 2],
                    lineWidth: 2,
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
          data={{
            labels: graphDateState,
            datasets: [
              {
                label: kpi.title,
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#172b4d',
                borderColor: '#FF9B28',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#172b4d',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#172b4d',
                pointHoverBorderColor: '#172b4d',
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                pointHitRadius: 10,
                data: graphDataState,
              },
              {
                label: 'hedef',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#172b4d',
                borderColor: '#66CB9F',
                borderCapStyle: 'butt',
                borderDash: [5, 5],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#172b4d',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#172b4d',
                pointHoverBorderColor: '#172b4d',
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                pointHitRadius: 10,
                data: graphTargetState,
              },
            ],
          }}
        />
      )}
    </>
  );
};

function ExpandingCategory(props) {
  const [open, setOpen] = React.useState(true);
  const [openedPopoverId, setOpenedPopoverId] = React.useState(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [share, setShare] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const { t } = useTranslation();

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick = (event, id) => {
    setOpenedPopoverId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };
  const graphSize = (lenght, index) => {
    if (index + 1 === lenght && lenght % 2 === 1) {
      return true;
    } else {
      return false;
    }
  };

  const openPop2 = Boolean(anchorEl2);
  const id2 = openPop2 ? 'simple-popover' : undefined;

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const { kpiCategories, listId } = props;
  return (
    <>
      <Grid container justify="space-between" style={{ background: '#F0F5FF', padding: '12px 30px', marginBottom: 16 }}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" style={{ color: '#003CA5', fontSize: 14 }}>
            {kpiCategories.title}
          </Typography>
          <MoreVertIcon onClick={handleClick2} style={{ color: '#003CA5', fontSize: 20, cursor: 'pointer' }} />
        </Grid>
        {open ? (
          <ExpandLessIcon
            onClick={() => setOpen(!open)}
            style={{ cursor: 'pointer', transition: '0.5s', color: '#003CA5', fontSize: 20, marginLeft: 10 }}
          />
        ) : (
          <ExpandMoreIcon
            onClick={() => setOpen(!open)}
            style={{ cursor: 'pointer', transition: '0.5s', color: '#003CA5', fontSize: 20, marginLeft: 10 }}
          />
        )}
        <ShareDialog
          setShareBar={props.setShareBar}
          categoryId={kpiCategories.id}
          title={kpiCategories.title}
          open={share}
          handleClose2={handleClose}
          startupKPIToReport={props.startupKPIToReport}
          onClose={() => setShare(false)}
          groups={props.groups}
          people={props.people}
        />
        <Popover
          id={id2}
          open={openPop2}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          classes={{
            paper: classes.myPaper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid item xs={12}>
            <ListItem onClick={() => setShare(true)} style={{ cursor: 'pointer' }}>
              <Grid style={{ width: 'fit-content' }} container>
                <Typography style={{ width: 'fit-content' }} variant="body2">
                  {t('Paylaş')}
                </Typography>
              </Grid>
            </ListItem>
            <ListItem onClick={() => setRemove(true)} style={{ cursor: 'pointer' }}>
              <Grid style={{ width: 'fit-content' }} container>
                <Typography style={{ width: 'fit-content' }} variant="body2">
                  {t('Sil')}
                </Typography>
              </Grid>
            </ListItem>
            <RemoveDialog
              kpiCategoryDelete={props.kpiCategoryDelete}
              categoryId={kpiCategories.id}
              handleClose={handleClose2}
              refetchKPI={props.refetchKPI}
              open={remove}
              onClose={() => setRemove(false)}
            />
          </Grid>
        </Popover>
      </Grid>
      {open && (
        <Grid container xs={12} spacing={3} className={classes.graphGrid}>
          {kpiCategories &&
            kpiCategories.kpis.map((kpi, index) => (
              <Grid item xs={12} md={graphSize(kpiCategories.kpis.length, index) ? 12 : 6}>
                <Grid xs={12} className={kpi && kpi.datas.length > 2 ? classes.graphCardFull : classes.graphCard}>
                  <Grid container justify="space-between" style={{ marginBottom: 16 }}>
                    <Typography variant="h6" style={{ fontSize: 12 }}>
                      {kpi.title}
                    </Typography>
                    {kpi && kpi.datas.length > 2 && (
                      <MoreVertIcon
                        onClick={(event) => handleClick(event, kpi.id)}
                        style={{ color: '#8898aa', fontSize: 20, cursor: 'pointer' }}
                      />
                    )}
                    <DeleteKPIPopup
                      setShareBar={props.setShareBar}
                      groups={props.groups}
                      people={props.people}
                      startupKPIToReport={props.startupKPIToReport}
                      id={id}
                      listId={listId}
                      kpi={kpi}
                      refetchKPI={props.refetchKPI}
                      kpiDelete={props.kpiDelete}
                      handleClose={handleClose}
                      openedPopoverId={openedPopoverId}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                    />
                  </Grid>
                  {kpi && kpi.datas.length > 2 ? (
                    <GraphComponent kpi={kpi} />
                  ) : (
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Grid item className={classes.cloudGrid}>
                          <img src={CloudIcon} alt="cloud-icon" />
                        </Grid>
                      </Grid>
                      <Typography variant="h6" align="center" style={{ marginBottom: 4, marginTop: 8 }}>
                        {t('Verilerinizi şimdi ekleyin')}
                      </Typography>
                      <Typography variant="body2" align="center" style={{ fontSize: 10, color: '#CBD5E0' }}>
                        {t('(Minimum 2 veri)')}
                      </Typography>
                      <Grid container justify="center">
                        <Typography style={{ fontSize: 12, color: '#118DFF', cursor: 'pointer' }} variant="h6">
                          <Link
                            style={{ fontSize: 12, color: '#118DFF', cursor: 'pointer', fontWeight: 600 }}
                            to={{
                              pathname: `/veri-duzenle/${listId}`,
                              state: { focusKpi: kpi.id, focusFrequency: kpi.dataFrequency },
                            }}
                          >
                            <Button variant="outlinedPrimary" className={classes.graphButton}>
                              {t('Veri Ekle')}
                            </Button>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
}
const FormikGroupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        style={{ marginTop: 10, minHeight: 80 }}
        {...props}
        {...field}
        multiple
        disableCloseOnSelect
        debug={true}
        options={props.options}
        noOptionsText={
          <Typography variant="h6" align="center" color="primary" style={{cursor:'pointer', display:'flex', justifyContent:'center'}} onMouseDown={() => {
            props.history.push('/kisilerim');
          }}>
            <img src={newPlusBlue} alt="new-plus" style={{ marginRight: 8 }} />
            Davet Et
          </Typography>
        }
        getOptionLabel={(option) => option.title}
        hiddenLabel="true"
        className={classes.multiSelect2}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => (
          <TextField {...props} placeholder={t('Lütfen Seçiniz')} {...textFieldProps} variant="outlined" />
        )}
      ></Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
};

const FormikPersonDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        style={{ marginTop: 10, minHeight: 80 }}
        {...props}
        {...field}
        multiple
        disableCloseOnSelect
        options={props.options}
        noOptionsText={
          <Typography variant="h6" align="center" color="primary" style={{cursor:'pointer', display:'flex', justifyContent:'center'}} onMouseDown={() => {
            props.history.push('/kisilerim');
          }}>
            <img src={newPlusBlue} alt="new-plus" style={{ marginRight: 8 }} />
            Davet Et
          </Typography>
        }
        getOptionLabel={(option) => option.getName}
        hiddenLabel="true"
        className={classes.multiSelect2}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => (
          <TextField {...props} placeholder={t('Lütfen Seçiniz')} {...textFieldProps} variant="outlined" />
        )}
      ></Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
};
function ShareDialog(props) {
  const classes = useStyles();
  const { onClose, open, groups, people, handleClose2, listId, categoryId, kpiId, title, history } = props;
  const yesNo = ['Evet', 'Hayır'];
  const reminderOptions = ['Tek seferlik', 'Haftalık', 'Aylık', 'Çeyreklik', 'Yıllık'];
  const { t } = useTranslation();

  const handleSubmit = (variables) => {
    let tempGr = [];
    let tempPr = [];

    variables.groupIds.forEach((element) => {
      tempGr.push(element.id);
    });
    variables.groupIds = tempGr;

    variables.personIds.forEach((element) => {
      tempPr.push(element.id);
    });
    variables.personIds = tempPr;

    if (listId) {
      variables.listId = listId;
    }
    if (categoryId) {
      variables.categoryId = categoryId;
    }
    if (kpiId) {
      variables.kpiId = kpiId;
    }
    if (variables.startDate) {
      variables.startDate = variables.startDate.toLocaleString();
      variables.startDate = variables.startDate.split(' ')[0];
    }
    props
      .startupKPIToReport({ variables })
      .then((data) => {
        if (data.data.startupKPIToReport) {
          onClose(null);
          handleClose2();
          props.setShareBar(true);
        }
      })
      .then(() => {})
      .catch((error) => {});
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4" style={{ marginBottom: 20 }}>
          {title} {t('Raporunu Paylaş')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              title: title,
              personIds: [],
              groupIds: [],
              startDate: null,
              dataFrequency: '',
              reminderFrequency: '',
              isPublic: 'Hayır',
            }}
            validationSchema={reportSchemaForKpiList}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12} style={{ marginBottom: 25 }}>
                      <Typography variant="h6" style={{ display: 'flex' }}>
                        {t('Raporu hangi sıklıkta paylaşmak istiyorsunuz?')}
                      </Typography>
                      <Field
                        style={{ width: '100%', marginTop: 10 }}
                        component={FormikDropdown}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="dataFrequency"
                        options={reminderOptions}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} style={{ marginBottom: 30 }}>
                      <Typography variant="h6" style={{ display: 'flex' }}>
                        {t('Verileri hangi tarihten itibaren göndermek istiyorsunuz?')}
                      </Typography>
                      <DatePickerField name={'startDate'} dataFrequency={props.values.dataFrequency} />
                    </Grid>
                    {people && (
                      <Grid xs={12} style={{ marginBottom: 35 }} item>
                        <Typography variant="h6">{t('Verilerinizi kimlerle paylaşmak istiyorsunuz?')}</Typography>
                        <Field
                          component={FormikPersonDropdown}
                          history={history}
                          style={{ background: '#fff', marginTop: 10, width: '100%' }}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="personIds"
                          options={people}
                        ></Field>
                      </Grid>
                    )}
                    {groups && (
                      <Grid xs={12} style={{ marginBottom: 35 }} item>
                        <Typography variant="h6">
                          {t('Verilerinizi hangi gruplarla paylaşmak istiyorsunuz?')}
                        </Typography>
                        <Field
                          component={FormikGroupDropdown}
                          history={history}
                          style={{ background: '#fff', marginTop: 10 }}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="groupIds"
                          options={groups}
                        ></Field>
                      </Grid>
                    )}
                    <Grid>
                      <Typography align="left" variant="h6">
                        {t('Bu raporu herkese açık hale getirmek ister misin?')}
                      </Typography>
                      <FormikSelect name="isPublic" options={yesNo}></FormikSelect>
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        disabled={
                          !props.isValid ||
                          (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)
                        }
                        onClick={() => handleSubmit(props.values)}
                      >
                        {t('Paylaş')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

ShareDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function ListPopover(props) {
  const classes = useStyles();
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [share, setShare] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl3(null);
  };

  const openPop = Boolean(anchorEl3);
  const id = openPop ? 'simple-popover' : undefined;

  return (
    <>
      <MoreVertIcon onClick={handleClick} style={{ fontSize: 20, cursor: 'pointer', marginLeft: 5 }} />
      <ShareDialog
        setShareBar={props.setShareBar}
        listId={props.kpiList.id}
        title={props.kpiList.title}
        history={props.history}
        open={share}
        handleClose2={handleClose}
        startupKPIToReport={props.startupKPIToReport}
        onClose={() => setShare(false)}
        groups={props.groups}
        people={props.people}
      />
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl3}
        onClose={handleClose}
        classes={{
          paper: classes.myPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid item xs={12}>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setEdit(true)} button>
            <Grid style={{ width: 'fit-content' }} container>
              <Typography style={{ width: 'fit-content' }} variant="body2">
                {t('Düzenle')}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setShare(true)} button>
            <Grid style={{ width: 'fit-content' }} container>
              <Typography style={{ width: 'fit-content' }} variant="body2">
                {t('Paylaş')}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem onClick={() => setRemove(true)} style={{ cursor: 'pointer' }} button>
            <Grid style={{ width: 'fit-content' }} container>
              <Typography style={{ width: 'fit-content' }} variant="body2">
                {t('Sil')}
              </Typography>
            </Grid>
          </ListItem>
          <RemoveDialog
            deleteList={props.deleteList}
            listId={props.kpiList.id}
            handleClose={handleClose}
            open={remove}
            onClose={() => setRemove(false)}
          />
          <EditListDialog
            setListChanged={props.setListChanged}
            kpiListChangeName={props.kpiListChangeName}
            handleClose={handleClose}
            refetchKPI={props.refetchKPI}
            listId={props.kpiList.id}
            title={props.kpiList.title}
            open={edit}
            onClose={() => setEdit(false)}
          />
        </Grid>
      </Popover>
    </>
  );
}

export default function ListKPI(props) {
  const classes = useStyles();
  const [showKPI, setShowKPI] = React.useState(false);
  const [isAdded, setIsAdded] = React.useState(false);
  const [listChoice, setListChoice] = React.useState(false);
  const [shareBar, setShareBar] = React.useState(false);
  const [kpiList, setKpiList] = React.useState([]);
  const [listKPIId, setListKPIId] = React.useState(0);
  const [dialogPage, setDialogPage] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [selectedGA, setSelectedGA] = React.useState([]);
  const [listEdited, setListEdited] = React.useState(false);
  const [listChanged, setListChanged] = React.useState(false);
  const [displayList, setDisplayList] = React.useState(true);
  const [groups, setGroups] = React.useState();
  const [gA, setGA] = React.useState();
  const [people, setPeople] = React.useState();
  const [edit, setEdit] = React.useState(false);
  const { t } = useTranslation();

  const PopupClose = () => {
    setShowKPI(false);
    setEdit(false);
    setSelected([]);
    setSelectedGA([]);
  };

  const newKPI = (event) => {
    setListKPIId(listKPIId);
    setListChoice(true);
    setEdit(true);
    setShowKPI(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteList = (value) => {
    props
      .kpiListDelete({ variables: { listId: value } })
      .then((data) => {
        if (data.data.kpiListDelete) {
          setValue(0);
          refetchKPI();
        }
      })
      .then(() => {})
      .catch((error) => {});
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const KPI_LIST = gql`
    query getStartupKPILists {
      getStartupKPILists {
        id
        title
        categories {
          id
          title
          kpis {
            id
            kpiId
            createdAt
            description
            updatedAt
            title
            currency
            dataFormat
            dataFrequency
            type
            datas {
              date
              reached
              target
            }
          }
        }
      }
    }
  `;

  const { loading, data, refetch: refetchKPI } = useQuery(KPI_LIST, {
    fetchPolicy: 'network-only',
    variables: {},
  });

  const GA_VIEW_LIST = gql`
    query getUserGoogleAnalyticsViews {
      getUserGoogleAnalyticsViews {
        id
        name
        property {
          id
          name
          view {
            id
            name
          }
        }
      }
    }
  `;

  const { loading: loadingGA, data: dataGA, refetch: refetchGA } = useQuery(GA_VIEW_LIST, {
    fetchPolicy: 'network-only',
    variables: {},
  });

  const GET_GROUPS = gql`
    query getStartupGroupsSearch {
      getStartupGroupsSearch {
        id
        title
      }
    }
  `;

  const { loading: loadingGroup, data: dataGroup } = useQuery(GET_GROUPS, {
    fetchPolicy: 'network-only',
    variables: {},
  });

  const GET_PEOPLE = gql`
    query getStartupPersonsSearch {
      getStartupPersonsSearch {
        id
        getName
      }
    }
  `;
  const { loading: loadingPeople, data: dataPeople } = useQuery(GET_PEOPLE, {
    fetchPolicy: 'network-only',
    variables: {},
  });
  React.useEffect(() => {
    if (!loading) {
      if(window.location.href.includes('ga')){
        setShowKPI(true);
        setDialogPage(2);
        props.history.push('/verilerim');
      }
      if (data) {
        setKpiList(data.getStartupKPILists);
        if (data.getStartupKPILists && data.getStartupKPILists.length > 0) {
          setListKPIId(data.getStartupKPILists[0].id);
        }
      }
    }
    if (!loadingPeople) {
      if (dataPeople) {
        setPeople(dataPeople.getStartupPersonsSearch);
      }
    }
    if (!loadingGroup) {
      if (dataGroup) {
        setGroups(dataGroup.getStartupGroupsSearch);
      }
    }
    if (!loadingGA) {
      if (dataGA) {
        setGA(dataGA.getUserGoogleAnalyticsViews);
      }
    }
  }, [data, loading, loadingGroup, dataGroup, loadingPeople, dataPeople, loadingGA, dataGA]);

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        {!loading && (
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'min-content', maxWidth: 1320, paddingRight: 35 }}
            >
              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setShareBar(false)}
                open={shareBar}
              >
                <Alert severity="success">KPI seçtiğiniz kişilerle paylaşıldı.</Alert>
              </Snackbar>
              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setListEdited(false)}
                open={listEdited}
              >
                <Alert severity="success">Seçtiğiniz KPI listeye eklendi.</Alert>
              </Snackbar>
              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setListChanged(false)}
                open={listChanged}
              >
                <Alert severity="success">Yaptığınız değişiklikler kaydedildi.</Alert>
              </Snackbar>
              <Grid item style={{ width: '100%' }}>
                <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 36 }}>
                  <Grid item style={{ display: 'flex', paddingLeft: 12 }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                      <FolderOutlinedIcon style={{ fontSize: 20, marginTop: 5 }} />
                      {showKPI ? (
                        <Typography variant="h4" style={{ color: '#A0AEC0' }}>
                          Verilerim
                        </Typography>
                      ) : (
                        <Typography variant="h4">Verilerim</Typography>
                      )}
                      {showKPI &&
                        (!edit ? (
                          <Typography variant="h4">Yeni KPI Ekleyin</Typography>
                        ) : (
                          <Typography variant="h4">KPI Düzenleyin</Typography>
                        ))}
                    </Breadcrumbs>
                  </Grid>
                  {showKPI && (
                    <Grid container style={{ paddingLeft: 12 }}>
                      <Typography variant="body2" style={{ marginTop: 32 }}>
                        {t('Startup Borsa’nın farklı iş modellerine yönelik sunduğu hazır temel performans')} <br />
                        {t('göstergelerini kullanabilir ya da yeni bir performans seti oluşturabilirsiniz.')}
                      </Typography>
                    </Grid>
                  )}
                  {showKPI && (
                    <KPIDialog
                      setIsAdded={setIsAdded}
                      dialogPage={dialogPage}
                      isAdded={isAdded}
                      setListEdited={setListEdited}
                      selected={selected}
                      selectedGA={selectedGA}
                      edit={edit}
                      kpiGoogleAnalytics={props.kpiGoogleAnalytics}
                      kpiList={kpiList}
                      kpiListEdit={props.kpiListEdit}
                      gA={gA}
                      setEdit={setEdit}
                      setSelected={setSelected}
                      setSelectedGA={setSelectedGA}
                      kpiCreate={props.kpiCreate}
                      kpiSuggest={props.kpiSuggest}
                      listChoice={listChoice}
                      listKPIId={listKPIId}
                      setListChoice={setListChoice}
                      open={showKPI}
                      refetchKPI={refetchKPI}
                      onClose={() => PopupClose()}
                    />
                  )}
                  {!showKPI && kpiList.length > 0 && (
                    <Grid item style={{ display: 'flex' }}>
                      <Button
                        variant="containedPrimary"
                        onClick={() => setShowKPI(true)}
                        style={{
                          fontWeight: 'normal',
                          padding: '16px 24px',
                          fontSize: 14,
                          borderRadius: 8,
                          fontFamily: 'InterRegular',
                        }}
                      >
                        {t('Yeni Liste Ekle')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!showKPI && kpiList.length < 1 ? (
                <Grid container xs={12} spacing={3} style={{ margin: 0 }}>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} className={classes.whiteCard}>
                      <Grid item className={classes.photoCard}>
                        <img src={kpiIcon1} alt="kpi-logo-1" />
                      </Grid>
                      <Typography align="center" variant="h3" style={{ marginBottom: 24, color: '#003CA5' }}>
                        {t('KPI’larınızı')} <br /> {t('Yönetin')}
                      </Typography>
                      <Typography align="center" variant="body2" style={{ color: '#425466' }}>
                        {t(
                          "Startup Borsa'nın farklı iş modellerine yönelik oluşturduğu hazır KPI listelerinden birini seçebilir veya girişiminize özel KPI listesi oluşturabilirsiniz.",
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} className={classes.whiteCard}>
                      <Grid item className={classes.photoCard}>
                        <img src={kpiIcon2} alt="kpi-logo-2" />
                      </Grid>
                      <Typography align="center" variant="h3" style={{ marginBottom: 24, color: '#003CA5' }}>
                        {t('Verileri İstediğiniz')}
                        <br />
                        {t('Görünümde Takip Edin')}
                      </Typography>
                      <Typography align="center" variant="body2" style={{ color: '#425466' }}>
                        {t(
                          'Startup Borsa üzerinden takip etmek istediğiniz performans göstergelerini kolaylıkla görselleştirebilir ve bu göstegeleri haftalık, aylık, çeyreklik ve yıllık olarak takip edebilirsiniz.',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid item xs={12} className={classes.whiteCard}>
                      <Grid item className={classes.photoCard}>
                        <img src={kpiIcon3} alt="kpi-logo-3" />
                      </Grid>
                      <Typography align="center" variant="h3" style={{ marginBottom: 24, color: '#003CA5' }}>
                        {t('Yatırımcılarla')}
                        <br />
                        {t('Paylaşın')}
                      </Typography>
                      <Typography align="center" variant="body2" style={{ color: '#425466' }}>
                        {t(
                          'Startup Borsa kullanıcılarına yaptığı rutin hatırlatmalar ile verilerin düzenli olarak paylaşımını garantiliyor ve girişim gelişiminin kolay takibini sağlıyor. Platformda paylaşılan verileri dilediğiniz kişi veya kuruluşlarla paylaşabilirsiniz.',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="containedPrimary"
                      onClick={() => setShowKPI(true)}
                      style={{
                        fontWeight: 'normal',
                        padding: '11.5px 30px',
                        width: '100%',
                        background: '#0054E8',
                        fontSize: 16,
                        marginBottom: 50,
                      }}
                    >
                      {t('Hemen Başla')} <img src={arrowRight} alt="arrow-right" style={{ marginLeft: 16 }} />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                !showKPI && (
                  <Grid className={classes.whiteGrid}>
                    {!showKPI && (
                      <Grid container>
                        <Grid className={classes.appBarGrid}>
                          <AppBar
                            style={{ marginBottom: 10, zIndex: 0, borderRight: '3px solid #EDF2F7' }}
                            position="static"
                            color="default"
                          >
                            {kpiList && (
                              <>
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  style={{ borderBottom: 'none', position: 'relative' }}
                                  indicatorColor="#ffffff"
                                  textColor="primary"
                                  scrollButtons="on"
                                  variant="scrollable"
                                  aria-label="scrollable auto tabs example"
                                  classes={{
                                    flexContainer: classes.flexContainerTab,
                                  }}
                                >
                                  {kpiList.map((kpiList, index) =>
                                    value === index ? (
                                      <Grid item className={classes.selectedApp}>
                                        <Tab
                                          classes={{
                                            root: classes.tabRoot,
                                            textColorPrimary: classes.textColorP,
                                            wrapper: classes.wrap,
                                          }}
                                          style={{ background: '#118DFF' }}
                                          label={
                                            <>
                                              {kpiList.title}
                                              {value === index && (
                                                <ListPopover
                                                  setListChanged={setListChanged}
                                                  kpiListChangeName={props.kpiListChangeName}
                                                  setShareBar={setShareBar}
                                                  history={props.history}
                                                  groups={groups}
                                                  people={people}
                                                  startupKPIToReport={props.startupKPIToReport}
                                                  refetchKPI={refetchKPI}
                                                  kpiList={kpiList}
                                                  deleteList={deleteList}
                                                />
                                              )}
                                            </>
                                          }
                                          onClick={() => setListKPIId(kpiList.id)}
                                          {...a11yProps(index + 1)}
                                        />
                                        <img src={appBarBottom} alt="app-bar-bottom" style={{ width: 12 }} />
                                      </Grid>
                                    ) : (
                                      <Tab
                                        classes={{
                                          root: classes.tabRoot,
                                          textColorPrimary: classes.textColorP,
                                          wrapper: classes.wrap,
                                        }}
                                        label={
                                          <>
                                            {kpiList.title}
                                            {value === index && (
                                              <ListPopover
                                                setListChanged={setListChanged}
                                                kpiListChangeName={props.kpiListChangeName}
                                                setShareBar={setShareBar}
                                                groups={groups}
                                                people={people}
                                                startupKPIToReport={props.startupKPIToReport}
                                                refetchKPI={refetchKPI}
                                                kpiList={kpiList}
                                                deleteList={deleteList}
                                              />
                                            )}
                                          </>
                                        }
                                        onClick={() => setListKPIId(kpiList.id)}
                                        {...a11yProps(index + 1)}
                                      />
                                    ),
                                  )}
                                </Tabs>
                              </>
                            )}
                          </AppBar>
                        </Grid>
                        <Grid xs={12} md style={{ padding: 10, paddingLeft: 24 }}>
                          <Hidden smDown>
                            {displayList ? (
                              <Button
                                variant="containedPrimary"
                                onClick={() => setDisplayList(true)}
                                style={{
                                  fontWeight: 'normal',
                                  padding: '10.5px 20px',
                                  minWidth: 37,
                                  width: 37,
                                  height: 37,
                                  borderRadius: 8,
                                  marginRight: 12,
                                  background: '#118DFF',
                                  transition: '0.5s',
                                }}
                              >
                                <img src={list} alt={list} style={{ width: 16 }} />
                              </Button>
                            ) : (
                              <Button
                                variant="containedPrimary"
                                onClick={() => setDisplayList(true)}
                                style={{
                                  fontWeight: 'normal',
                                  padding: '10.5px 20px',
                                  minWidth: 37,
                                  width: 37,
                                  height: 37,
                                  borderRadius: 8,
                                  marginRight: 12,
                                  background: '#CBD5E0',
                                  transition: '0.5s',
                                }}
                              >
                                <img src={list} alt={list} style={{ width: 16 }} />
                              </Button>
                            )}
                          </Hidden>
                          <Hidden smDown>
                            {displayList ? (
                              <Button
                                variant="containedPrimary"
                                onClick={() => setDisplayList(false)}
                                style={{
                                  fontWeight: 'normal',
                                  padding: '10.5px 20px',
                                  minWidth: 37,
                                  width: 37,
                                  height: 37,
                                  borderRadius: 8,
                                  background: '#CBD5E0',
                                  transition: '0.5s',
                                }}
                              >
                                <img src={chart} alt={chart} style={{ width: 16 }} />
                              </Button>
                            ) : (
                              <Button
                                variant="containedPrimary"
                                onClick={() => setDisplayList(false)}
                                style={{
                                  fontWeight: 'normal',
                                  padding: '10.5px 20px',
                                  minWidth: 37,
                                  width: 37,
                                  height: 37,
                                  borderRadius: 8,
                                  background: '#118DFF',
                                  transition: '0.5s',
                                }}
                              >
                                <img src={chart} alt={chart} style={{ width: 16 }} />
                              </Button>
                            )}
                          </Hidden>
                        </Grid>
                      </Grid>
                    )}
                    {!showKPI &&
                      kpiList.map((kpiList, index) => (
                        <TabPanel value={value} index={index} style={{ width: '100%' }}>
                          {displayList ? (
                            <TableContainer
                              className={classes.table}
                              style={{ maxHeight: 500, trainsition: '0.5s' }}
                              component={Paper}
                            >
                              <Table aria-label="customized table" stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell align="left">
                                      <Typography variant="h6" className={classes.tableTitle}>
                                        {kpiList.title}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <Typography variant="h6" className={classes.tableTitle}>
                                        {t('Format')}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <Typography variant="h6" className={classes.tableTitle}>
                                        {t('Sıklık')}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <Typography variant="h6" className={classes.tableTitle}>
                                        {t('Son Düzenleme Tarihi')}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                    <StyledTableCell align="right">
                                      <Typography
                                        variant="h6"
                                        onClick={() => {
                                          newKPI();
                                          setDialogPage(0);
                                        }}
                                        style={{ color: '#003CA5', cursor: 'pointer', display: 'flex', fontSize: 14 }}
                                        className={classes.tableTitle}
                                      >
                                        <img src={newPlusBlue} alt="new-plus" style={{ marginRight: 8 }} />
                                        {t('Yeni KPI Ekle')}
                                      </Typography>
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {kpiList.categories.map((kpiCategories) => (
                                    <ExpandingRow
                                      setShareBar={setShareBar}
                                      groups={groups}
                                      people={people}
                                      startupKPIToReport={props.startupKPIToReport}
                                      listId={kpiList.id}
                                      kpiCategories={kpiCategories}
                                      kpiCategoryDelete={props.kpiCategoryDelete}
                                      kpiDelete={props.kpiDelete}
                                      refetchKPI={refetchKPI}
                                    />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Grid style={{ background: '#fff' }}>
                              {kpiList.categories.map((kpiCategories) => (
                                <ExpandingCategory
                                  setShareBar={setShareBar}
                                  groups={groups}
                                  people={people}
                                  startupKPIToReport={props.startupKPIToReport}
                                  listId={kpiList.id}
                                  kpiCategories={kpiCategories}
                                  kpiCategoryDelete={props.kpiCategoryDelete}
                                  kpiDelete={props.kpiDelete}
                                  refetchKPI={refetchKPI}
                                />
                              ))}
                            </Grid>
                          )}
                        </TabPanel>
                      ))}
                  </Grid>
                )
              )}
            </Grid>
            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setIsAdded(false)}
              open={isAdded}
            >
              <Alert severity="success">Liste Eklendi.</Alert>
            </Snackbar>
          </Grid>
        )}
      </main>
    </>
  );
}
