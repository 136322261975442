
import React, { useEffect } from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import 'moment/locale/tr'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';
import {DebounceInput} from 'react-debounce-input';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import newEyeWhite from "../../static/icon/eye-on.svg";
import newEyeBlue from "../../static/icon/eye-on-blue.svg";
import newEyeOff from "../../static/icon/eye-off.svg";
import newEyeOffWhite from "../../static/icon/eye-off-white.svg";
import newEyeOffBlue from "../../static/icon/eye-off-blue.svg";
import targetIcon from "../../static/icon/target-icon.svg";
import sbIcon from "../../static/icon/sb-amblem.png";
import gaIcon from "../../static/icon/ga-amblem.svg";
import upTarget from "../../static/icon/up-target.svg";
import downTarget from "../../static/icon/down-target.svg";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  TabsGeneral: {
    background: '#fff',
    borderRadius:16,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    marginBottom:16,
    paddingLeft:36,
    marginTop:36
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:14,
    color: '#000',
    fontWeight:600,
    height: '35px',
    border: 'none',
    textAlign:'center',
    borderRadius: 0,
    outline: 'none',
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    background: '#fff',
    '&:focus': {
      borderBottom: '3px solid #003CA5',
      color: '#003CA5',
    },
    '&::placeholder': {
      fontSize:9,
      textTransform:'uppercase',
      fontWeight:'normal',
      color:'#A0AEC0'
    },
    [theme.breakpoints.down('xs')]: {
        height: '40px',
    },
  },
  multiSelect: {
    maxHeight:40,
    borderRadius:0,
    '& div': {
      background: 'none!important',
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filter: {
    height:40,
    padding: '0 15px',
    border: '1px solid rgb(221 221 221)',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    marginBottom:50,
  },
  tabPaper: {
    marginTop:50,
  },
  textColorPrimary: {
    fontWeight:600,
    color: '#718096'
  },
  targetButton:{
    maxHeight:50,
    maxWidth:208,
    padding:'8px 16px',
    borderRadius:24,
    fontWeight:'normal',
    transition:'0.5s',
    zIndex:2,
    '&:hover': {
      background:'#003CA5',
    }
  },
  targetButtonDisabled:{
    maxHeight:50,
    maxWidth:208,
    background:'#F0F5FF',
    padding:'8px 16px',
    borderRadius:24,
    fontWeight:'normal',
    transition:'0.5s',
    zIndex:1,
    color:'#003CA5',
    '&:hover': {
      background:'#F0F5FF',
    }
  },
  showGrid:{
    background:'rgb(247, 247, 252)',
    display: 'flex',
    padding: '18px!important',
    border: '1px solid rgba(50, 50, 71, 0.05)!important',
    borderLeft: 'none!important',
    borderRadius:16,
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: '#8492A6',
    fontSize:10,
    padding:'14px 10px',
    borderRight:'2px solid #F0F5FF'
  },
  body: {
    fontSize: 14,
    border:'none',
    padding: '10px',
    borderRight:'2px solid #F0F5FF'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

function InlineEdit(props){
  const classes = useStyles();
  const { reached, date, kpiId, dataKpi, isFocus, refetch, setShowSave, type } = props;
  const [data, setData] = React.useState();
  const [showNotify, setShowNotify] = React.useState(false);
  const [achieved, setAchieved] = React.useState();
  let today = new Date().getTime();

  React.useEffect(() => {
    if (typeof data === 'string') {
    props.kpiDataUpdate({ variables: { date:date, kpiId:kpiId, reached:parseFloat(removeCommas(data)) } })
    .then(data => {
      if (data.data.kpiDataUpdate) {
        setTimeout(() => {
          if(showNotify){
            setShowSave('edildi');
          }
        }, 1000)
        refetch();
      }
    }).then(() => {
      setTimeout(() => {
        setShowSave('');
      }, 2000)
    })
    }
  }, [data]);

  React.useEffect(() => {
      let a = dataKpi.filter((x) => x.date === date);
      if(a.length > 0){
        if(a[0].isAchieved === true)
        {
          setAchieved(true); 
        }
        else if(a[0].isAchieved === false)
        {
          setAchieved(false); 
        }

        setData(a[0].reached ? a[0].reached : '')
      }else{
        setData('')
      }
  }, [date, dataKpi]);

  const handleData = event => {
    setShowNotify(true);
    setShowSave('kayit');
    setData(event.target.value)
  }
  
  const removeCommas = (value) => {
    let result = value.replace(/,/g, "");
    result = result.replace(/%/g, "");
    return result
  }

  return (
    !type || type === 'SB' ?
    <>
    {(achieved !== null && achieved !== undefined) ?
      <>
        {achieved ? (
          today < date ?
          <DebounceInput
            autoFocus={isFocus}
            class="inputTypeSearch"
            name="update"
            type="number"
            disabled
            value={data}
            autoComplete="off"
            placeholder="KPI Verisi Ekleyin"
            className={classes.Debounce}
            debounceTimeout={400}
            onChange={handleData}
          />
          :
          <Grid container style={{flexWrap: 'nowrap'}}>
            {data !== '' &&
              <img src={upTarget} alt="target-up" />
            }
            <DebounceInput
            autoFocus={isFocus}
            class="inputTypeSearch"
            name="update"
            type="number"
            value={data}
            autoComplete="off"
            style={{paddingRight:18}}
            placeholder="KPI Verisi Ekleyin"
            className={classes.Debounce}
            debounceTimeout={400}
            onChange={handleData}
          />
        </Grid>
        ) : (
          today < date ?
          <DebounceInput
            autoFocus={isFocus}
            class="inputTypeSearch"
            name="update"
            type="number"
            value={data}
            disabled
            placeholder="KPI Verisi Ekleyin"
            className={classes.Debounce}
            debounceTimeout={400}
            onChange={handleData}
          />
          :
          <Grid container style={{flexWrap: 'nowrap'}}>
            {data !== '' &&
              <img src={downTarget} alt="target-down" />
            }
            <DebounceInput
              autoFocus={isFocus}
              class="inputTypeSearch"
              name="update"
              type="number"
              value={data}
              style={{paddingRight:18}}
              autoComplete="off"
              placeholder="KPI Verisi Ekleyin"
              className={classes.Debounce}
              debounceTimeout={400}
              onChange={handleData}
            />
          </Grid>
        )}
      </>
      :
      today < date ?
      <DebounceInput
      autoFocus={isFocus}
      class="inputTypeSearch"
      name="update"
      type="number"
      value={data}
      autoComplete="off"
      disabled
      placeholder="KPI Verisi Ekleyin"
      className={classes.Debounce}
      debounceTimeout={400}
      onChange={handleData}
      />
      :
      <DebounceInput
        autoFocus={isFocus}
        class="inputTypeSearch"
        name="update"
        type="number"
        value={data}
        autoComplete="off"
        placeholder="KPI Verisi Ekleyin"
        className={classes.Debounce}
        debounceTimeout={400}
        onChange={handleData}
      />
      }
    </>
    :
    <DebounceInput
      autoFocus={isFocus}
      class="inputTypeSearch"
      name="update"
      type="number"
      disabled
      value={data}
      autoComplete="off"
      placeholder="Veri Bulunamadı"
      className={classes.Debounce}
      debounceTimeout={400}
      onChange={handleData}
    />
  );
}

function InlineEdit2(props){
  const classes = useStyles();
  const { reached, date, kpiId, dataKpi, refetch, isFocus, setShowSave } = props;
  const [achieved, setAchieved] = React.useState();
  const [showNotify, setShowNotify] = React.useState(false);
  const [data, setData] = React.useState();

  React.useEffect(() => {
    if (typeof data === 'string') {
    props.kpiDataUpdate({ variables: { date:date, kpiId:kpiId, target:parseFloat(removeCommas(data)) } })
    .then(data => {
      if (data.data.kpiDataUpdate) {
        setTimeout(() => {
          if(showNotify){
            setShowSave('edildi');
          }
        }, 500)
        refetch();
      }
    }).then(() => {
      setTimeout(() => {
        setShowSave('');
      }, 2000)
    })
    }
  }, [data]);

  React.useEffect(() => {
      let a = dataKpi.filter((x) => x.date === date);
      if(a.length > 0){
        if(a[0].isAchieved === true)
        {
          setAchieved(true); 
        }
        else if(a[0].isAchieved === false)
        {
          setAchieved(false); 
        }
        setData(a[0].target ? a[0].target : '')
      }else{
        setData('')
      }
  }, [date, dataKpi]);

  const handleData = event => {
    setShowNotify(true);
    setShowSave('kayit');
    setData(event.target.value)
  }

  const removeCommas = (value) => {
    let result = value.replace(/,/g, "");
    result = result.replace(/%/g, "");
    return result
  }

  return (
    <>
    {(achieved !== null && achieved !== undefined) ?
    <>
    {achieved ? (
    <DebounceInput
      class="inputTypeSearch"
      type="number"
      name="update"
      style={{ color: '#66CB9F' }}
      value={data}
      autoComplete="off"
      placeholder="Hedef Veri Ekleyin"
      className={classes.Debounce}
      debounceTimeout={400}
      onChange={handleData}
    />
    ) 
    :
    (
      <DebounceInput
        class="inputTypeSearch"
        type="number"
        name="update"
        value={data}
        autoComplete="off"
        style={{ color: '#F16063' }}
        placeholder="Hedef Veri Ekleyin"
        className={classes.Debounce}
        debounceTimeout={400}
        onChange={handleData}
      />
    )}
    </>
    :
    <DebounceInput
      class="inputTypeSearch"
      type="number"
      name="update"
      value={data}
      autoComplete="off"
      placeholder="Hedef Veri Ekleyin"
      className={classes.Debounce}
      debounceTimeout={400}
      onChange={handleData}
    />
    }
    </>
  )
}

function ExpandingRow2(props){
  const classes = useStyles();
  const [open,setOpen] = React.useState(false);
  const { category, dates, kpi, setIsTarget, focusKpi, refetch, allTargets, setShowSave } = props;

  React.useEffect(() => {
    setOpen(allTargets);
    setIsTarget(allTargets);
  },
  [allTargets])

  const handleOpen = event => {
    setOpen(true);
    setIsTarget(true);
  }

  const handleClose= event => {
    setIsTarget(false);
    setOpen(false);
  }
  return (
    <>
      {!open ? (
        <>
        <StyledTableRow key={kpi.id} style={{ background: 'rgb(247, 247, 252)', height:35 }}>
          <StyledTableCell align="left" style={{position:'relative', border: 'none'}}>
            <Typography variant="h6" style={{fontSize:12, color: '#003CA5', position:'absolute', width:500, top:5, left:4}}>
              {kpi.title}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>    
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>    
          <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>                               
        </StyledTableRow>
        <StyledTableRow key={kpi.title} style={{ transition: '0.5s', background:'rgb(247, 247, 252)' }}>
          <StyledTableCell align="left" style={{ background:'rgb(247, 247, 252)', padding:0, borderBottom:'1px solid rgba(50, 50, 71, 0.05)', borderTop: '1px solid rgba(50, 50, 71, 0.05)' }}>
            <Grid item style={{ background:'#fff', borderTopLeftRadius:16, borderBottomLeftRadius:16, padding:'9px 14px 11px 18px', minHeight:55, display:'flex', alignItems:'center' }}>
              {kpi.type === 'SB' && <img src={sbIcon} alt="sb-icon" style={{ width:18 }} />}
              {kpi.type === 'GA' && <img src={gaIcon} alt="ga-icon" style={{ width:18 }} />}
            </Grid>
          </StyledTableCell>
          {dates.map((date, index) => (
            <>
            {dates.length === index + 1 
            ? 
            <StyledTableCell align="left" style={{ borderTop: '1px solid rgba(50, 50, 71, 0.05)',borderBottom:'1px solid rgba(50, 50, 71, 0.05)', background:'rgb(247, 247, 252)', padding:0 }}>
              <Grid item style={{ background:'#fff', borderTopRightRadius:16, borderBottomRightRadius:16, padding:'9px 14px 11px 18px' }}>
                <InlineEdit type={kpi.type} setShowSave={setShowSave} kpiDataUpdate={props.kpiDataUpdate} refetch={refetch} dataKpi={kpi.datas} date={date} kpiId={kpi.id} isFocus={kpi.id === focusKpi && index === Math.floor(dates.length / 2)} />
              </Grid>
            </StyledTableCell>
            :    
            <StyledTableCell align="left" style={{ borderTop: '1px solid rgba(50, 50, 71, 0.05)', borderBottom:'1px solid rgba(50, 50, 71, 0.05)', background:'#fff' }}>
              <InlineEdit type={kpi.type} setShowSave={setShowSave} kpiDataUpdate={props.kpiDataUpdate} refetch={refetch} dataKpi={kpi.datas} date={date} kpiId={kpi.id} isFocus={kpi.id === focusKpi && index === Math.floor(dates.length / 2)} />
            </StyledTableCell>
            }    
            </>
          ))}
          <StyledTableCell align="left" className={classes.showGrid}>
            <img src={newEyeBlue} alt="new-eye-blue" style={{ cursor:'pointer' }} onClick={() => handleOpen()}/>
          </StyledTableCell>
        </StyledTableRow>
        </>
      ) : (
        <>
          <StyledTableRow key={kpi.id} style={{ background: 'rgb(247, 247, 252)', height:35 }}>
            <StyledTableCell align="left" style={{position:'relative', border: 'none'}}>
              <Typography variant="h6" style={{fontSize:12, color: '#003CA5', position:'absolute', width:500, top:5, left:4}}>
                {kpi.title}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>    
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>    
            <StyledTableCell align="left" style={{border: 'none'}}></StyledTableCell>                               
          </StyledTableRow>
          <StyledTableRow key={kpi.title} style={{background:'rgb(247, 247, 252)'}}>
            <StyledTableCell align="left" style={{ background:'rgb(247, 247, 252)', padding:0, borderBottom:'1px solid rgba(50, 50, 71, 0.05)', borderTop: '1px solid rgba(50, 50, 71, 0.05)' }}>
              <Grid item style={{ background:'#fff', borderTopLeftRadius:16, borderBottomLeftRadius:0, padding:'9px 14px 11px 18px', minHeight:55, display:'flex', alignItems:'center'}}>
                {kpi.type === 'SB' && <img src={sbIcon} alt="sb-icon" style={{ width:18 }} />}
                {kpi.type === 'GA' && <img src={gaIcon} alt="ga-icon" style={{ width:18 }} />}
              </Grid>
            </StyledTableCell>
            {dates.map((date, index) => (
              <>
              {dates.length === index + 1 
              ? 
              <StyledTableCell style={{ borderBottom:'1px solid rgba(50, 50, 71, 0.05)', background:'rgb(247, 247, 252)', padding:0 }} align="left">
                <Grid style={{ background:'#fff', borderTopRightRadius:16, borderBottomRightRadius:0, padding:'9px 14px 11px 18px' }} item>
                  <InlineEdit type={kpi.type} setShowSave={setShowSave} kpiDataUpdate={props.kpiDataUpdate} refetch={refetch} dataKpi={kpi.datas} date={date} kpiId={kpi.id} isFocus={kpi.id === focusKpi && index === Math.floor(dates.length / 2)} />
                </Grid>
              </StyledTableCell>
              :
              <StyledTableCell style={{ borderTop: '1px solid rgba(50, 50, 71, 0.05)', borderBottom:'1px solid rgba(50, 50, 71, 0.05)', background:'#fff' }} align="left">
                <InlineEdit type={kpi.type} setShowSave={setShowSave} kpiDataUpdate={props.kpiDataUpdate} refetch={refetch} dataKpi={kpi.datas} date={date} kpiId={kpi.id} isFocus={kpi.id === focusKpi && index === Math.floor(dates.length / 2)} />
              </StyledTableCell>
              }
              </>
            ))}
            <StyledTableCell align="left"></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow key={category.title} style={{ transition: '0.5s', background:'rgb(247, 247, 252)' }}>
            <StyledTableCell align="left" style={{ background:'rgb(247, 247, 252)', padding:0, borderBottom:'1px solid rgba(50, 50, 71, 0.05)', borderTop: '1px solid rgba(50, 50, 71, 0.05)' }}>
              <Grid item style={{ background:'#fff', borderTopLeftRadius:0, borderBottomLeftRadius:16, padding:'9px 14px 11px 18px', minHeight:55, display:'flex', alignItems:'center' }}>
                <img src={targetIcon} alt="target-icon" />
              </Grid>
            </StyledTableCell>
            {dates.map((date, index) => (
              dates.length === index + 1 ?
              <StyledTableCell style={{ borderBottom:'1px solid rgba(50, 50, 71, 0.05)', background:'rgb(247, 247, 252)', padding:0 }} align="left">
                <Grid style={{ background:'#fff', borderTopRightRadius:0, borderBottomRightRadius:16, padding:'9px 14px 11px 18px' }} item>
                <InlineEdit2 setShowSave={setShowSave} kpiDataUpdate={props.kpiDataUpdate} refetch={refetch} dataKpi={kpi.datas} date={date} kpiId={kpi.id} isFocus={kpi.id === focusKpi && index === Math.floor(dates.length / 2)}/>
                </Grid>
              </StyledTableCell>
              :
              <StyledTableCell style={{ borderTop: '1px solid rgba(50, 50, 71, 0.05)', borderBottom:'1px solid rgba(50, 50, 71, 0.05)', background:'#fff' }} align="left">
                <InlineEdit2 setShowSave={setShowSave} kpiDataUpdate={props.kpiDataUpdate} refetch={refetch} dataKpi={kpi.datas} date={date} kpiId={kpi.id} isFocus={kpi.id === focusKpi && index === Math.floor(dates.length / 2)}/>
              </StyledTableCell>
            ))}
            <StyledTableCell align="left" className={classes.showGrid}>
              <img src={newEyeOffBlue} alt="new-eye-off-blue" style={{ cursor:'pointer' }} onClick={() => handleClose()}/>
            </StyledTableCell>
          </StyledTableRow>
        </>
      )}
    </>
  );
}

function ExpandingRow(props){
  const [open,setOpen] = React.useState(true);
  const { category, dates, activeDate, focusKpi, refetch, allTargets, setShowSave } = props;

  return(
    <>
      <StyledTableRow key={category.title} style={{ background: 'rgb(247, 247, 252)', height:16 }} >
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>    
        <StyledTableCell align="left"></StyledTableCell>    
        <StyledTableCell align="left"></StyledTableCell>    
      </StyledTableRow>  
      <StyledTableRow key={category.title} style={{ background: '#F0F5FF' }}>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{position:'relative', padding:'7px 15px'}}><Typography variant="h6" style={{fontSize:10, color: '#003CA5', textTransform: 'uppercase', position:'absolute', width:500, top:8, left:0}}>{category.title}</Typography></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>    
        <StyledTableCell align="left" style={{ padding:'7px 15px' }}></StyledTableCell>    
        <StyledTableCell align="center" style={{ padding:'7px 15px' }}>{open ?<ExpandLessIcon onClick={() => setOpen(!open)} style={{cursor:'pointer',transition:'0.5s', color: '#003CA5'}} /> : <ExpandMoreIcon onClick={() => setOpen(!open)} style={{cursor:'pointer',transition:'0.5s', color: '#003CA5'}} />}</StyledTableCell>                               
      </StyledTableRow>
      {open && 
      <>
        {category.kpis.map((kpi) => (
          <ExpandingRow2 setShowSave={setShowSave} allTargets={allTargets} category={category} refetch={refetch} setIsTarget={props.setIsTarget} isTarget={props.isTarget} activeDate={activeDate} kpi={kpi} dates={dates} kpiDataUpdate={props.kpiDataUpdate} focusKpi={focusKpi} />
        ))}
      </>
      }
    </>
  )
}

export default function EditKpi(props){
    const classes = useStyles();
    const {showSave, setShowSave} = props;
    const [focusKpi, setFocusKpi] = React.useState(null);
    const [focusFrequency, setFocusFrequency] = React.useState(null);
    const [categories, setCategories] = React.useState();
    const [dates, setDates] = React.useState();
    const [activeDate, setActiveDate] = React.useState();
    const [recentDate, setRecentDate] = React.useState();
    const [isTarget, setIsTarget] = React.useState(false);
    const [frequency, setFrequency] = React.useState(null);
    const [month, setMonth] = React.useState();
    const [year, setYear] = React.useState();
    const [week, setWeek] = React.useState();
    const [quarter, setQuarter] = React.useState();
    const [day, setDay] = React.useState();
    const [value, setValue] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [allTargets, setAllTargets] = React.useState(false);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const setDayTab = (value) => {
      setDay(value);
      setRecentDate(value)
    };

    const setMonthTab = (value) => {
      setMonth(value);
      setRecentDate(value)
    };

    const setYearTab = (value) => {
      setYear(value);
      setRecentDate(value)
    };

    const setWeekTab = (value) => {
      setWeek(value);
      setRecentDate(value)
    };

    const setQuarterTab = (value) => {
      setQuarter(value);
      setRecentDate(value)
    };

    if (!focusFrequency) {
      if (props.location.state) {
        if (props.location.state.focusKpi && props.location.state.focusFrequency){
          setFocusKpi(props.location.state.focusKpi)
          setFocusFrequency(props.location.state.focusFrequency)
        }else {
          setFocusFrequency("Aylık")
        }
      }else{
        setFocusFrequency("Aylık")
      }
    }
    React.useEffect(() => {
        if(focusFrequency){
          if (focusFrequency === "Günlük"){
            setFrequency('Günlük')
            setRecentDate(day)
            setValue(4)
          }else if(focusFrequency === "Yıllık"){
            setFrequency('Yıllık')
            setRecentDate(year)
            setValue(0)
          }
          else if(focusFrequency === "Haftalık"){
            setFrequency('Haftalık')
            setRecentDate(week)
            setValue(3)
          }
          else if(focusFrequency === "Çeyreklik"){
            setFrequency('Çeyreklik')
            setRecentDate(quarter)
            setValue(1)
          }else if(focusFrequency === "Aylık"){
            setFrequency('Aylık')
            setRecentDate(month)
            setValue(2)
          }
        }
      },
      [focusKpi, focusFrequency])
    
    const KPI_DATAS = gql`
        query getStartupKPIDatas($listId: ID!, $dataFrequency: String!, $date: String) {
          getStartupKPIDatas(listId:$listId, dataFrequency:$dataFrequency, date:$date){
            date
            activeDate
            data{
              id
              title
              kpis{
                id
                title
                currency
                updatedAt
                type
                datas{
                  id
                  date
                  reached
                  isAchieved
                  target
                }
              }
            }
        }
    }
    `;

    const { loading, data , refetch } = useQuery(KPI_DATAS, {
      fetchPolicy:"network-only",
      variables: { listId: props.match.params.id, dataFrequency:frequency, date:recentDate},
      skip: !frequency
  });

  const KPI_DATA_TABS = gql`
  query getStartupKPIDataTabs($listId: ID!) {
    getStartupKPIDataTabs(listId:$listId)
}
`;

const { loading:loadingTab, data:dataTab , refetch:refetchTab } = useQuery(KPI_DATA_TABS, {
fetchPolicy:"network-only",
variables: { listId: props.match.params.id},
skip: !frequency
});

  React.useEffect(() => {
    if(!loading){
        if(data){
            setActiveDate(data.getStartupKPIDatas.activeDate)
            setDates(data.getStartupKPIDatas.date)
            setCategories(data.getStartupKPIDatas.data);
        }
    }
  },
  [loading, data])


    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
            >
              <Grid item style={{ width: '100%' }}>
                <Grid container justify="space-between" alignItems="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                      <FolderOutlinedIcon style={{ fontSize: 20, marginTop: 5 }} />
                        <Link to="/verilerim" style={{ padding: 0 }}>
                          <Typography variant="h4" style={{ color: '#A0AEC0' }}>
                            Verilerim
                          </Typography>
                        </Link>  
                        <Typography variant="h4">Veri Ekle</Typography>
                    </Breadcrumbs>
                </Grid>
                {categories && (
                  <Grid container xs={12}>
                    <Grid container xs={12} className={classes.TabsGeneral}>
                      <Grid item xs={12} md={7}>
                        <AppBar style={{ zIndex: 1 }} position="static" color="default">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                          >
                            {dataTab && dataTab.getStartupKPIDataTabs.includes('Yıllık') ? 
                            <Tab
                              label="Yıllık"
                              classes={{
                                textColorPrimary:classes.textColorPrimary
                              }}
                              onClick={() => (setFrequency('Yıllık'), setRecentDate(year), setIsTarget(false))}
                              {...a11yProps(0)}
                            />
                            :
                            <Tab
                              style={{display:'none'}}
                              label="Yıllık"
                              onClick={() => (setFrequency('Yıllık'), setRecentDate(year), setIsTarget(false))}
                              {...a11yProps(0)}
                            />
                            }
                            {dataTab && dataTab.getStartupKPIDataTabs.includes('Çeyreklik') ? 
                            <Tab
                              label="Çeyreklik"
                              classes={{
                                textColorPrimary:classes.textColorPrimary
                              }}
                              onClick={() => (setFrequency('Çeyreklik'), setRecentDate(quarter), setIsTarget(false))}
                              {...a11yProps(2)}
                            />
                            :
                            <Tab
                              style={{display:'none'}}
                              label="Çeyreklik"
                              onClick={() => (setFrequency('Çeyreklik'), setRecentDate(quarter), setIsTarget(false))}
                              {...a11yProps(2)}
                            />
                            }
                            {dataTab && dataTab.getStartupKPIDataTabs.includes('Aylık') ? 
                            <Tab
                              label="Aylık"
                              classes={{
                                textColorPrimary:classes.textColorPrimary
                              }}
                              onClick={() => (setFrequency('Aylık'), setRecentDate(month), setIsTarget(false))}
                              {...a11yProps(1)}
                            />
                            :
                            <Tab
                              style={{display:'none'}}
                              label="Aylık"
                              onClick={() => (setFrequency('Aylık'), setRecentDate(month), setIsTarget(false))}
                              {...a11yProps(1)}
                            />
                            }
                            {dataTab && dataTab.getStartupKPIDataTabs.includes('Haftalık') ? 
                            <Tab
                              label="Haftalık"
                              classes={{
                                textColorPrimary:classes.textColorPrimary
                              }}
                              onClick={() => (setFrequency('Haftalık'), setRecentDate(week), setIsTarget(false))}
                              {...a11yProps(3)}
                            />
                            :
                            <Tab
                              style={{display:'none'}}
                              label="Haftalık"
                              onClick={() => (setFrequency('Haftalık'), setRecentDate(week), setIsTarget(false))}
                              {...a11yProps(3)}
                            />
                            }
                            {dataTab && dataTab.getStartupKPIDataTabs.includes('Günlük') ?  
                            <Tab
                              label="Günlük"
                              classes={{
                                textColorPrimary:classes.textColorPrimary
                              }}
                              onClick={() => (setFrequency('Günlük'), setRecentDate(day), setIsTarget(false))}
                              {...a11yProps(4)}
                            />
                            :
                            <Tab
                              style={{display:'none'}}
                              label="Günlük"
                              onClick={() => (setFrequency('Günlük'), setRecentDate(day), setIsTarget(false))}
                              {...a11yProps(4)}
                            />
                            }
                          </Tabs>
                        </AppBar>
                      </Grid>
                    
                      <Grid item xs={12} md={5} container justify="flex-end" alignItems="center" style={{paddingRight:24}}>
                        <Button variant="containedPrimary" onClick={() => setAllTargets(true)} style={allTargets ? {marginRight:-24} : {marginRight:0, paddingRight:30}} className={allTargets ? classes.targetButton : classes.targetButtonDisabled}><img src={allTargets ? newEyeWhite : newEyeBlue} alt="new-eye" style={{ marginRight:8 }} />Tüm Hedefleri göster</Button>
                        <Button variant="containedPrimary" onClick={() => setAllTargets(false)} style={!allTargets ? {marginLeft:-24} : {marginLeft:0, paddingLeft:30}} className={!allTargets ? classes.targetButton : classes.targetButtonDisabled}><img src={allTargets ? newEyeOff : newEyeOffWhite} alt="new-eye-off" style={{ marginRight:8 }} />Tüm Hedefleri gizle</Button>
                      </Grid>  
                    </Grid>
                    <TabPanel style={{ width: '100%' }} value={value} index={1}>
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none', maxHeight:450, borderRadius:'8px' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table" stickyHeader>
                            <TableHead>
                              {dates && (
                                <TableRow>
                                  <StyledTableCell align="left" style={{display:'flex'}}>                                              
                                    <KeyboardArrowLeftIcon
                                      onClick={() => setMonthTab(dates[3])}
                                      style={{
                                        fontSize: 26,
                                        marginRight: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                  {dates.map((date, index) => (
                                    <>
                                        <StyledTableCell align="center" style={{minWidth:112}}>
                                          <Typography
                                            variant="h6"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:10, color:'#8492A6' }}
                                          >
                                            {moment(parseInt(date)).locale('tr').format('MMMM YYYY').includes("Mart") && moment(parseInt(date)).locale('tr').format('YYYY') + ' 1.Çeyrek'}
                                            {moment(parseInt(date)).locale('tr').format('MMMM YYYY').includes("Haziran") && moment(parseInt(date)).locale('tr').format('YYYY') + ' 2.Çeyrek'}
                                            {moment(parseInt(date)).locale('tr').format('MMMM YYYY').includes("Eylül") && moment(parseInt(date)).locale('tr').format('YYYY') + ' 3.Çeyrek'}
                                            {moment(parseInt(date)).locale('tr').format('MMMM YYYY').includes("Aralık") && moment(parseInt(date)).locale('tr').format('YYYY') + ' 4.Çeyrek'}
                                          </Typography>
                                        </StyledTableCell>
                                    </>
                                  ))}
                                  <StyledTableCell align="left" style={{display:'flex', justifyContent:'flex-end'}}>                                              
                                    <KeyboardArrowRightIcon
                                      onClick={() => setMonthTab(dates[5])}
                                      style={{
                                        fontSize: 26,
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            {data.getStartupKPIDatas.data && data.getStartupKPIDatas.date && (
                              <TableBody>
                                {data.getStartupKPIDatas.data.map((category) => (
                                  <ExpandingRow
                                    setShowSave={setShowSave}
                                    refetch={refetch}
                                    isTarget={isTarget}
                                    setIsTarget={setIsTarget}
                                    allTargets={allTargets}
                                    category={category}
                                    activeDate={activeDate}
                                    dates={data.getStartupKPIDatas.date}
                                    kpiDataUpdate={props.kpiDataUpdate}
                                    focusKpi={focusKpi}
                                  />
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Grid>
                    </TabPanel>
                    <TabPanel style={{ width: '100%' }} value={value} index={0}>
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none',maxHeight:450, borderRadius:'8px' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table" stickyHeader>
                            <TableHead>
                              {dates && (
                                <TableRow>
                                  <StyledTableCell align="left" style={{display:'flex'}}>
                                    <KeyboardArrowLeftIcon
                                      onClick={() => setYearTab(dates[3])}
                                      style={{
                                        fontSize: 26,
                                        marginRight: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                  {dates.map((date, index) => (
                                    <>
                                        <StyledTableCell align="center" style={{minWidth:112}}>
                                          <Typography
                                            variant="h6"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:10, color:'#8492A6' }}
                                          >
                                            {moment(parseInt(date)).locale('tr').format('YYYY')}
                                          </Typography>
                                        </StyledTableCell>
                                    </>
                                  ))}
                                  <StyledTableCell align="left" style={{display:'flex', justifyContent:'flex-end'}}>
                                    <KeyboardArrowRightIcon
                                      onClick={() => setYearTab(dates[5])}
                                      style={{
                                        fontSize: 26,
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            {data.getStartupKPIDatas.data && data.getStartupKPIDatas.date && (
                              <TableBody>
                                {data.getStartupKPIDatas.data.map((category) => (
                                  <ExpandingRow
                                    setShowSave={setShowSave}
                                    refetch={refetch}
                                    isTarget={isTarget}
                                    setIsTarget={setIsTarget}
                                    allTargets={allTargets}
                                    category={category}
                                    activeDate={activeDate}
                                    dates={data.getStartupKPIDatas.date}
                                    kpiDataUpdate={props.kpiDataUpdate}
                                    focusKpi={focusKpi}
                                  />
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Grid>
                    </TabPanel>
                    <TabPanel style={{ width: '100%' }} value={value} index={2}>
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none',maxHeight:450, borderRadius:'8px' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table" stickyHeader>
                            <TableHead>
                              {dates && (
                                <TableRow>
                                  <StyledTableCell align="left" style={{display:'flex'}}>
                                    <KeyboardArrowLeftIcon
                                      onClick={() => setQuarterTab(dates[3])}
                                      style={{
                                        fontSize: 26,
                                        marginRight: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                  {dates.map((date, index) => (
                                    <>
                                        <StyledTableCell align="center" style={{minWidth:112}}>
                                          <Typography
                                            variant="h6"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:10, color:'#8492A6' }}
                                          >
                                            {moment(parseInt(date)).locale('tr').format('MMMM YYYY')}
                                          </Typography>
                                        </StyledTableCell>
                                    </>
                                  ))}
                                  <StyledTableCell align="left" style={{display:'flex', justifyContent:'flex-end'}}>
                                    <KeyboardArrowRightIcon
                                      onClick={() => setQuarterTab(dates[5])}
                                      style={{
                                        fontSize: 26,
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            {data.getStartupKPIDatas.data && data.getStartupKPIDatas.date && (
                              <TableBody>
                                {data.getStartupKPIDatas.data.map((category) => (
                                  <ExpandingRow
                                    setShowSave={setShowSave}
                                    refetch={refetch}
                                    isTarget={isTarget}
                                    setIsTarget={setIsTarget}
                                    allTargets={allTargets}
                                    category={category}
                                    activeDate={activeDate}
                                    dates={data.getStartupKPIDatas.date}
                                    kpiDataUpdate={props.kpiDataUpdate}
                                    focusKpi={focusKpi}
                                  />
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Grid>
                    </TabPanel>
                    <TabPanel style={{ width: '100%' }} value={value} index={3}>
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none',maxHeight:450, borderRadius:'8px' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table" stickyHeader>
                            <TableHead>
                              {dates && (
                                <TableRow>
                                  <StyledTableCell align="left" style={{display:'flex'}}>
                                    <KeyboardArrowLeftIcon
                                      onClick={() => setWeekTab(dates[3])}
                                      style={{
                                        fontSize: 26,
                                        marginRight: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                  {dates.map((date, index) => (
                                    <>
                                        <StyledTableCell align="center" style={{minWidth:112}}>
                                          <Typography
                                            variant="h6"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:10, color:'#8492A6' }}
                                          >
                                            {moment(parseInt(date)).locale('tr').format('DD/MM/YYYY')}

                                          </Typography>
                                        </StyledTableCell>
                                    </>
                                  ))}
                                  <StyledTableCell align="left" style={{display:'flex', justifyContent:'flex-end'}}>
                                    <KeyboardArrowRightIcon
                                      onClick={() => setWeekTab(dates[5])}
                                      style={{
                                        fontSize: 26,
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            {data.getStartupKPIDatas.data && data.getStartupKPIDatas.date && (
                              <TableBody>
                                {data.getStartupKPIDatas.data.map((category) => (
                                  <ExpandingRow
                                    setShowSave={setShowSave}
                                    refetch={refetch}
                                    isTarget={isTarget}
                                    setIsTarget={setIsTarget}
                                    allTargets={allTargets}
                                    category={category}
                                    activeDate={activeDate}
                                    dates={data.getStartupKPIDatas.date}
                                    kpiDataUpdate={props.kpiDataUpdate}
                                    focusKpi={focusKpi}
                                  />
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Grid>
                    </TabPanel>
                    <TabPanel style={{ width: '100%' }} value={value} index={4}>
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none',maxHeight:450, borderRadius:'8px' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table" stickyHeader>
                            <TableHead>
                              {dates && (
                                <TableRow>
                                  <StyledTableCell align="left" style={{display:'flex'}}>
                                      <KeyboardArrowLeftIcon
                                        onClick={() => setDayTab(dates[3])}
                                        style={{
                                          fontSize: 26,
                                          marginRight: 5,
                                          cursor: 'pointer',
                                        }}
                                      />
                                  </StyledTableCell>
                                  {dates.map((date, index) => (
                                    <>
                                        <StyledTableCell align="center" style={{minWidth:112}}>
                                          <Typography
                                            variant="h6"
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize:10, color:'#8492A6' }}
                                          >
                                            <Grid item>
                                            {moment(parseInt(date)).locale('tr').format('DD/MM/YYYY')}
                                            </Grid>
                                          </Typography>
                                        </StyledTableCell>
                                    </>
                                  ))}
                                  <StyledTableCell align="left" style={{display:'flex', justifyContent:'flex-end'}}>
                                    <KeyboardArrowRightIcon
                                      onClick={() => setDayTab(dates[5])}
                                      style={{
                                        fontSize: 26,
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            {data.getStartupKPIDatas.data && data.getStartupKPIDatas.date && (
                              <TableBody>
                                {data.getStartupKPIDatas.data.map((category) => (
                                  <ExpandingRow
                                    setShowSave={setShowSave}
                                    refetch={refetch}
                                    isTarget={isTarget}
                                    setIsTarget={setIsTarget}
                                    allTargets={allTargets}
                                    category={category}
                                    activeDate={activeDate}
                                    dates={data.getStartupKPIDatas.date}
                                    kpiDataUpdate={props.kpiDataUpdate}
                                    focusKpi={focusKpi}
                                  />
                                ))}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Grid>
                    </TabPanel>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </>
    );
}