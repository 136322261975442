
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Pagination from "@material-ui/lab/Pagination";
import {DebounceInput} from 'react-debounce-input';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import {getCurrentPeriod} from '../../helpers';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import newSortingIcon from '../../static/icon/newSortingIcon.svg';
import moment from 'moment'
import 'moment/locale/tr'
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField, useFormikContext } from 'formik';
import FormikTextField from "../../Components/FormikShortTextField";
import FormikDropdown from "../../Components/FormikDropdown";
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Info from '../../static/icon/info.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { reportSchema } from '../../yupSchemas';
import FormikSelect from '../../Components/FormikSelect';
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
import Image from '../../static/img/404.png';
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import filterIcon from '../../static/icon/filterIcon.svg';
import newCross from '../../static/icon/new-cross.svg';
registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  multiSelect: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  multiSelect2: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1"
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filter: {
    height:40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  CheckText: {
    wordBreak:'break-all',
    marginBottom:10,
    '& span': {
      fontSize:15,
      lineHeight:1.2
    }
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myPaper2: {
    padding:25,
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    padding: 25,
    marginBottom:50,
  },
  tabPaper: {
    marginTop:50,
    [theme.breakpoints.up('md')]: {
      paddingLeft:50
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:11,
    padding: '16px 0px 16px 24px',
  },
  body: {
    fontSize: 14,
    padding: '16px 0px 16px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

function ExpandingRow(props){
  const [openedPopoverId,setOpenedPopoverId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedPopoverId2,setOpenedPopoverId2] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [open,setOpen] = React.useState(false);
  const {report , groups, people} = props;
  
  const handleClick = (event, id) => {
    setOpenedPopoverId(id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenedPopoverId(null)
    setAnchorEl(null);
  };

  const handleClick2 = (event, id) => {
    setOpenedPopoverId2(id)
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setOpenedPopoverId2(null)
    setAnchorEl2(null);
  };

  const handleCreate = (value) => {
    props.startupReportItemCreate({ variables:{listId:value} })
    .then(data => {
      if (data.data.startupReportItemCreate) {
        props.history.push(`/rapor-duzenle/${data.data.startupReportItemCreate}`)
      } 
    }).then(() => {})
    .catch((error) => {
    });
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const openPop2 = Boolean(anchorEl2);
  const id2 = openPop2 ? 'simple-popover' : undefined;

return (
  <>
    <StyledTableRow key={report.id}>
      <StyledTableCell style={{ paddingLeft: 10 }} onClick={() => setOpen(!open)} component="th" scope="row">
        {open ? (
          <ExpandLessIcon color="primary" style={{ cursor: 'pointer', transition: '0.5s' }} />
        ) : (
          <ExpandMoreIcon color="primary" style={{ cursor: 'pointer', transition: '0.5s' }} />
        )}
      </StyledTableCell>
      <StyledTableCell style={{ paddingLeft: 15 }} onClick={() => setOpen(!open)} component="th" scope="row">
        <Typography variant="h6" style={{ fontSize: 14 }}>
          {report.title}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">{report.dataFrequency}</StyledTableCell>
      <StyledTableCell align="left">
        {moment(parseInt(report.updatedAt)).locale('tr').format('Do MMMM YYYY')}
      </StyledTableCell>
      <StyledTableCell align="left">
        {report.nextPostDate && moment(parseInt(report.nextPostDate)).locale('tr').format('Do MMMM YYYY')}
      </StyledTableCell>
      <StyledTableCell align="left">{report.isPublic === 'Evet' ? (
        <Typography style={{ display: 'flex', alignItems: 'center' }} variant="body2">
          Herkese Açık
        </Typography>
      ): (
        <Typography style={{ display: 'flex', alignItems: 'center' }} variant="body2">
          Herkese Kapalı
        </Typography>
      ) }</StyledTableCell>
      <StyledTableCell align="right" style={{paddingRight:25}}>
        {report.createNew &&
          <Button
            style={{ fontWeight: 'normal', fontSize: 14, width: '20px', marginRight: 10, padding: '10.5px 52px' }}
            variant="outlinedSecondary"
            onClick={() => handleCreate(report.id)}
          >
            Oluştur
          </Button>
        }
        <MoreVertIcon
          onClick={(event) => handleClick2(event, report.id)}
          style={{ color: '#8898aa', fontSize: 20, cursor: 'pointer' }}
        />
      </StyledTableCell>
      <ReportListPopup
        id={id2}
        groups={groups}
        people={people}
        startupReportListEdit={props.startupReportListEdit}
        report={report}
        refetch={props.refetch}
        handleClose={handleClose2}
        openedPopoverId={openedPopoverId2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
      />
    </StyledTableRow>
    {open && (
      <>
        {report.items.map((item, index) => (
          <StyledTableRow key={item.id} style={{ background: '#f7f7f7' }}>
            <StyledTableCell component="th" scope="row" style={{ paddingLeft: 15 }}>
              <Typography variant="h6" color="primary" style={{ fontSize: 14 }}>
                {index + 1}
              </Typography>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" style={{ paddingLeft: 15 }}>
              <Typography variant="h6" color="primary" style={{ fontSize: 14 }}>
                {item.title}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left">
              <Typography variant="body2">
                {moment(parseInt(item.updatedAt)).locale('tr').format('Do MMMM YYYY')}
              </Typography>
            </StyledTableCell>

            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left">
              {item.status === 'Sent' && (
                <FiberManualRecordIcon style={{ color: '#28d09a', fontSize: 15, marginRight: 3 }} />
              )}
              {item.status === 'Draft' && (
                <FiberManualRecordIcon style={{ color: '#ffba00', fontSize: 15, marginRight: 3 }} />
              )}
              {item.status === 'Outdated' && (
                <FiberManualRecordIcon style={{ color: '#ff5757', fontSize: 15, marginRight: 3 }} />
              )}
            </StyledTableCell>
            <StyledTableCell align="right" style={{paddingRight:25}}>

              {item.status === 'Sent' ? (
                <>
                  <Link
                    style={{ padding: 0 }}
                    to={{
                      pathname: `/rapor-detay/${item.id}`,
                      state: { reportTitle: item.title }
                    }}
                  >
                    <Button
                      style={{ fontWeight: 'normal', fontSize: 14, width: '20px', marginRight: 10, padding: '10.5px 52px' }}
                      variant="outlinedPrimary"
                    >
                      Görüntüle
                    </Button>
                  </Link>
                  <MoreVertIcon
                    onClick={(event) => handleClick(event, item.id)}
                    style={{ color: '#8898aa', fontSize: 20, cursor: 'pointer' }}
                  />
                </>
              ) : (
                item.status !== 'Outdated' &&
                <>
                  <Link
                    style={{ padding: 0 }}
                    to={{
                      pathname: `/rapor-duzenle/${item.id}`,
                      state: { reportTitle: item.title }
                    }}
                  >
                    <Button
                      style={{ fontWeight: 'normal', fontSize: 14, width: '20px', marginRight: 10, padding: '10.5px 52px' }}
                      variant="outlinedSecondary"
                    >
                      Düzenle
                    </Button>
                  </Link>
                  <MoreVertIcon
                  onClick={(event) => handleClick(event, item.id)}
                  style={{ color: '#8898aa', fontSize: 20, cursor: 'pointer' }}
                  />
                </>
              )}
            </StyledTableCell>
            <DeleteReportPopup
              id={id}
              report={item}
              startupReportDelete={props.startupReportDelete}
              refetch={props.refetch}
              handleClose={handleClose}
              openedPopoverId={openedPopoverId}
              anchorEl={anchorEl}
              onClose={handleClose}
            />
          </StyledTableRow>
        ))}
      </>
    )}
  </>
);
}
const FormikGroupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { t, i18n } = useTranslation();
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10,minHeight:80}}
          {...props}
          {...field}
          multiple
          disableCloseOnSelect
          options={props.options}
          getOptionLabel={(option) => option.title}
          hiddenLabel="true"
          className={classes.multiSelect2}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder={t('Lütfen Seçin')} {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      style={{zIndex:999}}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
        autocomplete="off"
        style={{
          width:'100%',
          padding:'13px',
          borderRadius: 3,
          border:'1px solid rgb(221 221 221 / 56%)',
          boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
          marginTop:10
        }}
        type="text"
        placeholder={'mm'} />
      }
      maxDate={getCurrentPeriod(props.dataFrequency).date}
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

const FormikPersonDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { t, i18n } = useTranslation();
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10,minHeight:80}}
          {...props}
          {...field}
          multiple
          disableCloseOnSelect
          options={props.options}
          getOptionLabel={(option) => option && option.getName}
          hiddenLabel="true"
          className={classes.multiSelect2}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder={t('Lütfen Seçin')} {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

function DeleteReportPopup(props){
  const classes = useStyles();
  const { id, anchorEl, report, onClose, openedPopoverId } = props

  const deleteReport= (value) => {
    props.startupReportDelete({ variables: { id:value } })
      .then(data => {
        if (data.data.startupReportDelete) {
          props.refetch();
          onClose();
        }
      }).then(() => {})
      .catch((error) => {
      });
  };

  return (
    <Popover
      id={id}
      open={openedPopoverId === report.id}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Grid item xs={12}>
        <ListItem style={{ cursor: 'pointer' }} button>
          <Link style={{ padding: 0 }} to={{
            pathname: `/rapor-duzenle/${report.id}`,
          }}>
            <Grid style={{ width: 'fit-content' }} container>
              <Typography style={{ width: 'fit-content' }} variant="body2">
                Düzenle
              </Typography>
            </Grid>
          </Link>
        </ListItem>
        <ListItem onClick={() => deleteReport(report.id)} style={{ cursor: 'pointer' }} button>
          <Grid style={{ width: 'fit-content' }} container>
            <Typography style={{ width: 'fit-content' }} variant="body2">
              Sil
            </Typography>
          </Grid>
        </ListItem>
      </Grid>
    </Popover>
  );
}

function ReportListPopup(props){
  const classes = useStyles();
  const { id, anchorEl, report, onClose, openedPopoverId, refetch, groups, people, handleClose } = props
  const [editReport, setEditReport] = React.useState(false);

  return (
    <>
      <EditReportDialog open={editReport} reportList={report} handleClose2={handleClose} startupReportListEdit={props.startupReportListEdit} onClose={() => setEditReport(false)} refetch={refetch} groups={groups} people={people} />
      <Popover
        id={id}
        open={openedPopoverId === report.id}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid item xs={12}>
          <ListItem style={{ cursor: 'pointer' }} onClick={() => setEditReport(true)}  button>
              <Grid style={{ width: 'fit-content' }} container>
                <Typography style={{ width: 'fit-content' }} variant="body2">
                  Düzenle
                </Typography>
              </Grid>
          </ListItem>
        </Grid>
      </Popover>
    </>
  );
}

function AddReportDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, groups, people, reportList } = props;
  const yesNo = ["Evet", "Hayır"];
  const reminderOptions = ['Tek seferlik', 'Haftalık', 'Aylık', 'Çeyreklik', 'Yıllık'];

  const handleSubmit = variables => {
    let tempGr = []
    let tempPr = []

    variables.groupIds.forEach(element => {
      tempGr.push(element.id)
    });
    variables.groupIds = tempGr

    variables.personIds.forEach(element => {
      tempPr.push(element.id)
    });    
    variables.personIds = tempPr

      props.startupReportListCreate({ variables })
      .then(data => {
        if (data.data.startupReportListCreate) {
          props.history.push('/rapor-duzenle/' +data.data.startupReportListCreate )
        } 
      }).then(() => {})
      .catch((error) => {
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding:'30px 30px 0 0'}}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor:'pointer' }}/>
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4" style={{marginBottom:20}}>
        {t('Rapor Oluştur')}
        </Typography>
        <Typography align="center" variant="body2" style={{ marginTop: 25 }}>
          {t('Verilerinizi dilediğiniz kişi ve gruplara kolayca raporlayın.')}
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              title: reportList ? reportList.title : '',
              startDate: reportList ? reportList.startDate : null,
              dataFrequency: reportList ? reportList.dataFrequency : '',
              reminderFrequency: reportList ? reportList.reminderFrequency : '',
              isPublic: reportList ? reportList.isPublic : 'Hayır',
              personIds: reportList ? reportList.personIds : [],
              groupIds: reportList ? reportList.groupIds : [],
            }}
            validationSchema={reportSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                handleSubmit,
                setFieldValue
              } = props;
              return (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12} style={{marginBottom:15}}>
                      <Typography variant="h6" style={{ width: '100%' }}>
                      {t('Rapor Adı')}
                      </Typography>
                      <FormikTextField
                        name="title"
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Rapor Adı"
                      />
                    </Grid>
                    <Grid item container xs={12} style={{marginBottom:25}}>
                      <Typography variant="h6" style={{ display:'flex' }}>
                      {t('Raporunuzu seçtiğiniz kişilerle hangi sıklıkla paylaşmak istiyorsunuz?')}
                      </Typography>
                      <Field
                        style={{width:'100%',marginTop:10}}
                        component={FormikDropdown}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="dataFrequency"
                        options={reminderOptions}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} style={{marginBottom:35}}>
                      <Typography variant="h6" style={{ display:'flex' }}>
                      {t('Verileri hangi tarihten itibaren rapora eklemek istiyorsunuz?')}
                      </Typography>
                      <DatePickerField
                        name={'startDate'}
                        dataFrequency={props.values.dataFrequency}
                      />
                    </Grid>
                    {people && (
                        <Grid xs={12} style={{marginBottom:35}} item>
                          <Typography variant="h6">
                          {t('Gönderilecek Kişiler')}
                          </Typography>
                          <Field
                            component={FormikPersonDropdown}
                            style={{ background: '#fff', marginTop: 10, width:'100%' }}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="personIds"
                            options={people}
                          ></Field>
                        </Grid>
                      )}
                      {groups && (
                        <Grid xs={12} style={{marginBottom:35}} item>
                          <Typography variant="h6">
                          {t('Gönderilecek Gruplar')} 
                          </Typography>
                          <Field
                            component={FormikGroupDropdown}
                            style={{ background: '#fff', marginTop: 10 }}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="groupIds"
                            options={groups}
                          ></Field>
                        </Grid>
                      )}
                    <Grid>
                      <Typography align="left" variant="h6">
                      {t('Raporunuzu herkese açık hale getirmek ister misiniz?')}   
                      </Typography>
                      <FormikSelect
                        name="isPublic"
                        options={yesNo}
                      ></FormikSelect>
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        disabled={!props.isValid || (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)} 
                        onClick={() => handleSubmit(props.values)}
                      >
                       {t('Oluştur')}  
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditReportDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open, refetch ,groups, people, reportList, handleClose2 } = props;
  const yesNo = ['Evet', 'Hayır'];
  const reminderOptions = ['Tek seferlik', 'Haftalık', 'Aylık', 'Çeyreklik', 'Yıllık'];

  const handleSubmit = variables => {
    let tempGr = []
    let tempPr = []

    variables.groupIds.forEach(element => {
      tempGr.push(element.id)
    });
    
    variables.groupIds = tempGr

    variables.personIds.forEach(element => {
      tempPr.push(element.id)
    });

    variables.personIds = tempPr

      props.startupReportListEdit({ variables })
      .then(data => {
        if (data.data.startupReportListEdit) {
          refetch()
          onClose(null);
          handleClose2();
        } 
      }).then(() => {})
      .catch((error) => {
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding:'30px 30px 0 0'}}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor:'pointer' }}/>
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4" style={{marginBottom:20}}>
        {t('Rapor Düzenle')}  
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              id:reportList.id,
              title: reportList ? reportList.title : '',
              startDate: reportList ? moment(parseInt(reportList.startDate)).format('YYYY/MM') : '',
              dataFrequency: reportList ? reportList.dataFrequency : '',
              reminderFrequency: reportList ? reportList.reminderFrequency : '',
              isPublic: reportList ? reportList.isPublic : 'Hayır',
              personIds: reportList ? reportList.persons : [],
              groupIds: reportList ? reportList.groups : [],
            }}
            validationSchema={reportSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12} style={{marginBottom:15}}>
                      <Typography variant="h6" style={{ width: '100%' }}>
                      {t('Rapor Adı')}
                      </Typography>
                      <FormikTextField
                        name="title"
                        style={{ width: '100%', marginBottom: 20 }}
                        type="text"
                        placeholder="Set Adı"
                      />
                    </Grid>
                    <Grid item container xs={12} style={{marginBottom:25}}>
                      <Typography variant="h6" style={{ display:'flex' }}>
                      {t('Raporunuzu seçtiğiniz kişilerle hangi sıklıkla paylaşmak istiyorsunuz?')}
                      </Typography>
                      <Field
                        style={{width:'100%',marginTop:10}}
                        component={FormikDropdown}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="dataFrequency"
                        options={reminderOptions}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} style={{marginBottom:35}}>
                      <Typography variant="h6" style={{ display:'flex' }}>
                      {t('Verileri hangi tarihten itibaren rapora eklemek istiyorsunuz?')}
                      </Typography>
                      <DatePickerField
                        name={'startDate'}
                        dataFrequency={props.values.dataFrequency}
                      />
                    </Grid>                   
                    {people && (
                        <Grid xs={12} style={{marginBottom:35}} item>
                          <Typography variant="h6">
                          {t('Gönderilecek Kişiler')}
                          </Typography>
                          <Field
                            component={FormikPersonDropdown}
                            style={{ background: '#fff', marginTop: 10, width:'100%' }}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="personIds"
                            options={people}
                          ></Field>
                        </Grid>
                      )}
                      {groups && (
                        <Grid xs={12} style={{marginBottom:35}} item>
                          <Typography variant="h6">
                          {t('Gönderilecek Gruplar')}
                          </Typography>
                          <Field
                            component={FormikGroupDropdown}
                            style={{ background: '#fff', marginTop: 10 }}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="groupIds"
                            options={groups}
                          ></Field>
                        </Grid>
                      )}
                    <Grid>
                      <Typography align="left" variant="h6">
                      {t('Raporunuzu herkese açık hale getirmek ister misiniz?')}
                      </Typography>
                      <FormikSelect
                        name="isPublic"
                        options={yesNo}
                      ></FormikSelect>
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        disabled={!props.isValid} 
                        onClick={() => handleSubmit(props.values)}
                      >
                        {t('Düzenle')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


export default function ReportList(props){
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [addReport, setAddReport] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(6);
    const [reportsCount, setReportsCount] = React.useState(0);
    const [groups, setGroups] = React.useState();
    const [people, setPeople] = React.useState();
    const [reports, setReports] = React.useState();
    const [search, setSearch] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [sort, setSort] = React.useState([]);
    const [filter, setFilter] = React.useState("Statüsüne Göre");
    const [status, setStatus] = React.useState([]);
    const [dataFrequency, setDataFrequency] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const { t } = useTranslation();
    const [anchorElSort, setAnchorElSort] = React.useState(null);

    const handleClickSort = (event) => {
      setAnchorElSort(event.currentTarget);
    };

    const handleCloseSort = () => {
      setAnchorElSort(null);
    };

    const openPopSort = Boolean(anchorElSort);
    const idSort = openPopSort ? 'simple-popover' : undefined;

    const options = [
      {name:'A-Z', value:'title__ASC'},
      {name:'Z-A', value:'title__DESC'},
      {name:'Eskiden Yeniye', value:'id__ASC'},
      {name:'Yeniden Eskiye', value:'id__DESC'},
    ];
    const filterOptions = ['Statüsüne Göre', 'Gönderim Sıklığına Göre'];

    const statusOptions = [{title:'Gönderildi', value:'Sent'}, {title:'Taslak ', value:'Draft'}, {title:'Zamanı Geçmiş', value:'Outdated'}];
    const dataFrequencyOptions = ['Tek seferlik', 'Haftalık', 'Aylık', 'Çeyreklik', 'Yıllık'];

    const ALL_REPORTS = gql`
    query startupReportList($order:String, $search: String, $page: Int, $pageSize: Int, $status: [String], $dataFrequency: [String]) {
      startupReportList(orderBy:[$order], search:$search, pagination:{page:$page, pageSize:$pageSize}, filter:{status:$status, dataFrequency:$dataFrequency}){
            count
            pageCount
            requestCount
            data{
              id
              title
              startDate
              dataFrequency
              updatedAt
              reminderFrequency
              isPublic
              nextPostDate
              createNew
              groups{
                  id
                  title
              }
              persons{
                  id
                  getName
              }
              items{
                id
                title
                updatedAt
                status
                sentDate
              }
            }
        }
    }
    `;
      const { loading, data, refetch} = useQuery(ALL_REPORTS, {
        fetchPolicy:'network-only',
        variables: {search:search, page:page, pageSize:pageSize, order: order, dataFrequency:dataFrequency, status:status },
    });
    const GET_GROUPS = gql`
    query getStartupGroupsSearch {
      getStartupGroupsSearch{
        id
        title
      }
    }
    `;

    const { loading:loadingGroup, data:dataGroup} = useQuery(GET_GROUPS, {
        fetchPolicy:'network-only',
        variables: {},
    });

    const GET_PEOPLE = gql`
    query getStartupPersonsSearch {
      getStartupPersonsSearch{
        id
        getName
      }
    }
    `;
      const { loading:loadingPeople, data:dataPeople} = useQuery(GET_PEOPLE, {
        fetchPolicy:'network-only',
        variables: {},
    });

    React.useEffect(() => {
        if(!loading){
            if(data){
                setReports(data.startupReportList.data);
                setPageCount(data.startupReportList.pageCount)
                setReportsCount(data.startupReportList.count)
            }
        }
        if(!loadingPeople){
          if(dataPeople){
            setPeople(dataPeople.getStartupPersonsSearch);
          }
        }
        if(!loadingGroup){
          if(dataGroup){
            setGroups(dataGroup.getStartupGroupsSearch);
          }
        }
        if(reports){
          setShow(true)

        }
    },
  [data, loading, loadingGroup, dataGroup, loadingPeople, dataPeople, reports])

  const handlePageChange = (event, value) => {
    window.scrollTo(0,0);
    setPage(value);
  };
  
  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
  
  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (event) => {
    setPage(1);
    setFilter(event.target.value);
  };

  const handleStatus = (event) => {
    setPage(1);
    if (status.includes(event.target.value)){
      let a = status.filter(e => e !== event.target.value);
      setStatus(a);
     }
     else{
      setStatus([...status, event.target.value]);
     }
  };

  const handleDataFrequency = (event) => {
    setPage(1);
    if (dataFrequency.includes(event.target.value)){
      let a = dataFrequency.filter(e => e !== event.target.value);
      setDataFrequency(a);
     }
     else{
      setDataFrequency([...dataFrequency, event.target.value]);
     }
  };
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;
    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <AddReportDialog
              open={addReport}
              history={props.history}
              startupReportListCreate={props.startupReportListCreate}
              onClose={() => setAddReport(false)}
              refetch={refetch}
              groups={groups}
              people={people}
            />
            {show && (
              <Grid
                className={'main-container'}
                xs={12}
                style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
              >
                {reports && reports.length !== 0 ? (
                  <Grid item style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 35 }}>
                      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <InsertDriveFileOutlinedIcon style={{ fontSize: 20, marginTop:5 }} />
                        <Typography variant="h4">Raporlarım</Typography>
                      </Breadcrumbs>
                      <Hidden smDown>
                        <Grid item className={classes.filterArea}>
                          <DebounceInput
                            class="inputTypeSearch"
                            name="search"
                            value={search}
                            placeholder="Ara..."
                            className={classes.Debounce}
                            minLength={2}
                            debounceTimeout={800}
                            onChange={handleSearch}
                          />
                          <Grid className={classes.filter} onClick={handleClick} item>
                            <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                            <Hidden smDown>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                {t('Filtrele')}
                              </Typography>
                              <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize:20 }} />
                            </Hidden>
                          </Grid>
                          <Popover
                            id={id}
                            open={openPop}
                            anchorEl={anchorEl}
                            style={{marginTop:11}}
                            onClose={handleClose}
                            classes={{
                              paper: classes.myPaper,
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Grid container xs={12}>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  borderRight: '1px solid rgb(0 0 0 / 5%)',
                                  maxHeight: 250,
                                  overflowY: 'auto',
                                }}
                              >
                                <Typography variant="h5">{t('Filtreler')}</Typography>
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <RadioGroup onChange={handleFilter} value={filter}>
                                    {filterOptions.map((value, i) => {
                                      // Return the element. Also pass key
                                      return (
                                        <FormControlLabel
                                          classes={{ label: classes.myLabel }}
                                          control={<Radio color="primary" value={value} />}
                                          label={value}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: '30px',
                                  borderRight: '1px solid rgb(0 0 0 / 5%)',
                                  maxHeight: 250,
                                  overflowY: 'auto',
                                }}
                              >
                                <Typography variant="h5">{t('Filtre Seç')}</Typography>
                                {filter === 'Statüsüne Göre' && (
                                  <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup onChange={handleStatus} value={status}>
                                      {statusOptions.map((value, i) => {
                                        return (
                                          <FormControlLabel
                                            className={classes.CheckText}
                                            control={
                                              <Checkbox
                                                classes={{ root: classes.myCheckbox }}
                                                color="primary"
                                                onChange={handleStatus}
                                                checked={status.includes(value.value)}
                                                name="filter4-1"
                                                value={value.value}
                                              />
                                            }
                                            label={value.title}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                )}
                                {filter === 'Gönderim Sıklığına Göre' && (
                                  <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup onChange={handleDataFrequency} value={dataFrequency}>
                                      {dataFrequencyOptions.map((value, i) => {
                                        return (
                                          <FormControlLabel
                                            className={classes.CheckText}
                                            control={
                                              <Checkbox
                                                classes={{ root: classes.myCheckbox }}
                                                color="primary"
                                                onChange={handleDataFrequency}
                                                checked={dataFrequency.includes(value)}
                                                name="filter4-1"
                                                value={value}
                                              />
                                            }
                                            label={value}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                )}
                              </Grid>
                              <Grid item xs={4} style={{ paddingLeft: '30px', maxHeight: 250, overflowY: 'auto' }}>
                                <Typography style={{ marginBottom: 18 }} variant="h5">
                                  {t('Filtre Seçimlerim')}
                                </Typography>
                                {status && (
                                  <>
                                    {status.map((value, i) => {
                                      return (
                                        <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                          {value === 'Sent' && 'Gönderildi'}
                                          {value === 'Draft' && 'Taslak'}
                                          {value === 'Outdated' && 'Zamanı Geçmiş'}
                                        </Typography>
                                      );
                                    })}
                                  </>
                                )}
                                {dataFrequency && (
                                  <>
                                    {dataFrequency.map((value, i) => {
                                      return (
                                        <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                          {value}
                                        </Typography>
                                      );
                                    })}
                                  </>
                                )}
                              </Grid>
                              <Grid container justify="flex-end"></Grid>
                            </Grid>
                          </Popover>

                          <Grid className={classes.filter} onClick={handleClickSort} item>
                          <img src={newSortingIcon} style={{ width: 15 }} alt="filter" />
                          <Hidden smDown>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {t('Sırala')}
                            </Typography>
                            <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                          </Hidden>
                        </Grid>
                        <Popover
                          id={idSort}
                          open={openPopSort}
                          anchorEl={anchorElSort}
                          style={{ marginTop: 11 }}
                          onClose={handleCloseSort}
                          classes={{
                            paper: classes.myPaper2,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{ maxHeight: 250, overflowY: 'auto' }}
                          >
                            <Typography variant="h5">{t('Sırala')}</Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <RadioGroup onChange={handleSort} value={sort}>
                                {options.map((option, i) => {
                                  // Return the element. Also pass key
                                  return (
                                    <FormControlLabel
                                      classes={{ label: classes.myLabel }}
                                      control={
                                        <Radio
                                          color="primary"
                                          value={option.value}
                                          onClick={() => setOrder(option.value)}
                                        />
                                      }
                                      label={option.name}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Popover>
                          <Link
                            style={{ padding: 0, marginRight: 20 }}
                            to={{
                              pathname: `/rapor-talepleri/`,
                            }}
                          >
                            <Button
                              style={{
                                fontWeight: 'normal',
                                padding: '10.5px 15px',
                                fontSize: 14,
                                width: '100%',
                                minWidth: 165,
                              }}
                              variant="outlinedSecondary"
                              onClick={() => setAddReport(true)}
                            >
                              {t('Rapor Talepleri')} : {data ? data.startupReportList.requestCount : 0}
                            </Button>
                          </Link>
                          <Button
                            style={{
                              fontWeight: 'normal',
                              padding: '11.5px 15px',
                              fontSize: 14,
                              width: '100%',
                              minWidth: 182,
                            }}
                            variant="containedPrimary"
                            onClick={() => setAddReport(true)}
                          >
                            {t('Rapor Oluştur')}{' '}
                          </Button>
                        </Grid>
                      </Hidden>
                    </Grid>
                    {reports && reports.length !== 0 ? (
                      <Grid container xs={12}>
                        <Grid container xs={12} className={classes.whiteCard}>
                          <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell style={{ paddingLeft: 15 }}>
                                    <Typography variant="h6" color="primary">
                                      #
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell style={{ paddingLeft: 15 }}>
                                    <Typography variant="h6" color="primary">
                                      {' '}
                                      {t('Rapor Adı')}{' '}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography
                                      variant="h6"
                                      color="primary"
                                      style={{ fontWeight: 500, display: 'flex' }}
                                    >
                                      {' '}
                                      {t('Gönderim Sıklığı')}{' '}
                                      <LightTooltip
                                        arrow
                                        title={t(
                                          'Raporunuzu seçtiğiniz kişilerle hangi sıklıkta paylaştığınızı gösterir.',
                                        )}
                                      >
                                        <img src={Info} alt="info" style={{ width: '18px', marginLeft: 5 }} />
                                      </LightTooltip>
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography
                                      variant="h6"
                                      color="primary"
                                      style={{ fontWeight: 500, display: 'flex' }}
                                    >
                                      {' '}
                                      {t('Son Güncelleme')}{' '}
                                      <LightTooltip arrow title={t('Raporunuzun en son güncelleme tarihini gösterir.')}>
                                        <img src={Info} alt="info" style={{ width: '18px', marginLeft: 5 }} />
                                      </LightTooltip>
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography
                                      variant="h6"
                                      color="primary"
                                      style={{ fontWeight: 500, display: 'flex' }}
                                    >
                                      {' '}
                                      {t('Bir Sonraki Gönderim')}{' '}
                                      <LightTooltip
                                        arrow
                                        title={t(
                                          'Tekrarlayan raporlarınızın bir sonraki ayda ne zaman gönderilmesi gerektiğini gösterir.',
                                        )}
                                      >
                                        <img src={Info} alt="info" style={{ width: '18px', marginLeft: 5 }} />
                                      </LightTooltip>
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography variant="h6" color="primary">
                                      {t('Durum')}{' '}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {reports.map((report, index) => (
                                  <ExpandingRow
                                    history={props.history}
                                    startupReportItemCreate={props.startupReportItemCreate}
                                    startupReportDelete={props.startupReportDelete}
                                    startupReportListEdit={props.startupReportListEdit}
                                    refetch={refetch}
                                    groups={groups}
                                    people={people}
                                    index={index + 1}
                                    report={report}
                                  />
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Grid container justify="space-between">
                            <Grid style={{ display: 'flex', marginTop: 25, flexWrap: 'wrap' }} item>
                              <Typography
                                style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}
                                variant="body2"
                              >
                                <FiberManualRecordIcon style={{ color: '#28d09a', fontSize: 15, marginRight: 3 }} />
                                {t('Gönderildi')}
                              </Typography>
                              <Typography
                                style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}
                                variant="body2"
                              >
                                <FiberManualRecordIcon style={{ color: '#ffba00', fontSize: 15, marginRight: 3 }} />
                                {t('Taslak')}
                              </Typography>
                              <Typography
                                style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}
                                variant="body2"
                              >
                                <FiberManualRecordIcon style={{ color: '#ff5757', fontSize: 15, marginRight: 3 }} />
                                {t('Zamanı Geçmiş')}
                              </Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCount}
                                page={page}
                                onChange={handlePageChange}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <Grid container justify="center">
                          <Typography style={{ margin: '25px 0' }} variant="h5">
                            {t('Raporunuz Bulunamadı')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid container justify="center">
                    <Grid item container direction="column" xs={6} alignItems="center">
                      <img src={Image} alt="404" style={{ width: '40%' }} />
                      <Typography variant="h2" style={{ marginTop: '25px' }} align="center">
                        {t('Henüz hiç rapor oluşturmadınız.')}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: '35px', color: '#777777', marginBottom: '15px' }}
                        align="center"
                      >
                        {t(
                          'Rapor oluşturarak girişiminizdeki gelişmeleri belirlediğiniz kişiler ve gruplarla paylaşabilirsiniz.',
                        )}
                      </Typography>
                      <Button
                        onClick={() => setAddReport(true)}
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          marginRight: 20,
                          padding: '11.5px 30px',
                        }}
                        variant="containedPrimary"
                      >
                        {t('Rapor Oluştur')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </main>
      </>
    );
}