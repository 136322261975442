import React from "react"
import {Grid, Typography, Hidden, Button} from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import login from '../../static/img/auth-photo.jpg';
import FormikTextField from "../../Components/FormikShortTextField";
import logo from '../../static/img/login-logo.png';
import {forgotPasswordSchema, setPasswordSchema} from '../../yupSchemas';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import {makeStyles} from "@material-ui/core/styles";
import createBrowserHistory from 'history/createBrowserHistory';
import Snackbar from "@material-ui/core/Snackbar";
import {Link} from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import authBackground from '../../static/img/new-auth-background.png';
import authLogo from '../../static/img/new-auth-logo.png';
import newAuth1 from '../../static/img/new-auth-1.png';
import newAuth2 from '../../static/img/new-auth-2.png';
import newAuth3 from '../../static/img/new-auth-3.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    display: 'block',
  },
  link: {
    textDecoration: 'none',
    color: '#777777',
    padding: 0,
    fontWeight: 'normal',
    marginTop: 20,
  },
  leftGrid: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100vh',
      overflowY: 'auto',
    },
    backgroundImage: 'url(' + authBackground + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  logoGrid: {
    height: 'fit-content',
    [theme.breakpoints.up('xl')]: {
      paddingTop: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    paddingTop: 30,
    paddingRight: 30,
  },
  newAround: {
    [theme.breakpoints.up('xl')]: {
      marginTop: 110,
    },
    marginTop: 25,
  },
  signUp: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 30,
    },
    marginTop: 20,
  },
  loginGrid: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },
  },
  logo: {
    width: 120,
  },
  loginText: {
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1.35,
    color: '#172b4d',
  },
  welcomeText: {
    color: '#777777',
    [theme.breakpoints.up('xl')]: {
      marginTop: 40,
    },
    marginTop: 25,
  },
  EmailText: {
    [theme.breakpoints.up('xl')]: {
      marginTop: 50,
    },
    marginTop: 30,
    fontWeight: 500,
  },
  PasswordText: {
    marginTop: 15,
    fontWeight: 500,
  },
}));

  export default function ForgotPassword(props){

    const history = createBrowserHistory({forceRefresh:true});
    document.body.style = 'background: #ffffff;margin:0';
    const [code] = React.useState(new URLSearchParams(props.history.location.search).get("code"));
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [serverSuccess, setServerSuccess] = React.useState(false);
    const [serverSetPasswordSuccess, setServerSetPasswordSuccess] = React.useState(false);
    const [serverMessage, setServerMessage] = React.useState({
        detail: '',
        message: '',
        type: false,
      });
      const settings = {
        dots: true,
        arrows:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
      };
    const handleSubmit = async variablesAll => {
        setIsSubmitting(true);
        const { ...variables } = variablesAll;
        props.startForgotPassword({ variables })
          .then(data => {
            if (data.data.startForgotPassword) {
                    // history.push("/login");
              setServerSuccess(true);
            } else {
                setServerMessage({
                    detail: get(data, 'errors[0].functionError.detail', ' '),
                    message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
                    type: false,
                  });
                  // setServerSuccess(true);
            }
          }).then(() => {
                setIsSubmitting(false);
            }).catch(error => {
                setServerMessage({
                    message: 'Unexpected Error',
                    type: false,
                  });
                  // setServerSuccess(true);
          });
      };
    const handleSetPassword = async variablesAll => {
        setIsSubmitting(true);
        const { ...variables } = variablesAll;
        variables.code = code
        props.forgotPassword({ variables })
          .then(data => {
            if (data.data.forgotPassword) {
              setServerSetPasswordSuccess(true);
            } else {
                setServerMessage({
                    detail: get(data, 'errors[0].functionError.detail', ' '),
                    message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
                    type: false,
                  });
                  // setServerSuccess(true);
            }
          }).then(() => {
                setIsSubmitting(false);
            }).catch(error => {
                setServerMessage({
                    message: 'Unexpected Error',
                    type: false,
                  });
                  // setServerSuccess(true);
          });
      };
        const classes = useStyles();
        const preventDefault = (event) => event.preventDefault();
        return (
        <Grid container style={{height : '100vh'}}>
            <Grid container item lg={6} md={12} xs={12}>
                <Grid xs={12} justify="center" className={classes.loginGrid} container item>
                  {code &&
                  <Grid item sm={7} xs={11} style={{margin:'auto'}}>
                    <Formik
                      initialValues={{
                        passwordAgain: '',
                        password: '',
                        code:'',
                      }}
                      validationSchema={setPasswordSchema}
                      onSubmit={values => {
                        handleSetPassword(values);
                      }}
                    >
                      {props => (
                        <Form>
                          <Typography variant="h2">Şifreni Değiştir</Typography>
                          <Typography variant="body1" className={classes.EmailText} style={{fontWeight:500}}>Yeni Şifre</Typography>
                          <FormikTextField name="password" type="password" placeholder="Şifre" />
                          <Typography variant="body1" className={classes.EmailText} style={{fontWeight:500}}>Şifreyi Tekrarlayın</Typography>
                          <FormikTextField name="passwordAgain" type="password" placeholder="Şifre Tekrar" />
                          <Grid item container xs={12} justify="center" style={{marginTop: 40}}>
                            <Button variant="containedPrimary" style={{fontWeight: 'normal'}} type="submit" disabled={!props.isValid || (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)}>Şifreyi Değiştir</Button>
                          </Grid>
                        </Form>
                      )}
                    </Formik>

                    <Snackbar autoHideDuration={3500} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => history.push("/login")} open={serverSetPasswordSuccess} >
                      <Alert severity="success">Başarılı..Yeni Parolanızla Giriş Yapabilirsiniz.</Alert>
                    </Snackbar>
                  </Grid>
                  }
                  {!code && <Grid item sm={7} xs={11} style={{margin:'auto'}}>
                      <Typography variant="h2">Parolamı Unuttum</Typography>
                        <Formik
                          initialValues={{
                            email: '',
                          }}
                          validationSchema={forgotPasswordSchema}
                          onSubmit={values => {
                            handleSubmit(values);
                          }}
                        >
                          {propss => (
                            <Form>
                              <Typography variant="body1" className={classes.EmailText} style={{fontWeight:500}}>E-posta</Typography>
                              <FormikTextField  name="email" type="email" placeholder="Email Address" />
                              <Grid item container xs={12} justify="center" style={{marginTop: 15}}>
                                <Button variant="containedPrimary" style={{fontWeight: '500', width:'100%'}} type="submit" disabled={!propss.isValid || isSubmitting || serverSuccess} >Gönder</Button>
                              </Grid>
                            </Form>
                          )}
                        </Formik>

                    <Grid item xs={12} container  className={classes.newAround} justify="center">
                      <Link to="/login" style={{fontWeight: 'normal', padding: 0,display:'flex',alignItems:'center'}}>
                      <KeyboardBackspaceIcon style={{marginRight:5}}/>{'Giriş Yap'}
                      </Link>
                    </Grid>
                      <Snackbar autoHideDuration={10000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setServerSuccess(false)} open={serverSuccess} >
                        <Alert severity="success">Email adresinize gelen link üzerinden yeni şifrenizi belirleyebilirsiniz!</Alert>
                      </Snackbar>
                    </Grid>
                  }
                </Grid>
            </Grid>
            <Hidden mdDown>
                <Grid item sm={6} xs={12}>
                <Grid className={classes.leftGrid}>
                  <Grid container justify="flex-end" style={{ padding: 50 }}>
                    <img src={authLogo} alt="authlogo" />
                  </Grid>
                  <Grid item style={{ marginBottom: 80 }}>
                    <Slider {...settings} style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Grid container justify="center" style={{ marginTop: 60 }}>
                          <img src={newAuth1} alt="auth1" style={{ width: 350 }} />
                        </Grid>
                        <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                          Yatırımcı ilişkilerini yönet
                        </Typography>
                        <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                          Girişiminizin gelişmelerini tek bir <br /> noktadan takip edebilir, yatırımcılarınız ile{' '}
                          <br /> kolayca paylaşabilirsiniz.
                        </Typography>
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }}>
                        <Grid container justify="center">
                          <img src={newAuth2} alt="auth2" style={{ width: 480 }} />
                        </Grid>
                        <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                          Türkiyenin en geniş yatırımcı <br /> ağına ulaş!
                        </Typography>
                        <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                        Girişimcilik ekosisteminin deneyimli isimleriyle birlikte
                          <br /> çalışma ve iletişime geçme fırsatlarını yakala.
                        </Typography>
                      </Grid>
                      <Grid item style={{ textAlign: 'center' }}>
                        <Grid container justify="center" style={{ marginTop: 150 }}>
                          <img src={newAuth3} alt="auth3" style={{ width: 580 }} />
                        </Grid>
                        <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                          Yatırımcı ilişkilerini <br />
                                tek bir noktadan sürdür!
                        </Typography>
                        <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                          Birçok yatırımcıyla yapacağın paylaşımları, <br />
                              veri takibini ve ortaklık tablonu tek <br /> kanaldan takip et.
                        </Typography>
                      </Grid>
                    </Slider>
                  </Grid>
                </Grid>
                </Grid>
            </Hidden>
        </Grid>
    );}