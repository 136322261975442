import DocumentSettings from './DocumentSettings'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const MY_SETTINGS = gql`
  mutation mySettings(
    $documents: [userDocumentInput]
  ) {
  mySettings(
        documents: $documents
    )
  }
`;
const JoinWithMutation = compose(
  graphql(MY_SETTINGS, {
  name: 'mySettings',
  }),
)(DocumentSettings);
export default JoinWithMutation;