import InvestorProfileShow from './InvestorProfileShow'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const MY_INVESTOR = gql`
  mutation myInvestorProfileSettings(
    $profilePhoto: Upload
    $backgroundPhoto: Upload
    $biography: String
    $website: String
    $socialLinkedIn: String
    $socialTwitter: String
    $cityId: ID
    $companyName: String
    $companyRole: String
    $interestedIndustries: [String]
    $career: [InvestorCareerInput]
    $portfolioCompanyCount: Int
    $investmentType: String
    $exitedCompanyCount: Int
    $interestedStage: [String]
    $portfoliosIds: [ID]
    $isFinished: Boolean
  ) {
    myInvestorProfileSettings(
      profilePhoto: $profilePhoto
      backgroundPhoto: $backgroundPhoto
      biography: $biography
      website: $website
      socialLinkedIn: $socialLinkedIn
      socialTwitter: $socialTwitter
      cityId: $cityId
      companyName: $companyName
      companyRole: $companyRole
      interestedIndustries: $interestedIndustries
      career: $career
      portfolioCompanyCount: $portfolioCompanyCount
      investmentType: $investmentType
      exitedCompanyCount: $exitedCompanyCount
      interestedStage: $interestedStage
      portfoliosIds: $portfoliosIds
      isFinished: $isFinished
      )
    }
`;

const INVITE_STARTUP = gql`
mutation investorInviteStartup($title: String!, $website: String!, $contactNameSurname: String!, $contactMail: String!,) {
  investorInviteStartup(title:$title, website:$website, contactNameSurname:$contactNameSurname, contactMail:$contactMail)
}
`;

const SEND_MAIL = gql`
  mutation investorShareProfileWithMail($mail: String!) {
    investorShareProfileWithMail(mail: $mail) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(MY_INVESTOR, {
    name: 'myInvestorProfileSettings',
  }),
  graphql(INVITE_STARTUP, {
    name: 'investorInviteStartup',
  }),
  graphql(SEND_MAIL, {
    name: 'investorShareProfileWithMail',
  }),
)(InvestorProfileShow);
export default JoinWithMutation;