import ToInvestPopup from './ToInvestPopup'
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';



const JoinWithMutation = compose(
    
  )(ToInvestPopup);
  export default JoinWithMutation;