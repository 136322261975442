import gql from "graphql-tag";
import {FRAGMENT_MESSAGES} from "./fragments";
export const MY_PROFILE = gql`
  query myProfile {
    myProfile {
      id
      role
      firstName
      lastName
      seenFirstPopup
      isEmailVerified
      profilePhoto
      socialLinkedIn
      biography
      website
      interestedIndustries
      getRole
      startup {
        id
        slug
        title
        logo
      }
      city {
        id
        title
      }
    }
  }
`;
export const DASHBOARD_SUMMARY = gql`
  query dashboardSummary {
    dashboardSummary {
      archive
      reevaluate
      informationwaiting
      inprogress
      voting
      portfolio
      countries
      total
    }
  }
`;
export const FIRST_EVA = gql`
  query dashboardAboutFirstEva {
    dashboardAboutFirstEva {
      modes
      average
      standardDeviation
      median
      firstQ {
        average
        standardDeviation
        median
      }
      secondQ {
        average
        standardDeviation
        median
      }
      thirdQ {
        average
        standardDeviation
        median
      }
      fourthQ {
        average
        standardDeviation
        median
      }
    }
  }
`;

export const ADMIN_GET_PENDING_STARTUP_INV_REQS_WITH_ID = gql`
  query adminPendingStartupInvestmentRequests($startupId: ID!) {
    adminPendingStartupInvestmentRequests(startupId: $startupId) {
      id
      status
    }
  }
`;

export const HEADER_LEFT_DRAWER = gql`
  query HeaderLeftDrawer {
    HeaderLeftDrawer @client
    HeaderLeftDrawerMobile @client
  }
`;

export const ALL_CONVERSATIONS = gql`
  query allConversations {
    allConversations {
      id
      createdAt
      updatedAt
      users {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;
export const GET_ACTIVE_INVESTMENT_SPRINT_DETAIL = gql`
  query getActiveInvestmentSprintDetail($startupId: ID!) {
    getActiveInvestmentSprintDetail(startupId: $startupId) {
      id
      currentInvestmentAmount
      requiredInvestmentAmount
      sprintCurrencySymbol
      oldAdditionalCommitment
    }
  }
`;

export const GET_PENDING_INVESTMENT_REQUESTS = gql`
  query pendingStartupInvestmentRequests($startupId: ID!) {
    pendingStartupInvestmentRequests(startupId: $startupId) {
      id
      status
      investmentAmount
      createdAt
      investor {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;

export const ADMIN_PENDING_INVESTMENT_REQUESTS = gql`
  query adminPendingStartupInvestmentRequests($startupId: ID!) {
    adminPendingStartupInvestmentRequests(startupId: $startupId) {
      id
      status
      investmentAmount
      createdAt
      showToStartup
      investor {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;
export const ALL_MESSAGES = gql`
  query allMessages {
    allMessages {
      ...MessageFields
    }
  }
  ${FRAGMENT_MESSAGES.message}
`;

export const UNREAD_MESSAGE_NUMBER = gql`
  query unreadMessageNumber{
    unreadMessageNumber
  }
`

export const GET_CREATE_INVESTMENT_SPRINT_DETAIL = gql`
  query getCreateInvestmentSprintDetail {
    getCreateInvestmentSprintDetail {
      id
      status
      finishedStage
      sprintCurrencySymbol
      oldEvaluation
      minTicketSize
      currentInvestmentAmount
      currentCommitmentAmount
      requiredInvestmentAmount
      hasReceivedCommitment
      oldAdditionalCommitment
      sprintClosingDate
      contactPreference
      registerNewspaperDocs {
        title
        file
        extension
        fileSize
        date
      }
      mainContractDocs {
        title
        file
        extension
        fileSize
        date
      }
      taxPlateDocs {
        title
        file
        extension
        fileSize
        date
      }
      operatingCertificateDocs {
        title
        file
        extension
        fileSize
        date
      }
    }
  }
`;

export const ADMIN_GET_CREATE_INVESTMENT_SPRINT_DETAIL = gql`
  query adminGetCreateInvestmentSprintDetail($startupId: ID!) {
    adminGetCreateInvestmentSprintDetail(startupId: $startupId) {
      id
      status
      isFeatured
      sprintClosingDate
      finishedStage
      sprintCurrencySymbol
      oldEvaluation
      minTicketSize
      requiredInvestmentAmount
      currentInvestmentAmount
      currentCommitmentAmount
      hasReceivedCommitment
      oldAdditionalCommitment
      sprintClosingDate
      contactPreference
      registerNewspaperDocs {
        title
        file
        extension
        fileSize
        date
      }
      mainContractDocs {
        title
        file
        extension
        fileSize
        date
      }
      taxPlateDocs {
        title
        file
        extension
        fileSize
        date
      }
      operatingCertificateDocs {
        title
        file
        extension
        fileSize
        date
      }
    }
  }
`;


export const GET_USER = gql`
  query getUser($id: ID!){
    getUser(id: $id){
      id
      firstName
      lastName
      profilePhoto
    }
  }
`