import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import get from 'lodash/get';
import moment from 'moment'
import FormikTextField from "../../Components/FormikTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import FormikNewDropdown from '../FormikDropdown';
import FormikMultiSelect from "../../Components/FormikMultiSelect";
import FormikProvinceDropdown from "../../Components/FormikProvinceDropdown";
import Dropzone from 'react-dropzone';
import { Field } from 'formik';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useField, useFormikContext } from 'formik';
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from 'react-i18next';
import tr from "date-fns/locale/tr";
import ImageCrop from "../../Components/ImageCrop";
import BlueTag from '../../Components/blueTag.js';
registerLocale("tr", tr);

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  paper2: {
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  fontLg: {
    fontSize: 16,
    fontWeight: 500,
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80px',
    width: '90px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 4,
    border: 'solid 1px #dddddd6b',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '500',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
}));

function validateLinkedinUrl(value) {
  if (value !== '' && value) {
    let error;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?');// query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}
function validateLinkedinUrl2(value) {
  if (value !== '' && value !== null) {
    let error;
    var pattern = new RegExp(/(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/); // query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}
const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
          autocomplete="off"
          style={{
            width: '100%',
            padding: '13px',
            borderRadius: 3,
            border: '1px solid rgb(221 221 221 / 56%)',
            boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
            marginTop: 10
          }}
          type="text"
          placeholder={'mm'} />
      }
      maxDate={moment().format("YYYY-MM-DD")}
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};
export default function GeneralTab(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [files, setFiles] = React.useState([]);
  const [industry, setIndustry] = React.useState([]);
  const businessModel = ['B2B', 'B2C', 'Marketplace'];
  const [openPhoto, setOpenPhoto] = React.useState(false);
  const stage = [
    'Fikir aşaması',
    'MVP',
    'Erken Aşama',
    'Büyüme'
  ];
  const [country, setCountry] = React.useState([]);
  const ALL_COUNTRIES = gql`
        query allCountries {
            allCountries{
                id
                title
            }        
        }
        `;
  const { loading: loading2, data: data2 } = useQuery(ALL_COUNTRIES, {
    variables: {},
  });

  React.useEffect(() => {
    if (!loading2) {
      if (data2) {
        let ind = [
          'AI / Yapay Zeka',
          'Akıllı Şehirler',
          'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
          'Atık Yönetimi',
          'Bilişim Teknolojileri',
          'Biyoteknoloji',
          'Blokzinciri',
          'Data Analiz & İş zekası',
          'Donanım',
          'e-ticaret',
          'Eğitim & Eğitim Teknolojileri',
          'Eğlence & Müzik',
          'Etkinlik',
          'Ev hizmetleri ve teknolojileri ',
          'Finans & Bankacılık',
          'Finans Teknolojileri',
          'Gayrimenkul',
          'Geri Dönüşüm',
          'Giyilebilir Teknolojiler',
          'Hukuk Teknolojileri',
          'İnsan Kaynakları & İşe Alım',
          'Kriptopara',
          'Kurumsal Hizmetler',
          'Mikro Mobilite',
          'Moda & Güzellik',
          'Medya',
          'Müşteri İlişkileri Yönetimi',
          'Nesnelerin İnterneti',
          'Oyun',
          'Perakende',
          'Regülasyon Teknolojileri',
          'Reklam & Pazarlama',
          'Robotik Teknolojileri',
          'SaaS',
          'Sağlık Hizmetleri & Sağlık Teknolojileri',
          'Seyahat & Turizm',
          'Siber Güvenlik',
          'Sigorta & Sigorta Teknolojileri',
          'Sosyal Girişimcilik',
          'Sosyal Medya',
          'Spor & Fitness',
          'Tarım & Tarım Teknolojileri',
          'Teknoloji',
          'Telekomünikasyon',
          'Temiz Teknoloji',
          'Teslimat & Lojistik',
          'Topluluk Yönetimi',
          'Tüketici Hizmetleri',
          'Ulaşım',
          'Video & Animasyon',
          'Yazılım',
          'Yenilenebilir Enerji',
          'Yiyecek & İçecek',
        ];
        ind = ind.filter(e => e !== `${props.detail.sector}`);
        setIndustry(ind);
        setCountry(data2.allCountries)
      }
    }
  },
    [data2, loading2])

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
    flexWrap: 'wrap',
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 4,
    border: '1px solid #eaeaea',
    width: 90,
    height: 80,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: 7
  };
  return (
    <>
      {!props.edit ? (
        <Paper className={classes.paper2}>
          <Grid item>
            {props.detail.description && (
              <>
                <Typography variant="h5" align="left" style={{ width: '100%' }}>
                  {t('Genel Bakış')}
                </Typography>
                {props.data ? (
                  <>
                    {props.data.newDetail && props.data.originalDetail && (
                      <>
                        {props.data.newDetail.description === props.data.originalDetail.description ? (
                          <Typography
                            className={classes.fontLg}
                            variant="body1"
                            align="left"
                            style={{ width: 'fit-content', marginTop: 16, lineHeight: 2 }}
                          >
                            {props.detail.description}
                          </Typography>
                        ) : (
                          <Typography
                            className={classes.fontLg}
                            variant="body1"
                            align="left"
                            style={{
                              width: 'fit-content',
                              marginTop: 16,
                              lineHeight: 2,
                              background: '#0055ff12',
                              padding: '0 5px',
                              borderRadius: 4,
                            }}
                          >
                            {props.detail.description}
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Typography
                    className={classes.fontLg}
                    variant="body1"
                    align="left"
                    style={{ width: 'fit-content', marginTop: 16, lineHeight: 2 }}
                  >
                    {props.detail.description}
                  </Typography>
                )}
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            <Grid container xs={12}>
              <Grid container xs={12} md={10} style={{ height: 'max-content' }}>
                <Grid container>
                  <Typography variant="h5" style={{ width: 'fit-content' }}>
                    {t('Temel Bilgiler')}
                  </Typography>
                </Grid>
                {props.data ? (
                  <>
                    {props.data.newDetail && props.data.originalDetail && (
                      <>
                        {props.data.newDetail.website === props.data.originalDetail.website ? (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Website')}:
                                  </Typography>
                            {props.detail.website && (
                              <a href={props.detail.website} target="_blank" style={{ padding: 0 }}>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#118DFF' }}
                                >
                                  {truncateString(props.detail.website, 35)}
                                </Typography>
                              </a>
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Website')}:
                                  </Typography>
                            {props.detail.website && (
                              <a href={props.detail.website} target="_blank" style={{ padding: 0 }}>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    color: '#118DFF',
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {truncateString(props.detail.website, 35)}
                                </Typography>
                              </a>
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  props.detail.website && (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#A0AEC0', marginRight: 10 }}
                      >
                        {t('Website')}:
                            </Typography>
                      <a href={props.detail.website} target="_blank" style={{ padding: 0 }}>
                        <Typography
                          variant="h5"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#118DFF' }}
                        >
                          {truncateString(props.detail.website, 35)}
                        </Typography>
                      </a>
                    </Grid>
                  )
                )}
                {props.data ? (
                  <>
                    {props.data.newDetail && props.data.originalDetail && (
                      <>
                        {JSON.stringify(props.data.newDetail.country) ===
                          JSON.stringify(props.data.originalDetail.country) ? (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Konum')}:
                                  </Typography>
                            {props.detail.country && (
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                              >
                                {get(props.detail.country, 'title', '')}
                              </Typography>
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Konum')}:
                                  </Typography>
                            {props.detail.country && (
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {get(props.detail.country, 'title', '')}
                              </Typography>
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  props.detail.country && (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#A0AEC0', marginRight: 10 }}
                      >
                        {t('Konum')}:
                            </Typography>
                      <Typography variant="h5" align="left" className={classes.fontLg}>
                        {get(props.detail.country, 'title', '')}
                      </Typography>
                    </Grid>
                  )
                )}
                {props.data ? (
                  <>
                    {props.data.startupDetail && props.data.originalDetail && (
                      <>
                        {props.data.startupDetail.businessModelArray === props.data.originalDetail.businessModelArray ? (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('İş Modeli')}:
                                  </Typography>
                            <Typography
                              variant="h5"
                              align="left"
                              display='flex'
                              className={classes.fontLg}
                            >
                              {props.detail.businessModelArray && props.detail.businessModelArray.length > 0 && props.detail.businessModelArray.map((model) => (
                                <Typography variant="body1" style={{marginRight:10}}>
                                  {model}
                                </Typography>
                              ))}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('İş Modeli')}:
                                  </Typography>
                            <Typography
                              variant="h5"
                              align="left"
                              className={classes.fontLg}
                              style={{
                                background: '#0055ff12',
                                padding: '0 5px',
                                borderRadius: 4,
                                display:'flex'
                              }}
                            >
                              {props.detail.businessModelArray && props.detail.businessModelArray.length > 0 && props.detail.businessModelArray.map((model) => (
                                <Typography variant="body1" style={{marginRight:10}}>
                                  {model}
                                </Typography>
                              ))}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  props.detail.businessModelArray && props.detail.businessModelArray.length > 0 && (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#A0AEC0', marginRight: 10 }}
                      >
                        {t('İş Modeli')}:
                            </Typography>
                      <Typography variant="h5" align="left" style={{display:'flex'}} className={classes.fontLg}>
                        {props.detail.businessModelArray.map((model) => (
                          <Typography variant="body1" style={{marginRight:10}}>
                            {model}
                          </Typography>
                        ))}
                      </Typography>
                    </Grid>
                  )
                )}
                {props.data ? (
                  <>
                    {props.data.newDetail && props.data.originalDetail && (
                      <>
                        {props.data.newDetail.contactMail === props.data.originalDetail.contactMail ? (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('İletişim Bilgisi')}:
                                  </Typography>
                            <Typography
                              variant="h5"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#118DFF', paddingRight: 10 }}
                            >
                              {props.detail.contactMail}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('İletişim Bilgisi')}:
                                  </Typography>
                            <Typography
                              variant="h5"
                              align="left"
                              className={classes.fontLg}
                              style={{
                                color: '#118DFF',
                                background: '#0055ff12',
                                padding: '0 5px',
                                borderRadius: 4,
                                paddingRight: 10
                              }}
                            >
                              {props.detail.contactMail}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  props.detail.contactMail && (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#A0AEC0', marginRight: 10 }}
                      >
                        {t('İletişim Bilgisi')}:
                            </Typography>
                      <Typography
                        variant="h5"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#118DFF', paddingRight: 10 }}
                      >
                        {props.detail.contactMail}
                      </Typography>
                    </Grid>
                  )
                )}
                {props.data ? (
                  <>
                    {props.data.startupDetail && props.data.originalDetail && (
                      <>
                        {props.data.startupDetail.foundationDate === props.data.originalDetail.foundationDate ? (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Kuruluş Tarihi')}:
                                  </Typography>
                            {props.detail.foundationDate && (
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                              >
                                {moment(props.detail.foundationDate).format('MMMM YYYY')}
                              </Typography>
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Kuruluş Tarihi')}:
                                  </Typography>
                            {props.detail.foundationDate && (
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {moment(props.detail.foundationDate).format('MMMM YYYY')}
                              </Typography>
                            )}
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  props.detail.foundationDate && (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#A0AEC0', marginRight: 10 }}
                      >
                        {t('Kuruluş Tarihi')}:
                            </Typography>
                      {props.detail.foundationDate && (
                        <Typography
                          variant="h5"
                          align="left"
                          className={classes.fontLg}
                        >
                          {moment(props.detail.foundationDate).format('MMMM YYYY')}
                        </Typography>
                      )}
                    </Grid>
                  )
                )}
                {props.data ? (
                  <>
                    {props.data.startupDetail && props.data.originalDetail && (
                      <>
                        {props.data.startupDetail.stage === props.data.originalDetail.stage ? (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Aşama')}:
                                  </Typography>
                            <Typography
                              variant="h5"
                              align="left"
                              className={classes.fontLg}
                            >
                              {props.detail.stage}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                            <Typography
                              variant="body1"
                              align="left"
                              className={classes.fontLg}
                              style={{ color: '#A0AEC0', marginRight: 10 }}
                            >
                              {t('Aşama')}:
                                  </Typography>
                            <Typography
                              variant="h5"
                              align="left"
                              className={classes.fontLg}
                              style={{
                                background: '#0055ff12',
                                padding: '0 5px',
                                borderRadius: 4,
                              }}
                            >
                              {props.detail.stage}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  props.detail.stage && (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.fontLg}
                        style={{ color: '#A0AEC0', marginRight: 10 }}
                      >
                        {t('Aşama')}:
                            </Typography>
                      <Typography variant="h5" align="left" className={classes.fontLg}>
                        {props.detail.stage}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
              <Grid item xs={12} md={2} alignItems="flex-end">
                <Grid container style={{ height: 'fit-content' }}>
                  {/* <Typography variant="h6" style={{width:'fit-content',marginTop:40}}>
                                    Uygulama Linkleri
                                    </Typography>
                                    <Grid item container direction="column" alignItems="flex-start" style={{marginTop:20}}>
                                        <a href={props.detail.androidApp} style={{padding:0}} target="_blank"><img src={google} alt="google" /></a>
                                        <a href={props.detail.iosApp} style={{padding:0}} target="_blank"><img src={apple} alt="apple"></img></a>
                                    </Grid> */}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {JSON.stringify(props.data.startupDetail.industry) ===
                            JSON.stringify(props.data.originalDetail.industry) ? (
                            <>
                              <Grid item xs={12} style={{ display: 'flex', marginTop: 20 }}>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginTop: 20 }}
                                >
                                  {t('İlişkili olduğu diğer sektörler')}:{' '}
                                </Typography>
                              </Grid>
                              <Grid container xs={12} sm spacing={1} style={{ marginTop: 10 }}>
                                {props.detail.industry &&
                                  props.detail.industry.map((ind, index) => (
                                    <Grid item>
                                      <BlueTag title={ind} />
                                    </Grid>
                                  ))}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} style={{ display: 'flex', marginTop: 20 }}>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginTop: 20 }}
                                >
                                  {t('İlişkili olduğu diğer sektörler')}:
                                      </Typography>
                              </Grid>
                              <Grid container xs={12} sm spacing={1} style={{ marginTop: 10 }}>
                                {props.detail.industry &&
                                  props.detail.industry.map((ind, index) => (
                                    <Grid item>
                                      <BlueTag title={ind} />
                                    </Grid>
                                  ))}
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.industry && (
                      <>
                        <Grid item xs={12} style={{ display: 'flex', marginTop: 20 }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginTop: 20 }}
                          >
                            {t('İlişkili olduğu diğer sektörler')}:
                                </Typography>
                        </Grid>
                        <Grid container xs={12} sm spacing={1} style={{ marginTop: 10 }}>
                          {props.detail.industry &&
                            props.detail.industry.map((ind, index) => (
                              <Grid item>
                                <BlueTag title={ind} />
                              </Grid>
                            ))}
                        </Grid>
                      </>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper className={classes.paper2}>
            <Grid container spacing={2}>
              <Grid item>
                <Field>
                  {({ field, form, meta }) => (
                    <Dropzone
                      maxSize={20971520}
                      accept={'image/*'}
                      onDrop={(acceptedFiles) =>
                        setFiles(
                          acceptedFiles.map((file) =>
                            Object.assign(file, { preview: URL.createObjectURL(file) }),
                          ),
                          form.setFieldValue(`logo`, acceptedFiles),
                          setOpenPhoto(true),
                        )
                      }
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                        <section style={{ textAlign: 'center' }}>
                          {typeof form.values.logo !== 'string' || form.values.logo === '' ? (
                            <div {...getRootProps({ className: classes.baseStyle })}>
                              <input {...getInputProps()} />
                              {!form.values.logo ? (
                                <Grid item container direction="column" justify="center" alignItems="center">
                                  {form.values.title ? (
                                    <Grid container alignItems="center" justify="center">
                                      <Typography variant="h4">{form.values.title.charAt(0)}</Typography>
                                      <AddIcon style={{ color: '#777777', fontSize: 20 }} />
                                    </Grid>
                                  ) : (
                                    <>
                                      <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                      <Typography variant="body2" style={{ display: 'flex', color: '#777777' }}>
                                        {t('Logo')}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                              ) : (
                                <aside style={thumbsContainer}>
                                  {acceptedFiles[0] && (
                                    <div
                                      id={form.values.logo[0].name}
                                      style={thumb}
                                      key={form.values.logo[0].name}
                                    >
                                      <div style={thumbInner}>
                                        <img src={form.values.logo[0].preview} style={img} alt="file" />
                                      </div>
                                    </div>
                                  )}
                                  {form.values.logo[0] && !acceptedFiles[0] && (
                                    <div
                                      id={form.values.logo[0].name}
                                      style={thumb}
                                      key={form.values.logo[0].name}
                                    >
                                      <div style={thumbInner}>
                                        <img src={form.values.logo[0].preview} style={img} alt="file" />
                                      </div>
                                    </div>
                                  )}
                                </aside>
                              )}
                            </div>
                          ) : (
                            <div {...getRootProps({ className: classes.baseStyle })}>
                              <img
                                src={form.values.logo}
                                alt="avatar"
                                style={{ width: '100%', height: '100%' }}
                              />
                            </div>
                          )}
                          {form.values.logo && (
                            <Button
                              variant="containedSecondary"
                              style={{
                                padding: '4.5px 15px',
                                fontSize: 14,
                                marginTop: 5,
                                width: 90,
                                marginBottom: 20,
                              }}
                              onClick={() => form.setFieldValue(`logo`, '')}
                            >
                              Sil
                            </Button>
                          )}
                        </section>
                      )}
                    </Dropzone>
                  )}
                </Field>
              </Grid>
              <Field>
                {({ field, form, meta }) => (
                  <ImageCrop
                    setFieldValue={form.setFieldValue}
                    files={files}
                    logo={form.values.logo}
                    setOpenPhoto={setOpenPhoto}
                    openPhoto={openPhoto}
                  />
                )}
              </Field>
              <Grid xs={12} md={3} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Şirket Adı')}
                </Typography>
                <FormikTextField name="title" type="text" placeholder="Şirket adını yazınız" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={4} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Website')}
                </Typography>
                <FormikTextField
                  name="website"
                  type="text"
                  validate={validateLinkedinUrl}
                  placeholder="https://"
                ></FormikTextField>
              </Grid>
              {country && (
                <Grid xs={12} md={4} item>
                  <Typography variant="h6" style={{ width: 'fit-content' }}>
                    {t('Kuruluş Yeri')}
                  </Typography>
                  <Field
                    component={FormikProvinceDropdown}
                    textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                    name="country"
                    options={country}
                  ></Field>
                </Grid>
              )}
              <Grid xs={12} md={4} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Kuruluş Tarihi')}
                </Typography>
                <DatePickerField name={'foundationDate'} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={4} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('E-posta adresi')}
                </Typography>
                <FormikTextField name="contactMail" type="email"></FormikTextField>
              </Grid>
              <Grid xs={12} md={4} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('İş Modeli')}
                </Typography>
                <Field
                  component={FormikMultiSelect}
                  textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                  name="businessModelArray"
                  options={businessModel}
                ></Field>
              </Grid>
              <Grid xs={12} md={4} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Aşama')}
                </Typography>
                <Field
                  component={FormikNewDropdown}
                  textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                  name="stage"
                  options={stage}
                ></Field>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={4} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Sektör')}
                </Typography>
                {industry.length > 0 && (
                  <Field
                    component={FormikNewDropdown}
                    textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                    name="sector"
                    options={industry}
                  ></Field>
                )}
              </Grid>
              <Field>
                {({ field, form, meta }) => (
                  <>
                    <Grid xs={12} md={8} item>
                      <Typography variant="h6" style={{ width: 'fit-content' }}>
                        {t('İlişkili olduğunuz sektörler')}
                      </Typography>
                      {industry.length > 0 && (
                        <Field
                          component={FormikMultiSelect}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="industry"
                          selectedLength={form.values.industry}
                          options={industry}
                        ></Field>
                      )}
                    </Grid>
                  </>
                )}
              </Field>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Kısa açıklama')}
                </Typography>
                <FormikMultiLine name="oneliner" rows={8} type="text" placeholder="Oneliner yazınız" />
              </Grid>
              <Grid xs={12} md={6} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  {t('Detaylı Bilgi')}
                </Typography>
                <FormikMultiLine
                  name="description"
                  rows={8}
                  type="text"
                  placeholder={t('Şirketinizi detaylı bir şekilde anlatınız')}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  Facebook
                      </Typography>
                <FormikTextField
                  name="socialFacebook"
                  type="text"
                  validate={validateLinkedinUrl}
                  placeholder="https://"
                ></FormikTextField>
              </Grid>
              <Grid xs={12} md={6} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  Twitter
                      </Typography>
                <FormikTextField
                  name="socialTwitter"
                  type="text"
                  validate={validateLinkedinUrl}
                  placeholder="https://"
                ></FormikTextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  Instagram
                      </Typography>
                <FormikTextField
                  name="socialInstagram"
                  type="text"
                  validate={validateLinkedinUrl}
                  placeholder="https://"
                ></FormikTextField>
              </Grid>
              <Grid xs={12} md={6} item>
                <Typography variant="h6" style={{ width: 'fit-content' }}>
                  LinkedIn
                      </Typography>
                <FormikTextField
                  name="socialLinkedin"
                  type="text"
                  validate={validateLinkedinUrl2}
                  placeholder="https://"
                ></FormikTextField>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}