import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import editSvg from'../../static/img/new.svg';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import TextField from "@material-ui/core/TextField";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from "@material-ui/lab/Pagination";
import {DebounceInput} from 'react-debounce-input';
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import {CSVLink} from "react-csv";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Dialog from "@material-ui/core/Dialog";
import { Link } from 'react-router-dom';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import moment from 'moment'
import 'moment/locale/tr'
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newBag from '../../static/icon/new-bag.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import filterIcon from '../../static/icon/filterIcon.svg';
import { UserDialog } from '../AdminUsers/AdminUsers';
import Switch from '@material-ui/core/Switch';
import { getFormattedDateFromUnix } from '../../helpers';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  multiSelect: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  multiSelect2:{
    borderRadius:4,
    '& div': {
      background: 'none!important',
      paddingRight:'5px!important',
      paddingLeft:'5px'
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color:'#003CA5',
    },
  },
  sticker: {
    marginRight:10,
    color:'#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom:10
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filter: {
    height:40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'2px',
    padding: 25,
    marginBottom:50,
    marginTop:50,
  },
  addButton: {
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    color:'#ffba00',
    marginLeft:10,
    borderColor:'#ffba00',
    backgroundColor : "#fff",
    "&:hover" : {
      backgroundColor : "#ffba00",
      color:'#fff',
      borderColor:'#ffba00',
    }
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop:30,
    '& textarea': {
        padding: 16,
        paddingRight:90,
        fontSize:13,
        lineHeight:1
      },
  },
  sendButton:{
      minWidth:50,
      height:50,
      borderRadius:'50%',
      background:'#003CA5',
      marginTop:'-80px',
      marginRight:30,
      cursor:'pointer',
      "&:hover" : {
          background:'#003CA5',
        }
  },
  exportButton:{
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    marginLeft:10,
    border:'1px solid #003CA5',
    borderRadius:3,
    display:'flex',
    alignItems:'center',
    color:'#003CA5',
    "&:hover" : {
      backgroundColor : "#003CA5",
      color:'#fff',
      borderColor:'#003CA5',
    }
  }
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom:'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

function RemoveStartupDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, id, handleClose } = props;

  const handleSubmit = () => {
    props.adminDeleteStartup({ variables: { id: id } })
    .then(data => {
         if (data.data.adminDeleteStartup) {
          refetch()
           onClose(null);
           props.setIsDeleted(true);
         } 
    })
    onClose(null);
    handleClose();
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Girişimi Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Bu girişimi silmek istiyorsunuz. Yaptığınız işlemi gerçekleştirmek istediğinizden emin misiniz?</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Dialog>
  );
}

RemoveStartupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
function StartupDialog(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [valueText, setValueText] = React.useState('');
  const [notes, setNotes] = React.useState([]);
  const [remove, setRemove] = React.useState(false);
  const { onClose, open, startup } = props;

  
  const ALL_NOTES = gql`
  query getStartupAdminNotes($id:ID!) {
      getStartupAdminNotes(id:$id){
          id
          submitBy{
              id
              firstName
              lastName
              profilePhoto
          }
          note
          date
          }
      
      }
  `;
    const { loading, data, refetch} = useQuery(ALL_NOTES, {
      fetchPolicy:'network-only',
      variables: {id:startup.id},
  });
  React.useEffect(() => {
      if(!loading){
          if(data){
            setNotes(data.getStartupAdminNotes);
          }
      }
  },
[data, loading])

  const handleClose = () => {
    onClose(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeText = (event) => {
    setValueText(event.target.value);
  };

  const sendNote= (value) => {
    if (value !== ''){
      props.adminStartupAddNote({ variables: { id: startup.id, note:value } })
        .then(data => {
          if (data.data.adminStartupAddNote) {
            setValueText('')
            refetch()
          }
        });
    }
};

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="space-between" alignItems="center" xs={12} style={{ padding: '0 20px', marginTop: 10 }}>
        <Grid item style={{ marginBottom: 20, marginTop: 20, display: 'flex' }}>
          {startup.logo ? (
            <img
              src={startup.logo}
              alt="logo"
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                marginRight: 20,
                objectFit: 'cover',
                border: 'solid 1px #eaedf3',
              }}
            />
          ) : (
            <Grid
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
                marginRight: 20,
              }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                {startup.title && startup.title.charAt(0)}
              </Typography>
            </Grid>
          )}
          <Typography align="left" variant="body1" stlye={{ fontWeight: 500 }}>
            {startup.title}
          </Typography>
        </Grid>
        <Button
          variant="containedPrimary"
          onClick={() => setRemove(true)}
          style={{ fontSize: 14, height: 50, padding: '10px', color: '#fff', background: '#ff5757' }}
        >
          {' '}
          Girişimi Sil{' '}
        </Button>
        <RemoveStartupDialog
          setIsDeleted={props.setIsDeleted}
          adminDeleteStartup={props.adminDeleteStartup}
          id={startup.id}
          refetch={props.refetch}
          open={remove}
          onClose={() => setRemove(false)}
          handleClose={handleClose}
        />
      </Grid>
      <Grid item xs={12}>
        <hr size="1" style={{ border: '0.2px solid #f5f5f5', marginBottom: 25 }} />
      </Grid>
      <Grid container justify="center" xs={12}>
        <Grid container xs={11}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Notlar" {...a11yProps(0)} style={{ fontWeight: 600 }} />
              <Tab label="Satış Durumu" {...a11yProps(1)} style={{ fontWeight: 600 }} />
              <Tab label="İstekler" {...a11yProps(2)} style={{ fontWeight: 600 }} />
            </Tabs>
          </AppBar>
          <TabPanel style={{ width: '100%' }} value={value} index={0}>
            <Grid container xs={12} style={{ marginTop: 0, border: '1px solid #e9e9f0' }} className={classes.whiteCard}>
              {notes && (
                <Grid container direction="column">
                  {notes.map((note) => (
                    <Grid item style={{ padding: '25px 0', borderBottom: '1px solid rgb(128 128 128 / 19%)' }}>
                      <Grid alignItems="center" container>
                        {note.submitBy.profilePhoto ? (
                          <img
                            src={note.submitBy.profilePhoto}
                            alt="avatar"
                            style={{ maxWidth: 50, borderRadius: '25%', marginRight: 20 }}
                          />
                        ) : (
                          <Grid
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: '25%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#003CA5',
                            marginRight: 20,
                          }}
                        >
                          <Typography variant="h5" style={{ color: '#fff' }}>
                            {note.submitBy.firstName && note.submitBy.firstName.charAt(0)}
                          </Typography>
                        </Grid>
                        )}
                        <Typography variant="h6" align="left">
                          {note.submitBy.firstName} {note.submitBy.lastName}
                        </Typography>
                      </Grid>
                      <Typography
                        variant="body2"
                        align="left"
                        style={{ marginTop: '15px', fontSize: 13, color: '#777777' }}
                      >
                        {note.note}
                      </Typography>
                      <Typography
                        variant="h5"
                        align="left"
                        style={{ marginTop: '15px', fontSize: 13, color: '#003CA5' }}
                      >
                        {moment(note.date).locale('tr').format('Do MMMM YYYY, h:mm:ss a')}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
              <TextField
                id="standard-multiline-flexible"
                multiline
                placeholder={'Yorumunuzu Yazın...'}
                className={classes.textArea}
                rows={4}
                value={valueText}
                onChange={handleChangeText}
              />
              <Grid container justify="flex-end">
                <Button
                  onClick={() => {
                    sendNote(valueText);
                  }}
                  className={classes.sendButton}
                >
                  <SendIcon style={{ color: '#ffffff' }}></SendIcon>
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel style={{ width: '100%' }} value={value} index={1}>
            <Grid
              container
              xs={12}
              style={{ marginTop: 0, border: '1px solid #e9e9f0' }}
              className={classes.whiteCard}
            ></Grid>
          </TabPanel>
          <TabPanel style={{ width: '100%' }} value={value} index={2}>
            <Grid
              container
              xs={12}
              style={{ marginTop: 0, border: '1px solid #e9e9f0' }}
              className={classes.whiteCard}
            ></Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function getDataForCSV(data) {
  let result = []
  data.forEach((item) => {
    result.push({
      id: item.id,
      title: item.title,
      contactMail: item.contactMail,
      businessModelArray: item.businessModelArray,
      website: item.website,
      joinedAt: getFormattedDateFromUnix(item.createdAt),
      listedAt: item.approvalDate ? item.approvalDate.replaceAll(".","/") : "-",
    })
  })
  return result
}
function ExpandingRow(props){
  const classes = useStyles();
  const [showStartup, setShowStartup] = React.useState(false);
  const [showUserProfilePopup, setShowUserProfilePopup] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const { row, refetch } = props;


  const handleIsFeatured = (event) => {
    row.isFeatured = event.target.checked;
    props
      .adminHandleStartupIsFeatured({ variables:{startupId: row.id, isFeatured:event.target.checked } })
      .then((data) => {
        if (data.data.adminHandleStartupIsFeatured) {
          refetch();
          // setAccessSent(true);
          // if (isAccess){
          //   setAccessText('Haberdar olma talebiniz başarıyla geri alınmıştır.');
          // }
          // else{
          //   setAccessText('Haberdar olma talebiniz başarıyla alınmıştır.');
          // }
        } else {
          // setAccessError(true);
        }
      })
      .then(() => {})
      .catch((error) => {
        // setAccessError(true);
      });
  };
  return (
    <>
      <StyledTableRow key={row.id}>
        <StyledTableCell style={{ padding: '12px 0px 12px 30px' }} component="th" scope="row">
          {row.logo ? (
            <img
              src={row.logo}
              alt="logo"
              style={{ width: 35, height: 35, borderRadius: '4px', border: 'solid 1px #eaedf3' }}
            />
          ) : (
            <Grid
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
                marginRight: 20,
              }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                {row.title && row.title.charAt(0)}
              </Typography>
            </Grid>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.slug ? (
            <Typography variant="body2">
              <Link to={`/girisim/${row.slug}`} style={{ padding: 0, fontSize: 16 }}>
                {row.title ? row.title : '-'}
              </Link>
            </Typography>
          ) : (
            <Typography variant="body2">
              <Link to={`/girisim/${row.id}`} style={{ padding: 0, fontSize: 16 }}>
                {row.title ? row.title : '-'}
              </Link>
            </Typography>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.users && row.users.length > 0 && (
            <>
              {row.users.map((user) => {
                return (
                  <>
                    <Typography
                      variant="body2"
                      style={{ width: '100%' }}
                      onClick={() => setShowUserProfilePopup(user.id)}
                    >
                      {user.getFullName}
                    </Typography>
                    <UserDialog
                      adminAddNoteOnUser={props.adminAddNoteOnUser}
                      open={showUserProfilePopup === user.id}
                      row={user}
                      onClose={() => setShowUserProfilePopup(0)}
                    />
                  </>
                );
              })}
            </>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.website ? (
            <a href={row.website.match(/^https?:/) ?
              row.website : '//' + row.website} target="_blank">
              <Typography variant="body2">{row.website}</Typography>
            </a>
          ) : <Typography variant="body2">-</Typography>}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{row.contactMail ? row.contactMail : '-'}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left" >
          <Typography variant="body2">{getFormattedDateFromUnix(row.createdAt)}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left" >
          <Typography variant="body2">{row.approvalDate ? row.approvalDate.replaceAll(".","/") : "-"}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <FiberManualRecordIcon style={{ color: row.isActive ? '#28d09a' : '#ff5757', fontSize: 15 }} />
        </StyledTableCell>
        <StyledTableCell align="left" style={{ paddingRight: 15 }}>
          <Grid container>
            <AntSwitch checked={row.isFeatured} onChange={handleIsFeatured} />
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="right" style={{ paddingRight: 15 }}>
          <Grid container justify="flex-end">
            {row.slug ? (
              <Link style={{ padding: 0 }} to={`/girisim/${row.slug}`}>
                <img
                  src={editSvg}
                  style={{ width: 20, height: 19, marginTop: 2 }}
                  alt="sticker"
                  className={classes.sticker}
                />
              </Link>
            ) : (
              <Link style={{ padding: 0 }} to={`/girisim/${row.id}`}>
                <img
                  src={editSvg}
                  style={{ width: 20, height: 19, marginTop: 2 }}
                  alt="sticker"
                  className={classes.sticker}
                />
              </Link>
            )}
            <Link style={{ padding: 0 }} to={`/admin/kullanicilar/?=${row.id}`}>
              <BookmarkBorderIcon alt="sticker" style={{ marginRight: 5 }} className={classes.sticker} />
            </Link>
            <MoreVertIcon
              style={{ cursor: 'pointer' }}
              onClick={() => setShowStartup(true)}
              alt="sticker"
              className={classes.sticker}
            />
            <StartupDialog
              setIsDeleted={props.setIsDeleted}
              adminDeleteStartup={props.adminDeleteStartup}
              refetch={props.refetch}
              adminStartupAddNote={props.adminStartupAddNote}
              open={showStartup}
              startup={row}
              onClose={() => setShowStartup(false)}
            />
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

export default function DiscoverStartups(props){
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [sort, setSort] = React.useState([]);
    const [startups, setStartups] = React.useState();
    const [startupsCSV, setStartupsCSV] = React.useState();
    const [order, setOrder] = React.useState("id__DESC");
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [filter, setFilter] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCompany = () => {
      props.adminCreateStartup()
        .then(data => {
          if (data.data.adminCreateStartup) {
              props.history.push('/girisim/' + data.data.adminCreateStartup)
          }
      })    
    };

    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    const ALL_STARTUPS = gql`
    query adminStartupsPaginate($order:String, $favoritedUser:ID, $search: String, $page: Int, $pageSize: Int) {
        adminStartupsPaginate(orderBy:[$order],filter : { favoritedUser : $favoritedUser }, search:$search, pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            startups{
                id
                createdAt
                approvalDate
                isActive
                slug
                title
                logo
                contactMail
                foundationDate
                businessModelArray
                industry
                website
                isFeatured
                users{
                    id
                    firstName
                    lastName
                    getFullName
                    profilePhoto
                    socialLinkedIn
                    phoneNumber
                    email
                    country{id, title}
                    city{id, title}
                    isAdmin
                    onBoardInfo{
                        isShareHolder
                        isInvestor
                        investorType
                        type
                        hasEverInvested
                        investedCompanyCount
                        investmentAmount
                        interestedSector
                        plannedAmountOfAngelInvestment
                        riskPreference
                        plannedInvCompAmouInTwo
                        plannedExitYears
                        preferSellOnSB
                        legalObstaclesOnSell
                        legalObstaclesOnSelldesc
                        hearSBFrom
                        purposeEnteringSB
                        corporateName
                        corporateDefinition
                        corporateRole
                        corporateSectors
                        corporateReservedToInvest
                        corporateRiskPreference
                        corpPlannedInvCompAmouInTwo
                        corpPlannedExitYear
                        companyName
                        companyWebsite
                        companySector
                        companyRole
                        investorShareRate
                        hasShare
                    }
                }
            }
        }
    }
    `;
    const CSV_STARTUPS = gql`
    query adminStartupsPaginate($order:String, $page: Int, $pageSize: Int) {
        adminStartupsPaginate(orderBy:[$order], pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            startups{
                id
                createdAt
                approvalDate
                isActive
                logo
                slug
                title
                oneliner
                description
                socialLinkedin
                socialFacebook
                socialTwitter
                summary
                contactMail
                website
                sector
                stage
                industry
                businessModelArray
                foundationDate
                businessModel
                iosApp
                androidApp
                webApp
                productsProblemsSolutions
                productsDifferences
                hasApplication
                targetedMarket
                totalMarketSize
                targetedTotalMarketSize
                targetedRegion
                detailMarketAnalysisNote
                hasPotentialExitStrategy
                plannedExitDate
                potentialBuyers
                hasUsers
                userSize
                monthlyActiveUserSize
                tractionPayingCustomerSize
                tractionHasPoCCustomer
                detailRevenueModels{
                    title
                    description
                    revenuePercentage
                    items{
                        productName
                        price
                    }
                }
                hasInvestment
                totalInvestmentRound
                totalInvestmentSize
                lastValuation
                lastFundingDate
                lastFundingType
                lastFundingSize
                detailMarketAnalysis
                detailTractionText
                tractionHasRevenue
                tractionRevenueSize
                profileFillRate
                isFeatured

                city{id title}
                country{id title}
                documents{file title type date}
                startupFeeds{ date type title desc newUrl }
                competitors{ logo title link}
                customers{ logo title link }
                founders{ logo nameSurname title linkedin position }
                investors{ type logo title subtitle link }
                adminNotes{id submitBy{id firstName lastName email} note date}
                users{
                    id
                    firstName
                    lastName
                    getFullName
                    profilePhoto
                    socialLinkedIn
                    phoneNumber
                    email
                }
            }
        }
    }
    `;

    /*
    const params = new URLSearchParams(props.history.location.search); 
    let fav = parseInt(params.get('favorited-user')) && parseInt(params.get('favorited-user')) >= 0 ? parseInt(params.get('favorited-user')): undefined
    */

    const { loading, data, refetch} = useQuery(ALL_STARTUPS, {
        fetchPolicy:'network-only',
        variables: {order:order, search:search, page:page, pageSize:pageSize /*,favoritedUser:fav*/ },
    });

    const { loading:loadingCSV, data:dataCSV } = useQuery(CSV_STARTUPS, {
      variables: { order: "id__DESC", page: 1, pageSize: 50000},
    });

    const options = [
      {name:'A-Z', value:'title__ASC'},
      {name:'Z-A', value:'title__DESC'},
      {name:'Latest', value:'id__DESC'},
      {name:'Oldest', value:'id__ASC'},
    ];

    const employeeOptions = ['1-5', '6-10', '11-15', '16-50', '51-200']
    const pageSizeOptions = [3, 5, 10, 20, 50]
    const filterOptions = ['Ekip Büyüklüğü göre'];
    React.useEffect(() => {
        if(!loading){
            if(data){
                setStartups(data.adminStartupsPaginate.startups);
                setPageCount(data.adminStartupsPaginate.pageCount)
            }
        }
        if(!loadingCSV){
          if(dataCSV){
              setStartupsCSV(dataCSV.adminStartupsPaginate.startups);
          }
        }
    },
  [data, loading, loadingCSV, dataCSV])

  const handlePageChange = (event, value) => {
    window.scrollTo(0,0);
    setPage(value);
  };
  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };
  const handleFilter = (event) => {
    setPage(1);
    setFilter(event.target.value);
  };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
            >
              <Grid item style={{ width: '100%' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item style={{ display: 'flex' }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                      <img src={newBag} style={{ width: 18, marginTop: 5 }} alt="settings" />
                      <Typography variant="h4">Girişimler</Typography>
                    </Breadcrumbs>
                    {startupsCSV && (
                      <CSVLink
                        data={getDataForCSV(startupsCSV)}
                        filename={(new Date()).toISOString() + '_startups.csv'}
                        target="_blank"
                        variant="outlinedPrimary"
                        className={classes.exportButton}
                      >
                        Dışa Aktar
                      </CSVLink>
                    )}
                    <Button className={classes.addButton} onClick={() => handleCompany()} variant="outlinedPrimary">
                      {' '}
                      Girişim Ekle{' '}
                    </Button>
                  </Grid>
                  <Hidden smDown>
                    <Grid item className={classes.filterArea}>
                      <DebounceInput
                        class="inputTypeSearch"
                        name="search"
                        value={search}
                        placeholder="Ara..."
                        className={classes.Debounce}
                        minLength={2}
                        debounceTimeout={800}
                        onChange={handleSearch}
                      />
                      <Grid className={classes.filter} onClick={handleClick} item>
                        <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                        <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                          Filtrele
                        </Typography>
                        <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
                      </Grid>
                      <Popover
                        id={id}
                        open={openPop}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        classes={{
                          paper: classes.myPaper,
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Grid container xs={12}>
                          <Grid item xs={4} style={{ borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                            <Typography variant="h5">Filtrele</Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <RadioGroup onChange={handleFilter} value={filter}>
                                {filterOptions.map((value, i) => {
                                  // Return the element. Also pass key
                                  return (
                                    <FormControlLabel
                                      classes={{ label: classes.myLabel }}
                                      control={<Radio color="primary" value={value} />}
                                      label={value}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} style={{ paddingLeft: '30px', borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                            <Typography variant="h5">Filtre Seç</Typography>
                          </Grid>
                          <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                            <Typography style={{ marginBottom: 18 }} variant="h5">
                              Seçimleriniz
                            </Typography>
                          </Grid>
                          <Grid container justify="flex-end"></Grid>
                        </Grid>
                      </Popover>
                      <FormControl placeholder="Lütfen Seçiniz" style={{ width: '100%', maxWidth: '116px' }}>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          displayEmpty
                          value={sort}
                          className={classes.multiSelect}
                          onChange={handleSort}
                          IconComponent={(props) => (
                            <ExpandMoreIcon
                              {...props}
                              style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                            />
                          )}
                          input={<Input />}
                          /*   MenuProps={MenuProps} */
                        >
                          <MenuItem value="" disabled>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              Sırala
                            </Typography>
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                {option.name}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Hidden>
                </Grid>
                {startups && (
                  <>
                    {startups.length > 0 ? (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    #{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Girişim{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Yetkililer{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Website{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    İletişim{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Katılma Tarihi{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Listelenme Tarihi{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Statü{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Öne Çıkan Girişim{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{paddingRight:60}}>
                                  <Select
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    displayEmpty
                                    value={pageSize}
                                    className={classes.multiSelect2}
                                    style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                                    IconComponent={(props) => (
                                      <ExpandMoreIcon
                                        {...props}
                                        style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                      />
                                    )}
                                    input={<Input style={{ color: '#003CA5' }} />}
                                    /*   MenuProps={MenuProps} */
                                  >
                                    {pageSizeOptions.map((option) => (
                                      <MenuItem
                                        key={option}
                                        value={option}
                                        onClick={() => (setPageSize(option), setPage(1))}
                                      >
                                        <ListItemText className={classes.ListItemText2} primary={option} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {startups.map((row) => (
                                <ExpandingRow
                                  refetch={refetch}
                                  setIsDeleted={setIsDeleted}
                                  adminDeleteStartup={props.adminDeleteStartup}
                                  adminStartupAddNote={props.adminStartupAddNote}
                                  adminAddNoteOnUser={props.adminAddNoteOnUser}
                                  adminHandleStartupIsFeatured={props.adminHandleStartupIsFeatured}
                                  row={row}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {startups.length > 0 ? (
                          <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                            <Pagination
                              count={pageCount}
                              page={page}
                              onChange={handlePageChange}
                              variant="outlined"
                              color="primary"
                            />
                          </Grid>
                        ) : (
                          <Grid container justify="center">
                            <Typography style={{ margin: '25px 0' }} variant="h5">
                              Girişim bulunamadı...
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <Grid container justify="center">
                          <Typography style={{ margin: '25px 0' }} variant="h5">
                            Girişim bulunamadı...
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setIsDeleted(false)}
              open={isDeleted}
            >
              <Alert severity="success">Girişim başarıyla silindi.</Alert>
            </Snackbar>
          </Grid>
        </main>
      </>
    );
}