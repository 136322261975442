import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from '@material-ui/icons/Send';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment'
import Avatar from '../../static/img/default-avatar.png';
import 'moment/locale/tr'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>({
        paper: {
            textAlign: 'center',
            borderRadius: 16,
            color: theme.palette.text.secondary,
            background: '#ffffff',
            marginBottom: '30px',
            padding: 36,
            boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
            filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))', 
            [theme.breakpoints.down('lg')]: {
                padding: 24,
            }, 
        },
        fontLg:{
            fontSize:14
        },
        textArea: {
            width: '100%',
            borderRadius: 6,
            marginTop:24,
            '& textarea': {
                padding: 16,
                paddingRight:90,
                fontSize:15,
                lineHeight:1
              },
        },
        sendButton:{
            minWidth:50,
            height:50,
            borderRadius:'50%',
            background:'#003CA5',
            marginTop:'-80px',
            marginRight:30,
            cursor:'pointer',
            "&:hover" : {
                background:'#003CA5',
              }
        },
        commentItem:{
            padding:'25px 0',
            borderBottom:'1px solid rgb(128 128 128 / 19%)',
            "&:last-child" : {
                borderBottom:'none',
              }
        }
    }));

export default function CommentTab(props) {
        const classes = useStyles();
        const [value, setValue] = React.useState('');
        const [pageSize, setPageSize] = React.useState(8);
        const [count, setCount] = React.useState();
        const [loadMore, setLoadMore] = React.useState(false);
        const [comments, setComments] = React.useState([]);
        const [countClicked, setCountClicked] = React.useState(false);
        const { t } = useTranslation();

        const ALL_COMMENTS = gql`
        query getStartupComments($startupId:ID!, $pageSize: Int, $loadMore: Boolean) {
            getStartupComments(startupId:$startupId, pageSize:$pageSize, loadMore:$loadMore){
                count
                pageCount
                comments{
                    id
                    user{
                        id
                        firstName
                        lastName
                    }
                    comment
                    date
                }
            }
        }
        `;
          const { loading, data, refetch} = useQuery(ALL_COMMENTS, {
            fetchPolicy:'network-only',
            variables: {startupId:props.id, pageSize:pageSize, loadMore:loadMore },
        });
        React.useEffect(() => {
            if(!loading){
                if(data){
                    setComments(data.getStartupComments.comments);
                    setCount(data.getStartupComments.count);
                }
            }
        },
      [data, loading])
        const handleChange = (event) => {
            setValue(event.target.value);
          };
        const sendComment= (value) => {
            if (value !== ''){
              props.commentOnStartup({ variables: { startupId: props.id, comment:value } })
                .then(data => {
                  if (data.data.commentOnStartup) {
                    setValue('')
                    props.setIsComment(true)
                    refetch()
                  }
                });
            }
        };


        return (
          <Paper className={classes.paper}>
            <Grid item>
              <Typography variant="h3" align="left">
                {t('Yorumlar')}
              </Typography>
              <TextField
                id="standard-multiline-flexible"
                multiline
                placeholder={t('Yorumunuzu paylaşın')}
                className={classes.textArea}
                rows={4}
                value={value}
                onChange={handleChange}
              />
              <Grid container justify="flex-start">
                <Button
                  onClick={() => {
                    sendComment(value);
                  }}
                  style={{ padding: '11.5px 27px', marginTop: 16 }}
                  variant="containedPrimary"
                >
                  {t('Gönder')}
                </Button>
              </Grid>
              {comments && (
                <Grid container direction="column">
                  {comments.map((comment) => (
                    <Grid item className={classes.commentItem}>
                      <Grid alignItems="flex-start" container>
                        {comment.user.profilePhoto ? (
                          <img
                            src={comment.user.profilePhoto}
                            alt="avatar"
                            style={{ maxWidth: 50, borderRadius: '50%', marginRight: 20 }}
                          />
                        ) : (
                          <Grid
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: '#003CA5',
                              marginRight: 20
                            }}
                          >
                            <Typography variant="h3" style={{ color: '#fff' }}>
                              {comment.user.firstName && comment.user.firstName.charAt(0)}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs>
                          <Typography variant="h6" align="left">
                            {comment.user.firstName} {comment.user.lastName}
                          </Typography>
                          <Typography variant="body2" align="left" style={{ fontSize: 12, color: '#A0AEC0' }}>
                            {moment(comment.date).locale('tr').format('Do MMMM YYYY - h:mm:ss a')}
                          </Typography>
                          <Typography variant="body1" align="left" style={{ marginTop: '8px' }}>
                            {comment.comment}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  {count > 8 && countClicked === false && (
                    <Typography
                      variant="body2"
                      align="left"
                      style={{ cursor: 'pointer', marginTop: '15px', fontSize: 13, color: '#777777' }}
                      onClick={() => {
                        setLoadMore(true);
                        setCountClicked(true);
                      }}
                    >
                      {t('Diğer Yorumları Göster...')}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Paper>
        );
}