import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormikTextField from "../FormikShortTextField";
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import { changePasswordSchema } from '../../yupSchemas';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Pagination from "@material-ui/lab/Pagination";
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#172b4d',
        color: '#fff',
        fontWeight: 500,
        fontSize: 14,
        padding:16
    },
    body: {
        fontSize: 14,
        border: '1px solid #8898aa',
        borderLeft: 'none',
        borderBottom: 'none'
    },
    fontLg:{
        fontSize:14
    },
    
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
            backgroundColor: '#fff',
    },
}))(TableRow);
const useStyles = makeStyles((theme) =>({
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            marginBottom: '30px',
            padding: 50,
            [theme.breakpoints.down('sm')]: {
                minHeight: '0',
                padding: 25,
            },
        },
        fontLg:{
            fontSize:14
        },
        objectCard:{
            display:'flex',
            alignItems:'center'
        },
        AddButton:{
            padding: '12.5px 34px',
            fontSize:16,
            marginRight:10,
            fontWeight:'normal'
        },
        baseStyle: {
            flex: 1,
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            alignItems: 'center',
            height: '80px',
            width:'90px',
            justifyContent: 'center',
            borderWidth: 2,
            borderRadius: 4,
            border: 'solid 1px #dddddd6b',
            backgroundColor: '#ffffff',
            color: '#172b4d',
            fontWeight: '500',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        inputField: {
            width:'100%',
            marginTop:10,
        },
        tableRoot:{
            boxShadow:'none',
            border:'1px solid #8898aa',
            borderRadius:4,
            '&::-webkit-scrollbar': {
                display:'none'
            },
        },
    }));
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        flexWrap: 'wrap',
      };
      
      const thumb = {
        display: 'inline-flex',
        borderRadius: 4,
        border: '1px solid #eaeaea',
        width: 90,
        height: 80,
        padding: 4,
        boxSizing: 'border-box'
      };
      
      const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
      };
      
      const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius:7
      };
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);
export default function PrivacySettings(props) {
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const [submitting, setSubmitting] = React.useState(false);
        const [del, setDelete] = React.useState(false);
        const [error, setError] = React.useState('');
        const [actions, setActions] = React.useState(false);
        const [serverSuccess, setServerSuccess] = React.useState(false);
        const [page, setPage] = React.useState(1);
        const [pageCount, setPageCount] = React.useState(1);

        const USER_LOGS = gql`
        query getUserLogs( $page: Int, $pageSize: Int) {
            getUserLogs(pagination:{page:$page, pageSize:$pageSize}){
                count
                pageCount
                userLogs{
                    createdAt
                    type
                    ip
                }
            }
        }
        `;
          const { loading, data, refetch} = useQuery(USER_LOGS, {
            fetchPolicy:'network-only',
            variables: {page:page, pageSize:8},
        });
    
        React.useEffect(() => {
            if(!loading){
                if(data){
                    setActions(data.getUserLogs.userLogs);
                    setPageCount(data.getUserLogs.pageCount);
                }
            }
        },
      [data, loading])

      const handlePageChange = (event, value) => {
        window.scrollTo(0,0);
        setPage(value);
      };

        const handleSubmit = async variablesAll => {
            setSubmitting(true);
            const { ...variables } = variablesAll;
            props.changePassword({ variables })
              .then(data => {
                if (data.data.changePassword) {
                    setSubmitting(false);
                    props.setIsSent(true);
                    window.location.reload();
                } 
                else {
                      setServerSuccess(true);
                }
                }).then(() => {
                    setSubmitting(false);
                    }).catch(error => {
                        if(error.toString().includes("Wrong password")){
                            setError('Eski şifreniz hatalı.')
                        }
                        if(error.toString().includes("New password")){
                            setError('Oluşturduğunuz yeni şifre, eski şifreniz ile aynı olmamalıdır. Lütfen başka bir şifre belirleyiniz.')
                        }
                        setServerSuccess(true);
                });
        };
        const handleDelete = () => {
            setSubmitting(true);
            props.deleteMyAccount()
              .then(data => {
                if (data.data.deleteMyAccount) {
                    setSubmitting(false);
                    setDelete(true)
                } 
                else {
                    setServerSuccess(true);
                }
                }).then(() => {
                    setSubmitting(false);
                    }).catch(error => {
                        setError('Talebiniz Daha Önceden Alındı')
                        setServerSuccess(true);
                });
        };
        return (
                <>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Formik
                            initialValues={{
                                oldPassword: '',
                                newPassword1: '',
                                newPassword2:'',
                            }}
                            validationSchema={changePasswordSchema}
                            onSubmit={values => {
                                handleSubmit(values);
                            }}
                        > 
                        {props => (
                        <Form>
                            <Grid container xs={12} item spacing={3} style={{margin:0}}>
                                <Grid item>
                                <Typography variant="h5" style={{width:'fit-content',marginBottom:15}}>
                                {t('Şifreni Değiştir')}
                                </Typography>
                                <Typography  className={classes.fontLg}variant="body1" align="left" style={{width:'fit-content',color:'#777777'}}>
                                {t('• Şifrenizi değiştirmek için mevcut şifrenizi girerek kimliğinizi doğrulayın.')} 
                                </Typography>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:0}}>
                                    <Typography variant="h6" style={{fontWeight:500,textAlign:'left'}}>{t('Mevcut Şifre')}</Typography>
                                    <FormikTextField className={classes.inputField} name="oldPassword" type="password" placeholder={t('Şifrenizi girin')}></FormikTextField>
                                </Grid>
                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                    <Typography variant="h6" style={{fontWeight:500,textAlign:'left'}}>{t('Yeni Şifre')}</Typography>
                                    <FormikTextField className={classes.inputField} name="newPassword1" type="password" placeholder={t('Yeni şifrenizi girin')}></FormikTextField>
                                </Grid>
                                <Grid item xs={12} md={6} style={{paddingBottom:0}}>
                                    <Typography variant="h6" style={{fontWeight:500,textAlign:'left'}}>{t('Şifreyi Tekrarlayın')}</Typography>
                                    <FormikTextField className={classes.inputField} name="newPassword2" type="password" placeholder={t('Yeni şifrenizi girin')}></FormikTextField>
                                </Grid>
                                <Grid item xs={12} style={{textAlign:'left'}}>
                                    <Button variant="containedPrimary" className={classes.editButton} style={{fontWeight: 'normal',fontSize:16,marginBottom:30}} onClick={() => handleSubmit(props.values)}>{t('Şifreyi Değiştir')}</Button>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h5" style={{width:'fit-content',marginBottom:15}}>
                                    Hesabımı Sil
                                    </Typography>
                                    <Typography  className={classes.fontLg}variant="body1" align="left" style={{width:'fit-content',color:'#777777',marginBottom:15}}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry’s standard dummy text ever since the 
                                    </Typography>
                                    <Typography  className={classes.fontLg}variant="body1" align="left" style={{width:'fit-content',color:'#777777'}}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry’s standard dummy text ever since the 
                                    </Typography>
                                    <Grid item xs={12} style={{textAlign:'left'}}>
                                        <Button variant="outlinedSecondary" className={classes.editButton} style={{fontWeight: 'normal',fontSize:16,marginTop:20}} onClick={handleDelete}> Hesabımı Sil </Button>
                                    </Grid>
                                </Grid>    */}
                            </Grid>
                        </Form> 
                         )}
                       </Formik>
                       
                       <Snackbar autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setServerSuccess(false)} open={serverSuccess} >
                            <Alert severity="error">{error}</Alert>
                        </Snackbar> 
                        <Snackbar autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setDelete(false)} open={del} >
                            <Alert severity="success">Talebiniz Yöneticilerimize İletildi</Alert>
                        </Snackbar> 
                        </Grid>
                        {actions &&
                        <Grid container xs={12} md={6} item spacing={3} style={{margin:0}}> 
                            <Grid xs={12}>
                                <Typography variant="h5" style={{width:'fit-content',marginBottom:25,marginTop:10}}>
                                    Hesap Hareketleri
                                </Typography>
                                <TableContainer component={Paper} classes={{root:classes.tableRoot}}>
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left">Tarih ve Saat</StyledTableCell>
                                                <StyledTableCell align="left">IP address</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {actions.map((row) => (
                                                <>
                                                {row.createdAt && 
                                                <StyledTableRow key={row.createdAt}>
                                                  <StyledTableCell align="center">
                                                    <LightTooltip arrow title={t(row.type)}>
                                                      {row.type === "Hatalı giriş denemesi." ? (
                                                          <ErrorOutlineIcon style={{ color: '#ff0000', fontSize: 25 }}/>) : (
                                                        <CheckCircleOutlineIcon style={{ color: '#00ff00', fontSize: 25 }}/>)}
                                                    </LightTooltip>
                                                  </StyledTableCell>
                                                    <StyledTableCell style={{width:'60%'}} component="th" scope="row">
                                                        <Grid item>
                                                            <Typography variant="body1" color="secondary" style={{fontWeight:500,fontSize:15,color:'#172b4d'}}>{moment(row.createdAt, "x").format("DD MMM YYYY H:mm ")}</Typography>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    {row.ip && <StyledTableCell align="left"><Typography variant="h5" style={{fontSize:15,color:'#172b4d'}} color="primary">{row.ip}</Typography></StyledTableCell>}
                                                </StyledTableRow>
                                                }
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {actions.length > 0 &&
                                    <Grid container item style={{margin:'10px 0',padding:0}} justify="center">
                                        <Pagination count={pageCount} page={page} onChange={handlePageChange}
                                                    variant="outlined" color="primary" />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                </Paper>
                </>
        );
}