import './adminSecondaryList.css';

import { Box, Grid, Input, ListItemText, MenuItem, Select, TableCell, TableRow, Typography } from '@material-ui/core';
import { numberWithPeriods, resolveCurrencySymbol } from '../../../helpers';

import { AntSwitch } from '../../../Components/AntSwitch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export function TableRowsListed({ classes, pageSizeOptions, pageSize, setPageSize, setPage }) {
  return (
    <TableRow>
      <StyledTableCell>#</StyledTableCell>
      <StyledTableCell>İSİM SOYİSİM</StyledTableCell>
      <StyledTableCell>ŞİRKET</StyledTableCell>
      <StyledTableCell>İLETİŞİM YÖNTEMİ</StyledTableCell>
      <StyledTableCell>MİNİMUM TUTAR</StyledTableCell>
      <StyledTableCell>MAKSİMUM TUTAR</StyledTableCell>
      <StyledTableCell>STATÜ</StyledTableCell>
      <StyledTableCell>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          labelId="pagination-1"
          id="pagination-1"
          displayEmpty
          value={pageSize}
          className={classes.multiSelect2}
          style={{ height: 30, borderColor: '#003CA5', width: 50 }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }} />
          )}
          input={<Input />}
        >
          {pageSizeOptions.map((option) => (
            <MenuItem key={option} value={option} onClick={() => (setPageSize(option), setPage(1))}>
              <ListItemText className={classes.ListItemText2} primary={option} />
            </MenuItem>
          ))}
        </Select>
      </StyledTableCell>
    </TableRow>
  );
}

export function ExpandingRowListed(props) {
  const { request, handleVisibilitySwitch } = props;

  if (!request) return null;

  return (
    <>
      <StyledTableRow className="align-typo-left" key={request.startup.title}>
        <StyledTableCell scope="row">
          <Grid container style={{ alignItems: 'center' }}>
            {request.applicant.profilePhoto ? (
              <img
                src={request.applicant.profilePhoto}
                alt="logo"
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  marginRight: 20,
                  objectFit: 'cover',
                  border: 'solid 1px #eaedf3',
                }}
              />
            ) : (
              <Grid
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  display: 'flex',
                  marginRight: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography variant="h5" style={{ color: '#fff' }}>
                  {request.applicant.firstName?.charAt(0)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </StyledTableCell>
        <StyledTableCell>
          {
            request.applicant.slug?
            <Link to={`/yatirimci/${request.applicant.slug}`}>
              <Typography variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
                {`${request.applicant.firstName} ${request.applicant.lastName}`}
              </Typography>
            </Link>:
            <Typography variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
              {`${request.applicant.firstName} ${request.applicant.lastName}`}
            </Typography>
          }
          
        </StyledTableCell>
        <StyledTableCell>
          <Link to={`/girisim/${request.startup.slug}`}>
            <Typography variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
              {request.startup.title}
            </Typography>
          </Link>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{request.contactPreference}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>{`${resolveCurrencySymbol(
            request.currencySymbol,
          )} ${numberWithPeriods(request.minAmount)}`}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>{`${resolveCurrencySymbol(
            request.currencySymbol,
          )} ${numberWithPeriods(request.maxAmount)}`}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <AntSwitch
            checked={request.adminStatusSwitch}
            onChange={() => handleVisibilitySwitch(request.id, request.adminStatusSwitch)}
          />
        </StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
    </>
  );
}

export function TableRowsUnlisted({ classes, pageSizeOptions, pageSize, setPageSize, setPage }) {
  return (
    <TableRow>
      <StyledTableCell>#</StyledTableCell>
      <StyledTableCell>İSİM SOYİSİM</StyledTableCell>
      <StyledTableCell>ŞİRKET ADI</StyledTableCell>
      <StyledTableCell>ŞİRKET WEBSİTESİ</StyledTableCell>
      <StyledTableCell>ŞİRKET YETKİLİSİ İSİM SOYİSİM</StyledTableCell>
      <StyledTableCell>ŞİRKET YETKİLİSİ EMAİL</StyledTableCell>
      <StyledTableCell>İLETİŞİM YÖNTEMİ</StyledTableCell>
      <StyledTableCell>MİNİMUM TUTAR</StyledTableCell>
      <StyledTableCell>MAKSİMUM TUTAR</StyledTableCell>
      <StyledTableCell>STATÜ</StyledTableCell>
      <StyledTableCell>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          labelId="pagination-1"
          id="pagination-1"
          displayEmpty
          value={pageSize}
          className={classes.multiSelect2}
          style={{ height: 30, borderColor: '#003CA5', width: 50 }}
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }} />
          )}
          input={<Input />}
        >
          {pageSizeOptions.map((option) => (
            <MenuItem key={option} value={option} onClick={() => (setPageSize(option), setPage(1))}>
              <ListItemText className={classes.ListItemText2} primary={option} />
            </MenuItem>
          ))}
        </Select>
      </StyledTableCell>
    </TableRow>
  );
}

export function ExpandingRowUnlisted(props) {
  const { request, handleVisibilitySwitch } = props;

  if (!request) return null;

  return (
    <>
      <StyledTableRow className="align-typo-left" key={request.id}>
        <StyledTableCell scope="row">
          <Grid container style={{ alignItems: 'center' }}>
            {request.applicant.profilePhoto ? (
              <img
                src={request.applicant.profilePhoto}
                alt="logo"
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  marginRight: 20,
                  objectFit: 'cover',
                  border: 'solid 1px #eaedf3',
                }}
              />
            ) : (
              <Grid
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  display: 'flex',
                  marginRight: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography variant="h5" style={{ color: '#fff' }}>
                  {request.applicant.firstName?.charAt(0)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </StyledTableCell>
        <StyledTableCell>
          {
            request.applicant.slug?
            <Link to={`/yatirimci/${request.applicant.slug}`}>
              <Typography variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
                {`${request.applicant.firstName} ${request.applicant.lastName}`}
              </Typography>
            </Link>:
            <Typography variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
              {`${request.applicant.firstName} ${request.applicant.lastName}`}
            </Typography>
          }
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{request.companyName}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{request.companyWebsite}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{request.companyAuthorityFullname}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{request.companyAuthorityEmail}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2">{request.contactPreference}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>{`${resolveCurrencySymbol(
            request.currencySymbol,
          )} ${numberWithPeriods(request.minAmount)}`}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>{`${resolveCurrencySymbol(
            request.currencySymbol,
          )} ${numberWithPeriods(request.maxAmount)}`}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <AntSwitch
            checked={request.adminStatusSwitch}
            onChange={() => handleVisibilitySwitch(request.id, request.adminStatusSwitch)}
          />
        </StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
    </>
  );
}

//helper components
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FAFAFB',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#003CA5',
    padding: '16px',
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
    // height: 80,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 16,
    // borderCollapse: 'separate',
  },
}))(TableRow);

export function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}
