import React from "react"
import { Grid, Typography, Hidden, Button } from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom'
import authBackground from '../../static/img/new-auth-background.jpg';
import authLogo from '../../static/img/new-auth-logo.png';
import newAuth1 from '../../static/img/new-auth-1.png';
import newAuth2 from '../../static/img/new-auth-2.png';
import newAuth3 from '../../static/img/new-auth-3.png';
import { loginSchema } from '../../yupSchemas';
import FormikTextField from "../../Components/FormikShortTextField";
import logo from '../../static/img/logo2.png';
import Snackbar from "@material-ui/core/Snackbar";
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useAuth } from "../../context/auth";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import process from 'process';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    display: 'block',
  },
  link: {
    textDecoration: 'none',
    color: '#003CA5',
    padding: 0,
    fontWeight: '600',
    fontSize: 12,
    marginTop: 8
  },
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftGrid: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100vh',
      overflowY: 'auto'
    },
    backgroundImage: 'url(' + authBackground + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  logoGrid: {
    height: 'fit-content',
    [theme.breakpoints.up('xl')]: {
      padding: 80,
      paddingBottom: 0,
      paddingTop: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      paddingTop: '35px',
    },
    padding: 40,
  },
  newAround: {
    marginTop: 35,
    color: '#A0AEC0'
  },
  signUp: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 30,
    },
    marginTop: 20,
  },
  loginGrid: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },
  },
  logo: {
    width: 120,
  },
  firstLogo: {
    width: 200,
  },
  welcomeText: {
    color: '#27272E',
    marginTop: 6,
  },
  EmailText: {
    [theme.breakpoints.up('xl')]: {
      marginTop: 50,
    },
    marginTop: 30,
  },
  PasswordText: {
    fontWeight: 600,
  },
  signLink: {
    fontWeight: 'normal',
    color: '#172b4d',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
  },
}));

export default function Login(props) {
  const { setAuthToken } = useAuth();
  const { t, i18n } = useTranslation();
  document.body.style = 'background: #ffffff;margin:0';
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const classes = useStyles();
  const [firstPage, setFirstPage] = React.useState(false);
  const [serverMessage, setServerMessage] = React.useState({
    detail: '',
    message: '',
    type: false,
  });
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000
  };

  if (props.location.state && props.location.state.haveMessage && !message) {
    if (props.location.state.message && props.location.state.message.type && props.location.state.message.text) {
      setShowMessage(true)
      setMessage(props.location.state.message)
      window.history.replaceState({}, document.title)
    }
  }
  const handleOnCloseSnackbar = (event) => {
    setShowMessage(false);
  };
  if (window.location.href.indexOf("?") > -1 && firstPage === false) {
    setFirstPage(true)
  }

  const handleSubmit = variables => {
    setIsSubmitting(true);
    props.authenticate({ variables })
      .then(data => {
        if (data.data.authenticate) {
          const token = data.data.authenticate.token;

          let params = new URLSearchParams(props.history.location.search);
          params.append('utm_event', "login");
          /*  const userId = data.data.authenticateUser.id; */
          setAuthToken(token);
          if (window.location.origin.includes('dashboard')){
            try {
              window.gist.identify(data.data.authenticate.user.id.toString(), {
                "email": data.data.authenticate.user.email,
              });
            }catch {}
          }
          if (data.data.authenticate.user.role === "Member") {
            if (params.get("next")) {
              props.history.push(params.get("next"));
            }
            else {
              props.history.push(`/dashboard?utm_event=login`);
            }
          }
          else if (data.data.authenticate.user.role === "SuperAdmin" || data.data.authenticate.user.role === "Admin") {
            if (params.get("next")) {
              props.history.push(params.get("next"));
            }
            else {
              props.history.push(`/dashboard-admin`);
            }
          }
        }
        else {
          setServerMessage({
            detail: get(data, 'errors[0].functionError.detail', ' '),
            message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
            type: false,
          });
          setServerSuccess(true);
        }
      }).then(() => {
        setIsSubmitting(false);
      }).catch(error => {
        console.log(error)
        setServerMessage({
          message: 'Unexpected Error',
          type: false,
        });
        setServerSuccess(true);
      });
  };
  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid container item lg={6} md={12} xs={12}>
        {firstPage ? (
          <Grid xs={12} container item alignItems="center" className={classes.logoGrid}>
            {window.location.href.includes('?') ? (
              <Link to={'/sign-up'} style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                <KeyboardBackspaceIcon style={{ marginRight: 5 }} />
                <Typography variant="body1">{t('Geri Dön')}</Typography>
              </Link>
            ) : (
              <Link
                onClick={() => setFirstPage(false)}
                style={{ padding: 0, display: 'flex', alignItems: 'center' }}
              >
                <KeyboardBackspaceIcon style={{ marginRight: 5 }} />
                <Typography variant="body1">{t('Geri Dön')}</Typography>
              </Link>
            )}
          </Grid>
        ) : (
          <Grid xs={12} container item justify="space-between" alignItems="center"></Grid>
        )}
        <Grid sm={12} xl={12} xs={12} justify="center" className={classes.loginGrid} container item>
          {firstPage ? (
            <Grid item className={classes.mainGrid} sm={7} xs={11}>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={loginSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {(props) => (
                  <Form>
                    <Typography variant="h2" align="left">
                      👋
                          </Typography>
                    <Typography variant="h2" align="left" style={{ marginTop: 30 }}>
                      {t('Giriş Yap')}
                    </Typography>
                    <Typography variant="body1" align="left" className={classes.welcomeText}>
                      {t('Hesabınıza giriş yapın.')}
                    </Typography>
                    <FormikTextField
                      name="email"
                      type="email"
                      placeholder={t('E-postanızı girin')}
                      style={{ marginTop: 48 }}
                    />
                    <FormikTextField
                      name="password"
                      type="password"
                      placeholder="Şifrenizi girin"
                      style={{ marginTop: 24 }}
                    />
                    <Grid container xs={12} justify="flex-end">
                      <Link to="/forgot-password" className={classes.link}>
                        {t('Şifremi Unuttum')}
                      </Link>
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginTop: 30 }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: '600', width: '100%' }}
                        type="submit"
                        disabled={
                          !props.isValid ||
                          (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)
                        }
                      >
                        {t('Giriş Yap')}
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>

              <Snackbar
                onClose={() => setServerSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={serverSuccess}
              >
                <Alert severity="error">Hatalı veya eksik bir şifre girdiniz.</Alert>
              </Snackbar>
              <Grid container justify="center">
                <Typography
                  className={classes.newAround}
                  style={{ marginBottom: 40 }}
                  variant="h6"
                  align="center"
                >
                  Henüz bir hesabınız yok mu?{' '}
                  <Link
                    to="/sign-up/?=l"
                    style={{ color: '#003CA5', fontWeight: '600', padding: 0, fontSize: 14 }}
                  >
                    Kayıt Ol
                        </Link>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.mainGrid} item sm={12} xs={11}>
              <Grid style={{ textAlign: 'center' }}>
                <img src={logo} alt="startup-borsa" className={classes.firstLogo}></img>
                <Grid item container xs={12} justify="center">
                  <Grid item container xs={7} justify="center">
                    <Typography variant="body1" align="center" className={classes.welcomeText}>
                      Girişimci ve yatırımcıların dijitalde <br /> buluşma noktasına hoşgeldiniz.
                          </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify="center" style={{ marginTop: 30 }}>
                  <Button
                    variant="containedPrimary"
                    onClick={() => setFirstPage(true)}
                    style={{ fontWeight: '600' }}
                  >
                    {t('Giriş Yap')}
                  </Button>
                </Grid>
                <Grid container justify="center">
                  <Typography
                    className={classes.newAround}
                    style={{ marginBottom: 40 }}
                    variant="h6"
                    align="center"
                  >
                    Henüz bir hesabınız yok mu?{' '}
                    <Link
                      to="/sign-up/?=l"
                      style={{ color: '#003CA5', fontWeight: '600', padding: 0, fontSize: 14 }}
                    >
                      Kayıt Ol
                          </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Typography
                  className={classes.newAround}
                  style={{ marginBottom: 40 }}
                  variant="h6"
                  align="center"
                >
                  {t('Startup Borsa, bir')}{' '}
                  <a
                    href="https://www.gedik.com/"
                    target="_blank"
                    rel="nofollow"
                    style={{ color: '#003CA5', fontWeight: '600', padding: 0 }}
                  >
                    Gedik Yatırım
                        </a>{' '}
                  {t('kuruluşudur.')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid item sm={6} xs={12}>
          <Grid className={classes.leftGrid}>
            <Grid container justify="flex-end" style={{ padding: 50 }}>
              <img src={authLogo} alt="authlogo" />
            </Grid>
            <Grid item style={{ marginBottom: 80 }}>
              <Slider {...settings} style={{ textAlign: 'center' }}>
                <Grid item style={{ outline: 'unset' }}>
                  <Grid container justify="center" style={{ marginTop: 60 }}>
                    <img src={newAuth1} alt="auth1" style={{ width: 350 }} />
                  </Grid>
                  <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                    Yatırımcı ilişkilerini yönet
                        </Typography>
                  <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                    Girişiminizin gelişmelerini tek bir <br /> noktadan takip edebilir, yatırımcılarınız ile{' '}
                    <br /> kolayca paylaşabilirsiniz.
                        </Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                  <Grid container justify="center">
                    <img src={newAuth2} alt="auth2" style={{ width: 480 }} />
                  </Grid>
                  <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                    Türkiyenin en geniş yatırımcı <br /> ağına ulaş!
                        </Typography>
                  <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                    Girişimcilik ekosisteminin deneyimli isimleriyle birlikte
                          <br /> çalışma ve iletişime geçme fırsatlarını yakala.
                        </Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                  <Grid container justify="center" style={{ marginTop: 150 }}>
                    <img src={newAuth3} alt="auth3" style={{ width: 580 }} />
                  </Grid>
                  <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                    Yatırımcı ilişkilerini <br />
                                  tek bir noktadan sürdür!
                          </Typography>
                  <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                    Birçok yatırımcıyla yapacağın paylaşımları, <br />
                                veri takibini ve ortaklık tablonu tek <br /> kanaldan takip et.
                          </Typography>
                </Grid>
              </Slider>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      {message ? (
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleOnCloseSnackbar}
          open={showMessage}
        >
          <Alert severity={message.type}>{message.text}</Alert>
        </Snackbar>
      ) : null}
    </Grid>
  );
}