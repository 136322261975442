import DraftProfile from './DraftProfile'
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import {HEADER_LEFT_DRAWER} from '../../queries'

const ADD_FAVORITE_MUTATION = gql`
  mutation addFavorite(
    $startupId: ID!
  ) {
    addFavorite(
        startupId: $startupId
      )
    }
`;
const REMOVE_FAVORITE_MUTATION = gql`
  mutation removeFavorite(
    $startupId: ID!
  ) {
    removeFavorite(
        startupId: $startupId
      )
    }
`;

const EDIT_DRAFT = gql`
  mutation editStartupDraft(
    $draftId: ID!
    $status: String
    $logo: Upload
    $title: String
    $oneliner: String
    $description: String
    $city: ID
    $country: ID
    $sector: String
    $stage: String
    $socialLinkedin: String
    $socialFacebook: String
    $productsProblemsSolutions: String
    $productsDifferences: String
    $socialTwitter: String
    $socialInstagram: String
    $stockPrice: Int
    $stockValuation: Int
    $stockDiscount: Int
    $stockEndDate: String
    $summary: String
    $contactMail: String
    $website: String
    $industry: [String]
    $businessModelArray: [String]
    $foundationDate: String
    $iosApp: String
    $androidApp: String
    $webApp: String
    $hasApplication: String
    $targetedMarket: String
    $totalMarketSize: String
    $targetedTotalMarketSize: String
    $targetedRegion: String
    $detailMarketAnalysisNote: String
    $plannedExitDate: String
    $potentialBuyers: String
    $hasUsers: String
    $userSize: Int
    $monthlyActiveUserSize: Int
    $competitors: [startupCompetitorInput]
    $customers: [startupCustomerInput]
    $founders: [startupFounderInput]
    $investors: [startupInvestorInput]
    $detailMarketAnalysis: String
    $detailTractionText: String
    $hasInvestment: String
    $totalInvestmentRound: Int
    $totalInvestmentSize: BigInt
    $hasPotentialExitStrategy: String
    $lastValuation: BigInt
    $lastFundingDate: String
    $lastFundingType: String
    $lastFundingSize: BigInt
    $tractionPayingCustomerSize: Int
    $tractionHasPoCCustomer: String
    $tractionHasRevenue: String
    $tractionRevenueSize: Int
    $detailRevenueModels: [startupRevenueModelInput]
    $startupFeeds: [startupFeedInput]
    $documents: [startupDocumentInput]
  ) {
    editStartupDraft(
      draftId: $draftId
      status: $status
      logo: $logo
      title: $title
      oneliner: $oneliner
      description: $description
      city: $city
      country: $country
      sector: $sector
      stage: $stage
      socialLinkedin: $socialLinkedin
      socialFacebook: $socialFacebook
      socialTwitter: $socialTwitter
      socialInstagram: $socialInstagram
      stockPrice: $stockPrice
      stockValuation: $stockValuation
      stockDiscount: $stockDiscount
      stockEndDate: $stockEndDate
      summary: $summary
      webApp: $webApp
      hasApplication: $hasApplication
      targetedMarket: $targetedMarket
      totalMarketSize: $totalMarketSize
      targetedTotalMarketSize: $targetedTotalMarketSize
      targetedRegion: $targetedRegion
      detailMarketAnalysisNote: $detailMarketAnalysisNote
      hasPotentialExitStrategy: $hasPotentialExitStrategy
      plannedExitDate: $plannedExitDate
      potentialBuyers: $potentialBuyers
      hasUsers: $hasUsers
      userSize: $userSize
      monthlyActiveUserSize: $monthlyActiveUserSize
      contactMail: $contactMail
      website: $website
      industry: $industry
      businessModelArray: $businessModelArray
      foundationDate: $foundationDate
      iosApp: $iosApp
      androidApp: $androidApp
      productsProblemsSolutions: $productsProblemsSolutions
      productsDifferences: $productsDifferences
      competitors: $competitors
      customers: $customers
      hasInvestment: $hasInvestment
      totalInvestmentRound: $totalInvestmentRound
      totalInvestmentSize: $totalInvestmentSize
      lastValuation: $lastValuation
      lastFundingDate: $lastFundingDate
      lastFundingType: $lastFundingType
      lastFundingSize: $lastFundingSize
      founders: $founders
      investors: $investors
      detailMarketAnalysis: $detailMarketAnalysis
      detailTractionText: $detailTractionText
      tractionPayingCustomerSize: $tractionPayingCustomerSize
      tractionHasPoCCustomer: $tractionHasPoCCustomer
      tractionHasRevenue: $tractionHasRevenue
      tractionRevenueSize: $tractionRevenueSize
      detailRevenueModels: $detailRevenueModels
      startupFeeds: $startupFeeds
      documents: $documents
      )
    }
`;

const EDIT_DRAFT_STATUS = gql`
    mutation startupDraftSetEdit($draftId: ID!) {
      startupDraftSetEdit(draftId:$draftId) 
    }
`;

const MANAGE_APPROVAL = gql`
  mutation adminManageApproval(
    $id: ID!
    $status: String!
    $description: String
){
    adminManageApproval
    (
        id: $id
        status: $status
        description: $description
    )
  }
`;
const JoinWithMutation = compose(
    graphql(EDIT_DRAFT, {
      name: 'editStartupDraft',
    }),
    graphql(MANAGE_APPROVAL, {
      name: 'adminManageApproval',
    }),
    graphql(EDIT_DRAFT_STATUS, {
      name: 'startupDraftSetEdit',
    }),
    graphql(ADD_FAVORITE_MUTATION, {
      name: 'addFavorite',
    }),
    graphql(REMOVE_FAVORITE_MUTATION, {
      name: 'removeFavorite',
    }),
    graphql(HEADER_LEFT_DRAWER, {
      name: 'HeaderLeftDrawer',
    }),
  )(DraftProfile);
  export default JoinWithMutation;