import AdminInvestmentRequests from './AdminInvestmentRequests'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const REQUEST_ACTION = gql`
  mutation adminHandleInvestmentRequestAction($adminTookAction: Boolean!, $reqId: ID!) {
    adminHandleInvestmentRequestAction(adminTookAction: $adminTookAction, reqId: $reqId) 
  }
`;


const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(REQUEST_ACTION, {
    name: 'adminHandleInvestmentRequestAction',
  }),
)(AdminInvestmentRequests);
export default JoinWithMutation;