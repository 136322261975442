import { ADMIN_EDIT_INVESTMENT_SPRINT_STAGE_1, CREATE_INVESTMENT_SPRINT_STAGE_1 } from '../../../mutations';
import { Field, Form, Formik } from 'formik';
import { Grid, Snackbar, Typography } from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import FormikDropdown from '../../../Components/FormikDropdown';
import FormikNumberField from '../../../Components/FormikNumberField';
import FormikTextField from '../../../Components/FormikShortTextField';
import { GET_CREATE_INVESTMENT_SPRINT_DETAIL } from '../../../queries';
import React from 'react';
import { investmentSprintFormSchema } from '../../../yupSchemas';
import { makeStyles } from '@material-ui/core/styles';
import { resolveCurrencyName } from '../../../helpers';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  stepperDarkBlue: {
    backgroundColor: '#003CA5',
    border: 0,
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    zIndex: '998',
  },
  stepperLightBlue: {
    backgroundColor: '#118DFF',
    border: 0,
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    zIndex: '998',
  },
  stepper: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    backgroundColor: '#F0F5FF',
    zIndex: '998',
  },
  stepperLine: {
    width: '8px',
    borderRadius: 50,
    height: '375px',

    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },

    backgroundColor: '#F0F5FF',
    position: 'absolute',
    marginLeft: '14px',
    marginTop: '-20px',
    zIndex: '1',
  },
  contactLink: {
    fontSize: 14,
    color: '#003CA5',
    fontWeight: '600',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mainContainer: {
    padding: '33px 0 33px 0',
  },
}));

function MainFormStage({
  setFormStep,
  formData,
  isAdmin,
  setDisableNextButton,
  formikRef,
  succesfullyEditedSnackbar,
  setSuccesfullyEditedSnackbar,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [openCommitmentField, setOpenCommitmentField] = React.useState('');

  const stageYesno = [true, false];
  const stageShare = ['Fikir aşaması', 'MVP', 'Erken Aşama', 'Büyüme'];
  const stageTel = ['Email', 'Telefon'];
  const [requiredFieldsAlert, setRequiredFieldsAlert] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    sprintClosingDate: formData?.sprintClosingDate || '',
    oldEvaluation: formData?.oldEvaluation || '',
    //anticipatedEvaluation: formData?.anticipatedEvaluation || '',
    //minRequiredInvestmentAmount: formData?.minRequiredInvestmentAmount || '',
    requiredInvestmentAmount: formData?.requiredInvestmentAmount || '',
    hasReceivedCommitment: formData?.hasReceivedCommitment || '',
    //shareType: formData?.shareType || '',
    minTicketSize: formData?.minTicketSize || '',
    contactPreference: formData?.contactPreference || '',
    oldAdditionalCommitment: formData?.oldAdditionalCommitment || '',
    sprintCurrencySymbol: formData?.sprintCurrencySymbol || '',
  });

  const [adminEditSprintStage1] = useMutation(ADMIN_EDIT_INVESTMENT_SPRINT_STAGE_1);
  const [createSprintStage1] = useMutation(CREATE_INVESTMENT_SPRINT_STAGE_1);

  React.useEffect(() => {
    setDisableNextButton(false);
  }, []);

  React.useEffect(() => {
    if (formData)
      setInitialValues({
        sprintClosingDate: formData.sprintClosingDate || '',
        oldEvaluation: formData.oldEvaluation || '',
        //anticipatedEvaluation: formData.anticipatedEvaluation || '',
        //minRequiredInvestmentAmount: formData.minRequiredInvestmentAmount || '',
        requiredInvestmentAmount: formData.requiredInvestmentAmount || '',
        hasReceivedCommitment: formData.hasReceivedCommitment || '',
        //shareType: formData.shareType || '',
        minTicketSize: formData.minTicketSize || '',
        contactPreference: formData.contactPreference || '',
        oldAdditionalCommitment: formData?.oldAdditionalCommitment || '',
        sprintCurrencySymbol: formData?.sprintCurrencySymbol || '',
      });
  }, [formData]);

  const handleFormikSubmit = async (values) => {
    let usedMutation = createSprintStage1;
    if (isAdmin) usedMutation = adminEditSprintStage1;

    const submitData = { sprintId: formData?.id, ...values };
    submitData.oldEvaluation = parseInt(submitData.oldEvaluation);
    submitData.oldAdditionalCommitment = parseInt(submitData.oldAdditionalCommitment);

    const { error } = await usedMutation({
      variables: submitData,
      refetchQueries: !isAdmin && [{ query: GET_CREATE_INVESTMENT_SPRINT_DETAIL }],
    });

    if (error) console.log(error);

    setFormStep(2);
  };

  const handleActive = () => {
    if (window.gist) {
      window.gist.chat('open');
    }
  };

  const currency = ['₺', '£', '$', '€'];

  return (
    <div>
      <Grid className={classes.mainContainer} container spacing={5}>
        <Grid item xs={12} md={7}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={investmentSprintFormSchema}
            onSubmit={handleFormikSubmit}
            innerRef={formikRef}
          >
            {(props) => (
              <Form>
                <Grid item xs>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: 'center',
                      color: '#27272E',
                      width: '100%',
                      marginBottom: 30,
                      marginTop: 0,
                      fontSize: 28,
                    }}
                  >
                    {t('Tur Bilgileri')}
                  </Typography>
                </Grid>
                <Grid item align="center" justify="center" xs>
                  <Typography
                    style={{
                      textAlign: 'center',
                      width: '60%',
                      marginBottom: 30,
                      fontSize: 16,
                      color: '#425466',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      textAlign: 'center',
                      fontFeatureSettings: 'salt on',
                    }}
                  >
                    {t('Aktif yatırım arayışınız ile ilgili detayları giriniz.')}
                  </Typography>
                </Grid>
                <Grid container spacing={5}>
                  <Grid xs={12} md={6} item>
                    <Field
                      style={{
                        marginTop: 10,
                        //margin: '-15px !important',
                        padding: '-15px !important',
                        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                        border: 0,
                        borderRadius: 6,
                      }}
                      component={FormikDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined', placeholder: 'Para Birimi' }}
                      name="sprintCurrencySymbol"
                      getOptionLabel={(option) => resolveCurrencyName(option)}
                      options={currency}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikNumberField
                      style={{
                        margin: '-15px !important',
                        padding: '-15px !important',
                        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                        border: 0,
                        borderRadius: 6,
                      }}
                      name="oldEvaluation"
                      required
                      //validate={validateLinkedinUrl}
                      placeholder="Yatırım öncesi değerleme"
                    ></FormikNumberField>
                  </Grid>
                </Grid>
                <Grid container spacing={5}></Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6}>
                    <FormikNumberField
                      style={{
                        margin: '-15px !important',
                        padding: '-15px !important',
                        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                        border: 0,
                        borderRadius: 6,
                      }}
                      name="requiredInvestmentAmount"
                      type="number"
                      required
                      //validate={validateLinkedinUrl}
                      placeholder="Aranan yatırım tutarı"
                    ></FormikNumberField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikNumberField
                      style={{
                        margin: '-15px !important',
                        padding: '-15px !important',
                        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                        border: 0,
                        borderRadius: 6,
                      }}
                      name="minTicketSize"
                      required
                      type="number"
                      //validate={validateLinkedinUrl}
                      placeholder="Minimum yatırım tutarı"
                    ></FormikNumberField>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6}>
                    <Field
                      style={{
                        marginBottom: 0,
                        marginTop: -10,
                        //margin: '-15px !important',
                        padding: '-15px !important',
                        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                        border: 0,
                        borderRadius: 6,
                      }}
                      component={FormikDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined', placeholder: 'Taahhüt aldınız mı?' }}
                      name="hasReceivedCommitment"
                      options={stageYesno}
                      getOptionLabel={(option) => (option === true ? t('Evet') : option === false ? t('Hayır') : '')}
                      onChange={setOpenCommitmentField(props.values.hasReceivedCommitment)}
                    ></Field>
                  </Grid>
                  {openCommitmentField === true ? (
                    <Grid item xs={12} md={6}>
                      <FormikNumberField
                        style={{
                          marginTop: -10,
                          margin: '-15px !important',
                          padding: '-15px !important',
                          boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                          border: 0,
                          borderRadius: 6,
                        }}
                        name="oldAdditionalCommitment"
                        required
                        type="number"
                        //validate={validateLinkedinUrl}
                        placeholder="Mevcut taahhüt tutarı"
                      ></FormikNumberField>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}></Grid>
                    </>
                  )}
                </Grid>

                <Grid container spacing={5} style={{ marginTop: !openCommitmentField && 40 }}>
                  <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                    <Typography style={{ fontSize: '12px', marginBottom: 4, marginTop: -29, textAlign: 'left' }}>
                      Planlanan tur kapanış tarihi
                    </Typography>
                    <FormikTextField
                      style={{
                        margin: '-15px !important',
                        padding: '-15px !important',
                        boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2) !important',
                        border: 0,
                        borderRadius: 6,
                      }}
                      name="sprintClosingDate"
                      type="date"
                      required
                      min={new Date().toISOString().split('T')[0]}
                      placeholder="Tahmini Tur kapanış tarihi"
                    ></FormikTextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      style={{ width: '100%', marginTop: -20 }}
                      component={FormikDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined', placeholder: 'İletişim yöntemi' }}
                      name="contactPreference"
                      options={stageTel}
                    ></Field>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid item xs>
            <Typography
              variant="h5"
              style={{
                textAlign: 'center',
                color: '#27272E',
                width: '100%',
                marginBottom: 30,
                marginTop: 0,
                fontSize: 28,
              }}
            >
              {t('Süreç')}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              style={{
                textAlign: 'center',
                width: '100%',
                marginBottom: 30,
                fontSize: 16,
                color: '#425466',
                fontStyle: 'normal',
                fontWeight: 'normal',
                textAlign: 'center',
                fontFeatureSettings: 'salt on',
              }}
            >
              {t(
                'Startup Borsa, yatırım ile yolculuğunu büyütmeyi hedefleyen teknoloji girişimlerinin yatırımcılara erişim sağlamalarına yardımcı olur ve iletişim sürecini destekler.',
              )}
            </Typography>
          </Grid>
          <div className={classes.stepperLine}></div>
          <Grid container xs style={{ marginBottom: 30 }}>
            <Grid item className={classes.stepperLightBlue}>
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.75 8L7.25 9.5L10.25 6.5M14.75 8C14.75 8.88642 14.5754 9.76417 14.2362 10.5831C13.897 11.4021 13.3998 12.1462 12.773 12.773C12.1462 13.3998 11.4021 13.897 10.5831 14.2362C9.76417 14.5754 8.88642 14.75 8 14.75C7.11358 14.75 6.23583 14.5754 5.41689 14.2362C4.59794 13.897 3.85382 13.3998 3.22703 12.773C2.60023 12.1462 2.10303 11.4021 1.76381 10.5831C1.42459 9.76417 1.25 8.88642 1.25 8C1.25 6.20979 1.96116 4.4929 3.22703 3.22703C4.4929 1.96116 6.20979 1.25 8 1.25C9.79021 1.25 11.5071 1.96116 12.773 3.22703C14.0388 4.4929 14.75 6.20979 14.75 8Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Grid>
            <Grid xs>
              <Typography
                variant="h6"
                style={{
                  textAlign: 'start',
                  fontWeight: '600',
                  fontSize: '14px',
                  width: '100%',
                  marginBottom: 5,
                  color: 'rgb(39, 39, 46,0.90)',
                }}
              >
                {t('Şirket Profilini Doldur ve Listelen')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#718096',
                  textAlign: 'start',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  width: '100%',
                }}
              >
                {t(
                  'Girişimlerin uygun yatırımcıya erişebilmesi için profilinin doluluk oranının yüksek olması gerekir.',
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs style={{ marginBottom: 30 }}>
            <Grid item className={classes.stepperDarkBlue}>
              <Typography variant="h6" style={{ color: '#fff' }}>
                1
              </Typography>
            </Grid>
            <Grid xs>
              <Typography
                variant="h6"
                style={{
                  color: 'rgb(39, 39, 46,0.90)',
                  textAlign: 'start',
                  fontWeight: '600',
                  fontSize: '14px',
                  width: '100%',
                  marginBottom: 5,
                }}
              >
                {t('Yatırım Turu Bilgilerini Ekle')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#718096',
                  textAlign: 'start',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  width: '100%',
                }}
              >
                {t('Yatırımcıların tur hakkındaki bilgilere erişebilmesi için detayları girin.')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs style={{ marginBottom: 30 }}>
            <Grid item className={classes.stepper}>
              <Typography variant="h6" style={{ color: '#003CA5' }}>
                2
              </Typography>
            </Grid>
            <Grid xs>
              <Typography
                variant="h6"
                style={{
                  color: 'rgb(39, 39, 46,0.90)',
                  textAlign: 'start',
                  fontWeight: '600',
                  fontSize: '14px',
                  width: '100%',
                  marginBottom: 5,
                }}
              >
                {t('Hesap Yöneticisi ile Görüş')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#718096',
                  textAlign: 'start',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  width: '100%',
                }}
              >
                {t('Uygun yatırımcılara erişebilmen ve rehberlik için temsilcimiz size ulaşacaktır.')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs style={{ marginBottom: 30 }}>
            <Grid item className={classes.stepper}>
              <Typography variant="h6" style={{ color: '#003CA5' }}>
                3
              </Typography>
            </Grid>
            <Grid xs>
              <Typography
                variant="h6"
                style={{
                  color: 'rgb(39, 39, 46,0.90)',
                  textAlign: 'start',
                  fontWeight: '600',
                  fontSize: '14px',
                  width: '100%',
                  marginBottom: 5,
                }}
              >
                {t('Sıradaki Yatırımcınla Tanış')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#718096',
                  textAlign: 'start',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  width: '100%',
                }}
              >
                {t('Yatırım arayan girişimlerde yer alarak öne çık ve sıradaki yatırımcınla tanış.')}
              </Typography>
            </Grid>
          </Grid>

          <Typography
            variant="h5"
            style={{
              width: '100%',
              marginTop: 35,
              fontSize: 14,
              color: '#A0AEC0',
              fontStyle: 'normal',
              fontWeight: '600 !important',
              textAlign: 'center',
            }}
          >
            {t('Sorularınız mı var?')}
            <a type="button" onClick={handleActive} className={classes.contactLink}>
              {t('Bizimle iletişime geçin')}
            </a>
          </Typography>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setRequiredFieldsAlert(false)}
          open={requiredFieldsAlert}
        >
          <Alert severity="error">Lütfen tüm alanları doldurun</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={() => setSuccesfullyEditedSnackbar(false)}
          open={succesfullyEditedSnackbar}
        >
          <Alert severity="success">Form Başarıyla Düzenlendi</Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}

export default MainFormStage;
