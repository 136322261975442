import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import ListKPIGA from './ListKPIGA';

const GA_EDIT_LIST = gql`
  mutation kpiGoogleAnalytics ($viewId: String!, $listId: ID!) {
      kpiGoogleAnalytics(viewId: $viewId, listId: $listId) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(GA_EDIT_LIST, {
    name: 'kpiGoogleAnalytics',
  }),
)(ListKPIGA);
export default JoinWithMutation;