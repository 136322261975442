import {gql} from "@apollo/client";
export const FRAGMENT_MESSAGES = {
  message: gql`
    fragment MessageFields on Message {
      id
      createdAt
      message
      conversation {
        id
      }
      unread {
        id
      }
      sender {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  `
};
