export function getSectorsArray() {
  return [
    'AI / Yapay Zeka',
    'Akıllı Şehirler',
    'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
    'Atık Yönetimi',
    'Bilişim Teknolojileri',
    'Biyoteknoloji',
    'Blokzinciri',
    'Data Analiz & İş zekası',
    'Donanım',
    'e-ticaret',
    'Eğitim & Eğitim Teknolojileri',
    'Eğlence & Müzik',
    'Etkinlik',
    'Ev hizmetleri ve teknolojileri ',
    'Finans & Bankacılık',
    'Finans Teknolojileri',
    'Gayrimenkul',
    'Geri Dönüşüm',
    'Giyilebilir Teknolojiler',
    'Hukuk Teknolojileri',
    'İnsan Kaynakları & İşe Alım',
    'Kriptopara',
    'Kurumsal Hizmetler',
    'Mikro Mobilite',
    'Moda & Güzellik',
    'Medya',
    'Müşteri İlişkileri Yönetimi',
    'Nesnelerin İnterneti',
    'Oyun',
    'Perakende',
    'Regülasyon Teknolojileri',
    'Reklam & Pazarlama',
    'Robotik Teknolojileri',
    'SaaS',
    'Sağlık Hizmetleri & Sağlık Teknolojileri',
    'Seyahat & Turizm',
    'Siber Güvenlik',
    'Sigorta & Sigorta Teknolojileri',
    'Sosyal Girişimcilik',
    'Sosyal Medya',
    'Spor & Fitness',
    'Tarım & Tarım Teknolojileri',
    'Teknoloji',
    'Telekomünikasyon',
    'Temiz Teknoloji',
    'Teslimat & Lojistik',
    'Topluluk Yönetimi',
    'Tüketici Hizmetleri',
    'Ulaşım',
    'Video & Animasyon',
    'Yazılım',
    'Yenilenebilir Enerji',
    'Yiyecek & İçecek',
  ];
}
