import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import linkedinLogo from '../../static/icon/linkedin-investor-profile.svg';
import twitterLogo from '../../static/icon/twitter-investor-icon.svg';
import publicLogo from '../../static/img/public-logo.jpg';
import worldIcon from '../../static/icon/world-icon.svg';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import BlueTag from '../../Components/blueTag';
import moment from 'moment';
import { Link } from 'react-router-dom';

registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  companyGrid: {
    padding: '16px',
    borderRadius: '6px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#fff',
    marginBottom: 16
  },
  whiteGrid: {
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    marginBottom: 32,
  },
  mainContainer: {
    width: 100,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    padding: '0px 15px 0 24px',
    maxHeight: 600,
    [theme.breakpoints.down('sm')]: {
      padding: '16',
    },
  },
  mobilePadding: {
    padding: '200px',
    paddingTop: 32,
    paddingBottom: 32,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      paddingTop: 16,
    },
  },
  search: {
    position: 'relative',
    width: '100%',
  },
  searchBox: {
    width: '100%',
    maxWidth: 350,
    minWidth: 125,
    background: '#ffffff',
    borderRadius: 6,
    marginTop: 5,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#7c7c7c',
  },
  Debounce: {
    width: '100%',
    fontSize: 13,
    height: '50px',
    fontFamily: 'inherit',
    outline: 'none',
    border: 'none',
    background: '#FFFFFF',
    color: '#718096',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    padding: '26px 56px 26px 0',
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    borderRadius: 6,
    '&:focus': {
      border: '1px solid #003CA5',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  uploadGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 80,
    height: 80,
    background: '#E4EEFF',
    marginBottom: 16
  },
  blueGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 116,
    height: 116,
    background: '#F0F5FF',
    borderRadius: '50%',
    marginBottom: 24
  },
  backgroundImg: {
    objectFit: 'cover',
    height: 180,
    borderRadius: 16,
    width: '100%'
  },
  profileImg: {
    objectFit: 'cover',
    height: 130,
    width: 130,
    borderRadius: '50%',
  },
  profileGrid: {
    marginLeft: 30,
    marginTop: -75,
    border: '5px solid #fff',
    borderRadius: '50%',
    height: 'max-content'
  },
  portfoyCompany: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '32px',
      borderLeft: '1px solid rgba(160, 174, 192, 0.15)'
    },
  },
  publicHeader: {
    background: '#fff',
    height: '96px',
    width: '100%',
    position: 'fixed',
    zIndex: 999,
    [theme.breakpoints.up('sm')]: {
      padding: '0 160px',
    },
  },
  descPadding: {
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  }
}));

export default function StartupProfilePublic(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [startup, setStartup] = React.useState();
  const [city, setCity] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [successInvite, setSuccessInvite] = React.useState(false);

  const PUBLIC_STARTUP = gql`
  query publicStartupDetail($slug:String!){
    publicStartupDetail(slug:$slug){
      id
      logo
      slug
      title
      oneliner
      description
      socialLinkedin
      socialFacebook
      socialTwitter
      socialInstagram
      summary
      website
      stage
      sector
      foundationDate
      businessModelArray
      industry
      city{id title}
      country{id title}
    }
  }
  `;

  const { loading: loading, data: data, refetch } = useQuery(PUBLIC_STARTUP, {
    fetchPolicy: 'network-only',
    variables: { slug: props.match.params.slug },
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (data.publicStartupDetail) {
          setStartup(data.publicStartupDetail);
        }else{
          props.history.push(`/login/?next=${window.location.pathname}`)
        }
      }

    }
  },
    [data, loading]);

  const thumb = {
    display: 'inline-flex',
    borderRadius: 16,
    width: '100%',
    height: 180,
    padding: 4,
    boxSizing: 'border-box'
  };

  return (
    <>
      {!props.authToken &&
        <Grid container justify="space-between" alignItems="center" className={classes.publicHeader}>
          <img src={publicLogo} alt="logo" style={{ height: 40 }} />
          <Grid item style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Link to="/sign-up" style={{ padding: 0 }}>
              <Button
                variant="outlinedPrimary"
                style={{ fontWeight: 'normal', padding: '11.5px 32px', marginRight: 16 }}
              >
                {t('Kayıt Ol')}
              </Button>
            </Link>
            <Link to="/login" style={{ padding: 0 }}>
              <Button
                variant="containedPrimary"
                style={{ fontWeight: 'normal', padding: '11.5px 32px' }}
              >
                {t('Giriş Yap')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      }
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccess(false)}
            open={success}
          >
            <Alert severity="success">Başarıyla düzenlendi.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccessInvite(false)}
            open={successInvite}
          >
            <Alert severity="success">Başarıyla davet edildi.</Alert>
          </Snackbar>
          <Grid
            container
            xs={12}
            style={{ marginTop: 145, height: 'fit-content', maxWidth: 1350, padding: 16 }}
          >
            <Grid container className={classes.mobilePadding}>
              {startup &&
                <>
                  <Grid xs={12} className={classes.whiteGrid}>
                    <Grid container>
                      {startup.logo ?
                        <Grid item className={classes.profileGrid} style={startup.backgroundPhoto ? {} : { marginTop: 24 }}>
                          <img src={startup.logo} alt="background" className={classes.profileImg} />
                        </Grid>
                        :
                        <Grid item className={classes.profileGrid} style={startup.backgroundPhoto ? { background: '#003CA5', width: 112, height: 112, display: 'flex', alignItems: 'center', justifyContent: 'center' } : { background: '#003CA5', width: 112, height: 112, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
                          <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 36 }}>
                            {startup.title.charAt(0)}
                          </Typography>
                        </Grid>
                      }
                      <Grid xs={12} md justify="space-between" style={startup.backgroundPhoto ? { display: 'flex', padding: 20 } : { display: 'flex', padding: 20, marginTop: 24 }}>
                        <Grid item>
                          <Grid item style={{ display: 'flex' }}>
                            <Typography variant="h3" style={{ display: 'flex', marginRight: 8 }}>
                              {startup.title}
                            </Typography>
                          </Grid>
                          <Typography variant="body2" style={{ color: '#718096', marginTop: 6 }}>
                            {startup.sector}
                          </Typography>
                          <Typography variant="body2" style={{ color: '#718096', marginTop: 3, maxWidth: 500 }}>
                            {startup.oneliner}
                          </Typography>
                        </Grid>
                        <Grid item style={{ textAlign: 'right' }}>
                          {startup.socialLinkedIn &&
                            <a href={startup.socialLinkedIn} style={{ padding: 0 }} target="_blank">
                              <img src={linkedinLogo} alt="linkedin" />
                            </a>
                          }
                          {startup.socialTwitter &&
                            <a href={startup.socialTwitter} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                              <img src={twitterLogo} alt="twitter" />
                            </a>
                          }
                          {startup.website &&
                            <a href={startup.website} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                              <img src={worldIcon} alt="website" />
                            </a>
                          }
                          <Grid container>
                            <Link style={{ padding: 0 }} to={{
                              pathname: `/sign-up`,
                            }}
                            >
                              <Button
                                variant="containedPrimary"
                                style={{ fontWeight: 'normal', padding: '11.5px 25px', marginTop: 24 }}
                              >
                                {t('Tamamını Gör')}
                              </Button>
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.descPadding}>
                      <Grid container justify="space-between" alignItems="center">
                        {startup.description && startup.description.length > 0 &&
                          <Typography align="left" variant="h5">
                            {t('Detaylı Bilgi')}
                          </Typography>
                        }
                      </Grid>
                      <Typography align="left" variant="body2" style={{ marginTop: 8 }}>
                        {startup.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography align="left" variant="h4" style={{ marginBottom: 24 }}>
                    {t('Temel Bilgiler')}
                  </Typography>
                  <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', display: 'flex', flexWrap: 'wrap' }}>
                    <Grid container xs={12} md={8}>
                      <Grid item xs={12} sm={6} style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16 }}>
                        <Typography
                          variant="h5"
                          align="left"
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('Kuruluş Yeri')}:
                            </Typography>
                        {startup.country && (
                          <Typography
                            variant="h5"
                            align="left"
                          >
                            {get(startup.country, 'title', '')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16 }}>
                        <Typography
                          variant="h5"
                          align="left"
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('İş Modeli')}:
                        </Typography>
                        {startup.businessModelArray && startup.businessModelArray.length > 0 && (
                          <Typography
                            variant="h5"
                            align="left"
                          >
                            {startup.businessModelArray[0]}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16 }}>
                        <Typography
                          variant="h5"
                          align="left"
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('Kuruluş Tarihi')}:
                            </Typography>
                        {startup.foundationDate && (
                          <Typography
                            variant="h5"
                            align="left"
                          >
                            {moment(parseInt(startup.foundationDate)).format('MMMM YYYY')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16 }}>
                        <Typography
                          variant="h5"
                          align="left"
                          style={{ color: '#A0AEC0', marginRight: 10 }}
                        >
                          {t('Aşama')}:
                            </Typography>
                        {startup.stage && (
                          <Typography
                            variant="h5"
                            align="left"
                          >
                            {startup.stage}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <Grid item xs={12} style={{ display: 'flex' }}>
                        <Typography
                          variant="h5"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('İlişkili olduğu sektörler')}:
                        </Typography>
                      </Grid>
                      <Grid container xs={12} sm spacing={1} style={{ marginTop: 10 }}>
                        {startup.industry &&
                          startup.industry.map((ind, index) => (
                            <Grid item>
                              <BlueTag title={ind} />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" style={{ marginTop:32 }}>                  
                    <Typography variant="body2" align="center" style={{ color:'#718096' }}>
                      Startup Borsa, bir <strong>Gedik Yatırım</strong> kuruluşudur.
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}