
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Pagination from "@material-ui/lab/Pagination";
import { DebounceInput } from 'react-debounce-input';
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newPeople from '../../static/icon/new-people.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Switch from '@material-ui/core/Switch';
import 'moment/locale/tr'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
    marginTop: 35
  },
  Debounce: {
    width: '100%',
    maxWidth: '180px',
    fontSize: 16,
    height: '35px',
    color: '#8898aa',
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid #c8c8ca',
    background: 'inherit',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  addButton: {
    fontWeight: 'normal',
    padding: '6.5px 11px',
    fontSize: 13,
    color: '#ffba00',
    marginLeft: 10,
    borderColor: '#ffba00',
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#ffba00",
      color: '#fff',
      borderColor: '#ffba00',
    }
  },
  multiSelect2: {
    borderRadius: 4,
    '& div': {
      background: 'none!important',
      paddingRight: '5px!important',
      paddingLeft: '12px'
    }
  },
  multiSelect: {
    maxHeight: 40,
    minWidth: 116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor: '#fff',
    '& div': {
      background: 'none!important',
    }
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop: 30,
    '& textarea': {
      padding: 16,
      paddingRight: 90,
      fontSize: 13,
      lineHeight: 1
    },
  },
  sendButton: {
    minWidth: 50,
    height: 50,
    borderRadius: '50%',
    background: '#003CA5',
    marginTop: '-80px',
    marginRight: 30,
    cursor: 'pointer',
    "&:hover": {
      background: '#003CA5',
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color: '#8898aa',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color: '#003CA5',
    },
  },
  sticker: {
    marginRight: 10,
    color: '#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  filter: {
    height: 40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: '20px',
    },
  },
  myPaper: {
    padding: 25,
    width: '650px'
  },
  myLabel: {
    fontSize: 14
  },
  formControl: {
    marginTop: 10
  },
  myCheckbox: {
    padding: '5px 9px'
  },
  whiteCard: {
    background: '#ffffff',
    borderRadius: '4px',
    padding: 25,
    marginBottom: 50,
    marginTop: 50,
  },
  RightBorder: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid rgb(233, 233, 240)'
    },
  },
  paddingL: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '40px!important'
    },
  }
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize: 11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

function ExpandingRow(props) {
  const { row, refetch } = props;
  const classes = useStyles();
  const [accessSent, setAccessSent] = React.useState(false);
  const [accessText, setAccessText] = React.useState('');
  const [accessError, setAccessError] = React.useState(false);

  function getUserFullName(first, last) {
    let f = first ? first : ''
    let l = last ? ' ' + last : ''
    return f + l
  }
  function getLocation(item) {
    let country = item.country ? item.country.title : ''
    let city = item.city ? item.city.title + '' : ''
    return city + country
  }

  const handleAccessChecked = (event) => {
    row.accessity = event.target.checked ? 'Public' : 'Private'
    props
      .adminHandleInvestorProfileAccessity({ variables:{investorId: row.id, status:row.accessity } })
      .then((data) => {
        if (data.data.adminHandleInvestorProfileAccessity) {
            refetch();
            setAccessSent(true);
            if (row.accessity !== 'Private'){
              setAccessText('Haberdar olma talebiniz başarıyla geri alınmıştır.');
            }
            else{
              setAccessText('Haberdar olma talebiniz başarıyla alınmıştır.');
            }
        } else {
          setAccessError(true);
        }
      })
      .then(() => {})
      .catch((error) => {
        setAccessError(true);
      });
  };

  return (
    <>
      <StyledTableRow key={row.name}>
        <StyledTableCell style={{ padding: '12px 0px 12px 30px' }} component="th" scope="row">
          {row.profilePhoto ? (
            <img
              src={row.profilePhoto}
              alt="logo"
              style={{ width: 35, height: 35, borderRadius: '50%', border: 'solid 1px #eaedf3' }}
            />
          ) : (
            <Grid
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
              }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                {row.firstName && row.firstName.charAt(0)}
              </Typography>
            </Grid>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Link to={`yatirimci/${row.slug}`} style={{ padding: 0 }}>
            <Typography variant="body2" align="left" style={{ fontWeight: 500 }}>
              {getUserFullName(row.firstName, row.lastName)}
            </Typography>
          </Link>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{row.website ? row.website : '-'}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{row.email ? row.email : '-'}</Typography>
        </StyledTableCell>
        <StyledTableCell style={{ maxWidth: 150 }} align="left">
          <Typography variant="body2">{getLocation(row)}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ paddingRight: 15 }}>
          <Grid container>
            <AntSwitch checked={row.accessity !== 'Private'} onChange={handleAccessChecked} />
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
export default function AdminInvestors(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [sort, setSort] = React.useState([]);
  const [investors, setInvestors] = React.useState();
  const [order, setOrder] = React.useState("id__DESC");
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const ALL_INVESTORS = gql`
    query adminAllInvestorsPaginate($order:String, $search: String, $page: Int, $pageSize: Int) {
      adminAllInvestorsPaginate(orderBy:[$order], search:$search, pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            data{
                id
                slug
                firstName
                lastName
                profilePhoto
                website
                email
                city{id, title}
                accessity
            }
        }
    }
    `;

  const { loading, data, refetch } = useQuery(ALL_INVESTORS, {
    fetchPolicy: 'network-only',
    variables: { order: order, search: search, page: page, pageSize: pageSize },
  });

  const options = [
    { name: 'A-Z', value: 'user__firstName__ASC' },
    { name: 'Z-A', value: 'user__firstName__DESC' },
    { name: 'Latest', value: 'id__DESC' },
    { name: 'Oldest', value: 'id__ASC' },
  ];
  const pageSizeOptions = [3, 5, 10, 20, 50]
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setInvestors(data.adminAllInvestorsPaginate.data);
        setPageCount(data.adminAllInvestorsPaginate.pageCount)
      }
    }
  },
    [data, loading])

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };
  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid
            className={'main-container'}
            xs={12}
            style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
          >
            <Grid item style={{ width: '100%' }}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item style={{ display: 'flex' }}>
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <img src={newPeople} style={{ width: 18, marginTop: 5 }} alt="people" />
                    <Typography variant="h4">Yatırımcılar</Typography>
                  </Breadcrumbs>
                </Grid>
                <Hidden smDown>
                  <Grid item className={classes.filterArea}>
                    <DebounceInput
                      class="inputTypeSearch"
                      name="search"
                      value={search}
                      placeholder="Ara..."
                      className={classes.Debounce}
                      minLength={2}
                      debounceTimeout={800}
                      onChange={handleSearch}
                    />
                    <FormControl placeholder="Please Select" style={{ width: '100%', maxWidth: '116px' }}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-mutiple-checkbox-label2"
                        id="demo-mutiple-checkbox2"
                        displayEmpty
                        value={sort}
                        className={classes.multiSelect}
                        onChange={handleSort}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props}
                            style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                          />
                        )}
                        input={<Input />}
                      /*   MenuProps={MenuProps} */
                      >
                        <MenuItem value="" disabled>
                          <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                            Sırala
                          </Typography>
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {option.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Hidden>
              </Grid>
              {investors && (
                <Grid container xs={12} className={classes.whiteCard}>
                  <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            <Typography variant="h6" color="primary">
                              {' '}
                                #{' '}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" color="primary">
                              {' '}
                                İsim Soyisim{' '}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" color="primary">
                              {' '}
                                Website{' '}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" color="primary">
                              {' '}
                                E-posta{' '}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" color="primary">
                              {' '}
                                Lokasyon{' '}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="h6" color="primary">
                              {' '}
                                Statü{' '}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                              }}
                              labelId="demo-mutiple"
                              id="demo-mutiple"
                              displayEmpty
                              value={pageSize}
                              className={classes.multiSelect2}
                              style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                              IconComponent={(props) => (
                                <ExpandMoreIcon
                                  {...props}
                                  style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                />
                              )}
                              input={<Input />}
                            /*   MenuProps={MenuProps} */
                            >
                              {pageSizeOptions.map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  onClick={() => (setPageSize(option), setPage(1))}
                                >
                                  <ListItemText className={classes.ListItemText2} primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {investors.map((row) => (
                          <ExpandingRow status={props.status} refetch={refetch} row={row} adminHandleInvestorProfileAccessity={props.adminHandleInvestorProfileAccessity} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {investors.length > 0 ? (
                    <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                      <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                      />
                    </Grid>
                  ) : (
                    <Grid container justify="center">
                      <Typography style={{ margin: '25px 0' }} variant="h5">
                        Kullanıcı bulunamadı...
                        </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}