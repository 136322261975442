import './myOffersTable.css';

import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { getFormattedDateFromUnix, numberWithPeriods, resolveCurrencySymbol } from '../../helpers';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ColorfulTag from '../../Components/ColorfulTag';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Image from '../../static/img/404.png';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import clsx from 'clsx';
import filterIcon from '../../static/icon/filterIcon.svg';
import get from 'lodash/get';
import { getSectorsArray } from './getSectorsArray';
import gql from 'graphql-tag';
import newHome from '../../static/icon/new-home-blue.svg';
import newSortingIcon from '../../static/icon/newSortingIcon.svg';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },

  CheckText: {
    wordBreak: 'break-all',
    marginBottom: 10,
    '& span': {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  filterPadding: {
    paddingLeft: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      marginTop: 10,
    },
  },
  filter: {
    height: 40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  myPaper: {
    padding: 25,
    width: '650px',
  },
  myPaper2: {
    padding: 25,
  },
  myLabel: {
    fontSize: 14,
  },
  formControl: {
    marginTop: 10,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
  whiteCard: {
    background: '#ffffff',
    borderRadius: '4px',
    padding: 25,
    marginTop: 100,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 12px',
    backgroundColor: '#F7F7FC',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize: 10,
    padding: '16px 0px 16px 24px',
  },
  body: {
    fontSize: 14,
    padding: '16px 0px 16px 24px',
    height: 80,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 16,
    borderCollapse: 'separate',
  },
}))(TableRow);

function ExpandingRow(props) {
  const { request } = props;

  function resolveCurrentStatusTag() {
    switch (request.status) {
      case 'Pending':
        return <ColorfulTag title="Cevap bekleniyor." backgroundColor="#FFF3E5" textColor="#FF9B28" />;
      case 'Rejected':
        return <ColorfulTag title="Talep reddedildi." backgroundColor="#FEEFEF" textColor="#F16063" />;
      case 'Approved':
        return <ColorfulTag title="Talep kabul edildi." backgroundColor="#DEFFEE" textColor="#66CB9F" />;
      default:
        return <ColorfulTag title="Cevap bekleniyor." backgroundColor="#FFF3E5" textColor="#FF9B28" />;
    }
  }

  return (
    <>
      <StyledTableRow className="offersTableRow" key={request.startup.title}>
        <StyledTableCell scope="row">
          <Grid container style={{ alignItems: 'center' }}>
            {request.startup.logo ? (
              <img
                src={request.startup.logo}
                alt="logo"
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  marginRight: 20,
                  objectFit: 'cover',
                  border: 'solid 1px #eaedf3',
                }}
              />
            ) : (
              <Grid
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: '4px',
                  display: 'flex',
                  marginRight: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography variant="h5" style={{ color: '#fff' }}>
                  {request.startup.title && request.startup.title.charAt(0)}
                </Typography>
              </Grid>
            )}
            <Link to={`/girisim/${request.startup.slug}`}>
              <Typography variant="h6" style={{ fontSize: 14, fontWeight: 500, padding: 0 }}>
                {request.startup.title}
              </Typography>
            </Link>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{request.startup.sector}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{getFormattedDateFromUnix(request.createdAt)}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {(request.investmentAmount &&
              resolveCurrencySymbol(request.currentSprint?.sprintCurrencySymbol) +
                numberWithPeriods(request.investmentAmount)) ||
              ''}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">{resolveCurrentStatusTag()}</StyledTableCell>
      </StyledTableRow>
    </>
  );
}

const MY_ALL_INVESTMENT_REQUESTS = gql`
  query myAllInvestmentRequestsPaginated(
    $order: String
    $page: Int
    $pageSize: Int
    $status: [String]
    $sector: [String]
  ) {
    myAllInvestmentRequestsPaginated(
      orderBy: [$order]
      pagination: { page: $page, pageSize: $pageSize }
      filter: { status: $status, sector: $sector }
    ) {
      data {
        id
        status
        showToStartup
        createdAt
        investmentAmount
        startup {
          id
          slug
          title
          logo
          sector
        }
        currentSprint {
          id
          sprintCurrencySymbol
        }
      }
    }
  }
`;

export default function MyInvestmentOffer(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderFollowed, setOrderFollowed] = React.useState('');
  const [sortFollowed, setSortFollowed] = React.useState([]);
  const [filter, setFilter] = React.useState('Statüsüne Göre');
  const [status, setStatus] = React.useState([]);
  const [sector, setSector] = React.useState([]);
  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const { data, refetch } = useQuery(MY_ALL_INVESTMENT_REQUESTS, {
    variables: { order: orderFollowed, status: status, sector: sector },
  });
  const allMyRequests = data?.myAllInvestmentRequestsPaginated?.data;

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const openPopSort = Boolean(anchorElSort);
  const idSort = openPopSort ? 'simple-popover' : undefined;

  const optionsFollowed = [
    { name: 'A-Z', value: 'request__id__DESC' },
    { name: 'Z-A', value: 'request__id__ASC' },
  ];

  const filterOptions = ['Statüsüne Göre', 'Sektörüne Göre'];
  const sectorOptions = getSectorsArray();
  const statusOptions = [
    { title: 'Onay bekleyen', value: 'Pending' },
    { title: 'Onaylanan', value: 'Approved' },
    { title: 'Reddedilen', value: 'Rejected' },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = (event) => {
    setFilter(event.target.value);
  };

  const handleStatus = (event) => {
    if (status.includes(event.target.value)) {
      let a = status.filter((e) => e !== event.target.value);
      setStatus(a);
      refetch();
    } else {
      setStatus([...status, event.target.value]);
    }
  };

  const handleSector = (event) => {
    if (sector.includes(event.target.value)) {
      let a = sector.filter((e) => e !== event.target.value);
      setSector(a);
      refetch();
    } else {
      setSector([...sector, event.target.value]);
    }
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const handleSortFollowed = (event) => {
    setSortFollowed(event.target.value);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
            {(
              <Grid item style={{ width: '100%', padding: '0 10px' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <img src={newHome} style={{ width: 20, marginTop: 5 }} alt="eye" />
                    <Typography variant="h4">Taleplerim</Typography>
                  </Breadcrumbs>
                  {value === 0 && (
                    <Grid item className={classes.filterArea}>
                      <>
                        <Grid className={classes.filter} onClick={handleClick} item>
                          <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                          <Hidden smDown>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {t('Filtrele')}
                            </Typography>
                            <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                          </Hidden>
                        </Grid>
                        <Popover
                          id={id}
                          open={openPop}
                          anchorEl={anchorEl}
                          style={{ marginTop: 11 }}
                          onClose={handleClose}
                          classes={{
                            paper: classes.myPaper,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Grid container xs={12}>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{
                                borderRight: '1px solid rgb(0 0 0 / 5%)',
                                maxHeight: 250,
                                overflowY: 'auto',
                              }}
                            >
                              <Typography variant="h5">{t('Filtreler')}</Typography>
                              <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup onChange={handleFilter} value={filter}>
                                  {filterOptions.map((value, i) => {
                                    // Return the element. Also pass key
                                    return (
                                      <FormControlLabel
                                        classes={{ label: classes.myLabel }}
                                        control={<Radio color="primary" value={value} />}
                                        label={value}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              className={classes.filterPadding}
                              style={{
                                borderRight: '1px solid rgb(0 0 0 / 5%)',
                                maxHeight: 250,
                                overflowY: 'auto',
                              }}
                            >
                              <Typography variant="h5">{t('Filtre Seç')}</Typography>
                              {filter === 'Statüsüne Göre' && (
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <FormGroup onChange={handleStatus} value={status}>
                                    {statusOptions.map((value, i) => {
                                      return (
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleStatus}
                                              checked={status.includes(value.value)}
                                              name="filter4-1"
                                              value={value.value}
                                            />
                                          }
                                          label={value.title}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                </FormControl>
                              )}
                              {filter === 'Sektörüne Göre' && (
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <FormGroup onChange={handleSector} value={sector}>
                                    {sectorOptions.map((value, i) => {
                                      return (
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleSector}
                                              checked={sector.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={value}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              className={classes.filterPadding}
                              style={{ maxHeight: 250, overflowY: 'auto' }}
                            >
                              <Typography style={{ marginBottom: 18 }} variant="h5">
                                {t('Filtre Seçimlerim')}
                              </Typography>
                              {status && (
                                <>
                                  {status.map((value, i) => {
                                    return (
                                      <Typography style={{ fontWeight: 'normal' }} variant="h6">
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleStatus}
                                              checked={status.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={
                                            (value === 'Pending' && 'Onay Bekleyen') ||
                                            (value === 'Approved' && 'Onaylanan') ||
                                            (value === 'Rejected' && 'Reddedilen')
                                          }
                                        />
                                      </Typography>
                                    );
                                  })}
                                </>
                              )}
                              {sector && (
                                <>
                                  {sector.map((value, i) => {
                                    return (
                                      <Typography style={{ fontWeight: 'normal' }} variant="h6">
                                        <FormControlLabel
                                          className={classes.CheckText}
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleSector}
                                              checked={sector.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={value}
                                        />
                                      </Typography>
                                    );
                                  })}
                                </>
                              )}
                            </Grid>
                            <Grid container justify="flex-end"></Grid>
                          </Grid>
                        </Popover>
                        <Grid className={classes.filter} onClick={handleClickSort} item style={{ marginLeft: 15 }}>
                          <img src={newSortingIcon} style={{ width: 15 }} alt="filter" />
                          <Hidden smDown>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {t('Sırala')}
                            </Typography>
                            <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 10, fontSize: 20 }} />
                          </Hidden>
                        </Grid>
                        <Popover
                          id={idSort}
                          open={openPopSort}
                          anchorEl={anchorElSort}
                          style={{ marginTop: 11 }}
                          onClose={handleCloseSort}
                          classes={{
                            paper: classes.myPaper2,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              maxHeight: 250,
                              overflowY: 'auto',
                            }}
                          >
                            <Typography variant="h5">{t('Sırala')}</Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <RadioGroup onChange={handleSortFollowed} value={sortFollowed}>
                                {optionsFollowed.map((option, i) => {
                                  // Return the element. Also pass key
                                  return (
                                    <FormControlLabel
                                      classes={{ label: classes.myLabel }}
                                      control={
                                        <Radio
                                          color="primary"
                                          value={option.value}
                                          onClick={() => setOrderFollowed(option.value)}
                                        />
                                      }
                                      label={option.name}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Popover>
                      </>
                    </Grid>
                  )}
                </Grid>
                <Grid container xs={12}>
                  <TabPanel style={{ width: '100%' }} value={value} index={0}>
                    {allMyRequests && allMyRequests.length > 0 ? (
                      <Grid container xs={12} style={{ marginTop: 82 }}>
                        <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>{t('GİRİŞİMİN ADI')}</StyledTableCell>
                                <StyledTableCell align="left">{t('SEKTÖR')}</StyledTableCell>
                                <StyledTableCell align="left">{t('TALEP TARİHİ')}</StyledTableCell>
                                <StyledTableCell align="left">{t('TALEP TUTARI')}</StyledTableCell>
                                <StyledTableCell align="left">{t('DURUM')}</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {allMyRequests.map((request) => (
                                <ExpandingRow request={request} />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    ) : (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <Grid container justify="center">
                          <Typography style={{ margin: '25px 0' }} variant="h5">
                            {t('Yaptığınız Yatırım Teklifi Bulunamadı...')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </TabPanel>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </main>
    </>
  );
}