import {useApolloClient, useLazyQuery, useQuery} from "@apollo/react-hooks";
import {Button} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Snackbar from "@material-ui/core/Snackbar";
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HomeOutlined from "@material-ui/icons/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PublicIcon from "@material-ui/icons/Public";
import SearchIcon from "@material-ui/icons/Search";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ViewModule from "@material-ui/icons/ViewModule";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import gql from "graphql-tag";
import get from "lodash/get";
import React, {useRef} from "react";
import {DebounceInput} from "react-debounce-input";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {useLanguage} from "../../context/language";
import {
  GET_CREATE_INVESTMENT_SPRINT_DETAIL,
  HEADER_LEFT_DRAWER,
  MY_PROFILE,
  UNREAD_MESSAGE_NUMBER
} from "../../queries";
import blueProfileMenuIcon from "../../static/icon/blue-profile-menu-icon.svg";
import bolt from "../../static/icon/bolt.svg";
import Invest from "../../static/icon/Invest.svg";
import InvestBlue from "../../static/icon/InvestBlue.svg";
import investorsBlue from "../../static/icon/investors-blue.svg";
import investors from "../../static/icon/investors.svg";
import newBagBlue from "../../static/icon/new-bag-blue.svg";
import newBag from "../../static/icon/new-bag.svg";
import newEyeBlue from "../../static/icon/new-eye-blue.svg";
import newEye from "../../static/icon/new-eye.svg";
import newHomeBlue from "../../static/icon/new-home-blue.svg";
import newHome from "../../static/icon/new-home.svg";
import newPeopleBlue from "../../static/icon/new-people-blue.svg";
import newPeople from "../../static/icon/new-people.svg";
import newPlus from "../../static/icon/new-plus.svg";
import newSettingsBlue from "../../static/icon/new-settings-blue.svg";
import newSettings from "../../static/icon/new-settings.svg";
import people from "../../static/icon/people.svg";
import profileMenuIcon from "../../static/icon/profile-menu-icon.svg";
import savedKpi from "../../static/icon/saved-kpi.svg";
import savingKpi from "../../static/icon/saving-kpi.svg";
import Sblogo from "../../static/img/logo2.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  grow: {
    flexGrow: 1
  },
  searchBox: {
    position: "absolute",
    background: "#ffffff",
    borderRadius: 2,
    marginTop: 5,
    boxShadow: "1px 1px 2px 1px rgba(201,201,201,1)",
    maxHeight: 130,
    overflowY: "auto",
    minWidth: 540,
    [theme.breakpoints.down("xs")]: {
      minWidth: 258
    }
  },
  search: {
    position: "relative",
    width: "100%",
    maxWidth: "540px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    zIndex: 99999,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#7c7c7c"
  },
  inputInput: {
    padding: "26px 56px 26px 0",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    height: 10,
    fontSize: 16,
    fontFamily: "inherit",
    border: "1px solid #E3EBF2",
    outline: "none",
    borderRadius: 6,
    width: "100%",
    backgroundColor: "#fff",
    color: "#777777"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0
    }
  },
  iconGrid: {
    borderLeft: "1px solid #eef1f5",
    padding: "10px 20px",
    paddingRight: 5,
    minHeight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#ffffff"
  },
  ListItem: {
    padding: "14px 0 14px 23px",
    margin: "2px 0"
  },
  ListItemInner: {
    marginLeft: 28,
    padding: 18,
    paddingLeft: 28,
    borderLeft: "3px solid rgba(160, 174, 192, 0.5)",
    transition: "0.5s",
    "&:hover": {
      backgroundColor: "#ffffff"
    }
  },
  List: {
    padding: "0 8px",
    height: "inherit"
  },
  drawerHeader: {
    background: "#fff",
    minHeight: 98,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  bottomText: {
    color: "#ffffff",
    fontSize: 13,
    padding: 16,
    paddingLeft: 33
  },
  Top: {
    paddingLeft: 30,
    paddingTop: 20,
    fontSize: 13,
    color: "#ffffff"
  },
  popover: {
    pointerEvents: "none"
  },
  popoverContent: {
    padding: "20px 0",
    minWidth: "100px",
    textAlign: "center",
    marginTop: 5,
    pointerEvents: "auto",
    boxShadow: "0px 2px 8px 0px rgba(201,201,201,1)"
  },
  blueField: {
    width: "100%",
    minHeight: 70,
    background: "#F0F6FF",
    padding: "21px 28px"
  },
  blueFieldText: {
    color: "#003CA5",
    marginLeft: 15,
    fontSize: 14
  },
  mailIcon: {
    color: "rgb(255 186 0)",
    display: "flex",
    marginRight: 15,
    marginLeft: 16
  },
  startupLogo: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)"
  },
  startupLogo2: {
    width: 35,
    height: 35,
    borderRadius: "4px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)"
  },
  rightWhite: {
    background: "#ffffff",
    borderRadius: "4px",
    padding: 20,
    height: "fit-content"
  },
  companyPhoto: {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #eaedf3",
    marginRight: 15
  },
  avatarGrid: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    border: "5px solid #FFF",
    "&:hover": {
      border: "5px solid #EDF2F7"
    }
  },
  avatarGridSelected: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    border: "5px solid #379FFF"
  },
  inboxButton: {
    height: "46px",
    width: "46px"
  },
  newMessageCircle: {
    height: 8,
    width: 8,
    borderRadius: "50%",
    backgroundColor: "#0054E8",
    position: "absolute",
    right: "10px",
    top: "12px",
    zIndex: 1
  }
}));

export default function PersistentDrawerLeft(props) {
  const {t, i18n} = useTranslation();
  const {showSave, setShowSave} = props;
  const classes = useStyles();
  const theme = useTheme();
  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const [openedPopover, setOpenedPopover] = React.useState(false);
  const popoverAnchor = useRef(null);
  const [user, setUser] = React.useState({});
  const [role, setRole] = React.useState();
  const [adminMenu, setAdminMenu] = React.useState(true);
  const [again, setAgain] = React.useState(false);
  const [notify, setNotify] = React.useState();
  const [notifyId, setNotifyId] = React.useState();
  const {globalLanguage, setGlobalLanguage} = useLanguage();
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [profileSelected, setProfileSelected] = React.useState(false);
  const [pipelineSelected, setPipelineSelected] = React.useState(false);
  const [shareSelected, setShareSelected] = React.useState(false);

  const {data: createInvSprintData} = useQuery(GET_CREATE_INVESTMENT_SPRINT_DETAIL);
  const hasASprint = createInvSprintData?.getCreateInvestmentSprintDetail?.status === "Active";

  const {
    data: unreadMessageNumberData,
    loading: unreadMessageNumberLoading,
    error: unreadMessageNumberError
  } = useQuery(UNREAD_MESSAGE_NUMBER);

  const handlePopoverOpen = ({currentTarget}) => {
    setOpenedPopover(true);
  };

  const handleDrawerOpen = () => {
    props.setOpen(true);
  };

  const HandleSearch = event => {
    setSearch(event.target.value);
    getData();
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClickSearch = () => {
    setOpen(true);
  };

  const handleAgain = () => {
    props.resendVerifyMail().then(data => {
      if (data.data.resendVerifyMail) {
        setAgain(true);
      }
    });
  };

  const openPop = Boolean(anchorEl);

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const handleRole = variables => {
    props.changeRole({variables: {role: variables}}).then(data => {
      if (data.data.changeRole) {
        setRole(variables);
      }
    });
  };

  const SEARCH_STARTUPS = gql`
    query topStartupSearch($search: String) {
      topStartupSearch(search: $search) {
        id
        title
        oneliner
        logo
        slug
      }
    }
  `;

  const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN);
    // her logoutta sayfa yenileniyor
    client.clearStore().then(() => window.location.assign("/login"));
  };

  const {loading, data, refetch} = useQuery(MY_PROFILE, {
    fetchPolicy: "network-only",
    variables: {}
  });

  const [getData, {loading: loadingSearch, data: dataSearch}] = useLazyQuery(SEARCH_STARTUPS, {
    fetchPolicy: "network-only",
    variables: {search: search}
  });

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setUser(data.myProfile);
        setRole(data.myProfile.getRole);
      }
    }
  }, [data, loading]);

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14
    }
  }))(Tooltip);

  function changeLanguage() {
    let lng = globalLanguage === "tr" ? "en" : "tr";
    setGlobalLanguage(lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
        })}
      >
        <Toolbar>
          <Hidden smDown>
            <IconButton
              color="#172b4d"
              aria-label="open drawer"
              onClick={() =>
                client.writeQuery({
                  query: HEADER_LEFT_DRAWER,
                  data: {
                    HeaderLeftDrawer: true
                  }
                })
              }
              edge="start"
              className={clsx(
                classes.menuButton,
                get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true) && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="#172b4d"
              aria-label="open drawer"
              onClick={() =>
                client.writeQuery({
                  query: HEADER_LEFT_DRAWER,
                  data: {
                    HeaderLeftDrawerMobile: true
                  }
                })
              }
              edge="start"
              className={clsx(
                classes.menuButton,
                get(props, "HeaderLeftDrawer.HeaderLeftDrawerMobile", true) && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          {open ? (
            <div
              class="autocomplete-backdrop"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,.25)",
                visibility: "visible",
                opacity: "1",
                zIndex: "0",
                transition: "opacity .5s,visibility .5s"
              }}
            ></div>
          ) : (
            <div
              class="autocomplete-backdrop"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,.25)",
                visibility: "hidden",
                opacity: "0",
                zIndex: "0",
                transition: "opacity .5s,visibility .5s"
              }}
            ></div>
          )}
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grid item style={{width: "100%", maxWidth: "540px"}}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <DebounceInput
                  class="inputTypeSearch"
                  name="search"
                  value={search}
                  placeholder={t("Find the best for you…")}
                  className={classes.inputInput}
                  minLength={2}
                  debounceTimeout={200}
                  onChange={HandleSearch}
                  autoComplete="off"
                  onClick={handleClickSearch}
                />
              </div>
              {open && search && dataSearch && (
                <Grid className={classes.searchBox} xs={3}>
                  <List component="nav" aria-label="secondary mailbox folders">
                    {dataSearch.topStartupSearch.map(searchResult => (
                      <>
                        {searchResult.status !== null && (
                          <Grid>
                            <Link
                              onClick={() => setSearch("")}
                              to={`/girisim/${searchResult.slug ? searchResult.slug : searchResult.id}`}
                              style={{padding: 0}}
                            >
                              <ListItem onClick={handleClickAway} button style={{minHeight: 50}}>
                                <Grid container alignItems="center">
                                  {searchResult.logo ? (
                                    <img src={searchResult.logo} alt="rocket" className={classes.startupLogo2} />
                                  ) : (
                                    <Grid
                                      style={{
                                        width: 36,
                                        height: 36,
                                        borderRadius: "4px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        background: "#003CA5"
                                      }}
                                    >
                                      <Typography variant="h3" style={{color: "#fff"}}>
                                        {searchResult.title && searchResult.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{marginLeft: 10}}>
                                    <Typography variant="body1" color="primary" style={{fontSize: 15, fontWeight: 500}}>
                                      {searchResult.title}
                                    </Typography>
                                    {searchResult.oneliner && (
                                      <Typography
                                        variant="body1"
                                        style={{fontSize: 13, marginTop: -8, color: "#777777"}}
                                      >
                                        {truncateString(searchResult.oneliner, 50)}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </ListItem>
                            </Link>
                          </Grid>
                        )}
                      </>
                    ))}
                    {dataSearch.topStartupSearch.length === 0 && (
                      <ListItem button>
                        <Typography variant="body1">{t("No Results Found…")}</Typography>
                      </ListItem>
                    )}
                  </List>
                </Grid>
              )}
            </Grid>
          </ClickAwayListener>
          <div className={classes.grow}></div>
          {Object.keys(user).length > 0 && (
            <>
              <Grid className={classes.mailIcon} alignItems="center">
                <Hidden xsDown>
                  {!user.startup && role === "Investor" && (
                    <Link style={{padding: 0}} to={`/girisim-yarat`}>
                      <Button variant="containedPrimary" style={{marginRight: 16, padding: "10px 21px"}}>
                        <img src={newPlus} alt="plus" style={{marginRight: 10, width: 12}} />
                        {t("Şirket Ekle")}
                      </Button>
                    </Link>
                  )}
                </Hidden>
                {window.location.href.includes("veri-duzenle") ||
                  (window.location.href.includes("cap-table") && (
                    <Hidden smDown>
                      {showSave === "kayit" && (
                        <Typography style={{marginRight: 16, color: "#000", display: "flex"}} variant="body2">
                          <img src={savingKpi} alt="saving-kpi" style={{marginRight: 10}} />
                          Kayit ediliyor
                        </Typography>
                      )}
                      {showSave === "edildi" && (
                        <Typography style={{marginRight: 16, color: "#66CB9F", display: "flex"}} variant="body2">
                          <img src={savedKpi} alt="saved-kpi" style={{marginRight: 10}} />
                          Kayit edildi
                        </Typography>
                      )}
                    </Hidden>
                  ))}
                {!user.isEmailVerified && (
                  <>
                    <ErrorOutlineOutlinedIcon />
                    <Hidden smDown>
                      <Typography style={{marginLeft: 5}} variant="body2">
                        Lütfen Gelen E-postayı Onaylayınız.
                      </Typography>
                      <LightTooltip arrow title={t("Tekrar mail almak için tıklayınız.")}>
                        <AutorenewIcon onClick={() => handleAgain()} style={{marginLeft: 5, cursor: "pointer"}} />
                      </LightTooltip>
                    </Hidden>
                  </>
                )}
                <Snackbar
                  autoHideDuration={6000}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}
                  onClose={() => setAgain(false)}
                  open={again}
                >
                  <Alert severity="success">{t("Mail talebiniz başarıyla iletildi.")}</Alert>
                </Snackbar>
              </Grid>
              {/*
              <Hidden mdDown>
                <Grid item className={classes.iconGrid}>
                  <Grid alignItems="center" style={{ color: '#172b4d', display: 'flex' }}>
                    {window.location.pathname === '/bildirimler' ? (
                      <img src={newBell} style={{ width: 16 }} alt="settings" />
                    ) : (
                      <img src={newBell} style={{ width: 16 }} alt="settings" />
                    )}
                  </Grid>
                  <Popover
                    id={id}
                    open={openPop}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    classes={{
                      paper: classes.myPaper,
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {notify && (
                      <Grid container xs={12}>
                        <Grid className={classes.rightWhite} xs={12}>
                          <Grid container alignItems="center">
                            <Typography variant="h5" style={{ marginRight: 10 }}>
                              Bildirimler
                            </Typography>
                             <Typography variant="body2">({4})</Typography> 
                          </Grid>
                          <Grid xs={12}>
                            <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '20px 0' }} />
                          </Grid>
                          <Typography variant="body1" style={{ wordBreak: 'break-all', fontSize: 16 }} align="left">
                            Henüz hiç bildiriminiz bulunmuyor...
                          </Typography>
                           {notify.map((not,index) => (
                        <>
                          {index < 3 &&
                            <>
                              <Grid container alignItems="center">
                                <img src={Avatar} className={classes.companyPhoto} alt="logo" style={{objectFit:'cover'}}/>
                                  <Grid item xs> 
                                    <Typography variant="h5" style={{wordBreak:'break-all',fontSize:16}} align="left">{not.title}</Typography>
                                    <Typography variant="body2" align="left">{not.message}</Typography>
                                  </Grid>
                              </Grid>
                              <Grid xs={12}>
                                <hr size="1" style={{border: '0.2px solid #f5f5f5', margin:'20px 0'}}/>
                              </Grid> 
                            </>
                            }
                        </>
                      ))}
                      <Grid container justify="center" alignItems="center">
                        <Link style={{padding:0,display:'flex',alignItems:'center'}} to='/bildirimler'>
                          <Typography variant="h6" style={{color:'#0055ff'}}>Tümünü Gör</Typography>
                          <ArrowRightAltIcon style={{color:'#0055ff'}} />
                        </Link> 
                      </Grid> 
                        </Grid>
                      </Grid>
                    )}
                  </Popover>
                </Grid>
              </Hidden>
              */}
              <Hidden xsUp>
                <Grid container xs="1" justify="space-around" alignItems="center" className={classes.iconGrid}>
                  <Button
                    variant="containedPrimary"
                    onClick={() => changeLanguage()}
                    style={{fontWeight: "normal", padding: "7.5px 20px", fontSize: 15, maxHeight: 30}}
                  >
                    {globalLanguage}
                  </Button>
                </Grid>
              </Hidden>
            </>
          )}
          <Link to="/mesajlar" style={{padding: 0, position: "relative"}}>
            {Boolean(unreadMessageNumberData?.unreadMessageNumber) && <div className={classes.newMessageCircle}></div>}

            <IconButton color="primary" className={classes.inboxButton}>
              <MailOutlinedIcon style={{color: "rgb(160, 174, 192)", fontSize: 22}} />
            </IconButton>
          </Link>
          {Object.keys(user).length > 0 ? (
            <Link to="/ayarlar" style={{padding: 0}}>
              <Grid
                item
                className={window.location.pathname === "/ayarlar" ? classes.avatarGridSelected : classes.avatarGrid}
              >
                {user.profilePhoto ? (
                  <>
                    <img
                      src={user.profilePhoto}
                      alt="avatar"
                      style={{maxWidth: 36, height: 36, borderRadius: "50%", objectFit: "cover"}}
                    />
                    <div
                      style={{
                        position: "absolute",
                        background: "#fff",
                        width: 15,
                        height: 15,
                        top: 27,
                        right: 22,
                        borderRadius: "50%",
                        padding: 3
                      }}
                    >
                      <div
                        style={{
                          background: "#66CB9F",
                          borderRadius: "50%",
                          width: "100%",
                          height: "100%"
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <Grid
                    style={{
                      width: 36,
                      height: 36,
                      borderRadius: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#003CA5"
                    }}
                  >
                    <Typography variant="h4" style={{color: "#fff"}}>
                      {user.firstName && user.firstName.charAt(0)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Link>
          ) : (
            <Grid
              item
              className={window.location.pathname === "/ayarlar" ? classes.avatarGridSelected : classes.avatarGrid}
            >
              <Grid
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#003CA5"
                }}
              >
                <Typography variant="h4" style={{color: "#fff"}}>
                  {user.firstName && user.firstName.charAt(0)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Hidden smDown>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container style={{paddingLeft: 16}} justify="flex-start">
              <Link to="/" style={{padding: 0}}>
                <img src={Sblogo} alt="logo" style={{maxWidth: 110, maxHeight: 110, height: 55, width: 110}} />
              </Link>
            </Grid>
            <IconButton
              style={{color: "#425466"}}
              onClick={() =>
                client.writeQuery({
                  query: HEADER_LEFT_DRAWER,
                  data: {
                    HeaderLeftDrawer: false
                  }
                })
              }
            >
              {theme.direction === "ltr" ? (
                <div style={{color: "#425466", height: 25}}>
                  <MenuOpenIcon />
                </div>
              ) : (
                <div style={{color: "#425466", height: 25}}>
                  <MenuOpenIcon />
                </div>
              )}
            </IconButton>
          </div>
          {Object.keys(user).length > 0 && (
            <>
              {adminMenu && (role === "Admin" || role === "SuperAdmin") ? (
                <List className={classes.List}>
                  <Link to="/dashboard-admin" style={{padding: 0}}>
                    {window.location.pathname === "/dashboard-admin" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Dashboard"}
                      >
                        <ListItemIcon>
                          <HomeOutlined style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("Anasayfa")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Dashboard"}>
                        <ListItemIcon>
                          <HomeOutlined style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Anasayfa")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/kullanicilar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/kullanicilar" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Kullanıcılar"}
                      >
                        <ListItemIcon>
                          <img src={people} style={{width: 23}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Kullanıcılar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Kullanıcılar"}>
                        <ListItemIcon>
                          <img src={people} style={{width: 23}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Kullanıcılar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yatirimcilar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yatirimcilar" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Admin Yatırımcılar"}
                      >
                        <ListItemIcon>
                          <img src={investorsBlue} style={{width: 16}} alt="investors" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("Yatırımcılar")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Admin Yatırımcılar"}>
                        <ListItemIcon>
                          <img src={investors} style={{width: 16}} alt="investors" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Yatırımcılar")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/girisimler" style={{padding: 0}}>
                    {window.location.pathname === "/admin/girisimler" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Girişimler"}
                      >
                        <ListItemIcon>
                          <img src={newBagBlue} style={{width: 16}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Girişimler
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Girişimler"}>
                        <ListItemIcon>
                          <img src={newBag} style={{width: 16}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Girişimler</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/draftlar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/draftlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Draftlar"}
                      >
                        <ListItemIcon>
                          <CreateOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Draftlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Draftlar"}>
                        <ListItemIcon>
                          <CreateOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Draftlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/kpi" style={{padding: 0}}>
                    {window.location.pathname === "/admin/kpi" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"kpi"}
                      >
                        <ListItemIcon>
                          <TrendingUpIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          KPI Düzenle
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"kpi"}>
                        <ListItemIcon>
                          <TrendingUpIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">KPI Düzenle</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yorumlar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yorumlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Yorumlar"}
                      >
                        <ListItemIcon>
                          <ChatOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Yorumlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Yorumlar"}>
                        <ListItemIcon>
                          <ChatOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Yorumlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yatirim-basvurulari" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yatirim-basvurulari" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Yatırım Başvurular"}
                      >
                        <ListItemIcon>
                          <img src={newEyeBlue} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Yatırım Başvuruları
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Yatırım Başvuruları"}>
                        <ListItemIcon>
                          <img src={newEye} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Yatırım Başvuruları</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yatirim-turlari" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yatirim-turlari" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Yatırım Turu"}
                      >
                        <ListItemIcon>
                          <img src={InvestBlue} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Yatırım Turu
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Yatırım Turu"}>
                        <ListItemIcon>
                          <img src={Invest} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Yatırım Turu</Typography>
                      </ListItem>
                    )}
                  </Link>
                  {/*<Link to="/admin/hisse-liste" style={{ padding: 0 }}>
                    {window.location.pathname === '/admin/hisse-liste' ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{ background: '#F0F6FF', borderRadius: 4 }}
                        key={'Hisse Talepleri'}
                      >
                        <ListItemIcon>
                          <TrendingUpIcon style={{ color: '#003CA5', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{ color: '#003CA5' }}>
                          Hisse Talepleri
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={'Hisse Talepleri'}>
                        <ListItemIcon>
                        <TrendingUpIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6">Hisse Talepleri</Typography>
                      </ListItem>
                    )}
                    </Link>*/}

                  <Link to="/admin/ulke-sehir" style={{padding: 0}}>
                    {window.location.pathname === "/admin/ulke-sehir" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Ülke/Şehir"}
                      >
                        <ListItemIcon>
                          <PublicIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Ülke/Şehir
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Ülke/Şehir"}>
                        <ListItemIcon>
                          <PublicIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Ülke/Şehir</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/SSS" style={{padding: 0}}>
                    {window.location.pathname === "/admin/SSS" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Sıkça Sorulan Sorular-admin"}
                      >
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("SSS")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Sıkça Sorulan Sorular-admin"}>
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">{t("SSS")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <hr size="1" style={{border: "0.2px solid #f5f5f5", margin: "15px 0"}} />
                  <Link to="/ayarlar" style={{padding: 0}}>
                    {window.location.pathname === "/ayarlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Ayarlar-admin"}
                      >
                        <ListItemIcon>
                          <SettingsOutlined style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Ayarlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Ayarlar-admin"}>
                        <ListItemIcon>
                          <SettingsOutlined style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Ayarlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  {/* <Hidden lgUp>
                    <Link to="/bildirimler" style={{ padding: 0 }}>
                      {window.location.pathname === '/bildirimler' ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{ background: '#F0F6FF', borderRadius: 4 }}
                          button
                          key={'Bildirimler-admin'}
                        >
                          <ListItemIcon>
                            <NotificationsNoneIcon style={{ color: '#003CA5', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6" style={{ color: '#003CA5' }}>
                            Bildirimler
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={'Bildirimler-admin'}>
                          <ListItemIcon>
                            <NotificationsNoneIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6">Bildirimler</Typography>
                        </ListItem>
                      )}
                    </Link>
                  </Hidden> */}
                </List>
              ) : (
                <List className={classes.List}>
                  {role === "Admin" || role === "SuperAdmin" ? (
                    <Link to="/dashboard-admin" style={{padding: 0}}>
                      {window.location.pathname === "/dashboard-admin" ? (
                        <ListItem
                          className={classes.ListItem}
                          button
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          key={"Dashboard"}
                        >
                          <ListItemIcon>
                            <img src={newHome} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Anasayfa")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Dashboard"}>
                          <ListItemIcon>
                            <img src={newHomeBlue} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Anasayfa")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <Link to="/dashboard" style={{padding: 0}}>
                      {window.location.pathname === "/dashboard" ? (
                        <ListItem
                          className={classes.ListItem}
                          button
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          key={"Dashboard"}
                        >
                          <ListItemIcon>
                            <img src={newHome} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Anasayfa")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Dashboard"}>
                          <ListItemIcon>
                            <img src={newHomeBlue} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Anasayfa")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <Link to="/girisimler" style={{padding: 0}}>
                      {window.location.pathname === "/girisimler" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Girişimleri Keşfet"}
                        >
                          <ListItemIcon>
                            <img src={newBagBlue} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Girişimleri Keşfet")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Girişimleri Keşfet"}>
                          <ListItemIcon>
                            <img src={newBag} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Girişimleri Keşfet")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <>
                      {user.startup && (
                        <Link to={`/girisim/${user.startup.slug}`} style={{padding: 0}}>
                          {window.location.pathname === `/girisim/${user.startup.slug}` ? (
                            <ListItem
                              className={classes.ListItem}
                              style={{background: "#F0F6FF", borderRadius: 4}}
                              button
                              key={"Şirket Bilgilerim"}
                            >
                              <ListItemIcon>
                                <WorkOutlineIcon style={{color: "#003CA5", fontSize: 20}} />
                              </ListItemIcon>
                              <Typography variant="h6" style={{color: "#003CA5"}}>
                                {t("Şirket Profilim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem className={classes.ListItem} button key={"Şirket Bilgilerim"}>
                              <ListItemIcon>
                                <WorkOutlineIcon style={{color: "#A0AEC0", fontSize: 20}} />
                              </ListItemIcon>
                              <Typography variant="h6">{t("Şirket Profilim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                      )}
                    </>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    profileSelected && !pipelineSelected ? (
                      <>
                        <ListItem
                          className={classes.ListItem}
                          button
                          onClick={() => setProfileSelected(false)}
                          key={"Profilim"}
                        >
                          <ListItemIcon>
                            <img src={blueProfileMenuIcon} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Profilim")}
                          </Typography>
                        </ListItem>
                        {props.getInvestorProfileStatus ? (
                          <Link to="/yatirimci-profilim" style={{padding: 0}}>
                            {window.location.pathname === "/yatirimci-profilim" ? (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  paddingLeft: 0,
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    borderLeft: "3px solid #118DFF",
                                    paddingLeft: 28,
                                    marginLeft: -3,
                                    paddingTop: 2
                                  }}
                                >
                                  {t("Yatırımcı Profili")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography variant="h6">{t("Yatırımcı Profili")}</Typography>
                              </ListItem>
                            )}
                          </Link>
                        ) : (
                          <Link to="/yatirimci-profili-yarat" style={{padding: 0}}>
                            {window.location.pathname === "/yatirimci-profili-yarat" ? (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  paddingLeft: 0,
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    borderLeft: "3px solid #118DFF",
                                    paddingLeft: 28,
                                    marginLeft: -3,
                                    paddingTop: 2
                                  }}
                                >
                                  {t("Yatırımcı Profili")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography variant="h6">{t("Yatırımcı Profili")}</Typography>
                              </ListItem>
                            )}
                          </Link>
                        )}
                        {props.getInvestorProfileStatus ? (
                          <Link to="/basvurular" style={{padding: 0}}>
                            {window.location.pathname === "/basvurular" ? (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  paddingLeft: 0,
                                  transition: "none",
                                  paddingBottom: 0,
                                  paddingTop: 12,
                                  marginBottom: 8,
                                  width: 200
                                }}
                                key={"Başvurular"}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    borderLeft: "3px solid #118DFF",
                                    paddingLeft: 28,
                                    marginLeft: -3,
                                    paddingBottom: 2
                                  }}
                                >
                                  {t("Başvurular")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  transition: "none",
                                  paddingBottom: 0,
                                  paddingTop: 12,
                                  marginBottom: 8,
                                  width: 200
                                }}
                                key={"Başvurular"}
                              >
                                <Typography variant="h6">{t("Başvurular")}</Typography>
                              </ListItem>
                            )}
                          </Link>
                        ) : (
                          <ListItem className={classes.ListItemInner} key={"Başvurular"}>
                            <Typography variant="h6" style={{color: "#777777"}}>
                              {t("Başvurular")}
                            </Typography>
                          </ListItem>
                        )}
                      </>
                    ) : (
                      <ListItem
                        className={classes.ListItem}
                        button
                        key={"Profilim"}
                        onClick={() => {
                          setProfileSelected(true);
                          setPipelineSelected(false);
                        }}
                      >
                        <ListItemIcon>
                          <img src={profileMenuIcon} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Profilim")}</Typography>
                      </ListItem>
                    )
                  ) : (
                    <>
                      {/* <Link to="/yatirim-turu" style={{ padding: 0 }}>
                        {window.location.pathname === "/yatirim-turu" ? (
                          <ListItem
                            className={classes.ListItem}
                            style={{ background: '#F0F6FF', borderRadius: 4 }}
                            button
                            key={'Yatırım Turu'}
                          >
                            <ListItemIcon>
                              <img src={investorTourBlue} style={{ width: 16 }} alt="investor-tour" />
                            </ListItemIcon>
                            <Typography variant="h6" style={{ color: '#003CA5' }}>
                              {t('Yatırım Turu')}
                            </Typography>
                          </ListItem>
                        ) : (
                          <ListItem className={classes.ListItem} button key={'Yatırım Turu'}>
                            <ListItemIcon>
                              <img src={investorTour} style={{ width: 16 }} alt="investor-tour-blue" />
                            </ListItemIcon>
                            <Typography variant="h6">{t('Yatırım Turu')}</Typography>
                          </ListItem>
                        )}
                      </Link> */}
                    </>
                  )}
                  {role === "StartupContact" && (
                    <Link to="/yatirimcilar" style={{padding: 0}}>
                      {window.location.pathname === "/yatirimcilar" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırımcılar"}
                        >
                          <ListItemIcon>
                            <img src={investorsBlue} style={{width: 16}} alt="investors" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Yatırımcılar")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırımcılar2"}>
                          <ListItemIcon>
                            <img src={investors} style={{width: 16}} alt="investors" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Yatırımcılar")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <></>
                  ) : (
                    // <Link to="/yatirimci-arayanlar" style={{ padding: 0 }}>
                    //   {window.location.pathname === '/yatirimci-arayanlar' ? (
                    //     <ListItem
                    //       className={classes.ListItem}
                    //       style={{ background: '#F0F6FF', borderRadius: 4 }}
                    //       button
                    //       key={'Yatırım arayan girişimler'}
                    //     >
                    //       <ListItemIcon>
                    //         <img src={investorTourBlue} style={{ width: 16 }} alt="people" />
                    //       </ListItemIcon>
                    //       <Typography variant="h6" style={{ color: '#003CA5' }}>
                    //         {t('Yatırım arayan girişimler')}
                    //       </Typography>
                    //     </ListItem>
                    //   ) : (
                    //     <ListItem className={classes.ListItem} button key={'Yatırım arayan girişimler'}>
                    //       <ListItemIcon>
                    //         <img src={investorTour} style={{ width: 16 }} alt="people" />
                    //       </ListItemIcon>
                    //       <Typography variant="h6">{t('Yatırım arayan girişimler')}</Typography>
                    //     </ListItem>
                    //   )}
                    // </Link>
                    <Link to="/kisilerim" style={{padding: 0}}>
                      {window.location.pathname === "/kisilerim" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Kişilerim"}
                        >
                          <ListItemIcon>
                            <img src={newPeopleBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Kişilerim")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Kişilerim"}>
                          <ListItemIcon>
                            <img src={newPeople} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Kişilerim")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <Link to="/yatirimcilar" style={{padding: 0}}>
                      {window.location.pathname === "/yatirimcilar" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırımcılar"}
                        >
                          <ListItemIcon>
                            <img src={investorsBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Yatırımcılar")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırımcılar"}>
                          <ListItemIcon>
                            <img src={investors} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Yatırımcılar")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <Link to="/verilerim" style={{padding: 0}}>
                      {window.location.pathname === "/verilerim" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Verilerim"}
                        >
                          <ListItemIcon>
                            <FolderOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Verilerim")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Verilerim"}>
                          <ListItemIcon>
                            <FolderOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Verilerim")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <Link to="/yatirimci-arayanlar" style={{padding: 0}}>
                      {window.location.pathname === "/yatirimci-arayanlar" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırımcı Arayanlar"}
                        >
                          <ListItemIcon>
                            <img src={InvestBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Yatırımcı Arayanlar")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırımcı Arayanlar"}>
                          <ListItemIcon>
                            <img src={Invest} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Yatırımcı Arayanlar")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <Link to="/yatirim-turu" style={{padding: 0}}>
                      {window.location.pathname === "/yatirim-turu" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırım Turu"}
                        >
                          <ListItemIcon>
                            <img src={InvestBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {hasASprint ? t("Yatırım Turu") : t("Yatırım Turu Ekle")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırım Turu"}>
                          <ListItemIcon>
                            <img src={Invest} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">
                            {" "}
                            {hasASprint ? t("Yatırım Turu") : t("Yatırım Turu Ekle")}
                          </Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {/*<ListItem
                  className={classes.ListItem}
                  style={{ background: '#F0F6FF', borderRadius: 4 }}
                  button
                  key={'Yatırımcı Arayanlar'}
                  component={Link} to="/yatirimci-arayanlar"
                >
                  <ListItemIcon>
                    <img src={Invest} style={{ width: 16 }} alt="star" />
                  </ListItemIcon>
                  <Typography variant="h6" style={{ color: '#003CA5' }}>
                    {t('Yatırımcı Arayanlar')}
                  </Typography>
                </ListItem><ListItem className={classes.ListItem} button key={'Yatırımcı Arayanlar'} component={Link} to="/yatirimci-arayanlar">
                   <ListItemIcon>
                     <img src={Invest} style={{background:'#F0F6FF', width: 18 }} alt="people" />
                   </ListItemIcon>
                   <Typography variant="h6">{t('Yatırımcı Arayanlar')}</Typography>
                  </ListItem>*/}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    !profileSelected && pipelineSelected ? (
                      <>
                        <ListItem
                          className={classes.ListItem}
                          button
                          onClick={() => setPipelineSelected(false)}
                          key={"İlgilendiğim Girişimler"}
                        >
                          <ListItemIcon>
                            <img src={newEyeBlue} style={{width: 18}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "rgb(0, 60, 165)"}}>
                            {t("İlgilendiğim Girişimler")}
                          </Typography>
                        </ListItem>
                        <Link to="/favorilerim" style={{padding: 0}}>
                          {window.location.pathname === "/favorilerim" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingTop: 0,
                                paddingBottom: 12,
                                marginTop: 8,
                                width: 200
                              }}
                              key={"Favorilerim"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingTop: 2
                                }}
                              >
                                {t("Favorilerim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{transition: "none", paddingTop: 0, paddingBottom: 12, marginTop: 8, width: 200}}
                              key={"Favorilerim"}
                            >
                              <Typography variant="h6">{t("Favorilerim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                        <Link to="/takip-ettiklerim" style={{padding: 0}}>
                          {window.location.pathname === "/takip-ettiklerim" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                width: 200
                              }}
                              key={"Takip Ettiklerim"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingBottom: 2
                                }}
                              >
                                {t("Takip Ettiklerim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 0,
                                //marginBottom: 8,
                                width: 200
                              }}
                              key={"Takip Ettiklerim"}
                            >
                              <Typography variant="h6">{t("Takip Ettiklerim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                        <Link to="/tekliflerim" style={{padding: 0}}>
                          {window.location.pathname === "/tekliflerim" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                width: 200
                              }}
                              key={"Tekliflerim"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingBottom: 2
                                }}
                              >
                                {t("Taleplerim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                //marginBottom: 8,
                                width: 200
                              }}
                              key={"Tekliflerim"}
                            >
                              <Typography variant="h6">{t("Taleplerim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                        <Link to="/portfoyum" style={{padding: 0}}>
                          {window.location.pathname === "/portfoyum" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                width: 200
                              }}
                              key={"Portfoyüm"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingBottom: 2
                                }}
                              >
                                {t("Portfoyüm")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                //marginBottom: 8,
                                width: 200
                              }}
                              key={"Portfoyüm"}
                            >
                              <Typography variant="h6">{t("Portfoyüm")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                      </>
                    ) : (
                      <>
                        <ListItem
                          className={classes.ListItem}
                          button
                          key={"İlgilendiğim Girişimler"}
                          onClick={() => {
                            setProfileSelected(false);
                            setPipelineSelected(true);
                          }}
                        >
                          <ListItemIcon>
                            <img src={newEye} style={{width: 18}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("İlgilendiğim Girişimler")}</Typography>
                        </ListItem>
                      </>
                    )
                  ) : (
                    <>
                      {window.location.pathname === "/raporlarim" ? (
                        <Link to="/raporlarim" style={{padding: 0}}>
                          <ListItem
                            className={classes.ListItem}
                            style={{background: "#F0F6FF", borderRadius: 4}}
                            button
                            key={"Raporlarım"}
                          >
                            <ListItemIcon>
                              <InsertDriveFileOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                            </ListItemIcon>
                            <Typography variant="h6" style={{color: "#003CA5"}}>
                              {t("Raporlarım")}
                            </Typography>
                          </ListItem>
                        </Link>
                      ) : (
                        <Link to="/raporlarim" style={{padding: 0}}>
                          <ListItem className={classes.ListItem} button key={"Raporlarım"}>
                            <ListItemIcon>
                              <InsertDriveFileOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                            </ListItemIcon>
                            <Typography variant="h6">{t("Raporlarım")}</Typography>
                          </ListItem>
                        </Link>
                      )}
                    </>
                  )}
                  {/*{role === 'Investor' || role === 'SuperAdmin' || role === 'Admin' ? (
                    <Link to="/hisse-al-sat" style={{ padding: 0 }}>
                      {window.location.pathname === '/hisse-al-sat' ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{ background: '#F0F6FF', borderRadius: 4 }}
                          button
                          key={'Hisse Al/Sat'}
                        >
                          <ListItemIcon>
                            <TrendingUpIcon style={{ color: '#003CA5', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6" style={{ color: '#003CA5' }}>
                            {t('Hisse Al/Sat')}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={'Hisse Al/Sat'}>
                          <ListItemIcon>
                            <TrendingUpIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6">{t('Hisse Al/Sat')}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <></>
                  )}*/}
                  <Link to="/SSS" style={{padding: 0}}>
                    {window.location.pathname === "/SSS" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Sıkça Sorulan Sorular"}
                      >
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("SSS")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Sıkça Sorulan Sorular"}>
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">{t("SSS")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <hr size="1" style={{border: "0.2px solid #f5f5f5", margin: "15px 0"}} />
                  <Link to="/ayarlar" style={{padding: 0}}>
                    {window.location.pathname === "/ayarlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Ayarlar-admin"}
                      >
                        <ListItemIcon>
                          <img src={newSettingsBlue} style={{width: 16}} alt="settings" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("Ayarlar")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Ayarlar-admin"}>
                        <ListItemIcon>
                          <img src={newSettings} style={{width: 16}} alt="settings" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Ayarlar")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  {/* <Hidden lgUp>
                    <Link to="/bildirimler" style={{ padding: 0 }}>
                      {window.location.pathname === '/bildirimler' ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{ background: '#F0F6FF', borderRadius: 4 }}
                          button
                          key={'Bildirimler-admin'}
                        >
                          <ListItemIcon>
                            <NotificationsNoneIcon style={{ color: '#003CA5', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6" style={{ color: '#003CA5' }}>
                            {t('Bildirimler')}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={'Bildirimler-admin'}>
                          <ListItemIcon>
                            <NotificationsNoneIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6">{t('Bildirimler')}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  </Hidden> */}
                </List>
              )}
            </>
          )}
          {Object.keys(user).length > 0 && (
            <>
              {role === "SuperAdmin" || role === "Admin" ? (
                <>
                  {adminMenu ? (
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{cursor: "pointer"}}
                      onClick={() => setAdminMenu(false)}
                      className={classes.blueField}
                    >
                      <Grid item style={{padding: 0, display: "flex", alignItems: "center"}}>
                        <Typography variant="h6" className={classes.blueFieldText}>
                          {t("Kullanıcı Olarak Gör")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowRightAltIcon style={{color: "#003CA5", marginTop: 5}} />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      style={{cursor: "pointer"}}
                      onClick={() => setAdminMenu(true)}
                      container
                      alignItems="center"
                      justify="space-between"
                      className={classes.blueField}
                    >
                      <Grid item style={{padding: 0, display: "flex", alignItems: "center"}}>
                        <Typography variant="h6" className={classes.blueFieldText}>
                          {t("Admin Olarak Gör")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowRightAltIcon style={{color: "#003CA5", marginTop: 5}} />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {role === "Investor" ? (
                    <>
                      {user.startup ? (
                        <Grid
                          container
                          alignItems="center"
                          justify="space-between"
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            handleRole("StartupContact");
                            props.setRoleChange("StartupContact");
                          }}
                          className={classes.blueField}
                        >
                          <Link
                            to={`/girisim/${user.startup.slug}`}
                            style={{padding: 0, display: "flex", alignItems: "center"}}
                          >
                            {user.startup.logo ? (
                              <img src={user.startup.logo} alt="logo" className={classes.startupLogo} />
                            ) : (
                              <Grid
                                style={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background: "#003CA5"
                                }}
                              >
                                <Typography variant="h3" style={{color: "#fff"}}>
                                  {user.startup.title && user.startup.title.charAt(0)}
                                </Typography>
                              </Grid>
                            )}
                            {user.startup.title && (
                              <Typography variant="h6" className={classes.blueFieldText}>
                                {truncateString(user.startup.title, 12)}
                              </Typography>
                            )}
                          </Link>
                          <Grid item>
                            <ArrowRightAltIcon style={{color: "#003CA5", marginTop: 5}} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Link to={`/girisim-yarat`} style={{padding: 0, display: "flex", alignItems: "center"}}>
                          <Grid container alignItems="center" style={{paddingLeft: 32}} className={classes.blueField}>
                            <AddCircleOutlineIcon style={{color: "#003CA5", cursor: "pointer"}}></AddCircleOutlineIcon>
                            <Typography variant="h6" className={classes.blueFieldText}>
                              Şirket Ekle
                            </Typography>
                          </Grid>
                        </Link>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      style={{cursor: "pointer"}}
                      alignItems="center"
                      className={classes.blueField}
                      onClick={() => {
                        handleRole("Investor");
                        props.setRoleChange("Investor");
                      }}
                    >
                      <Grid style={{display: "flex"}} alignItems="center" item>
                        <img src={bolt} alt="bolt" />
                        <Typography variant="h6" className={classes.blueFieldText}>
                          Şirketleri Keşfet
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{cursor: "pointer", background: "#fff", marginTop: 0}}
            className={classes.blueField}
          >
            <ListItem onClick={logout} style={{paddingLeft: 5}}>
              <ListItemIcon>
                <ExitToAppIcon style={{color: "#F16063", fontSize: 20}} />
              </ListItemIcon>
              <Typography variant="h6" style={{color: "#F16063", fontSize: 13}}>
                {t("Çıkış Yap")}
              </Typography>
            </ListItem>
          </Grid>
          {/* <Typography variant="body2" className={classes.bottomText}>
            Startup Borsa, bir
            <a style={{color:'#fff',padding:0,cursor:'pointer'}} href="https://www.gedik.com/" className={classes.bottomText}> Gedik Yatırım</a> kuruluşudur
          </Typography> */}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={get(props, "HeaderLeftDrawer.HeaderLeftDrawerMobile", false)}
          onClose={() =>
            client.writeQuery({
              query: HEADER_LEFT_DRAWER,
              data: {
                HeaderLeftDrawerMobile: false
              }
            })
          }
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader}>
            <Grid container style={{paddingRight: 10}} justify="left">
              <Link to="/" style={{padding: 0}}>
                <img alt="logo" src={Sblogo} style={{maxWidth: 110, width: 110}} />
              </Link>
            </Grid>
            <IconButton
              style={{paddingBottom: 0}}
              onClick={() =>
                client.writeQuery({
                  query: HEADER_LEFT_DRAWER,
                  data: {
                    HeaderLeftDrawerMobile: false
                  }
                })
              }
            >
              {theme.direction === "ltr" ? (
                <div>
                  <MenuOpenIcon />
                </div>
              ) : (
                <div>
                  <MenuOpenIcon />
                </div>
              )}
            </IconButton>
          </div>
          {Object.keys(user).length > 0 && (
            <>
              {adminMenu && (role === "Admin" || role === "SuperAdmin") ? (
                <List className={classes.List}>
                  <Link to="/dashboard-admin" style={{padding: 0}}>
                    {window.location.pathname === "/dashboard-admin" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Dashboard"}
                      >
                        <ListItemIcon>
                          <img src={newHome} style={{width: 16}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("Anasayfa")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Dashboard"}>
                        <ListItemIcon>
                          <img src={newHomeBlue} style={{width: 16}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Anasayfa")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/kullanicilar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/kullanicilar" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Kullanıcılar"}
                      >
                        <ListItemIcon>
                          <img src={people} style={{width: 23}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Kullanıcılar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Kullanıcılar"}>
                        <ListItemIcon>
                          <img src={people} style={{width: 23}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Kullanıcılar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yatirimcilar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yatirimcilar" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Admin Yatırımcılar"}
                      >
                        <ListItemIcon>
                          <img src={investorsBlue} style={{width: 16}} alt="investors" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("Yatırımcılar")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Admin Yatırımcılar"}>
                        <ListItemIcon>
                          <img src={investors} style={{width: 16}} alt="investors" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Yatırımcılar")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/girisimler" style={{padding: 0}}>
                    {window.location.pathname === "/admin/girisimler" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Girişimler"}
                      >
                        <ListItemIcon>
                          <ViewModule style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Girişimler
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Girişimler"}>
                        <ListItemIcon>
                          <ViewModule style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Girişimler</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/draftlar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/draftlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Draftlar"}
                      >
                        <ListItemIcon>
                          <CreateOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Draftlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Draftlar"}>
                        <ListItemIcon>
                          <CreateOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Draftlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/kpi" style={{padding: 0}}>
                    {window.location.pathname === "/admin/kpi" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"kpi"}
                      >
                        <ListItemIcon>
                          <TrendingUpIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          KPI Düzenle
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"kpi"}>
                        <ListItemIcon>
                          <TrendingUpIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">KPI Düzenle</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yorumlar" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yorumlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Yorumlar-admin"}
                      >
                        <ListItemIcon>
                          <ChatOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Yorumlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Yorumlar-admin"}>
                        <ListItemIcon>
                          <ChatOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Yorumlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yatirim-basvurulari" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yatirim-basvurulari" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Yatırım Başvurular"}
                      >
                        <ListItemIcon>
                          <img src={newEyeBlue} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Yatırım Başvuruları
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Yatırım Başvuruları"}>
                        <ListItemIcon>
                          <img src={newEye} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Yatırım Başvuruları</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/yatirim-turlari" style={{padding: 0}}>
                    {window.location.pathname === "/admin/yatirim-turlari" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Yatırım Turu"}
                      >
                        <ListItemIcon>
                          <img src={InvestBlue} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Yatırım Turu
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Yatırım Turu"}>
                        <ListItemIcon>
                          <img src={Invest} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">Yatırım Turu</Typography>
                      </ListItem>
                    )}
                  </Link>
                  {/*<Link to="/admin/hisse-liste" style={{ padding: 0 }}>
                    {window.location.pathname === '/admin/hisse-liste' ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{ background: '#F0F6FF', borderRadius: 4 }}
                        key={'Hisse Talepleri'}
                      >
                        <ListItemIcon>
                          <TrendingUpIcon style={{ color: '#003CA5', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{ color: '#003CA5' }}>
                          Hisse Talepleri
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={'Hisse Talepleri'}>
                        <ListItemIcon>
                        <TrendingUpIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6">Hisse Talepleri</Typography>
                      </ListItem>
                    )}
                    </Link>*/}

                  <Link to="/admin/ulke-sehir" style={{padding: 0}}>
                    {window.location.pathname === "/admin/ulke-sehir" ? (
                      <ListItem
                        className={classes.ListItem}
                        button
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        key={"Ülke/Şehir-admin"}
                      >
                        <ListItemIcon>
                          <PublicIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Ülke/Şehir
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Ülke/Şehir-admin"}>
                        <ListItemIcon>
                          <PublicIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">Ülke/Şehir</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <Link to="/admin/SSS" style={{padding: 0}}>
                    {window.location.pathname === "/admin/SSS" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Sıkça Sorulan Sorular-admin"}
                      >
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("SSS")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Sıkça Sorulan Sorular-admin"}>
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">{t("SSS")}</Typography>
                      </ListItem>
                    )}
                  </Link>
                  <hr size="1" style={{border: "0.2px solid #f5f5f5", margin: "15px 0"}} />
                  <Link to="/ayarlar" style={{padding: 0}}>
                    {window.location.pathname === "/ayarlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Ayarlar-admin"}
                      >
                        <ListItemIcon>
                          <img src={newSettingsBlue} style={{width: 16}} alt="settings" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Ayarlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Ayarlar-admin"}>
                        <ListItemIcon>
                          <img src={newSettings} style={{width: 16}} alt="settings" />
                        </ListItemIcon>
                        <Typography variant="h6">Ayarlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  {/* <Link to="/bildirimler" style={{ padding: 0 }}>
                    {window.location.pathname === '/bildirimler' ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{ background: '#F0F6FF', borderRadius: 4 }}
                        button
                        key={'Bildirimler-admin'}
                      >
                        <ListItemIcon>
                          <NotificationsNoneIcon style={{ color: '#003CA5', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{ color: '#003CA5' }}>
                          Bildirimler
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={'Bildirimler-admin'}>
                        <ListItemIcon>
                          <NotificationsNoneIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6">Bildirimler</Typography>
                      </ListItem>
                    )}
                  </Link> */}
                </List>
              ) : (
                <List className={classes.List}>
                  {role === "Admin" || role === "SuperAdmin" ? (
                    <Link to="/dashboard-admin" style={{padding: 0}}>
                      {window.location.pathname === "/dashboard-admin" ? (
                        <ListItem
                          className={classes.ListItem}
                          button
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          key={"Dashboard"}
                        >
                          <ListItemIcon>
                            <img src={newHome} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Anasayfa")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Dashboard"}>
                          <ListItemIcon>
                            <img src={newHomeBlue} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Anasayfa")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <Link to="/dashboard" style={{padding: 0}}>
                      {window.location.pathname === "/dashboard" ? (
                        <ListItem
                          className={classes.ListItem}
                          button
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          key={"Dashboard"}
                        >
                          <ListItemIcon>
                            <img src={newHome} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Anasayfa")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Dashboard"}>
                          <ListItemIcon>
                            <img src={newHomeBlue} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Anasayfa")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <Link to="/girisimler" style={{padding: 0}}>
                      {window.location.pathname === "/girisimler" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Girişimleri Keşfet"}
                        >
                          <ListItemIcon>
                            <img src={newBagBlue} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Girişimleri Keşfet")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Girişimleri Keşfet"}>
                          <ListItemIcon>
                            <img src={newBag} style={{width: 16}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Girişimleri Keşfet")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <>
                      {user.startup && (
                        <Link to={`/girisim/${user.startup.slug}`} style={{padding: 0}}>
                          {window.location.pathname === `/girisim/${user.startup.slug}` ? (
                            <ListItem
                              className={classes.ListItem}
                              style={{background: "#F0F6FF", borderRadius: 4}}
                              button
                              key={"Şirket Bilgilerim"}
                            >
                              <ListItemIcon>
                                <WorkOutlineIcon style={{color: "#003CA5", fontSize: 20}} />
                              </ListItemIcon>
                              <Typography variant="h6" style={{color: "#003CA5"}}>
                                {t("Şirket Profilim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem className={classes.ListItem} button key={"Şirket Bilgilerim"}>
                              <ListItemIcon>
                                <WorkOutlineIcon style={{color: "#A0AEC0", fontSize: 20}} />
                              </ListItemIcon>
                              <Typography variant="h6">{t("Şirket Profilim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                      )}
                    </>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    profileSelected && !pipelineSelected ? (
                      <>
                        <ListItem
                          className={classes.ListItem}
                          button
                          onClick={() => {
                            setProfileSelected(false);
                            setPipelineSelected(false);
                          }}
                          key={"Profilim"}
                        >
                          <ListItemIcon>
                            <img src={blueProfileMenuIcon} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Profilim")}
                          </Typography>
                        </ListItem>
                        {props.getInvestorProfileStatus ? (
                          <Link to="/yatirimci-profilim" style={{padding: 0}}>
                            {window.location.pathname === "/yatirimci-profilim" ? (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  paddingLeft: 0,
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    borderLeft: "3px solid #118DFF",
                                    paddingLeft: 28,
                                    marginLeft: -3,
                                    paddingTop: 2
                                  }}
                                >
                                  {t("Yatırımcı Profili")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography variant="h6">{t("Yatırımcı Profili")}</Typography>
                              </ListItem>
                            )}
                          </Link>
                        ) : (
                          <Link to="/yatirimci-profili-yarat" style={{padding: 0}}>
                            {window.location.pathname === "/yatirimci-profili-yarat" ? (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  paddingLeft: 0,
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    borderLeft: "3px solid #118DFF",
                                    paddingLeft: 28,
                                    marginLeft: -3,
                                    paddingTop: 2
                                  }}
                                >
                                  {t("Yatırımcı Profili")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  transition: "none",
                                  paddingTop: 0,
                                  paddingBottom: 12,
                                  marginTop: 8,
                                  width: 200
                                }}
                                key={"Yatırımcı Profili"}
                              >
                                <Typography variant="h6">{t("Yatırımcı Profili")}</Typography>
                              </ListItem>
                            )}
                          </Link>
                        )}
                        {props.getInvestorProfileStatus ? (
                          <Link to="/basvurular" style={{padding: 0}}>
                            {window.location.pathname === "/basvurular" ? (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  paddingLeft: 0,
                                  transition: "none",
                                  paddingBottom: 0,
                                  paddingTop: 12,
                                  marginBottom: 8,
                                  width: 200
                                }}
                                key={"Başvurular"}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    borderLeft: "3px solid #118DFF",
                                    paddingLeft: 28,
                                    marginLeft: -3,
                                    paddingBottom: 2
                                  }}
                                >
                                  {t("Başvurular")}
                                </Typography>
                              </ListItem>
                            ) : (
                              <ListItem
                                className={classes.ListItemInner}
                                style={{
                                  transition: "none",
                                  paddingBottom: 0,
                                  paddingTop: 12,
                                  marginBottom: 8,
                                  width: 200
                                }}
                                key={"Başvurular"}
                              >
                                <Typography variant="h6">{t("Başvurular")}</Typography>
                              </ListItem>
                            )}
                          </Link>
                        ) : (
                          <ListItem
                            className={classes.ListItemInner}
                            style={{
                              paddingLeft: 0,
                              transition: "none",
                              paddingBottom: 0,
                              paddingTop: 12,
                              marginBottom: 8,
                              width: 200
                            }}
                            key={"Başvurular"}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                borderLeft: "3px solid #118DFF",
                                paddingLeft: 28,
                                marginLeft: -3,
                                paddingBottom: 2
                              }}
                            >
                              {t("Başvurular")}
                            </Typography>
                          </ListItem>
                        )}
                      </>
                    ) : (
                      <ListItem
                        className={classes.ListItem}
                        button
                        key={"Profilim"}
                        onClick={() => {
                          setProfileSelected(true);
                          setPipelineSelected(false);
                        }}
                      >
                        <ListItemIcon>
                          <img src={profileMenuIcon} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("Profilim")}</Typography>
                      </ListItem>
                    )
                  ) : (
                    <>
                      {/* <Link to="/yatirim-turu" style={{ padding: 0 }}>
                        {window.location.pathname === "/yatirim-turu" ? (
                          <ListItem
                            className={classes.ListItem}
                            style={{ background: '#F0F6FF', borderRadius: 4 }}
                            button
                            key={'Yatırım Turu'}
                          >
                            <ListItemIcon>
                              <img src={investorTourBlue} style={{ width: 16 }} alt="investor-tour" />
                            </ListItemIcon>
                            <Typography variant="h6" style={{ color: '#003CA5' }}>
                              {t('Yatırım Turu')}
                            </Typography>
                          </ListItem>
                        ) : (
                          <ListItem className={classes.ListItem} button key={'Yatırım Turu'}>
                            <ListItemIcon>
                              <img src={investorTour} style={{ width: 16 }} alt="investor-tour-blue" />
                            </ListItemIcon>
                            <Typography variant="h6">{t('Yatırım Turu')}</Typography>
                          </ListItem>
                        )}
                      </Link> */}
                    </>
                  )}
                  {role === "StartupContact" && (
                    <Link to="/yatirimcilar" style={{padding: 0}}>
                      {window.location.pathname === "/yatirimcilar" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırımcılar"}
                        >
                          <ListItemIcon>
                            <img src={investorsBlue} style={{width: 16}} alt="investors" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Yatırımcılar")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırımcılar"}>
                          <ListItemIcon>
                            <img src={investors} style={{width: 16}} alt="investors" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Yatırımcılar")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <></>
                  ) : (
                    // <Link to="/yatirimci-arayanlar" style={{ padding: 0 }}>
                    //   {window.location.pathname === '/yatirimci-arayanlar' ? (
                    //     <ListItem
                    //       className={classes.ListItem}
                    //       style={{ background: '#F0F6FF', borderRadius: 4 }}
                    //       button
                    //       key={'Yatırım arayan girişimler'}
                    //     >
                    //       <ListItemIcon>
                    //         <img src={investorTourBlue} style={{ width: 16 }} alt="people" />
                    //       </ListItemIcon>
                    //       <Typography variant="h6" style={{ color: '#003CA5' }}>
                    //         {t('Yatırım arayan girişimler')}
                    //       </Typography>
                    //     </ListItem>
                    //   ) : (
                    //     <ListItem className={classes.ListItem} button key={'Yatırım arayan girişimler'}>
                    //       <ListItemIcon>
                    //         <img src={investorTour} style={{ width: 16 }} alt="people" />
                    //       </ListItemIcon>
                    //       <Typography variant="h6">{t('Yatırım arayan girişimler')}</Typography>
                    //     </ListItem>
                    //   )}
                    // </Link>
                    <Link to="/kisilerim" style={{padding: 0}}>
                      {window.location.pathname === "/kisilerim" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Kişilerim"}
                        >
                          <ListItemIcon>
                            <img src={newPeopleBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Kişilerim")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Kişilerim"}>
                          <ListItemIcon>
                            <img src={newPeople} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Kişilerim")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <Link to="/yatirimcilar" style={{padding: 0}}>
                      {window.location.pathname === "/yatirimcilar" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırımcılar"}
                        >
                          <ListItemIcon>
                            <img src={investorsBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Yatırımcılar")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırımcılar"}>
                          <ListItemIcon>
                            <img src={investors} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Yatırımcılar")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <Link to="/verilerim" style={{padding: 0}}>
                      {window.location.pathname === "/verilerim" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Verilerim"}
                        >
                          <ListItemIcon>
                            <FolderOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Verilerim")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Verilerim"}>
                          <ListItemIcon>
                            <FolderOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Verilerim")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    <Link to="/yatirimci-arayanlar" style={{padding: 0}}>
                      {window.location.pathname === "/yatirimci-arayanlar" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırımcı Arayanlar"}
                        >
                          <ListItemIcon>
                            <img src={InvestBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {t("Yatırımcı Arayanlar")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırımcı Arayanlar"}>
                          <ListItemIcon>
                            <img src={Invest} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">{t("Yatırımcı Arayanlar")}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <Link to="/yatirim-turu" style={{padding: 0}}>
                      {window.location.pathname === "/yatirim-turu" ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{background: "#F0F6FF", borderRadius: 4}}
                          button
                          key={"Yatırım Turu"}
                        >
                          <ListItemIcon>
                            <img src={InvestBlue} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "#003CA5"}}>
                            {hasASprint ? t("Yatırım Turu") : t("Yatırım Turu Ekle")}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={"Yatırım Turu"}>
                          <ListItemIcon>
                            <img src={Invest} style={{width: 16}} alt="star" />
                          </ListItemIcon>
                          <Typography variant="h6">
                            {" "}
                            {hasASprint ? t("Yatırım Turu") : t("Yatırım Turu Ekle")}
                          </Typography>
                        </ListItem>
                      )}
                    </Link>
                  )}
                  {role === "Investor" || role === "SuperAdmin" || role === "Admin" ? (
                    !profileSelected && pipelineSelected ? (
                      <>
                        <ListItem
                          className={classes.ListItem}
                          button
                          onClick={() => setPipelineSelected(false)}
                          key={"İlgilendiğim Girişimler"}
                        >
                          <ListItemIcon>
                            <img src={newEyeBlue} style={{width: 18}} alt="people" />
                          </ListItemIcon>
                          <Typography variant="h6" style={{color: "rgb(0, 60, 165)"}}>
                            {t("İlgilendiğim Girişimler")}
                          </Typography>
                        </ListItem>
                        <Link to="/favorilerim" style={{padding: 0}}>
                          {window.location.pathname === "/favorilerim" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingTop: 0,
                                paddingBottom: 12,
                                marginTop: 8,
                                width: 200
                              }}
                              key={"Favorilerim"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingTop: 2
                                }}
                              >
                                {t("Favorilerim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingTop: 0,
                                paddingBottom: 12,
                                marginTop: 8,
                                width: 200
                              }}
                              key={"Favorilerim"}
                            >
                              <Typography variant="h6">{t("Favorilerim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                        <Link to="/takip-ettiklerim" style={{padding: 0}}>
                          {window.location.pathname === "/takip-ettiklerim" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                width: 200
                              }}
                              key={"Takip Ettiklerim"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingBottom: 2
                                }}
                              >
                                {t("Takip Ettiklerim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 0,
                                //marginBottom: 8,
                                width: 200
                              }}
                              key={"Takip Ettiklerim"}
                            >
                              <Typography variant="h6">{t("Takip Ettiklerim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                        <Link to="/tekliflerim" style={{padding: 0}}>
                          {window.location.pathname === "/tekliflerim" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                width: 200
                              }}
                              key={"Tekliflerim"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingBottom: 2
                                }}
                              >
                                {t("Tekliflerim")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                //marginBottom: 8,
                                width: 200
                              }}
                              key={"Tekliflerim"}
                            >
                              <Typography variant="h6">{t("Tekliflerim")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                        <Link to="/portfoyum" style={{padding: 0}}>
                          {window.location.pathname === "/portfoyum" ? (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                paddingLeft: 0,
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                width: 200
                              }}
                              key={"Portfoyüm"}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  borderLeft: "3px solid #118DFF",
                                  paddingLeft: 28,
                                  marginLeft: -3,
                                  paddingBottom: 2
                                }}
                              >
                                {t("Portfoyüm")}
                              </Typography>
                            </ListItem>
                          ) : (
                            <ListItem
                              className={classes.ListItemInner}
                              style={{
                                transition: "none",
                                paddingBottom: 0,
                                paddingTop: 12,
                                //marginBottom: 8,
                                width: 200
                              }}
                              key={"Portfoyüm"}
                            >
                              <Typography variant="h6">{t("Portfoyüm")}</Typography>
                            </ListItem>
                          )}
                        </Link>
                      </>
                    ) : (
                      <ListItem
                        className={classes.ListItem}
                        button
                        key={"İlgilendiğim Girişimler"}
                        onClick={() => {
                          setProfileSelected(false);
                          setPipelineSelected(true);
                        }}
                      >
                        <ListItemIcon>
                          <img src={newEye} style={{width: 18}} alt="people" />
                        </ListItemIcon>
                        <Typography variant="h6">{t("İlgilendiğim Girişimler")}</Typography>
                      </ListItem>
                    )
                  ) : (
                    <>
                      {window.location.pathname === "/raporlarim" ? (
                        <Link to="/raporlarim" style={{padding: 0}}>
                          <ListItem
                            className={classes.ListItem}
                            style={{background: "#F0F6FF", borderRadius: 4}}
                            button
                            key={"Raporlarım"}
                          >
                            <ListItemIcon>
                              <InsertDriveFileOutlinedIcon style={{color: "#003CA5", fontSize: 20}} />
                            </ListItemIcon>
                            <Typography variant="h6" style={{color: "#003CA5"}}>
                              {t("Raporlarım")}
                            </Typography>
                          </ListItem>
                        </Link>
                      ) : (
                        <Link to="/raporlarim" style={{padding: 0}}>
                          <ListItem className={classes.ListItem} button key={"Raporlarım"}>
                            <ListItemIcon>
                              <InsertDriveFileOutlinedIcon style={{color: "#A0AEC0", fontSize: 20}} />
                            </ListItemIcon>
                            <Typography variant="h6">{t("Raporlarım")}</Typography>
                          </ListItem>
                        </Link>
                      )}
                    </>
                  )}
                  {/*{role === 'Investor' || role === 'SuperAdmin' || role === 'Admin' ? (
                    <Link to="/hisse-al-sat" style={{ padding: 0 }}>
                      {window.location.pathname === '/hisse-al-sat' ? (
                        <ListItem
                          className={classes.ListItem}
                          style={{ background: '#F0F6FF', borderRadius: 4 }}
                          button
                          key={'Hisse Al/Sat'}
                        >
                          <ListItemIcon>
                            <TrendingUpIcon style={{ color: '#003CA5', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6" style={{ color: '#003CA5' }}>
                            {t('Hisse Al/Sat')}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem className={classes.ListItem} button key={'Hisse Al/Sat'}>
                          <ListItemIcon>
                            <TrendingUpIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                          </ListItemIcon>
                          <Typography variant="h6">{t('Hisse Al/Sat')}</Typography>
                        </ListItem>
                      )}
                    </Link>
                  ) : (
                    <></>
                  )}*/}
                  <Link to="/SSS" style={{padding: 0}}>
                    {window.location.pathname === "/SSS" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Sıkça Sorulan Sorular"}
                      >
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#003CA5", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          {t("SSS")}
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Sıkça Sorulan Sorular"}>
                        <ListItemIcon>
                          <HelpOutlineIcon style={{color: "#A0AEC0", fontSize: 20}} />
                        </ListItemIcon>
                        <Typography variant="h6">{t("SSS")}</Typography>
                      </ListItem>
                    )}
                  </Link>

                  <hr size="1" style={{border: "0.2px solid #f5f5f5", margin: "15px 0"}} />
                  <Link to="/ayarlar" style={{padding: 0}}>
                    {window.location.pathname === "/ayarlar" ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{background: "#F0F6FF", borderRadius: 4}}
                        button
                        key={"Ayarlar"}
                      >
                        <ListItemIcon>
                          <img src={newSettingsBlue} style={{width: 16}} alt="settings" />
                        </ListItemIcon>
                        <Typography variant="h6" style={{color: "#003CA5"}}>
                          Ayarlar
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={"Ayarlar"}>
                        <ListItemIcon>
                          <img src={newSettings} style={{width: 16}} alt="settings" />
                        </ListItemIcon>
                        <Typography variant="h6">Ayarlar</Typography>
                      </ListItem>
                    )}
                  </Link>
                  {/* <Link to="/bildirimler" style={{ padding: 0 }}>
                    {window.location.pathname === '/bildirimler' ? (
                      <ListItem
                        className={classes.ListItem}
                        style={{ background: '#F0F6FF', borderRadius: 4 }}
                        button
                        key={'Bildirimler'}
                      >
                        <ListItemIcon>
                          <NotificationsNoneIcon style={{ color: '#003CA5', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6" style={{ color: '#003CA5' }}>
                          Bildirimler
                        </Typography>
                      </ListItem>
                    ) : (
                      <ListItem className={classes.ListItem} button key={'Bildirimler'}>
                        <ListItemIcon>
                          <NotificationsNoneIcon style={{ color: '#A0AEC0', fontSize: 20 }} />
                        </ListItemIcon>
                        <Typography variant="h6">Bildirimler</Typography>
                      </ListItem>
                    )}
                  </Link> */}
                </List>
              )}
            </>
          )}
          {Object.keys(user).length > 0 && (
            <>
              {role === "SuperAdmin" || role === "Admin" ? (
                <>
                  {adminMenu ? (
                    <Grid
                      container
                      style={{cursor: "pointer"}}
                      onClick={() => setAdminMenu(false)}
                      alignItems="center"
                      justify="space-between"
                      className={classes.blueField}
                    >
                      <Grid item style={{padding: 0, display: "flex", alignItems: "center"}}>
                        <Typography variant="h6" className={classes.blueFieldText}>
                          {t("Kullanıcı Olarak Gör")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowRightAltIcon style={{color: "#003CA5", marginTop: 5}} />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      style={{cursor: "pointer"}}
                      onClick={() => setAdminMenu(true)}
                      container
                      alignItems="center"
                      justify="space-between"
                      className={classes.blueField}
                    >
                      <Grid item style={{padding: 0, display: "flex", alignItems: "center"}}>
                        <Typography variant="h6" className={classes.blueFieldText}>
                          {t("Admin Olarak Gör")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowRightAltIcon style={{color: "#003CA5", marginTop: 5}} />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {role === "Investor" ? (
                    <>
                      {user.startup ? (
                        <Grid
                          container
                          alignItems="center"
                          justify="space-between"
                          className={classes.blueField}
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            handleRole("StartupContact");
                            props.setRoleChange("StartupContact");
                          }}
                        >
                          <Link
                            to={`/girisim/${user.startup.slug}`}
                            style={{padding: 0, display: "flex", alignItems: "center"}}
                          >
                            {user.startup.logo ? (
                              <img src={user.startup.logo} alt="logo" className={classes.startupLogo} />
                            ) : (
                              <Grid
                                style={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background: "#003CA5"
                                }}
                              >
                                <Typography variant="h3" style={{color: "#fff"}}>
                                  {user.startup.title && user.startup.title.charAt(0)}
                                </Typography>
                              </Grid>
                            )}
                            {user.startup.title && (
                              <Typography variant="h6" className={classes.blueFieldText}>
                                {truncateString(user.startup.title, 12)}
                              </Typography>
                            )}
                          </Link>
                          <Grid item>
                            <ArrowRightAltIcon style={{color: "#003CA5", marginTop: 5}} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Link to={`/girisim-yarat`} style={{padding: 0, display: "flex", alignItems: "center"}}>
                          <Grid container alignItems="center" style={{paddingLeft: 32}} className={classes.blueField}>
                            <AddCircleOutlineIcon style={{color: "#003CA5", cursor: "pointer"}}></AddCircleOutlineIcon>
                            <Typography variant="h6" className={classes.blueFieldText}>
                              Şirket Ekle
                            </Typography>
                          </Grid>
                        </Link>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      style={{cursor: "pointer"}}
                      alignItems="center"
                      className={classes.blueField}
                      onClick={() => {
                        handleRole("Investor");
                        props.setRoleChange("Investor");
                      }}
                    >
                      <Grid style={{display: "flex"}} alignItems="center" item>
                        <img src={bolt} alt="bolt" />
                        <Typography variant="h6" className={classes.blueFieldText}>
                          Şirketleri Keşfet
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{cursor: "pointer", background: "#fff", marginTop: 0}}
            className={classes.blueField}
          >
            <ListItem onClick={logout} style={{paddingLeft: 5}}>
              <ListItemIcon>
                <ExitToAppIcon style={{color: "#F16063", fontSize: 20}} />
              </ListItemIcon>
              <Typography variant="h6" style={{color: "#F16063", fontSize: 13}}>
                {t("Çıkış Yap")}
              </Typography>
            </ListItem>
          </Grid>
          {/* <Typography variant="body2" className={classes.bottomText}>
            Startup Borsa, bir
            <strong> Gedik Yatırım</strong> kuruluşudur
          </Typography> */}
        </Drawer>
      </Hidden>
    </div>
  );
}
