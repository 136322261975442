
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import Avatar from '../../static/img/default-avatar.png';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import moment from 'moment'
import 'moment/locale/tr'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  tabRoot:{
    minHeight:40,
    padding:'9px 12px',
    fontWeight:500
  },
  whiteCard: {
    minHeight:325,
    background:'#ffffff',
    borderRadius:'4px',
    padding: 50,
    [theme.breakpoints.down('lg')]: {
        padding: 35,
    }, 
    [theme.breakpoints.down('sm')]: {
        minHeight: '0',
        padding: 25,
    }, 
  },
  textColorP:{
    color:'#003CA5',
    borderColor:'#003CA5',
    borderRadius:4,
    marginRight:20,
    '&$selected': {
      color:'#ffffff',
    }
  },
  accordionRoot:{
    borderRadius:4,
    border:'none',
    boxShadow:'none'
  },
  accordionSummaryRoot:{
    borderRadius:4,
    boxShadow:'0 0 4px 0 rgba(0, 0, 0, 0.16)',
  },
  accordionSummaryContent:{
    margin:'0!important',
    padding:'10px 25px'
  },
  accordionDetailsRoot:{
    borderRadius:4,
    boxShadow:'0 0 4px 0 rgba(0, 0, 0, 0.16)',
    marginTop:2,
    padding:25
  },
  rightWhite:{
    background:'#ffffff',
    borderRadius:'4px',
    padding:20,
    height:'fit-content'
  },
  companyPhoto: {
    width: '50px',
    height: '50px',
    borderRadius: '4px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #eaedf3',
    marginRight:15
  },
  margin0: {
    [theme.breakpoints.down('sm')]: {
      margin:0
    },
  },
  justifyLeft: {
    [theme.breakpoints.up('sm')]: {
      justifyContent:'flex-end'
    },
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
export default function Notifications(props){
    const classes = useStyles();
    const [notify, setNotify] = React.useState();
    const [notifyId, setNotifyId] = React.useState();

    const NOTIFICATION_SUBS = gql`
      subscription notificationSent {
        notificationSent {
          id
          createdAt
          url
          message
          title
          photo
          isRead
        }
      }
    `;

    const ALL_NOTIFICATIONS = gql`
      query getAllNotifications {
        getAllNotifications{
          id
          createdAt
          title
          photo
          url
          message
          isRead
        }
      }
    `;

    const { loading, data, refetch, subscribeToMore} = useQuery(ALL_NOTIFICATIONS, {
        fetchPolicy:'network-only',
        variables: {},
    });

    React.useEffect(() => {
      if(!loading){
          if(data){
            if(!notifyId){
              subscribeToMore({
                document: NOTIFICATION_SUBS,
                updateQuery: (prev, { subscriptionData }) => {
                  if (!subscriptionData.data) return prev;
                    const newFeedItem = subscriptionData.data.notificationSent;
                    setNotifyId(newFeedItem.id)
                    return Object.assign({}, prev, {
                      getAllNotifications: [newFeedItem, ...prev.getAllNotifications]         
                    });                 
                }
              })
            }
            setNotify(data.getAllNotifications);
          }
      }
    },
    [data, loading])
    return (
            <>
                  <main
                  className={clsx(classes.content, {
                      [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', false),
                  })}
                  >
                  <Grid container style={{background:'#f7f7fc',justifyContent:'center'}}>
                        <Grid className={"main-container"} xs={12} style={{marginTop:140,height:'fit-content',maxWidth:1320}}>               
                            <Grid item style={{width:'100%'}}>
                              <Typography variant="h4" style={{fontWeight:'bold',marginBottom:50}}>Bildirimler</Typography>
                              <Grid className={classes.margin0} container spacing={4} xs={12}>
                                <Grid item  xs={12} md={9}>  
                                  <Grid item style={{minHeight:325,background:'#ffffff',borderRadius:'4px'}}>
                                    <Grid className={classes.rightWhite} xs={12}>
                                      <Grid container alignItems="center">
                                        <Typography variant="h5" style={{marginRight:10}}>Bildirimler</Typography>
                                        <Typography variant="body2">({4})</Typography>
                                      </Grid>
                                      <Grid xs={12}>
                                        <hr size="1" style={{border: '0.2px solid #f5f5f5', margin:'20px 0'}}/>
                                      </Grid>
                                      {notify && 
                                        <>
                                        {notify.map(not => (
                                          <>
                                            <Grid container justify="space-between">
                                              <Grid container alignItems="center" xs={12} md>
                                                  {not.photo ? <img src={not.photo} className={classes.companyPhoto} alt="logo" style={{objectFit:'cover'}}/> : <img src={Avatar} className={classes.companyPhoto} alt="logo" style={{objectFit:'cover'}}/>}
                                                  <Grid item xs> 
                                                    <Typography variant="h5" style={{wordBreak:'break-all',fontSize:16}} align="left">{not.title}</Typography>
                                                    <Typography variant="body2" align="left">{not.message}</Typography>
                                                  </Grid>
                                              </Grid>
                                              <Grid style={{display:'flex'}} alignItems="center" justify="flex-end" item>
                                                  <Grid item xs> 
                                                    <Link className={classes.justifyLeft} style={{padding:0,display:'flex',alignItems:'center'}} to={not.url}>
                                                      <Typography variant="h6" style={{color:'#003CA5',fontSize:14}}>İncele</Typography>
                                                      <ArrowRightAltIcon style={{color:'#003CA5',fontSize:18}} />
                                                    </Link>
                                                    <Typography variant="body2" style={{color:'#777777',fontSize:12}}>{moment(parseInt(not.createdAt)).locale('tr').startOf('day').fromNow()}</Typography> 
                                                  </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid xs={12}>
                                              <hr size="1" style={{border: '0.2px solid #f5f5f5', margin:'20px 0'}}/>
                                            </Grid> 
                                          </>
                                        ))}
                                        </>
                                      }
                                    </Grid>         
                                  </Grid>  
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <Grid item className={classes.whiteCard} style={{textAlign:'center',padding:30}}>               
                                    <Typography variant="h5" style={{lineHeight:'1.75'}} color='primary'>Aradığın sorunun cevabını bulamadın mı?</Typography>
                                    <Typography variant="body2" style={{lineHeight:'2',marginTop:15}}>Sistem yöneticilerine mesaj göndererek sorun yaşadığınız konunun çözümüne veya bilgi almak istediğiniz detaylara kolayca ulaşabilirsiniz.</Typography>
                                    <Button variant="containedPrimary" style={{fontSize:14,padding:'10.5px 20px',marginTop:20,fontWeight:'normal'}}>Mesaj Gönder</Button>
                                  </Grid>
                                </Grid>        
                              </Grid>     
                            </Grid>                           
                        </Grid>
                </Grid>
            </main>        
        </>
    )
}