import {Avatar, Grid, makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import React from "react";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 24,
    marginLeft: 8,
    marginRight: 8,
    width: 24
  },
  card: {
    background: theme.palette.primary.main,
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(133, 133, 133, 0.10023)",
    color: "#ffffff",
    maxWidth: "63%",
    height: "fit-content",
    padding: "16px 8px",
    width: "fit-content"
  },
  main: {
    padding: "8px 0px",
    height: "fit-content"
  }
}));

export default function SendMessageCard({data}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.main}>
      <Grid container direction="row-reverse">
        <Grid className={classes.card}>
          <Typography style={{color: "#ffffff"}}>{data.message}</Typography>
          <Grid container justify="flex-end">
            <small> {moment.utc(data.createdAt, "x").local().fromNow()}</small>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
