import * as compose from 'lodash.flowright';

import DecideComponent from './DecideComponent';
import { HEADER_LEFT_DRAWER } from '../../queries';
import { graphql } from 'react-apollo';

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
)(DecideComponent);

export default JoinWithMutation;
