import { Button, Grid, Snackbar, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import ExcelSvg from '../../../assets/svgs/excel.svg';
import PdfSvg from '../../../assets/svgs/pdf.svg';
import PptxSvg from '../../../assets/svgs/pptx.svg';
import WordSvg from '../../../assets/svgs/word.svg';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    margin: '0px 26px 24px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 0px 24px 0px',
    },
    border: '1px dashed #CBD5E0',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
    boxSizing: 'border-box',
    borderRadius: 16,
    height: 250,
  },
  uploadedFileContainer: {
    wordBreak: 'break-all',

    margin: '16px 2px',
    [theme.breakpoints.up('sm')]: {
      margin: 16,
    },
    maxHeight: 150,
    [theme.breakpoints.up('md')]: {
      maxHeight: 110,
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: 100,
    },
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: 10,
  },
  a: {
    textAlign: 'left !important',
  },
}));

function UploadDocumentsStage({
  registerNewspaperDocs,
  mainContractDocs,
  taxPlateDocs,
  operatingCertificateDocs,
  setRegisterNewspaperDocs,
  setMainContractDocs,
  setTaxPlateDocs,
  setOperatingCertificateDocs,
  setDisableNextButton,
  succesfullyEditedSnackbar,
  setSuccesfullyEditedSnackbar,
}) {
  const classes = useStyles();
  //input ref hooks for uploading data
  const registerNewspaperInput = useRef(null);
  const mainContractInput = useRef(null);
  const taxPlateInput = useRef(null);
  const operatingCertificateInput = useRef(null);

  //for displaying uploaded data TODO: add multiple data upload support if needed
  const [registerNewspaperFileDisplay, setRegisterNewspaperFileDisplay] = useState({});
  const [mainContractFileDisplay, setMainContractFileDisplay] = useState({});
  const [taxPlateFileDisplay, setTaxPlateFileDisplay] = useState({});
  const [operatingCertificateFileDisplay, setOperatingCertificateFileDisplay] = useState({});
  const [invalidDocAlert, setInvalidDocAlert] = useState(false);
  const [missingDocAlert, setMissingDocAlert] = useState(false);

  React.useEffect(() => {
    setDisableNextButton(true);

    if (registerNewspaperDocs[0]?.file)
      setRegisterNewspaperFileDisplay({
        fileUrl: registerNewspaperDocs[0]?.file,
        title: registerNewspaperDocs[0]?.title,
        lastEdit: registerNewspaperDocs[0]?.date?.split('T')[0],
        size: (registerNewspaperDocs[0]?.fileSize / (1024 * 1024)).toFixed(2),
        svg: getSvgFromFileOrExtension(null, registerNewspaperDocs[0]?.extension),
      });
    if (mainContractDocs[0]?.file)
      setMainContractFileDisplay({
        fileUrl: mainContractDocs[0]?.file,
        title: mainContractDocs[0]?.title,
        lastEdit: mainContractDocs[0]?.date?.split('T')[0],
        size: (mainContractDocs[0]?.fileSize / (1024 * 1024)).toFixed(2),
        svg: getSvgFromFileOrExtension(null, mainContractDocs[0]?.extension),
      });

    if (taxPlateDocs[0]?.file)
      setTaxPlateFileDisplay({
        fileUrl: taxPlateDocs[0]?.file,
        title: taxPlateDocs[0]?.title,
        lastEdit: taxPlateDocs[0]?.date?.split('T')[0],
        size: (taxPlateDocs[0]?.fileSize / (1024 * 1024)).toFixed(2),
        svg: getSvgFromFileOrExtension(null, taxPlateDocs[0]?.extension),
      });

    if (operatingCertificateDocs[0]?.file)
      setOperatingCertificateFileDisplay({
        fileUrl: operatingCertificateDocs[0]?.file,
        title: operatingCertificateDocs[0]?.title,
        lastEdit: operatingCertificateDocs[0]?.date?.split('T')[0],
        size: (operatingCertificateDocs[0]?.fileSize / (1024 * 1024)).toFixed(2),
        svg: getSvgFromFileOrExtension(null, operatingCertificateDocs[0]?.extension),
      });
  }, []);

  React.useEffect(() => {
    if (
      mainContractDocs[0]?.file &&
      taxPlateDocs[0]?.file &&
      registerNewspaperDocs[0]?.file &&
      operatingCertificateDocs[0]?.file
    ) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
      setMissingDocAlert(true);
    }
  }, [registerNewspaperDocs, mainContractDocs, taxPlateDocs, operatingCertificateDocs]);
  //handlers and helpers
  function getSvgFromFileOrExtension(file, extension) {
    let strType = extension?.slice(1);
    if (file) strType = file.type?.split('/')[1];

    switch (strType) {
      case 'pdf':
      case 'png':
      case 'jpeg':
        return PdfSvg;
      case 'ppt':
      case 'pptx':
      case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'vnd.ms-powerpoint':
        return PptxSvg;
      case 'doc':
      case 'docx':
      case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'msword':
        return WordSvg;
      case 'xls':
      case 'xlsx':
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'vnd.ms-excel':
        return ExcelSvg;
      default:
        return null;
    }
  }

  //10mb max file size
  const maxFileSize = 10 * 1024 * 1024;
  function checkFileValidity(file) {
    //max 10 mb
    if (file.size > maxFileSize) return false;

    //if null, return false
    if (!getSvgFromFileOrExtension(file)) return false;

    return true;
  }

  function handleUpload(e, uploadName) {
    const file = e.target.files[0];
    if (!checkFileValidity(file)) {
      setInvalidDocAlert(true);
      return;
    }

    const filePropertiesForDisplay = {
      fileUrl: URL.createObjectURL(file),
      title: file.name,
      lastEdit: file.lastModifiedDate.getDate(),
      size: (file.size / (1024 * 1024)).toFixed(2),
      svg: getSvgFromFileOrExtension(file),
    };

    switch (uploadName) {
      case 'Şirket Güncel Esas Sözleşmesi':
        setRegisterNewspaperFileDisplay(filePropertiesForDisplay);
        setRegisterNewspaperDocs([{ file: file }]);
        return;
      case 'Faaliyet Belgesi':
        setMainContractFileDisplay(filePropertiesForDisplay);
        setMainContractDocs([{ file: file }]);
        return;
      case 'Şirket Pay Defteri':
        setTaxPlateFileDisplay(filePropertiesForDisplay);
        setTaxPlateDocs([{ file: file }]);
        return;
      case 'Şirket İmza Sirküleri':
        setOperatingCertificateFileDisplay(filePropertiesForDisplay);
        setOperatingCertificateDocs([{ file: file }]);
        return;
    }
  }

  function handleDelete(uploadName) {
    switch (uploadName) {
      case 'Şirket Güncel Esas Sözleşmesi':
        setRegisterNewspaperFileDisplay({});
        setRegisterNewspaperDocs([]);
        return;
      case 'Faaliyet Belgesi':
        setMainContractFileDisplay({});
        setMainContractDocs([]);
        return;
      case 'Şirket Pay Defteri':
        setTaxPlateFileDisplay({});
        setTaxPlateDocs([]);
        return;
      case 'Şirket İmza Sirküleri':
        setOperatingCertificateFileDisplay({});
        setOperatingCertificateDocs([]);
        return;
    }
  }

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      {[
        {
          title: 'Şirket Güncel Esas Sözleşmesi',
          ref: registerNewspaperInput,
          fileDisplayData: registerNewspaperFileDisplay,
          //show files if they already exist in db or newly uploaded
          showFiles: !!registerNewspaperDocs[0]?.file,
        },
        {
          title: 'Faaliyet Belgesi',
          ref: mainContractInput,
          fileDisplayData: mainContractFileDisplay,
          showFiles: !!mainContractDocs[0]?.file,
        },
        {
          title: 'Şirket Pay Defteri',
          ref: taxPlateInput,
          fileDisplayData: taxPlateFileDisplay,
          showFiles: !!taxPlateDocs[0]?.file,
        },
        {
          title: 'Şirket İmza Sirküleri',
          ref: operatingCertificateInput,
          fileDisplayData: operatingCertificateFileDisplay,
          showFiles: !!operatingCertificateDocs[0]?.file,
        },
      ].map((el, idx) => (
        <Grid
          className={classes.uploadContainer}
          item
          container
          xs={12}
          md={5}
          style={{ marginTop: idx < 2 ? '24px' : '0' }}
        >
          <Grid item style={{ margin: '16px 0 0 16px' }}>
            <Typography style={{ fontWeight: 'bold' }}>{el.title}</Typography>
          </Grid>
          {/*show files ? show already uploaded data : show upload new data view*/}
          {el.showFiles ? (
            <Grid container item xs={12} md={11} className={classes.uploadedFileContainer}>
              <Grid container item style={{ margin: 16 }}>
                <Grid xs={12} md={3} item>
                  <img src={el.fileDisplayData.svg} width="70" alt="uploaded file" />
                </Grid>
                <Grid container xs={12} md={9} item style={{ flexDirection: 'column' }}>
                  <Grid item>
                    <a
                      className={classes.a}
                      style={{
                        color: '#118DFF',
                        fontSize: 14,
                        fontWeight: 'bold',
                        paddingLeft: 0,
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '1',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingTop: 0,
                      }}
                      download
                      type="button"
                      href={el.fileDisplayData.fileUrl}
                    >
                      {el.fileDisplayData.title}
                    </a>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="span"
                      style={{ fontSize: 12 }}
                    >{`${el.fileDisplayData.lastEdit} - ${el.fileDisplayData.size} MB`}</Typography>
                  </Grid>
                  <Grid item container>
                    <Grid item>
                      <a
                        type="button"
                        download
                        style={{ fontSize: 12, paddingLeft: 0 }}
                        href={el.fileDisplayData.fileUrl}
                      >
                        Görüntüle
                      </a>
                    </Grid>
                    <Grid item>
                      <a
                        type="button"
                        style={{ fontSize: 12, paddingLeft: 0, cursor: 'pointer' }}
                        onClick={() => handleDelete(el.title)}
                      >
                        Sil
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              style={{ flexDirection: 'column', marginBottom: 62 }}
            >
              <Grid item>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <circle cx="32" cy="32" r="32" fill="#F0F5FF" />
                  <path
                    d="M29 33H35M32 30V36M37 41H27C26.4696 41 25.9609 40.7893 25.5858 40.4142C25.2107 40.0391 25 39.5304 25 39V25C25 24.4696 25.2107 23.9609 25.5858 23.5858C25.9609 23.2107 26.4696 23 27 23H32.586C32.8512 23.0001 33.1055 23.1055 33.293 23.293L38.707 28.707C38.8946 28.8945 38.9999 29.1488 39 29.414V39C39 39.5304 38.7893 40.0391 38.4142 40.4142C38.0391 40.7893 37.5304 41 37 41Z"
                    stroke="#003CA5"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Grid>
              <Grid item style={{ marginTop: '4px' }}>
                (Doc, PPT, PDF Desteklemektedir. )
              </Grid>
              <Grid item>
                <Button
                  onClick={() => el.ref.current.click()}
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: '20px' }}
                >
                  Dosya Yükle
                </Button>
              </Grid>
              <input type="file" ref={el.ref} onChange={(e) => handleUpload(e, el.title)} style={{ display: 'none' }} />
            </Grid>
          )}
        </Grid>
      ))}
      {/* <Grid
        className={classes.uploadContainer}
        style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        item
        container
        xs={12}
        md={5}
      >
        <Grid item>
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <circle cx="32" cy="32" r="32" fill="#FFF3E6" />
            <path
              d="M29 33H35M32 30V36M37 41H27C26.4696 41 25.9609 40.7893 25.5858 40.4142C25.2107 40.0391 25 39.5304 25 39V25C25 24.4696 25.2107 23.9609 25.5858 23.5858C25.9609 23.2107 26.4696 23 27 23H32.586C32.8512 23.0001 33.1055 23.1055 33.293 23.293L38.707 28.707C38.8946 28.8945 38.9999 29.1488 39 29.414V39C39 39.5304 38.7893 40.0391 38.4142 40.4142C38.0391 40.7893 37.5304 41 37 41Z"
              stroke="#FF9B28"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Grid>
        <Grid item style={{ marginTop: '4px' }}>
          <Typography style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
            Bilgileri daha sonra güncellemek için, <br />
            <Link to="/ayarlar" style={{ fontSize: 16, color: '#FF9B28', fontWeight: 'bold' }}>
              {'Ayarlar > Şirket'}
            </Link>
            belgeleri sekmesinden <br /> düzenleme yapabilirsiniz.
          </Typography>
        </Grid>
      </Grid> */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setInvalidDocAlert(false)}
        open={invalidDocAlert}
      >
        <Alert severity="error">Uygunsuz dosya boyutu (Max 10MB) veya türü</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => setMissingDocAlert(false)}
        open={missingDocAlert}
      >
        <Alert severity="warning">Lütfen Tüm Alanlara Gerekli Dosyaları Yükleyin</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setSuccesfullyEditedSnackbar(false)}
        open={succesfullyEditedSnackbar}
      >
        <Alert severity="success">Form Başarıyla Düzenlendi</Alert>
      </Snackbar>
    </Grid>
  );
}

export default UploadDocumentsStage;
