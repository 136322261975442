import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import AdminComments from './AdminComments';

const EDIT_COMMENT = gql`
  mutation adminHandleStartupComment($commentIds: [ID!], $status: String!) {
    adminHandleStartupComment(commentIds: $commentIds, status: $status) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(EDIT_COMMENT, {
    name: 'adminHandleStartupComment',
  }),
)(AdminComments);
export default JoinWithMutation;