import Dashboard from './Dashboard'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const REPORT_GUIDE = gql`
  mutation userGuideChangeStatus($type: String!, $status: String!) {
    userGuideChangeStatus(type: $type, status: $status) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(REPORT_GUIDE, {
    name: 'userGuideChangeStatus',
  }),
)(Dashboard);
export default JoinWithMutation;