import React from "react"
import { Grid, Typography, Hidden, Button } from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { signupSchema } from '../../yupSchemas';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormikTextField from "../../Components/FormikShortTextFieldSignUp";
import FormikProvinceDropdown from "../../Components/FormikProvinceDropdown";
import logo from '../../static/img/logo2.png';
import Snackbar from "@material-ui/core/Snackbar";
import { Form, Formik, Field } from 'formik';
import newCross from '../../static/icon/new-cross.svg';
import 'react-phone-input-2/lib/style.css'
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useAuth } from "../../context/auth";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ReactPhoneInput from "react-phone-input-2";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import authBackground from '../../static/img/new-auth-background.jpg';
import authLogo from '../../static/img/new-auth-logo.png';
import newAuth1 from '../../static/img/new-auth-1.png';
import newAuth2 from '../../static/img/new-auth-2.png';
import newAuth3 from '../../static/img/new-auth-3.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import process from 'process';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    display: 'block'
  },
  link: {
    textDecoration: 'none',
    color: '#777777',
    padding: 0,
    fontWeight: 'normal',
    marginTop: 20

  },
  leftGrid: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100vh',
      overflowY: 'auto'
    },
    backgroundImage: 'url(' + authBackground + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  logoGrid: {
    height: 'fit-content',
    [theme.breakpoints.up('xl')]: {
      padding: 80,
      paddingBottom: 0,
      paddingTop: '35px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      paddingTop: '35px',
    },
    padding: 40,
  },
  mainGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  newAround: {
    marginTop: 35,
    color: '#A0AEC0'
  },
  signUp: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 30
    },
    marginTop: 20
  },
  loginGrid: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },
  },
  logo: {
    width: 120,
  },
  firstLogo: {
    width: 200,
  },
  reactTelInput: {
    '& input': {
      width: '100%!important',
    },
  },
  welcomeText: {
    color: '#27272E',
    marginTop: 6,
  },
  EmailText: {
    [theme.breakpoints.up('xl')]: {
      marginTop: 50
    },
    marginTop: 30,
    fontWeight: 600
  },
  PasswordText: {
    fontWeight: 600
  },
  signLink: {
    fontWeight: 'normal',
    color: '#172b4d',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: 20
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  mobileFont: {
    fontWeight: 'normal',
    color: '#172b4d',
    padding: 0,
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15
  },
}));

function InfoDialog(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { onClose, open } = props;

  const handleListItemClick = () => {
    onClose();
  };
  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog maxWidth={'md'} fullWidth={true} aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title" style={{ marginTop: 16 }}>
        <Typography style={{ fontSize: 28 }} variant="h3" align="center">{t('Sıradaki yatırımcınla tanış!')}</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ padding: 16 }}>
        <Typography variant="body2" align="center" style={{ fontSize: 16 }}>
        Yeni yatırımcılarla tanışırken aşağıdaki adımları takip edeceğiz.
        </Typography>
        <Grid container xs={12} md={8} style={{ marginTop:24 }}>
          <VerticalTimeline layout="1-column-left">
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#27272E', paddingTop: 0 }}
              iconStyle={{ background: '#003CA5', color: '#fff', display:'flex', alignItems:'center', justifyContent:'center', boxShadow:'none' }}
              icon={
                <Typography variant="h6" align="center" style={{ fontSize: 12, color: '#fff' }}>
                  01
                </Typography>
              }
            >
              <Typography variant="h6">{t('Profilinizi Oluşturun ve Şirket Bilgilerinizi Tamamlayın')}</Typography>
              <Typography variant="body2" style={{ marginTop:8, color:'#718096', fontSize:12 }}>Hesabınız üzerinden şirket ekleyerek şirket profilinizi oluşturmanız gerekiyor. Şirket doluluk oranınız, %50 barajını geçtiğinde girişiminiz listelenmeye başlar ve böylece yatırımcılarını ilgisini çekebilirsiniz.</Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#27272E', paddingTop: 0, marginTop:8 }}
              iconStyle={{ background: '#F0F5FF', color: '#fff', display:'flex', alignItems:'center', justifyContent:'center', boxShadow:'none' }}
              icon={
                <Typography variant="h6" align="center" style={{ fontSize: 12, color: '#003CA5' }}>
                  02
                </Typography>
              }
            >
              <Typography variant="h6">{t('Yatırımcıları Keşfedin')}</Typography>
              <Typography variant="body2" style={{ marginTop:8, color:'#718096', fontSize:12 }}>Platformda öne çıkan yatırımcıların profillerini inceleyin. Size en uygun olduğunu düşündüğünüz yatırımcı ile birebir iletişime geçin.</Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#27272E', paddingTop: 0, marginTop:8 }}
              iconStyle={{ background: '#F0F5FF', color: '#fff', display:'flex', alignItems:'center', justifyContent:'center', boxShadow:'none' }}
              icon={
                <Typography variant="h6" align="center" style={{ fontSize: 12, color: '#003CA5' }}>
                  03
                </Typography>
              }
            >
              <Typography variant="h6">{t('İletişim Detaylarını Tamamlayın')}</Typography>
              <Typography variant="body2" style={{ marginTop:8, color:'#718096', fontSize:12 }}>Yatırımcıların girişiminiz hakkında daha detaylı bilgi sahibi olabilmesi için detaylı bilgilerinizi paylaşın.</Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#fff', color: '#27272E', paddingTop: 0, marginTop:8 }}
              iconStyle={{ background: '#F0F5FF', color: '#fff', display:'flex', alignItems:'center', justifyContent:'center', boxShadow:'none' }}
              icon={
                <Typography variant="h6" align="center" style={{ fontSize: 12, color: '#003CA5' }}>
                  04
                </Typography>
              }
            >
              <Typography variant="h6">{t('Yatırımcılar Sizi Keşfetsin')}</Typography>
              <Typography variant="body2" style={{ marginTop:8, color:'#718096', fontSize:12 }}>Yatırımcılar ile birebir görüşmeye başlayın. Onlara raporlarınızı yollayın ve yatırımcı ilişkilerinizi bir sonraki aşamaya taşıyın.</Typography>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Grid>
        <Grid container justify="center">
          <Button
            variant="containedPrimary"
            style={{ width: '100%', maxWidth: 424, height: 46, fontSize: 14, marginTop: 32 }}
            onClick={handleListItemClick}
          >
            {t('Şimdi başlayın')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default function SignUp(props) {
  const { setAuthToken } = useAuth();
  const { t, i18n } = useTranslation();
  document.body.style = 'background: #ffffff;margin:0';
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [error, setError] = React.useState('');
  const classes = useStyles();
  const [firstPage, setFirstPage] = React.useState(false);
  const [city, setCity] = React.useState([]);
  const [serverMessage, setServerMessage] = React.useState({
    detail: '',
    message: '',
    type: false,
  });
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };
  const ALL_CITIES = gql`
      query allCities($country:String) {
          allCities(country:$country){
              id
              title
          }        
      }
    `;
  const { loading: loading2, data: data2 } = useQuery(ALL_CITIES, {
    variables: { country: 'TR' },
  });

  if (window.location.href.indexOf("?") > -1 && firstPage === false) {
    setFirstPage(true)
  }

  React.useEffect(() => {
    if (!loading2) {
      if (data2) {
        setCity(data2.allCities)
      }
    }
    if (props.location.state && props.location.state.openPopup && !showPopup) {
      setShowPopup(true);
    }
  },
    [data2, loading2])

  const FormikCheckBox = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <FormControlLabel style={{ marginRight: 0 }} control={<Checkbox {...field} checked={field.value} color="primary" />} />
        {label}
      </>
    );
  };
  const handleSubmit = async variablesAll => {
    const { passwordConfirm, ...variables } = variablesAll;
    if (variables.city !== null) {
      variables.city = variables.city.id
    }
    variables.country = 227
    props.register({ variables })
      .then(data => {
        if (data.data.register) {
          const token = data.data.register.token;
          setAuthToken(token);
          if (window.location.origin.includes('dashboard')){
            try {
              window.gist.identify(data.data.register.user.id.toString(), {
                "email": data.data.register.user.email,
              });
            }catch {}
          }
          props.history.push(`/dashboard/?utm_event=signup`);
        }
      }).then(() => {
        setIsSubmitting(false);
      }).catch(error => {
        if (error.toString().includes("Not a valid code.")) {
          setError('Hatalı bir kod girdiniz.');
          setServerSuccess(true);
        }
        else {
          setError('Sistemde kayıtlı olan bir e-posta adresi girdiniz.');
          setServerSuccess(true);
        }
      });
  };

  return (
    <Grid container style={{ height: '100vh' }}>
      <InfoDialog open={showPopup} onClose={() => setShowPopup(false)} />
      <Grid container style={{ height: '100vh', overflow: 'auto' }} item lg={6} md={12} xs={12}>
        {firstPage ? (
          <Grid xs={12} container itemalignItems="center" className={classes.logoGrid}>
            {window.location.href.includes('?') ? (
              <Link to={'/login'} style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                <KeyboardBackspaceIcon style={{ marginRight: 5 }} />
                <Typography variant="body1">{t('Geri Dön')}</Typography>
              </Link>
            ) : (
              <Link
                onClick={() => setFirstPage(false)}
                style={{ padding: 0, display: 'flex', alignItems: 'center' }}
              >
                <KeyboardBackspaceIcon style={{ marginRight: 5 }} />
                <Typography variant="body1">{t('Geri Dön')}</Typography>
              </Link>
            )}
          </Grid>
        ) : (
          <Grid xs={12} container item justify="space-between" alignItems="center"></Grid>
        )}
        <Grid xs={12} alignItems="center" className={classes.loginGrid} container item>
          {firstPage ? (
            <Grid className={classes.mainGrid} item sm={12} xs={11}>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  password: '',
                  passwordConfirm: '',
                  city: '',
                  userAgreement: false,
                  confidentialityAgreement: false,
                  marketingAgreement: false,
                }}
                validationSchema={signupSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {(props) => (
                  <Form>
                    <Typography variant="h2" align="left" style={{ paddingLeft: 6 }}>
                      {t('Kayıt Ol')}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      className={classes.welcomeText}
                      style={{ paddingLeft: 6 }}
                    >
                      {t('Lütfen hesabınızı oluşturmak için formu doldurun.')}
                    </Typography>
                    <Grid container xs={12} item spacing={3} style={{ margin: '30px 0 0 0' }}>
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Typography variant="h6">
                          {t('Ad')}
                          <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          className={classes.inputField}
                          name="firstName"
                          placeholder={t('Ad')}
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Typography variant="h6">
                          {t('Soyad')}
                          <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          className={classes.inputField}
                          name="lastName"
                          placeholder={t('Soyad')}
                        ></FormikTextField>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 12px' }}>
                      <Typography variant="h6">
                        {t('E-posta')}
                        <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                      </Typography>
                      <FormikTextField name="email" placeholder={t('E-postanızı girin')}></FormikTextField>
                    </Grid>
                    <Grid container xs={12} item spacing={3} style={{ margin: 0 }}>
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Typography variant="h6">
                          {t('Şifre')}
                          <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          className={classes.inputField}
                          name="password"
                          type="password"
                          placeholder={t('Şifrenizi Oluşturun')}
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Typography variant="h6">
                          {t('Şifreyi Tekrarlayın')}
                          <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          className={classes.inputField}
                          name="passwordConfirm"
                          type="password"
                          placeholder="Şifrenizi Girin"
                        ></FormikTextField>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} item spacing={3} style={{ margin: 0 }}>
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Typography variant="h6">
                          {t('Konum')}
                          <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <Field
                          component={FormikProvinceDropdown}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="city"
                          options={city}
                        ></Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">
                          {t('Telefon')}
                          <span style={{ color: '#05f', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <Field name="phoneNumber">
                          {({ field, form, meta }) => {
                            const { name, value } = field;
                            return (
                              <>
                                <div>
                                  <Grid style={{ minHeight: 70 }}>
                                    <ReactPhoneInput
                                      name={name}
                                      value={value}
                                      style={{ marginTop: 10, borderRadius: 7 }}
                                      className={classes.inputField}
                                      onChange={(e) => form.setFieldValue('phoneNumber', e)}
                                      country={'tr'}
                                    />
                                    {meta.touched && meta.error ? (
                                      <Typography
                                        style={{
                                          color: '#FF0000',
                                          fontSize: '12px',
                                          marginBottom: 4,
                                          marginTop: 4,
                                        }}
                                      >
                                        {meta.error}
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                </div>
                              </>
                            );
                          }}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0 12px' }}>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap' }}>
                        <FormikCheckBox
                          name="userAgreement"
                          type="Checkbox"
                          label={
                            <a
                              target="_blank"
                              href="https://startupborsa.com/kullanici-sozlesmesi/"
                              style={{
                                fontSize: 12,
                                padding: 0,
                                paddingTop: 14,
                                textAlign: 'left',
                                textDecoration: 'underline',
                              }}
                            >
                              {t("Kullanıcı Sözleşmesi'ni okudum ve onaylıyorum.")}
                            </a>
                          }
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap' }}>
                        <FormikCheckBox
                          name="confidentialityAgreement"
                          type="Checkbox"
                          label={
                            <a
                              target="_blank"
                              href="https://startupborsa.com/gizlilik-politikasi-ve-kisisel-veriler-metni/"
                              style={{
                                fontSize: 12,
                                padding: 0,
                                paddingTop: 14,
                                textAlign: 'left',
                                textDecoration: 'underline',
                              }}
                            >
                              {t("Gizlilik Politikası ve Kişisel Veriler Metni'ni okudum ve onaylıyorum.")}
                            </a>
                          }
                        />
                      </Grid>
                      <Grid item container xs={12} style={{ paddingTop: 0, flexWrap: 'nowrap' }}>
                        <FormikCheckBox
                          name="marketingAgreement"
                          type="Checkbox"
                          label={
                            <a style={{ fontSize: 12, padding: 0, paddingTop: 14, textAlign: 'left' }}>
                              {t('Startup Borsa tarafından Kişisel Veriler Metni’nde belirtilen amaç ve sınırlamalar doğrultusunda elektronik ileti gönderilmesine izin veriyorum.')}
                            </a>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginTop: 24, padding: '0 12px' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: '600', width: '100%' }}
                        type="submit"
                        disabled={
                          !props.isValid ||
                          (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)
                        }
                      >
                        {t('Kayıt Ol')}
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>

              <Snackbar autoHideDuration={6000} onClose={() => setServerSuccess(false)} open={serverSuccess}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
              <Grid container justify="center">
                <Typography className={classes.newAround} style={{ marginBottom: 30 }} variant="h6" align="center">
                  Hesabınız var mı?{' '}
                  <Link to="/login/?=s" style={{ color: '#003CA5', fontWeight: '600', padding: 0, fontSize: 14 }}>
                    Giriş Yap
                        </Link>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.mainGrid} item sm={12} xs={11}>
              <Grid style={{ textAlign: 'center' }}>
                <img src={logo} alt="startup-borsa" className={classes.firstLogo}></img>
                <Grid item container xs={12} justify="center">
                  <Grid item container xs={7} justify="center">
                    <Typography variant="body1" align="center" className={classes.welcomeText}>
                      {t('Girişimci ve yatırımcıların dijitalde buluşma noktasına hoşgeldiniz.')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify="center" style={{ marginTop: 30 }}>
                  <Button
                    variant="containedPrimary"
                    onClick={() => setFirstPage(true)}
                    style={{ fontWeight: '600' }}
                  >
                    {t('Kayıt Ol')}
                  </Button>
                </Grid>
                <Grid container justify="center">
                  <Typography
                    className={classes.newAround}
                    style={{ marginBottom: 30 }}
                    variant="h6"
                    align="center"
                  >
                    Hesabınız var mı?{' '}
                    <Link to="/login/?=s" style={{ color: '#003CA5', fontWeight: '600', padding: 0, fontSize: 14 }}>
                      Giriş Yap
                          </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Typography className={classes.newAround} style={{ marginBottom: 40 }} variant="h6" align="center">
                  {t('Startup Borsa, bir')}{' '}
                  <a
                    href="https://www.gedik.com/"
                    target="_blank"
                    rel="nofollow"
                    style={{ color: '#003CA5', fontWeight: '600', padding: 0 }}
                  >
                    Gedik Yatırım
                        </a>{' '}
                  {t('kuruluşudur.')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid item sm={6} xs={12}>
          <Grid className={classes.leftGrid}>
            <Grid container justify="flex-end" style={{ padding: 50 }}>
              <img src={authLogo} alt="authlogo" />
            </Grid>
            <Grid item style={{ marginBottom: 80 }}>
              <Slider {...settings} style={{ textAlign: 'center' }}>
                <Grid item>
                  <Grid container justify="center" style={{ marginTop: 60 }}>
                    <img src={newAuth1} alt="auth1" style={{ width: 350 }} />
                  </Grid>
                  <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                    Yatırımcı ilişkilerini yönet
                        </Typography>
                  <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                    Girişiminizin gelişmelerini tek bir <br /> noktadan takip edebilir, yatırımcılarınız ile{' '}
                    <br /> kolayca paylaşabilirsiniz.
                        </Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                  <Grid container justify="center">
                    <img src={newAuth2} alt="auth2" style={{ width: 480 }} />
                  </Grid>
                  <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                    Türkiyenin en geniş yatırımcı <br /> ağına ulaş!
                        </Typography>
                  <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                    Girişimcilik ekosisteminin deneyimli isimleriyle birlikte
                          <br /> çalışma ve iletişime geçme fırsatlarını yakala.
                        </Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                  <Grid container justify="center" style={{ marginTop: 150 }}>
                    <img src={newAuth3} alt="auth3" style={{ width: 580 }} />
                  </Grid>
                  <Typography variant="h2" style={{ color: '#fff', marginTop: 30 }}>
                    Yatırımcı ilişkilerini <br />
                          tek bir noktadan sürdür!
                  </Typography>
                  <Typography variant="body2" style={{ color: '#fff', marginBottom: 30, marginTop: 10 }}>
                    Birçok yatırımcıyla yapacağın paylaşımları, <br />
                        veri takibini ve ortaklık tablonu tek <br /> kanaldan takip et.
                  </Typography>
                </Grid>
              </Slider>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}