import { Avatar, Grid, Hidden, makeStyles, Typography, withWidth } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { useTempConversation } from "../../../context/tempConversation";
import ReceiveMessageCard from "./ReceiveMessageCard";
import SendMessageCard from "./SendMessageCard";
import SendMessageInput from "./SendMessageInput";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 40,
    marginRight: 16,
    width: 40
  },

  italic: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontStyle: "italic"
  },
  link: {
    display: "flex",
    textDecoration: "unset"
  },
  messagesGrid: {
    background: "FFFFFF",
    flex: "1 1 0",
    padding: "12px 20px 0px",
    overflow: "auto",
  },
  profileInfo: {
    borderBottom: "2px solid #EDF2F7",
    padding: "0px 24px",
    height: 66,
  },
  title: {
    height: "100%"
  }
}));

function InboxContainer({selectedConversation, setSelectedConversation, selectedAllMessages, userId}) {
  const classes = useStyles();
  const {temporaryConversation, receiverUser} = useTempConversation();

  const receivedUser = (temporaryConversation? receiverUser: selectedConversation?.users?.[0]);

  return (
    <Grid container justify="center" className={classes.title}>
      <Grid container direction="column" justify="space-between">
        {(!selectedConversation && !temporaryConversation)? (
          <Grid container justify="center" alignItems="center" className={classes.messagesGrid}>
            {/*<Typography> Mesajlarınız burada görünür </Typography>*/}
          </Grid>
        ) : (
          <>
            <Grid container className={classes.profileInfo}>
              <Grid container alignItems="center" justify="flex-start">
                <Grid item>
                  <Hidden smUp>
                    <ArrowBackIcon style={{marginRight: 12}} onClick={() => setSelectedConversation(null)} />
                  </Hidden>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar} src={receivedUser?.profilePhoto} />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1"> {receivedUser?.firstName + " " + receivedUser?.lastName} </Typography>
                </Grid>
                
                {/* {false && <Grid item>
                  <IconButton onClick={openDeleteDialog}>
                    <svg width="16" height="16" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17 23.3333V39.3333M27 23.3333V39.3333M2 12.6667H42M39.5 12.6667L37.3325 45.0453C37.2427 46.3909 36.6782 47.6502 35.7528 48.5695C34.8273 49.4889 33.6097 50 32.345 50H11.655C10.3903 50 9.17267 49.4889 8.24721 48.5695C7.32176 47.6502 6.75729 46.3909 6.6675 45.0453L4.5 12.6667H39.5ZM29.5 12.6667V4.66667C29.5 3.95942 29.2366 3.28115 28.7678 2.78105C28.2989 2.28095 27.663 2 27 2H17C16.337 2 15.7011 2.28095 15.2322 2.78105C14.7634 3.28115 14.5 3.95942 14.5 4.66667V12.6667H29.5Z" stroke="#000000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </IconButton>
                </Grid>} */}
              </Grid>
            </Grid>
            <Grid container className={classes.messagesGrid} id="text-screen">
              <Grid style={{width: "100%"}}>
                {selectedAllMessages?.map(data =>
                  data.sender.id === userId ? (
                    <SendMessageCard data={data}></SendMessageCard>
                  ) : (
                    <ReceiveMessageCard userId={userId} data={data}></ReceiveMessageCard>
                  )
                )}
              </Grid>
            </Grid>
            <SendMessageInput 
              conversationId={selectedConversation?.id} 
              temporaryConversation={temporaryConversation} 
              userId={userId}
              receivedUserId={receivedUser?.id}
            />{" "}
          </>
        )}
      </Grid>
    </Grid>
  );
}
export default withWidth()(InboxContainer);
