import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import InvestmentRequests from './InvestmentRequests';

const INVESTOR_RESPONSE = gql`
  mutation investorResponseInvestmentRequest($reqId: ID!, $status: String!, $communicationMethod: String, $responseExplanation: String) {
    investorResponseInvestmentRequest(reqId: $reqId, status: $status, communicationMethod: $communicationMethod, responseExplanation: $responseExplanation) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(INVESTOR_RESPONSE, {
    name: 'investorResponseInvestmentRequest',
  }),
)(InvestmentRequests);
export default JoinWithMutation;