import gql from "graphql-tag";
import {FRAGMENT_MESSAGES} from "./fragments";

export const CREATE_CONVERSATION = gql`
  mutation createConversation($usersIds: [ID!]) {
    createConversation(usersIds: $usersIds) {
      id
      createdAt
      updatedAt
      users{
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;


//INVESTMENT SPRINT
export const CREATE_INVESTMENT_SPRINT_STAGE_1 = gql`
  mutation createInvestmentSprintStage1(
    $sprintId: ID
    $sprintCurrencySymbol: String!
    $oldEvaluation: Int!
    $minTicketSize: String!
    $requiredInvestmentAmount: String!
    $hasReceivedCommitment: Boolean!
    $oldAdditionalCommitment: Int
    $sprintClosingDate: String!
    $contactPreference: String!
  ) {
    createInvestmentSprintStage1(
      sprintId: $sprintId
      sprintCurrencySymbol: $sprintCurrencySymbol
      oldEvaluation: $oldEvaluation
      minTicketSize: $minTicketSize
      requiredInvestmentAmount: $requiredInvestmentAmount
      hasReceivedCommitment: $hasReceivedCommitment
      oldAdditionalCommitment: $oldAdditionalCommitment
      sprintClosingDate: $sprintClosingDate
      contactPreference: $contactPreference
    ) {
      id
      status
      finishedStage
      sprintCurrencySymbol
      oldEvaluation
      minTicketSize
      requiredInvestmentAmount
      hasReceivedCommitment
      oldAdditionalCommitment
      sprintClosingDate
      contactPreference
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage($conversationId: ID!, $message: String!) {
    createMessage(conversationId: $conversationId, message: $message) {
      id
    }
  }
`;

export const DELETE_CONVERSATION = gql`
  mutation deleteConversation($conversationId: ID!){
    deleteConversation(conversationId: $conversationId)
  }
`

export const CREATE_INVESTMENT_SPRINT_STAGE_2 = gql`
  mutation createInvestmentSprintStage2(
    $sprintId: ID!
    $registerNewspaperDocs: [startupDocumentInput]
    $mainContractDocs: [startupDocumentInput]
    $taxPlateDocs: [startupDocumentInput]
    $operatingCertificateDocs: [startupDocumentInput]
    $agreement: Boolean!
  ) {
    createInvestmentSprintStage2(
      sprintId: $sprintId
      registerNewspaperDocs: $registerNewspaperDocs
      mainContractDocs: $mainContractDocs
      taxPlateDocs: $taxPlateDocs
      operatingCertificateDocs: $operatingCertificateDocs
      agreement: $agreement
    ) {
      id
      status
      finishedStage
      registerNewspaperDocs {
        title
        file
        extension
        fileSize
        date
      }
      mainContractDocs {
        title
        file
        extension
        fileSize
        date
      }
      taxPlateDocs {
        title
        file
        extension
        fileSize
        date
      }
      operatingCertificateDocs {
        title
        file
        extension
        fileSize
        date
      }
    }
  }
`;

export const UPDATE_UNREAD_MESSAGE = gql`
  mutation markMessageAsRead($messageId: ID!) {
    markMessageAsRead(messageId: $messageId) {
      ...MessageFields
    }
  }
  ${FRAGMENT_MESSAGES.message}
`;
export const ADMIN_EDIT_INVESTMENT_SPRINT_STAGE_1 = gql`
  mutation adminEditInvestmentSprintStage1(
    $sprintId: ID!
    $sprintCurrencySymbol: String!
    $oldEvaluation: Int!
    $minTicketSize: String!
    $requiredInvestmentAmount: String!
    $hasReceivedCommitment: Boolean!
    $oldAdditionalCommitment: Int
    $sprintClosingDate: String!
    $contactPreference: String!
  ) {
    adminEditInvestmentSprintStage1(
      sprintId: $sprintId
      sprintCurrencySymbol: $sprintCurrencySymbol
      oldEvaluation: $oldEvaluation
      minTicketSize: $minTicketSize
      requiredInvestmentAmount: $requiredInvestmentAmount
      hasReceivedCommitment: $hasReceivedCommitment
      oldAdditionalCommitment: $oldAdditionalCommitment
      sprintClosingDate: $sprintClosingDate
      contactPreference: $contactPreference
    ) {
      id
      finishedStage
      sprintCurrencySymbol
      oldEvaluation
      minTicketSize
      requiredInvestmentAmount
      hasReceivedCommitment
      oldAdditionalCommitment
      sprintClosingDate
      contactPreference
    }
  }
`;
export const ADMIN_EDIT_INVESTMENT_SPRINT_STAGE_2 = gql`
  mutation adminEditInvestmentSprintStage2(
    $sprintId: ID!
    $registerNewspaperDocs: [startupDocumentInput]
    $mainContractDocs: [startupDocumentInput]
    $taxPlateDocs: [startupDocumentInput]
    $operatingCertificateDocs: [startupDocumentInput]
  ) {
    adminEditInvestmentSprintStage2(
      sprintId: $sprintId
      registerNewspaperDocs: $registerNewspaperDocs
      mainContractDocs: $mainContractDocs
      taxPlateDocs: $taxPlateDocs
      operatingCertificateDocs: $operatingCertificateDocs
    ) {
      id
      finishedStage
      registerNewspaperDocs {
        title
        file
        extension
        fileSize
        date
      }
      mainContractDocs {
        title
        file
        extension
        fileSize
        date
      }
      taxPlateDocs {
        title
        file
        extension
        fileSize
        date
      }
      operatingCertificateDocs {
        title
        file
        extension
        fileSize
        date
      }
    }
  }
`;
export const ADMIN_HANDLE_INVESTMENT_SPRINT_ISFEATURED = gql`
  mutation adminSetStartupInvSprintIsFeatured($sprintId: ID!, $isFeatured: Boolean!) {
    adminSetStartupInvSprintIsFeatured(sprintId: $sprintId, isFeatured: $isFeatured) {
      id
      isFeatured
    }
  }
`;

export const ADMIN_HANDLE_INVESTMENT_SPRINT_STATUS = gql`
  mutation adminSetStartupInvestmentSprintStatus($sprintId: ID!, $status: String!) {
    adminSetStartupInvestmentSprintStatus(sprintId: $sprintId, status: $status) {
      id
      status
    }
  }
`;
//END INVESTMENT SPRINT

//STARTUP INVESTMENT REQUEST
export const INVESTOR_SEND_STARTUP_INVESTMENT_REQUEST = gql`
  mutation investorSendInvestmentRequest(
    $startupId: ID!
    $email: String
    $telephone: String
    $adress: String
    $communicationMethod: String!
    $investmentAmount: Int!
  ) {
    investorSendInvestmentRequest(
      startupId: $startupId
      email: $email
      telephone: $telephone
      adress: $adress
      communicationMethod: $communicationMethod
      investmentAmount: $investmentAmount
    ) {
      id
    }
  }
`;
export const HANDLE_INV_REQUEST = gql`
  mutation startupResponseInvestmentRequest($reqId: ID!, $status: String!, $rejectReason: String) {
    startupResponseInvestmentRequest(reqId: $reqId, status: $status, rejectReason: $rejectReason) {
      id
      status
    }
  }
`;

export const ADMIN_HANDLE_INV_REQUEST = gql`
  mutation adminResponseInvestmentRequest($reqId: ID!, $status: String!, $rejectReason: String) {
    adminResponseInvestmentRequest(reqId: $reqId, status: $status, rejectReason: $rejectReason) {
      id
      status
    }
  }
`;

export const ADMIN_SET_INVESTMENT_REQUEST_VISIBILITY = gql`
  mutation adminHandleStartupInvestmentRequestVisibility($reqId: ID!, $showToStartup: Boolean!) {
    adminHandleStartupInvestmentRequestVisibility(reqId: $reqId, showToStartup: $showToStartup) {
      id
      showToStartup
      currentSprint {
        id
        currentCommitmentAmount
      }
    }
  }
`;
// END STARTUP INVESTMENT REQUEST
