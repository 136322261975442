import * as compose from "lodash.flowright";

import {HEADER_LEFT_DRAWER} from "../../queries";
import Inbox from "./Inbox";
import {graphql} from "react-apollo";

const JoinWithQuery = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: "HeaderLeftDrawer"
  })
)(Inbox);

export default JoinWithQuery;
