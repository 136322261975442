import * as compose from 'lodash.flowright';

import AdminInvestOnStartup from './AdminInvestOnStartup';
import { HEADER_LEFT_DRAWER } from '../../queries';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
)(AdminInvestOnStartup);
export default JoinWithMutation;
