import {Avatar, Grid, makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import React, {useEffect} from "react";
import {useMutation} from "react-apollo";
import {Link} from "react-router-dom";
import { UNREAD_MESSAGE_NUMBER } from "../../../queries";
import {UPDATE_UNREAD_MESSAGE} from "../../../mutations";

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 40,
    marginRight: 8,
    width: 40,
    padding: 0
  },
  card: {
    background: "#FFFFFF",
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(133, 133, 133, 0.10023)",
    maxWidth: "63%",
    padding: "16px 8px",
    height: "fit-content",
    width: "fit-content"
  },
  main: {
    padding: "8px 0px",
    height: "fit-content"
  }
}));

export default function ReceiveMessageCard({data, userId}) {
  const classes = useStyles();

  const [markMessageAsRead] = useMutation(UPDATE_UNREAD_MESSAGE, {
    refetchQueries: [
      {
        query: UNREAD_MESSAGE_NUMBER
      }
    ]
  });

  useEffect(() => {
    if (data.unread.some(u => u.id === userId)) {
      markMessageAsRead({variables: {messageId: data.id}});
    }
  }, []);

  return (
    <Grid container className={classes.main}>
      <Grid container justify="flex-end">
        <Grid item>
          <Avatar component={Link} to={`/`} className={classes.avatar} src={null} />
        </Grid>

        <Grid item xs>
          <Grid className={classes.card}>
            <Typography className={classes.receiveTypo}>{data.message}</Typography>
            <Grid container justify="flex-end">
              <small> {moment.utc(data.createdAt, "x").local().fromNow()}</small>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
