import ReportCreate from './ReportCreate'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const CREATE_REPORT = gql`
  mutation startupReportCreate($listId: ID!, $sendingText: String!, $status: String!,  $content: [startupReportContentInput]) {
    startupReportCreate(listId: $listId, sendingText: $sendingText, status: $status, content: $content) 
  }
`;

const CREATE_PREVIEW = gql`
  mutation startupReportPreview($listId: ID!, $sendingText: String!,  $content: [startupReportContentInput]) {
    startupReportPreview(listId: $listId, sendingText: $sendingText, content: $content){
      id
      title
      sendingText
      content{
          type
          title
          text
          kpiData{
            date
            reached
          }
        }
    }
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_REPORT, {
    name: 'startupReportCreate',
  }),
  graphql(CREATE_PREVIEW, {
    name: 'startupReportPreview',
  }),
)(ReportCreate);
export default JoinWithMutation;