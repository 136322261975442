
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    mainGrid:{
        background: '#E3F2FF',
        borderRadius: '6px',
        padding:'2px 10px',
        width:'max-content'
        
    },
    text:{
        fontSize:10,
        color: '#379FFF'
    }
}));

function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
}

export default function BlueTag(props) {
    const classes = useStyles();

    return (
            <Grid container item className={classes.mainGrid}>
                <Typography variant="h5" align='center' className={classes.text}>{truncateString(props.title, 40)}</Typography>
            </Grid>
    );
  }