import { graphql } from 'react-apollo';
import VerifyEmail from './VerifyEmail';
import gql from 'graphql-tag';
import * as compose from "lodash.flowright";

const VERIFY_EMAIL = gql`
  mutation verifyEmail($code: String!) {
    verifyEmail(code: $code)
  }
`;

const JoinWithMutation = compose(
    graphql(VERIFY_EMAIL, {
      name: 'verifyEmail',
    }),
)(VerifyEmail);
export default JoinWithMutation;