import { Grid, Hidden, Typography } from '@material-ui/core';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    backgroundColor: '#F0F5FF',
    zIndex: '998',
  },
  stepperBlue: {
    backgroundColor: '#003CA5',
    border: 0,
    width: 35,
    height: 35,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    zIndex: '998',
  },
  stepperLine: {
    width: '8px',
    borderRadius: 50,
    height: '90%',

    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },

    backgroundColor: '#F0F5FF',
    position: 'absolute',
    marginLeft: '14px',
    marginTop: '-20px',
    zIndex: '1',
  },
}));

function GenericStepper({ header, description, bottomJSX, stepsJsonArray }) {
  const classes = useStyles();
  return (
    <Grid container item style={{ flexDirection: 'column', marginTop: 24 }}>
      <Typography align="center" variant="h2">
        {header}
      </Typography>
      <Typography variant="h6" align="center" style={{ color: '#425466', marginTop: 16 }}>
        {description}
      </Typography>
      <Grid item container style={{ position: 'relative', flexDirection: 'column', marginTop: 51 }}>
        <Hidden mdDown>
          <div className={classes.stepperLine}></div>
        </Hidden>
        {stepsJsonArray.map((step, index) => (
          <Grid container xs style={{ marginBottom: 30 }}>
            <Grid item className={step.isBlue ? classes.stepperBlue : classes.stepper}>
              {step.isFinished ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <circle cx="16" cy="16" r="15.5" fill="#003CA5" stroke="white" />
                  <path
                    d="M13 16L15 18L19 14M25 16C25 17.1819 24.7672 18.3522 24.3149 19.4442C23.8626 20.5361 23.1997 21.5282 22.364 22.364C21.5282 23.1997 20.5361 23.8626 19.4442 24.3149C18.3522 24.7672 17.1819 25 16 25C14.8181 25 13.6478 24.7672 12.5558 24.3149C11.4639 23.8626 10.4718 23.1997 9.63604 22.364C8.80031 21.5282 8.13738 20.5361 7.68508 19.4442C7.23279 18.3522 7 17.1819 7 16C7 13.6131 7.94821 11.3239 9.63604 9.63604C11.3239 7.94821 13.6131 7 16 7C18.3869 7 20.6761 7.94821 22.364 9.63604C24.0518 11.3239 25 13.6131 25 16Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <Typography variant="h6" style={{ color: '#003CA5' }}>
                  {index + 1}
                </Typography>
              )}
            </Grid>
            <Grid xs>
              <Typography
                variant="h6"
                style={{ textAlign: 'start', fontWeight: '600', fontSize: '14px', width: '100%', marginBottom: 5 }}
              >
                {step.title}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: '#718096',
                  textAlign: 'start',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  width: '100%',
                }}
              >
                {step.text ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam, nibh ut sagittis imperdiet.'}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {/*Extra jsx field for buttons text etc.*/}
        <Grid item style={{ marginTop: 20 }}>
          {bottomJSX}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GenericStepper;
