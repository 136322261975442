import EditKPI from './EditKPI'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const UPDATE_DATA = gql`
  mutation kpiDataUpdate ($date: String!, $kpiId: ID! $target: Float, $reached: Float,) {
    kpiDataUpdate(kpiId: $kpiId, date: $date, target: $target, reached: $reached) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(UPDATE_DATA, {
    name: 'kpiDataUpdate',
  }),
)(EditKPI);

export default JoinWithMutation;