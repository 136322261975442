import { createMuiTheme } from '@material-ui/core';
import InterBoldFont from "./assets/fonts/Inter-Bold.ttf";
import InterRegularFont from "./assets/fonts/Inter-Regular.ttf";
import InterSemiBoldFont from "./assets/fonts/Inter-SemiBold.ttf";

const interRegular = {
  fontFamily: "InterRegular",
  fontStyle: "normal",
  fontWeight: 500,
  src: `
    local('Inter-Regular'),
    url(${InterRegularFont}) format('truetype')
  `
};
const interSemiBold = {
  fontFamily: "InterSemiBold",
  fontStyle: "normal",
  src: `
    local('Inter-SemiBold'),
    url(${InterSemiBoldFont}) format('truetype')
  `
};
const interBold = {
  fontFamily: "InterBold",
  fontStyle: "normal",
  src: `
    local('Inter-Bold'),
    url(${InterBoldFont}) format('truetype')
  `
};

const base = {
  palette: {
    primary: {
      main: '#003CA5',
    },
    secondary: {
      main: '#8898aa',
    },
    common: {
      white: '#ffffff',
    },
    shape: {
      borderRadius: 5,
    },
    iconColor: {
      main: '#172b4d',
    },
    borderColor: {
      main: '#003CA5',
    },

    background: {
      main: '#f7f7fc',
      secondary: '#f6f9fc',
      containedPrimary: '#003CA5',
      containedSecondary: '#ffba00',
      outlinedSecondary: '#ffffff',
      outlinedPrimary: '#003CA5',
    },
    text: {
      main: '#172b4d',
      secondary: '#777777',
    },
  },
  shadows: [
    'none', // 0
    '0 0 6px 0 rgba(0, 0, 0, 0.16)', // 1
    '0 0 10px 0 rgba(0, 0, 0, 0.16)', // 2
    'none', // 3
    'none', // 4
    'none', // 5
    'none', // 6
    'none', // 7
    'none', // 8
    'none', // 9
    'none', // 10
    'none', // 11
    'none', // 12
    'none', // 13
    'none', // 14
    'none', // 15
    'none', // 16
    'none', // 17
    'none', // 18
    'none', // 19
    'none', // 20
    'none', // 21
    'none', // 22
    'none', // 23
    'none', // 24
  ],
  typography: {
    h1: {
      fontFamily:'InterBold',
      fontSize: '36px',
      lineHeight: '49px',
      color: '#27272E',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '28px',
      lineHeight: '38px',
      fontFamily:'InterBold',
      color: '#27272E',
      fontWeight: '500',
    },
    h3: {
      fontSize: '20px',
      lineHeight: '27px',
      fontFamily:'InterBold',
      color: '#27272E',
      fontWeight: '500',
    },
    h4: {
      fontSize: '18px',
      lineHeight: '24px',
      fontFamily:'InterBold',
      color: '#27272E',
      fontWeight: '500',
    },
    h5: {
      fontSize: '16px',
      lineHeight: '25px',
      fontFamily:'InterSemiBold',
      color: '#27272E',
      fontWeight: '500',
    },

    h6: {
      fontSize: '14px',
      lineHeight: '24px',
      fontFamily:'InterSemiBold',
      color: '#27272E',
      fontWeight: '500',
      width: '-moz-fit-content',
      textShadow:'none'
    },

    body1: {
      fontFamily:'InterRegular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '26px',
      color: '#27272E',
      textShadow:'none'
    },
    body2: {
      fontFamily:'InterRegular',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.38px',
      textShadow:'none'
    },
  },
};

var theme = createMuiTheme(base);

theme = {
  ...theme,

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [interRegular, interSemiBold, interBold]
      }
    },
    MuiTypography: {
      color: '#172b4d',
    },

    MuiAppBar: {
      root: {
        background: '#ffffff',
      },
      colorPrimary:{
        background: '#ffffff',
        backgroundColor: '#ffffff',
      },
      colorDefault: {
        backgroundColor:'inherit'
      }
    },
    MuiSlider:{
      rail:{
        height: '10px',
        borderRadius:10
      },
      root:{
        '&$disabled':{
          color:'#EB7D00'
        }
      },
      track:{
        height: '10px',
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10
      }
    },
    MuiStepLabel: {
      label:{
        marginTop: '5px!important',
        fontSize: '13px',
      },
    },
    MuiBreadcrumbs: {
      separator:{
        marginLeft:15,
        marginRight:15,
      },
    },
    MuiStepConnector: {
      line:{
        borderColor:'#f7f7fc'
      },
    },
    MuiTab: {
      textColorPrimary:{
        color:'#777777',
        marginRight:16,
      },
      root: {
        textTransform:'inherit',
        fontSize:14,
        fontWeight:500,
        padding:'24px',
        minWidth:'max-content!important'
      },
    },
    MuiTabs: {
      flexContainer:{
        borderBottom: '1px solid #EDF2F7'
      },
    },
    MuiTabScrollButton:{
      root:{
        '&:before': {
          position:'absolute',
          left:0,
          height:55
        },
        '&:last-child': {
        position:'absolute',
        right:0,
        height:48
        },
        color:'#003CA5',
        opacity:1,
        fontSize:'26px!important',
        background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)'
      }
    },
    MuiStepIcon: {
      root: {
        color: 'rgb(255, 255, 255)',
        borderRadius: '50%',
        border: '1px solid #28b1e7',
        MuiStepIcon: {}
      },
      active: {
        color: 'rgb(255, 255, 255)!important',
      },
      text:{
        fill: 'rgb(40, 177, 231)',
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '13.5px 17px',
        fontSize: 15,
        borderRadius:6
      },
      multiline:{
        padding: '14.5px 14.5px',
        fontSize: 15,
      },
      root: {
        boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
        '&$focused $notchedOutline': {
          borderColor: '#003CA5',
          borderWidth: 1,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#003CA5',
        },
        borderRadius: base.palette.shape.borderRadius,
      },
      notchedOutline: {
        borderColor: '#dddddd70',
      },
    },

    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiFilledInput: {
      input: {
        padding: '14.5px 20px',
        fontSize: 16,
      },
      root: {
        '&$focused': {
          borderColor: '#172B4D',
          backgroundColor: '#ffffff',
        },
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        backgroundColor: '#ffffff',
        border: 'solid 1px ',
        '&:hover:not($disabled):not($focused):not($error)': {
          borderColor: '#DDDDDD',
        },
        borderRadius: base.palette.shape.borderRadius,
      },

      underline: {
        border: '1px solid',
        borderColor: '#DDDDDD',

        '&:after': {
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transition: 'none',
          width: 0,
          pointerEvents: 'none', // Transparent to the hover style.
        },
        '&$focused:after': {
          transform: 'scaleX(1)',
        },
        '&$hover:before': {},
        '&$error:after': {
          borderBottomColor: '#f44336',
          transform: 'scaleX(1)', // error is always underlined in red
        },
        '&:before': {
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '"\\00a0"',
          position: 'absolute',
          transition: 'none',
          right: 0,
          width: 0,

          pointerEvents: 'none', // Transparent to the hover style.
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          /*        borderBottom: `2px solid #ffffff`, */
          /* borderColor : "#DDDDDD", */

          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            /*     borderBottom: `1px solid #ffffff`, */
          },
        },
        '&$disabled:before': {},
      },
    },

    MuiToolbar: {
      dense: {
        minHeight: 100,
      },
      regular: {
        height: 98,
      },
    },

    MuiInputBase: {
      root: {
        borderRadius: base.palette.shape.borderRadius,
      },
      input: {
        borderRadius: base.palette.shape.borderRadius,
        color: base.palette.text.main,
        fontSize: 18,
        lineHeight: 1.5,
        '&::placeholder': {
          color: base.palette.text.secondary,
        },
      },
    },

    MuiButton: {
      root: {
        fontSize: '12px',
        borderRadius:6,
        fontWeight: '500',
        fontStretch: 'normal',
        fontFamily: "InterSemiBold",
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.45px',
        textAlign: 'center',
        textTransform: 'capitalize',
        padding: '11.5px 51px',
      },

      containedPrimary: {
        backgroundColor: base.palette.background.containedPrimary,
        color: base.palette.common.white,
        '&:hover': {
          backgroundColor: base.palette.background.containedPrimary,
        },
      },
      containedSecondary: {
        backgroundColor: base.palette.background.containedSecondary,
        color: base.palette.common.white,
        '&:hover': {
          backgroundColor: base.palette.background.containedSecondary,
        },
      },

      outlinedPrimary: {
        border: `1px solid ${base.palette.primary.main}`,
        '&:hover': {
          color:'#ffffff',
          backgroundColor: base.palette.background.outlinedPrimary,
        },
      },
      outlinedSecondary: {
        padding: '11.5px 45px',
        backgroundColor: base.palette.common.white,
        border: `1px solid ${base.palette.secondary.main}`,
        '&:hover': {
          backgroundColor: base.palette.background.outlinedSecondary,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 50,
        height: 50,
      },
    },
    MuiAutocomplete:{
      inputRoot:{
        minHeight:45,
        paddingBottom:'0!important'
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          borderColor: '#172B4D',
          backgroundColor: '#ffffff',
          color: '#172B4D',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth:'40px'
      }
    },
    MuiInput: {
      root: {
        border: 'solid 1px ',
        borderColor: '#dddddd4d',
        boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        /* https://github.com/mui-org/material-ui/issues/12192#issuecomment-406182809 */
        '&&&&:hover:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        padding: '18.5px 20px',
      },
      icon :{
        right : 18
      }
    },
    MuiAlert: {
      root:{
        padding:16,
        alignItems:'center',
        minHeight:80,
        minWidth:424,
        borderRadius:10
      },
      icon: {
        alignItems:'center',
        padding:12,
        borderRadius:'50%',
        background:'#0000001f',
        color:'#fff',
        opacity: 0.7,
        "& svg" : {
          color:'#fff',
        }
      },
      filledError: {
        backgroundColor:'#F16063'
      },
      standardSuccess: {
        backgroundColor:'#66CB9F',
        color:'#fff'
      }
    },
    MuiPopover: {
      paper: {
        borderRadius: 4,
        marginTop:-12,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)',
      }
    },
    MuiPaper:{
      elevation8:{
        boxShadow:'none!important',
        border: '1px solid rgb(221 221 221)'
      }
    },
    MuiPaginationItem:{
      icon:{
        color:'#003CA5',
        borderColor:'#003CA5'
      },
      outlinedPrimary:{
        '&$selected': {
          color:'#fff',
          backgroundColor:'#003CA5'
        }
      }
    },
    MuiSelectIcon: {
      transition: 'all 0.3s ease-out',
    },
    MuiCheckbox :{
      root: {
        color: '#afafaf',
        borderRadius: '7px',
        "&:hover" : {
          color: '#89C5FC',
        }
      },
      color : theme.palette.primary.main
    },
    MuiChip :{
      label: {
        fontSize:13,
        maxWidth:190
      },
      root:{
        height:25
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '550px'
      },
      paper:{
        borderRadius:16
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft:{
        borderRight:'1px solid rgb(247 247 252)'
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#58585870'
      }
    },
    MuiIconButton  :{
      colorPrimary : {
        "&:hover" : {
          backgroundColor : "#ffffff"
        }
      }
    },

    // Text Field label edit
    /*   MuiInputLabel: {
      formControl: {
        top: '12.5px',
        left: '20px',
      },
    }, */
     MuiInputLabel: {
      outlined: {
        fontSize:16
      },
      formControl: {
        top:'-3px'
      },
      root:{
        padding:10,
      },
      shrink:{
        display:'none'
      }
    }, 
  },
};

export default theme;
