
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import get from 'lodash/get';
import newPerson from '../../static/icon/profile-menu-icon.svg';
import linkedinLogo from '../../static/icon/linkedin-investor-profile.svg';
import showInvestorAccordion from '../../static/icon/show-investor-accordion.svg';
import hideInvestorAccordion from '../../static/icon/hide-investor-accordion.svg';
import twitterLogo from '../../static/icon/twitter-investor-icon.svg';
import dollarIcon from '../../static/icon/dollar-icon.svg';
import poundIcon from '../../static/icon/pound-icon.svg';
import euroIcon from '../../static/icon/euro-icon.svg';
import tlIcon from '../../static/icon/tl-icon.svg';
import publicLogo from '../../static/img/public-logo.jpg';
import worldIcon from '../../static/icon/world-icon.svg';
import blueWorldIcon from '../../static/icon/blue-world-icon.svg';
import plusIcon from '../../static/icon/plus-investor.svg';
import ticketIcon from '../../static/icon/ticket.svg';
import minTicketIcon from '../../static/icon/minTicket.svg';
import personEndIcon from '../../static/icon/person-end-icon.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import tr from "date-fns/locale/tr";
import moment from 'moment'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import GreenTag from "../../Components/greenTag";
import { Link } from 'react-router-dom'
import Dialog from "@material-ui/core/Dialog";
import linkedin from '../../static/icon/new-linkedin.svg';
import twitter from '../../static/icon/new-twitter.svg';
import { Form, Formik, Field, useField, useFormikContext } from 'formik';
import FormikTextField from "../../Components/FormikShortTextField";
import websiteEndIcon from '../../static/icon/website-end-icon.svg';
import FormikNumberField from "../../Components/FormikNumberField";
import FormikDropdownCommitment from "../../Components/FormikDropdownCommitment";
import DatePicker, { registerLocale } from "react-datepicker";
import Popover from '@material-ui/core/Popover';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import { TwitterShareButton, LinkedinShareButton } from 'react-share';
import linkedinBlueLogo from '../../static/icon/new-linkedin.svg';
import twitterBlueLogo from '../../static/icon/new-twitter.svg';
import newCross from '../../static/icon/new-cross.svg';
import DialogTitle from "@material-ui/core/DialogTitle";
import sureIcon from '../../static/icon/sure-icon.svg';
import approvedInvestor from '../../static/icon/approved-investor.svg';
import Tooltip from '@material-ui/core/Tooltip';
import CreateConversation from '../../Components/CreateConversation';
registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  companyGrid: {
    padding: '16px',
    borderRadius: '6px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#fff',
    marginBottom: 16
  },
  whiteGrid: {
    background: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    marginBottom: 32,
  },
  popoverGrid: {
    borderRadius: 6,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)!important',
    padding: 16,
    border: 'none',
    marginTop: 0,
  },
  mainContainer: {
    width: 100,
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    padding: '0px 15px 0 24px',
    maxHeight: 600,
    [theme.breakpoints.down('sm')]: {
      padding: '16',
    },
  },
  mobilePadding: {
    padding: '200px',
    paddingTop: 32,
    paddingBottom: 32,
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      paddingTop: 16,
    },
  },
  search: {
    position: 'relative',
    width: '100%',
  },
  formGrid: {
    [theme.breakpoints.up('sm')]: {
      padding: '16px 48px',
    },
  },
  searchBox: {
    width: '100%',
    maxWidth: 350,
    minWidth: 125,
    background: '#ffffff',
    borderRadius: 6,
    marginTop: 5,
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#7c7c7c',
  },
  twitterButton: {
    padding: 16,
    borderRadius: 6,
    boxShadow: '0px 0px 1px rgb(12 26 75 / 24%), 0px 3px 8px -1px rgb(50 50 71 / 5%)',
    minWidth: 50
  },
  Debounce: {
    width: '100%',
    fontSize: 13,
    height: '50px',
    fontFamily: 'inherit',
    outline: 'none',
    border: 'none',
    background: '#FFFFFF',
    color: '#718096',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    padding: '26px 56px 26px 0',
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    borderRadius: 6,
    '&:focus': {
      border: '1px solid #003CA5',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  uploadGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 80,
    height: 80,
    background: '#E4EEFF',
    marginBottom: 16
  },
  blueGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 116,
    height: 116,
    background: '#F0F5FF',
    borderRadius: '50%',
    marginBottom: 24
  },
  datePlaceholder: {
    '&::placeholder': {
      color: '#c4c4c4',
    },
    fontFamily: 'inherit'
  },
  backgroundImg: {
    objectFit: 'cover',
    height: 180,
    borderRadius: 16,
    width: '100%'
  },
  profileImg: {
    objectFit: 'cover',
    height: 112,
    width: 112,
    borderRadius: '50%',
  },
  profileImg2: {
    objectFit: 'cover',
    height: 46,
    width: 46,
    borderRadius: '14px',
  },
  profileGrid: {
    marginLeft: 30,
    marginTop: -60,
    border: '5px solid #fff',
    borderRadius: '50%',
    height: 'max-content'
  },
  profileGrid2: {
    border: '5px solid #fff',
    borderRadius: '14px',
    height: 'max-content'
  },
  portfoyCompany: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '32px',
      borderLeft: '1px solid rgba(160, 174, 192, 0.15)'
    },
  },
  publicHeader: {
    background: '#fff',
    height: '96px',
    width: '100%',
    position: 'fixed',
    zIndex: 999,
    [theme.breakpoints.up('sm')]: {
      padding: '0 160px',
    },
  },
  borderRight: {
    borderRight: 'none',
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid #7180962b;',
    },
  }
}));

function https(value) {
  if (!value.includes('http')) {
    const prefix = 'https://';
    if (value.substr(0, prefix.length) !== prefix) {
      value = prefix + value;
    }
    return value
  }
  else {
    return value
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

function CareerPart(props) {
  const { index, careerItem, careerList, setRemove, setRemoveItem, setSuccess, refetch } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Grid xs={12} container style={{ marginBottom: 15 }} justify="space-between">
        <Grid item>
          <Typography variant="h6" align="left" style={{ marginBottom: 4 }}>
            {careerItem.company},
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: 4 }}>
            {careerItem.jobTitle}
          </Typography>
          <Typography variant="body2" align="left" style={{ marginBottom: 4 }}>
            {careerItem.startDate && moment(careerItem.startDate).format('MMMM YYYY')} - {careerItem.isPresent ? 'Şu Anda' : (careerItem.endDate && moment(careerItem.endDate).format('MMMM YYYY'))}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

function PortfolioPart(props) {
  const classes = useStyles();
  const { index, portfolioItem, setRemoveCompany, setRemoveItem } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Grid xs={12} container style={{ marginBottom: 15 }} justify="space-between" className={classes.companyGrid}>
        <Grid xs style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Grid xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            {portfolioItem.logo ?
              <img src={portfolioItem.logo} style={{ width: 45, minWidth:45, height: '45px', borderRadius: 12 }} />
              :
              <Grid
                style={{
                  width: 45,
                  height: 45,
                  minWidth:45,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#003CA5',
                }}
              >
                <Typography variant="h4" style={{ color: '#fff' }}>
                  {portfolioItem.title && portfolioItem.title.charAt(0)}
                </Typography>
              </Grid>
            }
            <Typography variant="h5" align="left" style={{ marginBottom: 4, marginLeft: 16, maxWidth: 210 }}>
              {portfolioItem.title}
            </Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <Typography variant="body1" align="left" style={{ marginBottom: 4, maxWidth: 210 }}>
              {portfolioItem.sector}
            </Typography>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <LightTooltip arrow title={t("Startup Borsa'ya kayıtlı şirket")}>
              <img src={approvedInvestor} alt="approved-icon" />
            </LightTooltip>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const DatePickerField = ({ ...props }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      style={{ zIndex: 999, color: '#B5B5BD' }}
      {...props}
      placeholderText="Tur Kapanış Tarihi"
      selected={(field.value && new Date(field.value)) || null}
      minDate={new Date()}
      locale="tr"
      customInput={
        <input
          autocomplete="off"
          style={{
            width: '100%',
            padding: '13px',
            borderRadius: 3,
            border: '1px solid rgb(221 221 221 / 56%)',
            boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
            marginTop: 10,
          }}
          className={classes.datePlaceholder}
          type="text"
          placeholder={'mm'} />
      }
      dateFormat="yyyy/MM/dd"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

function validateLinkedinUrl(value) {
  if (value !== '' && value) {
    let error;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?');// query string
    error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
    return error;
  }
  return false;
}

export function RequestDialog(props) {
  const classes = useStyles();
  const { onClose, open, row, investor, firstName, lastName, setSuccessRequest } = props;
  const yesNo = ['Evet', 'Hayır'];
  const currency = props.currency ? props.currency : '$' 

  const handleClose = () => {
    onClose(null);
  };

  const handleSubmit = (variables) => {
    if (variables.minTicketSize) {
      variables.minTicketSize = variables.minTicketSize.toString();
    }
    props.startupSendInvestmentRequest({ variables })
      .then(data => {
        if (data.data.startupSendInvestmentRequest) {
          handleClose();
          setSuccessRequest(true);
        }
      });
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="lg"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="center" xs={12} style={{ padding: '36px 72px' }}>
        <Grid xs={12} md={7} className={classes.borderRight}>
          <Typography align="center" variant="h2">
            İletişime Geç
          </Typography>
          <Typography align="center" variant="body1" style={{ marginTop: 16 }}>
            Bir yatırımcı ile iletişime geçerek <br /> girişiminizi birçok kişiye tanıtın.
          </Typography>
          <Grid container style={{ marginTop: 24 }}>
            {investor && investor.profilePhoto ?
              <Grid item className={classes.profileGrid2}>
                <img src={investor.profilePhoto} alt="background" className={classes.profileImg2} />
              </Grid>
              :
              <Grid item className={classes.profileGrid2} style={{ background: '#003CA5', width: 46, height: 46, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 24 }}>
                  {investor.firstName.charAt(0)}
                </Typography>
              </Grid>
            }
            <Grid item xs style={{ paddingLeft: 8 }}>
              <Typography variant="h5">
                {investor.firstName} {investor.lastName}
              </Typography>
              <Typography variant="body2" style={{ color: '#718096' }}>
                {investor.companyRole}
              </Typography>
              <Typography variant="body2" style={{ color: '#718096' }}>
                {investor.city && investor.city.title}/Türkiye
              </Typography>
              <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                {investor.website &&
                  <a
                    href={investor.website}
                    style={{ padding: 0, marginRight: 20 }}
                    target="_blank"
                  >
                    <img src={blueWorldIcon} alt={"website"} style={{ width: 18, marginTop: 2 }} />
                  </a>
                }
                {investor.socialLinkedIn &&
                  <a
                    href={investor.socialLinkedIn}
                    style={{ padding: 0, marginRight: 20 }}
                    target="_blank"
                  >
                    <img src={linkedin} alt={linkedin} style={{ width: 16 }} />
                  </a>
                }
                {investor.socialTwitter &&
                  <a
                    href={investor.socialTwitter}
                    style={{ padding: 0 }}
                    target="_blank"
                  >
                    <img src={twitter} alt={twitter} style={{ width: 18, marginTop: 2 }} />
                  </a>
                }
              </Grid>
              <Grid container spacing={1} style={{ marginTop: 12 }}>
                {investor.investmentType && (
                  <Grid item>
                    <GreenTag title={investor.investmentType} />
                  </Grid>
                )}
                {investor.interestedStage && investor.interestedStage.length > 0 && (
                  <>
                    {investor.interestedStage.map((ind, index) => (
                      <Grid item>
                        <GreenTag title={ind} />
                      </Grid>
                    ))}
                  </>
                )}
                {investor.interestedIndustries && investor.interestedIndustries.length > 0 && (
                  <>
                    {investor.interestedIndustries.map((ind, index) => (
                      <Grid item>
                        <GreenTag title={ind} />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container direction="column" style={{ marginTop: 24, paddingRight: 32 }}>
              <Typography variant="h5">
                Hakkında
              </Typography>
              <Typography variant="body2" style={{ marginTop: 8 }}>
                {investor.biography}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={5}>
          <Typography align="center" variant="h2">
            Yatırım Bilgileri
          </Typography>
          <Typography align="center" variant="body1" style={{ marginTop: 16 }}>
            Yatırımcının sizi daha iyi tanıyabilmesi için <br /> daha fazla bilgiye ihtiyacımız var.
          </Typography>
          <Formik
            initialValues={{
              investorId: investor.id,
              website: (props.startup && props.startup.website) ? props.startup.website : '',
              startup: props.startup ? props.startup.title : '',
              nameSurname: firstName + ' ' + lastName,
              preInvestmentAssessment: '',
              requiredInvestmentAmount: '',
              isReceivedCommitment: '',
              minTicketSize: '',
              tourClosingDate: ''
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {props => {
              const {
                values,
                setFieldValue
              } = props;
              return (
                <Form>
                  <Grid item className={classes.formGrid}>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                      <FormikTextField name="nameSurname" type="text" placeholder="Adı Soyadı" endIcon={personEndIcon} style={{ paddingBottom: 0 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField name="startup" disabled type="text" placeholder="Şirket Adı" endIcon={ticketIcon} style={{ paddingBottom: 0 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField name="website" type="text" placeholder="Websitesi" validate={validateLinkedinUrl} endIcon={websiteEndIcon} style={{ paddingBottom: 0 }} />
                    </Grid>
                    <Grid item xs={12}>
                      {currency === "₺" &&
                        <FormikNumberField name="preInvestmentAssessment" placeholder="Yatırım Öncesi Değerlendirme" endIcon={tlIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                      {currency === "£" &&
                        <FormikNumberField name="preInvestmentAssessment" placeholder="Yatırım Öncesi Değerlendirme" endIcon={poundIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                      {currency === "$" &&
                        <FormikNumberField name="preInvestmentAssessment" placeholder="Yatırım Öncesi Değerlendirme" endIcon={dollarIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                      {currency === "€" &&
                        <FormikNumberField name="preInvestmentAssessment" placeholder="Yatırım Öncesi Değerlendirme" endIcon={euroIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {currency === "₺" &&
                        <FormikNumberField name="requiredInvestmentAmount" placeholder="Aranan Yatırım Miktarı" endIcon={tlIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                      {currency === "£" &&
                        <FormikNumberField name="requiredInvestmentAmount" placeholder="Aranan Yatırım Miktarı" endIcon={poundIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                      {currency === "$" &&
                        <FormikNumberField name="requiredInvestmentAmount" placeholder="Aranan Yatırım Miktarı" endIcon={dollarIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                      {currency === "€" &&
                        <FormikNumberField name="requiredInvestmentAmount" placeholder="Aranan Yatırım Miktarı" endIcon={euroIcon} onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} maxLen={15} style={{ paddingBottom: 0 }} />
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        style={{ width: '100%', marginTop: 10 }}
                        component={FormikDropdownCommitment}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="isReceivedCommitment"
                        options={yesNo}
                      ></Field>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField name="minTicketSize" type="number" placeholder="Minimum Yatırım Tutarı" endIcon={minTicketIcon} style={{ paddingBottom: 0 }} />
                    </Grid>
                    <Grid item xs={12}>
                      <DatePickerField name="tourClosingDate" />
                    </Grid>
                    <Grid item container xs={12} justify="center" spacing={3} style={{ margin: 0, marginTop: 32, height: 'fit-content' }}>
                      <Grid item xs={12} md={6}>
                        <Button
                          variant="outlinedSecondary"
                          style={{ fontWeight: 'normal', width: '100%' }}
                          onClick={() => handleClose()}
                        >
                          Vazgeç
                          </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          variant="containedPrimary"
                          style={{ fontWeight: 'normal', width: '100%', padding: '12px 24px' }}
                          onClick={() => handleSubmit(props.values)}
                        >
                          İletişime Geç
                          </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function MailDialog(props) {
  const classes = useStyles();
  const { onClose, open, setMailSend, mail, investor } = props;

  const handleSubmit = () => {
    props.investorShareProfileWithMail({ variables: { slug: investor.slug, mail: mail } })
      .then(data => {
        if (data.data.investorShareProfileWithMail) {
          setMailSend(true)
          onClose();
        }
        else {
          onClose();
        }
      });
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
        <img src={newCross} alt="newCross" onClick={onClose} style={{ cursor: 'pointer' }} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Grid container justify="center">
          <Grid item className={classes.blueGrid}>
            <img src={sureIcon} alt="sure-icon" />
          </Grid>
        </Grid>
        <Typography align="center" variant="h2">
          Yatırımcıyı Paylaş
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            Yatırımcıyı paylaşmak istediğinize emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }}
                onClick={() => {
                  onClose(null);
                }}
                variant="outlinedSecondary"
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  width: '100%',
                  marginTop: 20,
                }}
                disabled={!mail}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
              >
                Paylaş
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default function InvestorProfile(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [investor, setInvestor] = React.useState();
  const [city, setCity] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [remove, setRemove] = React.useState(false);
  const [removeCompany, setRemoveCompany] = React.useState(false);
  const [removeItem, setRemoveItem] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [successInvite, setSuccessInvite] = React.useState(false);
  const [careerShow, setCareerShow] = React.useState(false);
  const [companyShow, setCompanyShow] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [startupError, setStartupError] = React.useState(false);
  const [successRequest, setSuccessRequest] = React.useState(false);
  const [careerList, setCareerList] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [copied, setCopied] = React.useState(false);
  const [mailSend, setMailSend] = React.useState(false);
  const [mail, setMail] = React.useState(null);
  const [mailPopup, setMailPopup] = React.useState(false);

  const handleChangeMail = (event) => {
    setMail(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const INVESTOR_PROFILE = gql`
  query investorDetail($slug:String!){
    investorDetail(slug:$slug){
      id
      slug
      firstName
      lastName
      profilePhoto
      backgroundPhoto
      interestedIndustries
      biography
      website
      socialLinkedIn
      userId
      city{
        id 
        title
      }
      socialTwitter
      companyName
      companyRole
      portfolioCompanyCount
      investmentType
      exitedCompanyCount
      interestedStage
      career{
        company
        jobTitle
        startDate
        endDate
        isPresent
      }
      portfolios{
        id
        title
        oneliner
        logo
        sector
        slug
        website
        businessModelArray
        country {
          id
          title
        }
      }
    }
  }
  `;

  const { loading: loading, data: data, refetch } = useQuery(INVESTOR_PROFILE, {
    fetchPolicy: 'network-only',
    variables: { slug: props.match.params.slug },
  });

  const ALL_CITIES = gql`
  query allCities($country:String) {
      allCities(country:$country){
          id
          title
      }        
  }
  `;

  const { loading: loading2, data: data2 } = useQuery(ALL_CITIES, {
    variables: { country: 'TR' },
  });

  const handleTypename = (values) => {
    values = values.map(({ __typename, ...array }) => array)
    return values
  };

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (data.investorDetail) {
          if (data.investorDetail.socialLinkedin) {
            data.investorDetail.socialLinkedin = https(data.investorDetail.socialLinkedin)
          }
          if (data.investorDetail.socialTwitter) {
            data.investorDetail.socialTwitter = https(data.investorDetail.socialTwitter)
          }
          if (data.investorDetail.website) {
            data.investorDetail.website = https(data.investorDetail.website)
          }
          if (data.investorDetail.career !== [] && data.investorDetail.career) {
            data.investorDetail.career = handleTypename(data.investorDetail.career);
            data.investorDetail.career.forEach((car) => {
              if (car.startDate) {
                car.startDate = moment(car.startDate).format('yyyy/MM');
              }
              if (car.endDate) {
                car.endDate = moment(car.endDate).format('yyyy/MM');
              }
            });
            setCareerList(data.investorDetail.career);
          }
          if (data.investorDetail.city) {
            data.investorDetail.cityId = data.investorDetail.city
          }
          setInvestor(data.investorDetail);
          if (data.investorDetail.portfolios) {
            setSelected(data.investorDetail.portfolios);
          }
        }
      }

    }
    if (!loading2) {
      if (data2) {
        if (data2.allCities.length > 0) {
          setCity(data2.allCities);
        }
        else {
          setCity([{ id: 1, title: 'İzmir' }, { id: 2, title: 'İstanbul' }]);
        }
      }
    }
  },
    [data, loading, data2, loading2]);

  const thumb = {
    display: 'inline-flex',
    borderRadius: 16,
    width: '100%',
    height: 180,
    padding: 4,
    boxSizing: 'border-box'
  };

  return (
    <>
      {!props.authToken &&
        <Grid container justify="space-between" alignItems="center" className={classes.publicHeader}>
          <img src={publicLogo} alt="logo" style={{ height: 40 }} />
          <Grid item style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Link to="/sign-up" style={{ padding: 0 }}>
              <Button
                variant="outlinedPrimary"
                style={{ fontWeight: 'normal', padding: '11.5px 32px', marginRight: 16 }}
              >
                {t('Kayıt Ol')}
              </Button>
            </Link>
            <Link to="/login" style={{ padding: 0 }}>
              <Button
                variant="containedPrimary"
                style={{ fontWeight: 'normal', padding: '11.5px 32px' }}
              >
                {t('Giriş Yap')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      }
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccess(false)}
            open={success}
          >
            <Alert severity="success">Başarıyla düzenlendi.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setStartupError(false)}
            open={startupError}
          >
            <Alert severity="error">Yatırımcılar ile iletişime geçmek için önce girişiminizin şirket profilini oluşturmalısınız.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccessRequest(false)}
            open={successRequest}
          >
            <Alert severity="success">İletişime geçme talebiniz başarıyla iletildi.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setSuccessInvite(false)}
            open={successInvite}
          >
            <Alert severity="success">Başarıyla davet edildi.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setMailSend(false)}
            open={mailSend}
          >
            <Alert severity="success">E-posta adresi ile başarıyla paylaşıldı.</Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setCopied(false)}
            open={copied}
          >
            <Alert severity="success">Bağlantı kopyalandı.</Alert>
          </Snackbar>
          <Grid
            container
            xs={12}
            style={{ marginTop: 145, height: 'fit-content', maxWidth: 1350, padding: 16 }}
          >
            {props.authToken &&
              <Breadcrumbs style={{ padding: '0 25px', marginTop: -15 }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <img src={newPerson} style={{ width: 14, marginTop: 5 }} alt="people" />
                <Typography variant="h4">Yatırımcı Profili</Typography>
              </Breadcrumbs>
            }
            <Grid container className={classes.mobilePadding}>
              {investor &&
                <>
                  <Grid xs={12} className={classes.whiteGrid}>
                    {investor.backgroundPhoto && <img src={investor.backgroundPhoto} alt="background" className={classes.backgroundImg} />}
                    <Grid container>
                      {investor.profilePhoto ?
                        <Grid item className={classes.profileGrid} style={investor.backgroundPhoto ? {} : { marginTop: 24 }}>
                          <img src={investor.profilePhoto} alt="background" className={classes.profileImg} />
                        </Grid>
                        :
                        <Grid item className={classes.profileGrid} style={investor.backgroundPhoto ? { background: '#003CA5', width: 112, height: 112, display: 'flex', alignItems: 'center', justifyContent: 'center' } : { background: '#003CA5', width: 112, height: 112, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
                          <Typography variant="h5" style={{ display: 'flex', color: '#fff', fontSize: 36 }}>
                            {investor.firstName.charAt(0)}
                          </Typography>
                        </Grid>
                      }
                      <Grid xs={12} md justify="space-between" style={investor.backgroundPhoto ? { display: 'flex', padding: 20, flexWrap: 'wrap' } : { display: 'flex', padding: 20, marginTop: 24, flexWrap: 'wrap' }}>
                        <Grid item>
                          <Grid item style={{ display: 'flex' }}>
                            <Typography variant="h3" style={{ display: 'flex', marginRight: 8 }}>
                              {investor.firstName} {investor.lastName}
                            </Typography>
                            {props.authToken && investor.investmentType && <GreenTag title={investor.investmentType} />}
                          </Grid>
                          <Typography variant="body2" style={{ color: '#718096' }}>
                            {investor.companyRole}
                          </Typography>
                          <Typography variant="body2" style={{ color: '#718096', marginTop: 2 }}>
                            {investor.city && investor.city.title}/Türkiye
                        </Typography>
                        </Grid>
                        <Grid item xs={12} md="auto" style={{ textAlign: 'right' }}>
                          {investor.socialLinkedIn &&
                            <a href={investor.socialLinkedIn} style={{ padding: 0 }} target="_blank">
                              <img src={linkedinLogo} alt="linkedin" />
                            </a>
                          }
                          {investor.socialTwitter &&
                            <a href={investor.socialTwitter} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                              <img src={twitterLogo} alt="twitter" />
                            </a>
                          }
                          {investor.website &&
                            <a href={investor.website} style={{ padding: 0, marginLeft: 14 }} target="_blank">
                              <img src={worldIcon} alt="website" />
                            </a>
                          }
                          <Grid container>
                            {props.authToken ?
                              <>
                                <CreateConversation style={{ marginTop: 24, marginRight: 8, paddingLeft: 25, paddingRight: 25 }} userId={props.userId} receivedUserId={data.investorDetail.userId}/>
                                {props.startup ?
                                  <Button
                                    variant="containedPrimary"
                                    onClick={() => setDialogOpen(true)}
                                    style={{ fontWeight: 'normal', padding: '11.5px 25px', marginTop: 24, marginRight: 8, background: '#118DFF' }}
                                  >
                                    {t('İletişime Geç')}
                                  </Button>
                                  :
                                  <Button
                                    variant="containedPrimary"
                                    onClick={() => setStartupError(true)}
                                    style={{ fontWeight: 'normal', padding: '11.5px 25px', marginTop: 24, marginRight: 8, background: '#118DFF' }}
                                  >
                                    {t('İletişime Geç')}
                                  </Button>
                                }
                                {/* <Button
                                  variant="containedPrimary"
                                  style={{ fontWeight: 'normal', padding: '11.5px 25px', marginTop: 24 }}
                                  onClick={handleClick}
                                >
                                  {t('Paylaş')}
                                </Button> */}
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  classes={{ paper: classes.popoverGrid }}
                                  style={{ marginTop: 10 }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <Typography variant="body2" style={{ color: '#718096', fontSize: 12 }}>Public Link Paylaş</Typography>
                                  <Grid container style={{ marginTop: 8, boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)', height: 36 }}>
                                    <Grid item style={{ padding: '8px 16px', width: 250 }}>
                                      <Typography variant="body2" value="Hello World" id="copyText" style={{ color: '#B5B5BD' }}>
                                        /yatirimci/{investor.slug}
                                      </Typography>
                                    </Grid>
                                    <CopyToClipboard text={window.location.origin + '/yatirimci/' + investor.slug}>
                                      <Grid xs style={{ background: '#FFF3E6', cursor: 'pointer', padding: '8px 16px' }} onClick={() => setCopied(true)}>
                                        <Typography variant="h6" style={{ color: '#FF9B28', fontSize: 12 }}>
                                          Linki Kopyala
                                        </Typography>
                                      </Grid>
                                    </CopyToClipboard>
                                  </Grid>
                                  <Grid container style={{ marginTop: 32 }}>
                                    <Grid item>
                                      <TextField
                                        style={{ width: 250, height: 16 }}
                                        variant="outlined"
                                        placeholder={'E-posta adresi'}
                                        value={mail}
                                        onChange={handleChangeMail}
                                      />
                                    </Grid>
                                    {mail ?
                                      <Grid xs style={{ background: '#FFF3E6', cursor: 'pointer', padding: '11px 16px', textAlign: 'center' }} onClick={() => setMailPopup(true)}>
                                        <Typography variant="h6" style={{ color: '#FF9B28', fontSize: 12 }}>
                                          Gönder
                                      </Typography>
                                      </Grid>
                                      :
                                      <Grid xs style={{ background: '#dfdfdf9c', padding: '11px 16px', textAlign: 'center' }}>
                                        <Typography variant="h6" style={{ color: '#FF9B28', fontSize: 12 }}>
                                          Gönder
                                      </Typography>
                                      </Grid>
                                    }
                                  </Grid>
                                  <Typography variant="body2" style={{ color: '#718096', fontSize: 12, marginTop: 16 }}>Sosyal Medyada paylaş</Typography>
                                  <Grid container style={{ marginTop: 8 }}>
                                    <LinkedinShareButton
                                      windowWidth={750}
                                      windowHeight={600}
                                      url={window.location.origin + '/yatirimci/' + investor.slug}
                                      title={window.location.origin + '/yatirimci/' + investor.slug}
                                      summary="Yatırımcı profili detaylarını görebilirsiniz.">
                                      <Button className={classes.twitterButton}>
                                        <img src={linkedinBlueLogo} alt="linkedin-logo" />
                                      </Button>
                                    </LinkedinShareButton>
                                    <TwitterShareButton
                                      url={window.location.origin + '/yatirimci/' + investor.slug}>
                                      <Button className={classes.twitterButton} style={{ marginLeft: 12 }}>
                                        <img src={twitterBlueLogo} alt="twitter-logo" />
                                      </Button>
                                    </TwitterShareButton>
                                  </Grid>
                                </Popover>
                              </>
                              :
                              <Link style={{ padding: 0 }} to={{
                                pathname: `/sign-up`,
                                state: { openPopup: true },
                              }}
                              >
                                <Button
                                  variant="containedPrimary"
                                  style={{ fontWeight: 'normal', padding: '11.5px 25px', marginTop: 24 }}
                                >
                                  {t('İletişime Geç')}
                                </Button>
                              </Link>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container style={{ padding: 40 }}>
                      <Grid container justify="space-between" alignItems="center">
                        {investor.biography && investor.biography.length > 0 &&
                          <Typography align="left" variant="h5">
                            {t('Hakkında')}
                          </Typography>
                        }
                      </Grid>
                      <Typography align="left" variant="body2" style={{ marginTop: 8 }}>
                        {investor.biography}
                      </Typography>
                    </Grid>
                  </Grid>
                  {props.authToken && (investor.portfolioCompanyCount || investor.exitedCompanyCount || (investor.interestedIndustries && investor.interestedIndustries.length > 0)) &&
                    <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px' }}>
                      <Typography align="left" variant="h4">
                        {t('Yatırım Bilgileri')}
                      </Typography>
                      <Grid container style={{ marginTop: 16 }}>
                        {(investor.exitedCompanyCount || (typeof investor.exitedCompanyCount === 'number')) &&
                          <Grid item xs={12} md={6}>
                            <Typography align="left" variant="body1" style={{ marginBottom: 12 }}>
                              {t('Exit yapan şirketler')}
                            </Typography>

                            <GreenTag title={investor.exitedCompanyCount} />
                          </Grid>
                        }
                        {(investor.portfolioCompanyCount || (typeof investor.portfolioCompanyCount === 'number')) &&
                          <Grid item xs={12} md={6} className={investor.exitedCompanyCount && classes.portfoyCompany}>
                            <Typography align="left" variant="body1" style={{ marginBottom: 12 }}>
                              {t('Portföy Şirket Sayısı')}
                            </Typography>
                            <GreenTag title={investor.portfolioCompanyCount} />
                          </Grid>
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                      </Grid>
                      <Grid container style={{ marginTop: 16 }}>
                        <Grid item xs={12}>
                          <Grid container justify="space-between" alignItems="center">
                            {investor.interestedIndustries && investor.interestedIndustries.length > 0 &&
                              <Typography align="left" variant="body1">
                                {t('İlgilenilen Sektörler')}
                              </Typography>
                            }
                          </Grid>
                          {investor.interestedIndustries && investor.interestedIndustries.length > 0 &&
                            <Grid container xs={12} sm spacing={1} style={{ marginTop: 10 }}>
                              {investor.interestedIndustries.map((ind, index) => (
                                <Grid item>
                                  <GreenTag title={ind} />
                                </Grid>
                              ))}
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {(careerList && careerList.length) > 0 &&
                    <>
                      {!careerList || (careerList && careerList.length) < 5 ?
                        <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px' }}>
                          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                            <Typography align="left" variant="h4">
                              {t('Deneyimler')}
                            </Typography>
                          </Grid>
                          <>
                            {careerList && careerList.length > 0 && careerList.sort(function compare(a, b) {
                              var dateA = new Date(a.startDate);
                              var dateB = new Date(b.startDate);
                              return dateB - dateA;
                            }).map((careerItem, index) => (
                              <>
                                <CareerPart
                                  setRemove={setRemove}
                                  setRemoveItem={setRemoveItem}
                                  careerItem={careerItem}
                                  careerList={careerList}
                                  setSuccess={setSuccess}
                                  index={index}
                                  refetch={refetch}
                                  myInvestorProfileSettings={props.myInvestorProfileSettings}
                                />
                                <Grid item xs={12}>
                                  <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                </Grid>
                              </>
                            ))}
                          </>
                        </Grid>
                        :
                        careerShow ?
                          <>
                            <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                                <Typography align="left" variant="h4">
                                  {t('Deneyimler')}
                                </Typography>
                              </Grid>
                              <>
                                {careerList && careerList.length > 0 && careerList.sort(function compare(a, b) {
                                  var dateA = new Date(a.startDate);
                                  var dateB = new Date(b.startDate);
                                  return dateB - dateA;
                                }).map((careerItem, index) => (
                                  <>
                                    <CareerPart
                                      setRemove={setRemove}
                                      setRemoveItem={setRemoveItem}
                                      careerItem={careerItem}
                                      careerList={careerList}
                                      setSuccess={setSuccess}
                                      index={index}
                                      refetch={refetch}
                                      myInvestorProfileSettings={props.myInvestorProfileSettings}

                                    />
                                    <Grid item xs={12}>
                                      <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                    </Grid>
                                  </>
                                ))}
                              </>
                            </Grid>
                            <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32 }}>
                              <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 10 }} onClick={() => setCareerShow(false)}>
                                <img src={hideInvestorAccordion} alt="hide-accordion" style={{ marginRight: 8 }} />
                            Gizle
                        </Typography>
                            </Grid>
                          </>
                          :
                          <>
                            <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                                <Typography align="left" variant="h4">
                                  {t('Deneyimler')}
                                </Typography>
                              </Grid>
                              <>
                                {careerList && careerList.length > 0 && careerList.sort(function compare(a, b) {
                                  var dateA = new Date(a.startDate);
                                  var dateB = new Date(b.startDate);
                                  return dateB - dateA;
                                }).map((careerItem, index) => (
                                  index < 4 &&
                                  <>
                                    <CareerPart
                                      setRemove={setRemove}
                                      setRemoveItem={setRemoveItem}
                                      careerItem={careerItem}
                                      setSuccess={setSuccess}
                                      careerList={careerList}
                                      index={index}
                                      refetch={refetch}
                                      myInvestorProfileSettings={props.myInvestorProfileSettings}
                                    />
                                    <Grid item xs={12}>
                                      <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                    </Grid>
                                  </>
                                ))}
                              </>
                            </Grid>
                            <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32, borderBottomRightRadius: 16, borderBottomLeftRadius: 16, }}>
                              <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 11, textTransform: 'uppercase', }} onClick={() => {
                                setCareerShow(true);
                                setCompanyShow(false);
                              }}>
                                <img src={showInvestorAccordion} alt="show-accordion" style={{ marginRight: 8 }} />
                            Tümünü Göster
                          </Typography>
                            </Grid>
                          </>
                      }
                    </>
                  }
                  {selected && selected.length > 0 &&
                    <>
                      {selected && selected.length < 5 ?
                        <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px' }}>
                          <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                            <Typography align="left" variant="h4">
                              {t('Portföyündeki Şirketler')}
                            </Typography>
                          </Grid>
                          <>
                            {selected && selected.length > 0 && selected.map((portfolioItem, index) => (
                              <>
                                <PortfolioPart
                                  portfolioItem={portfolioItem}
                                  index={index}
                                  setRemoveCompany={setRemoveCompany}
                                  setRemoveItem={setRemoveItem}
                                />
                                {selected.length !== index + 1 &&
                                  <Grid item xs={12}>
                                    <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                  </Grid>
                                }
                              </>
                            ))}
                          </>
                        </Grid>
                        :
                        companyShow ?
                          <>
                            <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                                <Typography align="left" variant="h4">
                                  {t('Portföyündeki Şirketler')}
                                </Typography>
                              </Grid>
                              <>
                                {selected && selected.length > 0 && selected.map((portfolioItem, index) => (
                                  <>
                                    <PortfolioPart
                                      portfolioItem={portfolioItem}
                                      index={index}
                                      setRemoveCompany={setRemoveCompany}
                                      setRemoveItem={setRemoveItem}
                                    />
                                    {selected.length !== index + 1 &&
                                      <Grid item xs={12}>
                                        <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                      </Grid>
                                    }
                                  </>
                                ))}
                              </>
                            </Grid>
                            <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32 }}>
                              <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 10 }} onClick={() => setCompanyShow(false)}>
                                <img src={hideInvestorAccordion} alt="hide-accordion" style={{ marginRight: 8 }} />
                            Gizle
                        </Typography>
                            </Grid>
                          </>
                          :
                          <>
                            <Grid xs={12} className={classes.whiteGrid} style={{ padding: '24px 40px', marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 24 }}>
                                <Typography align="left" variant="h4">
                                  {t('Portföyündeki Şirketler')}
                                </Typography>
                                <img src={plusIcon} alt="plus-icon" style={{ cursor: 'pointer' }} />
                              </Grid>
                              <>
                                {selected && selected.length > 0 && selected.map((portfolioItem, index) => (
                                  index < 4 &&
                                  <>
                                    <PortfolioPart
                                      portfolioItem={portfolioItem}
                                      index={index}
                                      setRemoveCompany={setRemoveCompany}
                                      setRemoveItem={setRemoveItem}
                                    />
                                    {selected.length !== index + 1 &&
                                      <Grid item xs={12}>
                                        <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', margin: 16 }} />
                                      </Grid>
                                    }
                                  </>
                                ))}
                              </>
                            </Grid>
                            <Grid container justify="center" alignItems="center" style={{ background: '#F0F5FF', height: 40, marginBottom: 32, borderBottomRightRadius: 16, borderBottomLeftRadius: 16, }}>
                              <Typography variant="h6" color="primary" style={{ display: 'flex', cursor: 'pointer', fontSize: 11, textTransform: 'uppercase' }} onClick={() => {
                                setCareerShow(false);
                                setCompanyShow(true);
                              }}>
                                <img src={showInvestorAccordion} alt="show-accordion" style={{ marginRight: 8 }} />
                            Tümünü Göster
                          </Typography>
                            </Grid>
                          </>
                      }
                    </>
                  }
                </>
              }
              <Grid container justify="center" style={{ marginTop: 32 }}>
                <Typography variant="body2" align="center" style={{ color: '#718096' }}>
                  Startup Borsa, bir <a href="https://www.gedik.com/" target="_blank" style={{ fontWeight: 600, padding: 0, color: 'rgb(113, 128, 150)', fontSize: 16 }}>Gedik Yatırım</a> kuruluşudur.
                </Typography>
              </Grid>
              {investor &&
                <RequestDialog currency={props.startup && props.startup.currency} onClose={() => setDialogOpen(false)} setSuccessRequest={setSuccessRequest} open={dialogOpen} investor={investor} firstName={props.firstName} lastName={props.lastName} startup={props.startup && props.startup} startupSendInvestmentRequest={props.startupSendInvestmentRequest} />
              }
              <MailDialog open={mailPopup} setMailSend={setMailSend} onClose={() => setMailPopup(false)} mail={mail} investor={investor} investorShareProfileWithMail={props.investorShareProfileWithMail} />
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}