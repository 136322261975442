import ReportDetail from './ReportDetail'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const EDIT_REPORT_STATUS = gql`
  mutation startupReportEditStatus($id: ID!, $status: String!) {
    startupReportEditStatus(id: $id, status: $status) 
  }
`;

const DELETE_REPORT = gql`
  mutation startupReportDelete($id:ID!) {
    startupReportDelete(id: $id) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(EDIT_REPORT_STATUS, {
    name: 'startupReportEditStatus',
  }),
  graphql(DELETE_REPORT, {
    name: 'startupReportDelete',
  }),
)(ReportDetail);
export default JoinWithMutation;