import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";

import {Button, Snackbar} from "@material-ui/core";

import {Alert} from "@material-ui/lab";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Photo1 from "../../static/img/startupLanding1.png";
import Photo2 from "../../static/img/startupLanding2.png";
import Popups from "../../Components/Popups";
import React from "react";
import Slider from "@material-ui/core/Slider";
import Slider2 from "react-slick";
import Typography from "@material-ui/core/Typography";
import blog1 from "../../static/img/blog1.jpg";
import blog2 from "../../static/img/blog2.jpg";
import blog3 from "../../static/img/blog3.jpg";
import clsx from "clsx";
import get from "lodash/get";
import gql from "graphql-tag";
import {makeStyles} from "@material-ui/core/styles";
import newHome from "../../static/icon/new-home-blue.svg";
import order from "../../static/img/order.png";
import time from "../../static/icon/time.svg";
import {useQuery} from "@apollo/react-hooks";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  root: {
    display: "flex"
  },
  blueGrid: {
    padding: "24px",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    background: "#fff"
  },
  whiteGrid: {
    background: "#ffffff",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    marginBottom: 25
  },
  yatırımButton: {
    fontWeight: "normal",
    padding: "11.5px 47px",
    fontSize: 12,
    marginTop: 16
  },
  socialLink: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 25
    },
    color: "#003CA5"
  },
  companyCard: {
    padding: 16,
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    borderRadius: 16,
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0
    }
  },
  whiteCard: {
    minHeight: 230,
    background: "#ffffff",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    padding: 50,
    [theme.breakpoints.down("lg")]: {
      padding: 35
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "0",
      padding: 25
    }
  },
  blogInfo: {
    paddingLeft: 16,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginTop: 16
    }
  },
  threeGrid: {
    padding: "30px 30px 20px 30px",
    borderRadius: "4px",
    marginTop: 30,
    minHeight: 280,
    [theme.breakpoints.down("lg")]: {
      minHeight: 345,
      overflow: "auto"
    }
  },
  gridLink: {
    display: "flex",
    alignItems: "center",
    marginTop: 25,
    padding: 0
  },
  gridImage: {
    backgroundImage: "url(" + order + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "250px",
    width: "100%"
  },
  img: {
    objectFit: "contain",
    width: 80,
    height: 80,
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 60
    }
  },
  imgEmpty: {
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#003CA5",
    [theme.breakpoints.down("sm")]: {
      width: 60,
      height: 60
    }
  },
  rightWhite: {
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    padding: 25,
    height: "fit-content",
    background: "#fff"
  },
  cardGrid: {
    margin: 0
  },
  mainContainer: {
    width: 100,
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    padding: "0px 15px 0 24px",
    maxHeight: 600,
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  center: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "8px 20px!important;"
    },
    height: "fit-content"
  },
  noMargin: {
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },
  mobilePadding: {
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 6px"
    }
  },
  imgGrid: {
    borderRadius: "10px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #eaedf3",
    maxWidth: 80,
    maxHeight: 80,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 60,
      maxHeight: 60
    }
  },
  titleBar: {
    width: "fit-content",
    marginLeft: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: 25
    }
  },
  titleFlex: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: 25
    }
  },
  model: {
    color: "#777777",
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    fontSize: 16,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 30,
      marginBottom: 4,
      width: "fit-content"
    }
  },
  oneliner: {
    color: "#777777",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "75%"
    }
  },
  Title: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: "left",
    color: "#172b4d",
    wordBreak: "break-word"
  }
}));

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

export default function Dashboard(props) {
  const classes = useStyles();
  const [showMessage, setShowMessage] = React.useState(false);
  const [guide, setGuide] = React.useState(false);
  const [guideReport, setGuideReport] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [startups, setStartups] = React.useState([]);
  const [startupsCount, setStartupsCount] = React.useState();
  const {t} = useTranslation();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

  if (props.location.state && props.location.state.haveMessage && !message) {
    if (props.location.state.message && props.location.state.message.type && props.location.state.message.text) {
      setShowMessage(true);
      setMessage(props.location.state.message);
      window.history.replaceState({}, document.title);
    }
  }

  const ALL_STARTUPS = gql`
    query allStartupsPaginate($page: Int, $pageSize: Int) {
      allStartupsPaginate(pagination: {page: $page, pageSize: $pageSize}) {
        count
        pageCount
        startups {
          id
          slug
          title
          logo
          oneliner
          businessModelArray
        }
      }
    }
  `;
  const {loading, data, refetch} = useQuery(ALL_STARTUPS, {
    fetchPolicy: "network-only",
    variables: {page: 1, pageSize: 4}
  });
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (props.setRoleChange) {
          if (props.getRole === "StartupContact") {
            props.setRoleChange("StartupContact");
          } else {
            props.setRoleChange("Investor");
          }
        }
        setStartups(data.allStartupsPaginate.startups);
        setStartupsCount(data.allStartupsPaginate.count);
      }
    }
  }, [data, loading]);

  React.useEffect(() => {
    if (!guide) {
      // if (props.getRole !== 'Investor' && (window.location.href.indexOf("dashboard.startupborsa") > -1) && window.userGuiding) {
      //   window.userGuiding.previewGuide(23701, {
      //     checkHistory: true,
      //   });
      //   setGuide(true);
      // }
    }
  }, [guide]);

  // if (!guideReport && props.ugCreatedReport === "Waiting" && props.getRole !== 'Investor' && (window.location.href.indexOf("dashboard.startupborsa") > -1) && window.userGuiding) {
  //   window.userGuiding.previewGuide(23710, {
  //     checkHistory: true,
  //   });
  //   props.userGuideChangeStatus({ variables: { type: "CreatedReport", status: "Seen" } });
  //   setGuideReport(true)
  // };

  const handleOnCloseSnackbar = event => {
    setShowMessage(false);
  };
  return (
    <>
      <Popups />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
        })}
      >
        {props.roleChange && (
          <Grid container style={{background: "#f7f7fc", justifyContent: "center", minHeight: "100vh"}}>
            <Grid
              className={classes.mainContainer}
              xs={12}
              style={{marginTop: 145, height: "fit-content", maxWidth: 1350}}
            >
              {!props.startup ||
                (props.roleChange === "Investor" && (
                  <Breadcrumbs
                    style={{padding: "0 13px", marginBottom: 36}}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <img src={newHome} style={{width: 16}} alt="bag" />
                    <Typography variant="h4">Anasayfa</Typography>
                  </Breadcrumbs>
                ))}

              {!props.startup || props.roleChange === "Investor" ? (
                <Grid container xs={12} className={classes.noMargin} spacing={3}>
                  <Grid
                    style={{
                      height: "fit-content",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                    item
                    xs={12}
                    md={8}
                  >
                    <Grid className={classes.mobilePadding} style={{height: "fit-content"}} xs={12}>
                      <Grid className={classes.blueGrid} xs={12} style={{minHeight: 236}}>
                        <Typography variant="h5">
                          {t("Merhaba")}, {props.firstName} {props.lastName}!
                        </Typography>
                        <Typography variant="body1" style={{marginTop: 20}}>
                          {t("Girişimcilik dünyasının buluşma noktası Startup Borsa'ya hoşgeldiniz!")}
                        </Typography>
                        <Typography variant="h6" style={{marginTop: 25}}>
                          {t("Startup Borsa Ekibi")}
                        </Typography>
                        <Link style={{padding: 0}} to={`/girisimler`}>
                          <Button variant="containedPrimary" className={classes.yatırımButton}>
                            {t("Keşfet")}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid className={classes.mobilePadding} style={{marginTop: 38}} xs={12}>
                      <Grid
                        className={classes.whiteGrid}
                        style={{padding: "20px 23px 35px", minHeight: 200, marginTop: 8}}
                        xs={12}
                      >
                        <Slider2 {...settings} style={{textAlign: "left"}}>
                          <Grid>
                            <Grid container>
                              <Grid item style={{display: "flex!important"}}>
                                <a
                                  href="https://startupborsa.com/girisimci-bilgi-seti/girisimci-bilgi-seti-seri/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <img src={blog1} alt="blog-1" style={{height: 150, borderRadius: 16}} />
                                </a>
                              </Grid>
                              <Grid xs={12} sm className={classes.blogInfo}>
                                <Typography variant="h5">
                                  <a
                                    href="https://startupborsa.com/girisimci-bilgi-seti/girisimci-bilgi-seti-seri/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      padding: 0,
                                      fontSize: "16px",
                                      lineHeight: "25px",
                                      fontFamily: "InterSemiBold",
                                      color: "#27272E",
                                      fontWeight: "500"
                                    }}
                                  >
                                    Girişimci Bilgi Seti - Seri 1: KPI
                                  </a>
                                </Typography>
                                <Typography style={{marginTop: 16, color: "#777777", fontSize: 15}} variant="body1">
                                  Startup Borsa 'da Girişimcilerin İhtiyacı olan Temel Bilgiler Serisi'nin ilk yazısında
                                  sizler için KPI konusunu işledik.
                                </Typography>
                                <Grid style={{marginTop: 16}} container>
                                  <Typography variant="body2" style={{marginRight: 40}}>
                                    Startup Borsa{" "}
                                  </Typography>
                                  <Typography variant="body2" style={{display: "flex"}}>
                                    <img src={time} style={{width: 20, marginRight: 10}} alt="time" />3 dakikalık okuma{" "}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Grid container>
                              <Grid item style={{display: "flex!important"}}>
                                <a
                                  href="https://startupborsa.com/startup-ekosistemi/turk-girisim-ekosisteminde-unicorn/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <img src={blog2} alt="blog-2" style={{height: 150, borderRadius: 16}} />
                                </a>
                              </Grid>
                              <Grid xs={12} sm className={classes.blogInfo}>
                                <Typography variant="h5">
                                  <a
                                    href="https://startupborsa.com/startup-ekosistemi/turk-girisim-ekosisteminde-unicorn/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      padding: 0,
                                      fontSize: "16px",
                                      lineHeight: "25px",
                                      fontFamily: "InterSemiBold",
                                      color: "#27272E",
                                      fontWeight: "500"
                                    }}
                                  >
                                    Unicorn Sayısı Türk Girişimcilik Ekosisteminde Neden Çok Az?
                                  </a>
                                </Typography>
                                <Typography style={{marginTop: 16, color: "#777777", fontSize: 15}} variant="body1">
                                  Türkiye'den bu güne kadar yalnızca bir unicorn çıktı. Bunun sebeplerinin başında
                                  inovasyon eksikliği geliyor. Ekosistemde başka ne eksik?
                                </Typography>
                                <Grid style={{marginTop: 16}} container>
                                  <Typography variant="body2" style={{marginRight: 40}}>
                                    Startup Borsa{" "}
                                  </Typography>
                                  <Typography variant="body2" style={{display: "flex"}}>
                                    <img src={time} style={{width: 20, marginRight: 10}} alt="time" />2 dakikalık okuma{" "}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Grid container>
                              <Grid item style={{display: "flex!important"}}>
                                <a
                                  href="https://startupborsa.com/startup-ekosistemi/girisimlerde-finansal-raporlamanin-puf-noktalari/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    padding: 0
                                  }}
                                >
                                  <img src={blog3} alt="blog-3" style={{height: 150, borderRadius: 16}} />
                                </a>
                              </Grid>
                              <Grid xs={12} sm className={classes.blogInfo}>
                                <Typography variant="h5">
                                  <a
                                    href="https://startupborsa.com/startup-ekosistemi/girisimlerde-finansal-raporlamanin-puf-noktalari/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      padding: 0,
                                      fontSize: "16px",
                                      lineHeight: "25px",
                                      fontFamily: "InterSemiBold",
                                      color: "#27272E",
                                      fontWeight: "500"
                                    }}
                                  >
                                    Girişimlerde Finansal Raporlamanın Püf Noktaları
                                  </a>
                                </Typography>
                                <Typography style={{marginTop: 16, color: "#777777", fontSize: 15}} variant="body1">
                                  Girişimlerinizi tanıtırken ve yatırımcıları ikna ederken ihtiyacınız olan raporlama
                                  sistemi için gerekli olanları sizin için derledik.
                                </Typography>
                                <Grid style={{marginTop: 16}} container>
                                  <Typography variant="body2" style={{marginRight: 40}}>
                                    Startup Borsa{" "}
                                  </Typography>
                                  <Typography variant="body2" style={{display: "flex"}}>
                                    <img src={time} style={{width: 20, marginRight: 10}} alt="time" />3 dakikalık okuma{" "}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Slider2>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      height: "fit-content",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                    item
                    xs={12}
                    md={4}
                  >
                    <Grid xs={12}>
                      <Grid className={classes.rightWhite} xs={12}>
                        <Grid container alignItems="center" justify="space-between" style={{marginBottom: 16}}>
                          <Link style={{padding: 0, display: "flex", alignItems: "center"}} to="/girisimler">
                            <Typography variant="h5" style={{marginRight: 5}}>
                              {t("Son Eklenen Girişimler")}({startupsCount})
                            </Typography>
                          </Link>
                          <Link style={{padding: 0, display: "flex", alignItems: "center"}} to="/girisimler">
                            <Typography variant="h6" style={{color: "#118DFF"}}>
                              Tümünü Gör
                            </Typography>
                          </Link>
                        </Grid>
                        {startups.map(startup => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              className={classes.companyCard}
                              component={Link}
                              to={`/girisim/${startup.slug}`}
                            >
                              <Grid item>
                                {startup.logo ? (
                                  <img
                                    src={startup.logo}
                                    alt="logo"
                                    style={{
                                      width: "46px",
                                      height: "46px",
                                      objectFit: "contain",
                                      marginRight: 8,
                                      borderRadius: 12
                                    }}
                                  />
                                ) : (
                                  <Grid
                                    style={{
                                      width: 46,
                                      height: 46,
                                      borderRadius: "12px",
                                      marginRight: 8,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      background: "#003CA5"
                                    }}
                                  >
                                    <Typography variant="h3" style={{color: "#fff"}}>
                                      {startup.title && startup.title.charAt(0)}
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                              <Grid item xs>
                                <Grid container alignItems="flex-end">
                                  <Typography variant="h5" style={{wordBreak: "break-all"}} align="left">
                                    {startup.title && truncateString(startup.title, 20)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    align="left"
                                    style={{marginLeft: 10, color: "#777777", fontSize: 12}}
                                  >
                                    {startup.businessModelArray && startup.businessModelArray[0]}
                                  </Typography>
                                </Grid>
                                <Typography variant="body2" align="left" style={{color: "#425466"}}>
                                  {startup.oneliner && truncateString(startup.oneliner, 30)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid className={classes.cardGrid} container spacing={3} xs={12}>
                    <Breadcrumbs
                      style={{padding: "0 15px", marginBottom: 30}}
                      separator={<NavigateNextIcon fontSize="small" />}
                      aria-label="breadcrumb"
                    >
                      <img src={newHome} style={{width: 16}} alt="bag" />
                      <Typography variant="h4">Şirketim Menüsü</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12}>
                      <Grid item className={classes.whiteGrid} style={{marginBottom: 0}} xs={12}>
                        <Grid
                          item
                          container
                          direction="column"
                          justify="end"
                          xs={12}
                          style={{minHeight: 160}}
                          className={classes.whiteCard}
                        >
                          <Grid xs={12} container item justify="end">
                            <Grid className={classes.imgGrid} item>
                              {props.startup.logo ? (
                                <img src={props.startup.logo} className={classes.img} alt="logo"></img>
                              ) : (
                                <Grid className={classes.imgEmpty}>
                                  <Typography variant="h4" style={{color: "#fff"}}>
                                    {props.startup.title && props.startup.title.charAt(0)}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item className={classes.titleBar} xs={12} md>
                              <Grid container alignItems="center" justify="space-between">
                                <Grid item className={classes.titleFlex}>
                                  <Typography className={classes.Title}>{props.startup.title}</Typography>
                                  <Typography className={classes.model} variant="body1" align="left">
                                    {props.startup.businessModel}{" "}
                                  </Typography>
                                  <a
                                    href={props.startup.website}
                                    style={{padding: 0}}
                                    className={classes.socialLink}
                                    target="_blank"
                                  >
                                    <Typography variant="body1" color="primary" align="left" style={{marginTop: 5}}>
                                      {props.startup.website}
                                    </Typography>
                                  </a>
                                </Grid>
                                <Grid container xs={12} md={3} justify="flex-end">
                                  {props.startup.profileFillRate > -1 && (
                                    <Grid container xs={12}>
                                      <Grid container justify="space-between">
                                        <Typography style={{color: "#172b4d", fontWeight: "normal"}} variant="h6">
                                          {t("Profil Doluluk Oranı")}
                                        </Typography>
                                        <Typography style={{color: "#172b4d"}} variant="h6" id="disabled-slider">
                                          %{props.startup.profileFillRate}
                                        </Typography>
                                      </Grid>
                                      <Slider
                                        disabled
                                        defaultValue={props.startup.profileFillRate}
                                        aria-labelledby="disabled-slider"
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              {props.startup.oneliner ? (
                                <Typography className={classes.oneliner} variant="body2" align="left">
                                  {props.startup.oneliner}
                                </Typography>
                              ) : (
                                <Typography className={classes.oneliner} variant="body2" align="left"></Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid
                        item
                        className={classes.whiteGrid}
                        xs={12}
                        style={{textAlign: "center", padding: 10, minHeight: 438}}
                      >
                        <img src={Photo1} alt="photo1" style={{width: "250px"}} />
                        <Typography variant="h5">{t("Keşfedilen Girişimlerden Biri Ol")}</Typography>
                        <Typography variant="body2" style={{marginTop: 20, color: "#777777"}}>
                          {t(
                            "Profilinide girişiminize dair en güncel bilgileri paylaşın ve girişiminiz keşfedilmesini sağlayın."
                          )}
                        </Typography>
                        <Link
                          style={{padding: 0}}
                          to={`/girisim/${props.startupSlug ? props.startupSlug : props.startupId}`}
                        >
                          <Button
                            variant="containedPrimary"
                            style={{margin: "20px 0", fontSize: 14, padding: "11.5px 20px"}}
                          >
                            {t("Şirket Bilgilerini Güncelle")}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid
                        item
                        className={classes.whiteGrid}
                        xs={12}
                        style={{textAlign: "center", padding: 10, minHeight: 438}}
                      >
                        <img src={Photo2} alt="photo1" style={{width: "250px"}} />
                        <Typography variant="h5">{t("KPI’larınızı Yönetin")}</Typography>
                        <Typography variant="body2" style={{marginTop: 20, color: "#777777"}}>
                          {t(
                            "Startup Borsa'nın farklı iş modellerine yönelik oluşturduğu hazır KPI listelerinden birini seçebilir veya girişiminize özel KPI listesi oluşturabilirsiniz."
                          )}
                        </Typography>
                        <Link style={{padding: 0}} to={`/verilerim`}>
                          <Button
                            variant="containedPrimary"
                            style={{margin: "20px 0", fontSize: 14, padding: "11.5px 20px"}}
                          >
                            {t("Yeni KPI Ekle")}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </main>
      {message ? (
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{vertical: "top", horizontal: "center"}}
          onClose={handleOnCloseSnackbar}
          open={showMessage}
        >
          <Alert severity={message.type}>{message.text}</Alert>
        </Snackbar>
      ) : null}
    </>
  );
}
