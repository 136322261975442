import { TextField, Typography, Grid } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import useFastField from './useFastField.js'
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) =>({
    inputField: {
        width: '100%',
        marginTop: 10,
    },
}));

function NumberFormatPercentage(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        isAllowed={(values) => {
          if(values){
            let {floatValue} = values;
            if(floatValue === undefined){
              floatValue = 0;
            }
            return floatValue >= 0 && floatValue <= 100;
          }
        }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        suffix="%"
      />
    );
  }


export default function FormikPercentageField({ type,label,inputProps,maxLen, ...props }){
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
    <Grid style={{minHeight:95}}>
        <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            inputProps={{
                maxLength: maxLen
            }}
            InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
        />

            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4,textAlign:'left' }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  }