
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    mainGrid:{
        background: '#DEFFEE',
        borderRadius: '6px',
        padding:'2px 10px',
        width:'max-content'
        
    },
    text:{
        fontSize:10,
        color: '#66CB9F'
    }
}));

export default function GreenTag(props) {
    const classes = useStyles();

    return (
            <Grid container item className={classes.mainGrid}>
                <Typography variant="h5" align='center' className={classes.text}>{props.title}</Typography>
            </Grid>
    );
  }