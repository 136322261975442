import { ADMIN_HANDLE_INV_REQUEST, ADMIN_SET_INVESTMENT_REQUEST_VISIBILITY, HANDLE_INV_REQUEST } from '../../mutations';
import {
  ADMIN_PENDING_INVESTMENT_REQUESTS,
  GET_ACTIVE_INVESTMENT_SPRINT_DETAIL,
  GET_PENDING_INVESTMENT_REQUESTS,
} from '../../queries';
import {
  Avatar,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getFormattedDateFromUnix, numberWithPeriods, resolveCurrencySymbol } from '../../helpers';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { AntSwitch } from '../../Components/AntSwitch';
import ColorfulTag from '../../Components/ColorfulTag';
import Confetti from 'react-confetti';
import Hidden from '@material-ui/core/Hidden';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import get from 'lodash/get';
import newCross from '../../static/icon/new-cross.svg';
import newHome from '../../static/icon/new-home.svg';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  tabRoot: {
    minHeight: 40,
    padding: '24px',
    fontWeight: 500,
  },
  paper: {
    /* Rectangle 3323 */
    height: '115px',

    /* Dashboard/White/Default */

    background: '#FFFFFF',
    /* Drop Shadow */

    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '8px',

    textAlign: 'center',

    /*[theme.breakpoints.down('xs')]: {
      width: '350px',
      //marginLeft: '100%',
    },*/
  },
  longPaper: {
    backgroundColor: '#FAFAFB',
    width: '100%',
    display: 'flex',
    height: '56px',
    borderBottom: '2px solid #EDF2F7',
    boxShadow: 'none',
    /*[theme.breakpoints.down('xs')]: {
      paddingRight: '550px',
    },*/
  },
  h1: {
    color: '#118DFF',
    margin: '16px 0px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '9px',
    fontSize: '28px',
    padding: '14px',
  },
  userPaper: {
    marginTop: '1%',
    width: '100%',
    height: '80px',
    /* Theme/White/Default */

    background: '#FFFFFF',
    /* Drop Shadow */

    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '16px',
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      paddingRight: '85%',
      width: '550px',
    },
  },
  userPaper2: {
    marginTop: '12px',
    width: '100%',
    height: '80px',
    padding: '8px',
    /* Theme/White/Default */

    background: '#FFFFFF',
    /* Drop Shadow */

    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '16px',
    //display: 'flex',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '155px',
    },
  },
  userPaperName: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    //margin: 'auto 0',
    //marginLeft: '1%',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    color: '#425466',
    //textAlign: 'center',
  },
  textArea: {
    '& > *': {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  longPaperText1: {
    //margin: 'auto 0',
    //textAlign: 'center',

    marginLeft: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '16px',
    color: '#8492A6',
    textTransform: 'uppercase',

    [theme.breakpoints.down('xs')]: {
      //marginLeft: '20px',
    },
  },
  longPaperText2: {
    // margin: 'auto 0',
    //textAlign: 'center',

    //marginLeft: '20%',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#8492A6',
    [theme.breakpoints.down('xs')]: {
      //textAlign: 'center',
    },
  },
  longPaperText3: {
    //margin: 'auto 0',
    //textAlign: 'center',
    //marginLeft: '12%',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '16px',
    color: '#8492A6',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      //marginLeft: '100px',
    },
  },
  mainGrid: {
    background: '#f7f7fc',
    justifyContent: 'center',
    minHeight: '100vh',
    paddingLeft: '2%',
    paddingRight: '2%',
    Width: '100%',
  },
  paperText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '9px',
    textAlign: 'center',
    display: 'flex',
    color: '#118DFF',
  },
  fourCardsGrid: {
    marginTop: '2%',

    /*[theme.breakpoints.down('xs')]: {
      display: 'grid',
      textAlign: 'center',
    },*/
  },
  userPaperButtons: {
    borderRadius: '8px',
    height: '36px',
    width: '158px',
    margin: '0px 8px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  userPaperButtons1: {
    borderRadius: '8px',
    height: '36px',
    width: '158px',
    margin: '0px 8px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#003CA5',
    background: '#F0F6FF',
    '&:hover': {
      background: '#DEFFEE',
      color: '#66CB9F',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  userPaperDate: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '25px',
    color: '#425466',
    //textAlign: 'center',

    //margin: 'auto 0',
    //marginLeft: '12%',
    [theme.breakpoints.up('xl')]: {
      //marginLeft: '18%',
    },
    [theme.breakpoints.up('lg')]: {
      //marginLeft: '16%',
    },
  },
  userPaperInvestment: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '25px',
    color: '#425466',
    //margin: 'auto 0',
    //marginLeft: '12%',
    //textAlign: 'center',

    /*[theme.breakpoints.up('xl')]: {
      marginLeft: '18%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '16%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '13%',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '5%',
    },*/
  },
  buttonsGrid: {
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      display: '',
      textAlign: 'center',
      marginLeft: '25%',
    },
  },
  progressBarText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '8px',
    display: 'flex',
    color: '#118DFF',
  },
  boxTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '11px',
    lineHeight: '12px',
    color: '#8492A6',
    textTransform: 'uppercase',
    paddingLeft: 14,
    paddingRİght: 14,
  },
  progressText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '8px',
    color: '#118DFF',
    marginBottom: 9,
  },
  dialogDescription: {
    paddingLeft: 32,
    paddingRight: 32,
    fontWeight: 100,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#425466',
  },

  dialogButton: {
    marginBottom: 16,
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: '220px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,
      maxWidth: '120px',
    },
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 40,
    border: '1px solid #118DFF',
  },
  colorPrimary: {
    backgroundColor: '#FAFBFD', //theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'background: linear-gradient(270deg, #118DFF 4.72%, #003CA5 98.33%)',
  },
}))(LinearProgress);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function GetInvestment(props) {
  const size = useWindowSize();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const [declined, setDeclined] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [offerID, setOfferID] = React.useState('');
  const [reasonText, setReasonText] = React.useState('');
  const { t, i18n } = useTranslation();

  const isAdmin = props.isAdmin;
  const myStartupId = props.startupId;
  const { id: paramId } = useParams();

  const startupId = isAdmin ? paramId : myStartupId;

  //****************QUERIES****************
  const { data } = useQuery(GET_ACTIVE_INVESTMENT_SPRINT_DETAIL, { variables: { startupId: startupId } });

  const { data: invRequests } = useQuery(GET_PENDING_INVESTMENT_REQUESTS, {
    variables: { startupId: startupId },
    skip: isAdmin,
  });

  const { data: adminInvRequests } = useQuery(ADMIN_PENDING_INVESTMENT_REQUESTS, {
    variables: { startupId: startupId },
    skip: !isAdmin,
  });

  const investmentRequests = isAdmin
    ? adminInvRequests?.adminPendingStartupInvestmentRequests
    : invRequests?.pendingStartupInvestmentRequests;

  //++++++++++++++++MUTATIONS+++++++++++++++++++++
  const [startupResponseInvestmentRequest] = useMutation(HANDLE_INV_REQUEST, {
    refetchQueries: [
      { query: GET_PENDING_INVESTMENT_REQUESTS, variables: { startupId: startupId } },
      { query: GET_ACTIVE_INVESTMENT_SPRINT_DETAIL, variables: { startupId: startupId } },
    ],
  });

  const [adminResponseInvestmentRequest] = useMutation(ADMIN_HANDLE_INV_REQUEST, {
    refetchQueries: [
      { query: ADMIN_PENDING_INVESTMENT_REQUESTS, variables: { startupId: startupId } },
      { query: GET_ACTIVE_INVESTMENT_SPRINT_DETAIL, variables: { startupId: startupId } },
    ],
  });

  const [adminSetVisibility] = useMutation(ADMIN_SET_INVESTMENT_REQUEST_VISIBILITY);

  const handleVisibilitySwitch = async (id, oldVal) => {
    const boolVal = !!oldVal;
    const { error } = await adminSetVisibility({ variables: { reqId: id, showToStartup: !boolVal } });
    if (error) {
      console.log(error);
    }
  };

  const handleClickOpen = (id) => {
    setOfferID(id || null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAccepted(false);
    setDeclined(false);
  };

  const handleAccept = async () => {
    setOpen(false);
    setAccepted(true);
    try {
      const { data } = await (isAdmin
        ? adminResponseInvestmentRequest({ variables: { reqId: offerID, status: 'Approved' } })
        : startupResponseInvestmentRequest({ variables: { reqId: offerID, status: 'Approved' } }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDecline = (id) => {
    setOfferID(id || null);
    setDeclined(true);
  };

  const handleConfirmDecline = async () => {
    setDeclined(false);
    try {
      const { data } = await (isAdmin
        ? adminResponseInvestmentRequest({
            variables: { reqId: offerID, status: 'Rejected', rejectMessage: `${reason} - ${reasonText}` },
          })
        : startupResponseInvestmentRequest({
            variables: { reqId: offerID, status: 'Rejected', rejectMessage: `${reason} - ${reasonText}` },
          }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const resolvePercentage = (data) => {
    const totalInvestmentAmount = data?.getActiveInvestmentSprintDetail?.currentInvestmentAmount + data?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment;
    const percentage =
      (totalInvestmentAmount * 100) /
      data?.getActiveInvestmentSprintDetail?.requiredInvestmentAmount;

    return percentage > 100 ? 100 : percentage.toFixed(2);
  };


  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        {/* confetti */}
        {accepted ? <Confetti recycle={accepted} width={size.width} height={size.height} /> : accepted}

        <Grid className={classes.mainGrid} container>
          <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <img src={newHome} style={{ width: 18, marginTop: 3 }} alt="bag" />
              <Typography variant="h4">
                <strong>Yatırım Turu</strong>
              </Typography>
            </Breadcrumbs>

            {/* 4 cards */}
            <Grid className={classes.fourCardsGrid} container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.paper}
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Typography className={classes.boxTitle}> ARANAN YATIRIM TUTARI </Typography>
                  <Typography align="center" variant="h2" className={classes.h1}>
                    {(data &&
                      resolveCurrencySymbol(data?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol) +
                        numberWithPeriods(data?.getActiveInvestmentSprintDetail?.requiredInvestmentAmount)) ||
                      ''}
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper className={classes.paper} style={{ left: '565px', padding: '16px' }}>
                  <Typography className={classes.boxTitle} style={{ marginBottom: 14 }}>
                    KABUL EDİLEN TAAHHÜTLER
                  </Typography>
                  <Grid container xs={12}>
                    <Grid item xs={6}>
                      <Typography variant="h6" align="left" className={classes.progressText}>
                        {(data &&
                          numberWithPeriods(
                            data?.getActiveInvestmentSprintDetail?.currentInvestmentAmount +
                              data?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment,
                          )) ||
                          ''}
                        {resolveCurrencySymbol(data?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" align="right" className={classes.progressText}>
                        %{resolvePercentage(data) || ''}
                      </Typography>
                    </Grid>
                  </Grid>

                  <BorderLinearProgress variant="determinate" value={resolvePercentage(data) || 0} maxValue="100" />
                </Paper>
              </Grid>
            </Grid>

            {/* Divider*/}
            <Grid container style={{ marginTop: '20px' /*backgroundColor: 'transparent'*/ }} item xs={12}>
              <Paper className={classes.longPaper}>
                <Grid /*className={classes.fourCardsGrid}*/
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  container
                  spacing={3}
                  style={{
                    height: '-webkit-fill-available',
                  }}
                >
                  <Grid item xs={4} md={3}>
                    <Typography className={classes.longPaperText1}> YATIRIMCI </Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography className={classes.longPaperText2}> TALEP TARİHi </Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography className={classes.longPaperText3}> TALEP TUTARI </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* List all the offers*/}
            {investmentRequests?.map((request) => (
              <Paper className={classes.userPaper2}>
                <Grid
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  container
                  spacing={3}
                  style={{
                    height: '-webkit-fill-available',
                  }}
                >
                  <Grid item xs={4} md={1}>
                    {request.investor?.profilePhoto ? (
                      <Avatar
                        style={{ margin: 'auto 0', marginLeft: '2%', borderRadius: '14px' }}
                        src={request.investor?.profilePhoto}
                      />
                    ) : (
                      <Avatar
                        t
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: '14px',
                          margin: 'auto 0',
                          marginLeft: '2%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#003CA5',
                        }}
                      >
                        <Typography variant="h4" style={{ color: '#fff', textTransform: 'uppercase' }}>
                          {request?.investor?.firstName?.charAt(0) + request?.investor?.lastName?.charAt(0)}
                        </Typography>
                      </Avatar>
                    )}
                    <Hidden smUp>
                      <Grid item xs={12} md={2}>
                        <Typography className={classes.userPaperName}>
                          <strong>
                            {request.investor?.firstName} {request.investor?.lastName}
                          </strong>
                        </Typography>
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={12} md={2}>
                      <Typography className={classes.userPaperName}>
                        <strong>
                          {request.investor?.firstName} {request.investor?.lastName}
                        </strong>
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Grid item xs={4} md={2}>
                    <Typography className={classes.userPaperDate}>
                      {getFormattedDateFromUnix(request?.createdAt) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography className={classes.userPaperInvestment}>
                      {resolveCurrencySymbol(data?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol) +
                        numberWithPeriods(request?.investmentAmount) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid
                      container
                      justify="center"
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                    >
                      {request.status === 'Pending' && (
                        <>
                          <Button
                            className={classes.userPaperButtons1}
                            //variant="outlined"
                            color="primary"
                            disabled={isAdmin && !request?.showToStartup}
                            onClick={() => handleClickOpen(request?.id)}
                          >
                            Talebi Kabul Et
                          </Button>
                          <Button
                            className={classes.userPaperButtons}
                            style={{
                              color: '#718096',
                            }}
                            variant="outlined"
                            onClick={() => handleClickDecline(request?.id)}
                          >
                            Talebi Reddet
                          </Button>

                          {isAdmin && (
                            <AntSwitch
                              checked={request?.showToStartup}
                              onChange={() => handleVisibilitySwitch(request?.id, request?.showToStartup)}
                            />
                          )}
                        </>
                      )}

                      {request.status === 'Rejected' && (
                        <>
                          <ColorfulTag title="Teklif Reddedildi." backgroundColor="#FEEFEF" textColor="#F16063" />
                        </>
                      )}

                      {request.status === 'Approved' && (
                        <>
                          <ColorfulTag title="Teklif Kabul Edildi." backgroundColor="#DEFFEE" textColor="#66CB9F" />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}

            {/* Accept Pop Up*/}
            <div>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                {/*style={{ maxWidth: 416 }}*/}
                <Grid container justify="center" style={{ maxWidth: 416 }}>
                  <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                    <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
                  </Grid>

                  <Grid
                    container
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 24,
                    }}
                  >
                    <div
                      style={{ width: '116px', height: '116px', backgroundColor: '#F0F5FF', borderRadius: '100%' }}
                    ></div>
                    <svg
                      style={{ height: '42px', position: 'absolute' }}
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.1987 17C16.4797 14.2817 19.9353 12.3333 24 12.3333C29.1567 12.3333 33.3333 15.467 33.3333 19.3333C33.3333 22.6 30.3513 25.3417 26.3193 26.1163C25.0547 26.359 24 27.3763 24 28.6667M24 35.6667H24.0233M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                        stroke="#003CA5"
                        stroke-width="4.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Grid>

                  <DialogTitle>
                    <Typography
                      align="center"
                      style={{
                        paddingLeft: 32,
                        paddingRight: 32,
                        fontWeight: 600,
                        fontSize: '25px',
                        lineHeight: '38px',
                        textAlign: 'center',
                        color: '#27272E',
                      }}
                    >
                      {t('Talebi Kabul Et')}
                    </Typography>
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText className={classes.dialogDescription} id="alert-dialog-slide-description">
                      Talebi kabul etmek istediğinizden emin misiniz?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Grid item sm={6} md={6}>
                      <Button
                        onClick={handleClose}
                        className={classes.dialogButton}
                        variant="outlinedSecondary"
                        style={{
                          height: '36px',
                          padding: '12px 20px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'static',
                        }}
                        fullWidth
                      >
                        Vazgeç
                      </Button>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Button
                        className={classes.dialogButton}
                        onClick={handleAccept}
                        variant="containedPrimary"
                        type="submit"
                        color="primary"
                        style={{
                          height: '36px',
                          padding: '12px 20px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'static',
                        }}
                        fullWidth
                      >
                        Talebi Kabul Et
                      </Button>
                    </Grid>
                  </DialogActions>
                </Grid>
              </Dialog>
            </div>

            {/* Congrats Pop Up*/}
            <div>
              <Dialog
                open={accepted}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                  <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
                </Grid>

                <Grid
                  container
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 24,
                  }}
                >
                  <div
                    style={{ width: '116px', height: '116px', backgroundColor: '#F0F5FF', borderRadius: '100%' }}
                  ></div>
                  <svg
                    style={{ position: 'absolute', height: 42 }}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M45 32.274C43.7797 32.274 42.5593 32.6263 41.5 33.3333C40.4635 34.0244 39.2457 34.3931 38 34.3931C36.7543 34.3931 35.5365 34.0244 34.5 33.3333C33.4635 32.6423 32.2457 32.2735 31 32.2735C29.7543 32.2735 28.5365 32.6423 27.5 33.3333C26.4635 34.0244 25.2457 34.3931 24 34.3931C22.7543 34.3931 21.5365 34.0244 20.5 33.3333C19.4635 32.6423 18.2457 32.2735 17 32.2735C15.7543 32.2735 14.5365 32.6423 13.5 33.3333C12.4635 34.0244 11.2457 34.3931 10 34.3931C8.75429 34.3931 7.53646 34.0244 6.5 33.3333C5.46367 32.6421 4.24572 32.2734 3 32.274M17 10V14.6667M24 10V14.6667M31 10V14.6667M17 3H17.0233M24 3H24.0233M31 3H31.0233M45 45V28.6667C45 27.429 44.5083 26.242 43.6332 25.3668C42.758 24.4917 41.571 24 40.3333 24H7.66667C6.42899 24 5.242 24.4917 4.36683 25.3668C3.49167 26.242 3 27.429 3 28.6667V45H45ZM38 24V19.3333C38 18.0957 37.5083 16.9087 36.6332 16.0335C35.758 15.1583 34.571 14.6667 33.3333 14.6667H14.6667C13.429 14.6667 12.242 15.1583 11.3668 16.0335C10.4917 16.9087 10 18.0957 10 19.3333V24H38Z"
                      stroke="#003CA5"
                      stroke-width="4.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>

                <DialogTitle>
                  <Typography
                    align="center"
                    style={{
                      paddingLeft: 32,
                      paddingRight: 32,
                      fontWeight: 600,
                      fontSize: '25px',
                      lineHeight: '38px',
                      textAlign: 'center',
                      color: '#27272E',
                    }}
                  >
                    {t('Talep Kabul Edildi')}
                  </Typography>
                </DialogTitle>

                <DialogContent>
                  <DialogContentText className={classes.dialogDescription} id="alert-dialog-slide-description">
                    {t(
                      'Tebrikler! Talebi kabul ederek yeni yatırımcınızla buluştunuz. Hesap yöneticimiz sizi yatırımcıyla iletişime geçirecektir.',
                    )}
                  </DialogContentText>
                </DialogContent>

                <DialogActions style={{ margin: 'auto' }}>
                  <Link to="/">
                    <Button
                      onClick={handleClose}
                      variant="containedPrimary"
                      color="primary"
                      className={classes.dialogButton}
                    >
                      Taleplere Dön
                    </Button>
                  </Link>
                </DialogActions>
              </Dialog>
            </div>

            {/* Decline Pop Up*/}
            <div>
              <Dialog
                open={declined}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <Grid container justify="center" style={{ maxWidth: 416 }}>
                  <Grid
                    container
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 34,
                    }}
                  >
                    <div
                      style={{
                        width: '116px',
                        height: '116px',
                        backgroundColor: 'rgba(246, 142, 135, 0.15)',
                        borderRadius: '100%',
                      }}
                    ></div>
                    <svg
                      style={{ height: '42px', position: 'absolute' }}
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.3333 28.6667L24 24M24 24L28.6667 19.3333M24 24L19.3333 19.3333M24 24L28.6667 28.6667M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                        stroke="#F16063"
                        stroke-width="4.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Grid>
                  <DialogTitle id="simple-dialog-title">
                    <Typography
                      align="center"
                      style={{
                        paddingLeft: 32,
                        paddingRight: 32,
                        fontWeight: 600,
                        fontSize: '25px',
                        lineHeight: '38px',
                        textAlign: 'center',
                        color: '#27272E',
                      }}
                    >
                      {t('Talebi Reddet')}
                    </Typography>
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Typography align="center" className={classes.dialogDescription}>
                        Talebi reddetmek istediğinize emin misiniz?
                      </Typography>
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions item sm={12} style={{ margin: 'auto' }}>
                    <Grid item sm={6} md={6}>
                      <Button
                        onClick={handleConfirmDecline}
                        variant="outlinedSecondary"
                        className={classes.dialogButton}
                        style={{
                          height: '36px',
                          padding: '12px 20px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'static',
                        }}
                        fullWidth
                      >
                        {t('Talebi Reddet')}
                      </Button>
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <Button
                        onClick={handleClose}
                        color="primary"
                        variant="containedPrimary"
                        className={classes.dialogButton}
                        style={{
                          height: '36px',
                          padding: '12px 20px',
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'static',
                        }}
                        fullWidth
                      >
                        {t('Vazgeç')}
                      </Button>
                    </Grid>
                  </DialogActions>
                </Grid>
              </Dialog>
            </div>
          </Grid>
        </Grid>
      </main>
    </>
  );
}

export default GetInvestment;
