import { Grid, Typography } from '@material-ui/core';

import OneCikanSvg from '../assets/svgs/OneCikanSvg.svg';
import React from 'react';

function IsFeaturedBanner() {
  return (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
      style={{
        //if height is chaged also change the static negative margin values inside components
        height: 32,
        backgroundColor: '#FFF3E5',
        borderRadius: '8px 8px 0px 0px',
        position: 'relative',
        zIndex: 10,
        top: 32,
      }}
    >
      <Typography align="center" variant="body1" style={{ color: '#FF9B28', fontWeight: 700 }}>
        <img src={OneCikanSvg} height={14} width={14} alt="öne çıkan" /> ÖNE ÇIKAN GİRİŞİM
      </Typography>
    </Grid>
  );
}

export default IsFeaturedBanner;
