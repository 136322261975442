import React from 'react';
import logo from '../../static/img/startupBorsaLogo.jpg';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) =>({
  logo: {
    width: 150,
    height: 50,
    [theme.breakpoints.down('md')]: {
        marginTop: '30px'
    },
  },
  toolbar: {
    padding: '0px 100px',
  },
  applicationCard: {
    background:'#ffffff',
    marginTop:100,
    marginBottom:50,
    [theme.breakpoints.down('lg')]: {
        marginBottom:100   
    },
}, 
}));
function VerifyEmail(props) {
const [verify, setVerify] = React.useState(false);
const [code, setCode] = React.useState(false);
const handleVerify = variables => {
        props.verifyEmail(variables)
            .then(data => {
                if (data.data.verifyEmail) {
                    setCode(true)
                } else {
                    // HATA DÖNMELİ Mİ ???                
                }
            }).then().catch(error => {
                //   HATA ERROR
        });
}  

React.useEffect(() => {
    if(!verify){
        let params = new URLSearchParams(props.location.search);
        let variables = {
            code: params.get("code")
        }
        handleVerify({variables})
    }
},
[verify])
const classes = useStyles();
  return (
          <div>
            <Hidden mdDown>
                <AppBar position="static" style={{background : '#ffffff'}} elevation={1}>
                    <Toolbar variant="dense" disableGutters className={classes.toolbar}>
                            <img src={logo} className={classes.logo} alt="startup-borsa"></img>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <Hidden mdUp>
                <Grid container justify="center" style={{background: '#ffffff',paddingBottom:30}}>
                    <img src={logo} alt="startup-borsa" className={classes.logo} style={{marginRight: '10px'}}></img>
                </Grid>
            </Hidden>
            { code ? props.history.push({
                pathname: `/`,
                state: { message:{type:"success", text: "Başarılı. E-posta adresiniz onaylanmıştır."}, haveMessage: true }
              })
            : 
            <Grid container justify="center">
                    <Grid className={classes.applicationCard} container xs={12} lg={10} xl={9}>
                        <Grid item container direction="column" xs={12} style={{marginTop:60,marginBottom:75}} alignItems="center">
                                <Typography variant="h3" style={{marginTop:'25px'}} align="center">
                                    Your activation code is invalid
                                </Typography>
                        </Grid>
                    </Grid>
            </Grid>
            }
          </div>
  );
}
export default VerifyEmail;
