import { Breadcrumbs, Grid, Paper, Table, TableBody, TableContainer, TableHead, Typography } from '@material-ui/core';
import { ExpandingRowListed, ExpandingRowUnlisted, TabPanel, TableRowsListed, TableRowsUnlisted } from './Components';
import { Redirect } from 'react-router-dom';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import newHome from '../../static/icon/new-home-blue.svg';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import TabsBar from '../../Components/TabsBar';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  CheckText: {
    wordBreak: 'break-all',
    marginBottom: 10,
    '& span': {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  filterPadding: {
    paddingLeft: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      marginTop: 10,
    },
  },
  filter: {
    height: 40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  myPaper: {
    padding: 25,
    width: '650px',
  },
  myPaper2: {
    padding: 25,
  },
  myLabel: {
    fontSize: 14,
  },
  formControl: {
    marginTop: 10,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
  whiteCard: {
    background: '#ffffff',
    borderRadius: '4px',
    padding: 25,
    marginTop: 100,
  },
  multiSelect2: {
    borderRadius: 4,
    '& div': {
      background: 'none!important',
      paddingRight: '5px!important',
      paddingLeft: '12px',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color: '#003CA5',
    },
  },
}));

const ADMIN_SECONDARY_SHARE_LIST = gql`
  query adminGetSecondaryShareRequestsPaginated(
    $shareTypeEnumFilter: ShareTypeEnum!
    $orderBy: [String]
    $page: Int
    $pageSize: Int
  ) {
    adminGetSecondaryShareRequestsPaginated(
      shareTypeEnumFilter: $shareTypeEnumFilter
      orderBy: $orderBy
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      count
      pageCount
      data {
        id
        currencySymbol
        adminStatusSwitch

        companyName
        companyWebsite
        companyAuthorityFullname
        companyAuthorityEmail
        contactPreference
        minAmount
        maxAmount

        applicant {
          id
          firstName
          lastName
          profilePhoto
          slug
        }

        startup {
          id
          title
          slug
        }
      }
    }
  }
`;


const shareObjects = [
  { name: 'SAT - Listelenmiş Startuplar', filterType: 'SELL_LISTED' },
  { name: 'AL - Listelenmiş Startuplar', filterType: 'BUY_LISTED' },
  { name: 'SAT - Listelenmemiş Startuplar', filterType: 'SELL_UNLISTED' },
  { name: 'AL - Listelenmemiş Startuplar', filterType: 'BUY_UNLISTED' }
];

function AdminSecondaryShareList(props) {
  //constants
  const classes = useStyles();
  const { t } = useTranslation();



  //states
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [order, setOrder] = React.useState('id_DESC');
  const [tabIndex, setTabIndex] = React.useState(0);


  const shareObject = shareObjects[tabIndex];
  const pageShareType = shareObject.filterType?.split('_')[1]; //LISTED OR UNLISTED

  const pageSizeOptions = [10, 15, 20, 50];


  //gql
  const { data } = useQuery(ADMIN_SECONDARY_SHARE_LIST, {
    variables: { shareTypeEnumFilter: shareObject.filterType, order: [order], pageSize: pageSize, page: page },
  });

  const pageCount = data?.adminGetSecondaryShareRequestsPaginated?.pageCount || 0;
  const allRequests = data?.adminGetSecondaryShareRequestsPaginated?.data;

  //helpers
  const handlePageChange = (_, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  const adminHandleVisibilitySwitch = async (id, status) => {
    const { error } = await props.adminSetSecondaryShareRequestsSwitch({
      variables: { secondaryId: id, status: !status },
    });
    if (error) {
      console.log(error);
    }
  };

  if (!shareObject) return <Redirect to="/404" />;

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
      })}
    >
      <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
        <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
          <Grid item style={{ width: '100%', padding: '0 10px' }}>
            <Grid container justify="space-between" alignItems="center">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <img src={newHome} style={{ width: 20, marginTop: 5 }} alt="eye" />
                <Typography variant="h4">Hisse Talepleri</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid container xs={12} style={{ marginTop: 50 }}>
              <TabsBar value={tabIndex} onChange={(e,v)=>setTabIndex(v)} data={shareObjects.map((e)=>({label: e.name}))}/>
              <TabPanel style={{ width: '100%' }} index={0}>                
                {allRequests && allRequests.length > 0 ? (
                  <Grid container xs={12}>                    
                    <TableContainer style={{ boxShadow: 'none', overflowY: 'hidden' }} component={Paper}>
                      <Table style={{ backgroundColor: '#F7F7FC' }} aria-label="customized table">
                        <TableHead>
                          {pageShareType === 'LISTED' ? (
                            <TableRowsListed
                              classes={{ multiSelect2: classes.multiSelect2, ListItemText2: classes.ListItemText2 }}
                              multiSelect2={classes.multiSelect2}
                              pageSizeOptions={pageSizeOptions}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              setPage={setPage}
                            />
                          ) : (
                            <TableRowsUnlisted
                              classes={{ multiSelect2: classes.multiSelect2, ListItemText2: classes.ListItemText2 }}
                              multiSelect2={classes.multiSelect2}
                              pageSizeOptions={pageSizeOptions}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              setPage={setPage}
                            />
                          )}
                        </TableHead>
                        <TableBody>
                          {allRequests.map((request) =>
                            pageShareType === 'LISTED' ? (
                              <ExpandingRowListed
                                handleVisibilitySwitch={adminHandleVisibilitySwitch}
                                request={request}
                              />
                            ) : (
                              <ExpandingRowUnlisted
                                handleVisibilitySwitch={adminHandleVisibilitySwitch}
                                request={request}
                              />
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid container justify="flex-end">
                      <Grid item style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={handlePageChange}
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container xs={12} className={classes.whiteCard}>
                    <Grid container justify="center">
                      <Typography style={{ margin: '25px 0' }} variant="h5">
                        {t('Hisse Talebi Bulunamadı...')}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
}

export default AdminSecondaryShareList;
