
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import get from 'lodash/get';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  tabRoot:{
    minHeight:40,
    padding:'24px',
    fontWeight:500
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    padding: 50,
    [theme.breakpoints.down('lg')]: {
        padding: 35,
    }, 
    [theme.breakpoints.down('sm')]: {
        minHeight: '0',
        padding: 25,
    }, 
  },
  TabsGeneral:{
    background: '#fff',
    borderTopLeftRadius:16,
    borderTopRightRadius:16,
  },
  textColorP:{
    color:'#777777',
    borderColor:'#777777',
    borderRadius:4,
    marginRight:20,
    '&$selected': {
      color:'#ffffff',
    }
  },
  accordionRoot:{
    borderRadius:4,
    border:'none',
    boxShadow:'none',
    marginBottom:40,
    "&:last-child" : {
      marginBottom:0,
    }
  },
  accordionSummaryRoot:{
    borderRadius:4,
    boxShadow:'0 0 4px 0 rgba(0, 0, 0, 0.16)',
  },
  accordionSummaryContent:{
    margin:'0!important',
    padding:'10px 25px'
  },
  accordionDetailsRoot:{
    borderRadius:4,
    boxShadow:'0 0 4px 0 rgba(0, 0, 0, 0.16)',
    marginTop:2,
    padding:40
  },
  mobileSpacing:{
    margin:0,
    [theme.breakpoints.down('sm')]: {
      padding:'16px 0!important'
    }
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
export default function StartupProfile(props){
    const classes = useStyles();
    const [faqs, setFaq] = React.useState();
    const [type, setType] = React.useState();
    const [groups, setGroups] = React.useState();
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();

    const GET_FAQ = gql`
    query getFAQ($type: Int, $page: Int, $pageSize: Int) {
      getFAQ( type:$type, pagination:{page:$page, pageSize:$pageSize}){
        count
        pageCount
        faq{
          id
          answerDate
          submitDate
          order
          submitBy{
            firstName
            lastName
          }
          question
          answer
          type{
            id
            type
          }
        }
        }
    }
    `;
      const { loading, data, refetch} = useQuery(GET_FAQ, {
        fetchPolicy:'network-only',
        variables: {page:1, pageSize:10, type:type},
    });

    const GET_GROUP = gql`
    query getFAQGroups($page: Int, $pageSize: Int) {
      getFAQGroups(pagination:{page:$page, pageSize:$pageSize}){
        count
        pageCount
        groups{
          id
          type
        }
        }
    }
    `;
    const { loading:loadingGroup, data:dataGroup, refetch:refetchGroup } = useQuery(GET_GROUP, {
        fetchPolicy:"network-only",
        variables: {page:1, pageSize:10},
    });

    React.useEffect(() => {
      if(!loading){
          if(data){
              setFaq(data.getFAQ.faq);
          }
      }
      if(!loadingGroup){
        if(dataGroup){
          setGroups(dataGroup.getFAQGroups.groups);
        }
      }
    },
    [data, loading, loadingGroup, dataGroup])

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
            >
              <Grid item style={{ width: '100%', marginBottom:24 }}>
                <Breadcrumbs
                  style={{ padding: '0 14px', marginBottom:24 }}
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <HelpOutlineIcon style={{ fontSize: 20, marginTop: 8 }} />
                  <Typography variant="h4">Sıkça Sorulan Sorular</Typography>
                </Breadcrumbs>
                <Grid container spacing={4} className={classes.mobileSpacing} xs={12}>
                  <Grid item className={classes.mobileSpacing} xs={12} md={9}>
                    <AppBar position="static" color="default">
                      {groups && (
                        <>
                          {groups.length > 2 ? (
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              scrollButtons="on"
                              variant="scrollable"
                              aria-label="scrollable auto tabs example"
                              className={classes.TabsGeneral}
                            >
                              <Tab
                                classes={{ root: classes.tabRoot, textColorPrimary: classes.textColorP }}
                                onClick={() => setType()}
                                style={{fontWeight:600}}
                                label={t('Tüm Sorular')}
                                {...a11yProps(0)}
                              />
                              {groups.map((group, index) => (
                                <Tab
                                  classes={{ root: classes.tabRoot, textColorPrimary: classes.textColorP }}
                                  onClick={() => setType(group.id)}
                                  style={{fontWeight:600}}
                                  label={group.type}
                                  {...a11yProps(index + 1)}
                                />
                              ))}
                            </Tabs>
                          ) : (
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="primary"
                              textColor="primary"
                              scrollButtons="off"
                              variant="scrollable"
                              aria-label="scrollable auto tabs example"
                              className={classes.TabsGeneral}
                            >
                              <Tab
                                classes={{ root: classes.tabRoot, textColorPrimary: classes.textColorP }}
                                onClick={() => setType()}
                                label={t('Genel')}
                                {...a11yProps(0)}
                              />
                              {groups.map((group, index) => (
                                <Tab
                                  classes={{ root: classes.tabRoot, textColorPrimary: classes.textColorP }}
                                  onClick={() => setType(group.id)}
                                  label={group.type}
                                  {...a11yProps(index + 1)}
                                />
                              ))}
                            </Tabs>
                          )}
                        </>
                      )}
                    </AppBar>
                    <Grid item className={classes.whiteCard}>
                      {faqs && !loading && (
                        <>
                          <TabPanel value={value} index={0}>
                            {faqs.map((faq) => (
                              <Accordion classes={{ root: classes.accordionRoot }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{ margin: 0 }}
                                  classes={{
                                    root: classes.accordionSummaryRoot,
                                    content: classes.accordionSummaryContent,
                                  }}
                                >
                                  <Typography style={{ lineHeight: '1.5' }} variant="h6">
                                    {faq.question}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                                  <Typography variant="body2" style={{ fontSize: 15, lineHeight: 1.77 }}>
                                    {faq.answer}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </TabPanel>
                          {groups &&
                            groups.map((group, index) => (
                              <TabPanel value={value} index={index + 1}>
                                {faqs.map((faq) => (
                                  <Accordion style={{ marginBottom: 40 }} classes={{ root: classes.accordionRoot }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      style={{ margin: 0 }}
                                      classes={{
                                        root: classes.accordionSummaryRoot,
                                        content: classes.accordionSummaryContent,
                                      }}
                                    >
                                      <Typography style={{ lineHeight: '1.5' }} variant="h6">
                                        {faq.question}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                                      <Typography variant="body2" style={{ fontSize: 15, lineHeight: 1.77 }}>
                                        {faq.answer}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                ))}
                              </TabPanel>
                            ))}
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid item className={classes.whiteCard} style={{ textAlign: 'center', padding: 30 }}>
                      <Typography variant="h5" style={{ lineHeight: '1.75' }} color="primary">
                        {t('Aradığınız sorunun cevabını bulamadınız mı?')}
                      </Typography>
                      <Typography variant="body2" style={{ lineHeight: '2', marginTop: 15 }}>
                        {t(
                          'Merak ettiğiniz konuyla ilgili sistem yöneticilerine mesaj gönderebilir, aradığınız cevaba en kısa zamanda ulaşabilirsiniz.',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </>
    );
}