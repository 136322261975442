import { Typography } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) =>({
  inputField: {
    width: '100%',
    //marginTop: 10,
  },
}))

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

export default function FormikNumberField2({ type,label,inputProps, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
  <Grid /*style={{minHeight:75}}*/>
      <TextField
          variant="outlined"
          className={classes.inputField}
          {...field}
          {...props}
          inputProps={{
              maxLength: 15
          }}
          InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment:
              props.endIcon && 
              <InputAdornment position="end">
                <img src={props.endIcon} alt="end-icon" />
              </InputAdornment> 
          }}  
          error={meta.touched && meta.error}
          placeholder={props.placeholder}
      />

          {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4, textAlign:'left' }}>
              {meta.error}
          </Typography>
          ) : null}
  </Grid>        
    </>
  );
}
