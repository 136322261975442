import React from 'react';
import {
  Grid,
  TextField,
  Input,
  Typography,
  Button,
  Select,
  FormControl,
  ListItemText,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import NewHeader from '../../Components/NewHeader';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
}));

function Test(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [personName, setPersonName] = React.useState([]);
  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <NewHeader setOpen={setOpen} open={open}></NewHeader>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <Grid container justify="center">
            <Grid className={'main-container'} style={{ marginTop: 100 }}>
              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <Typography> Typography </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <TextField placeholder="TextField outlined" variant="outlined" />
                <Typography> TextField outlined </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <FormControl>
                  <Typography variant="h5"> Name </Typography>
                  <TextField placeholder="TextField outlined" variant="outlined" />
                </FormControl>
                <Typography> FormControl </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <TextField error variant="outlined" />
                <Typography> TextField outlined error </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <Button variant="text"> Submit </Button>
                <Typography> variant="text" </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <Button variant="outlinedPrimary"> Cancel </Button>
                <Typography> variant="outlinedPrimary" </Typography>
              </Grid>
              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <Button variant="outlinedSecondary"> Cancel </Button>
                <Typography> variant="outlinedSecondary" </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <Button variant="containedPrimary"> Submit </Button>
                <Typography> variant="containedPrimary" </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <Button variant="containedSecondary"> Submit </Button>
                <Typography> variant="containedSecondary" </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 32 }}>
                <FormControl style={{ width: 300 }}>
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    /*   MenuProps={MenuProps} */
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography> Select Mutiple Checkbox </Typography>
              </Grid>

              <Grid container justify="space-between" direction="column" style={{ marginBottom: 32 }}>
                <Typography variant="h1"> variant="h1" </Typography>
                <Typography variant="h2"> variant="h2" </Typography>

                <Grid container justify="space-between">
                  <Typography variant="h3"> variant="h3"</Typography>
                </Grid>

                <Typography variant="h4"> variant="h4" </Typography>
                <Typography variant="h5"> variant="h5" </Typography>

                <Typography variant="h6"> variant="h6" </Typography>

                <Grid container justify="space-between">
                  <Typography variant="body1"> variant="body1" </Typography>
                  <Typography variant="body1" color="primary">
                    variant="body1" color="primary"
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    variant="body1" color="textPrimary"
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    variant="body1" color="secondary"
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    variant="body1" color="textSecondary"
                  </Typography>
                </Grid>

                <Typography variant="body2"> variant="body2" </Typography>
                <Typography variant="subtitle1"> variant="subtitle1"" </Typography>
                <Typography variant="subtitle2"> variant="subtitle2" </Typography>
                <Typography variant="caption"> variant="caption" </Typography>
              </Grid>

              <Grid container justify="space-between" style={{ marginBottom: 32 }}>
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <Checkbox icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} color="primary" />
                  }
                />
                <Typography> Checkbox </Typography>
              </Grid>

              <Grid container justify="space-between" style={{ marginBottom: 32 }}>
                <FormControl style={{ width: 300 }}>
                  <Select IconComponent={KeyboardArrowDownIcon} input={<Input />}></Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    </React.Fragment>
  );
}

export default Test;
