
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import { DebounceInput } from 'react-debounce-input';
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { Link } from 'react-router-dom';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import moment from 'moment'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import newPeople from '../../static/icon/new-people.svg';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import filterIcon from '../../static/icon/filterIcon.svg';
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import 'moment/locale/tr'
import { CSVLink } from 'react-csv';
import { getFormattedDateFromUnix } from '../../helpers';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
    marginTop: 35
  },
  Debounce: {
    width: '100%',
    maxWidth: '180px',
    fontSize: 16,
    height: '35px',
    color: '#8898aa',
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid #c8c8ca',
    background: 'inherit',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  addButton: {
    fontWeight: 'normal',
    padding: '6.5px 11px',
    fontSize: 13,
    color: '#ffba00',
    marginLeft: 10,
    borderColor: '#ffba00',
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#ffba00",
      color: '#fff',
      borderColor: '#ffba00',
    }
  },
  multiSelect2: {
    borderRadius: 4,
    '& div': {
      background: 'none!important',
      paddingRight: '5px!important',
      paddingLeft: '12px'
    }
  },
  multiSelect: {
    maxHeight: 40,
    minWidth: 116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor: '#fff',
    '& div': {
      background: 'none!important',
    }
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop: 30,
    '& textarea': {
      padding: 16,
      paddingRight: 90,
      fontSize: 13,
      lineHeight: 1
    },
  },
  sendButton: {
    minWidth: 50,
    height: 50,
    borderRadius: '50%',
    background: '#003CA5',
    marginTop: '-80px',
    marginRight: 30,
    cursor: 'pointer',
    "&:hover": {
      background: '#003CA5',
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color: '#8898aa',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color: '#003CA5',
    },
  },
  sticker: {
    marginRight: 10,
    color: '#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  filter: {
    height: 40,
    padding: '0 15px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: '20px',
    },
  },
  myPaper: {
    padding: 25,
    width: '650px'
  },
  myLabel: {
    fontSize: 14
  },
  formControl: {
    marginTop: 10
  },
  myCheckbox: {
    padding: '5px 9px'
  },
  CheckText: {
    wordBreak:'break-all',
    marginBottom:10,
    '& span': {
      fontSize:15,
      lineHeight:1.2
    }
  },
  whiteCard: {
    background: '#ffffff',
    borderRadius: '4px',
    padding: 25,
    marginBottom: 50,
    marginTop: 50,
  },
  RightBorder: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid rgb(233, 233, 240)'
    },
  },
  paddingL: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '40px!important'
    },
  },
  exportButton:{
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    marginLeft:10,
    border:'1px solid #003CA5',
    borderRadius:3,
    display:'flex',
    alignItems:'center',
    color:'#003CA5',
    "&:hover" : {
      backgroundColor : "#003CA5",
      color:'#fff',
      borderColor:'#003CA5',
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize: 11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

export function UserDialog(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [valueText, setValueText] = React.useState('');
  const [notes, setNotes] = React.useState([]);
  const { onClose, open, row } = props;


  const ALL_USER_NOTES = gql`
  query getUserAdminNotes($id:ID!) {
    getUserAdminNotes(id:$id){
          id
          submitBy{
              id
              firstName
              lastName
              profilePhoto
          }
          note
          date
          }
      
      }
  `;
  const { loading, data, refetch } = useQuery(ALL_USER_NOTES, {
    fetchPolicy: 'network-only',
    variables: { id: row.id },
  });
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setNotes(data.getUserAdminNotes);
      }
    }
  },
    [data, loading])

  const handleClose = () => {
    onClose(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeText = (event) => {
    setValueText(event.target.value);
  };

  const sendNote = (value) => {
    if (value !== '') {
      props.adminAddNoteOnUser({ variables: { id: row.id, note: value } })
        .then(data => {
          if (data.data.adminAddNoteOnUser) {
            setValueText('')
            refetch()
          }
        });
    }
  };

  function getLocation(item) {
    let country = item.country ? item.country.title : ''
    let city = item.city ? item.city.title + ', ' : ''
    return city + country
  }

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="center" xs={12}>
        <Grid item style={{ marginBottom: 20, marginTop: 35, display: 'flex' }} xs={12} md={11}>
          {row.profilePhoto ? (
            <img
              src={row.profilePhoto}
              alt="logo"
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                marginRight: 20,
                objectFit: 'cover',
                border: 'solid 1px #eaedf3',
              }}
            />
          ) : (
            <Grid
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
                marginRight: 20,
              }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                {row.firstName && row.firstName.charAt(0)}
              </Typography>
            </Grid>
          )}
          <Typography align="left" variant="body1" stlye={{ fontWeight: 500 }}>
            {row.firstName} {row.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr size="1" style={{ border: '0.2px solid #f5f5f5', marginBottom: 25 }} />
        </Grid>
      </Grid>
      <Grid container justify="center" xs={12}>
        <Grid container style={{ padding: '0 35px' }} xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Kullanıcı Bilgiler" {...a11yProps(0)} style={{ fontWeight: 600 }} />
              <Tab label="Notlar" {...a11yProps(1)} style={{ fontWeight: 600 }} />
            </Tabs>
          </AppBar>
          <TabPanel style={{ width: '100%' }} value={value} index={0}>
            <Grid
              container
              xs={12}
              spacing={2}
              style={{ border: '1px solid #e9e9f0', margin: 0, marginBottom: 50, paddingLeft: 40 }}
              className={classes.whiteCard}
            >
              <Grid item xs={12} md={6} className={classes.RightBorder}>
                <Typography variant="h5" style={{ fontWeight: '500', marginBottom: 20 }}>
                  Kayıt bilgileri
                </Typography>
                <Grid item container style={{ marginBottom: 15 }}>
                  <Typography variant="body2" style={{ fontWeight: '500' }}>
                    Telefon:{' '}
                  </Typography>
                  <Typography variant="body2">{row.phoneNumber}</Typography>
                </Grid>
                <Grid item container style={{ marginBottom: 15 }}>
                  <Typography variant="body2" style={{ fontWeight: '500' }}>
                    Email Adresi:{' '}
                  </Typography>
                  <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
                    {row.email}
                  </Typography>
                </Grid>
                <Grid item container style={{ marginBottom: 15 }}>
                  <Typography variant="body2" style={{ fontWeight: '500' }}>
                    Lokasyon:{' '}
                  </Typography>
                  <Typography variant="body2">{getLocation(row)}</Typography>
                </Grid>
                <Grid item container>
                  <Typography variant="body2" style={{ fontWeight: '500' }}>
                    linkedIn:{' '}
                  </Typography>
                  <Typography variant="body2">{row.socialLinkedIn}</Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.paddingL} xs={12} md={6}>
                <Typography variant="h5" style={{ fontWeight: '500', marginBottom: 20 }}>
                  Anket bilgileri
                </Typography>
                {row.onBoardInfo.type && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Sizi Hangisi Tanımlar:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.type}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.hasEverInvested && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Daha Önce Yatırım Yaptı mı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.hasEverInvested}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.investedCompanyCount && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Toplam Kaç Girişime Yatırım Yaptı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.investedCompanyCount}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.investmentAmount && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Toplamda Ne Kadar Yatırım Yaptı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.investmentAmount}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.interestedSector && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Hangi Sektörlerle İlgileniyor:{' '}
                    </Typography>
                    <Typography variant="body2">
                      {row.onBoardInfo.interestedSector.map((ind, index) =>
                        index + 1 === row.onBoardInfo.interestedSector.length ? ind : ind + ' , ',
                      )}
                    </Typography>
                  </Grid>
                )}
                {row.onBoardInfo.companyName && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Şirket Adı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.companyName}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.companyWebsite && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Şirket Websitesi:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.companyWebsite}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.companySector && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Şirket Sektörü:{' '}
                    </Typography>
                    <Typography variant="body2">
                      {row.onBoardInfo.companySector.map((ind, index) =>
                        index + 1 === row.onBoardInfo.companySector.length ? ind : ind + ' , ',
                      )}
                    </Typography>
                  </Grid>
                )}
                {row.onBoardInfo.companyRole && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Şirketteki Rolü:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.companyRole}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.hasShare && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Şirkette Hisseniz Var mı?:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.hasShare}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.investorShareRate && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Şirkette Ne Kadar Hisse Sahibisiniz:{' '}
                    </Typography>
                    <Typography variant="body2">%{row.onBoardInfo.investorShareRate}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corporateName && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurum Adı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.corporateName}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corporateDefinition && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurum Tanımı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.corporateDefinition}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corporateRole && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurumdaki Rolü:{' '}
                    </Typography>
                    <Typography variant="body2"> {row.onBoardInfo.corporateRole}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corporateSectors && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurumun Sektörleri:{' '}
                    </Typography>
                    <Typography variant="body2">
                      {row.onBoardInfo.corporateSectors.map((ind, index) =>
                        index + 1 === row.onBoardInfo.corporateSectors.length ? ind : ind + ' , ',
                      )}
                    </Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corporateReservedToInvest && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurum Fonunun Yatırım Bütçesi:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.corporateReservedToInvest}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corporateRiskPreference && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurumun Risk Tercihi:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.corporateRiskPreference}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corpPlannedInvCompAmouInTwo && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurumun 2 Yıl İçinde Yatırım Sayısı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.corpPlannedInvCompAmouInTwo}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.corpPlannedExitYear && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kurumun Kaç Yıl İçerisinde Çıkış Yapacak:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.corpPlannedExitYear}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.plannedAmountOfAngelInvestment && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Melek Yatırım Bütçesi:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.plannedAmountOfAngelInvestment}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.riskPreference && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Yatırım Risk Tercihi:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.riskPreference}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.plannedInvCompAmouInTwo && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      2 Yıl İçinde Yatırım Sayısı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.plannedInvCompAmouInTwo}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.plannedExitYears && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Kaç Yıl İçerisinde Çıkış Yapacak:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.plannedExitYears}</Typography>
                  </Grid>
                )}

                {row.onBoardInfo.preferSellOnSB && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Hisselerini Satmayı Düşünür mü:{' '}
                    </Typography>
                    <Typography variant="body2"> {row.onBoardInfo.preferSellOnSB}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.legalObstaclesOnSell && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Hisselerini Satması Hukuksal Engel Yaratır mı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.legalObstaclesOnSell}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.legalObstaclesOnSelldesc && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Hukuksal Engel Açıklaması:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.legalObstaclesOnSelldesc}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.hearSBFrom && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Startup Borsa'yı Nereden Duydu:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.hearSBFrom}</Typography>
                  </Grid>
                )}
                {row.onBoardInfo.purposeEnteringSB && (
                  <Grid item container style={{ marginBottom: 15 }}>
                    <Typography variant="body2" style={{ fontWeight: '500' }}>
                      Startup Borsa'ya Giriş Amacı:{' '}
                    </Typography>
                    <Typography variant="body2">{row.onBoardInfo.purposeEnteringSB}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel style={{ width: '100%' }} value={value} index={1}>
            <Grid container xs={12} style={{ marginTop: 0, border: '1px solid #e9e9f0' }} className={classes.whiteCard}>
              {notes && (
                <Grid container direction="column">
                  {notes.map((note) => (
                    <Grid item style={{ padding: '25px 0', borderBottom: '1px solid rgb(128 128 128 / 19%)' }}>
                      <Grid alignItems="center" container>
                        {note.submitBy.profilePhoto ? (
                          <img
                            src={note.submitBy.profilePhoto}
                            alt="avatar"
                            style={{ maxWidth: 50, borderRadius: '25%', marginRight: 20 }}
                          />
                        ) : (
                          <Grid
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: '25%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: '#003CA5',
                              marginRight: 20,
                            }}
                          >
                            <Typography variant="h5" style={{ color: '#fff' }}>
                              {note.submitBy.firstName && note.submitBy.firstName.charAt(0)}
                            </Typography>
                          </Grid>
                        )}
                        <Typography variant="h6" align="left">
                          {note.submitBy.firstName} {note.submitBy.lastName}
                        </Typography>
                      </Grid>
                      <Typography
                        variant="body2"
                        align="left"
                        style={{ marginTop: '15px', fontSize: 13, color: '#777777' }}
                      >
                        {note.note}
                      </Typography>
                      <Typography
                        variant="h5"
                        align="left"
                        style={{ marginTop: '15px', fontSize: 13, color: '#003CA5' }}
                      >
                        {moment(note.date).locale('tr').format('Do MMMM YYYY, h:mm:ss a')}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
              <TextField
                id="standard-multiline-flexible"
                multiline
                placeholder={'Yorumunuzu Yazın...'}
                className={classes.textArea}
                rows={4}
                value={valueText}
                onChange={handleChangeText}
              />
              <Grid container justify="flex-end">
                <Button
                  onClick={() => {
                    sendNote(valueText);
                  }}
                  className={classes.sendButton}
                >
                  <SendIcon style={{ color: '#ffffff' }}></SendIcon>
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function ExpandingRow(props) {
  const classes = useStyles();
  const [showStartup, setShowStartup] = React.useState(false);
  function getUserFullName(first, last) {
    let f = first ? first : ''
    let l = last ? ' ' + last : ''
    return f + l
  }
  function getLocation(item) {
    let country = item.country ? item.country.title : ''
    let city = item.city ? item.city.title + ', ' : ''
    return city + country
  }
  const { showDate, row } = props;
  return (
    <>
      <StyledTableRow key={row.name}>
        <StyledTableCell style={{ padding: '12px 0px 12px 30px' }} component="th" scope="row">
          {row.profilePhoto ? (
            <img
              src={row.profilePhoto}
              alt="logo"
              style={{ width: 35, height: 35, borderRadius: '50%', border: 'solid 1px #eaedf3' }}
            />
          ) : (
            <Grid
              style={{
                width: 35,
                height: 35,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
              }}
            >
              <Typography variant="h5" style={{ color: '#fff' }}>
                {row.firstName && row.firstName.charAt(0)}
              </Typography>
            </Grid>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {getUserFullName(row.firstName, row.lastName)}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{row.phoneNumber ? row.phoneNumber : '-'}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{row.email ? row.email : '-'}</Typography>
        </StyledTableCell>
        <StyledTableCell style={{ maxWidth: 150 }} align="left">
          <Typography variant="body2">{getLocation(row)}</Typography>
        </StyledTableCell>
{showDate &&        <StyledTableCell align="left" >
          <Typography variant="body2">{getFormattedDateFromUnix(row.createdAt)}</Typography>
        </StyledTableCell>}
{showDate &&        <StyledTableCell align="left" >
          <Typography variant="body2">{row.approvalDate ? row.approvalDate.replaceAll(".","/") : "-"}</Typography>
        </StyledTableCell>}
        <StyledTableCell align="left" style={{ paddingRight: 15 }}>
          <Grid container>
            <Link style={{ padding: 0 }} to={`/admin/girisimler/?=${row.id}`}>
              <BookmarkBorderIcon style={{ marginRight: 5 }} alt="sticker" className={classes.sticker} />
            </Link>
            <MoreVertIcon
              style={{ cursor: 'pointer' }}
              onClick={() => setShowStartup(true)}
              alt="sticker"
              className={classes.sticker}
            />
            <UserDialog
              adminAddNoteOnUser={props.adminAddNoteOnUser}
              open={showStartup}
              row={row}
              onClose={() => setShowStartup(false)}
            />
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

function getDataForCSV(data) {
  let result = []
  data.forEach((item) => {
    result.push({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      countryId: item.country?.id,
      countryTitle: item.country?.title,
      cityId: item.city?.id,
      cityTitle: item.city?.title,
      isAdmin: item.isAdmin,
      joinedAt: getFormattedDateFromUnix(item.createdAt),
      listedAt: item.approvalDate ? item.approvalDate.replaceAll(".","/") : "-",
      onBoardInfo_isShareHolder: item.onBoardInfo?.isShareHolder,
      onBoardInfo_isInvestor: item.onBoardInfo?.isInvestor,
      onBoardInfo_investorType: item.onBoardInfo?.investorType,
      onBoardInfo_type: item.onBoardInfo?.type,
      onBoardInfo_hasEverInvested: item.onBoardInfo?.hasEverInvested,
      onBoardInfo_investedCompanyCount: item.onBoardInfo?.investedCompanyCount,
      onBoardInfo_investmentAmount: item.onBoardInfo?.investmentAmount,
      onBoardInfo_interestedSector: item.onBoardInfo?.interestedSector,
      onBoardInfo_plannedAmountOfAngelInvestment: item.onBoardInfo?.plannedAmountOfAngelInvestment,
      onBoardInfo_riskPreference: item.onBoardInfo?.riskPreference,
      onBoardInfo_plannedInvCompAmouInTwo: item.onBoardInfo?.plannedInvCompAmouInTwo,
      onBoardInfo_plannedExitYears: item.onBoardInfo?.plannedExitYears,
      onBoardInfo_preferSellOnSB: item.onBoardInfo?.preferSellOnSB,
      onBoardInfo_legalObstaclesOnSell: item.onBoardInfo?.legalObstaclesOnSell,
      onBoardInfo_legalObstaclesOnSelldesc: item.onBoardInfo?.legalObstaclesOnSelldesc,
      onBoardInfo_hearSBFrom: item.onBoardInfo?.hearSBFrom,
      onBoardInfo_purposeEnteringSB: item.onBoardInfo?.purposeEnteringSB,
      onBoardInfo_corporateName: item.onBoardInfo?.corporateName,
      onBoardInfo_corporateDefinition: item.onBoardInfo?.corporateDefinition,
      onBoardInfo_corporateRole: item.onBoardInfo?.corporateRole,
      onBoardInfo_corporateSectors: item.onBoardInfo?.corporateSectors,
      onBoardInfo_corporateReservedToInvest: item.onBoardInfo?.corporateReservedToInvest,
      onBoardInfo_corporateRiskPreference: item.onBoardInfo?.corporateRiskPreference,
      onBoardInfo_corpPlannedInvCompAmouInTwo: item.onBoardInfo?.corpPlannedInvCompAmouInTwo,
      onBoardInfo_corpPlannedExitYear: item.onBoardInfo?.corpPlannedExitYear,
      onBoardInfo_companyName: item.onBoardInfo?.companyName,
      onBoardInfo_companyWebsite: item.onBoardInfo?.companyWebsite,
      onBoardInfo_companySector: item.onBoardInfo?.companySector,
      onBoardInfo_companyRole: item.onBoardInfo?.companyRole,
      onBoardInfo_investorShareRate: item.onBoardInfo?.investorShareRate,
      onBoardInfo_hasShare: item.onBoardInfo?.hasShare,
    })
  })
  return result
}

export default function DiscoverUsers(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [usersCount, setUsersCount] = React.useState(0);
  const [url, setUrl] = React.useState(true);
  const [urlNormal, setUrlNormal] = React.useState(true);
  const [fav, setFav] = React.useState();
  const [sort, setSort] = React.useState([]);
  const [userType, setUserType] = React.useState([]);
  const [isStartupContact, setIsStartupContact] = React.useState([]);
  const [interestedIndustriesFilter, setInterestedIndustriesFilters] = React.useState([]);
  const [hasEverInvestedFilter, setHasEverInvestedFilter] = React.useState([]);
  const [users, setUsers] = React.useState();
  const [order, setOrder] = React.useState("id__DESC");
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addUser, setAddUser] = React.useState(false);
  const [usersCSV, setUsersCSV] = React.useState();

  const filterOptions = ['Tip', 'Girişim', 'İlgilenilen Sektörler', 'Şehirler', 'Daha önce özel şirketlere yatırım yaptınız mı?'];
  const userTypeOptions = [{name:'Girişimci', value:'Shareholder'}, {name:'Çalışan', value:'Employee'}, {name:'B. Yatırımcı', value:'Individual Investor'}, {name:'K. Yatırımcı', value:'Corporate Investor'}, {name:'Diğer', value:'Other'},];
  const isStartupContactOptions = [{name:'Var', value:true}, {name:'Yok', value:false}];
  const yesNo = ['Evet', 'Hayır'];
  const industry = [
    'AI / Yapay Zeka',
    'Akıllı Şehirler',
    'AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik',
    'Atık Yönetimi',
    'Bilişim Teknolojileri',
    'Biyoteknoloji',
    'Blokzinciri',
    'Data Analiz & İş zekası',
    'Donanım',
    'e-ticaret',
    'Eğitim & Eğitim Teknolojileri',
    'Eğlence & Müzik',
    'Etkinlik',
    'Ev hizmetleri ve teknolojileri ',
    'Finans & Bankacılık',
    'Finans Teknolojileri',
    'Gayrimenkul',
    'Geri Dönüşüm',
    'Giyilebilir Teknolojiler',
    'Hukuk Teknolojileri',
    'İnsan Kaynakları & İşe Alım',
    'Kriptopara',
    'Kurumsal Hizmetler',
    'Mikro Mobilite',
    'Moda & Güzellik',
    'Müşteri İlişkileri Yönetimi',
    'Nesnelerin İnterneti',
    'Oyun',
    'Perakende',
    'Regülasyon Teknolojileri',
    'Reklam & Pazarlama',
    'Robotik Teknolojileri',
    'SaaS',
    'Sağlık Hizmetleri & Sağlık Teknolojileri',
    'Seyahat & Turizm',
    'Siber Güvenlik',
    'Sigorta & Sigorta Teknolojileri',
    'Sosyal Girişimcilik',
    'Sosyal Medya',
    'Spor & Fitness',
    'Tarım & Tarım Teknolojileri',
    'Teknoloji',
    'Telekomünikasyon',
    'Temiz Teknoloji',
    'Teslimat & Lojistik',
    'Topluluk Yönetimi',
    'Tüketici Hizmetleri',
    'Ulaşım',
    'Video & Animasyon',
    'Yazılım',
    'Yenilenebilir Enerji',
    'Yiyecek & İçecek',
  ];

  const handleUserType = (event) => {
    setPage(1);
    if (userType.includes(event.target.value)){
      let a = userType.filter(e => e !== event.target.value);
      setUserType(a);
     }
     else{
      setUserType([...userType, event.target.value]);
     }
  };

  const handleisStartupContact = (value) => {
    setPage(1);
    if (isStartupContact.includes(value)){
      setIsStartupContact([]);
     }
     else{
        setIsStartupContact([value]);
     }
  };
  const handleinterestedIndustriesFilter = (value) => {
    setPage(1);
    if (interestedIndustriesFilter.includes(value)){
      let a = interestedIndustriesFilter.filter(e => e !== value);
      setInterestedIndustriesFilters(a);
    }
    else{
      setInterestedIndustriesFilters([...interestedIndustriesFilter, value]);
    }
  };
  const handleHasEverInvestedFilter = (value) => {
    setPage(1);
    if (hasEverInvestedFilter.includes(value)){
      let a = hasEverInvestedFilter.filter(e => e !== value);
      setHasEverInvestedFilter(a);
    }
    else{
      setHasEverInvestedFilter([...hasEverInvestedFilter, value]);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenAddUser = () => {
    setAddUser(true);
  };
  const handleCloseAddUser = (value) => {
    setAddUser(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const ALL_USERS = gql`
    query adminUsersPaginate($order:String, $search: String, $userType: [String], $isStartupContact: [Boolean], 
          $favoritedStartup:ID, $page: Int, $pageSize: Int, $interestedIndustriesFilter:[String], 
          $hasEverInvestedFilter:[String]) {
        adminUsersPaginate(orderBy:[$order], filter : { favoritedStartup : $favoritedStartup, userType: $userType, 
            isStartupContact: $isStartupContact, interestedIndustries: $interestedIndustriesFilter,
            onBoardInfo:{hasEverInvested: $hasEverInvestedFilter} }, 
            search:$search, pagination:{page:$page, pageSize:$pageSize}){
            count
            pageCount
            users{
                id
                createdAt
                approvalDate
                firstName
                lastName
                profilePhoto
                socialLinkedIn
                phoneNumber
                email
                country{id, title}
                city{id, title}
                isAdmin
                onBoardInfo{
                  isShareHolder
                  isInvestor
                  investorType
                  type
                  hasEverInvested
                  investedCompanyCount
                  investmentAmount
                  interestedSector
                  plannedAmountOfAngelInvestment
                  riskPreference
                  plannedInvCompAmouInTwo
                  plannedExitYears
                  preferSellOnSB
                  legalObstaclesOnSell
                  legalObstaclesOnSelldesc
                  hearSBFrom
                  purposeEnteringSB
                  corporateName
                  corporateDefinition
                  corporateRole
                  corporateSectors
                  corporateReservedToInvest
                  corporateRiskPreference
                  corpPlannedInvCompAmouInTwo
                  corpPlannedExitYear
                  companyName
                  companyWebsite
                  companySector
                  companyRole
                  investorShareRate
                  hasShare
                }
            }
        }
    }
    `;
  if (window.location.href.indexOf("?") > -1 && url === true) {
    let a = window.location.href.split('=')[1];
    setFav(parseInt(a))
    setUrl(false)
  }
  if (window.location.href.indexOf("?") < 0 && urlNormal === true) {
    setFav(null)
    setUrlNormal(false)
  }
  const { loading, data, refetch } = useQuery(ALL_USERS, {
    fetchPolicy: 'network-only',
    variables: { order: order, search: search, page: page, pageSize: pageSize,
      favoritedStartup: fav, userType: userType, isStartupContact:isStartupContact,
      interestedIndustriesFilter:interestedIndustriesFilter, hasEverInvestedFilter:hasEverInvestedFilter},
  });

  const CSV_USERS = gql`
      query adminUsersPaginate($order:String, $page: Int, $pageSize: Int) {
          adminUsersPaginate(orderBy:[$order], pagination:{page:$page, pageSize:$pageSize}){
              count
              pageCount
              users{
                  id
                  createdAt
                  approvalDate
                  firstName
                  lastName
                  profilePhoto
                  socialLinkedIn
                  phoneNumber
                  email
                  country{id, title}
                  city{id, title}
                  isAdmin
                  onBoardInfo{
                      isShareHolder
                      isInvestor
                      investorType
                      type
                      hasEverInvested
                      investedCompanyCount
                      investmentAmount
                      interestedSector
                      plannedAmountOfAngelInvestment
                      riskPreference
                      plannedInvCompAmouInTwo
                      plannedExitYears
                      preferSellOnSB
                      legalObstaclesOnSell
                      legalObstaclesOnSelldesc
                      hearSBFrom
                      purposeEnteringSB
                      corporateName
                      corporateDefinition
                      corporateRole
                      corporateSectors
                      corporateReservedToInvest
                      corporateRiskPreference
                      corpPlannedInvCompAmouInTwo
                      corpPlannedExitYear
                      companyName
                      companyWebsite
                      companySector
                      companyRole
                      investorShareRate
                      hasShare
                  }
              }
          }
      }
  `;
  const { loading: loadingCSV, data: dataCSV } = useQuery(CSV_USERS, {
    fetchPolicy: 'network-only',
    variables: { order: "id__DESC", page: 1, pageSize: 50000},
  });
  const options = [
    { name: 'A-Z', value: 'firstName__ASC' },
    { name: 'Z-A', value: 'firstName__DESC' },
    { name: 'Latest', value: 'id__DESC' },
    { name: 'Oldest', value: 'id__ASC' },
  ];
  const pageSizeOptions = [3, 5, 10, 20, 50]
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        data.adminUsersPaginate.users.forEach(element => {
          if (!element.logo) {
            element.logo = 'https://www.vhv.rs/dpng/d/188-1880556_travel-agency-logo-design-example-image-travel-and.png'
          }
        });
        setUsers(data.adminUsersPaginate.users);
        setPageCount(data.adminUsersPaginate.pageCount)
        setUsersCount(data.adminUsersPaginate.count)
      }
    }
    if(!loadingCSV){
      if(dataCSV){
        setUsersCSV(dataCSV.adminUsersPaginate.users);
      }
    }
  },
    [data, loading, dataCSV, loadingCSV])

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };
  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };
  const handleFilter = (event) => {
    setPage(1);
    setFilter(event.target.value);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid
            className={'main-container'}
            xs={12}
            style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
          >
            <Grid item style={{ width: '100%' }}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item style={{ display: 'flex' }}>
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <img src={newPeople} style={{ width: 18, marginTop: 5 }} alt="people" />
                    <Typography variant="h4">Kullanıcılar {usersCount ? `(${usersCount})` : "" }</Typography>
                  </Breadcrumbs>
                  <Link to="/admin/kod-gönder" style={{ padding: 0 }}>
                    <Button className={classes.addButton} variant="outlinedPrimary">
                      Kod Gönder
                      </Button>
                  </Link>

                  {usersCSV && (
                    <CSVLink
                      data={getDataForCSV(usersCSV)}
                      filename={(new Date()).toISOString() + '_users.csv'}
                      target="_blank"
                      variant="outlinedPrimary"
                      className={classes.exportButton}
                    >
                      Dışa Aktar
                    </CSVLink>
                  )}
                </Grid>
                <Hidden smDown>
                  <Grid item className={classes.filterArea}>
                    <DebounceInput
                      class="inputTypeSearch"
                      name="search"
                      value={search}
                      placeholder="Ara..."
                      className={classes.Debounce}
                      minLength={2}
                      debounceTimeout={800}
                      onChange={handleSearch}
                    />
                    {value === 0 && 
                    <>
                    <Grid className={classes.filter} onClick={handleClick} item>
                      <img src={filterIcon} style={{ width: 12 }} alt="filter" />
                      <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                        Filtrele
                        </Typography>
                      <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
                    </Grid>
                    <Popover
                      id={id}
                      open={openPop}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      classes={{
                        paper: classes.myPaper,
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Grid container xs={12}>
                        <Grid item xs={4} style={{ borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                          <Typography variant="h5">Filtrele</Typography>
                          <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup onChange={handleFilter} value={filter}>
                              {filterOptions.map((value, i) => {
                                // Return the element. Also pass key
                                return (
                                  <FormControlLabel
                                    classes={{ label: classes.myLabel }}
                                    control={<Radio color="primary" value={value} />}
                                    label={value}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} style={{ paddingLeft: '30px', borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                          <Typography variant="h5">Filtre Seç</Typography>
                          {filter === 'Tip' && (
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup onChange={handleUserType} value={userType}>
                                {userTypeOptions.map((value, i) => {
                                  return (
                                    <FormControlLabel
                                      className={classes.CheckText}
                                      control={
                                        <Checkbox
                                          classes={{ root: classes.myCheckbox }}
                                          color="primary"
                                          onChange={handleUserType}
                                          checked={userType.includes(value.value)}
                                          name="filter4-1"
                                          value={value.value}
                                        />
                                      }
                                      label={value.name}
                                    />
                                  );
                                })}
                              </FormGroup>
                            </FormControl>
                          )}
                          {filter === 'Girişim' && (
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup value={isStartupContact}>
                                {isStartupContactOptions.map((value, i) => {
                                  return (
                                    <FormControlLabel
                                      className={classes.CheckText}
                                      control={
                                        <Checkbox
                                          classes={{ root: classes.myCheckbox }}
                                          color="primary"
                                          onClick={() => handleisStartupContact(value.value)}
                                          checked={isStartupContact.includes(value.value)}
                                          name="filter4-1"
                                          value={value.value}
                                        />
                                      }
                                      label={value.name}
                                    />
                                  );
                                })}
                              </FormGroup>
                            </FormControl>
                          )}
                          {filter === 'İlgilenilen Sektörler' && (
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup value={interestedIndustriesFilter}>
                                {industry.map((value) => {
                                  return (
                                    <FormControlLabel
                                      className={classes.CheckText}
                                      control={
                                        <Checkbox
                                          classes={{ root: classes.myCheckbox }}
                                          color="primary"
                                          onClick={() => handleinterestedIndustriesFilter(value)}
                                          checked={interestedIndustriesFilter.includes(value)}
                                          name="filter4-1"
                                          value={value}
                                        />
                                      }
                                      label={value}
                                    />
                                  );
                                })}
                              </FormGroup>
                            </FormControl>
                          )}
                          {filter === 'Daha önce özel şirketlere yatırım yaptınız mı?' && (
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup value={hasEverInvestedFilter}>
                                {yesNo.map((value) => {
                                  return (
                                    <FormControlLabel
                                      className={classes.CheckText}
                                      control={
                                        <Checkbox
                                          classes={{ root: classes.myCheckbox }}
                                          color="primary"
                                          onClick={() => handleHasEverInvestedFilter(value)}
                                          checked={hasEverInvestedFilter.includes(value)}
                                          name="filter4-1"
                                          value={value}
                                        />
                                      }
                                      label={value}
                                    />
                                  );
                                })}
                              </FormGroup>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                          <Typography style={{ marginBottom: 18 }} variant="h5">
                            Seçimleriniz
                            </Typography>
                          {userType && (
                            <>
                              {userType.map((value) => {
                                return (
                                  <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                    {value}
                                  </Typography>
                                );
                              })}
                            </>
                          )}
                          {interestedIndustriesFilter && (
                            <>
                              {interestedIndustriesFilter.map((value) => {
                                return (
                                  <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                    {value}
                                  </Typography>
                                );
                              })}
                            </>
                          )}
                          {hasEverInvestedFilter && (
                            <>
                              {hasEverInvestedFilter.map((value) => {
                                return (
                                  <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                    Daha önce özel şirketlere yatırım yaptınız mı? ({value})
                                  </Typography>
                                );
                              })}
                            </>
                          )}
                        </Grid>
                        <Grid container justify="flex-end"></Grid>
                      </Grid>
                    </Popover>
                    </>
                    }
                    <FormControl placeholder="Please Select" style={{ width: '100%', maxWidth: '116px' }}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        labelId="demo-mutiple-checkbox-label2"
                        id="demo-mutiple-checkbox2"
                        displayEmpty
                        value={sort}
                        className={classes.multiSelect}
                        onChange={handleSort}
                        IconComponent={(props) => (
                          <ExpandMoreIcon
                            {...props}
                            style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                          />
                        )}
                        input={<Input />}
                      /*   MenuProps={MenuProps} */
                      >
                        <MenuItem value="" disabled>
                          <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                            Sırala
                            </Typography>
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              {option.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Hidden>
              </Grid>
              {users && (
                <Grid container xs={12} className={classes.whiteCard}>
                  <AppBar style={{ zIndex: 0 }} className={classes.tabPaper} position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="rgb(247, 247, 252)"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className={classes.TabsGeneral}
                    >
                      <Tab label="Tümü" {...a11yProps(0)} onClick={() => {setUserType([]);setPage(1)}} style={{ fontWeight: 600, paddingTop: 0 }} />
                      <Tab label="Girişimci" {...a11yProps(1)} onClick={() => {setUserType(['Shareholder']);setPage(1)}} style={{ fontWeight: 600, paddingTop: 0 }} />
                      <Tab label="Yatırımcı" {...a11yProps(2)} onClick={() => {setUserType(['Corporate Investor', 'Individual Investor']);setPage(1)}} style={{ fontWeight: 600, paddingTop: 0 }} />
                      <Tab label="Çalışan" {...a11yProps(3)} onClick={() => {setUserType(['Employee']);setPage(1)}} style={{ fontWeight: 600, paddingTop: 0 }} />
                      <Tab label="Diğer" {...a11yProps(4)} onClick={() => {setUserType(['Other']);setPage(1)}} style={{ fontWeight: 600, paddingTop: 0 }} />
                    </Tabs>
                  </AppBar>
                  <TabPanel style={{ width: '100%' }} value={value} index={0}>
                    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Typography variant="h6" color="primary">
                                {' '}
                                #{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                İsim Soyisim{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Telefon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Email{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Lokasyon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-mutiple"
                                id="demo-mutiple"
                                displayEmpty
                                value={pageSize}
                                className={classes.multiSelect2}
                                style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                                IconComponent={(props) => (
                                  <ExpandMoreIcon
                                    {...props}
                                    style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                  />
                                )}
                                input={<Input />}
                              /*   MenuProps={MenuProps} */
                              >
                                {pageSizeOptions.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => (setPageSize(option), setPage(1))}
                                  >
                                    <ListItemText className={classes.ListItemText2} primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users.map((row) => (
                            <ExpandingRow adminAddNoteOnUser={props.adminAddNoteOnUser} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {users.length > 0 ? (
                      <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={handlePageChange}
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Typography style={{ margin: '25px 0' }} variant="h5">
                          Kullanıcı bulunamadı...
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={1}>
                    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Typography variant="h6" color="primary">
                                {' '}
                                #{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                İsim Soyisim{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Telefon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Email{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Lokasyon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Katılma Tarihi{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Listelenme Tarihi{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-mutiple"
                                id="demo-mutiple"
                                displayEmpty
                                value={pageSize}
                                className={classes.multiSelect2}
                                style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                                IconComponent={(props) => (
                                  <ExpandMoreIcon
                                    {...props}
                                    style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                  />
                                )}
                                input={<Input />}
                              /*   MenuProps={MenuProps} */
                              >
                                {pageSizeOptions.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => (setPageSize(option), setPage(1))}
                                  >
                                    <ListItemText className={classes.ListItemText2} primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users.map((row) => (
                            <ExpandingRow showDate={true} adminAddNoteOnUser={props.adminAddNoteOnUser} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {users.length > 0 ? (
                      <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={handlePageChange}
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Typography style={{ margin: '25px 0' }} variant="h5">
                          Kullanıcı bulunamadı...
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={2}>
                    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Typography variant="h6" color="primary">
                                {' '}
                                #{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                İsim Soyisim{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Telefon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Email{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Lokasyon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Katılma Tarihi{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Listelenme Tarihi{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-mutiple"
                                id="demo-mutiple"
                                displayEmpty
                                value={pageSize}
                                className={classes.multiSelect2}
                                style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                                IconComponent={(props) => (
                                  <ExpandMoreIcon
                                    {...props}
                                    style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                  />
                                )}
                                input={<Input />}
                              /*   MenuProps={MenuProps} */
                              >
                                {pageSizeOptions.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => (setPageSize(option), setPage(1))}
                                  >
                                    <ListItemText className={classes.ListItemText2} primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users.map((row) => (
                            <ExpandingRow showDate={true}  adminAddNoteOnUser={props.adminAddNoteOnUser} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {users.length > 0 ? (
                      <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={handlePageChange}
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Typography style={{ margin: '25px 0' }} variant="h5">
                          Kullanıcı bulunamadı...
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={3}>
                    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Typography variant="h6" color="primary">
                                {' '}
                                #{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                İsim Soyisim{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Telefon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Email{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Lokasyon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-mutiple"
                                id="demo-mutiple"
                                displayEmpty
                                value={pageSize}
                                className={classes.multiSelect2}
                                style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                                IconComponent={(props) => (
                                  <ExpandMoreIcon
                                    {...props}
                                    style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                  />
                                )}
                                input={<Input />}
                              /*   MenuProps={MenuProps} */
                              >
                                {pageSizeOptions.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => (setPageSize(option), setPage(1))}
                                  >
                                    <ListItemText className={classes.ListItemText2} primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users.map((row) => (
                            <ExpandingRow adminAddNoteOnUser={props.adminAddNoteOnUser} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {users.length > 0 ? (
                      <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={handlePageChange}
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Typography style={{ margin: '25px 0' }} variant="h5">
                          Kullanıcı bulunamadı...
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={4}>
                    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Typography variant="h6" color="primary">
                                {' '}
                                #{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                İsim Soyisim{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Telefon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Email{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Typography variant="h6" color="primary">
                                {' '}
                                Lokasyon{' '}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  getContentAnchorEl: null,
                                }}
                                labelId="demo-mutiple"
                                id="demo-mutiple"
                                displayEmpty
                                value={pageSize}
                                className={classes.multiSelect2}
                                style={{ height: 30, borderColor: '#003CA5', width: 50 }}
                                IconComponent={(props) => (
                                  <ExpandMoreIcon
                                    {...props}
                                    style={{ color: '#8898aa', cursor: 'pointer', marginRight: 5 }}
                                  />
                                )}
                                input={<Input />}
                              /*   MenuProps={MenuProps} */
                              >
                                {pageSizeOptions.map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => (setPageSize(option), setPage(1))}
                                  >
                                    <ListItemText className={classes.ListItemText2} primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users.map((row) => (
                            <ExpandingRow adminAddNoteOnUser={props.adminAddNoteOnUser} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {users.length > 0 ? (
                      <Grid container item style={{ margin: '30px 0', padding: 0 }} justify="flex-end">
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={handlePageChange}
                          variant="outlined"
                          color="primary"
                        />
                      </Grid>
                    ) : (
                      <Grid container justify="center">
                        <Typography style={{ margin: '25px 0' }} variant="h5">
                          Kullanıcı bulunamadı...
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}