import {Avatar, Grid, Typography, makeStyles} from "@material-ui/core";

import React from "react";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  card: {
    "&:hover": {
      background: "#0000000a",
      transition: "all .2s ease-in-out"
    },
    "&:last-child": {
      paddingBottom: "calc(12px + env(safe-area-inset-bottom))"
    },
    cursor: "pointer",
    padding: "18px 20px",
    height: "fit-content"
  },
  selectedCard: {
    background: "#0000000a",
    cursor: "pointer",
    padding: "18px 20px",
    height: "fit-content"
  },
  avatar: {
    width: 46,
    height: 46,
    marginRight: 10
  },
  unreadBead: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    background: "#0054E8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
  },
  unreadText: {
    textAlign: "center",
    color: "white",
    fontSize: "12px",
    lineHeight: "12px"
  }
}));

export default function InboxCard({data, isSelected, ...rest}) {
  const classes = useStyles();
  const user = data?.users[0] || {};

  return (
    <Grid container className={isSelected ? classes.selectedCard : classes.card} {...rest}>
      <Grid item>
        <Avatar className={classes.avatar} src={user.profilePhoto}></Avatar>
      </Grid>
      <Grid item xs>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs>
            <Typography>{user.firstName + " " + user.lastName} </Typography>
          </Grid>
          {data.hasUnreadMessage && !isSelected && (
            <div className={classes.unreadBead}></div>
          )}
        </Grid>

        <Grid container justify="flex-end">
          {moment.utc(data.updatedAt, "x").local().fromNow()}
        </Grid>
      </Grid>
    </Grid>
  );
}
