import { ADMIN_GET_PENDING_STARTUP_INV_REQS_WITH_ID, MY_PROFILE } from '../../queries';
import {
  formatDashedDate,
  numberWithPeriods,
  resolveCurrencySymbol,
  resolveStatusColor,
  resolveStatusText,
} from '../../helpers';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Alert from '@material-ui/lab/Alert';
import Bush from './yatirim_bg.png';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GreenTag from '../greenTag.js';
import Grid from '@material-ui/core/Grid';
import { INVESTOR_SEND_STARTUP_INVESTMENT_REQUEST } from '../../mutations';
import IsFeaturedBanner from '../../Components/IsFeaturedBanner';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import StarIcon from '@material-ui/icons/Star';
import Stepper from './Stepper';
import ToInvestPopup from '../ToInvestPopup/ToInvestPopup';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const CustomDialogActions = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
}))(DialogActions);

const useStyles = makeStyles((theme) => ({
  startupCard: {
    background: '#ffffff',
    minHeight: '310px',
    display: 'block',
    height: '472px',
    borderRadius: '16px',
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    padding: 36,
    [theme.breakpoints.down('lg')]: {
      padding: 24,
    },
    [theme.breakpoints.down('sm')]: {
      //minHeight: '0',
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
    },
  },
  titleBar: {
    width: 'fit-content',
    marginLeft: 8,
  },
  startupGrid: {
    height: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
  values: {
    color: '#28b1e7',
    wordBreak: 'break-word',
    marginTop: 5,
  },
  startupLogo: {
    width: 70,
    height: 70,
    [theme.breakpoints.down('lg')]: {
      width: 60,
      height: 60,
    },
    background: '#423eff',
    borderRadius: '10px',
  },
  img: {
    width: 45,
    height: 45,
    objectFit: 'contain',
    borderRadius: '12px',
  },
  Title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: 'left',
    color: '#172b4d',
    wordBreak: 'break-word',
  },
  favIcon: {
    cursor: 'pointer',
  },
  desc: {
    color: '#425466',
    wordBreak: 'break-word',
    fontSize: 14,
  },
  model: {
    color: '#777777',
    fontSize: 18,
    [theme.breakpoints.down('lg')]: {
      fontSize: 13,
    },
  },
  splitLine: {
    paddingTop: '24px',
    paddingBottom: '24px',
    padding: '24px',
    margin: '24px',
  },
  paper: {
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '12px',
    height: 'auto',
  },
  investTitleText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '9px',
    lineHeight: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#8492A6',
    minHeight: 24,
    height: 'auto',
  },
  investText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#118DFF',
    paddingTop: '20px',
    paddingBottom: '20px',
    minHeight: 45,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  buttonsBg: {
    marginTop: '14px',
    marginBottom: '14px',
  },
  btnBLue: {
    padding: '18px 0 18px 0',
    background: '#003CA5',
    borderRadius: '8px',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '12px',
    color: '#fff',
    border: '1px solid #003cA5',
    '&:hover': {
      background: '#fff',
      border: '1px solid #003cA5',
      color: '#003cA5',
      '& svg > path': {
        stroke: '#003cA5',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      height: '50%',
    },
  },
  btnOrange: {
    padding: '18px 0 18px 0',
    background: '#FFF',
    borderRadius: '8px',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '12px',
    color: '#FF9B28',
    border: '1px solid rgba(50, 50, 71, 0.05)',
    '&:hover': {
      background: '#FF9B28',
      border: '1px solid #FF9B28',
      color: '#fff',
      '& svg > path': {
        stroke: '#fff',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      height: '50%',
    },
  },
  paperPopUp: {
    padding: 12,
    textAlign: 'center',
    fontWeight: 600,
    foınstSize: 9,
    color: '#8492A6',
    height: '80%',
    width: '100%',
    boxShadow: '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    borderRadius: '8px',
  },
  h1: {
    color: '#118DFF',
  },
  formControl: {
    width: '50%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  popupPaper: {
    //margin: '0 auto',
    //width: '70%',
    //height: '55%',
    textAlign: 'center',

    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginLeft: 32,
    marginRight: 32,
    display: 'flex',
    marginBottom: 32,
  },
  popupInvestmentCard: {
    marginTop: '16px',
    marginBottom: '16px',
    width: '100%',
    height: '250px',
    margin: '0 auto',
    backgroundImage: `url(${Bush})`,
    backgroundSize: 'cover',
  },
  avatar: {
    marginTop: '2%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '4px',
  },
  checkbox: {
    margin: '0 auto',
    fontSize: '72px',
    color: '#003CA5',
    marginTop: '2%',
    marginBottom: '2%',
    backgroundColor: '#F0F5FF',
    borderRadius: '50%',
    width: 116,
    height: 116,
    textAlign: 'center',
  },
  userInput: {
    marginTop: '0',
    marginBottom: 12,
    background: '##fff',
    border: '1px solid #E3EBF2',
    height: 48,
    fontSize: 15,
    fontWeight: 500,

    '&.Mui-focused': {
      backgroundColor: '#F0F6FF',
      border: '1px solid #003CA5',
    },
    //boxShadow: '0px 0px 0px 2px rgba(76, 111, 255, 0.3)',
    /*borderRadius: '6px',
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
    margin: '8px 0px',*/
  },
  popupContainer: {
    padding: '40px 72px 40px 72px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 10px 40px 10px',
    },
  },
  popupContainer2: {
    padding: '40px 70px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 10px',
    },
  },
  disabledButton: {
    borderColor: 'rgba(50, 50, 71, 0.05)',
  },
  disabledLink: {
    cursor: 'default',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RemoveFavDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, id } = props;

  const handleSubmit = () => {
    props.removeFavorite({ variables: { startupId: id } }).then((data) => {
      if (data.data.removeFavorite) {
        refetch();
        onClose(null);
        props.setNotFavorited(true);
      }
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Favoriden Kaldır
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>
            Bu şirketi favorilerinizden kaldırıyorsunuz. Bu işlemi gerçekleştirmek istediğinizden emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button
                style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }}
                onClick={() => onClose(null)}
                variant="outlinedSecondary"
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{
                  fontWeight: 'normal',
                  padding: '11.5px 15px',
                  fontSize: 14,
                  width: '100%',
                  marginTop: 20,
                  background: '#ff5757',
                  '&:hover': { background: '#ff5757' },
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
              >
                Kaldır
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function getDateFunc() {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

RemoveFavDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function InvestToStartupCard(props) {
  const [isFavorited, setIsFavorited] = React.useState(null);
  const [remove, setRemove] = React.useState(false);
  const [notFavorited, setNotFavorited] = React.useState(false);
  const [amountWillingToPay, setAmountWillingToPay] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [requiredFieldPopup, setRequiredFieldPopup] = React.useState(false);
  const { t } = useTranslation();

  //mutations and queries
  const [investorSendInvReq] = useMutation(INVESTOR_SEND_STARTUP_INVESTMENT_REQUEST);

  const { data: userData } = useQuery(MY_PROFILE);
  //
  const { isAdmin, status } = props;

  const { data: investmentRequestData } = useQuery(ADMIN_GET_PENDING_STARTUP_INV_REQS_WITH_ID, {
    variables: { startupId: props.id },
    skip: !isAdmin,
  });

  const pendingRequestsLen = investmentRequestData?.adminPendingStartupInvestmentRequests?.filter(
    (el) => el.status === 'Pending',
  ).length;

  const resolveNormalOrAdminText = (text) => {
    if (!isAdmin) return text;

    switch (text) {
      case 'İlgileniyorum':
        return 'Turu Kontrol Et';
      case 'Keşfet':
        return 'Teklifleri Kontrol Et';
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpen3(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmitStartupInvestmentRequest = async (values) => {
    const { error } = await investorSendInvReq({
      variables: {
        startupId: props.id,
        email: values.email,
        telephone: values.telephone,
        adress: values.adress,
        communicationMethod: values.communicationMethod,
        investmentAmount: parseInt(values.investmentAmount),
      },
    });

    if (error) console.log(error);

    setOpen(false);
    setOpen3(true);
  };

  const addFavorite = (id) => {
    props
      .addFavorite({ variables: { startupId: id } })
      .then((data) => {
        if (data.data.addFavorite) {
          props.refetch();
          setIsFavorited(true);
        }
      })
      .catch(() => null);
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }
  const stage = ['Email', 'Telefon', 'Yüz yüze'];

  const classes = useStyles();
  return (
    <Grid item md={12} lg={6} sm={12} xs={12} className={classes.startupGrid}>
      {props.isFeatured && <IsFeaturedBanner />}
      <Grid item container direction="column" justify="end" xs={12} className={classes.startupCard}>
        <Grid
          container
          direction="column"
          style={{ flexGrow: 1, minHeight: 263, marginTop: props.isFeatured && 32 }}
          justify="space-between"
        >
          {isAdmin ? (
            <Link style={{ padding: 0, height: 180 }} to={`/girisim/${props.slug}`}>
              <Grid item>
                <Grid xs={12} container item justify="end">
                  <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
                    <Grid item>
                      {props.logo ? (
                        <img src={props.logo} className={classes.img} alt="logo" style={{ objectFit: 'contain' }}></img>
                      ) : (
                        <Grid
                          style={{
                            width: 45,
                            height: 45,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#003CA5',
                          }}
                        >
                          <Typography variant="h4" style={{ color: '#fff' }}>
                            {props.name && props.name.charAt(0)}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Link>
                  <Grid container direction="column" className={classes.titleBar} xs>
                    <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
                      <Typography variant="h5" align="left">
                        {props.name}
                      </Typography>
                    </Link>
                    {props.industry && (
                      <Typography variant="body2" align="left" style={{ fontSize: 12, color: '#425466' }}>
                        {props.industry[0]}
                      </Typography>
                    )}
                    <Grid container spacing={1} style={{ marginTop: 4 }}>
                      {props.businessModelArray &&
                        props.businessModelArray.length > 0 &&
                        props.businessModelArray.map((model) => (
                          <Grid item>
                            <GreenTag title={model} />
                          </Grid>
                        ))}
                      {props.country && (
                        <Grid item>
                          <GreenTag title={props.country.title} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="h4" align="left" style={{ fontSize: '14px', color: resolveStatusColor(status) }}>
                    Statü: {resolveStatusText(status)}
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: 20 }}>
                  {props.desc && props.desc !== '' ? (
                    <Typography variant="body2" align="left" className={classes.desc}>
                      {truncateString(props.desc, 120)}
                    </Typography>
                  ) : (
                    <Typography variant="body2" align="left" className={classes.desc}>
                      {props.oneliner && truncateString(props.oneliner, 120)}
                    </Typography>
                  )}
                </Grid>
                <Grid item style={{ marginTop: 20 }}></Grid>
              </Grid>
            </Link>
          ) : (
            <Grid item>
              <Grid xs={12} container item justify="end">
                <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
                  <Grid item>
                    {props.logo ? (
                      <img src={props.logo} className={classes.img} alt="logo" style={{ objectFit: 'contain' }}></img>
                    ) : (
                      <Grid
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#003CA5',
                        }}
                      >
                        <Typography variant="h4" style={{ color: '#fff' }}>
                          {props.name && props.name.charAt(0)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Link>
                <Grid container direction="column" className={classes.titleBar} xs>
                  <Link style={{ padding: 0 }} to={`/girisim/${props.slug}`}>
                    <Typography variant="h5" align="left">
                      {props.name}
                    </Typography>
                  </Link>
                  {props.industry && (
                    <Typography variant="body2" align="left" style={{ fontSize: 12, color: '#425466' }}>
                      {props.industry[0]}
                    </Typography>
                  )}
                  <Grid container spacing={1} style={{ marginTop: 4 }}>
                    {props.businessModelArray &&
                      props.businessModelArray.length > 0 &&
                      props.businessModelArray.map((model) => (
                        <Grid item>
                          <GreenTag title={model} />
                        </Grid>
                      ))}
                    {props.country && (
                      <Grid item>
                        <GreenTag title={props.country.title} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {/* {!props.isFavorited && <Typography style={{marginRight: 8}}>Favoriye Ekle</Typography>} */}
                {props.isFavorited ? (
                  <StarIcon
                    onClick={() => setRemove(true)}
                    style={{ color: '#F9AB00', cursor: 'pointer', fontSize: 32 }}
                    className={classes.favIcon}
                  />
                ) : (
                  <StarIcon
                    onClick={() => addFavorite(props.id)}
                    className={classes.favIcon}
                    style={{ color: '#dddddd', cursor: 'pointer', fontSize: 32 }}
                  />
                )}
              </Grid>

              <Grid item style={{ marginTop: 20, height: 50 }}>
                {props.desc && props.desc !== '' ? (
                  <Typography variant="body2" align="left" className={classes.desc}>
                    {truncateString(props.desc, 120)}
                  </Typography>
                ) : (
                  <Typography variant="body2" align="left" className={classes.desc}>
                    {props.oneliner && truncateString(props.oneliner, 120)}
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ marginTop: 20 }}></Grid>
            </Grid>
          )}
          <Grid classname={classes.splitLine} item xs={12}>
            <hr
              size="1"
              style={{ border: '1px solid rgba(160, 174, 192, 0.5)', marginTop: '24px', marginBottom: '24px' }}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid direction="column" alignItems="center" justify="center" item xs={6} sm={4}>
              <Paper className={classes.paper}>
                <Grid direction="column" className={classes.investTitleText}>
                  TOPLANAN TAAHHÜT TUTARI
                </Grid>
                <Grid direction="column" alignItems="center" justify="center" className={classes.investText}>
                  {(props.currentInvestmentAmount !== undefined &&
                    resolveCurrencySymbol(props.currencySymbol) + numberWithPeriods(props.currentInvestmentAmount)) ||
                    ''}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Paper className={classes.paper}>
                <Grid direction="column" className={classes.investTitleText}>
                  KALAN TAAHHÜT TUTARI
                </Grid>
                <Grid direction="column" alignItems="center" justify="center" className={classes.investText}>
                  {(props.amountOfInvestmentSought !== undefined &&
                    props.currentInvestmentAmount !== undefined &&
                    resolveCurrencySymbol(props.currencySymbol) +
                      numberWithPeriods(Math.max(0, props.amountOfInvestmentSought - props.currentInvestmentAmount))) ||
                    ''}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper className={classes.paper}>
                <Grid direction="column" className={classes.investTitleText}>
                  Tur Kapanış Tarihi
                </Grid>
                <Grid direction="column" alignItems="center" justify="center" className={classes.investText}>
                  {formatDashedDate(props.sprintClosingDate)}
                </Grid>
              </Paper>
            </Grid>
            <Grid className={classes.buttonsBg} container spacing={2}>
              <Grid item xs={6}>
                {!isAdmin ? (
                  <Button onClick={handleClickOpen} className={classes.btnBLue} fullWidth>
                    {resolveNormalOrAdminText('İlgileniyorum')}
                    <svg
                      className={classes.investBtnSvg}
                      style={{ marginLeft: 15 }}
                      alt="arrowWhite"
                      width="18"
                      height="10"
                      viewBox="0 0 20 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 1L19 5M19 5L15 9M19 5H1"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                ) : (
                  <Link
                    style={{ padding: 0 }}
                    to={status === 'Finished' ? null : `/admin/yatirimci-arayanlar/${props.id}`}
                    className={status === 'Finished' ? classes.disabledLink : ''}
                  >
                    <Button
                      className={classes.btnBLue}
                      classes={{ disabled: classes.disabledButton }}
                      disabled={status === 'Finished'}
                      fullWidth
                    >
                      {resolveNormalOrAdminText('İlgileniyorum')}
                      <svg
                        className={classes.investBtnSvg}
                        style={{ marginLeft: 15 }}
                        alt="arrowWhite"
                        width="18"
                        height="10"
                        viewBox="0 0 20 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 1L19 5M19 5L15 9M19 5H1"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Link>
                )}
              </Grid>

              <Grid item xs={6}>
                <Link
                  style={{ padding: 0 }}
                  to={
                    !isAdmin
                      ? `/girisim/${props.slug}`
                      : status === 'Finished'
                      ? null
                      : `/admin/yatirim-turu/${props.id}`
                  }
                  className={status === 'Finished' ? classes.disabledLink : ''}
                >
                  <Button
                    className={classes.btnOrange}
                    disabled={status === 'Finished'}
                    style={{ width: '100%' }}
                    fullWidth
                  >
                    {resolveNormalOrAdminText('Keşfet')}{' '}
                    {isAdmin && pendingRequestsLen !== 0 ? `(${pendingRequestsLen})` : ''}
                    <svg
                      style={{ marginLeft: 15 }}
                      alt="arrowOrange"
                      width="18"
                      height="10"
                      viewBox="0 0 20 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={classes.startupBtnSvg}
                        d="M15 1L19 5M19 5L15 9M19 5H1"
                        stroke={status === 'Finished' ? '#ffffff' : '#FF9B28'}
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RemoveFavDialog
          setNotFavorited={setNotFavorited}
          removeFavorite={props.removeFavorite}
          id={props.id}
          refetch={props.refetch}
          open={remove}
          onClose={() => setRemove(false)}
        />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setIsFavorited(false)}
        open={isFavorited}
      >
        <Alert severity="success">Şirket favorilerinize eklendi.</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={() => setNotFavorited(false)}
        open={notFavorited}
      >
        <Alert severity="success">Şirket favorilerinizden çıkartıldı.</Alert>
      </Snackbar>

      {/**************************************************Pop Up 1**********************************/}

      <ToInvestPopup
        openInvest={open}
        setOpenInvest={setOpen}
        logo={props.logo}
        name={props.name}
        industry={props.industry}
        businessModelArray={props.businessModelArray}
        country={props.country}
        amountOfInvestmentSought={props.amountOfInvestmentSought}
        currentInvestmentAmount={props.currentInvestmentAmount /*+ props.oldAdditionalCommitment*/}
        currencySymbol={props.currencySymbol}
        minTicketSize={props.minTicketSize}
        sprintClosingDate={props.sprintClosingDate}
        id={props.id}
      />

      {/*Pop Up 3*/}
      <div>
        <Dialog
          open={open3}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <Grid container sm={12} spacing={3}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} md={7} style={{ marginBottom: '50px' }}>
                  <Grid className={classes.checkbox}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17 24L21.6667 28.6667L31 19.3333M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                        stroke="#003CA5"
                        stroke-width="4.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Grid>

                  <Typography variant="h2" style={{ textAlign: 'center', marginTop: '2%' }}>
                    Talep alındı
                  </Typography>
                  <Grid style={{ width: '50%', marginLeft: '25%', marginBottom: 60 }}>
                    <Typography style={{ textAlign: 'center' }}>
                      Talebiniz hesap yöneticinize iletilmiştir. <br /> En kısa zamanda belirlediğiniz iletişim
                      yöntemiyle sizinle iletişime geçecektir.
                    </Typography>
                  </Grid>
                  <CustomDialogActions style={{ alignItems: 'stretch' }}>
                    <Button onClick={handleClose} variant="outlinedSecondary">
                      Tamam
                    </Button>
                    <Link style={{ padding: 0 }} to={`/yatirimci-arayanlar`}>
                      <Button onClick={handleClose} color="primary" variant="containedPrimary">
                        Diğer şirketleri gör
                      </Button>
                    </Link>
                  </CustomDialogActions>
                </Grid>

                {/************************ Stepper ***********************************/}
                <Grid item sm={12} md={5} style={{ marginTop: '30px' }}>
                  <Stepper firstStep={'✓'} />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setRequiredFieldPopup(false)}
        open={requiredFieldPopup}
      >
        <Alert severity="error">Lütfen tüm alanları doldurun</Alert>
      </Snackbar>
    </Grid>
  );
}
