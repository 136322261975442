import * as compose from 'lodash.flowright';

import { HEADER_LEFT_DRAWER } from '../../queries';
import MyInvestmentOffer from './MyInvestmentOffer';
import { graphql } from 'react-apollo';

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
)(MyInvestmentOffer);
export default JoinWithMutation;
