import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FieldArray, Field, useField, useFormikContext  } from 'formik';
import moment from 'moment'
import FormikTextField from "../../Components/FormikTextField";
import FormikShortTextField from "../../Components/FormikShortTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import FormikNewDropdown from '../FormikDropdown';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import { useTranslation } from 'react-i18next';
registerLocale("tr", tr);

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  fontLg: {
    fontSize: 14,
  },
  objectCard: {
    display: 'flex',
    alignItems: 'center',
  },
  leftPart: {
    textAlign: 'left',
    paddingRight: 16,
    marginRight:16,
    [theme.breakpoints.up('sm')]: {
      borderRight:'1px solid #E7E7E7'
    }
  },
  AddButton: {
    padding: '12.5px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    minWidth: 175,
    fontSize: 16,
    marginRight: 10,
    marginTop: 15,
    fontWeight: 'normal',
  },
}));
    function validateLinkedinUrl(value) {
        if(value !== '' && value){
            let error;
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'); // query string
            error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
            return error;
        }
        return false;
    }
    const DatePickerField = ({ ...props }) => {
      const { setFieldValue } = useFormikContext();
      const [field] = useField(props);
      return (
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          locale="tr"
          customInput={
            <input
            autocomplete="off"
            style={{
              width:'100%',
              padding:'13px',
              borderRadius: 3,
              border:'1px solid rgb(221 221 221 / 56%)',
              boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
              marginTop:10
            }}
            type="text"
            placeholder={'mm'} />
          }
          onChange={val => {
            setFieldValue(field.name, val);
          }}
        />
      );
    };

    function NewsPart(props){
      const { index, remove } = props;
      const classes = useStyles();
      const [open, setOpen] = React.useState(true);
      const type= ['Haber', 'Gelişmeler'];
      const { t } = useTranslation();

      return (
        <div>
        <Grid xs={12} container spacing={2}>
          <Grid container justify="space-between">
                <Grid item alignItems='center' style={{marginBottom:20,display:'flex'}}>
                    <Typography variant="h5" style={{marginLeft:5}}>
                        {props.detail.startupFeeds && props.detail.startupFeeds[index] ? props.detail.startupFeeds[index].title : 'Duyuru'}
                    </Typography>
                    {open ? (
                        <ExpandLessIcon
                            onClick={() => setOpen(!open)}
                            style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
                        />
                    ) : (
                        <ExpandMoreIcon
                            onClick={() => setOpen(!open)}
                            style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
                        />
                    )}
                </Grid>
                <DeleteOutlineIcon style={{width:25,cursor:'pointer'}} onClick={() => remove(index)}/>
          </Grid>
          {open && 
            <>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" style={{ width: 'fit-content' }}>
            {t('Duyuru başlığı')}
            </Typography>
            <FormikTextField
              name={`startupFeeds.${index}.title`}
              placeholder="Gelişme Başlığını yazınız"
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" style={{ width: 'fit-content' }}>
            {t('Duyuru tarihi')}
            </Typography>
            <DatePickerField name={`startupFeeds.${index}.date`} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" style={{ width: 'fit-content' }}>
            {t('Duyuru Türü')}
            </Typography>
            <Field
              component={FormikNewDropdown}
              textFieldProps={{ fullWidth: true, variant: 'outlined' }}
              name={`startupFeeds.${index}.type`}
              options={type}
            />
          </Grid>
          <Grid xs={12} item>
            <Typography variant="h6" style={{ width: 'fit-content' }}>
            {t('Duyuru açıklaması')}
            </Typography>
            <FormikMultiLine
              name={`startupFeeds.${index}.desc`}
              rows={4}
              type="text"
              placeholder="Gelişme detayını yazınız…"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <Typography variant="h6" style={{ width: 'fit-content' }}>
            {t('Duyuru linki')}
            </Typography>
            <FormikShortTextField
              name={`startupFeeds.${index}.newUrl`}
              validate={validateLinkedinUrl}
              placeholder="https://"
              type="text"
            />
          </Grid>
          </>
        }
          <Grid item xs={12}>
            <hr size="1" style={{ border: '0.2px solid rgb(245, 245, 245)', marginBottom: 25 }} />
          </Grid>
        </Grid>
      </div>
      );
    }    

export default function AnnouncementTab(props) {
        const classes = useStyles();
        const { t } = useTranslation();

        return (
          <Paper className={classes.paper}>
            {!props.edit ? (
              <Grid item>
                {props.detail.startupFeeds ? (
                  <>
                    {props.detail.startupFeeds.length > 0 ? (
                      <>
                        <Grid container xs={12} direction="column" alignItems="flex-start">
                          {props.detail.startupFeeds.map(
                            (startupFeed, index) =>
                              startupFeed && (
                                <Grid md={11} container style={{ marginBottom: 40 }}>
                                  <Grid item className={classes.leftPart}>
                                    <Typography variant="body1" style={{ color: '#A0AEC0', fontSize: 12 }}>
                                      {moment(startupFeed.date).format('DD-MM-YYYY')}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      align="right"
                                      style={{ color: '#A0AEC0', marginBottom: 10 }}
                                    >
                                      {startupFeed.type}
                                    </Typography>
                                  </Grid>
                                  <>
                                    {props.data ? (
                                      <>
                                        {props.data.newDetail && props.data.originalDetail && (
                                          <>
                                            {JSON.stringify(props.data.newDetail.startupFeeds[index]) ===
                                            JSON.stringify(props.data.originalDetail.startupFeeds[index]) ? (
                                              <Grid item xs>
                                                <Typography variant="h3" style={{ textAlign: 'left' }}>
                                                  {startupFeed.title}
                                                </Typography>
                                                <Typography
                                                  variant="body1"
                                                  style={{
                                                    textAlign: 'left',
                                                    color: '#425466',
                                                    marginTop: 5,
                                                    wordBreak: 'break-all',
                                                  }}
                                                >
                                                  {startupFeed.desc}
                                                </Typography>
                                                <Typography
                                                  variant="h5"
                                                  style={{
                                                    textAlign: 'left',
                                                    color: '#118DFF',
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  <a
                                                    href={startupFeed.newUrl}
                                                    alt="url"
                                                    target="_blank"
                                                    style={{ padding: 0, color: '#118DFF', fontSize:16 }}
                                                  >
                                                    {startupFeed.newUrl}
                                                  </a>
                                                </Typography>
                                              </Grid>
                                            ) : (
                                              <Grid
                                                item
                                                xs
                                                style={{
                                                  background: '#003CA5',
                                                  padding: '0 5px',
                                                  borderRadius: 4,
                                                }}
                                              >
                                                <Typography variant="h3" style={{ textAlign: 'left' }}>
                                                  {startupFeed.title}
                                                </Typography>
                                                <Typography
                                                  variant="body1"
                                                  style={{
                                                    textAlign: 'left',
                                                    color: '#425466',
                                                    marginTop: 5,
                                                    wordBreak: 'break-all',
                                                  }}
                                                >
                                                  {startupFeed.desc}
                                                </Typography>
                                                  <Typography
                                                    variant="h5"
                                                    style={{
                                                      textAlign: 'left',
                                                      color: '#118DFF',
                                                      marginTop: 5,
                                                    }}
                                                  >
                                                  <a
                                                  href={startupFeed.newUrl}
                                                  alt="url"
                                                  target="_blank"
                                                  style={{ padding: 0, color: '#118DFF', fontSize:16 }}
                                                >
                                                    {startupFeed.newUrl}
                                                    </a>
                                                  </Typography>
                                              </Grid>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <Grid item xs>
                                        <Typography variant="h3" style={{ textAlign: 'left' }}>
                                          {startupFeed.title}
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          style={{
                                            textAlign: 'left',
                                            color: '#425466',
                                            marginTop: 5,
                                            wordBreak: 'break-all',
                                          }}
                                        >
                                          {startupFeed.desc}
                                        </Typography>
                                          <Typography
                                            variant="body1"
                                            style={{ textAlign: 'left', color: '#118DFF', marginTop: 5 }}
                                          >
                                          <a href={startupFeed.newUrl} alt="url" target="_blank" style={{ padding: 0, color: '#118DFF', fontSize:16 }}>
                                            {startupFeed.newUrl}
                                          </a>
                                          </Typography>
                                      </Grid>
                                    )}
                                  </>
                                </Grid>
                              ),
                          )}
                        </Grid>
                      </>
                    ) : (
                      <Typography variant="h5" align="center">
                        {t('Duyuru Bulunamadı')}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="h5" align="center">
                    {t('Duyuru Bulunamadı')}
                  </Typography>
                )}
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid xs={12} item>
                    <FieldArray
                      name="startupFeeds"
                      render={({ insert, remove, push, unshift, form }) => (
                        <>
                          <div>
                            {form.values.startupFeeds && form.values.startupFeeds.length > 0 && (
                              <Grid item xs={12} style={{ paddingTop: 0, marginTop: 15 }}>
                                {form.values.startupFeeds.map((news, index) => (
                                  <NewsPart index={index} remove={remove} detail={props.detail} />
                                ))}
                              </Grid>
                            )}
                            <Grid xs={12} container justify="center" style={{ marginTop: 20 }}>
                              <Button variant="outlinedPrimary" className={classes.AddButton} onClick={() => push()}>
                                {t('Yeni Duyuru Ekle')}
                              </Button>
                            </Grid>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        );
}