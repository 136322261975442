import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./slider.css";

import {Form, Formik} from "formik";
import {getClosingDate, resolveCurrencySymbol} from "../../helpers";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";

import Alert from "@material-ui/lab/Alert";
import AnnouncementTab from "../../Components/AnnouncementTab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommentTab from "../../Components/CommentTab";
import CreateConversation from "../../Components/CreateConversation";
import DetailTab from "../../Components/DetailTab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FinanceTab from "../../Components/FinanceTab";
import FormikMultiLine from "../../Components/FormikMultiLine";
import GeneralTab from "../../Components/GeneralTab";
import GreenTag from "../../Components/greenTag.js";
import Grid from "@material-ui/core/Grid";
import IsFeaturedBanner from "../../Components/IsFeaturedBanner";
import {Link} from "react-router-dom";
import MakeSecondaryShareRequestPopup from "./MakeSecondaryShareRequestPopup";
import MaxLineTypography from "../../Components/MaxLineTypography/MaxLineTypography.js";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Paper from "@material-ui/core/Paper";
import ProductTab from "../../Components/ProductTab";
import PropTypes from "prop-types";
import React from "react";
import Right from "../../static/icon/arrow-right.svg";
import Slider from "@material-ui/core/Slider";
import Slider2 from "react-slick";
import Snackbar from "@material-ui/core/Snackbar";
import StarIcon from "@material-ui/icons/Star";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import ToInvestPopup from "../../Components/ToInvestPopup";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {detailSchema} from "../../yupSchemas";
import get from "lodash/get";
import gql from "graphql-tag";
import insta from "../../static/icon/new-instagram.svg";
import left from "../../static/icon/arrow-left.svg";
import linkedin from "../../static/icon/new-linkedin.svg";
import moment from "moment";
import newBag from "../../static/icon/new-bag.svg";
import newCross from "../../static/icon/new-cross.svg";
import newNotify from "../../static/img/new-notify.png";
import reportImg from "../../static/img/startupLanding3.png";
import twitter from "../../static/icon/new-twitter.svg";
import {useTranslation} from "react-i18next";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth
    },
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  root: {
    display: "flex"
  },
  cardGrid: {
    margin: 0,
    marginTop: 30,
    alignItems: "stretch"
  },
  whiteCard: {
    minHeight: "185px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    background: "#ffffff",
    borderRadius: "16px",
    padding: 36,
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    [theme.breakpoints.down("lg")]: {
      padding: 24
    }
  },
  notifyImg: {
    [theme.breakpoints.down("xs")]: {
      width: 80
    }
  },
  shareBuySellButtons: {
    width: 143,
    display: "inline-block",
    padding: "11.5px 0"
  },
  gridButton: {
    marginTop: 16,
    padding: "11.5px 0",
    fontSize: 12
  },
  cardButtons: {
    marginTop: 16,
    fontSize: 12,
    [theme.breakpoints.down("lg")]: {
      padding: "11.5px 21px"
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 198,
      minHeight: 55
    }
  },
  cardButtons2: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 12,
    [theme.breakpoints.down("lg")]: {
      padding: "11.5px 21px"
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 198,
      minHeight: 55,
      marginLeft: 0
    }
  },
  twoButtonContainer: {
    width: "100%",
    marginBottom: "0px",
    flexWrap: "nowrap"
  },
  TabsGeneral: {
    background: "#fff",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    boxShadow: "0px 0px 1px 0px rgb(50 50 71 / 5%)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))"
  },
  whiteGrid: {
    height: "auto",
    display: "flex",
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important"
    }
  },
  notifyImg: {
    [theme.breakpoints.down("xs")]: {
      width: 80
    }
  },
  titleBar: {
    width: "fit-content",
    marginLeft: 16,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: 25
    }
  },
  titleBar2: {
    width: "fit-content",
    marginLeft: 16
  },
  titleFlex: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: 25
    }
  },
  Title: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: "left",
    color: "#172b4d",
    wordBreak: "break-word"
  },
  model: {
    color: "#777777",
    width: "100%",
    fontSize: 16,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 30,
      width: "fit-content"
    }
  },
  oneliner: {
    color: "#425466",
    fontSize: 12
  },
  onelinerInvestBox: {
    color: "#425466",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14
  },
  img: {
    objectFit: "contain",
    width: 82,
    height: 82,
    borderRadius: "14px",
    [theme.breakpoints.down("sm")]: {
      width: 45,
      height: 45
    }
  },
  imgEmpty: {
    width: 82,
    height: 82,
    borderRadius: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#003CA5",
    [theme.breakpoints.down("sm")]: {
      width: 45,
      height: 45
    }
  },
  imgEmptyInvest: {
    width: 155,
    height: "100%",
    borderRadius: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#F0F5FF",
    [theme.breakpoints.down("sm")]: {
      width: 45,
      height: 45
    }
  },
  bottomBar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 999,
    background: "#fff",
    padding: "8px 70px",
    boxShadow: "0px -4px 4px #F7F7FC;"
  },
  button: {
    padding: "11px 20px",
    fontWeight: "normal",
    marginTop: 20,
    fontSize: 13
  },
  buttonTwo: {
    padding: "11px 20px",
    fontWeight: "normal",
    marginTop: 20,
    fontSize: 13
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: 4,
    color: theme.palette.text.secondary,
    boxShadow: "none",
    background: "#ffffff",
    marginBottom: "30px",
    padding: 50,
    [theme.breakpoints.down("sm")]: {
      minHeight: "0",
      padding: 25
    }
  },
  paper2: {
    textAlign: "center",
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 1px 0px rgb(50 50 71 / 5%)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    background: "#ffffff",
    marginBottom: "30px",
    padding: 36,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down("lg")]: {
      minHeight: "0",
      padding: 24
    }
  },
  buttonThree: {
    padding: "11px 20px",
    fontWeight: "normal",
    background: "#43b506",
    marginTop: 20,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 18
    },
    fontSize: 13,
    "&:hover": {
      background: "#43b506"
    }
  },
  socialLink: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 25
    },
    color: "#003CA5"
  },
  socialMargin: {
    marginLeft: 5,
    [theme.breakpoints.up("md")]: {
      marginLeft: 25
    }
  },
  tabPaper: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 16px"
    }
  },
  tabGrid: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 16px"
    }
  },
  editButton: {
    fontWeight: "normal",
    [theme.breakpoints.up("sm")]: {
      marginRight: 25
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 20
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "20px"
    }
  },
  closingText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#425466"
  },
  closingDate: {
    color: "#118DFF"
  },
  sliderDarkBlue: {
    rail: {
      height: "20px",
      borderRadius: 10
    },
    root: {
      "&$disabled": {
        color: "yellow"
      }
    },
    track: {
      height: "10px",
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10
    }
  },
  splitLine: {
    paddingTop: "24px",
    paddingBottom: "24px",
    padding: "24px",
    margin: "24px"
  },
  soughtInvest: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "8px",
    lineHeight: "16px",
    textAlign: "start",
    color: "#118DFF"
  },
  collectedInvest: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "start",
    color: "#718096"
  },
  amountText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "start",
    color: "#118DFF"
  },
  amountText2: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "start",
    color: "#fff"
  },
  amountText3: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "start",
    color: "#118DFF",
    marginLeft: 10
  },
  ticketBox: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    borderRadius: 8,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 24,
      marginRight: 24
    }
  },
  ticketBoxİnline: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  ticketText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "9px",
    lineHeight: "12px",
    textAlign: "center",
    color: "#8492A6"
  },
  amountTicket: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "12px",
    textAlign: "center",
    color: "#118DFF",
    paddingTop: 25
  },
  docsObject: {
    padding: 16,
    borderRadius: 16,
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))"
  },
  lastEditDate: {
    position: "absolute",
    padding: "10px 15px 0px 0px",
    right: 0,
    top: "0px",
    fontWeight: 600,
    fontSize: 13,
    color: "#A0AFBF"
  },
  pendingFillRate: {
    right: 0,
    top: "0px",
    fontWeight: "normal",
    fontSize: 14,
    color: "#A0AFBF"
  },
  ctaButton: {
    marginTop: "10px",
    whiteSpace: "nowrap",
    paddingLeft: 30,
    paddingRight: 30
  }
}));
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#FAFAFB",
    minWidth: 170,
    color: "#8492A6",
    padding: "18px 24px"
  },
  body: {
    fontSize: 14,
    borderBottom: "none",
    padding: "16px 0px 16px 24px",
    boxShadow: "inset 0px -1px 0px #edf2f7"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f7f7f7"
    }
  }
}))(TableRow);

const AntSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

function DraftDialog(props) {
  const classes = useStyles();
  const {onClose, open} = props;
  const [drafts, setDrafts] = React.useState([]);

  const DRAFTS = gql`
    query getStartupDrafts {
      getStartupDrafts {
        id
        status
        lastUserEdit
        description
        moderationStatus
        data {
          profileFillRate
        }
      }
    }
  `;

  const {loading: loadingDraft, data: dataDraft} = useQuery(DRAFTS, {
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (!loadingDraft) {
      if (dataDraft) {
        setDrafts(dataDraft.getStartupDrafts);
      }
    }
  }, [loadingDraft, dataDraft]);

  const handleDraft = () => {
    props
      .startupNewDraft()
      .then(data => {
        if (data.data.startupNewDraft) {
          props.history.push(`/draft-edit/${data.data.startupNewDraft}#edit`);
        } else {
        }
      })
      .then(() => {})
      .catch(error => {});
  };

  const editDraft = value => {
    props.history.push(`/draft-edit/${value}#edit`);
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <>
      {drafts[0]?.status === "Pending" ? (
        <Dialog
          style={{padding: 20}}
          fullWidth={true}
          maxWidth="xs"
          classes={{paper: classes.myDialog}}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <Grid container justify="flex-end" style={{padding: "30px 30px 0 0"}}>
            <img src={newCross} alt="newCross" onClick={handleClose} style={{cursor: "pointer"}} />
          </Grid>
          <Grid
            container
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div style={{width: "116px", height: "116px", backgroundColor: "#F0F5FF", borderRadius: "100%"}}></div>{" "}
            <svg
              style={{height: "42px", position: "absolute"}}
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.5408 7.19828L38.7913 15.4323M34.0407 3.70533C35.1348 2.61342 36.6187 2 38.166 2C39.7133 2 41.1972 2.61342 42.2913 3.70533C43.3853 4.79723 44 6.27817 44 7.82235C44 9.36653 43.3853 10.8475 42.2913 11.9394L10.1665 44H2V35.6821L34.0407 3.70533Z"
                stroke="#003CA5"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Grid>

          <DialogTitle id="simple-dialog-title">
            <Typography
              align="center"
              style={{
                paddingLeft: 32,
                paddingRight: 32,
                fontWeight: 600,
                fontSize: "28px",
                lineHeight: "38px",
                textAlign: "center",
                color: "#27272E"
              }}
            >
              {"Düzenlemeye devam et"}
            </Typography>
          </DialogTitle>
          <Typography
            align="center"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
              fontWeight: 100,
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#425466"
            }}
          >
            {"Önceki düzenleme talebiniz daha onaylanmadı. Taslak profili düzenlemeye devam edebilirsiniz."}
          </Typography>
          <Grid container style={{marginTop: 15, marginBottom: 40}}>
            <Grid style={{display: "flex", alignItems: "center", justifyContent: "center"}} xs={12} md={6}>
              <Button style={{minWidth: "70%", marginTop: 15}} onClick={handleClose} variant="outlinedSecondary">
                Vazgeç
              </Button>
            </Grid>
            <Grid style={{display: "flex", alignItems: "center", justifyContent: "center"}} xs={12} md={6}>
              <Button
                style={{minWidth: "70%", marginTop: 15}}
                onClick={() => editDraft(drafts[0].id)}
                variant="containedPrimary"
              >
                Düzenle
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      ) : (
        <Dialog
          style={{padding: 20}}
          fullWidth={true}
          maxWidth="xs"
          classes={{paper: classes.myDialog}}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <Grid container justify="flex-end" style={{padding: "30px 30px 0 0"}}>
            <img src={newCross} alt="newCross" onClick={handleClose} style={{cursor: "pointer"}} />
          </Grid>
          <Grid
            container
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div style={{width: "116px", height: "116px", backgroundColor: "#F0F5FF", borderRadius: "100%"}}></div>{" "}
            <svg
              style={{height: "42px", position: "absolute"}}
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.5408 7.19828L38.7913 15.4323M34.0407 3.70533C35.1348 2.61342 36.6187 2 38.166 2C39.7133 2 41.1972 2.61342 42.2913 3.70533C43.3853 4.79723 44 6.27817 44 7.82235C44 9.36653 43.3853 10.8475 42.2913 11.9394L10.1665 44H2V35.6821L34.0407 3.70533Z"
                stroke="#003CA5"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Grid>

          <DialogTitle id="simple-dialog-title">
            <Typography
              align="center"
              style={{
                paddingLeft: 32,
                paddingRight: 32,
                fontWeight: 600,
                fontSize: "28px",
                lineHeight: "38px",
                textAlign: "center",
                color: "#27272E"
              }}
            >
              {"Draft Oluştur"}
            </Typography>
          </DialogTitle>
          <Typography
            align="center"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
              fontWeight: 100,
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#425466"
            }}
          >
            {"Yaptığınız değişiklikler, düzenleme talebi olarak admin onayına gönderilecektir."}
          </Typography>
          <Grid container style={{marginTop: 15, marginBottom: 40}}>
            <Grid style={{display: "flex", alignItems: "center", justifyContent: "center"}} xs={12} md={6}>
              <Button style={{minWidth: "70%", marginTop: 15}} onClick={handleClose} variant="outlinedSecondary">
                Vazgeç
              </Button>
            </Grid>
            <Grid style={{display: "flex", alignItems: "center", justifyContent: "center"}} xs={12} md={6}>
              <Button style={{minWidth: "70%", marginTop: 15}} onClick={() => handleDraft()} variant="containedPrimary">
                Devam Et
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
}

function ReportDialog(props) {
  const classes = useStyles();
  const {onClose, open, startupId} = props;

  const handleSubmit = variables => {
    variables.startupId = startupId;
    props
      .createStartupFollowRequest({variables})
      .then(data => {
        if (data.data.createStartupFollowRequest) {
          onClose(null);
          props.setReportSent(true);
        } else {
          props.setReportError(true);
        }
      })
      .then(() => {})
      .catch(error => {
        props.setReportError(true);
        onClose(null);
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{padding: 20}}
      fullWidth={true}
      maxWidth="xs"
      classes={{paper: classes.myDialog}}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding: "30px 30px 0 0"}}>
        <img src={newCross} alt="newCross" onClick={handleClose} style={{cursor: "pointer"}} />
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Rapor Talep Et
        </Typography>
      </DialogTitle>
      <Grid container justify="center" xs={12}>
        <Grid item style={{marginBottom: 20}} xs={10}>
          <Typography align="center" variant="body1" style={{color: "#777777"}}>
            Girişim hakkında merak ettiğiniz diğer detaylar için rapor talep edebilirsiniz.
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          startupId: "",
          note: ""
        }}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {props => (
          <>
            <Grid container justify="center" style={{marginBottom: 50}} xs={12}>
              <Grid container justify="space-between" xs={9}>
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid item container alignItems="flex-end">
                      <Grid item container xs={12} style={{height: "fit-content"}}>
                        <Typography variant="h6" style={{width: "100%"}}>
                          Talep Açıklaması
                        </Typography>
                        <FormikMultiLine
                          style={{width: "100%", marginBottom: 20}}
                          name="note"
                          rows={4}
                          type="text"
                          placeholder="Merak ettiğiniz ve/veya raporda görmek istediğiniz içerik hakkında açıklama"
                        />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{marginBottom: 20, height: "fit-content"}}>
                        <Button
                          variant="containedPrimary"
                          style={{fontWeight: "normal", width: "100%"}}
                          disabled={!props.values.note}
                          onClick={() => handleSubmit(props.values)}
                        >
                          Gönder
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Dialog>
  );
}

ReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

function RemoveFavDialog(props) {
  const classes = useStyles();
  const {onClose, open, id, STARTUP_DETAIL, slug, startupId, paramSlug, setFav, setFavAlert} = props;

  const handleSubmit = () => {
    props
      .removeFavorite({
        variables: {startupId: id},
        refetchQueries: [
          {
            query: STARTUP_DETAIL,
            variables: startupId && startupId.toString() === paramSlug ? {id: startupId} : {slug: paramSlug}
          }
        ]
      })
      .then(data => {
        if (data.data.removeFavorite) {
          setFav(false);
          setFavAlert(true);
          onClose(null);
          props.setNotFavorited(true);
        }
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{padding: 20}}
      fullWidth={true}
      maxWidth="xs"
      classes={{paper: classes.myDialog}}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle style={{marginTop: 25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">
          Favoriden Kaldır
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom: 50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{color: "#777777"}}>
            Bu şirketi favorilerinizden kaldırıyorsunuz. Bu işlemi gerçekleştirmek istediğinizden emin misiniz?
          </Typography>
          <Grid container xs={12} spacing={2} style={{margin: 0}}>
            <Grid item xs={6} md={6}>
              <Button
                style={{fontWeight: "normal", padding: "11.5px 15px", fontSize: 14, minWidth: "90px", marginTop: 20}}
                onClick={() => onClose(null)}
                variant="outlinedSecondary"
                fullWidth
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                style={{
                  fontWeight: "normal",
                  padding: "11.5px 15px",
                  fontSize: 14,
                  marginTop: 20,
                  background: "#ff5757",
                  minWidth: "90px",
                  "&:hover": {background: "#ff5757"}
                }}
                onClick={() => handleSubmit()}
                variant="containedPrimary"
                fullWidth
              >
                Kaldır
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
RemoveFavDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default function StartupProfile(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openInvest, setOpenInvest] = React.useState(false);

  const [valuation, setValuation] = React.useState([]);
  const [investmentAmount, setInvestmentAmount] = React.useState([]);
  const [date, setDate] = React.useState([]);
  const [volume, setVolume] = React.useState([]);
  const [numberOfTransactions, setNumberOfTransactions] = React.useState([]);
  const [dateTraction, setDateTraction] = React.useState([]);
  const [sumTraction, setSumTraction] = React.useState([]);
  const [detail, setDetail] = React.useState({});
  const [fav, setFav] = React.useState(null);
  const [favAlert, setFavAlert] = React.useState(false);
  const [isComment, setIsComment] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [initialValuess, setInitialValuess] = React.useState();
  const [value, setValue] = React.useState(0);
  const [authRole, setAuthRole] = React.useState();
  const [isSent, setIsSent] = React.useState(false);
  const [reportSent, setReportSent] = React.useState(false);
  const [reportError, setReportError] = React.useState(false);
  const [isNotify, setIsNotify] = React.useState(false);
  const [notifySent, setNotifySent] = React.useState(false);
  const [notifyText, setNotifyText] = React.useState("");
  const [notifyError, setNotifyError] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [report, setReport] = React.useState(false);
  const [myId, setMyId] = React.useState(props.startupId);
  const [slug, setSlug] = React.useState(props.match.params.slug);
  //new addings
  //secondarySharePopupType: 'Buy' / 'Sell' OR if falsy (pop-up closed),
  const [secondarySharePopupType, setSecondarySharePopupType] = React.useState(null);
  const [notFavorited, setNotFavorited] = React.useState(false);
  const [remove, setRemove] = React.useState(false);

  const {getInvestorProfileStatus} = props;
  const isAdmin = props.authRole === "Admin" || props.authRole === "SuperAdmin";

  const {t} = useTranslation();
  const [drafts, setDrafts] = React.useState([]);
  const [fillRate2, setFillRate2] = React.useState(false);
  const [fillRate, setFillRate] = React.useState(false);

  const userId = props.userId; //created this because there is another props defined and it makes it impossible to reach props.userId

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNotify = () => {
    setIsNotify(!isNotify);
    props
      .handleStartupInvNotify({variables: {startupId: detail.id, currentStatus: !isNotify}})
      .then(data => {
        if (data.data.handleStartupInvNotify) {
          setNotifySent(true);
          if (isNotify) {
            setNotifyText("Haberdar olma talebiniz başarıyla geri alınmıştır.");
          } else {
            setNotifyText("Haberdar olma talebiniz başarıyla alınmıştır.");
          }
        } else {
          setNotifyError(true);
        }
      })
      .then(() => {})
      .catch(error => {
        setNotifyError(true);
      });
  };
  const DRAFTS = gql`
    query getStartupDrafts {
      getStartupDrafts {
        id
        status
        lastUserEdit
        description
        moderationStatus
        data {
          profileFillRate
        }
      }
    }
  `;

  const {loading: loadingDraft, data: dataDraft} = useQuery(DRAFTS, {
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    if (!loadingDraft) {
      if (dataDraft) {
        setDrafts(dataDraft.getStartupDrafts);
      }
    }
  }, [loadingDraft, dataDraft]);

  const STARTUP_DETAIL = gql`
    query startupDetail($id: ID, $slug: String) {
      startupDetail(id: $id, slug: $slug) {
        users {
          id
          firstName
          lastName
        }
        id
        slug
        title
        logo
        oneliner
        description
        currency
        sector
        stage
        socialLinkedin
        socialFacebook
        socialTwitter
        socialInstagram
        stockPrice
        stockValuation
        stockDiscount
        stockEndDate
        summary
        contactMail
        website
        industry
        businessModelArray
        foundationDate
        iosApp
        androidApp
        webApp
        targetedMarket
        totalMarketSize
        targetedTotalMarketSize
        targetedRegion
        detailMarketAnalysisNote
        hasPotentialExitStrategy
        plannedExitDate
        potentialBuyers
        hasUsers
        userSize
        monthlyActiveUserSize
        hasInvestment
        totalInvestmentRound
        totalInvestmentSize
        lastValuation
        lastFundingDate
        lastFundingType
        lastFundingSize
        isFavorited
        detailMarketAnalysis
        detailTractionText
        tractionPayingCustomerSize
        tractionHasPoCCustomer
        tractionHasRevenue
        tractionRevenueSize
        productsProblemsSolutions
        hasApplication
        productsDifferences
        profileFillRate
        publicReports {
          id
          title
          sendingText
          sentDate
        }
        detailRevenueModels {
          title
          description
          revenuePercentage
          items {
            productName
            price
          }
        }
        startupFeeds {
          date
          type
          title
          desc
          newUrl
        }
        founders {
          onlyLogo
          logo
          nameSurname
          position
          title
          linkedin
        }
        investors {
          onlyLogo
          logo
          title
          subtitle
          link
        }
        competitors {
          onlyLogo
          logo
          title
          link
        }
        customers {
          onlyLogo
          logo
          title
          link
        }
        documents {
          onlyFile
          file
          title
          type
          extension
          fileSize
          date
        }
        country {
          id
          title
        }
        isUserNotif
      }
    }
  `;

  const [getData, {loading, data}] = useLazyQuery(STARTUP_DETAIL, {
    fetchPolicy: "network-only",
    variables:
      props.startupId && props.startupId.toString() === props.match.params.slug ? {id: props.startupId} : {slug: slug}
  });

  const GET_ACTIVE_INVESTMENT_DETAİL = gql`
    query getActiveInvestmentSprintDetail($startupId: ID!) {
      getActiveInvestmentSprintDetail(startupId: $startupId) {
        id
        status
        isFeatured
        sprintClosingDate
        minTicketSize
        currentCommitmentAmount
        currentInvestmentAmount
        requiredInvestmentAmount
        hasReceivedCommitment
        sprintCurrencySymbol
        oldAdditionalCommitment
        sprintCurrencySymbol
        registerNewspaperDocs {
          onlyFile
          file
          title
          type
          fileSize
          date
          extension
        }
        mainContractDocs {
          onlyFile
          file
          title
          type
          fileSize
          date
          extension
        }
        taxPlateDocs {
          title
          file
          extension
          fileSize
          date
        }
        operatingCertificateDocs {
          title
          file
          extension
          fileSize
          date
        }
      }
    }
  `;

  const {data: activeData} = useQuery(GET_ACTIVE_INVESTMENT_DETAİL, {
    variables: {
      startupId: detail.id
    },
    skip: !detail.id
  });
  const isFeaturedSprint = activeData?.getActiveInvestmentSprintDetail?.isFeatured;
  const {days, hrs, min, ttt, today} = getClosingDate(activeData?.getActiveInvestmentSprintDetail?.sprintClosingDate);

  function nFormatter(num, digits) {
    const lookup = [
      {value: 1, symbol: ""},
      {value: 1e3, symbol: " K"},
      {value: 1e6, symbol: " M"},
      {value: 1e9, symbol: " B"}
      /*{ value: 1e12, symbol: ' T' },
      { value: 1e15, symbol: ' P' },
      { value: 1e18, symbol: ' E' },*/
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return (item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0").replaceAll(".", ",");
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  function https(value) {
    if (!value.includes("http")) {
      const prefix = "https://";
      if (value.substr(0, prefix.length) !== prefix) {
        value = prefix + value;
      }
      return value;
    } else {
      return value;
    }
  }

  const addFavorite = id => {
    props.addFavorite({variables: {startupId: id}}).then(data => {
      if (data.data.addFavorite) {
        setFav(true);
        setFavAlert(true);
      }
    });
  };
  const removeFavorite = id => {
    props.removeFavorite({variables: {startupId: id}}).then(data => {
      if (data.data.removeFavorite) {
        setFav(false);
        setFavAlert(true);
      }
    });
  };
  const handleSubmit = async variablesAll => {
    setSubmitting(true);
    const {...variables} = variablesAll;
    if (variables.tractionPayingCustomerSize === "") {
      variables.tractionPayingCustomerSize = 0;
    }
    if (variables.totalInvestmentRound === "") {
      variables.totalInvestmentRound = 0;
    }
    if (variables.totalInvestmentSize === "") {
      variables.totalInvestmentSize = 0;
    }
    if (variables.lastValuation === "") {
      variables.lastValuation = 0;
    }
    if (variables.lastFundingSize === "") {
      variables.lastFundingSize = 0;
    }
    variables.slug = props.match.params.slug;
    variables.tractionRevenueSize = parseInt(variables.tractionRevenueSize);

    if (variables.userSize === "") {
      variables.userSize = 0;
    }
    if (variables.monthlyActiveUserSize === "") {
      variables.monthlyActiveUserSize = 0;
    }
    if (variables.logo !== null) {
      if (typeof variables.logo === "string" && variables.logo !== "") {
        variables.logo = null;
      }
    }
    if (variables.country !== null) {
      variables.country = variables.country.id;
    }
    props
      .adminEditOnStartup({variables})
      .then(data => {
        if (data.data.adminEditOnStartup) {
          setSlug(data.data.adminEditOnStartup);
          if (data.data.adminEditOnStartup !== variables.slug) {
            props.history.push(`/girisim/${data.data.adminEditOnStartup}`);
            getData();
            setEdit(false);
            setSubmitting(false);
            setIsSent(true);
          } else {
            getData();
            setEdit(false);
            setSubmitting(false);
            setIsSent(true);
          }
        } else {
        }
      })
      .then(() => {})
      .catch(error => {});
  };
  const handleSubmit2 = async variablesAll => {
    setSubmitting(true);
    const {...variables} = variablesAll;
    if (variables.tractionPayingCustomerSize === "") {
      variables.tractionPayingCustomerSize = 0;
    }
    if (variables.totalInvestmentRound === "") {
      variables.totalInvestmentRound = 0;
    }
    if (variables.totalInvestmentSize === "") {
      variables.totalInvestmentSize = 0;
    }
    if (variables.lastValuation === "") {
      variables.lastValuation = 0;
    }
    if (variables.lastFundingSize === "") {
      variables.lastFundingSize = 0;
    }
    variables.slug = props.match.params.slug;
    variables.tractionRevenueSize = parseInt(variables.tractionRevenueSize);
    if (variables.logo !== null) {
      if (typeof variables.logo === "string" && variables.logo !== "") {
        variables.logo = null;
      }
    }
    if (variables.country !== null) {
      variables.country = variables.country.id;
    }
    props
      .adminEditOnStartup({variables})
      .then(data => {
        if (data.data.adminEditOnStartup) {
          setSlug(data.data.adminEditOnStartup);
          if (data.data.adminEditOnStartup !== variables.slug) {
            props.history.push(`/girisim/${data.data.adminEditOnStartup}`);
            setSubmitting(false);
          } else {
            setSubmitting(false);
          }
        } else {
        }
      })
      .then(() => {})
      .catch(error => {});
  };

  const handleTypename = values => {
    values = values.map(({__typename, ...array}) => array);
    return values;
  };

  //for access profileFillRate in another pages
  if (detail.id === props.startupId) {
    localStorage.setItem("profileFillRate", detail.profileFillRate);
    localStorage.setItem("yourStartupId", detail.id);
  }

  React.useEffect(() => {
    if (props.match.params.slug !== slug) {
      setSlug(props.match.params.slug);
    }
    if (!loading) {
      if (!data) {
        // if((window.location.href.indexOf("dashboard.startupborsa") > -1) && window.userGuiding){
        //   window.userGuiding.previewGuide(1563, {
        //     checkHistory: true,
        //   });
        // }
        getData();
      }
      if (data) {
        if (data.startupDetail == null) {
          props.history.push("/404");
          window.location.reload();
        }
        if (data.startupDetail.industry === null) {
          data.startupDetail.industry = [];
        }
        let val = [];
        let invAmnt = [];
        let dateTemp = [];
        let volumeTemp = [];
        let numberOfTransactionsTemp = [];
        let sumNumberOfTransactions = 0;
        let tractionDateTemp = [];

        if (!data.startupDetail.businessModelArray) {
          data.startupDetail.businessModelArray = [];
        }

        if (data.startupDetail.tractionPayingCustomerSize === 0) {
          data.startupDetail.tractionPayingCustomerSize = null;
        }
        if (data.startupDetail.socialLinkedin) {
          data.startupDetail.socialLinkedin = https(data.startupDetail.socialLinkedin);
        }
        if (data.startupDetail.foundationDate && data.startupDetail.foundationDate.indexOf("-") < 0) {
          data.startupDetail.foundationDate = moment(parseInt(data.startupDetail.foundationDate)).format("YYYY-MM");
        }
        if (data.startupDetail.lastFundingDate && data.startupDetail.lastFundingDate.indexOf("-") < 0) {
          data.startupDetail.lastFundingDate = moment(parseInt(data.startupDetail.lastFundingDate)).format("YYYY-MM");
        }

        if (data.startupDetail.socialFacebook) {
          data.startupDetail.socialFacebook = https(data.startupDetail.socialFacebook);
        }
        if (data.startupDetail.socialTwitter) {
          data.startupDetail.socialTwitter = https(data.startupDetail.socialTwitter);
        }
        if (data.startupDetail.socialInstagram) {
          data.startupDetail.socialInstagram = https(data.startupDetail.socialInstagram);
        }
        if (data.startupDetail.website) {
          data.startupDetail.website = https(data.startupDetail.website);
        }
        if (data.startupDetail.webApp) {
          data.startupDetail.webApp = https(data.startupDetail.webApp);
        }
        if (data.startupDetail.iosApp) {
          data.startupDetail.iosApp = https(data.startupDetail.iosApp);
        }
        if (data.startupDetail.androidApp) {
          data.startupDetail.androidApp = https(data.startupDetail.androidApp);
        }
        if (data.startupDetail.founders) {
          if (data.startupDetail.founders.length > 0) {
            data.startupDetail.founders.forEach(function (a) {
              if (a.linkedin) {
                a.linkedin = https(a.linkedin);
              }
            });
          }
        }
        if (data.startupDetail.competitors) {
          if (data.startupDetail.competitors.length > 0) {
            data.startupDetail.competitors.forEach(function (a) {
              if (a.link) {
                a.link = https(a.link);
              }
            });
          }
        }
        if (data.startupDetail.investors) {
          if (data.startupDetail.investors.length > 0) {
            data.startupDetail.investors.forEach(function (a) {
              if (a.link) {
                a.link = https(a.link);
              }
            });
          }
        }
        if (data.startupDetail.startupFeeds) {
          if (data.startupDetail.startupFeeds.length > 0) {
            data.startupDetail.startupFeeds.forEach(function (a) {
              if (a && a.newUrl) {
                a.newUrl = https(a.newUrl);
              }
              if (a && a.date && a.date.indexOf("-") < 0) {
                a.date = moment(parseInt(a.date)).format("YYYY-MM");
              }
            });
          }
        }
        if (data.startupDetail.competitors !== [] && data.startupDetail.competitors) {
          data.startupDetail.competitors = handleTypename(data.startupDetail.competitors);
        }
        if (data.startupDetail.customers !== [] && data.startupDetail.customers) {
          data.startupDetail.customers = handleTypename(data.startupDetail.customers);
        }
        if (data.startupDetail.founders !== [] && data.startupDetail.founders) {
          data.startupDetail.founders = handleTypename(data.startupDetail.founders);
        }
        if (data.startupDetail.investors !== [] && data.startupDetail.investors) {
          data.startupDetail.investors = handleTypename(data.startupDetail.investors);
        }
        if (data.startupDetail.detailRevenueModels !== [] && data.startupDetail.detailRevenueModels) {
          data.startupDetail.detailRevenueModels = handleTypename(data.startupDetail.detailRevenueModels);
          data.startupDetail.detailRevenueModels.forEach(function (v) {
            if (v.items !== null) {
              v.revenuePercentage = v.revenuePercentage.replace("%", "");
              v.items.forEach(function (a) {
                if (a !== null) {
                  delete a.__typename;
                }
              });
            } else {
              v.items = [];
            }
          });
        }
        if (
          data.startupDetail.startupFeeds !== [] &&
          data.startupDetail.startupFeeds &&
          data.startupDetail.startupFeeds[0] !== null
        ) {
          data.startupDetail.startupFeeds = handleTypename(data.startupDetail.startupFeeds);
        }
        if (data.startupDetail.documents !== [] && data.startupDetail.documents) {
          data.startupDetail.documents = handleTypename(data.startupDetail.documents);
        }
        setDate(dateTemp);
        setValuation(val);
        setNumberOfTransactions(numberOfTransactionsTemp);
        setInvestmentAmount(invAmnt);
        setVolume(volumeTemp);
        setDateTraction(tractionDateTemp);
        setSumTraction(sumNumberOfTransactions);
        setDetail(data.startupDetail);
        setIsNotify(data.startupDetail.isUserNotif);
        setInitialValuess(data.startupDetail);
        setFav(data.startupDetail.isFavorited);
        setAuthRole(props.authRole);
        setMyId(1);
        if (props.startupId) {
          setMyId(props.startupId);
        }
      }
    }
  }, [data, loading, props.match.params.slug]);

  // for , lastUserEdit profile
  let time = dataDraft?.getStartupDrafts[0]?.lastUserEdit;
  let editDate = new Date(Number(time));
  let lastEditDate = editDate.toLocaleDateString();

  let startupPathname = `/girisim/${props?.startup?.slug}`;
  React.useEffect(() => {
    if (window.location.pathname === startupPathname) {
      if (drafts[0]?.status === "Pending") {
        setFillRate2(true);
      }
      if (drafts[0]?.status !== "Pending") {
        setFillRate(true);
      }
    }
  }, [drafts[0]?.status]);

  let status = activeData?.getActiveInvestmentSprintDetail?.status;
  /*let status;
 React.useEffect(() => {
    if (statusData){
      status = statusData
    }
  },[])*/

  // for calculating non-decimal numbers. Current Investment Amount
  let myString =
    ((parseInt(activeData?.getActiveInvestmentSprintDetail.currentInvestmentAmount) +
      parseInt(activeData?.getActiveInvestmentSprintDetail.oldAdditionalCommitment)) /
      parseInt(activeData?.getActiveInvestmentSprintDetail.requiredInvestmentAmount)) *
    100;
  let dotPosition = myString.toFixed(0);
  let theBitBeforeTheDot = dotPosition; //myString.substring(0, dotPosition);

  // for calculating non-decimal numbers. Current Commitment Amount

  let myString2 =
    ((parseInt(activeData?.getActiveInvestmentSprintDetail.currentCommitmentAmount) +
      parseInt(activeData?.getActiveInvestmentSprintDetail.oldAdditionalCommitment)) /
      parseInt(activeData?.getActiveInvestmentSprintDetail.requiredInvestmentAmount)) *
    100;
  let dotPosition2 = myString2.toFixed(0);
  let theBitBeforeTheDot2 = dotPosition2; //myString2.substring(0, dotPosition2);
  /*(parseInt(
    activeData?.getActiveInvestmentSprintDetail.currentCommitmentAmount,
  ) /
    parseInt(
      activeData?.getActiveInvestmentSprintDetail.requiredInvestmentAmount,
    )) *
  100*/

  const handleClose = () => {
    setOpenInvest(false);
  };

  const handleClickOpen = () => {
    setOpenInvest(true);
  };
  const handleNotifyChecked = event => {
    setIsNotify(event.target.checked);
    props
      .handleStartupInvNotify({variables: {startupId: detail.id, currentStatus: !event.target.checked}})
      .then(data => {
        if (data.data.handleStartupInvNotify) {
          setNotifySent(true);
          if (isNotify) {
            setNotifyText("Haberdar olma talebiniz başarıyla geri alınmıştır.");
          } else {
            setNotifyText("Haberdar olma talebiniz başarıyla alınmıştır.");
          }
        } else {
          setNotifyError(true);
        }
      })
      .then(() => {})
      .catch(error => {
        setNotifyError(true);
      });
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
        })}
      >
        {authRole && (
          <Grid container style={{background: "#f7f7fc", justifyContent: "center", minHeight: "100vh"}}>
            <Grid className={"main-container"} xs={12} style={{marginTop: 140, height: "fit-content", maxWidth: 1320}}>
              <DraftDialog
                open={open}
                history={props.history}
                startupNewDraft={props.startupNewDraft}
                startupDraftSetEdit={props.startupDraftSetEdit}
                onClose={() => setOpen(false)}
              />
              <ReportDialog
                createStartupFollowRequest={props.createStartupFollowRequest}
                startupId={detail.id}
                setReportError={setReportError}
                setReportSent={setReportSent}
                open={report}
                onClose={() => setReport(false)}
              />
              <RemoveFavDialog
                setNotFavorited={setNotFavorited}
                removeFavorite={props.removeFavorite}
                id={detail.id}
                open={remove}
                slug={slug}
                startupId={props.startupId}
                paramSlug={props.match.params.slug}
                STARTUP_DETAIL={STARTUP_DETAIL}
                onClose={() => setRemove(false)}
                setFav={setFav}
                setFavAlert={setFavAlert}
              />
              <Formik
                initialValues={initialValuess}
                validationSchema={detailSchema}
                enableReinitialize={true}
                onSubmit={values => {
                  handleSubmit(values);
                }}
              >
                {props => (
                  <Form style={{width: "100%"}}>
                    <Grid item style={{width: "100%"}}>
                      {myId && (
                        <>
                          {isAdmin && (
                            <Grid container justify="space-between" alignItems="center" style={{padding: "0 10px"}}>
                              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <img src={newBag} style={{width: 16}} alt="bag" />
                                <Link to="/girisimler" style={{padding: 0}}>
                                  <Typography variant="h5" style={{color: "#718096"}}>
                                    Girişimleri Keşfet
                                  </Typography>
                                </Link>
                                <Typography variant="h5">Şirket Detayı</Typography>
                              </Breadcrumbs>
                              <Grid
                                item
                                justify="center"
                                alignItems="center"
                                style={{padding: "0 10px", marginTop: "10px"}}
                              >
                                <Link to={`/admin/yatirimci-arayanlar/${detail.id}`} style={{padding: 0}}>
                                  <Button
                                    variant="containedPrimary"
                                    className={classes.editButton}
                                    onClick={() => setEdit(true)}
                                  >
                                    Turu Kontrol Et
                                  </Button>
                                </Link>
                                <Link to={`/admin/yatirim-turu/${detail.id}`} style={{padding: 0}}>
                                  <Button
                                    variant="containedPrimary"
                                    className={classes.editButton}
                                    onClick={() => setEdit(true)}
                                  >
                                    Teklifleri Kontrol Et
                                  </Button>
                                </Link>
                                {!edit ? (
                                  <Button
                                    variant="containedPrimary"
                                    className={classes.editButton}
                                    style={{marginRight: 0}}
                                    onClick={() => setEdit(true)}
                                  >
                                    Düzenle
                                  </Button>
                                ) : (
                                  <>
                                    {submitting ? (
                                      <Button
                                        variant="containedPrimary"
                                        className={classes.editButton}
                                        disabled
                                        style={{fontWeight: "normal"}}
                                      >
                                        <CircularProgress
                                          style={{
                                            color: "#ffffff",
                                            width: "25px",
                                            height: "25px",
                                            marginRight: "10px",
                                            marginBottom: 0
                                          }}
                                        />
                                        Lütfen Bekleyiniz..
                                      </Button>
                                    ) : (
                                      <Grid container justify="flex-end" className={classes.bottomBar}>
                                        <Button
                                          variant="containedPrimary"
                                          className={classes.editButton}
                                          style={{fontWeight: "normal", padding: "13px 24px"}}
                                          disabled={!props.isValid}
                                          onClick={() => handleSubmit(props.values)}
                                        >
                                          Kaydet
                                        </Button>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}

                          <Grid container justify="space-between" alignItems="center" style={{padding: "0 10px"}}>
                            {authRole !== "Admin" && authRole !== "SuperAdmin" && (
                              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <img src={newBag} style={{width: 16}} alt="bag" />
                                <Link to="/girisimler" style={{padding: 0}}>
                                  <Typography variant="h4" style={{color: "#718096"}}>
                                    Girişimleri Keşfet
                                  </Typography>
                                </Link>
                                <Typography variant="h4">Şirket Detayı</Typography>
                              </Breadcrumbs>
                            )}
                            {myId === detail.id && !isAdmin && (
                              <Button
                                variant="containedPrimary"
                                className={classes.editButton}
                                style={{marginRight: 0}}
                                onClick={() => setOpen(true)}
                              >
                                Düzenle
                              </Button>
                            )}
                          </Grid>
                        </>
                      )}
                      {!edit ? (
                        <Grid className={classes.cardGrid} container spacing={4} xs={12}>
                          <Grid item className={classes.whiteGrid} xs={12} md={6}>
                            <Grid item container direction="column" justify="end" xs={12} className={classes.whiteCard}>
                              <Grid xs={12} container item justify="end">
                                {myId === detail.id ? (
                                  dataDraft?.getStartupDrafts[0] ? (
                                    <Typography className={classes.lastEditDate} variant="body2" align="left">
                                      Son düzenleme tarihi: {lastEditDate}
                                    </Typography>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}

                                <Grid item>
                                  {detail.logo ? (
                                    <img src={detail.logo} className={classes.img} alt="logo"></img>
                                  ) : (
                                    <Grid className={classes.imgEmpty}>
                                      <Typography variant="h2" style={{color: "#fff"}}>
                                        {detail.title && detail.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item className={classes.titleBar2} xs>
                                  <Grid container alignItems="flex-end" style={{marginBottom: 5}}>
                                    <Typography variant="h5">{detail.title}</Typography>
                                  </Grid>
                                  {detail.oneliner ? (
                                    <MaxLineTypography variant="body2" maxLine={3}>
                                      <Typography className={classes.oneliner} variant="body2" align="left">
                                        {detail.oneliner}
                                      </Typography>
                                    </MaxLineTypography>
                                  ) : (
                                    <Typography className={classes.oneliner} variant="body2" align="left">
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                      Ipsum has been the industry’s standard dummy text ever since the.{" "}
                                    </Typography>
                                  )}
                                  <Grid container alignItems="flex-end" style={{marginTop: 10}}>
                                    <Grid container spacing={1}>
                                      {detail.businessModelArray &&
                                        detail.businessModelArray.length > 0 &&
                                        detail.businessModelArray.map(model => (
                                          <Grid item>
                                            <GreenTag title={model} />
                                          </Grid>
                                        ))}
                                      {detail.country && detail.country.title && (
                                        <Grid item>
                                          <GreenTag title={detail.country.title} />
                                        </Grid>
                                      )}
                                      <Grid
                                        className={classes.socialMargin}
                                        item
                                        style={{display: "flex", alignItems: "center"}}
                                      >
                                        {detail.socialInstagram && (
                                          <a href={detail.socialInstagram} style={{padding: 0}} target="_blank">
                                            <img src={insta} alt={insta} style={{width: 16}} />
                                          </a>
                                        )}
                                        {detail.socialTwitter && (
                                          <a
                                            href={detail.socialTwitter}
                                            style={{padding: 0, marginLeft: 20}}
                                            target="_blank"
                                          >
                                            <img src={twitter} alt={twitter} style={{width: 18}} />
                                          </a>
                                        )}
                                        {detail.socialLinkedin && (
                                          <a
                                            href={detail.socialLinkedin}
                                            style={{padding: 0, marginLeft: 20}}
                                            target="_blank"
                                          >
                                            <img src={linkedin} alt={linkedin} style={{width: 16}} />
                                          </a>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {myId !== detail.id ? null : (
                                    <Grid container xs={12} style={{marginTop: 5}}>
                                      {detail.profileFillRate > -1 && (
                                        <Grid container xs={12}>
                                          <Grid container>
                                            <Typography style={{color: "#172b4d", fontWeight: "normal"}} variant="h6">
                                              Profil Doluluk Oranı:
                                            </Typography>
                                            <Typography
                                              style={{color: "#172b4d", marginLeft: 8, marginRight: 8}}
                                              variant="h6"
                                              id="disabled-slider"
                                            >
                                              %{detail.profileFillRate}
                                            </Typography>

                                            {dataDraft?.getStartupDrafts[0]?.data?.profileFillRate &&
                                              (dataDraft?.getStartupDrafts[0]?.status === "Pending" ? (
                                                <>
                                                  <Typography
                                                    className={classes.pendingFillRate}
                                                    style={{marginRight: 8}}
                                                    variant="h6"
                                                  >
                                                    Onay Bekleyen:
                                                  </Typography>
                                                  <Typography
                                                    className={classes.pendingFillRate}
                                                    //style={{ color: '#172b4d', marginLeft: 8 }}
                                                    variant="h6"
                                                  >
                                                    %{dataDraft?.getStartupDrafts[0]?.data?.profileFillRate}
                                                  </Typography>
                                                </>
                                              ) : (
                                                <></>
                                              ))}
                                          </Grid>
                                          <Slider
                                            style={{paddingTop: 5}}
                                            disabled
                                            defaultValue={detail.profileFillRate}
                                            value={detail.profileFillRate}
                                            aria-labelledby="disabled-slider"
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  )}
                                  <Grid className={classes.gridButton}>
                                    {userId !== data.startupDetail.users[0].id && (
                                      <CreateConversation
                                        className={classes.cardButtons}
                                        userId={userId}
                                        receivedUserId={data.startupDetail.users[0].id}
                                      />
                                    )}
                                    <Button
                                      onClick={() => setReport(true)}
                                      // className={classes.shareBuySellButtons}
                                      // style={{color: "#003CA5"}}
                                      className={classes.cardButtons2}
                                      variant="outlinedPrimary"
                                    >
                                      <Grid container alignItems="center" justify="center">
                                        {/*<img src={RaporSvgIcon} style={{fontSize: 12, marginRight: 8}} />*/}
                                        Rapor Talep Et
                                      </Grid>
                                    </Button>
                                  </Grid>
                                </Grid>
                                {myId !== detail.id && (
                                  <>
                                    {/* {!fav && <Typography style={{ marginRight: 8 }}>Favoriye Ekle</Typography>} */}
                                    {fav ? (
                                      <StarIcon
                                        onClick={() => setRemove(true)}
                                        style={{color: "#F9AB00", cursor: "pointer", fontSize: 32}}
                                        className={classes.favIcon}
                                      />
                                    ) : (
                                      <StarIcon
                                        onClick={() => addFavorite(detail.id)}
                                        className={classes.favIcon}
                                        style={{color: "#dddddd", cursor: "pointer", fontSize: 32}}
                                      />
                                    )}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item className={classes.whiteGrid} xs={12} md={6}>
                            {myId !== detail.id ? (
                              <>
                                {activeData?.getActiveInvestmentSprintDetail?.status === "Active" ? (
                                  <div style={{marginTop: isFeaturedSprint && -32}}>
                                    {isFeaturedSprint && <IsFeaturedBanner />}
                                    <Grid container className={classes.whiteCard} style={{height: 260}}>
                                      <Grid container alignItems="center" style={{marginTop: isFeaturedSprint && 32}}>
                                        <Grid item>
                                          <Grid className={classes.imgEmptyInvest} style={{height: 180}}>
                                            <svg
                                              width="98"
                                              height="54"
                                              viewBox="0 0 98 54"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M24.6582 33.1419C21.3864 20.9636 27.6574 8.23992 39.351 3.4231C51.0447 -1.39371 64.4651 3.15045 70.7967 14.0867"
                                                stroke="#003CA5"
                                                stroke-width="3.02944"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M70.7969 14.0867L78.0675 9.90611L82.0058 16.6921L95.9413 8.69434"
                                                stroke="#003CA5"
                                                stroke-width="3.02944"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M86.3379 6.11914L95.9412 8.69417L93.3662 18.2672"
                                                stroke="#003CA5"
                                                stroke-width="3.02944"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M27.1727 39.201L19.902 43.3817L15.9637 36.5957L2.05859 44.5934"
                                                stroke="#003CA5"
                                                stroke-width="3.02944"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M73.3113 20.1455C76.5831 32.3239 70.3121 45.0475 58.6185 49.8643C46.9248 54.6812 33.5044 50.137 27.1729 39.2007"
                                                stroke="#003CA5"
                                                stroke-width="3.02944"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                opacity="0.5"
                                                d="M48.9848 42.6743C57.8297 42.6743 65 35.5041 65 26.6592C65 17.8143 57.8297 10.644 48.9848 10.644C40.1399 10.644 32.9697 17.8143 32.9697 26.6592C32.9697 35.5041 40.1399 42.6743 48.9848 42.6743Z"
                                                fill="#003CA5"
                                              />
                                              <path
                                                opacity="0.5"
                                                d="M48.9845 33.9339C53.0025 33.9339 56.2596 30.6768 56.2596 26.6589C56.2596 22.6409 53.0025 19.3838 48.9845 19.3838C44.9666 19.3838 41.7095 22.6409 41.7095 26.6589C41.7095 30.6768 44.9666 33.9339 48.9845 33.9339Z"
                                                fill="#003CA5"
                                              />
                                            </svg>
                                          </Grid>
                                        </Grid>
                                        <Grid xs style={{marginLeft: 24}}>
                                          <Typography variant="h4">{t("İletişime Geç")}</Typography>
                                          <Typography
                                            className={classes.onelinerInvestBox}
                                            variant="body2"
                                            style={{marginTop: 10}}
                                          >
                                            {t(
                                              "Girişimle iletişime geçerek yatırım turu hakkında detaylı bilgi öğrenebilirsiniz."
                                            )}
                                          </Typography>
                                          <Grid item style={{marginTop: 25}}>
                                            {getInvestorProfileStatus ? (
                                              <Button onClick={handleClickOpen} variant="containedPrimary">
                                                İlgileniyorum
                                              </Button>
                                            ) : (
                                              <Link to="/yatirimci-arayanlar">
                                                <Button variant="containedPrimary">İlgileniyorum</Button>
                                              </Link>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ) : (
                                  /*<Grid container className={classes.whiteCard} alignContent="center">
                                    <Grid
                                      container
                                      item
                                      alignItems="center"
                                      alignContent="space-eveny"
                                      justify="center"
                                      style={{maxWidth: "400px", height: "120px"}}
                                    >
                                      <Grid
                                        item
                                        container
                                        className={classes.twoButtonContainer}
                                        alignItems="center"
                                        justify="space-evenly"
                                        wrap="nowrap"
                                      >
                                        <Grid item>
                                          <Button
                                            onClick={() => setSecondarySharePopupType("Buy")}
                                            variant="containedPrimary"
                                            className={classes.shareBuySellButtons}
                                          >
                                            <Grid container alignItems="center" justify="center">
                                              <AddCircleIcon style={{fontSize: 12, marginRight: 8}} />
                                              Hisse Al
                                            </Grid>
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <Button
                                            onClick={() => setSecondarySharePopupType("Sell")}
                                            variant="containedPrimary"
                                            className={classes.shareBuySellButtons}
                                          >
                                            <Grid container alignItems="center" justify="center">
                                              <RemoveCircleIcon style={{fontSize: 12, marginRight: 8}} />
                                              Hisse Sat
                                            </Grid>
                                          </Button>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        className={classes.twoButtonContainer}
                                        alignItems="center"
                                        justify="space-evenly"
                                        wrap="nowrap"
                                      >
                                        <Grid item>
                                          <Button
                                            onClick={() => setReport(true)}
                                            className={classes.shareBuySellButtons}
                                            style={{color: "#003CA5"}}
                                            variant="outlinedSecondary"
                                          >
                                            <Grid container alignItems="center" justify="center">
                                              <img src={RaporSvgIcon} style={{fontSize: 12, marginRight: 8}} />
                                              Rapor Talep Et
                                            </Grid>
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          {!isNotify ? (
                                            <Button
                                              onClick={handleNotify}
                                              className={classes.shareBuySellButtons}
                                              style={{color: "#003CA5"}}
                                              variant="outlinedSecondary"
                                            >
                                              <Grid container alignItems="center" justify="center">
                                                <NotificationsIcon style={{fontSize: 12, marginRight: 8}} />
                                                Haberdar Ol
                                              </Grid>
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={handleNotify}
                                              className={classes.shareBuySellButtons}
                                              variant="containedPrimary"
                                            >
                                              <Grid container alignItems="center" justify="center">
                                                <NotificationsOffIcon style={{fontSize: 12, marginRight: 8}} />
                                                İptal Et
                                              </Grid>
                                            </Button>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid> */
                                  <>
                                    <Grid container className={classes.whiteCard}>
                                      <Grid item>
                                        <img src={newNotify} className={classes.notifyImg} alt="notify" />
                                      </Grid>
                                      <Grid xs style={{marginLeft: 24}}>
                                        <Typography variant="h4">{t("Haberdar ol")}</Typography>
                                        <Typography variant="body2" style={{marginTop: 10}}>
                                          {detail.title}
                                          {t(" yatırım turuna çıktığında haberdar olmak ister misiniz? ")}
                                        </Typography>
                                        <Grid item style={{marginTop: 25}}>
                                          <AntSwitch
                                            checked={isNotify}
                                            onChange={handleNotifyChecked}
                                            name="isNotify"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                              </>
                            ) : (
                              <Grid container className={classes.whiteCard}>
                                <Grid item>
                                  <img src={newNotify} className={classes.notifyImg} alt="notify" />
                                </Grid>
                                <Grid xs={12} md={8} style={{marginLeft: 24}}>
                                  <Slider2 {...settings} style={{textAlign: "left"}}>
                                    <Grid xs={12} item>
                                      <Typography variant="h4">{t("Girişimcilere Öneriler")}</Typography>
                                      <Typography variant="body2" style={{marginTop: 10}}>
                                        {t(
                                          "Metrikleriniz, ürününüz, ekibiniz ve geleceğe yönelik planlarınız hakkında tutarlı ve samimi olmaya çalışın."
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={12} item>
                                      <Typography variant="h4">
                                        {t("Önceliğiniz para değil akıllı para olsun")}
                                      </Typography>
                                      <Typography variant="body2" style={{marginTop: 10}}>
                                        {t(
                                          "Öncelikle yatırımcı adayını bir takım arkadaşı ve danışman olarak görmeye çalışın.Amacınız; yatırımcınız ile sağlam temellere dayalı bir ilişki kurmak olsun."
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={12} item>
                                      <Typography variant="h4">{t("Veriler ve rakamlar ile konuşun")}</Typography>
                                      <Typography variant="body2" style={{marginTop: 10}}>
                                        {t(
                                          "Yatırımcılar rakamları sever.Siz de rakamlara önem verin.Finansal durumunuz, geleceğe dair planlarınız, gelir, gider, karlılık gibi konularda net olun."
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Slider2>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid className={classes.cardGrid} container spacing={3} xs={12}>
                          <Grid item className={classes.whiteGrid} xs={12}>
                            <Grid
                              item
                              container
                              direction="column"
                              justify="end"
                              xs={12}
                              style={{minHeight: 160}}
                              className={classes.whiteCard}
                            >
                              <Grid xs={12} container item justify="end">
                                <Grid item>
                                  {detail.logo ? (
                                    <img src={detail.logo} className={classes.img} alt="logo"></img>
                                  ) : (
                                    <Grid className={classes.imgEmpty}>
                                      <Typography variant="h2" style={{color: "#fff"}}>
                                        {detail.title && detail.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item className={classes.titleBar} xs={12} md>
                                  <Grid container alignItems="center" justify="space-between">
                                    <Grid item className={classes.titleFlex}>
                                      <Typography variant="h5">{detail.title}</Typography>
                                      <Typography
                                        className={classes.model}
                                        variant="body1"
                                        align="left"
                                        style={{display: "flex"}}
                                      >
                                        {detail.businessModelArray &&
                                          detail.businessModelArray.length > 0 &&
                                          detail.businessModelArray.map(model => (
                                            <Typography variant="body1" style={{marginRight: 10}}>
                                              {model}
                                            </Typography>
                                          ))}
                                      </Typography>
                                      <a
                                        href={detail.website}
                                        style={{padding: 0}}
                                        className={classes.socialLink}
                                        target="_blank"
                                      >
                                        <Typography variant="body1" color="primary" align="left">
                                          {detail.website}
                                        </Typography>
                                      </a>
                                    </Grid>
                                    <Grid container xs={12} md={3} justify="flex-end">
                                      {detail.profileFillRate > -1 && (
                                        <Grid container xs={12}>
                                          <Grid container justify="space-between">
                                            <Typography style={{color: "#172b4d", fontWeight: "normal"}} variant="h6">
                                              Profil Doluluk Oranı
                                            </Typography>
                                            <Typography style={{color: "#172b4d"}} variant="h6" id="disabled-slider">
                                              %{detail.profileFillRate}
                                            </Typography>
                                          </Grid>
                                          <Slider
                                            classname={classes.sliderDarkBlue}
                                            disabled
                                            defaultValue={detail.profileFillRate}
                                            aria-labelledby="disabled-slider"
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {detail.oneliner ? (
                                    <MaxLineTypography variant="body2" maxLine={3}>
                                      <Typography className={classes.oneliner} variant="body2" align="left">
                                        {detail.oneliner}
                                      </Typography>
                                    </MaxLineTypography>
                                  ) : (
                                    <Typography className={classes.oneliner} variant="body2" align="left">
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                      Ipsum has been the industry’s standard dummy text ever since the.{" "}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <AppBar className={classes.tabPaper} position="static" color="default">
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          className={classes.TabsGeneral}
                        >
                          <Tab label="Genel Bilgiler" {...a11yProps(0)} style={{fontWeight: 600}} />
                          <Tab label="Ekip" {...a11yProps(1)} style={{fontWeight: 600}} />
                          <Tab label="Ürün & Pazar" {...a11yProps(2)} style={{fontWeight: 600}} />
                          <Tab label="Finans" {...a11yProps(3)} style={{fontWeight: 600}} />
                          <Tab label="Raporlar" {...a11yProps(4)} style={{fontWeight: 600}} />
                          <Tab label="Duyurular" {...a11yProps(5)} style={{fontWeight: 600}} />
                          {status === "Active" ? (
                            <Tab label="Tur Bilgileri" {...a11yProps(6)} style={{fontWeight: 600}} />
                          ) : (
                            <></>
                          )}
                        </Tabs>
                      </AppBar>
                      <TabPanel value={value} index={0}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <GeneralTab {...props} detail={detail} edit={edit} />
                          {detail.id && !edit && (
                            <>
                              <CommentTab setIsComment={setIsComment} id={detail.id} />
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <DetailTab {...props} detail={detail} edit={edit} />
                          {detail.id && !edit && (
                            <>
                              <CommentTab setIsComment={setIsComment} id={detail.id} />
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <ProductTab
                            {...props}
                            detail={detail}
                            edit={edit}
                            valuation={valuation}
                            investmentAmount={investmentAmount}
                            date={date}
                            volume={volume}
                            numberOfTransactions={numberOfTransactions}
                            sumTraction={sumTraction}
                            dateTraction={dateTraction}
                          />
                          {detail.id && !edit && (
                            <>
                              <CommentTab setIsComment={setIsComment} id={detail.id} />
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <FinanceTab
                            {...props}
                            detail={detail}
                            edit={edit}
                            valuation={valuation}
                            investmentAmount={investmentAmount}
                            date={date}
                            volume={volume}
                            numberOfTransactions={numberOfTransactions}
                            sumTraction={sumTraction}
                            dateTraction={dateTraction}
                          />
                          {detail.id && !edit && (
                            <>
                              <CommentTab setIsComment={setIsComment} id={detail.id} />
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <Paper className={classes.paper2}>
                            {detail.publicReports && detail.publicReports.length > 0 ? (
                              <>
                                <TableContainer style={{boxShadow: "none"}} component={Paper}>
                                  <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell>
                                          <Typography variant="h6" style={{color: "#8492A6", fontSize: 10}}>
                                            {" "}
                                            Rapor Adı{" "}
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          <Typography variant="h6" style={{color: "#8492A6", fontSize: 10}}>
                                            {" "}
                                            Yayın Tarihi{" "}
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          <Typography variant="h6" style={{color: "#8492A6", fontSize: 10}}>
                                            {" "}
                                            Rapor Açıklaması{" "}
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left"></StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {detail.publicReports.map(report => (
                                        <StyledTableRow style={{background: "#fff"}} key={report.title}>
                                          <StyledTableCell style={{border: "none"}} align="left">
                                            <Typography variant="h6" style={{fontSize: 12}}>
                                              {report.title}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell style={{border: "none"}} align="left">
                                            <Typography variant="h6" style={{fontSize: 14}}>
                                              {moment(parseInt(report.sentDate)).format("Do-MM-YYYY")}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell style={{border: "none", maxWidth: 500}} align="left">
                                            <Typography variant="h6" style={{fontSize: 12}}>
                                              {report.sendingText}
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell style={{border: "none"}} align="left">
                                            {myId && myId === detail.id ? (
                                              <Link
                                                style={{padding: 0, display: "flex"}}
                                                to={`/rapor-goruntule/${report.id}`}
                                              >
                                                <Typography
                                                  style={{cursor: "pointer", color: "#118DFF", fontSize: 12}}
                                                  variant="h6"
                                                >
                                                  Raporu Görüntüle
                                                </Typography>
                                                <img src={Right} alt="Right" style={{width: 15, marginLeft: 10}} />
                                              </Link>
                                            ) : (
                                              <Link
                                                style={{padding: 0, display: "flex"}}
                                                to={`/rapor-icerigi/${report.id}`}
                                              >
                                                <Typography
                                                  style={{cursor: "pointer", color: "#118DFF", fontSize: 12}}
                                                  variant="h6"
                                                >
                                                  Raporu Görüntüle
                                                </Typography>
                                                <img src={Right} alt="Right" style={{width: 15, marginLeft: 10}} />
                                              </Link>
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            ) : myId && myId === detail.id ? (
                              <Grid xs={12} container direction="column" alignItems="center">
                                <Typography style={{width: "100%", marginBottom: "30px"}} align="center" variant="h4">
                                  {t("Henüz rapor oluşturmadınız.")}
                                </Typography>
                                <Grid xs={12} md={6}>
                                  <Typography style={{width: "100%", color: "#777777"}} align="center" variant="body2">
                                    {t(
                                      "Rapor oluşturarak girişiminizin gelişimini belirlediğiniz kişilerle paylaşabilirsiniz."
                                    )}
                                  </Typography>
                                </Grid>
                                <img src={reportImg} alt="report" style={{width: 300, objectFit: "contain"}} />
                                <Link to={`/raporlarim`} style={{padding: 0}}>
                                  <Button className={classes.button} variant="containedPrimary">
                                    {t("Rapor Oluştur")}
                                  </Button>
                                </Link>
                              </Grid>
                            ) : (
                              <Grid xs={12} container direction="column" alignItems="center">
                                <Typography style={{width: "100%", marginBottom: 30}} align="center" variant="h4">
                                  {t("Girişimin herkesle paylaşılan bir raporu bulunmamaktadır.")}
                                </Typography>
                                <Grid xs={12} md={6}>
                                  <Typography style={{width: "100%", color: "#777777"}} align="center" variant="body2">
                                    {t(
                                      "Girişimden rapor talep ederek girişimle ilgili detaylı bilgileri edinebilirsiniz."
                                    )}
                                  </Typography>
                                </Grid>
                                <img src={reportImg} alt="report" style={{width: 300, objectFit: "contain"}} />
                                <Button
                                  className={classes.button}
                                  onClick={() => setReport(true)}
                                  variant="containedPrimary"
                                >
                                  {" "}
                                  {t("Rapor Talep Et")}
                                </Button>
                              </Grid>
                            )}
                          </Paper>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={5}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <AnnouncementTab {...props} detail={detail} edit={edit} />

                          {detail.id && !edit && (
                            <>
                              <CommentTab setIsComment={setIsComment} id={detail.id} />
                            </>
                          )}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} index={6}>
                        <Grid className={classes.tabGrid} item xs={12}>
                          <Paper className={classes.paper2}>
                            {ttt >= today ? (
                              <Grid container spacing={0}>
                                <Grid item style={{display: "flex", justifyContent: "center"}} xs={12}>
                                  <Typography
                                    classname={classes.closingText}
                                    variant="h5"
                                    style={{
                                      width: "fit-content",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      textAlign: "center",
                                      color: "#425466",
                                      marginBottom: "8px"
                                    }}
                                  >
                                    {t("Yatırım turu")}{" "}
                                    <span classname={classes.closingDate} style={{color: "#118DFF"}}>
                                      {activeData?.getActiveInvestmentSprintDetail?.sprintClosingDate}
                                    </span>{" "}
                                    {t("tarihinde kapanacaktır.")}
                                  </Typography>
                                </Grid>

                                <Grid item style={{display: "flex", justifyContent: "center"}} xs={12}>
                                  <Typography
                                    classname={classes.countDown}
                                    variant="h5"
                                    style={{
                                      width: "fit-content",
                                      color: "#718096",
                                      fontWeight: "normal",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      textAlign: "center",
                                      marginBottom: "18px"
                                    }}
                                  >
                                    {t("Geri sayım başladı")}
                                  </Typography>{" "}
                                </Grid>

                                <Grid item style={{display: "flex", justifyContent: "center"}} xs={12}>
                                  <Typography
                                    classname={classes.remainingTime}
                                    variant="h5"
                                    style={{
                                      width: "fit-content",
                                      color: "#DBDFE5",
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      lineHeight: "10px",
                                      textAlign: "center",
                                      textTransform: "uppercase",
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <span
                                      classname={classes.remainingTimeBlue}
                                      style={{
                                        color: "#118DFF",
                                        fontWeight: "600",
                                        fontSize: "28px",
                                        lineHeight: "12px",
                                        textAlign: "center",
                                        textTransform: "uppercase",
                                        margin: "0 4px 0 4px"
                                      }}
                                    >
                                      {days}
                                    </span>{" "}
                                    {t("Gün")}
                                    {":"}
                                    <span
                                      classname={classes.remainingTimeBlue}
                                      style={{
                                        color: "#118DFF",
                                        fontWeight: "600",
                                        fontSize: "28px",
                                        lineHeight: "12px",
                                        textAlign: "center",
                                        textTransform: "uppercase",
                                        margin: "0 4px 0 4px"
                                      }}
                                    >
                                      {hrs}
                                    </span>{" "}
                                    {t("Saat")}
                                    {":"}
                                    <span
                                      classname={classes.remainingTimeBlue}
                                      style={{
                                        color: "#118DFF",
                                        fontWeight: "600",
                                        fontSize: "28px",
                                        lineHeight: "12px",
                                        textAlign: "center",
                                        textTransform: "uppercase",
                                        margin: "0 4px 0 4px"
                                      }}
                                    >
                                      {min}
                                    </span>{" "}
                                    {t("Dk")}
                                    {":"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : (
                              <Typography>
                                <span
                                  classname={classes.remainingTimeBlue}
                                  style={{
                                    color: "#118DFF",
                                    fontWeight: "600",
                                    fontSize: "28px",
                                    lineHeight: "12px",
                                    textAlign: "center",
                                    //textTransform: 'uppercase',
                                    margin: "0 4px 0 4px"
                                  }}
                                >
                                  {t("Yatırım Turu Bilgileri")}
                                </span>{" "}
                              </Typography>
                            )}
                            <Grid classname={classes.splitLine} item xs={12} md={12}>
                              <hr
                                size="1"
                                style={{border: "2px solid #FAFBFD", marginTop: "24px", marginBottom: "20px"}}
                              />
                            </Grid>
                            <Grid container style={{marginBottom: 40}} spacing={3}>
                              <Grid item xs={12} md={9}>
                                <Grid container spacing={1}>
                                  <Grid direction="column" alignItems="center" justify="center" item xs={12} md={3}>
                                    <Grid className={classes.soughtInvest}>
                                      Aranan Yatırım Tutarı:{" "}
                                      {resolveCurrencySymbol(
                                        activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol
                                      )}
                                      {nFormatter(
                                        activeData?.getActiveInvestmentSprintDetail.requiredInvestmentAmount,
                                        1
                                      )}
                                    </Grid>
                                    <Grid className={classes.collectedInvest}>Toplanan Taahhüt Tutarı:</Grid>
                                  </Grid>
                                  <Grid direction="column" alignItems="center" justify="center" item xs={12} md={9}>
                                    <Grid className={classes.collectedInvest}>
                                      <div
                                        style={{
                                          background: "#FAFBFD !important",
                                          boxShadow:
                                            "0px 0px 1px rgba(12, 26, 75, 0.44), 0px 3px 8px -1px rgba(50, 50, 71, 0.15)",
                                          borderRadius: "80px",
                                          border: "5px solid white",
                                          width: "100%",
                                          zIndex: "20",
                                          height: "32px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start"
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginLeft: 0,
                                            height: 20,
                                            background: "#003CA5",
                                            borderRadius: 40,
                                            textAlign: "center",
                                            width: `${theBitBeforeTheDot}` + "%",
                                            zIndex: "21",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }}
                                        >
                                          {activeData?.getActiveInvestmentSprintDetail?.currentInvestmentAmount +
                                            activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment ===
                                            0 || theBitBeforeTheDot < 10 ? (
                                            <></>
                                          ) : (
                                            <>
                                              {" "}
                                              <span className={classes.amountText2}>
                                                {resolveCurrencySymbol(
                                                  activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol
                                                )}
                                                {nFormatter(
                                                  activeData?.getActiveInvestmentSprintDetail?.currentInvestmentAmount +
                                                    activeData?.getActiveInvestmentSprintDetail
                                                      ?.oldAdditionalCommitment,
                                                  1
                                                )}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                        {activeData?.getActiveInvestmentSprintDetail?.currentInvestmentAmount +
                                          activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment ===
                                          0 || theBitBeforeTheDot >= 10 ? (
                                          <></>
                                        ) : (
                                          <>
                                            <span className={classes.amountText3}>
                                              {resolveCurrencySymbol(
                                                activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol
                                              )}
                                              {nFormatter(
                                                activeData?.getActiveInvestmentSprintDetail?.currentInvestmentAmount +
                                                  activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment,
                                                1
                                              )}
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid classname={classes.splitLine} item xs={12} md={12}>
                                  <hr
                                    size="1"
                                    style={{border: "2px solid #FAFBFD", marginTop: "24px", marginBottom: "20px"}}
                                  />
                                </Grid>

                                <Grid container spacing={1}>
                                  <Grid direction="column" alignItems="center" justify="center" item xs={12} md={3}>
                                    <Grid className={classes.collectedInvest}>Toplam Talep Tutarı:</Grid>
                                  </Grid>
                                  <Grid direction="column" alignItems="center" justify="center" item xs={12} md={9}>
                                    <Grid
                                      direction="column"
                                      alignItems="center"
                                      justify="center"
                                      className={classes.collectedInvest}
                                    >
                                      <div
                                        style={{
                                          background: "#FAFBFD !important",
                                          boxShadow:
                                            "0px 0px 1px rgba(12, 26, 75, 0.44), 0px 3px 8px -1px rgba(50, 50, 71, 0.15)",
                                          borderRadius: "80px",
                                          border: "5px solid white",
                                          width: "100%",
                                          zIndex: "20",
                                          height: "32px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start"
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginLeft: 0,
                                            height: 20,
                                            background: "#118DFF",
                                            borderRadius: 40,
                                            textAlign: "center",
                                            width: `${theBitBeforeTheDot2}` + "%",
                                            zIndex: "21",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }}
                                        >
                                          {activeData?.getActiveInvestmentSprintDetail?.currentCommitmentAmount +
                                            activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment ===
                                            0 || theBitBeforeTheDot2 < 10 ? (
                                            <></>
                                          ) : (
                                            <>
                                              <span className={classes.amountText2}>
                                                {resolveCurrencySymbol(
                                                  activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol
                                                )}
                                                {nFormatter(
                                                  activeData?.getActiveInvestmentSprintDetail?.currentCommitmentAmount +
                                                    activeData?.getActiveInvestmentSprintDetail
                                                      ?.oldAdditionalCommitment,
                                                  1
                                                )}
                                              </span>
                                            </>
                                          )}
                                        </div>

                                        {activeData?.getActiveInvestmentSprintDetail.currentCommitmentAmount +
                                          activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment ===
                                          0 || theBitBeforeTheDot2 >= 10 ? (
                                          <></>
                                        ) : (
                                          <>
                                            <span className={classes.amountText3}>
                                              {resolveCurrencySymbol(
                                                activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol
                                              )}
                                              {nFormatter(
                                                activeData?.getActiveInvestmentSprintDetail?.currentCommitmentAmount +
                                                  activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment,
                                                1
                                              )}
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item className={classes.ticketBox} xs={12} md={2}>
                                <Grid className={classes.ticketBoxİnline} container spacing={1}>
                                  <Grid>
                                    <Grid className={classes.ticketText}>Minimum Yatırım Tutarı</Grid>
                                    <Grid className={classes.amountTicket}>
                                      {resolveCurrencySymbol(
                                        activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol
                                      )}
                                      {nFormatter(activeData?.getActiveInvestmentSprintDetail.minTicketSize, 1)}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/*activeData?.getActiveInvestmentSprintDetail?.mainContractDocs[0]?.file && (
                              <>
                                <Typography
                                  variant="h5"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#425466',
                                    width: '100%',
                                    marginTop: 30,
                                    marginBottom: 30,
                                    textAlign: 'center',
                                  }}
                                >
                                  {t('Dokümanlar')}
                                </Typography>
                                <Grid container spacing={3}>
                                  <UploadedDocCard
                                    docFile={activeData?.getActiveInvestmentSprintDetail?.mainContractDocs[0]?.file}
                                    docFileTitle={activeData?.getActiveInvestmentSprintDetail?.mainContractDocs[0]?.title}
                                    docFileExtension={activeData?.getActiveInvestmentSprintDetail?.mainContractDocs[0]?.extension}
                                    docFileDate={activeData?.getActiveInvestmentSprintDetail?.mainContractDocs[0].date}
                                    docFileSize={activeData?.getActiveInvestmentSprintDetail?.mainContractDocs[0]?.fileSize}
                                  >
                                  </UploadedDocCard>
                                  <UploadedDocCard
                                    docFile={activeData?.getActiveInvestmentSprintDetail?.registerNewspaperDocs[0]?.file}
                                    docFileTitle={activeData?.getActiveInvestmentSprintDetail?.registerNewspaperDocs[0]?.title}
                                    docFileExtension={activeData?.getActiveInvestmentSprintDetail?.registerNewspaperDocs[0]?.extension}
                                    docFileDate={activeData?.getActiveInvestmentSprintDetail?.registerNewspaperDocs[0]?.date}
                                    docFileSize={activeData?.getActiveInvestmentSprintDetail?.registerNewspaperDocs[0]?.fileSize}
                                  >
                                  </UploadedDocCard>

                                </Grid>
                              </>
                            )*/}
                            {/* <Grid>
                            
                            <Typography variant="h6" style={{ width: 'fit-content', marginBottom: 30 }}>
                              Yüklediğim Dokümanlar
                            </Typography>
                            <Grid container spacing={3} style={{ marginBottom: 50 }}>
                              {props.detail.documents.map((document) => (
                                <>
                                  {document.file && (
                                    <Grid item xs={12} className={classes.objectCard}>
                                      <Grid container alignItems="center">
                                        <a href={document.file} download style={{ display: 'flex', padding: 0 }}>
                                          <img src={DashboardIcon} alt="DashboardIcon" style={{ marginRight: 10 }} />
                                          <Typography variant="h6" align="left" style={{ color: '#003CA5' }}>
                                            {document.title}
                                          </Typography>
                                        </a>
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              ))}
                            </Grid>
                         
                            </Grid> */}
                            {/* <Grid container spacing={1}>
                              <Grid direction="column" alignItems="center" justify="center" item xs={12} md={3}>
                                <Grid className={classes.soughtInvest}>Aranan Yatırım Miktarı: {'1M'}</Grid>
                                <Grid className={classes.collectedInvest}>Toplanan Yatırım Miktarı</Grid>
                              </Grid>
                              <Grid direction="column" alignItems="center" justify="center" item xs={12} md={6}>
                                <Grid className={classes.collectedInvest}>
                                  <SliderDarkBlue
                                    disabled
                                    valueLabelDisplay="auto"
                                    aria-label="dark blue slider"
                                    defaultValue={35}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid classname={classes.splitLine} item xs={12} md={9}>
                              <hr
                                size="1"
                                style={{ border: '2px solid #FAFBFD', marginTop: '24px', marginBottom: '20px' }}
                              />
                            </Grid>

                            <Grid container spacing={1}>
                              <Grid direction="column" alignItems="center" justify="center" item xs={12} md={3}>
                                <Grid className={classes.collectedInvest}>Commitment Miktarı</Grid>
                              </Grid>
                              <Grid direction="column" alignItems="center" justify="center" item xs={12} md={6}>
                                <Grid
                                  direction="column"
                                  alignItems="center"
                                  justify="center"
                                  className={classes.collectedInvest}
                                >
                                  <SliderLightBlue
                                    disabled
                                    valueLabelDisplay="auto"
                                    aria-label="dark blue slider"
                                    defaultValue={55}
                                  />
                                </Grid>
                              </Grid>
                            </Grid> */}
                          </Paper>
                        </Grid>
                      </TabPanel>
                    </Grid>
                    {(authRole === "Admin" || authRole === "SuperAdmin") && (
                      <Grid container justify="flex-end" alignItems="center" style={{marginBottom: 80}}>
                        {edit && (
                          <>
                            {value > 0 && (
                              <Button
                                variant="outlinedSecondary"
                                className={classes.editButton}
                                style={{fontWeight: "normal", fontSize: 14, display: "flex", alignItems: "center"}}
                                onClick={() => (setValue(value - 1), window.scrollTo(0, 0))}
                              >
                                <img src={left} alt="left" style={{marginRight: 10, width: 14}}></img>
                                {value === 1 && "Genel Bilgiler"}
                                {value === 2 && "Ekip"}
                                {value === 3 && "Ürün & Pazar"}
                                {value === 4 && "Finans"}
                                {value === 5 && "Raporlar"}
                              </Button>
                            )}
                            {value < 5 && (
                              <Button
                                variant="containedPrimary"
                                className={classes.editButton}
                                style={{fontWeight: "normal", fontSize: 14}}
                                onClick={() => (
                                  setValue(value + 1), window.scrollTo(0, 0), handleSubmit2(props.values)
                                )}
                              >
                                {value === 0 && "Ekip"}
                                {value === 1 && "Ürün & Pazar"}
                                {value === 2 && "Finans"}
                                {value === 3 && "Raporlar"}
                                {value === 4 && "Duyurular"}
                                {value === 5 && "Yatırım Bilgileri"}
                              </Button>
                            )}
                          </>
                        )}
                      </Grid>
                    )}
                  </Form>
                )}
              </Formik>
            </Grid>

            <MakeSecondaryShareRequestPopup
              startupId={detail.id}
              openWithType={secondarySharePopupType}
              setOpenWithType={setSecondarySharePopupType}
            />
            <ToInvestPopup
              openInvest={openInvest}
              setOpenInvest={setOpenInvest}
              logo={detail?.logo}
              name={detail?.title}
              industry={detail?.industry}
              businessModelArray={detail?.businessModelArray}
              country={detail?.country}
              amountOfInvestmentSought={activeData?.getActiveInvestmentSprintDetail?.requiredInvestmentAmount}
              currentInvestmentAmount={
                activeData?.getActiveInvestmentSprintDetail?.currentInvestmentAmount +
                activeData?.getActiveInvestmentSprintDetail?.oldAdditionalCommitment
              }
              currencySymbol={activeData?.getActiveInvestmentSprintDetail?.sprintCurrencySymbol}
              minTicketSize={activeData?.getActiveInvestmentSprintDetail?.minTicketSize}
              sprintClosingDate={activeData?.getActiveInvestmentSprintDetail?.sprintClosingDate}
              id={detail.id}
            />

            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              onClose={() => setIsComment(false)}
              open={isComment}
            >
              <Alert severity="success">Yorumunuz yönetici onayına gönderildi.</Alert>
            </Snackbar>
            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              onClose={() => setIsSent(false)}
              open={isSent}
            >
              <Alert severity="success">Değişiklikler Kaydedildi.</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              onClose={() => setFavAlert(false)}
              open={favAlert}
            >
              {fav ? (
                <Alert severity="success">Şirket favorilerinize eklendi.</Alert>
              ) : (
                <Alert severity="success">Şirket favorilerinizden çıkartıldı.</Alert>
              )}
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              autoHideDuration={5000}
              onClose={() => setReportSent(false)}
              open={reportSent}
            >
              <Alert severity="success">Rapor talebiniz alındı.</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              autoHideDuration={5000}
              onClose={() => setReportError(false)}
              open={reportError}
            >
              <Alert severity="error">Rapor talebiniz zaten gönderildi.</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              autoHideDuration={5000}
              onClose={() => setNotifySent(false)}
              open={notifySent}
            >
              <Alert severity="success">{notifyText}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              autoHideDuration={5000}
              onClose={() => setNotifyError(false)}
              open={notifyError}
            >
              <Alert severity="error">Haberdar olma talebiniz zaten gönderildi.</Alert>
            </Snackbar>
            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              onClose={() => setFillRate(false)}
              open={fillRate}
            >
              <Alert severity="info" style={{backgroundColor: "#003CA5", color: "#fff"}}>
                Profilinizi doldurmak yatırımcılar tarafınıdan görünülebilirliğinizi yüksek oranda artırır.
              </Alert>
            </Snackbar>
            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{vertical: "top", horizontal: "center"}}
              onClose={() => setFillRate2(false)}
              open={fillRate2}
            >
              <Alert severity="info" style={{backgroundColor: "#003CA5", color: "#fff"}}>
                Profiliniz onay sürecinden geçmektedir. Süreç sona erdiğinde sizi bilgilendireceğiz.
              </Alert>
            </Snackbar>
          </Grid>
        )}
      </main>
    </>
  );
}
