
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import {DebounceInput} from 'react-debounce-input';
import editSvg from'../../static/img/new.svg';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField, } from 'formik';
import FormikTextField from "../../Components/FormikTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PublicIcon from '@material-ui/icons/Public';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FormikNewDropdown from '../../Components/FormikDropdown';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  TabsGeneral:{
    background: '#fff',
    borderTopLeftRadius:16,
    borderTopRightRadius:16,
    paddingLeft:30
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  multiSelect: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  ButtonMobile:{
    [theme.breakpoints.down('sm')]: {
      marginTop:'20px',
    },
  },
  filter: {
    height:40,
    padding: '0 15px',
    border: '1px solid rgb(221 221 221)',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    padding: 25,
    marginBottom:50,
  },
  tabPaper: {
    marginTop:50,
  },
  sticker: {
    marginRight:10,
    color:'#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom:10
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom:'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

const FormikGroupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10}}
          {...props}
          {...field}
          options={props.options}
          getOptionLabel={(option) => option.type}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } label="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

function AddCityDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch } = props;
  const [searchCountries, setSearchCountries] = React.useState([]);


  const GET_ALL_COUNTRY = gql`
      query allCountries($search: String) {
          allCountries(search: $search){
              id
              title
          }
      }
  `;
  const { loading:loadingAllCountries, data: dataAllCountries} = useQuery(GET_ALL_COUNTRY, {
    fetchPolicy:'network-only'
  });
  React.useEffect(() => {
      if(!loadingAllCountries){
        if(dataAllCountries){
          setSearchCountries(dataAllCountries.allCountries);
        }
      }
    },
    [dataAllCountries, loadingAllCountries])


  const handleSubmit = variables => {
    let find = searchCountries.find(x => x.title === variables.country)
    if (!find){
      alert(variables.country + " not found.")
      return
    }
    variables.countryId = find.id
    props.createCity({ variables })
      .then(data => {
        if (data.data.createCity) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Şehir Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        title: '',
                        country: '',
                        countryId: null
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Şehir Adı</Typography>
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Şehir Adı" />
                                </Grid>
                                <Grid container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Ülke</Typography>
                                  <Field
                                    component={FormikNewDropdown}
                                    style={{ width: '100%', marginBottom: 20 }}
                                    textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                    name="country"
                                    options={searchCountries.map(({ title }) => title)}
                                  ></Field>
                                </Grid>
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddCityDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddCountryDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch } = props;

  const handleSubmit = variables => {
    props.createCountry({ variables })
      .then(data => {
        if (data.data.createCountry) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Ülke Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        title: '',
                        code: '',
                        code3: '',
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Ülke İsmi</Typography>
                                    <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Ülke İsmi" />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Alpha-2 Code</Typography>
                                    <FormikTextField name="code" style={{width:'100%',marginBottom:20}} type="text" placeholder="Alpha-2 Code" />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Alpha-3 Code</Typography>
                                    <FormikTextField name="code3" style={{width:'100%',marginBottom:20}} type="text" placeholder="Alpha-3 Code" />
                                  </Grid>
                                  <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                    <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                                  </Grid>
                                </Grid> 
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddCountryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditCityDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, city } = props;
  const [searchCountries, setSearchCountries] = React.useState([]);


  const GET_ALL_COUNTRY = gql`
      query allCountries($search: String) {
          allCountries(search: $search){
              id
              title
          }
      }
  `;
  const { loading:loadingAllCountries, data: dataAllCountries} = useQuery(GET_ALL_COUNTRY, {
    fetchPolicy:'network-only'
  });
  React.useEffect(() => {
      if(!loadingAllCountries){
        if(dataAllCountries){
          setSearchCountries(dataAllCountries.allCountries);
        }
      }
    },
    [dataAllCountries, loadingAllCountries])

  const handleSubmit = variables => {
    if (city.country.title !== variables.country){
      let find = searchCountries.find(x => x.title === variables.country)
      if (!find){
        alert(variables.country + " not found.")
        return
      }
      variables.countryId = find.id
    }
    props.editCity({ variables })
      .then(data => {
        if (data.data.editCity) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Şehir Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        cityId: city.id,
                        language:'tr',
                        title: city.title,
                        country: city.country.title,
                        countryId: city.country.id
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      >
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Şehir Adı</Typography>
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Şehir Adı" />
                                </Grid>
                                <Grid container xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Ülke</Typography>
                                    <Field
                                      component={FormikNewDropdown}
                                      style={{ width: '100%', marginBottom: 20 }}
                                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                      name="country"
                                      options={searchCountries.map(({ title }) => title)}
                                    ></Field>
                                </Grid>
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}>
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Form>
                         )}
                       </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditCityDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditCountryDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, country } = props;

  const handleSubmit = variables => {
    props.editCountry({ variables })
      .then(data => {
        if (data.data.editCountry) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Ülke Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        countryId: country.id,
                        title: country.title,
                        code: country.code,
                        code3: country.code3,
                        language:'tr',
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Ülke İsmi</Typography>
                                    <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Ülke İsmi" />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Alpha-2 Code</Typography>
                                    <FormikTextField name="code" style={{width:'100%',marginBottom:20}} type="text" placeholder="Alpha-2 Code" />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Alpha-3 Code</Typography>
                                    <FormikTextField name="code3" style={{width:'100%',marginBottom:20}} type="text" placeholder="Alpha-3 Code" />
                                  </Grid>
                                  <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}>
                                    <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Form>
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditCountryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteCityDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, city } = props;

  const handleSubmit = () => {
    props.deleteCity({ variables: { cityId: city.id } })
      .then(data => {
        if (data.data.deleteCity) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Şehir Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Görüntüleme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
}

DeleteCityDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteCountryDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, country } = props;

  const handleSubmit = () => {
    props.deleteCountry({ variables: { countryId: country.id } })
      .then(data => {
        if (data.data.deleteCountry) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Ülke Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Görüntüleme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Dialog>
  );
}

DeleteCountryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function ExpandingRow(props){
  const classes = useStyles();
  const [showEditItem, setShowEditItem] = React.useState(false);
  const [showDeleteItem, setShowDeleteItem] = React.useState(false);

  const { item, index } = props;
  return(
    <>
    {item &&
     <StyledTableRow key={index + 1}>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{item.id}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{item.title}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{item.code}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{item.code3}</Typography></StyledTableCell>
        <StyledTableCell align="left" style={{paddingLeft:10,minWidth:75}}>
          <Grid container alignItems="center">
            <Grid item style={{cursor:'pointer'}} onClick={() => setShowEditItem(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}}  alt="sticker" className={classes.sticker} /></Grid>
            <Grid item style={{cursor:'pointer'}} onClick={() => setShowDeleteItem(true)}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        <EditCountryDialog editCountry={props.editCountry} country={item} refetch={props.refetch} open={showEditItem} onClose={() => setShowEditItem(false)}/>
        <DeleteCountryDialog deleteCountry={props.deleteCountry} country={item} refetch={props.refetch} open={showDeleteItem} onClose={() => setShowDeleteItem(false)}/>
      </StyledTableRow>
      }
    </>
  )
}

function ExpandingRowCity(props){
  const classes = useStyles();
  const [open,setOpen] = React.useState(false);
  const [showEditCity, setShowEditCity] = React.useState(false);
  const [showDeleteCity, setShowDeleteCity] = React.useState(false);

  const { city } = props;
  return(
    <>
     <StyledTableRow key={city.id}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{city.id}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{city.title}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{city.country.title}</Typography></StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid style={{cursor:'pointer'}} onClick={() => setShowEditCity(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}} alt="sticker" className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowDeleteCity(true)}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        <EditCityDialog editCity={props.editCity} city={city} refetch={props.refetchCity} open={showEditCity} onClose={() => setShowEditCity(false)}/>
        <DeleteCityDialog deleteCity={props.deleteCity} city={city} refetch={props.refetchCity} open={showDeleteCity} onClose={() => setShowDeleteCity(false)}/>
      </StyledTableRow>
    </>
  )
}

export default function AdminCountryCity(props){
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(20);
    const [pageCity, setPageCity] = React.useState(1);
    const [pageCityCount, setPageCityCount] = React.useState(1);
    const [pageCitySize, setPageCitySize] = React.useState(20);
    const [countries, setCountry] = React.useState();
    const [cities, setCities] = React.useState();
    const [type, setType] = React.useState();
    const [search, setSearch] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addCity, setAddCity] = React.useState(false);
    const [addCountry, setAddCountry,] = React.useState(false);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    const GET_COUNTRY = gql`
    query adminAllCountries($page: Int, $pageSize: Int) {
      adminAllCountries( pagination:{page:$page, pageSize:$pageSize}){
        count
        pageCount
        data{
            id
            title
            code
            code3
        }
        }
    }
    `;
      const { loading, data, refetch} = useQuery(GET_COUNTRY, {
        fetchPolicy:'network-only',
        variables: {page:page, pageSize:pageSize},
    });

    const GET_CITY = gql`
    query adminAllCities($page: Int, $pageSize: Int, $search: String) {
        adminAllCities(pagination:{page:$page, pageSize:$pageSize}, search: $search){
        count
        pageCount
        data{
          id
          title
            country{ id title }
        }
        }
    }
    `;
    const { loading:loadingCity, data:dataCity, refetch:refetchCity } = useQuery(GET_CITY, {
        fetchPolicy:"network-only",
        variables: {page:pageCity, pageSize:pageCitySize, search: search},
    });
    React.useEffect(() => {
      if(!loading){
          if(data){
             setCountry(data.adminAllCountries.data);
              setPageCount(data.adminAllCountries.pageCount)
          }
      }
      if(!loadingCity){
        if(dataCity){
          setCities(dataCity.adminAllCities.data);
          setPageCityCount(dataCity.adminAllCities.pageCount)
        }
      }
    },
    [data, loading, dataCity, loadingCity])

  const handlePageChange = (event, value) => {
    window.scrollTo(0,0);
    setPage(value);
  };
  const handlePageCityChange = (event, value) => {
    window.scrollTo(0,0);
    setPageCity(value);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
    setPageCity(1);
  };

    return (
        <>
            <main
            className={clsx(classes.content, {
                [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
            })}
            >
                    <Grid container style={{background:'#f7f7fc',justifyContent:'center', minHeight: '100vh'}}>
                        <Grid className={"main-container"} xs={12} style={{marginTop:140,height:'fit-content',maxWidth:1320}}>
                            <Grid item style={{width:'100%'}}>
                                  <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center">
                                      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                        <PublicIcon style={{ fontSize: 20, marginTop:5 }} />
                                        <Typography variant="h4">Ülkeler - Şehirler</Typography>
                                      </Breadcrumbs>
                                      <Grid item className={classes.filterArea}>
                                        {value === 1 &&
                                        <Hidden smDown>
                                          <DebounceInput
                                            class="inputTypeSearch"
                                            name="search"
                                            value={search}
                                            placeholder="Ara..."
                                            className={classes.Debounce}
                                            minLength={2}
                                            debounceTimeout={800}
                                            onChange={handleSearch} />
                                          </Hidden>
                                          }
                                          <Button className={classes.ButtonMobile} style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginRight:15}} onClick={() => setAddCountry(true)} variant="containedSecondary"><AddIcon style={{color:'#fff',fontSize:15,marginRight:5,maxHeight:40}} /> Yeni Ülke Ekle </Button>
                                          <Button className={classes.ButtonMobile} style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',minWidth:160}} onClick={() => setAddCity(true)} variant="containedPrimary"><AddIcon style={{color:'#fff',fontSize:15,marginRight:5,maxHeight:40}} /> Yeni Şehir Ekle </Button>
                                          <AddCityDialog createCity={props.createCity} refetch={refetchCity} open={addCity} onClose={() => setAddCity(false)}/>
                                          <AddCountryDialog createCountry={props.createCountry} refetch={refetch} open={addCountry} onClose={() => setAddCountry(false)}/>
                                          
                                      </Grid>
                                  </Grid> 
                                  {countries && 
                                  <Grid container xs={12}>
                                  <AppBar style={{zIndex:0}} className={classes.tabPaper} position="static" color="default">
                                    <Tabs
                                      value={value}
                                      onChange={handleChange}
                                      indicatorColor="rgb(247, 247, 252)"
                                      textColor="primary"
                                      variant="scrollable"
                                      scrollButtons="auto"
                                      aria-label="scrollable auto tabs example"
                                      className={classes.TabsGeneral}
                                    >
                                      <Tab label="Ülkeler" {...a11yProps(0)} style={{fontWeight:600}} />
                                      <Tab label="Şehirler" {...a11yProps(1)} style={{fontWeight:600}} />
                                    </Tabs>
                                  </AppBar>
                                  <TabPanel style={{width:'100%'}} value={value} index={0}>
                                    <Grid container xs={12} className={classes.whiteCard}>
                                      <TableContainer style={{boxShadow:'none'}} component={Paper}>
                                        <Table className={classes.table} aria-label="customized table">
                                          <TableHead>
                                            <TableRow>
                                              <StyledTableCell><Typography variant="h6" color="primary"> Id </Typography></StyledTableCell>
                                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Ülke </Typography></StyledTableCell>
                                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Alpha-2 Code </Typography></StyledTableCell>
                                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Alpha-3 Code </Typography></StyledTableCell>
                                              <StyledTableCell align="left"></StyledTableCell>
                                            </TableRow>
                                          </TableHead>
                                          {countries &&
                                          <TableBody>
                                            {countries.map((country , index) => (
                                             <ExpandingRow index={index} item={country} deleteCountry={props.deleteCountry} editCountry={props.editCountry} refetch={refetch}/>
                                            ))}
                                          </TableBody>
                                          }
                                        </Table>
                                      </TableContainer>
                                      <Grid container justify="flex-end">
                                        <Grid item style={{marginTop:10,padding:0}} justify="flex-end">
                                          <Pagination count={pageCount} page={page} onChange={handlePageChange}
                                                    variant="outlined" color="primary" />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </TabPanel>
                                  <TabPanel style={{width:'100%'}} value={value} index={1}>
                                    <Grid container xs={12} className={classes.whiteCard}>
                                    <TableContainer style={{boxShadow:'none'}} component={Paper}>
                                        <Table className={classes.table} aria-label="customized table">
                                          <TableHead>
                                            <TableRow>
                                              <StyledTableCell><Typography variant="h6" color="primary"> Id </Typography></StyledTableCell>
                                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Şehir </Typography></StyledTableCell>
                                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Ülke </Typography></StyledTableCell>
                                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Aksiyonlar </Typography></StyledTableCell>
                                            </TableRow>
                                          </TableHead>
                                          {cities &&
                                          <TableBody>
                                            {cities.map((city) => (
                                             <ExpandingRowCity setValue={setValue} refetchCity={refetchCity} setType={setType} deleteCity={props.deleteCity} editCity={props.editCity} city={city} />
                                            ))}
                                          </TableBody>
                                          }
                                        </Table>
                                      </TableContainer>
                                      <Grid container justify="flex-end">
                                        <Grid item style={{marginTop:10,padding:0}} justify="flex-end">
                                          <Pagination count={pageCityCount} page={pageCity} onChange={handlePageCityChange}
                                                      variant="outlined" color="primary" />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </TabPanel>
                                </Grid>
                              }  
                            </Grid>
                        </Grid>
                    </Grid>
            </main>        
        </>
    )
}