import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {ApolloProvider} from "@apollo/react-hooks";
import {createUploadLink} from "apollo-upload-client";
import {getMainDefinition} from "@apollo/client/utilities";
import {split} from "@apollo/client";
import {createHttpLink, HttpLink} from "apollo-link-http";
import {ApolloLink} from "apollo-link";
import {ApolloClient} from "apollo-client";
import {WebSocketLink} from "apollo-link-ws";
import {InMemoryCache} from "apollo-cache-inmemory";
import {withClientState} from "apollo-link-state";
import "./i18n";
import gql from "graphql-tag";
import _ from "lodash";
import {buildQuery} from "./utils";

const cache = new InMemoryCache();

const stateLink = withClientState({
  cache,
  defaults: {
    HeaderLeftDrawer: true,
    HeaderLeftDrawerMobile: false
  }
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}`
});

const wsLink = new WebSocketLink({
  options: {
    connectionParams: () => {
      return {
        authToken: localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
      };
    },
    lazy: true,
    reconnect: true
  },
  uri: process.env.REACT_APP_WSS_URL
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-Auth-Token": localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)
        ? `Bearer ${localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)}`
        : null,
      tr_lang: localStorage.getItem("tr_lang") ? localStorage.getItem("tr_lang") : "tr"
    }
  });
  return forward(operation);
});

const httpLink = ApolloLink.from([
  authMiddleware,
  uploadLink,
  stateLink,
  createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: "include"
  })
]);

/*const splitLink = httpLink.split(
  // Don't batch createUpdate because its slow.
  operation => operation.operationName === 'createUpdate',

  new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  }),
);*/
const splitLink = split(
  // split based on operation type
  ({query}) => {
    const {kind, operation} = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  cache,
  link: splitLink
});
client.onResetStore(stateLink.writeDefaults);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
