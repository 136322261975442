import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: 0,
        backgroundColor: 'white',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
    },
    tab: {
        fontWeight: 400,
        padding: '20px 0',
        margin: '0 25px',
    },
    selectedTab: {
        borderBottom: 'solid',
    }
}));


/**
 * @param {Object} props
 * @param {number} props.value - selected tab
 * @param {(event, newValue)=>null} props.onChange
 * @param {Object[]} props.data
 * @param {String} props.data.label
 * @param {()=>any} props.data.onClick
 */
export default function TabsBar(props){
    const classes = useStyles();

    return (
        <AppBar className={classes.appBar} position="static" color="default">
            <Tabs
                value={props.value}
                onChange={props.onChange}
                indicatorColor="rgb(247, 247, 252)"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable tabs"
            >

            {props.data.map((element)=>(
                <Tab label={element.label} key={element.label} className={classes.tab} classes={{selected: classes.selectedTab}}/>
            ))}
            </Tabs>
        </AppBar>
    );
}


