import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, Prompt } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '../../static/img/default-avatar.png';
import GeneralTab from '../../Components/GeneralTab';
import DetailTab from '../../Components/DetailTab';
import ProductTab from '../../Components/ProductTab';
import AnnouncementTab from '../../Components/AnnouncementTab';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import insta from '../../static/icon/new-instagram.svg';
import linkedin from '../../static/icon/new-linkedin.svg';
import twitter from '../../static/icon/new-twitter.svg';
import left from '../../static/icon/arrow-left.svg';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import GreenTag from '../../Components/greenTag.js';
import { Form, Formik, Field } from 'formik';
import Slider from '@material-ui/core/Slider';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';
import Dialog from '@material-ui/core/Dialog';
import FormikTextField from '../../Components/FormikTextField';
import FormikNewDropdown from '../../Components/FormikDropdown';
import Blured from '../../static/img/Blured.png';
import { detailSchema } from '../../yupSchemas';
import FinanceTab from '../../Components/FinanceTab';
import { useTranslation } from 'react-i18next';
import newNotify from '../../static/img/new-notify.png';
import Slider2 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import newCross from '../../static/icon/new-cross.svg';
import DialogTitle from '@material-ui/core/DialogTitle';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  notifyImg: {
    [theme.breakpoints.down('xs')]: {
      width: 80,
    },
  },
  cardGrid: {
    margin: 0,
    marginTop: 30,
  },
  whiteCard: {
    minHeight: 200,
    background: '#ffffff',
    borderRadius: '16px',
    padding: 36,
    boxShadow: '0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    [theme.breakpoints.down('lg')]: {
      padding: 24,
    },
  },
  whiteGrid: {
    height: 'fit-content',
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
  imgEmpty: {
    width: 82,
    height: 82,
    borderRadius: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#003CA5',
    [theme.breakpoints.down('sm')]: {
      width: 45,
      height: 45,
    },
  },
  TabsGeneral: {
    background: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
  },
  imgGrid: {
    borderRadius: '10px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    border: 'solid 1px #eaedf3',
    maxWidth: 80,
    maxHeight: 80,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 60,
      maxHeight: 60,
    },
  },
  titleBar: {
    width: 'fit-content',
    marginLeft: 16,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 25,
    },
  },
  bottomBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 999,
    background: '#fff',
    padding: '8px 70px',
    boxShadow: '0px -4px 4px #F7F7FC;',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 35px',
    },
  },
  titleBar2: {
    width: 'fit-content',
    marginLeft: 16,
  },
  titleFlex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 25,
    },
  },
  Title: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: 1.35,
    textAlign: 'left',
    color: '#172b4d',
    wordBreak: 'break-word',
  },
  model: {
    color: '#777777',
    width: '100%',
    fontSize: 16,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
      marginTop: 5,
      width: 'fit-content',
    },
  },
  oneliner: {
    color: '#777777',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '75%',
    },
  },
  oneliner2: {
    color: '#777777',
    fontSize: 12,
  },
  img: {
    objectFit: 'contain',
    width: 82,
    height: 82,
    borderRadius: '14px',
    [theme.breakpoints.down('sm')]: {
      width: 45,
      height: 45,
    },
  },
  button: {
    padding: '11px 20px',
    fontWeight: 'normal',
    marginTop: 20,
    fontSize: 13,
  },
  buttonTwo: {
    padding: '11px 20px',
    fontWeight: 'normal',
    marginTop: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 18,
    },
    fontSize: 13,
  },
  buttonThree: {
    padding: '11px 20px',
    fontWeight: 'normal',
    background: '#43b506',
    marginTop: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 18,
    },
    fontSize: 13,
    '&:hover': {
      background: '#43b506',
    },
  },
  socialLink: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 25,
    },
    color: '#003CA5',
  },
  tabPaper: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 12px',
    },
  },
  tabGrid: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 12px',
    },
  },
  editButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      marginRight: 25,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
  editButton2: {
    width: 95,
    marginRight: 25,

    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {},
    [theme.breakpoints.down('md')]: {
      marginTop: '30px !important',
      marginRight: 14,
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function DraftDialog(props) {
  const { onClose, open, setAdminErrorMessage, setShowAdminErrorMessage } = props;
  let status = ['Onayla', 'Reddet'];

  const handleClose = () => {
    onClose(null);
  };

  const handleSubmit = (variables) => {
    variables.id = props.id;
    if (variables.status === 'Onayla' || variables.status === 'Approved') {
      variables.status = 'Approved';
    } else {
      variables.status = 'Rejected';
    }
    props
      .adminManageApproval({ variables })
      .then((data) => {
        if (data.data.adminManageApproval) {
          props.history.push('/admin/draftlar');
        }
      })
      .then(() => {})
      .catch((error) => {
        if (error && error.message.includes('invalid input syntax')) {
          setAdminErrorMessage(
            'Draft içeriğinde hata var, lütfen editleyip düzeltin. Tip: Büyük ihtimalle sayı girilen fieldlar ile ilgili.',
          );
          setShowAdminErrorMessage(true);
        } else {
          setAdminErrorMessage('Beklenmedik bir hata.');
          setShowAdminErrorMessage(true);
        }
      });
  };
  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="center" xs={12}>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                status: '',
                description: '',
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {(props) => (
                <Form>
                  <Grid xs={12} container justify="center" style={{ padding: 40 }}>
                    <Grid item xs={8}>
                      <Typography variant="h5" style={{ marginBottom: 10 }}>
                        Statü Değiştir
                      </Typography>
                      <Field
                        component={FormikNewDropdown}
                        style={{ width: '100%', marginBottom: 20 }}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="status"
                        options={status}
                      />
                    </Grid>
                    <Grid item xs={8} style={{ height: 'fit-content' }}>
                      <Typography variant="h5">Açıklama</Typography>
                      <FormikTextField
                        name="description"
                        style={{ width: '100%', marginBottom: 20 }}
                        type="text"
                        placeholder="Description"
                      />
                    </Grid>
                    <Grid item xs={8} justify="center" style={{ marginTop: 10, height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        onClick={() => handleSubmit(props.values)}
                      >
                        Gönder
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default function DraftProfile(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openExitPopup, setOpenExitPopup] = React.useState(false);
  const [openCompletePopup, setOpenCompletePopup] = React.useState(false);
  const [openLowFillRate, setOpenLowFillRate] = React.useState(false);
  const [valuation, setValuation] = React.useState([]);
  const [investmentAmount, setInvestmentAmount] = React.useState([]);
  const [date, setDate] = React.useState([]);
  const [volume, setVolume] = React.useState([]);
  const [numberOfTransactions, setNumberOfTransactions] = React.useState([]);
  const [dateTraction, setDateTraction] = React.useState([]);
  const [sumTraction, setSumTraction] = React.useState([]);
  const [detail, setDetail] = React.useState({});
  const [fav, setFav] = React.useState(null);
  const [isComment, setIsComment] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [initialValuess, setInitialValuess] = React.useState();
  const [value, setValue] = React.useState(0);
  const [authRole, setAuthRole] = React.useState();
  const [isSent, setIsSent] = React.useState(false);
  const [fillRate, setFillRate] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [myId, setMyId] = React.useState(props.startupId);
  const [draftId, setDraftId] = React.useState(props.match.params.id);
  const [status, setStatus] = React.useState();
  const [showAdminErrorMessage, setShowAdminErrorMessage] = React.useState();
  const [adminErrorMessage, setAdminErrorMessage] = React.useState();
  const { t, i18n } = useTranslation();
  const { onClose } = props;
  const [close, setClose] = React.useState(false);
  //const [exitPrompt, setExitPrompt] = React.useState(true);
  const [targetUrl, setTargetUrl] = React.useState('');
  const [open2, setOpen2] = React.useState(false);
  const [profileFillRatePopup, setProfileFillRatePopup] = React.useState(false);
  const [exit, setExit] = React.useState(true);
  const [propsValues,   setPropsValues  ] = React.useState();


  const handleOpenprofileFillRatePopup = () => {
    setProfileFillRatePopup(true);
  };
  const handleOpenExitPopup = () => {
    setOpenExitPopup(true);
  };
  const handleCloseCompletePopup = () => {
    setOpenCompletePopup(false);
    setValue(0);

  };
  const handleCloseFillRatePopup = () => {
    setOpenLowFillRate(false);
    setValue(0);

  };
  const handleCloseExitPopup = () => {
    setOpenExitPopup(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen2(false);
    setTargetUrl('');
  };

  const lowFillRatePopuptoCompletePopup = () => {
    setOpenLowFillRate(false);
    setOpenCompletePopup(true);
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const DRAFT_DETAIL = gql`
    query getStartupDraftDetail($draftId: ID!) {
      getStartupDraftDetail(draftId: $draftId) {
        editable
        original {
          id
          title
          logo
          oneliner
          description
          currency
          socialLinkedin
          socialFacebook
          socialTwitter
          socialInstagram
          stockPrice
          stockValuation
          stockDiscount
          stockEndDate
          summary
          productsDifferences
          productsProblemsSolutions
          contactMail
          stage
          sector
          website
          industry
          businessModelArray
          foundationDate
          iosApp
          androidApp
          webApp
          hasApplication
          targetedMarket
          totalMarketSize
          targetedTotalMarketSize
          targetedRegion
          detailMarketAnalysisNote
          hasPotentialExitStrategy
          plannedExitDate
          potentialBuyers
          hasUsers
          userSize
          monthlyActiveUserSize
          isFavorited
          detailMarketAnalysis
          detailTractionText
          tractionPayingCustomerSize
          tractionHasPoCCustomer
          tractionHasRevenue
          tractionRevenueSize
          profileFillRate
          hasInvestment
          totalInvestmentRound
          totalInvestmentSize
          lastValuation
          lastFundingDate
          lastFundingType
          lastFundingSize
          detailRevenueModels {
            title
            description
            revenuePercentage
            items {
              productName
              price
            }
          }
          startupFeeds {
            date
            type
            title
            desc
            newUrl
          }
          founders {
            onlyLogo
            logo
            nameSurname
            title
            linkedin
          }
          investors {
            onlyLogo
            logo
            title
            subtitle
            link
          }
          competitors {
            onlyLogo
            logo
            title
            link
          }
          customers {
            onlyLogo
            logo
            title
            link
          }
          documents {
            onlyFile
            file
            title
            type
            extension
            fileSize
            date
          }
          country {
            id
            title
          }
        }
        startupDraft {
          moderationStatus
          status
          data {
            title
            logo
            oneliner
            description
            currency
            socialLinkedin
            socialFacebook
            socialTwitter
            socialInstagram
            stockPrice
            stockValuation
            stockDiscount
            stockEndDate
            summary
            contactMail
            stage
            sector
            website
            industry
            businessModelArray
            productsProblemsSolutions
            productsDifferences
            foundationDate
            iosApp
            androidApp
            webApp
            hasApplication
            targetedMarket
            totalMarketSize
            targetedTotalMarketSize
            targetedRegion
            detailMarketAnalysisNote
            hasPotentialExitStrategy
            plannedExitDate
            potentialBuyers
            hasUsers
            userSize
            monthlyActiveUserSize
            isFavorited
            detailMarketAnalysis
            detailTractionText
            tractionPayingCustomerSize
            tractionHasPoCCustomer
            tractionHasRevenue
            tractionRevenueSize
            profileFillRate
            hasInvestment
            totalInvestmentRound
            totalInvestmentSize
            lastValuation
            lastFundingDate
            lastFundingType
            lastFundingSize
            detailRevenueModels {
              title
              description
              revenuePercentage
              items {
                productName
                price
              }
            }
            startupFeeds {
              date
              type
              title
              desc
              newUrl
            }
            founders {
              onlyLogo
              logo
              nameSurname
              position
              title
              linkedin
            }
            investors {
              onlyLogo
              logo
              title
              subtitle
              link
            }
            competitors {
              onlyLogo
              logo
              title
              link
            }
            customers {
              onlyLogo
              logo
              title
              link
            }
            documents {
              onlyFile
              file
              title
              type
              extension
              fileSize
              date
            }
            country {
              id
              title
            }
          }
        }
      }
    }
  `;
  const { loading, data, refetch } = useQuery(DRAFT_DETAIL, {
    fetchPolicy: 'network-only',
    variables: { draftId: props.match.params.id },
  });
  const handleChange = (event, newValue,formikValues) => {
    setValue(newValue);
    handleSubmit2(formikValues,true);
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  function https(value) {
    if (!value.includes('http')) {
      const prefix = 'https://';
      if (value.substr(0, prefix.length) !== prefix) {
        value = prefix + value;
      }
      return value;
    } else {
      return value;
    }
  }

  const handleSubmit = async (variablesAll) => {
    setSubmitting(true);
    const { ...variables } = variablesAll;
    if (variables.tractionPayingCustomerSize === '') {
      variables.tractionPayingCustomerSize = 0;
    }
    if (variables.totalInvestmentRound === '') {
      variables.totalInvestmentRound = 0;
    }
    if (variables.totalInvestmentSize === '') {
      variables.totalInvestmentSize = 0;
    }
    /* added later */ 
    if (variables.foundationDate === null) {
      variables.foundationDate = '';
    }
    
    if (variables.stage === null) {
      variables.stage = '';
    }
    
    if (variables.plannedExitDate === null ) {
      variables.plannedExitDate = '';
    }
    if (variables.potentialBuyers === '' ) {
      variables.potentialBuyers = '';
    }
    
    /**/ 
    if (variables.userSize === '') {
      variables.userSize = 0;
    }
    variables.status = 'Ready';
    if (variables.monthlyActiveUserSize === '') {
      variables.monthlyActiveUserSize = 0;
    }
    variables.draftId = parseInt(props.match.params.id);
    variables.tractionRevenueSize = parseInt(variables.tractionRevenueSize);
    if (variables.logo !== null) {
      if (typeof variables.logo === 'string' && variables.logo !== '') {
        variables.logo = null;
      }
    }
    if (variables.country !== null) {
      variables.country = variables.country.id;
    }
    props
      .editStartupDraft({ variables })
      .then((data) => {
        if (data.data.editStartupDraft) {
          setExit(false);
          props.history.push(`/girisim/${props.startup.id}`);
          //setValue(0)
          refetch();
          setEdit(false);
          setSubmitting(false);
          setIsSent(true);
        }
      })
      .then(() => {})
      .catch((error) => {});
    setOpenCompletePopup(false);
  };
  const handleSubmit2 = async (variablesAll,controll) => {
    setSubmitting(true);
    const { ...variables } = variablesAll;
    if (variables.tractionPayingCustomerSize === '') {
      variables.tractionPayingCustomerSize = 0;
    }
    if (variables.totalInvestmentRound === '') {
      variables.totalInvestmentRound = 0;
    }
     /* added later */ 
     if (variables.foundationDate === null) {
      variables.foundationDate = '';
    }
    
    if (variables.stage === null) {
      variables.stage = '';
    }
    
    if (variables.plannedExitDate === null ) {
      variables.plannedExitDate = '';
    }
    if (variables.potentialBuyers === '' ) {
      variables.potentialBuyers = '';
    }
    
    /**/ 
    if (variables.totalInvestmentSize === '') {
      variables.totalInvestmentSize = 0;
    }
    if (variables.userSize === '') {
      variables.userSize = 0;
    }
    if (variables.monthlyActiveUserSize === '') {
      variables.monthlyActiveUserSize = 0;
    }
    variables.draftId = parseInt(props.match.params.id);
    variables.tractionRevenueSize = parseInt(variables.tractionRevenueSize);
    if (variables.logo !== null) {
      if (typeof variables.logo === 'string' && variables.logo !== '') {
        variables.logo = null;
      }
    }
    if (variables.country !== null) {
      variables.country = variables.country.id;
    }
    props
      .editStartupDraft({ variables })
      .then((data) => {
        if (data.data.editStartupDraft) {
          refetch();
          setSubmitting(false);
          if(!controll){
            setValue( value + 1);
          } 
        }
      })
      .then(() => {})
      .catch((error) => {});
  };

  // added hash for to understand that we are in edit mode.
  // düzenleme modunda olduğumuzu anlamak için böyle bir çözüm ürettik.
  React.useEffect(() => {
    if (props.location.hash === '#edit') {
      setEdit(true);
    }
  }, []);

  const handleTypename = (values) => {
    values = values.map(({ __typename, ...array }) => array);
    return values;
  };

  const toAdminEdit = () => {
    setEdit(true);
    props.history.push(`/draft-edit/${props.match.params.id}`);
  };
  const toEdit = () => {
    props
      .startupDraftSetEdit({ variables: { draftId: props.match.params.id } })
      .then((data) => {
        if (data.data.startupDraftSetEdit) {
          setEdit(true);
          props.history.push(`/draft-edit/${props.match.params.id}`);
          setFillRate(true);
          handleCloseFillRatePopup();
          handleClose();
        } else {
        }
      })
      .then(() => {})
      .catch((error) => {});
  };

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        // if(window.location.href.indexOf("startupborsa") > -1 && !(window.location.href.indexOf("edit") > -1) && window.userGuiding){
        //   window.userGuiding.previewGuide(23704, {
        //     checkHistory: true,
        // })};
        data.startupDetail = data.getStartupDraftDetail.startupDraft.data;
        data.newDetail = data.getStartupDraftDetail.startupDraft.data;
        data.originalDetail = data.getStartupDraftDetail.original;

        /*if (props.history.location.pathname === `/draft/${props.match.params.id}` &&  window.location.pathname === `/draft-edit/${props.match.params.id}`){
          toEdit();
        }*/

        if (!data.originalDetail.businessModelArray) {
          data.originalDetail.businessModelArray = [];
        }
        if (!data.startupDetail.businessModelArray) {
          data.startupDetail.businessModelArray = [];
        }
        if (!data.newDetail.businessModelArray) {
          data.newDetail.businessModelArray = [];
        }
        if (data.originalDetail.competitors !== [] && data.originalDetail.competitors) {
          data.originalDetail.competitors = handleTypename(data.originalDetail.competitors);
        }
        if (data.originalDetail.customers !== [] && data.originalDetail.customers) {
          data.originalDetail.customers = handleTypename(data.originalDetail.customers);
        }
        if (data.originalDetail.founders !== [] && data.originalDetail.founders) {
          data.originalDetail.founders = handleTypename(data.originalDetail.founders);
        }
        if (data.originalDetail.investors !== [] && data.originalDetail.investors) {
          data.originalDetail.investors = handleTypename(data.originalDetail.investors);
        }
        if (
          data.originalDetail.startupFeeds !== [] &&
          data.originalDetail.startupFeeds &&
          data.originalDetail.startupFeeds[0] !== null
        ) {
          data.originalDetail.startupFeeds = handleTypename(data.originalDetail.startupFeeds);
        }
        if (data.originalDetail.documents !== [] && data.originalDetail.documents) {
          data.originalDetail.documents = handleTypename(data.originalDetail.documents);
        }

        if (data.startupDetail.industry === null) {
          data.startupDetail.industry = [];
        }
        let val = [];
        let invAmnt = [];
        let dateTemp = [];
        let volumeTemp = [];
        let numberOfTransactionsTemp = [];
        let sumNumberOfTransactions = 0;
        let tractionDateTemp = [];
        if (data.startupDetail.socialLinkedin) {
          data.startupDetail.socialLinkedin = https(data.startupDetail.socialLinkedin);
        }
        if (data.startupDetail.foundationDate) {
          data.startupDetail.foundationDate = data.startupDetail.foundationDate.split('T')[0];
        }
        if (data.startupDetail.lastFundingDate) {
          data.startupDetail.lastFundingDate = data.startupDetail.lastFundingDate.split('T')[0];
        }
        if (data.startupDetail.socialFacebook) {
          data.startupDetail.socialFacebook = https(data.startupDetail.socialFacebook);
        }
        if (data.startupDetail.socialTwitter) {
          data.startupDetail.socialTwitter = https(data.startupDetail.socialTwitter);
        }
        if (data.startupDetail.socialInstagram) {
          data.startupDetail.socialInstagram = https(data.startupDetail.socialInstagram);
        }
        if (data.startupDetail.website) {
          data.startupDetail.website = https(data.startupDetail.website);
        }
        if (data.startupDetail.webApp) {
          data.startupDetail.webApp = https(data.startupDetail.webApp);
        }
        if (data.startupDetail.iosApp) {
          data.startupDetail.iosApp = https(data.startupDetail.iosApp);
        }
        if (data.startupDetail.androidApp) {
          data.startupDetail.androidApp = https(data.startupDetail.androidApp);
        }
        if (data.startupDetail.founders) {
          if (data.startupDetail.founders.length > 0) {
            data.startupDetail.founders.forEach(function (a) {
              if (a.linkedin) {
                a.linkedin = https(a.linkedin);
              }
            });
          }
        }
        if (data.startupDetail.competitors) {
          if (data.startupDetail.competitors.length > 0) {
            data.startupDetail.competitors.forEach(function (a) {
              if (a.link) {
                a.link = https(a.link);
              }
            });
          }
        }
        if (data.startupDetail.investors) {
          if (data.startupDetail.investors.length > 0) {
            data.startupDetail.investors.forEach(function (a) {
              if (a.link) {
                a.link = https(a.link);
              }
            });
          }
        }
        if (data.startupDetail.customers) {
          if (data.startupDetail.customers.length > 0) {
            data.startupDetail.customers.forEach(function (a) {
              if (a.link) {
                a.link = https(a.link);
              }
            });
          }
        }
        if (data.startupDetail.startupFeeds) {
          if (data.startupDetail.startupFeeds.length > 0) {
            data.startupDetail.startupFeeds.forEach(function (a) {
              if (a && a.newUrl) {
                if (a.date) {
                  a.date = a.date.split('T')[0];
                }
                a.newUrl = https(a.newUrl);
              }
            });
          }
        }
        if (data.startupDetail.competitors !== [] && data.startupDetail.competitors) {
          data.startupDetail.competitors = handleTypename(data.startupDetail.competitors);
        }
        if (data.startupDetail.customers !== [] && data.startupDetail.customers) {
          data.startupDetail.customers = handleTypename(data.startupDetail.customers);
        }
        if (data.startupDetail.founders !== [] && data.startupDetail.founders) {
          data.startupDetail.founders = handleTypename(data.startupDetail.founders);
        }
        if (data.startupDetail.investors !== [] && data.startupDetail.investors) {
          data.startupDetail.investors = handleTypename(data.startupDetail.investors);
        }
        if (
          data.startupDetail.startupFeeds !== [] &&
          data.startupDetail.startupFeeds &&
          data.startupDetail.startupFeeds[0] !== null
        ) {
          data.startupDetail.startupFeeds = handleTypename(data.startupDetail.startupFeeds);
        }
        if (data.startupDetail.documents !== [] && data.startupDetail.documents) {
          data.startupDetail.documents = handleTypename(data.startupDetail.documents);
        }
        if (data.startupDetail.detailRevenueModels !== [] && data.startupDetail.detailRevenueModels) {
          data.startupDetail.detailRevenueModels = handleTypename(data.startupDetail.detailRevenueModels);
          data.startupDetail.detailRevenueModels.forEach(function (v) {
            if (v.items !== null) {
              v.revenuePercentage = v.revenuePercentage.replace('%', '');
              v.items.forEach(function (a) {
                if (a !== null) {
                  delete a.__typename;
                }
              });
            } else {
              v.items = [];
            }
          });
        }
        setDate(dateTemp);
        if (
          window.location.pathname === `/draft-edit/${props.match.params.id}` &&
          data.getStartupDraftDetail.editable &&
          data.getStartupDraftDetail.startupDraft.moderationStatus === 'Draft'
        ) {
          setEdit(true);
        }

        setValuation(val);
        setNumberOfTransactions(numberOfTransactionsTemp);
        setInvestmentAmount(invAmnt);
        setVolume(volumeTemp);
        setDateTraction(tractionDateTemp);
        setSumTraction(sumNumberOfTransactions);
        setDetail(data.startupDetail);
        setInitialValuess(data.startupDetail);
        setFav(data.startupDetail.isFavorited);
        setAuthRole(props.authRole);
        setMyId(1);

        if (props.startupId) {
          setMyId(props.startupId);
        }
      }
    }
  }, [data, loading]);

  const popupFunction = () => {
    if (detail.profileFillRate >= 50) {
      setOpenCompletePopup(true);
    }
    if (detail.profileFillRate < 50) {
      setOpenLowFillRate(true);
    }
  };

  return (
    <>
      {!loading && (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          {authRole && (
            <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
              <DraftDialog
                adminManageApproval={props.adminManageApproval}
                setAdminErrorMessage={setAdminErrorMessage}
                setShowAdminErrorMessage={setShowAdminErrorMessage}
                id={props.match.params.id}
                open={open}
                history={props.history}
                onClose={() => setOpen(false)}
              />
              <Grid
                className={'main-container'}
                xs={12}
                style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
              >
                <Formik
                  initialValues={initialValuess}
                  validationSchema={detailSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {(props) => {

                    /*setPropsValues(props.values)*/;
                    
                    return (
                      <Form style={{ width: '100%' }}>
                        
                        <Grid item style={{ width: '100%' }}>
                          {myId && (
                            <>
                              {(authRole === 'Admin' || authRole === 'SuperAdmin') && (
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  style={{ padding: '0 10px' }}
                                >
                                  <Typography variant="h3">Şirket Detayı</Typography>
                                  <>
                                    {!edit ? (
                                      data.getStartupDraftDetail.startupDraft.status === 'Pending' && (
                                        <Grid container justify="flex-end" style={{ padding: '0 10px' }}>
                                          <Button
                                            variant="containedPrimary"
                                            className={classes.editButton}
                                            style={{ marginLeft: 10 }}
                                            onClick={() => setOpen(true)}
                                          >
                                            Draft'ı Yönet
                                          </Button>
                                          <Button
                                            onClick={() => toAdminEdit()}
                                            variant="containedPrimary"
                                            className={classes.editButton}
                                            style={{ marginRight: 0 }}
                                          >
                                            Düzenle
                                          </Button>
                                        </Grid>
                                      )
                                    ) : (
                                      <>
                                        {submitting ? (
                                          <Button
                                            variant="containedPrimary"
                                            className={classes.editButton2}
                                            style={{ marginRight: 0 }}
                                            disabled
                                            style={{ fontWeight: 'normal', marginRight: 0 }}
                                          >
                                            <CircularProgress
                                              style={{
                                                color: '#ffffff',
                                                width: '25px',
                                                height: '25px',
                                                marginRight: '10px',
                                                marginBottom: 0,
                                              }}
                                            />{' '}
                                            Lütfen Bekleyiniz..
                                          </Button>
                                        ) : (
                                          <Grid container justify="flex-end" className={classes.bottomBar}>
                                            <Button
                                              variant="containedPrimary"
                                              className={classes.editButton2}
                                              style={{ fontWeight: 'normal', marginRight: 0, padding: '13px 24px' }}
                                              disabled={!props.isValid}
                                              onClick={() => {
                                                setStatus('');
                                                handleSubmit(props.values);
                                              }}
                                            >
                                              {' '}
                                              Kaydet{' '}
                                            </Button>
                                          </Grid>
                                        )}
                                      </>
                                    )}
                                  </>
                                </Grid>
                              )}
                              {authRole === 'Member' && (
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  style={{ padding: '0 10px' }}
                                >
                                  {/* 
                        added prompt and popup for to understand do exit without saving
                        kullanıcı edit modundayken kaydetmeden çıkmak isterse alert çıkar.
                        */}

                        <Dialog
                          open={open2}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          style={{ padding: 20 }}
                          fullWidth={true}
                          maxWidth="xs"
                          classes={{ paper: props.myDialog }}
                        >
                          <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                            <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
                          </Grid>
                          <Grid
                            container
                            style={{
                              width: '100%',
                              height: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                width: '116px',
                                height: '116px',
                                backgroundColor: 'rgba(246, 142, 135, 0.15)',
                                borderRadius: '100%',
                              }}
                            ></div>{' '}
                            <svg
                              style={{ height: '42px', position: 'absolute' }}
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.3333 28.6667L24 24M24 24L28.6667 19.3333M24 24L19.3333 19.3333M24 24L28.6667 28.6667M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                                stroke="#F16063"
                                stroke-width="4.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Grid>

                          <DialogTitle id="simple-dialog-title">
                            <Typography
                              align="center"
                              style={{
                                paddingLeft: 32,
                                paddingRight: 32,
                                fontWeight: 600,
                                fontSize: '25px',
                                lineHeight: '38px',
                                textAlign: 'center',
                                color: '#27272E',
                              }}
                            >
                              {t('Kaydetmeden çıkıyorsunuz')}
                            </Typography>
                          </DialogTitle>
                          <Typography
                            align="center"
                            style={{
                              paddingLeft: 32,
                              paddingRight: 32,
                              fontWeight: 100,
                              fontSize: '16px',
                              lineHeight: '24px',
                              textAlign: 'center',
                              color: '#425466',
                            }}
                          >
                            {t('Düzenlemenizi tamamlayarak onaya göndermek istiyor musunuz?')}
                          </Typography>
                          <Grid container style={{ marginTop: 15, marginBottom: 40 }}>
                            <Grid
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              xs={12}
                              md={6}
                            >
                              <Button
                                style={{ minWidth: '70%', marginTop: 15 }}
                                href={targetUrl}
                                variant="outlinedSecondary"
                              >
                                Çık
                              </Button>
                            </Grid>
                            <Grid
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              xs={12}
                              md={6}
                            >
                              <Button
                                style={{ minWidth: '70%', marginTop: 15 }}
                                onClick={() => toEdit()}
                                variant="containedPrimary"
                              >
                                Düzenle
                              </Button>
                            </Grid>
                          </Grid>
                        </Dialog>
                        <Prompt
                          when={/*props.values !== props.initialValues*/ exit === true}
                          message={(location, action) => {
                            console.log('Backing up...', location, action);
                            // gitmesi gerektiği url 'yi saklıyoruz
                            setTargetUrl(location.pathname);
                            handleClickOpen();
                            return targetUrl ? true : false;
                          }}
                        />
                                  <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                                    Şirket Detayı
                                  </Typography>
                                  {data.getStartupDraftDetail.startupDraft.status === 'Pending' && (
                                    <>
                                      {!edit ? (
                                        <Button
                                          onClick={() => toEdit()}
                                          variant="containedPrimary"
                                          className={classes.editButton2}
                                          style={{ marginRight: 0 }}
                                        >
                                          Düzenle
                                        </Button>
                                      ) : (
                                        <>
                                          {value === 4 && (
                                            <Grid container justify="flex-end" className={classes.bottomBar}>
                                              <Button
                                                variant="outlinedPrimary"
                                                className={classes.editButton2}
                                                style={{
                                                  fontWeight: 'normal',
                                                  fontSize: 14,
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                                onClick={() => (setValue(value - 1), window.scrollTo(0, 0))}
                                              >
                                                {'Geri'}
                                              </Button>
                                              <Button
                                                variant="containedPrimary"
                                                className={classes.editButton2}
                                                style={{ fontWeight: 'normal', fontSize: 14 }}
                                                onClick={() => {
                                                  setStatus('');
                                                  handleSubmit2(props.values);
                                                  setStatus('Ready');
                                                  handleSubmit(props.values);
                                                }}
                                              >
                                                {'Kaydet'}
                                              </Button>
                                            </Grid>
                                          )}
                                          {value === 0 && (
                                            <Grid container justify="flex-end" className={classes.bottomBar}>
                                              <Button
                                                variant="containedPrimary"
                                                className={classes.editButton2}
                                                style={{ fontWeight: 'normal', fontSize: 14 }}
                                                onClick={() => {
                                                  handleSubmit2(props.values);
                                                  setStatus('Ready');
                                                  window.scrollTo(0, 0);
                                                  setProfileFillRatePopup(true);
                                                }}
                                              >
                                                {'İlerle'}
                                              </Button>
                                            </Grid>
                                          )}
                                          {value != 4 &&
                                            value != 0 &&
                                            (submitting ? (
                                              <Button
                                                variant="containedPrimary"
                                                className={classes.editButton2}
                                                disabled
                                                style={{ fontWeight: 'normal' }}
                                              >
                                                <CircularProgress
                                                  style={{
                                                    color: '#ffffff',
                                                    width: '25px',
                                                    height: '25px',
                                                    marginRight: '10px',
                                                    marginBottom: 0,
                                                  }}
                                                />{' '}
                                                Yükleniyor
                                              </Button>
                                            ) : (
                                              <Grid container justify="flex-end" className={classes.bottomBar}>
                                                <Button
                                                  variant="outlinedSecondary"
                                                  className={classes.editButton2}
                                                  style={{
                                                    fontWeight: 'normal',
                                                    fontSize: 14,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                  }}
                                                  onClick={() => (setValue(value - 1), window.scrollTo(0, 0))}
                                                >
                                                  {'Geri'}
                                                </Button>
                                                <Button
                                                  variant="containedPrimary"
                                                  className={classes.editButton2}
                                                  style={{ fontWeight: 'normal', fontSize: 14 }}
                                                  onClick={() => {
                                                    handleSubmit2(props.values);
                                                    setStatus('Ready');
                                                    window.scrollTo(0, 0);
                                                    setProfileFillRatePopup();
                                                  }}
                                                >
                                                  {'İlerle'}
                                                </Button>
                                              </Grid>
                                            ))}
                                        </>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              )}
                            </>
                          )}
                          {!edit ? (
                            <Grid className={classes.cardGrid} container spacing={4} xs={12}>
                              <Grid item className={classes.whiteGrid} xs={12} md={6}>
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  justify="end"
                                  xs={12}
                                  className={classes.whiteCard}
                                >
                                  <Grid xs={12} container item justify="end">
                                    <Grid item>
                                      {detail.logo ? (
                                        <img src={detail.logo} className={classes.img} alt="logo"></img>
                                      ) : (
                                        <Grid className={classes.imgEmpty}>
                                          <Typography variant="h2" style={{ color: '#fff' }}>
                                            {detail.title && detail.title.charAt(0)}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid item className={classes.titleBar2} xs>
                                      <Grid container alignItems="flex-end" style={{ marginBottom: 5 }}>
                                        {data.originalDetail && (
                                          <>
                                            {data.originalDetail.title === detail.title ? (
                                              <Typography variant="h5">{detail.title}</Typography>
                                            ) : (
                                              <Typography
                                                variant="h5"
                                                style={{ background: '#0055ff12', padding: '0 5px', borderRadius: 4 }}
                                              >
                                                {detail.title}
                                              </Typography>
                                            )}
                                          </>
                                        )}
                                      </Grid>
                                      {data.originalDetail && detail.oneliner && (
                                        <>
                                          {data.originalDetail.oneliner === detail.oneliner ? (
                                            <Typography className={classes.oneliner2} variant="body2" align="left">
                                              {truncateString(detail.oneliner, 100)}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              className={classes.oneliner2}
                                              variant="body2"
                                              align="left"
                                              style={{ background: '#0055ff12', padding: '0 5px', borderRadius: 4 }}
                                            >
                                              {truncateString(detail.oneliner, 100)}
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                      <Grid container alignItems="flex-end" style={{ marginTop: 10 }}>
                                        <Grid container spacing={1}>
                                          {detail.businessModelArray &&
                                            detail.businessModelArray.length > 0 &&
                                            detail.businessModelArray.map((model) => (
                                              <Grid item>
                                                <GreenTag title={model} />
                                              </Grid>
                                            ))}
                                          {detail.country && detail.country.title && (
                                            <Grid item>
                                              <GreenTag title={detail.country.title} />
                                            </Grid>
                                          )}
                                          <Grid item style={{ display: 'flex', marginLeft: 25, alignItems: 'center' }}>
                                            <a href={detail.socialInstagram} style={{ padding: 0 }} target="_blank">
                                              <img src={insta} alt={insta} style={{ width: 16 }} />
                                            </a>
                                            <a
                                              href={detail.socialTwitter}
                                              style={{ padding: 0, marginLeft: 20 }}
                                              target="_blank"
                                            >
                                              <img src={twitter} alt={twitter} style={{ width: 18 }} />
                                            </a>
                                            <a
                                              href={detail.socialLinkedin}
                                              style={{ padding: 0, marginLeft: 20 }}
                                              target="_blank"
                                            >
                                              <img src={linkedin} alt={linkedin} style={{ width: 16 }} />
                                            </a>
                                          </Grid>
                                        </Grid>
                                        <Grid container xs={12} style={{ marginTop: 5 }}>
                                          {detail.profileFillRate > -1 && (
                                            <Grid container xs={12}>
                                              <Grid container>
                                                <Typography
                                                  style={{ color: '#172b4d', fontWeight: 'normal' }}
                                                  variant="h6"
                                                >
                                                  Profil Doluluk Oranı:
                                                </Typography>
                                                <Typography
                                                  style={{ color: '#172b4d', marginLeft: 8 }}
                                                  variant="h6"
                                                  id="disabled-slider"
                                                >
                                                  %{detail.profileFillRate}
                                                </Typography>
                                              </Grid>
                                              <Slider
                                                style={{ paddingTop: 5 }}
                                                disabled
                                                defaultValue={detail.profileFillRate}
                                                value={detail.profileFillRate}
                                                aria-labelledby="disabled-slider"
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item className={classes.whiteGrid} xs={12} md={6}>
                                <Grid container className={classes.whiteCard}>
                                  <Grid item>
                                    <img src={newNotify} className={classes.notifyImg} alt="notify" />
                                  </Grid>
                                  <Grid xs={12} md={8} style={{ marginLeft: 24 }}>
                                    <Slider2 {...settings} style={{ textAlign: 'left' }}>
                                      <Grid xs={12} item>
                                        <Typography variant="h4">{t('Girişimcilere Öneriler')}</Typography>
                                        <Typography variant="body2" style={{ marginTop: 10 }}>
                                          {t(
                                            'Metrikleriniz, ürününüz, ekibiniz ve geleceğe yönelik planlarınız hakkında tutarlı ve samimi olmaya çalışın.',
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={12} item>
                                        <Typography variant="h4">
                                          {t('Önceliğiniz para değil akıllı para olsun')}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginTop: 10 }}>
                                          {t(
                                            'Öncelikle yatırımcı adayını bir takım arkadaşı ve danışman olarak görmeye çalışın.Amacınız; yatırımcınız ile sağlam temellere dayalı bir ilişki kurmak olsun.',
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid xs={12} item>
                                        <Typography variant="h4">{t('Veriler ve rakamlar ile konuşun')}</Typography>
                                        <Typography variant="body2" style={{ marginTop: 10 }}>
                                          {t(
                                            'Yatırımcılar rakamları sever.Siz de rakamlara önem verin.Finansal durumunuz, geleceğe dair planlarınız, gelir, gider, karlılık gibi konularda net olun.',
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Slider2>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid className={classes.cardGrid} container spacing={3} xs={12}>
                              <Grid item className={classes.whiteGrid} xs={12}>
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  justify="end"
                                  xs={12}
                                  style={{ minHeight: 160 }}
                                  className={classes.whiteCard}
                                >
                                  <Grid xs={12} container item justify="end">
                                    <Grid item>
                                      {detail.logo ? (
                                        <img src={detail.logo} className={classes.img} alt="logo"></img>
                                      ) : (
                                        <Grid className={classes.imgEmpty}>
                                          <Typography variant="h2" style={{ color: '#fff' }}>
                                            {detail.title && detail.title.charAt(0)}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid item className={classes.titleBar} xs={12} md>
                                      <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={classes.titleFlex}>
                                          {data.originalDetail && (
                                            <>
                                              {data.originalDetail.title === detail.title ? (
                                                <Typography className={classes.Title}>{detail.title}</Typography>
                                              ) : (
                                                <Typography
                                                  className={classes.Title}
                                                  style={{ background: '#0055ff12', padding: '0 5px', borderRadius: 4 }}
                                                >
                                                  {detail.title}
                                                </Typography>
                                              )}
                                            </>
                                          )}
                                          {data.originalDetail && (
                                            <>
                                              {data.originalDetail.businessModelArray === detail.businessModelArray ? (
                                                <Typography
                                                  className={classes.model}
                                                  style={{ marginRight: 25 }}
                                                  variant="body1"
                                                  align="left"
                                                >
                                                  {detail.businessModelArray &&
                                                    detail.businessModelArray.length > 0 &&
                                                    detail.businessModelArray.map((model) => (
                                                      <Typography variant="body1">{model}</Typography>
                                                    ))}
                                                </Typography>
                                              ) : (
                                                <Typography
                                                  className={classes.model}
                                                  style={{
                                                    marginRight: 25,
                                                    background: '#0055ff12',
                                                    padding: '0 5px',
                                                    borderRadius: 4,
                                                    display: 'flex',
                                                  }}
                                                  variant="body1"
                                                  align="left"
                                                >
                                                  {detail.businessModelArray &&
                                                    detail.businessModelArray.length > 0 &&
                                                    detail.businessModelArray.map((model) => (
                                                      <Typography variant="body1" style={{ marginRight: 10 }}>
                                                        {model}
                                                      </Typography>
                                                    ))}
                                                </Typography>
                                              )}
                                            </>
                                          )}
                                          <a
                                            href={detail.website}
                                            style={{ padding: 0 }}
                                            className={classes.socialLink}
                                            target="_blank"
                                          >
                                            {data.originalDetail && (
                                              <>
                                                {data.originalDetail.website === detail.website ? (
                                                  <Typography variant="body1" color="primary" align="left">
                                                    {detail.website}
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    variant="body1"
                                                    color="primary"
                                                    style={{
                                                      background: '#0055ff12',
                                                      padding: '0 5px',
                                                      borderRadius: 4,
                                                    }}
                                                    align="left"
                                                  >
                                                    {detail.website}
                                                  </Typography>
                                                )}
                                              </>
                                            )}
                                          </a>
                                        </Grid>
                                        <Grid container xs={12} md={3} justify="flex-end">
                                          {detail.profileFillRate > -1 && (
                                            <Grid container xs={12}>
                                              <Grid container justify="space-between">
                                                <Typography
                                                  style={{ color: '#172b4d', fontWeight: 'normal' }}
                                                  variant="h6"
                                                >
                                                  Profil Doluluk Oranı
                                                </Typography>
                                                <Typography
                                                  style={{ color: '#172b4d' }}
                                                  variant="h6"
                                                  id="disabled-slider"
                                                >
                                                  %{detail.profileFillRate}
                                                </Typography>
                                              </Grid>
                                              <Slider
                                                disabled
                                                defaultValue={detail.profileFillRate}
                                                value={detail.profileFillRate}
                                                aria-labelledby="disabled-slider"
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                      {data.originalDetail && detail.oneliner && (
                                        <>
                                          {data.originalDetail.oneliner === detail.oneliner ? (
                                            <Typography className={classes.oneliner} variant="body2" align="left">
                                              {truncateString(detail.oneliner, 150)}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              className={classes.oneliner}
                                              variant="body2"
                                              align="left"
                                              style={{
                                                background: '#0055ff12',
                                                width: 'fit-content',
                                                padding: '0 5px',
                                                borderRadius: 4,
                                              }}
                                            >
                                              {truncateString(detail.oneliner, 150)}
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <AppBar className={classes.tabPaper} position="static" color="default">
                            <Tabs
                              value={value}
                              onChange={(event,newValue) => handleChange(event,newValue,props.values)}
                              indicatorColor="primary"
                              textColor="primary"
                              variant="scrollable"
                              scrollButtons="auto"
                              aria-label="scrollable auto tabs example"
                              className={classes.TabsGeneral}
                            >
                              <Tab label="Genel Bilgiler" {...a11yProps(0)} style={{ fontWeight: 600 }} />
                              <Tab label="Ekip" {...a11yProps(1)} style={{ fontWeight: 600 }} />
                              <Tab label="Ürün & Pazar" {...a11yProps(2)} style={{ fontWeight: 600 }} />
                              <Tab label="Finans" {...a11yProps(3)} style={{ fontWeight: 600 }} />
                              <Tab label="Duyurular" {...a11yProps(4)} style={{ fontWeight: 600 }} />
                            </Tabs>
                          </AppBar>
                          {data && (
                            <>
                              <TabPanel value={value} index={0}>
                                <Grid className={classes.tabGrid} item xs={12}>
                                  <GeneralTab {...props} data={data} detail={detail} edit={edit} />
                                </Grid>
                              </TabPanel>
                              <TabPanel value={value} index={1}>
                                <Grid className={classes.tabGrid} item xs={12}>
                                  <DetailTab {...props} data={data} detail={detail} edit={edit} />
                                </Grid>
                              </TabPanel>
                              <TabPanel value={value} index={2}>
                                <Grid className={classes.tabGrid} item xs={12}>
                                  <ProductTab
                                    {...props}
                                    detail={detail}
                                    data={data}
                                    edit={edit}
                                    valuation={valuation}
                                    investmentAmount={investmentAmount}
                                    date={date}
                                    volume={volume}
                                    numberOfTransactions={numberOfTransactions}
                                    sumTraction={sumTraction}
                                    dateTraction={dateTraction}
                                  />
                                </Grid>
                              </TabPanel>
                              <TabPanel value={value} index={3}>
                                <Grid className={classes.tabGrid} item xs={12}>
                                  <FinanceTab
                                    {...props}
                                    detail={detail}
                                    edit={edit}
                                    data={data}
                                    valuation={valuation}
                                    investmentAmount={investmentAmount}
                                    date={date}
                                    volume={volume}
                                    numberOfTransactions={numberOfTransactions}
                                    sumTraction={sumTraction}
                                    dateTraction={dateTraction}
                                  />
                                </Grid>
                              </TabPanel>
                              <TabPanel value={value} index={4}>
                                <Grid className={classes.tabGrid} item xs={12}>
                                  <AnnouncementTab {...props} detail={detail} edit={edit} />
                                </Grid>
                              </TabPanel>
                            </>
                          )}
                        </Grid>
                        {data.getStartupDraftDetail.startupDraft.status === 'Pending' && (
                          <>
                            {edit && (
                              <Grid container justify="flex-end" alignItems="center" style={{ marginBottom: 80 }}>
                                {edit && (
                                  <>
                                    {value === 4 && (
                                      <Grid container justify="flex-end" className={classes.bottomBar}>
                                        <Button
                                          variant="outlinedPrimary"
                                          className={classes.editButton2}
                                          style={{
                                            fontWeight: 'normal',
                                            fontSize: 14,
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                          onClick={() => (setValue(value - 1), window.scrollTo(0, 0))}
                                        >
                                          {'Geri'}
                                        </Button>
                                        <Button
                                          variant="containedPrimary"
                                          className={classes.editButton2}
                                          style={{ fontWeight: 'normal', fontSize: 14 }}
                                          onClick={() => {
                                            handleSubmit2(props.values);
                                            setStatus('Ready');
                                            popupFunction();
                                          }}
                                        >
                                          {'Kaydet'}
                                        </Button>
                                      </Grid>
                                    )}
                                    {value === 0 && (
                                      <Grid container justify="flex-end" className={classes.bottomBar}>
                                        <Button
                                          variant="containedPrimary"
                                          className={classes.editButton2}
                                          style={{ fontWeight: 'normal', fontSize: 14 }}
                                          onClick={() => {
                                            handleSubmit2(props.values);
                                            setStatus('Ready');
                                            window.scrollTo(0, 0);
                                          }}
                                        >
                                          {'İlerle'}
                                        </Button>
                                      </Grid>
                                    )}
                                    {value != 4 &&
                                      value != 0 &&
                                      (submitting ? (
                                        <Button
                                          variant="containedPrimary"
                                          className={classes.editButton2}
                                          disabled
                                          style={{ fontWeight: 'normal' }}
                                        >
                                          <CircularProgress
                                            style={{
                                              color: '#ffffff',
                                              width: '25px',
                                              height: '25px',
                                              marginRight: '10px',
                                              marginBottom: 0,
                                            }}
                                          />{' '}
                                          Yükleniyor
                                        </Button>
                                      ) : (
                                        <Grid container justify="flex-end" className={classes.bottomBar}>
                                          <Button
                                            variant="outlinedPrimary"
                                            className={classes.editButton2}
                                            style={{
                                              fontWeight: 'normal',
                                              fontSize: 14,
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                            onClick={() => (setValue(value - 1), window.scrollTo(0, 0))}
                                          >
                                            {'Geri'}
                                          </Button>
                                          <Button
                                            variant="containedPrimary"
                                            className={classes.editButton2}
                                            style={{ fontWeight: 'normal', fontSize: 14 }}
                                            onClick={() => {
                                              handleSubmit2(props.values);
                                              setStatus('Ready');
                                              window.scrollTo(0, 0);
                                            }}
                                          >
                                            {'İlerle'}
                                          </Button>
                                        </Grid>
                                      ))}
                                  </>
                                )}
                              </Grid>
                            )}
                          </>
                        )}
                        <Dialog
                          style={{ padding: 20 }}
                          fullWidth={true}
                          maxWidth="xs"
                          classes={{ paper: classes.myDialog }}
                          onClose={() => handleCloseCompletePopup()}
                          aria-labelledby="simple-dialog-title"
                          open={openCompletePopup}
                        >
                          <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                            <img
                              src={newCross}
                              alt="newCross"
                              onClick={() => handleCloseCompletePopup()}
                              style={{ cursor: 'pointer' }}
                            />
                          </Grid>
                          <Grid
                            container
                            style={{
                              width: '100%',
                              height: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                width: '116px',
                                height: '116px',
                                backgroundColor: '#F0F5FF',
                                borderRadius: '100%',
                              }}
                            ></div>{' '}
                            <svg
                              style={{ height: '42px', position: 'absolute' }}
                              viewBox="0 0 46 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M30.5408 7.19828L38.7913 15.4323M34.0407 3.70533C35.1348 2.61342 36.6187 2 38.166 2C39.7133 2 41.1972 2.61342 42.2913 3.70533C43.3853 4.79723 44 6.27817 44 7.82235C44 9.36653 43.3853 10.8475 42.2913 11.9394L10.1665 44H2V35.6821L34.0407 3.70533Z"
                                stroke="#003CA5"
                                stroke-width="4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Grid>

                          <DialogTitle id="simple-dialog-title">
                            <Typography
                              align="center"
                              style={{
                                paddingLeft: 32,
                                paddingRight: 32,
                                fontWeight: 600,
                                fontSize: '28px',
                                lineHeight: '38px',
                                textAlign: 'center',
                                color: '#27272E',
                              }}
                            >
                              {t('Düzenlemeyi Tamamla')}
                            </Typography>
                          </DialogTitle>
                          <Typography
                            align="center"
                            style={{
                              paddingLeft: 32,
                              paddingRight: 32,
                              fontWeight: 100,
                              fontSize: '16px',
                              lineHeight: '24px',
                              textAlign: 'center',
                              color: '#425466',
                            }}
                          >
                            {t('Düzenlemenizi tamamlayarak onaya göndermek istiyor musunuz?')}
                          </Typography>
                          <Grid container style={{ marginTop: 15, marginBottom: 40 }}>
                            <Grid
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              xs={12}
                              md={6}
                            >
                              <Button
                                style={{ minWidth: '70%', marginTop: 15 }}
                                onClick={() => handleCloseCompletePopup()}
                                variant="outlinedSecondary"
                              >
                                Geri Dön
                              </Button>
                            </Grid>
                            <Grid
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              xs={12}
                              md={6}
                            >
                              <Button
                                style={{ minWidth: '70%', marginTop: 15 }}
                                onClick={() => {
                                  setStatus('');
                                  handleSubmit2(props.values);
                                  setStatus('Ready');
                                  handleSubmit(props.values);
                                }}
                                variant="containedPrimary"
                              >
                                Gönder
                              </Button>
                            </Grid>
                          </Grid>
                        </Dialog>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>

              <Dialog
                style={{ padding: 20 }}
                fullWidth={true}
                maxWidth="xs"
                classes={{ paper: classes.myDialog }}
                onClose={() => handleCloseCompletePopup()}
                aria-labelledby="simple-dialog-title"
                open={false}
              >
                <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                  <img
                    src={newCross}
                    alt="newCross"
                    onClick={() => handleCloseCompletePopup()}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{ width: '116px', height: '116px', backgroundColor: '#F0F5FF', borderRadius: '100%' }}
                  ></div>{' '}
                  <svg
                    style={{ height: '42px', position: 'absolute' }}
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.5408 7.19828L38.7913 15.4323M34.0407 3.70533C35.1348 2.61342 36.6187 2 38.166 2C39.7133 2 41.1972 2.61342 42.2913 3.70533C43.3853 4.79723 44 6.27817 44 7.82235C44 9.36653 43.3853 10.8475 42.2913 11.9394L10.1665 44H2V35.6821L34.0407 3.70533Z"
                      stroke="#003CA5"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>

                <DialogTitle id="simple-dialog-title">
                  <Typography
                    align="center"
                    style={{
                      paddingLeft: 32,
                      paddingRight: 32,
                      fontWeight: 600,
                      fontSize: '28px',
                      lineHeight: '38px',
                      textAlign: 'center',
                      color: '#27272E',
                    }}
                  >
                    {t('Düzenlemeye devam et')}
                  </Typography>
                </DialogTitle>
                <Typography
                  align="center"
                  style={{
                    paddingLeft: 32,
                    paddingRight: 32,
                    fontWeight: 100,
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#425466',
                  }}
                >
                  {t('Önceki düzenleme talebiniz daha onaylanmadı. Taslak profili düzenlemeye devam edebilirsiniz.')}
                </Typography>
                <Grid container style={{ marginTop: 15, marginBottom: 40 }}>
                  <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={12} md={6}>
                    <Button
                      style={{ minWidth: '70%', marginTop: 15 }}
                      onClick={() => handleCloseCompletePopup()}
                      variant="outlinedSecondary"
                    >
                      Vazgeç
                    </Button>
                  </Grid>
                  <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={12} md={6}>
                    <Button
                      style={{ minWidth: '70%', marginTop: 15 }}
                      onClick={() => toEdit()}
                      variant="containedPrimary"
                    >
                      Düzenle
                    </Button>
                  </Grid>
                </Grid>
              </Dialog>

              {/* <Dialog
                style={{ padding: 20 }}
                fullWidth={true}
                maxWidth="xs"
                classes={{ paper: classes.myDialog }}
                onClose={() => handleCloseExitPopup()}
                aria-labelledby="simple-dialog-title"
                open={false}
              >
                <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                  <img
                    src={newCross}
                    alt="newCross"
                    onClick={() => handleCloseExitPopup()}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '116px',
                      height: '116px',
                      backgroundColor: 'rgba(246, 142, 135, 0.15)',
                      borderRadius: '100%',
                    }}
                  ></div>{' '}
                  <svg
                    style={{ height: '42px', position: 'absolute' }}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.3333 28.6667L24 24M24 24L28.6667 19.3333M24 24L19.3333 19.3333M24 24L28.6667 28.6667M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                      stroke="#F16063"
                      stroke-width="4.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>

                <DialogTitle id="simple-dialog-title">
                  <Typography
                    align="center"
                    style={{
                      paddingLeft: 32,
                      paddingRight: 32,
                      fontWeight: 600,
                      fontSize: '28px',
                      lineHeight: '38px',
                      textAlign: 'center',
                      color: '#27272E',
                    }}
                  >
                    {t('Kaydetmeden çıkıyorsunuz')}
                  </Typography>
                </DialogTitle>
                <Typography
                  align="center"
                  style={{
                    paddingLeft: 32,
                    paddingRight: 32,
                    fontWeight: 100,
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#425466',
                  }}
                >
                  {t('Düzenlemenizi tamamlayarak onaya göndermek istiyor musunuz?')}
                </Typography>
                <Grid container style={{ marginTop: 15, marginBottom: 40 }}>
                  <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={12} md={6}>
                    <Button
                      style={{ minWidth: '70%', marginTop: 15 }}
                      onClick={() => handleCloseExitPopup()}
                      variant="outlinedSecondary"
                      //className={classes.editButton}
                    >
                      Çık
                    </Button>
                  </Grid>
                  <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={12} md={6}>
                    <Button
                      style={{ minWidth: '70%', marginTop: 15 }}
                      onClick={() => toEdit()}
                      variant="containedPrimary"
                      //className={classes.editButton}
                    >
                      Düzenle
                    </Button>
                  </Grid>
                </Grid>
              </Dialog> */}

              {/* low fill rate popup */}
              <Dialog
                style={{ padding: 20 }}
                fullWidth={true}
                maxWidth="xs"
                classes={{ paper: classes.myDialog }}
                onClose={() => handleCloseFillRatePopup()}
                aria-labelledby="simple-dialog-title"
                open={openLowFillRate}
              >
                <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
                  <img
                    src={newCross}
                    alt="newCross"
                    onClick={() => handleCloseFillRatePopup()}
                    style={{ cursor: 'pointer' }}
                  />
                </Grid>
                <Grid
                  container
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{ width: '116px', height: '116px', backgroundColor: '#F0F5FF', borderRadius: '100%' }}
                  ></div>{' '}
                  <svg
                    style={{ height: '42px', position: 'absolute' }}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1987 17C16.4797 14.2817 19.9353 12.3333 24 12.3333C29.1567 12.3333 33.3333 15.467 33.3333 19.3333C33.3333 22.6 30.3513 25.3417 26.3193 26.1163C25.0547 26.359 24 27.3763 24 28.6667M24 35.6667H24.0233M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                      stroke="#003CA5"
                      stroke-width="4.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Grid>

                <DialogTitle id="simple-dialog-title">
                  <Typography
                    align="center"
                    style={{
                      paddingLeft: 32,
                      paddingRight: 32,
                      fontWeight: 600,
                      fontSize: '28px',
                      lineHeight: '38px',
                      textAlign: 'center',
                      color: '#27272E',
                    }}
                  >
                    {t('Profil doluluk oranı düşük')}
                  </Typography>
                </DialogTitle>
                <Typography
                  align="center"
                  style={{
                    paddingLeft: 32,
                    paddingRight: 32,
                    fontWeight: 100,
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: '#425466',
                  }}
                >
                  {t('Listelenmek için en az %50 doluluk oranına ulaşmalısınız.')}
                </Typography>
                <Grid container spacing={0} style={{ marginTop: 15, marginBottom: 40, paddingLeft: 0 }}>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={12} md={6}>
                    <Button
                      style={{ minWidth: '70%', marginTop: 15 }}
                      onClick={() => lowFillRatePopuptoCompletePopup()}
                      variant="outlinedSecondary"
                    >
                      Devam Et
                    </Button>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={12} md={6}>
                    <Button
                      style={{ minWidth: '70%', marginTop: 15 }}
                      onClick={() => handleCloseFillRatePopup()}
                      variant="containedPrimary"
                    >
                      Geri Dön
                    </Button>
                  </Grid>
                </Grid>
              </Dialog>

              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setIsComment(false)}
                open={isComment}
              >
                <Alert severity="success">Yorumunuz yönetici onayına gönderildi.</Alert>
              </Snackbar>
              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setIsSent(false)}
                open={isSent}
              >
                <Alert severity="success">Şirket taslağınız onaya gönderildi.</Alert>
              </Snackbar>
              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setFillRate(false)}
                open={fillRate}
              >
                <Alert severity="info" style={{ backgroundColor: '#003CA5', color: '#fff' }}>
                  Profilinizi doldurmak yatırımcılar tarafınıdan görünülebilirliğinizi yüksek oranda artırır.
                </Alert>
              </Snackbar>
              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setShowAdminErrorMessage(false)}
                open={showAdminErrorMessage}
              >
                <Alert severity="error">{adminErrorMessage}</Alert>
              </Snackbar>
            </Grid>
          )}
        </main>
      )}
    </>
  );
}
