
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import { DebounceInput } from 'react-debounce-input';
import editSvg from '../../static/img/new.svg';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField, } from 'formik';
import FormikTextField from "../../Components/FormikTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
    marginTop: 35
  },
  TabsGeneral: {
    background: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingLeft: 30
  },
  Debounce: {
    width: '100%',
    maxWidth: '180px',
    fontSize: 16,
    height: '35px',
    color: '#8898aa',
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid #c8c8ca',
    background: 'inherit',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  multiSelect: {
    marginBottom: 15,
    '& div': {
      background: 'none!important',
      paddingTop: '0!important',
      '& input': {
        paddingTop: '8px!important'
      }
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color: '#8898aa',
    },
  },
  filterArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  ButtonMobile: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  filter: {
    height: 40,
    padding: '0 15px',
    border: '1px solid rgb(221 221 221)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: '20px',
    },
  },
  myPaper: {
    padding: 25,
    width: '650px'
  },
  myLabel: {
    fontSize: 14
  },
  formControl: {
    marginTop: 10
  },
  myCheckbox: {
    padding: '5px 9px'
  },
  whiteCard: {
    background: '#ffffff',
    borderRadius: '4px',
    padding: 25,
    marginBottom: 50,
  },
  tabPaper: {
    marginTop: 50,
  },
  sticker: {
    marginRight: 10,
    color: '#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize: 11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

const FormikGroupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        style={{ marginTop: 10 }}
        {...props}
        {...field}
        options={props.options}
        getOptionLabel={(option) => option.type}
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props} label="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
      >
      </Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}

function AddGroupDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch } = props;

  const handleSubmit = variables => {
    props.createFAQGroup({ variables })
      .then(data => {
        if (data.data.createFAQGroup) {
          refetch()
          onClose(null);
        }
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">Group Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              type: '',
              order: '',
            }}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {props => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12}>
                      <Typography variant="h6" style={{ width: '100%' }}>Grup Adı</Typography>
                      <FormikTextField name="type" style={{ width: '100%', marginBottom: 20 }} type="text" placeholder="Grup Adı" />
                    </Grid>
                    <Grid item container xs={12} style={{ height: 'fit-content' }}>
                      <Typography variant="h6" style={{ width: '100%' }}>Grup Sırası</Typography>
                      <FormikTextField name="order" style={{ width: '100%', marginBottom: 20 }} type="number" placeholder="Grup Sırası" />
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button variant="containedPrimary" style={{ fontWeight: 'normal', width: '100%' }} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddGroupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddFAQDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, groups } = props;

  const handleSubmit = variables => {
    variables.type = variables.type.id
    props.createFAQ({ variables })
      .then(data => {
        if (data.data.createFAQ) {
          refetch()
          onClose(null);
        }
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="md" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">Soru Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              question: '',
              type: '',
              answer: '',
              order: ''
            }}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {props => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" style={{ width: '100%' }}>Grup</Typography>
                        <Field component={FormikGroupDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="type" options={groups}></Field>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" style={{ width: '100%' }}>Soru Sırası</Typography>
                        <FormikTextField name="order" style={{ width: '100%', marginBottom: 20 }} type="number" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={{ width: '100%' }}>Soru Metni</Typography>
                        <FormikTextField name="question" style={{ width: '100%', marginBottom: 20 }} type="text" placeholder="Soru Metni" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={{ width: '100%' }}>Cevap Metni</Typography>
                        <FormikMultiLine name="answer" rows={4} type="text" placeholder="Cevap Metni.." />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button variant="containedPrimary" style={{ fontWeight: 'normal', width: '100%' }} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddFAQDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditGroupDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, group } = props;

  const handleSubmit = variables => {
    props.editFAQGroup({ variables })
      .then(data => {
        if (data.data.editFAQGroup) {
          refetch()
          onClose(null);
        }
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">Grup Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              type: group.type,
              language: 'tr',
              groupId: group.id,
              order: group.order,
            }}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {props => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12}>
                      <Typography variant="h6" style={{ width: '100%' }}>Grup Adı</Typography>
                      <FormikTextField name="type" style={{ width: '100%', marginBottom: 20 }} type="text" placeholder="Grup Adı" />
                    </Grid>
                    <Grid item container xs={12} style={{ height: 'fit-content' }}>
                      <Typography variant="h6" style={{ width: '100%' }}>Grup Sırası</Typography>
                      <FormikTextField name="order" style={{ width: '100%', marginBottom: 20 }} type="number" placeholder="Grup Sırası" />
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button variant="containedPrimary" style={{ fontWeight: 'normal', width: '100%' }} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditGroupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditFAQDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, groups, faq } = props;

  const handleSubmit = variables => {
    variables.type = variables.type.id
    props.editFAQ({ variables })
      .then(data => {
        if (data.data.editFAQ) {
          refetch()
          onClose(null);
        }
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="md" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">Soru Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              faqId: faq.id,
              question: faq.question,
              type: faq.type,
              language: 'tr',
              answer: faq.answer,
              order: faq.order
            }}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {props => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" style={{ width: '100%' }}>Grup</Typography>
                        <Field component={FormikGroupDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="type" options={groups}></Field>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" style={{ width: '100%' }}>Soru Sırası</Typography>
                        <FormikTextField name="order" style={{ width: '100%', marginBottom: 20 }} type="number" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={{ width: '100%' }}>Soru Metni</Typography>
                        <FormikTextField name="question" style={{ width: '100%', marginBottom: 20 }} type="text" placeholder="Soru Metni" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" style={{ width: '100%' }}>Cevap Metni</Typography>
                        <FormikMultiLine name="answer" rows={4} type="text" placeholder="Cevap Metni.." />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                        <Button variant="containedPrimary" style={{ fontWeight: 'normal', width: '100%' }} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditFAQDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteGroupDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, group } = props;

  const handleSubmit = () => {
    props.deleteFAQGroup({ variables: { groupId: group.id } })
      .then(data => {
        if (data.data.deleteFAQGroup) {
          refetch()
          onClose(null);
        }
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {group.questionCount === 0 ?
        <>
          <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
            <Typography align="center" variant="h4">Group Sil</Typography>
          </DialogTitle>
          <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
            <Grid container justify="space-between" xs={9}>
              <Typography align="center" variant="body1" style={{ color: '#777777' }}>Görüntüleme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
              <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
                <Grid item xs={12} md={6}>
                  <Button style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20, background: '#ff5757', "&:hover": { background: "#ff5757" } }} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
        :
        <>
          <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
            <Typography align="center" variant="h4">Silinemiyor!</Typography>
          </DialogTitle>
          <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
            <Grid container justify="center" xs={9}>
              <Typography align="center" variant="body1" style={{ color: '#777777' }}>Bu grubu silemezsin çünkü
            gruba eklenmiş sorular var!</Typography>
              <Grid item xs={6}>
                <Button style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </Dialog>
  );
}

DeleteGroupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteFAQDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, faq } = props;

  const handleSubmit = () => {
    props.deleteFAQ({ variables: { faqId: faq.id } })
      .then(data => {
        if (data.data.deleteFAQ) {
          refetch()
          onClose(null);
        }
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h4">Soru Sil</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Typography align="center" variant="body1" style={{ color: '#777777' }}>Görüntüleme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
          <Grid container xs={12} spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12} md={6}>
              <Button style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20 }} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginTop: 20, background: '#ff5757', "&:hover": { background: "#ff5757" } }} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

DeleteFAQDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function ExpandingRow(props) {
  const classes = useStyles();
  const [showEditFaq, setShowEditFaq] = React.useState(false);
  const [showDeleteFaq, setShowDeleteFaq] = React.useState(false);

  const { faq, groups, index } = props;
  return (
    <>
      {faq &&
        <StyledTableRow key={index + 1}>
          <StyledTableCell component="th" scope="row">
            <Typography variant="h6" style={{ fontSize: 14 }}>{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left"><Typography variant="h6" style={{ fontSize: 14 }}>{faq.question}</Typography></StyledTableCell>
          <StyledTableCell align="left"><Typography variant="body2">{faq.answer}</Typography></StyledTableCell>
          {faq.submitBy && <StyledTableCell align="left" style={{ minWidth: 245 }}><Typography variant="h6">{faq.submitBy.firstName} {faq.submitBy.lastName}</Typography></StyledTableCell>}
          <StyledTableCell align="left" style={{ minWidth: 115 }}><Typography variant="h6">{faq.type && faq.type.type}</Typography></StyledTableCell>
          <StyledTableCell align="left" style={{ minWidth: 160 }}><Typography variant="body2">{moment(faq.submitDate).format('DD-MM-YYYY')}</Typography></StyledTableCell>
          <StyledTableCell align="left" style={{ paddingLeft: 10, minWidth: 75 }}>
            <Grid container alignItems="center">
              <Grid item style={{ cursor: 'pointer' }} onClick={() => setShowEditFaq(true)}><img src={editSvg} style={{ width: 20, height: 19, marginRight: 5 }} alt="sticker" className={classes.sticker} /></Grid>
              <Grid item style={{ cursor: 'pointer' }} onClick={() => setShowDeleteFaq(true)}><ClearIcon alt="sticker" style={{ marginRight: 5, fontSize: 25 }} className={classes.sticker} /></Grid>
            </Grid>
          </StyledTableCell>
          <EditFAQDialog editFAQ={props.editFAQ} faq={faq} groups={groups} refetch={props.refetch} open={showEditFaq} onClose={() => setShowEditFaq(false)} />
          <DeleteFAQDialog deleteFAQ={props.deleteFAQ} faq={faq} refetch={props.refetch} open={showDeleteFaq} onClose={() => setShowDeleteFaq(false)} />
        </StyledTableRow>
      }
    </>
  )
}

function ExpandingRowGroup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showEditGroup, setShowEditGroup] = React.useState(false);
  const [showDeleteGroup, setShowDeleteGroup] = React.useState(false);

  const { group } = props;
  return (
    <>
      <StyledTableRow key={group.id}>
        <StyledTableCell component="th" scope="row">
          <Typography variant="h6" style={{ fontSize: 14 }}>{group.type}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{ fontSize: 14 }}>{group.questionCount}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{group.order}</Typography></StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid style={{ cursor: 'pointer' }} onClick={() => (props.setType(group.id), props.setValue(0))}><VisibilityOutlinedIcon alt="sticker" style={{ marginRight: 10, fontSize: 25 }} className={classes.sticker} /></Grid>
            <Grid style={{ cursor: 'pointer' }} onClick={() => setShowEditGroup(true)}><img src={editSvg} style={{ width: 20, height: 19, marginRight: 5 }} alt="sticker" className={classes.sticker} /></Grid>
            <Grid style={{ cursor: 'pointer' }} onClick={() => setShowDeleteGroup(true)}><ClearIcon alt="sticker" style={{ marginRight: 5, fontSize: 25 }} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        <EditGroupDialog editFAQGroup={props.editFAQGroup} group={group} refetch={props.refetchGroup} open={showEditGroup} onClose={() => setShowEditGroup(false)} />
        <DeleteGroupDialog deleteFAQGroup={props.deleteFAQGroup} group={group} refetch={props.refetchGroup} open={showDeleteGroup} onClose={() => setShowDeleteGroup(false)} />
      </StyledTableRow>
    </>
  )
}

export default function AdminFAQ(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(6);
  const [faqs, setFaq] = React.useState();
  const [groups, setGroups] = React.useState();
  const [type, setType] = React.useState();
  const [search, setSearch] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addGroup, setAddGroup] = React.useState(false);
  const [addFaq, setAddFaq,] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const GET_FAQ = gql`
    query getFAQ($type: Int, $page: Int, $pageSize: Int, $search: String) {
      getFAQ( type:$type, pagination:{page:$page, pageSize:$pageSize}, search:$search){
        count
        pageCount
        faq{
          id
          answerDate
          submitDate
          order
          submitBy{
            firstName
            lastName
          }
          question
          answer
          type{
            id
            type
          }
        }
        }
    }
    `;
  const { loading, data, refetch } = useQuery(GET_FAQ, {
    fetchPolicy: 'network-only',
    variables: { page: page, pageSize: pageSize, type: type, search: search },
  });

  const GET_GROUP = gql`
    query getFAQGroups($page: Int, $pageSize: Int) {
      getFAQGroups(pagination:{page:$page, pageSize:$pageSize}){
        count
        pageCount
        groups{
          id
          type
          order
          questionCount
        }
        }
    }
    `;
  const { loading: loadingGroup, data: dataGroup, refetch: refetchGroup } = useQuery(GET_GROUP, {
    fetchPolicy: "network-only",
    variables: { page: 1, pageSize: 10 },
  });
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setFaq(data.getFAQ.faq);
        setPageCount(data.getFAQ.pageCount)
      }
    }
    if (!loadingGroup) {
      if (dataGroup) {
        setGroups(dataGroup.getFAQGroups.groups);
      }
    }
  },
    [data, loading, dataGroup, loadingGroup])

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setPage(value);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid className={"main-container"} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
            <Grid item style={{ width: '100%' }}>
              <Grid
                container
                justify="space-between"
                alignItems="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <HelpOutlineIcon style={{ fontSize: 20, marginTop: 5 }} />
                  <Typography variant="h4">Sıkça Sorulan Sorular</Typography>
                </Breadcrumbs>
                <Grid item className={classes.filterArea}>
                  {value === 0 &&
                    <Hidden smDown>
                      <DebounceInput
                        class="inputTypeSearch"
                        name="search"
                        value={search}
                        placeholder="Ara..."
                        className={classes.Debounce}
                        minLength={2}
                        debounceTimeout={800}
                        onChange={handleSearch} />
                    </Hidden>
                  }
                  <Button className={classes.ButtonMobile} style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', marginRight: 15 }} onClick={() => setAddFaq(true)} variant="containedSecondary"><AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} /> Yeni SSS Ekle </Button>
                  <Button className={classes.ButtonMobile} style={{ fontWeight: 'normal', padding: '11.5px 15px', fontSize: 14, width: '100%', minWidth: 155 }} onClick={() => setAddGroup(true)} variant="containedPrimary"><AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} /> Yeni Grup Ekle </Button>
                  <AddGroupDialog createFAQGroup={props.createFAQGroup} refetch={refetchGroup} open={addGroup} onClose={() => setAddGroup(false)} />
                  <AddFAQDialog createFAQ={props.createFAQ} groups={groups} refetch={refetch} open={addFaq} onClose={() => setAddFaq(false)} />

                </Grid>
              </Grid>
              {faqs &&
                <Grid container xs={12}>
                  <AppBar style={{ zIndex: 0 }} className={classes.tabPaper} position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="rgb(247, 247, 252)"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className={classes.TabsGeneral}
                    >
                      <Tab label="Sorular" {...a11yProps(0)} style={{ fontWeight: 600 }} />
                      <Tab label="Gruplar" {...a11yProps(1)} style={{ fontWeight: 600 }} />
                    </Tabs>
                  </AppBar>
                  <TabPanel style={{ width: '100%' }} value={value} index={0}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">#</StyledTableCell>
                              <StyledTableCell><Typography variant="h6" color="primary"> Soru </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Cevap </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Yazar </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Grup Adı </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Son Güncelleme </Typography></StyledTableCell>
                              <StyledTableCell align="left"></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {faqs &&
                            <TableBody>
                              {faqs.map((faq, index) => (
                                <ExpandingRow index={index} faq={faq} groups={groups} deleteFAQ={props.deleteFAQ} editFAQ={props.editFAQ} refetch={refetch} />
                              ))}
                            </TableBody>
                          }
                        </Table>
                      </TableContainer>
                      <Grid container justify="flex-end">
                        <Grid item style={{ marginTop: 10, padding: 0 }} justify="flex-end">
                          <Pagination count={pageCount} page={page} onChange={handlePageChange}
                            variant="outlined" color="primary" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={1}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell><Typography variant="h6" color="primary"> Grup Adı </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Gruptaki Soru Sayısı </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Grup Sıralaması </Typography></StyledTableCell>
                              <StyledTableCell align="left"><Typography variant="h6" color="primary"> Aksiyonlar </Typography></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {groups &&
                            <TableBody>
                              {groups.map((group) => (
                                <ExpandingRowGroup setValue={setValue} refetchGroup={refetchGroup} setType={setType} deleteFAQGroup={props.deleteFAQGroup} editFAQGroup={props.editFAQGroup} group={group} />
                              ))}
                            </TableBody>
                          }
                        </Table>
                      </TableContainer>
                    </Grid>
                  </TabPanel>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  )
}