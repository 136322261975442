import { makeStyles, withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#425466',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
}));

function CompleteProfileStage({ startup }) {
  const [progress, setProgress] = React.useState(0);
  const classes = useStyles();
  React.useEffect(() => {
    function circleProgress() {
      setProgress((prevCompleted) => (prevCompleted = 60));
    }

    const timer = setInterval(circleProgress, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}
      >
        <Grid item align="center" jutfiy="center" xs={12}>
          <div style={{ position: 'relative', height: 126, width: 126 }}>
            <CircularProgress
              variant="static"
              color="primary"
              thickness="5"
              size="126px"
              value={startup?.profileFillRate || localStorage.getItem('profileFillRate') || 0}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '28px',
                  lineHeight: '42px',
                  textAlign: 'center',
                  color: '#003CA5',
                }}
                variant="caption"
                /*component="div"*/ color="textSecondary"
              >
                {'%' + startup?.profileFillRate || localStorage.getItem('profileFillRate') || 0}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid item align="center" xs={12}>
          <Typography
            style={{
              fontWeight: '600',
              fontSize: '28px',
              lineHeight: '38px',
              textAlign: 'center',
              color: '#27272E',
            }}
            variant="caption"
            /*component="div"*/ color="textSecondary"
          >
            {'Şirket Bilgilerini Tamamla'}
          </Typography>
        </Grid>
        <Grid item align="center" xs={12}>
          <Typography className={classes.subtitle} color="textSecondary">
            {
              'Yatırım turunda olan girişimler alanında yer almak için şirket profilinizin %60’ı dolu olması gerekmektedir.'
            }
          </Typography>
        </Grid>
        <Grid item align="center" sm={12}>
          <Link to={`/girisim/${startup?.slug}`}>
            <Button
              variant="containedPrimary"
              style={{ borderRadius: 6, fontSize: 12, fontWeight: '600', padding: '13px 24px' }}
            >
              Profilini Doldur
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default CompleteProfileStage;
