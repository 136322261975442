
import React, { useRef } from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Link} from 'react-router-dom'
import get from 'lodash/get';
import Button from "@material-ui/core/Button";
import { Form, Formik, Field, useField, FieldArray, useFormikContext } from 'formik';
import FormikTextField from "../../Components/FormikShortTextField";
import FormikDropdown from "../../Components/FormikDropdown";
import moment from 'moment'
import 'moment/locale/tr'
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import { DndProvider , useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {stateToHTML} from 'draft-js-export-html'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { EditorState } from 'draft-js';
import { RichEditor } from "../../Components/RichEditor/RichEditor";
import ClearIcon from '@material-ui/icons/Clear';
import { stateFromHTML } from 'draft-js-import-html'
import { RichEditorReadOnly } from "../../Components/RichEditorReadOnly/RichEditorReadOnly.js";
import {Line} from 'react-chartjs-2';
import { reportEditSchema } from '../../yupSchemas';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { reportSchema } from '../../yupSchemas';
import Info from '../../static/icon/info.svg';
import PropTypes from 'prop-types';
import FormikSelect from '../../Components/FormikSelect';
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import newCross from '../../static/icon/new-cross.svg';
import {getCurrentPeriod} from '../../helpers';
registerLocale("tr", tr);

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  multiSelect2: {
    marginBottom:15,
    '& div': {
      background: 'none!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  whiteCard:{
    padding:35,
    background:'#fff',
    borderRadius: 4,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.16)',
    marginBottom:25
  },
  AddButton:{
    padding: '12.5px 0px',
    display:'flex',
    justifyContent:'space-around',
    minWidth:175,
    fontSize:16,
    marginRight:10,
    marginTop:15,
    fontWeight:'normal'
},
  editButton: {
    fontWeight:'normal',
    [theme.breakpoints.up('sm')]: {
      marginRight:25
    },
    [theme.breakpoints.down('md')]: {
      marginTop:30,
    }, 
  }
}));

const FormikGroupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10,minHeight:80}}
          {...props}
          {...field}
          multiple
          disableCloseOnSelect
          options={props.options}
          getOptionLabel={(option) => option.title}
          hiddenLabel="true"
          className={classes.multiSelect2}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
        autocomplete="off"
        style={{
          width:'100%',
          padding:'13px',
          borderRadius: 3,
          border:'1px solid rgb(221 221 221 / 56%)',
          boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
          marginTop:10
        }}
        type="text"
        placeholder={'mm'} />
      }
      maxDate={getCurrentPeriod(props.dataFrequency).date}
      showMonthYearPicker
      dateFormat="yyyy/MM"
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

const FormikPersonDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10,minHeight:80}}
          {...props}
          {...field}
          multiple
          disableCloseOnSelect
          options={props.options}
          getOptionLabel={(option) => option.getName}
          className={classes.multiSelect2}
          hiddenLabel="true"
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

function SelectPart(props){
  const classes = useStyles();
  const [list, setList] = React.useState();
  const [kpis, setKpis] = React.useState();
  const [listValue, setListValue] = React.useState();

    const GET_LIST = gql`
    query startupReportGetKPIList($listId: ID) {
      startupReportGetKPIList(listId:$listId){
        lists{
          id
          title
        }
        kpis{
          id
          title
        }
      }
    }
    `;

    const { loading, data } = useQuery(GET_LIST, {
        fetchPolicy:'network-only',
        variables: {listId: listValue ? listValue.id : ''},
    });
    React.useEffect(() => {
      if(!loading){
          if(data){
            let a =[]
            setList(data.startupReportGetKPIList.lists);
            setKpis(data.startupReportGetKPIList.kpis);
          }
      }
    },
    [data, loading])

  return (
    <Grid container xs={12} spacing={3}>
      {list &&
      <Grid item xs={12} sm={6}>
        <Autocomplete
          style={{ marginTop: 10, background: '#fff' }}
          options={list}
          noOptionsText={'Sonuç Bulunamadı..'}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect2}
          value={listValue}
          onChange={(_, value) => setListValue(value)}
          renderInput={(props) => <TextField {...props} placeholder="Liste Seçiniz" variant="outlined" />}
        ></Autocomplete>
      </Grid>
      }
      {kpis && 
      <Grid item xs={12} sm={6}>      
        <Field component={FormikKPIDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`content.${props.index}.kpi`} options={kpis}></Field>
      </Grid>
      }
    </Grid>
  );
}

const FormikKPIDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10, background:'#fff'}}
          {...props}
          {...field}
          options={props.options}
          noOptionsText={'Sonuç Bulunamadı..'}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect2}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

          {meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}


const GraphComponent = ({ con, ...props }) => {
  const classes = useStyles();
  const [graphDataState, setGraphData] = React.useState([]);
  const [graphDateState, setGraphDate] = React.useState([]);

  if (graphDataState.length === 0){
    let graphData = [];
    con.kpiData.forEach((data) => {
      graphData.push(data.reached);
    });
    setGraphData(graphData);
  }

  if (graphDateState.length === 0){
    let graphDate = [];
    con.kpiData.forEach((data) => {
      graphDate.push(moment(parseInt(data.date)).format('Do MMMM YYYY'));
    });  
    setGraphDate(graphDate);
  }

  return (
    <>
    {(graphDataState.length > 0 && graphDateState.length)  &&
        <Grid item xs={12} md={6} style={{padding:25}}>
          <Line
            options={{
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: false,
                      borderDashOffset: 1,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      borderDash: [2, 2],
                      lineWidth: 2,
                      drawBorder: false,
                    },
                  },
                ],
              },
            }}
            data={{
              labels: graphDateState,
              datasets: [
                {
                  label: 'Alınan Yatırım Miktarı',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: '#172b4d',
                  borderColor: '#ffba00',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#172b4d',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#172b4d',
                  pointHoverBorderColor: '#172b4d',
                  pointHoverBorderWidth: 1,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: graphDataState,
                },
              ],
            }}
          />                    
        </Grid>
      }
  </>
  );
}


const TextCard = ({ id, block, index, moveCard, setFieldValue, handleBlur, ...props }) => {
  const style = {
    border: '1px dashed #003CA5',
    padding: '30px 40px',
    marginBottom: '15px',
    backgroundColor: 'initial',
};

const ItemTypes = {
  CARD: 'card',
}
  const ref = useRef(null);
  const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover(item, monitor) {
          if (!ref.current) {
              return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
              return;
          }
          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();
          // Get vertical middle
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          // Determine mouse position
          const clientOffset = monitor.getClientOffset();
          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return;
          }
          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return;
          }
          // Time to actually perform the action
          moveCard(dragIndex, hoverIndex);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex;
      },
  });
  const [{ isDragging }, drag] = useDrag({
      item: { type: ItemTypes.CARD, id, index },
      collect: (monitor) => ({
          isDragging: monitor.isDragging(),
      }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid item xs={12} ref={ref} style={{ ...style, opacity }}>
      <Typography variant="h5" color="primary">
        Metin Bloğu
      </Typography>
      <FormikTextField name={`content.${index}.title`} type="text" placeholder="Rapor Adı" />
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <RichEditor editorState={block.text} onChange={setFieldValue} name={`content.${index}.text`} onBlur={handleBlur} />
      </Grid>
    </Grid>
  );
}
function RemoveDialog(props) {
  const classes = useStyles();
  const { onClose, open,  id } = props;

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Vazgeç</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Taslak kaydedilmedi. Vazgeçmek girmiş olduğunuz bilgilerin silinmesine neden olacaktır. Devam etmek istediğinizden emini misiniz? </Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => {onClose(null)}} variant="outlinedSecondary">Hayır</Button>
              </Grid>
              <Grid item xs={12} md={6}>
              {id && 
              <>
                            <Link
                              style={{ padding: 0, marginRight: 20 }}
                              to={{
                                pathname: `/rapor-detay/${id}`,
                              }}
                            >
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} variant="containedPrimary">Evet</Button>
                </Link>
                </>
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Dialog>
  );
}

RemoveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
function EditReportDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch ,groups, people, reportList } = props;
  const daysOptions = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
  const yesNo = ['Evet', 'Hayır'];
  const reminderOptions = ['Tek seferlik', 'Haftalık', 'Aylık', 'Çeyreklik', 'Yıllık'];
  const reminderDetails = {
    'Tek seferlik': [],
    'Haftalık':[
      "Her haftanın ilk iş günü",
      "Her haftanın son iş günü",
      "Her haftanın son günü",
      "Haftanın belirli bir günü"
    ],
    'Aylık':[
      "Her ayın ilk haftası",
      "Her ayın 15. günü",
      "Her ayın son haftası"
    ],
    'Çeyreklik':[
      "Her çeyreğin ilk ayı",
      "Her çeyreğin ikinci ayı",
      "Her çeyreğin son ayı"
    ],
    'Yıllık':[]
  };

  const handleSubmit = variables => {
    let tempGr = []
    let tempPr = []

    variables.groupIds.forEach(element => {
      tempGr.push(element.id)
    });
    variables.groupIds = tempGr

    variables.personIds.forEach(element => {
      tempPr.push(element.id)
    });
    variables.personIds = tempPr
      props.startupReportListEdit({ variables })
      .then(data => {
        if (data.data.startupReportListEdit) {
          refetch()
          props.setEdit(true)
          onClose(null);
        } 
      }).then(() => {})
      .catch((error) => {
      });
  };


  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="flex-end" style={{padding:'30px 30px 0 0'}}>
        <img src={newCross} alt="newCross" onClick={() => onClose(null)} style={{ cursor:'pointer' }}/>
      </Grid>
      <DialogTitle id="simple-dialog-title">
        <Typography align="center" variant="h4" style={{marginBottom:20}}>
          Rapor Düzenle
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginBottom: 50 }} xs={12}>
        <Grid container justify="space-between" xs={9}>
          <Formik
            initialValues={{
              id:reportList.id,
              title: reportList ? reportList.title : '',
              startDate: reportList ? moment(parseInt(reportList.startDate)).format('YYYY-MM') : '',
              dataFrequency: reportList ? reportList.dataFrequency : '',
              isPublic: reportList ? reportList.isPublic : 'Hayır',
              personIds: reportList ? reportList.persons : [],
              groupIds: reportList ? reportList.groups : [],
            }}
            validationSchema={reportSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid xs={12} justify="center">
                  <Grid item container alignItems="flex-end">
                    <Grid item container xs={12} style={{marginBottom:15}}>
                      <Typography variant="h6" style={{ width: '100%' }}>
                        Rapor Adı
                      </Typography>
                      <FormikTextField
                        name="title"
                        style={{ width: '100%', marginBottom: 20 }}
                        type="text"
                        placeholder="Set Adı"
                      />
                    </Grid>
                    <Grid item container xs={12} style={{marginBottom:25}}>
                      <Typography variant="h6" style={{ display:'flex' }}>
                        Gönderim Sıklığı<img src={Info} alt="info" style={{width:'18px',marginLeft:5}}/>
                      </Typography>
                      <Field
                        style={{width:'100%',marginTop:10}}
                        component={FormikDropdown}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="dataFrequency"
                        options={reminderOptions}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} style={{marginBottom:30}}>
                      <Typography variant="h6" style={{ display:'flex' }}>
                        Veri Başlangıç Tarihi <img src={Info} alt="info" style={{width:'18px',marginLeft:5}}/>
                      </Typography>
                      <DatePickerField
                        name={'startDate'}
                        dataFrequency={props.values.dataFrequency}
                      />
                    </Grid>
                    {people && (
                        <Grid xs={12} style={{marginBottom:35}} item>
                          <Typography variant="h6">
                            Gönderilecek Kişiler
                          </Typography>
                          <Field
                            component={FormikPersonDropdown}
                            style={{ background: '#fff', marginTop: 10, width:'100%' }}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="personIds"
                            options={people}
                          ></Field>
                        </Grid>
                      )}
                      {groups && (
                        <Grid xs={12} style={{marginBottom:35}} item>
                          <Typography variant="h6">
                            Gönderilecek Gruplar
                          </Typography>
                          <Field
                            component={FormikGroupDropdown}
                            style={{ background: '#fff', marginTop: 10 }}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="groupIds"
                            options={groups}
                          ></Field>
                        </Grid>
                      )}
                    <Grid>
                      <Typography align="left" variant="h6">
                        Bu raporu herkese açık hale getirmek ister misin?
                      </Typography>
                      <FormikSelect
                        name="isPublic"
                        options={yesNo}
                      ></FormikSelect>
                    </Grid>
                    <Grid item container xs={12} justify="center" style={{ marginBottom: 20, height: 'fit-content' }}>
                      <Button
                        variant="containedPrimary"
                        style={{ fontWeight: 'normal', width: '100%' }}
                        disabled={!props.isValid} 
                        onClick={() => handleSubmit(props.values)}
                      >
                        Düzenle
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditReportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function ReportEdit(props){
    const classes = useStyles();
    const [list, setList] = React.useState();
    const [detail, setDetail] = React.useState();
    const [id, setId] = React.useState();
    const [create, setCreate] = React.useState(true);
    const [editor, setEditor] = React.useState(EditorState.createEmpty());
    const [editReport, setEditReport] = React.useState(false);
    const [drag, setDrag] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [groups, setGroups] = React.useState();
    const [people, setPeople] = React.useState();
    const [remove, setRemove] = React.useState(false);
    

    const style = {
      border: '1px dashed #003CA5',
      padding: '30px 40px',
      marginBottom: '15px',
      backgroundColor: 'initial',
    };

    const style2 = {
      border: '1px dashed #ffba00',
      padding: '30px 40px',
      marginBottom: '15px',
      backgroundColor: 'initial',
    };
       
  const GET_DETAIL = gql`
  query startupReportDetail($id:ID!) {
    startupReportDetail(id:$id){
      id
      title
      status
      updatedAt
      sendingText
      content{
        type
        title
        text
        kpi{
            id
            title
        }
        kpiData{
          date
          reached
        }
      }
      list{
        id
        title
        groups{
          id
          title
        }
        persons{
          id
          getName
        }
        isPublic
        dataFrequency
        startDate
        nextPostDate
        updatedAt
        reminderFrequency
      }
    }
  }
  `;

  const { loading, data, refetch } = useQuery(GET_DETAIL, {
      fetchPolicy:'network-only',
      variables: {id:props.match.params.id},
  });

  const GET_GROUPS = gql`
  query getStartupGroupsSearch {
    getStartupGroupsSearch{
      id
      title
    }
  }
  `;

  const { loading:loadingGroup, data:dataGroup} = useQuery(GET_GROUPS, {
      fetchPolicy:'network-only',
      variables: {},
  });

  const GET_PEOPLE = gql`
  query getStartupPersonsSearch {
    getStartupPersonsSearch{
      id
      getName
    }
  }
  `;
    const { loading:loadingPeople, data:dataPeople} = useQuery(GET_PEOPLE, {
      fetchPolicy:'network-only',
      variables: {},
  });

    React.useEffect(() => {
      if(!loading){
        if(data){
          data.startupReportDetail.content.forEach(con => {
            if (con.type === 'text') {
              if (con.text) {
                let htmlText = con.text;
                if (typeof con.text === 'string') {
                  con.text = EditorState.createWithContent(stateFromHTML(htmlText));
                }
              } else {
                con.text = new EditorState.createEmpty();
              }
            }
          })
          setList(data.startupReportDetail.list);
          setDetail(data.startupReportDetail)
          setId(data.startupReportDetail.id)
        }
      }
      if(!loadingPeople){
        if(dataPeople){
          setPeople(dataPeople.getStartupPersonsSearch);
        }
      }
      if(!loadingGroup){
        if(dataGroup){
          setGroups(dataGroup.getStartupGroupsSearch);
        }
      }
    },
    [loading, data ,loadingGroup, dataGroup, loadingPeople, dataPeople])

    const handleSubmit = variables => {
      let content = []
      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
        let c = {title:element.title, text:element.text, type:element.type, kpi:element.kpi }
        content.push(c)
      });

      props.startupReportPreview({ variables:{content:content, sendingText:variables.sendingText, listId:variables.listId} })
        .then(data => {
          if (data.data.startupReportPreview) {
            variables.content.forEach(element => {
              if(element.type === 'text'){
                element.text = EditorState.createWithContent(stateFromHTML(element.text));
              }
            });
            data.data.startupReportPreview.content && data.data.startupReportPreview.content.forEach(con => {
              if (con.type === 'text') {
                if (con.text) {
                  let htmlText = con.text;
                  if (typeof con.text === 'string') {
                    con.text = EditorState.createWithContent(stateFromHTML(htmlText));
                  }
                } else {
                  con.text = new EditorState.createEmpty();
                }
              }
            })
            setDetail(data.data.startupReportPreview);
            if(create){
              setCreate(false)
            }           
          } 
        }).then(() => {})
        .catch((error) => {
        });
    };

    const handleDraft = variables => {
      let content = []
      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
        let c = {title:element.title, text:element.text, type:element.type, kpi:element.kpi }
        content.push(c)
      });
      props.startupReportEdit({ variables:{content:content, status:'Draft', id:id, sendingText:variables.sendingText, listId:variables.listId} })
        .then(data => {
          if (data.data.startupReportEdit) {
            props.history.push('/raporlarim')
          } 
        }).then(() => {})
        .catch((error) => {
        });
    };

    const handleShare = variables => {
      let content = []
      variables.content.forEach(element => {
        if(element.type === 'text'){
          element.text = stateToHTML(element.text.getCurrentContent())
        }
        else 
        {
          element.text = null
          delete element.kpi.__typename
        }
        let c = {title:element.title, text:element.text, type:element.type, kpi:element.kpi }
        content.push(c)
      });
      props.startupReportEdit({ variables:{content:content, status:'Sent', id:id, sendingText:variables.sendingText, listId:variables.listId} })
        .then(data => {
          if (data.data.startupReportEdit) {
            props.history.push('/raporlarim')
          } 
        }).then(() => {})
        .catch((error) => {
        });
    };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Snackbar autoHideDuration={5000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setDrag(false)} open={drag} >
              <Alert severity="success">Taslak kaydedildi. </Alert>
            </Snackbar>
            <Snackbar autoHideDuration={5000} anchorOrigin={{vertical: 'top', horizontal:'center'}} onClose={() => setEdit(false)} open={edit} >
              <Alert severity="success">Yaptığınız değişiklikler kaydedildi.</Alert>
            </Snackbar>
            {list && (
              <>
              <EditReportDialog open={editReport} setEdit={setEdit} reportList={list} startupReportListEdit={props.startupReportListEdit} onClose={() => setEditReport(false)} refetch={refetch} groups={groups} people={people} />
              <Formik
                initialValues={{
                  listId: list.id,
                  sendingText: detail && detail.sendingText ? detail.sendingText : '',
                  content: detail && detail.content ? detail.content : [],
                }}
                validationSchema={reportEditSchema}
              >
                {(props) => (
                  <Grid
                    className={'main-container'}
                    xs={12}
                    style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
                  >
                    <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 50 }}>
                      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                        {detail && detail.title}
                      </Typography>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        {create ? (
                          <>
                          {id && 
                          <>
                              <Button
                                style={{ fontWeight: 'normal', fontSize: 14, width: '100%', marginRight: 20 }}
                                variant="outlinedSecondary"
                                onClick={() => setRemove(true)}
                              >
                                Vazgeç
                              </Button>
                              <RemoveDialog id={id} open={remove} onClose={() => setRemove(false)} />
                            </>
                            }
                            <Button
                              variant="outlinedSecondary"
                              onClick={() => {
                                handleDraft(props.values)}}
                              disabled={!props.isValid} 
                              style={{ fontWeight: 'normal', fontSize: 14, minWidth: 190, marginRight: 20, padding:'11.5px 40px' }}
                            >
                              Taslak Kaydet
                            </Button>
                            <Button
                              variant="containedPrimary"
                              onClick={() => handleSubmit(props.values)}
                              disabled={!props.isValid} 
                              style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170, padding:'11.5px 40px' }}
                            >
                              Ön İzleme
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlinedSecondary"
                              onClick={() => setCreate(true)}
                              style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170, marginRight: 20 }}
                            >
                              Düzenle
                            </Button>
                            <Button
                              variant="outlinedSecondary"
                              onClick={() => handleDraft(props.values)}
                              disabled={!props.isValid} 
                              style={{ fontWeight: 'normal', fontSize: 14, minWidth: 190, marginRight: 20, padding:'11.5px 40px' }}
                            >
                              Taslak Kaydet
                            </Button>
                            <Button
                              variant="containedPrimary"
                              onClick={() => handleShare(props.values)}
                              disabled={!props.isValid} 
                              style={{ fontWeight: 'normal', fontSize: 14, minWidth: 170 }}
                            >
                              Paylaş
                            </Button>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Form style={{ width: '100%' }}>
                      {list && (
                        <Grid xs={12} container direction="column" className={classes.whiteCard}>
                          <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h5">Rapor Seçenekleri</Typography>
                            <Button
                              variant="outlinedPrimary"
                              onClick={() => setEditReport(true)}
                              style={{ fontWeight: 'normal', fontSize: 14, padding:'10px 20px' }}
                            >
                              Düzenle
                            </Button>
                          </Grid>
                          <Grid xs={12} container style={{ marginTop: 25 }}>
                            <Grid item xs={6} md={2}>
                              <Typography variant="h6">Gönderim Sıklığı</Typography>
                              <Typography variant="body2" style={{ marginTop: 10 }}>
                                {list.dataFrequency}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <Typography variant="h6">Başlangıç Tarihi</Typography>
                              <Typography variant="body2" style={{ marginTop: 10 }}>
                                {moment(parseInt(list.startDate)).format('MMMM YYYY')}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <Typography variant="h6">Gönderilenler</Typography>
                              <Typography variant="body2" style={{ marginTop: 10 }}>
                                {list.groups &&
                                  list.groups.map((group, index) =>
                                    list.groups.length === index + 1 ? group.title : group.title + ', ',
                                  )}
                                  {list.persons.length > 0 && list.groups.length > 0  && ', '}
                                  {list.persons &&
                                  list.persons.map((person, index) =>
                                    list.persons.length === index + 1 ? person.getName : person.getName + ', ',
                                  )}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                              <Typography variant="h6">Rapor Durumu</Typography>
                              {list.isPublic === 'Evet' ? (
                                <Typography style={{ marginTop: 10 }} variant="body2">
                                  Herkese Açık
                                </Typography>
                              ): (
                                <Typography style={{ marginTop: 10 }} variant="body2">
                                  Herkese Kapalı
                                </Typography>
                              ) }
                            </Grid>
                            {!create && (
                              <Grid xs={12} container style={{ marginTop: 25 }}>
                                <Grid item xs={12}>
                                  <Typography variant="h6">Gönderim Metni</Typography>
                                  <Typography variant="body2" style={{ marginTop: 10 }}>
                                    {detail.sendingText}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {create ? (
                        <Grid xs={12} container spacing={3}>
                          <Grid xs={12} item style={{ marginTop: 15 }}>
                            <Typography variant="h6">
                              Gönderim metni
                            </Typography>
                            <FormikMultiLine
                              style={{ background: '#fff', width: '100%', marginBottom: 20 }}
                              name="sendingText"
                              rows={4}
                              type="text"
                              placeholder="Açıklama.."
                            />
                          </Grid>
                          <Grid container xs={12} style={{ padding: 12 }}>
                            <FieldArray
                              name="content"
                              render={({ insert, remove, push, unshift, form }) => (
                                <>
                                  <Grid item style={{ display: 'flex', marginBottom: 20 }}>
                                    <Button
                                      variant="containedPrimary"
                                      onClick={() => push({ title: '', text: editor, type: 'text' })}
                                      className={classes.AddButton}
                                    >
                                      Metin Ekle
                                    </Button>
                                    <Button
                                      variant="outlinedPrimary"
                                      style={{
                                        borderColor: '#ffba00',
                                        background: '#fff',
                                        marginLeft: 20,
                                        color: '#ffba00',
                                      }}
                                      onClick={() => push({ title: '', text: editor, kpi: '', type: 'graph' })}
                                      className={classes.AddButton}
                                    >
                                      Veri Bloğu Ekle
                                    </Button>
                                  </Grid>
                                  {form.values.content.length > 0 && (
                                    <>
                                      {form.values.content.map((block, index) => (
                                        <>
                                          {block.type === 'text' ? (
                                            <Grid item xs={12} style={{ ...style }}>
                                              <Grid container justify="space-between">
                                                <Typography variant="h5" color="primary">
                                                  Metin Bloğu
                                                </Typography>
                                                <ClearIcon
                                                  onClick={() => remove(index)}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: 20,
                                                    marginTop: -15,
                                                    marginRight: -30,
                                                  }}
                                                />
                                              </Grid>
                                              <FormikTextField
                                                name={`content.${index}.title`}
                                                type="text"
                                                placeholder="Metin Başlığı giriniz"
                                              />
                                              <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                <RichEditor
                                                  editorState={block.text}
                                                  onChange={props.setFieldValue}
                                                  name={`content.${index}.text`}
                                                  onBlur={props.handleBlur}
                                                />
                                              </Grid>
                                            </Grid>
                                          ) : (
                                            <Grid item xs={12} style={{ ...style2 }}>
                                              <Grid container justify="space-between">
                                                <Typography
                                                  variant="h5"
                                                  color="primary"
                                                  style={{ fontWeight: 500, color: '#ffba00' }}
                                                >
                                                  Veri Bloğu
                                                </Typography>
                                                <ClearIcon
                                                  onClick={() => remove(index)}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: 20,
                                                    marginTop: -15,
                                                    marginRight: -30,
                                                  }}
                                                />
                                              </Grid>
                                              <FormikTextField
                                                name={`content.${index}.title`}
                                                type="text"
                                                style={{ fontWeight: 500 }}
                                                placeholder="Veri Bloğu Başlığı giriniz"
                                              />
                                              <SelectPart index={index} />
                                            </Grid>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid xs={12} container style={{ marginTop: 30 }} className={classes.whiteCard}>
                          <Typography variant="h5" style={{ width: '100%', marginBottom: 20 }}>
                            Rapor İçeriği
                          </Typography>
                          {detail && detail.content && (
                            <>
                              {detail.content.map((con) =>
                                con.type === 'text' ? (
                                  <Grid item xs={12}>
                                    <Typography variant="h6">{con.title}</Typography>
                                    <RichEditorReadOnly editorState={con.text} />
                                  </Grid>
                                ) : (
                                  con && con.kpiData.length > 0 &&
                                  <GraphComponent con={con} />
                                ),
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                    </Form>
                  </Grid>
                )}
              </Formik>
              </>
            )}
          </Grid>
        </main>
      </>
    );
}