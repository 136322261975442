import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import StartupShare from './StartupShare';

const CREATE_SECONDARY_SHARE_REQUEST = gql`
  mutation createSecondaryShareRequest(
    $requestType: String!
    $onListedStartup: Boolean!
    $startupId: ID
    $currencySymbol: String!
    $contactPreference: String!
    $minAmount: Int!
    $maxAmount: Int!
    $companyName: String
    $companyWebsite: String
    $companyAuthorityFullname: String
    $companyAuthorityEmail: String
  ) {
    createSecondaryShareRequest(
      requestType: $requestType
      onListedStartup: $onListedStartup
      startupId: $startupId
      currencySymbol: $currencySymbol
      contactPreference: $contactPreference
      minAmount: $minAmount
      maxAmount: $maxAmount
      companyName: $companyName
      companyWebsite: $companyWebsite
      companyAuthorityFullname: $companyAuthorityFullname
      companyAuthorityEmail: $companyAuthorityEmail
    ) {
      id
    }
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_SECONDARY_SHARE_REQUEST, {
    name: 'createSecondaryShareRequest',
  }),
)(StartupShare);
export default JoinWithMutation;