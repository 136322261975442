import AdminFAQ from './AdminFAQ'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const CREATE_FAQ_GROUP = gql`
  mutation createFAQGroup($type: String!, $order: Int!) {
    createFAQGroup(type: $type, order: $order) 
  }
`;

const EDIT_FAQ_GROUP = gql`
  mutation editFAQGroup($type: String!, $order: Int!, $language: String!, $groupId: ID!) {
    editFAQGroup(type: $type, order: $order, language: $language, groupId: $groupId) 
  }
`;

const DELETE_FAQ_GROUP = gql`
  mutation deleteFAQGroup($groupId: ID!) {
    deleteFAQGroup(groupId: $groupId) 
  }
`;

const CREATE_FAQ = gql`
  mutation createFAQ($type: Int, $order: Int, $question: String!, $answer: String!) {
    createFAQ(type: $type, order: $order, question: $question, answer: $answer ) 
  }
`;

const EDIT_FAQ = gql`
  mutation editFAQ($type: Int, $order: Int, $question: String!, $answer: String!, $language: String!, $faqId: ID!) {
    editFAQ(type: $type, order: $order, question: $question, answer: $answer, language: $language, faqId: $faqId ) 
  }
`;

const DELETE_FAQ = gql`
  mutation deleteFAQ($faqId: ID!) {
    deleteFAQ(faqId: $faqId) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_FAQ_GROUP, {
    name: 'createFAQGroup',
  }),
  graphql(EDIT_FAQ_GROUP, {
    name: 'editFAQGroup',
  }),
  graphql(DELETE_FAQ_GROUP, {
    name: 'deleteFAQGroup',
  }),
  graphql(CREATE_FAQ, {
    name: 'createFAQ',
  }),
  graphql(EDIT_FAQ, {
    name: 'editFAQ',
  }),
  graphql(DELETE_FAQ, {
    name: 'deleteFAQ',
  }),
)(AdminFAQ);
export default JoinWithMutation;