let monthQuarterDict = { 1: '3', 2: '6', 3: '9', 4: '12' };
function getKPIDateFormat(i, startDate, dataFrequency) {
  let date = null;
  switch (dataFrequency) {
    case 'Yıllık':
      date = new Date(startDate.getFullYear() + '-01-01');
      date.setFullYear(date.getFullYear() + i);
      date.setHours(0, 0, 0, 0);
      date.setSeconds(-1);
      break;
    case 'Günlük':
      date = new Date(startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate());
      date.setDate(date.getDate() + i);
      break;
    case 'Haftalık':
      let weekStart = startDate;
      let day = weekStart.getDay() || 7; // Get current day number, converting Sun. to 7
      if (day !== 1)
        // Only manipulate the date if it isn't Mon.
        weekStart.setHours(-24 * (day - 1)); // Set the hours to day number minus 1
      date = new Date(weekStart.getFullYear() + '-' + (weekStart.getMonth() + 1) + '-' + weekStart.getDate());
      date.setHours(24 * 7 * i);
      break;
    case 'Çeyreklik':
      let quarter = Math.floor((startDate.getMonth() + 3) / 3);
      date = new Date(startDate.getFullYear() + '-' + monthQuarterDict[quarter] + '-01');
      date.setMonth(date.getMonth() + i * 3);
      break;
    default:
      date = new Date(startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-01');
      date.setMonth(date.getMonth() + i);
      break;
  }
  return date;
}
function getCurrentPeriod(dataFrequency) {
  let beginning;
  let title;
  if (dataFrequency === 'Haftalık') {
    beginning = getKPIDateFormat(0, new Date(), dataFrequency);
    beginning.setSeconds(-1);
    let newDate = new Date(beginning.toString());
    newDate.setHours(24 * -6);
    title = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();
  } else if (dataFrequency === 'Aylık') {
    // 18 aralık -> 2020-11-30T23:59:59.000Z
    beginning = getKPIDateFormat(0, new Date(), dataFrequency);
    beginning.setSeconds(-1);
    title = beginning.getMonth() + 1 + '/' + beginning.getFullYear();
  } else if (dataFrequency === 'Çeyreklik') {
    beginning = getKPIDateFormat(-1, new Date(), dataFrequency);
    beginning.setMonth(beginning.getMonth() + 1);
    beginning.setSeconds(-1);
    title = beginning.getMonth() + 1 + '/' + beginning.getFullYear();
  } else if (dataFrequency === 'Yıllık') {
    beginning = getKPIDateFormat(0, new Date(), dataFrequency);
    beginning.setSeconds(-1);
    title = beginning.getFullYear();
  } else if (dataFrequency === 'Tek seferlik') {
    beginning = new Date();
    title = beginning.getMonth() + 1 + '/' + beginning.getFullYear();
  }
  return { date: beginning, title: title };
}

const resolveStatusText = (text) => {
  switch (text) {
    case 'Active':
      return 'Aktif';
    case 'Rejected':
      return 'Reddedildi';
    case 'Pending':
      return 'Onay Bekleyen';
    case 'Finished':
      return 'Tamamlandı';
  }
};

const resolveStatusColor = (text) => {
  switch (text) {
    case 'Active':
      return '#66CB9F';
    case 'Rejected':
      return '#F16063';
    case 'Pending':
      return '#003CA5';
    case 'Finished':
      return '#718096';
  }
};

const resolveCurrencySymbol = (currency) => {
  switch (currency) {
    case 'Türk Lirası':
    case '₺':
      return '₺';
    case 'İngiliz Sterlini':
    case '£':
      return '£';
    case 'ABD Doları':
    case '$':
      return '$';
    case 'Euro':
    case '€':
      return '€';
    default:
      return '';
  }
};

const resolveCurrencyName = (currency) => {
  switch (currency) {
    case '₺':
      return 'Türk Lirası';
    case '£':
      return 'İngiliz Sterlini';
    case '$':
      return 'ABD Doları';
    case '€':
      return 'Euro';
    default:
      return '';
  }
};

const getFormattedDateFromUnix = (unixTimestamp) => {
  const dateObject = new Date(unixTimestamp * 1);
  return dateObject.toLocaleString('tr-TR').slice(0, -9).replaceAll('.', '/'); //remove clock
};

const formatDashedDate = (date) => {
  if (!date) return '';
  if (date.split('/').length > 1) return date;

  const dateArr = date.split('-');
  const day = dateArr[2];
  dateArr[2] = dateArr[0];
  dateArr[0] = day;
  return dateArr.join('/');
};

const getClosingDate = (sprintClosingDate) => {
  /* for closing date on investment tab */
  let today = new Date();
  let closingDay = sprintClosingDate;
  closingDay = closingDay + ' 00:00:00';
  //let closingDay = closingDay.replace(/\//g, ',');
  closingDay = closingDay.replace(/-/g, ',');
  closingDay = closingDay.replace(/\//g, ',');
  let day = closingDay.slice(8, 10);
  let month = closingDay.slice(5, 7);
  month = parseInt(month);
  let year = closingDay.slice(0, 4);
  let ttt = new Date(year, month - 1, day);

  let diff = (ttt - today) / 1000;
  diff = Math.abs(Math.floor(diff));

  let years = Math.floor(diff / (365 * 24 * 60 * 60));
  let leftSec = diff - years * 365 * 24 * 60 * 60;

  month = Math.floor(leftSec / ((365 / 12) * 24 * 60 * 60));
  leftSec = leftSec - month * (365 / 12) * 24 * 60 * 60;

  let days = Math.floor(leftSec / (24 * 60 * 60));
  leftSec = leftSec - days * 24 * 60 * 60;

  let hrs = Math.floor(leftSec / (60 * 60));
  leftSec = leftSec - hrs * 60 * 60;

  let min = Math.floor(leftSec / 60);
  leftSec = leftSec - min * 60;

  return {
    days: days,
    min: min,
    hrs: hrs,
    ttt: ttt,
    today: today,
  };
};

function numberWithPeriods(x) {
  if (!x) return x;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}



module.exports = {
  getCurrentPeriod: getCurrentPeriod,
  formatDashedDate: formatDashedDate,
  resolveStatusText: resolveStatusText,
  resolveStatusColor: resolveStatusColor,
  resolveCurrencySymbol: resolveCurrencySymbol,
  resolveCurrencyName: resolveCurrencyName,
  getFormattedDateFromUnix: getFormattedDateFromUnix,
  getClosingDate: getClosingDate,
  numberWithPeriods: numberWithPeriods,
};
