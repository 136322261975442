import {
  ADMIN_EDIT_INVESTMENT_SPRINT_STAGE_2,
  ADMIN_HANDLE_INVESTMENT_SPRINT_ISFEATURED,
  ADMIN_HANDLE_INVESTMENT_SPRINT_STATUS,
  CREATE_INVESTMENT_SPRINT_STAGE_2,
} from '../../mutations';
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { resolveStatusColor, resolveStatusText } from '../../helpers';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { ADMIN_GET_CREATE_INVESTMENT_SPRINT_DETAIL } from '../../queries';
import { AntSwitch } from '../../Components/AntSwitch';
import FormSteps from './FormSteps/FormSteps';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import newBag from '../../static/icon/new-bag.svg';
import newCross from '../../static/icon/new-cross.svg';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  editButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      marginRight: 25,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
  contentContainer: {
    marginTop: 36,
    marginBottom: 100,
    backgroundColor: '#ffffff',
    borderRadius: 16,
    padding: '25px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '25px 50px',
    },
  },
  stepText: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
    fontSize: '14px',
  },
  innerContainer: {
    margin: 10,
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
    display: 'flex',
    width: '100%',
    minHeight: 610,
    borderRadius: 16,
  },
  splitterLine: {
    marginTop: 20,
    height: 0,
    width: '100%',
    border: '2px solid #EDF2F7',
  },
  formFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 999,
    background: '#fff',
    padding: '8px 70px',
    boxShadow: '0px -4px 4px #F7F7FC;',
    height: 60,

    /*width: '90%',
    height: 60,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF',
    top: 'calc( 100vh - 60px )',
    zIndex: '999',*/
  },
  statusTextMobile: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      display: 'inline-block',
    },
  },
  footerApproveButtons: {
    position: 'fixed',
    left: '80%',
    [theme.breakpoints.down('lg')]: {
      left: '70%',
    },
    [theme.breakpoints.down('md')]: {
      left: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      left: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      left: '40%',
    },
  },
  footerButtons: {
    position: 'fixed',
    left: '80%',
    [theme.breakpoints.down('lg')]: {
      left: '80%',
    },
    [theme.breakpoints.down('md')]: {
      left: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      left: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      left: '40%',
    },
  },
  dialogButton: {
    margin: '0px 24px 46px 24px',
    paddingLeft: 8,
    paddingRight: 8,
    width: 148,
  },
}));

function ApplyForInvestment({ createSprintData, isAdmin, startup, ...rest }) {
  //states and constants
  const { id: startupId } = useParams();
  const classes = useStyles();
  const [statusPopupOpen, setStatusPopupOpen] = React.useState(false);
  const [formStep, setFormStep] = React.useState(0);
  const [statusChangeSelect, setStatusChangeSelect] = React.useState(null);
  const [doneStepsArray, setDoneStepsArray] = React.useState([false, false, false, false]);
  const [registerNewspaperDocs, setRegisterNewspaperDocs] = React.useState([]);
  const [mainContractDocs, setMainContractDocs] = React.useState([]);
  const [taxPlateDocs, setTaxPlateDocs] = React.useState([]);
  const [operatingCertificateDocs, setOperatingCertificateDocs] = React.useState([]);
  const [userCompletedProfile, setUserCompletedProfile] = React.useState(
    startup?.profileFillRate >= 60 || localStorage.getItem('profileFillRate') >= 60 || false,
  );
  const [succesfullyEditedSnackbar, setSuccesfullyEditedSnackbar] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [disableNextButton, setDisableNextButton] = React.useState(false);
  const formikRef = React.useRef(null);

  //Queries and Mutations
  const { data: adminData } = useQuery(ADMIN_GET_CREATE_INVESTMENT_SPRINT_DETAIL, {
    variables: { startupId: startupId },
    skip: !isAdmin,
  });
  const [createSprintStage2] = useMutation(CREATE_INVESTMENT_SPRINT_STAGE_2);
  const [adminEditSprintStage2] = useMutation(ADMIN_EDIT_INVESTMENT_SPRINT_STAGE_2);

  const [adminHandleStatus] = useMutation(ADMIN_HANDLE_INVESTMENT_SPRINT_STATUS);
  const [adminHandleIsFeatured] = useMutation(ADMIN_HANDLE_INVESTMENT_SPRINT_ISFEATURED);
  //

  const formData = isAdmin ? adminData?.adminGetCreateInvestmentSprintDetail : createSprintData;

  React.useEffect(() => {
    if (userCompletedProfile && formStep === 0) setFormStep(1);
  }, []);

  React.useEffect(() => {
    if (formData) {
      setRegisterNewspaperDocs(formData?.registerNewspaperDocs || []);
      setMainContractDocs(formData?.mainContractDocs || []);
      setTaxPlateDocs(formData?.taxPlateDocs || []);
      setOperatingCertificateDocs(formData?.operatingCertificateDocs || []);

      setStatusChangeSelect(formData?.status);

      let finishedStageInteger = parseInt(formData.finishedStage?.split(' ')[1]) || 0;

      //also completed last step, so add one to finishedStageInteger
      if (formData.finishedStage == 'Stage 2') finishedStageInteger = 3;

      //also completed last step, so add one to finishedStageInteger
      if (formData.status === 'Finished' && userCompletedProfile) finishedStageInteger = 0;

      //set current step to completed step + 1
      setFormStep(finishedStageInteger + 1);

      //if is admin always start from 1, and not go to 3(managed in handleStage2Submit)
      if (isAdmin) {
        setFormStep(1);
        finishedStageInteger = 1;
      }

      //done steps array is used as a state for visual purposes
      setDoneStepsArray(doneStepsArray.map((e, idx) => idx <= finishedStageInteger));
    }
  }, [formData]);

  //handlers and helpers
  async function handleStatusChangeSubmit(status) {
    const { error } = await adminHandleStatus({ variables: { sprintId: formData?.id, status: status } });
    if (error) console.log(error);
    setStatusPopupOpen(false);
  }
  async function handleStage1Submit() {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }

    if (isAdmin) setSuccesfullyEditedSnackbar(true);
  }
  async function handleStage2Submit() {
    const vars = {
      sprintId: formData?.id,
    };

    //dont submit the unchanged files (they brake the form)
    if (registerNewspaperDocs[0]?.file !== formData?.registerNewspaperDocs[0]?.file) {
      vars.registerNewspaperDocs = registerNewspaperDocs;
    }

    if (mainContractDocs[0]?.file !== formData?.mainContractDocs[0]?.file) {
      vars.mainContractDocs = mainContractDocs;
    }

    if (taxPlateDocs[0]?.file !== formData?.taxPlateDocs[0]?.file) {
      vars.taxPlateDocs = taxPlateDocs;
    }

    if (operatingCertificateDocs[0]?.file !== formData?.operatingCertificateDocs[0]?.file) {
      vars.operatingCertificateDocs = operatingCertificateDocs;
    }

    if (!isAdmin) vars.agreement = true;

    let usedMutation = createSprintStage2;
    if (isAdmin) usedMutation = adminEditSprintStage2;

    const { error } = await usedMutation({
      variables: vars,
    });

    if (error) console.log(error);

    //go to approve stage if not admin
    if (!isAdmin) setFormStep(4);
    else setSuccesfullyEditedSnackbar(true);
  }
  const resolveTabColors = (idx) => {
    if (doneStepsArray[idx]) return '#118DFF';
    if (formStep === idx) return '#003CA5';
    return '#718096';
  };

  const resolveAdvanceButtonFunction = (formStep) => {
    if (formStep === 1) return handleStage1Submit;
    if (formStep === 2 && isAdmin) return handleStage2Submit;
    if (formStep === 3) return handleStage2Submit;

    return () => setFormStep(formStep + 1);
  };

  const resolveAdvanceButtonText = (formStep) => {
    if (formStep === 3) return 'Onayla';
    if (isAdmin && formStep === 2) return 'Düzenle';
    return 'İleri';
  };
  const handleClose = () => {
    setStatusPopupOpen(false);
  };

  const toggleIsFeatured = () => {
    const { error } = adminHandleIsFeatured({
      variables: { sprintId: formData?.id, isFeatured: !formData?.isFeatured },
    });
    if (error) console.error(error);
  };
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: get(rest, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
      })}
    >
      <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
        <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
          <Grid item style={{ width: '100%' }}>
            <Grid container justify="space-between" alignItems="center" style={{ padding: '0 10px' }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <img src={newBag} style={{ width: 16 }} alt="bag" />
                <Link to="/girisimler" style={{ padding: 0 }}>
                  <Typography variant="h5" style={{ color: '#718096' }}>
                    Yatırım Turu
                  </Typography>
                </Link>
                <Typography variant="h5">Tur Detayı</Typography>
              </Breadcrumbs>
              {isAdmin && (
                <Grid item justify="center" alignItems="center" style={{ display: 'flex', padding: '0 10px' }}>
                  <Typography
                    className={classes.statusTextMobile}
                    variant="h4"
                    align="left"
                    style={{
                      marginRight: 15,
                      fontSize: '14px',
                      color: resolveStatusColor(formData?.status),
                    }}
                  >
                    Statü: {resolveStatusText(formData?.status)}
                  </Typography>
                  <Button
                    variant="containedPrimary"
                    className={classes.editButton}
                    onClick={() => setStatusPopupOpen(true)}
                  >
                    Statüyü Güncelle
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container className={classes.contentContainer}>
            <Grid item container>
              {[
                '00 Şirket Bilgilerini Tamamla',
                '01 Tur Bilgilerini Doldur',
                '02 Şirket Bilgilerini Yükle',
                '03 Sözleşmeyi Onayla',
              ].map((text, idx) => (
                <>
                  {/*step is completed in db or user completed their profile add svg tick*/}
                  {(doneStepsArray[idx] || (idx === 0 && userCompletedProfile)) && (
                    <Typography style={{ marginRight: 10, marginTop: 6 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                          d="M5.75 8L7.25 9.5L10.25 6.5M14.75 8C14.75 8.88642 14.5754 9.76417 14.2362 10.5831C13.897 11.4021 13.3998 12.1462 12.773 12.773C12.1462 13.3998 11.4021 13.897 10.5831 14.2362C9.76417 14.5754 8.88642 14.75 8 14.75C7.11358 14.75 6.23583 14.5754 5.41689 14.2362C4.59794 13.897 3.85382 13.3998 3.22703 12.773C2.60023 12.1462 2.10303 11.4021 1.76381 10.5831C1.42459 9.76417 1.25 8.88642 1.25 8C1.25 6.20979 1.96116 4.4929 3.22703 3.22703C4.4929 1.96116 6.20979 1.25 8 1.25C9.79021 1.25 11.5071 1.96116 12.773 3.22703C14.0388 4.4929 14.75 6.20979 14.75 8Z"
                          stroke="#118DFF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Typography>
                  )}

                  <Typography
                    key={idx}
                    className={classes.stepText}
                    variant="h4"
                    style={{
                      color: idx === 0 && userCompletedProfile ? '#118DFF' : resolveTabColors(idx),
                    }}
                  >
                    {text}
                  </Typography>
                </>
              ))}
            </Grid>
            <div className={classes.splitterLine} />
            <Grid
              item
              container
              className={classes.innerContainer}
              style={{
                margin: formStep === 3 && 40,
                boxShadow:
                  formStep === 3 && '0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)',
              }}
            >
              <FormSteps
                step={formStep}
                setFormStep={setFormStep}
                formData={formData}
                isAdmin={isAdmin}
                registerNewspaperDocs={registerNewspaperDocs}
                mainContractDocs={mainContractDocs}
                taxPlateDocs={taxPlateDocs}
                operatingCertificateDocs={operatingCertificateDocs}
                setRegisterNewspaperDocs={setRegisterNewspaperDocs}
                setMainContractDocs={setMainContractDocs}
                setTaxPlateDocs={setTaxPlateDocs}
                setOperatingCertificateDocs={setOperatingCertificateDocs}
                setDisableNextButton={setDisableNextButton}
                formikRef={formikRef}
                startup={startup}
                succesfullyEditedSnackbar={succesfullyEditedSnackbar}
                setSuccesfullyEditedSnackbar={setSuccesfullyEditedSnackbar}
              />
            </Grid>
          </Grid>
        </Grid>
        {isAdmin && statusPopupOpen && (
          <Dialog open={statusPopupOpen} keepMounted onClose={() => setStatusPopupOpen(false)}>
            <Grid container justify="flex-end" style={{ padding: '30px 30px 0 0' }}>
              <img src={newCross} alt="newCross" onClick={handleClose} style={{ cursor: 'pointer' }} />
            </Grid>

            <Grid
              container
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 24,
              }}
            >
              <div style={{ width: '116px', height: '116px', backgroundColor: '#F0F5FF', borderRadius: '100%' }}></div>
              <svg
                style={{ height: '42px', position: 'absolute' }}
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.1987 17C16.4797 14.2817 19.9353 12.3333 24 12.3333C29.1567 12.3333 33.3333 15.467 33.3333 19.3333C33.3333 22.6 30.3513 25.3417 26.3193 26.1163C25.0547 26.359 24 27.3763 24 28.6667M24 35.6667H24.0233M45 24C45 26.7578 44.4568 29.4885 43.4015 32.0364C42.3461 34.5842 40.7993 36.8992 38.8492 38.8492C36.8992 40.7993 34.5842 42.3461 32.0364 43.4015C29.4885 44.4568 26.7578 45 24 45C21.2422 45 18.5115 44.4568 15.9636 43.4015C13.4158 42.3461 11.1008 40.7993 9.15076 38.8492C7.20073 36.8992 5.65388 34.5842 4.59853 32.0364C3.54318 29.4885 3 26.7578 3 24C3 18.4305 5.21249 13.089 9.15076 9.15076C13.089 5.21249 18.4305 3 24 3C29.5695 3 34.911 5.21249 38.8492 9.15076C42.7875 13.089 45 18.4305 45 24Z"
                  stroke="#003CA5"
                  stroke-width="4.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Grid>

            <DialogTitle>
              <Typography
                align="center"
                style={{
                  paddingLeft: 32,
                  paddingRight: 32,
                  fontWeight: 600,
                  fontSize: '25px',
                  lineHeight: '38px',
                  textAlign: 'center',
                  color: '#27272E',
                }}
              >
                {t('Statüyü Güncelle')}
              </Typography>
            </DialogTitle>

            <DialogContent style={{ minWidth: 364, minHeight: 134 }}>
              <Select
                style={{ width: '100%' }}
                value={statusChangeSelect}
                onChange={(e) => setStatusChangeSelect(e.target.value)}
              >
                <MenuItem value={'Active'}>Aktif</MenuItem>
                <MenuItem value={'Finished'}>Tamamlandı</MenuItem>
                <MenuItem value={'Pending'}>Onay Bekleyen</MenuItem>
                <MenuItem value={'Rejected'}>Reddedildi</MenuItem>
              </Select>
              <Grid container justify="center" alignItems="center" style={{ textAlign: 'center', marginTop: '20px' }}>
                <Grid item xs={9}>
                  <Typography>Öne Çıkan Girişim:</Typography>
                </Grid>
                <Grid item container justify="center" alignItems="center" xs>
                  <AntSwitch checked={formData?.isFeatured} onChange={toggleIsFeatured} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ margin: 'auto' }}>
              <Button
                className={classes.dialogButton}
                variant="outlinedSecondary"
                onClick={() => setStatusPopupOpen(false)}
              >
                {t('Vazgeç')}
              </Button>
              <Button
                className={classes.dialogButton}
                variant="containedPrimary"
                onClick={() => handleStatusChangeSubmit(statusChangeSelect)}
                color="primary"
              >
                {t('Güncelle')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
      <Grid container className={classes.formFooter}>
        {formStep === 3 && (
          <Grid container item className={classes.footerApproveButtons}>
            <Grid item>
              <Hidden mdUp>
                <Typography variant="h5" style={{ fontSize: 14, marginRight: 32, marginLeft: -127, marginTop: 6 }}>
                  Sözleşmeyi <br /> Onaylıyorum
                </Typography>
              </Hidden>
              <Hidden mdDown>
                <Typography variant="h5" style={{ fontSize: 14, marginRight: 32, marginLeft: -127, marginTop: 6 }}>
                  Sözleşmeyi Okudum ve Anladım
                </Typography>
              </Hidden>
            </Grid>
          </Grid>
        )}

        <Grid container item className={classes.footerButtons}>
          {formStep !== 0 && formStep !== 1 && formStep !== 4 && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setFormStep(formStep - 1);
                  setDisableNextButton(false);
                }}
                style={{ marginRight: 20, padding: '12px 32px' }}
              >
                Geri
              </Button>
            </Grid>
          )}
          <Grid item>
            {formStep !== 0 && formStep !== 4 && (
              <Button
                disabled={disableNextButton}
                color="primary"
                variant="contained"
                onClick={resolveAdvanceButtonFunction(formStep)}
                style={{ padding: '12px 32px' }}
              >
                {resolveAdvanceButtonText(formStep)}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
}

export default ApplyForInvestment;
