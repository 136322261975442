import PersonalSettings from './PersonalSettings'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const MY_SETTINGS = gql`
  mutation mySettings(
    $firstName: String
    $lastName: String
    $profilePhoto: Upload
    $biography: String
    $website: String
    $socialLinkedIn: String
    $interestedIndustries: [String]
    $documents: [userDocumentInput]
  ) {
  mySettings(
        firstName: $firstName
        lastName: $lastName
        profilePhoto: $profilePhoto
        biography: $biography
        socialLinkedIn: $socialLinkedIn
        website: $website
        interestedIndustries: $interestedIndustries
        documents: $documents
    )
  }
`;
const JoinWithMutation = compose(
  graphql(MY_SETTINGS, {
  name: 'mySettings',
  }),
)(PersonalSettings);
export default JoinWithMutation;