import AdminCountryCity from './AdminCountryCity'
import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';

const CREATE_CITY = gql`
  mutation createCity($title: String!, $countryId: ID!) {
    createCity(title: $title, countryId: $countryId) 
  }
`;

const EDIT_CITY = gql`
  mutation editCity($title: String!, $cityId: ID!, $countryId: ID!) {
    editCity(title: $title, cityId: $cityId, countryId: $countryId) 
  }
`;

const DELETE_CITY = gql`
  mutation deleteCity($cityId: ID!) {
    deleteCity(cityId: $cityId) 
  }
`;

const CREATE_COUNTRY = gql`
  mutation createCountry($title: String!, $code: String!, $code3: String!) {
    createCountry(title: $title, code: $code, code3: $code3) 
  }
`;

const EDIT_COUNTRY = gql`
  mutation editCountry($title: String!, $language: String!, $code: String!, $code3: String!, $countryId: ID!) {
    editCountry(title: $title, countryId: $countryId, language: $language, code: $code, code3: $code3) 
  }
`;

const DELETE_COUNTRY = gql`
  mutation deleteCountry($countryId: ID!) {
    deleteCountry(countryId: $countryId) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(CREATE_CITY, {
    name: 'createCity',
  }),
  graphql(EDIT_CITY, {
    name: 'editCity',
  }),
  graphql(DELETE_CITY, {
    name: 'deleteCity',
  }),
  graphql(CREATE_COUNTRY, {
    name: 'createCountry',
  }),
  graphql(EDIT_COUNTRY, {
    name: 'editCountry',
  }),
  graphql(DELETE_COUNTRY, {
    name: 'deleteCountry',
  }),
)(AdminCountryCity);
export default JoinWithMutation;