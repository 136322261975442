import { Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import tr from 'date-fns/locale/tr';
import { Field, FieldArray, useField, useFormikContext } from 'formik';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { defaults } from 'react-chartjs-2';
import DatePicker, { registerLocale } from 'react-datepicker';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import FormikNumberField from '../../Components/FormikNumberField';
import FormikPercentageField from '../../Components/FormikPercentageField';
import FormikTextField from '../../Components/FormikTextField';
import ImageCrop from '../../Components/ImageCrop';
import { numberWithPeriods, resolveCurrencySymbol } from '../../helpers';
import worldIcon from '../../static/icon/blue-world-icon.svg';
import FormikNewDropdown from '../FormikDropdown';
import FormikSelect from '../FormikSelect';


registerLocale('tr', tr);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F6FF',
    color: '#003CA5',
    fontWeight: 500,
    fontSize: 14,
    padding: 16,
  },
  body: {
    fontSize: 14,
    background: '#FDFDFD;',
    color: '#425466',
  },
  fontLg: {
    fontSize: 16,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
}))(TableRow);
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  pointerEvents: 'none',
  flexWrap: 'wrap',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 4,
  border: '1px solid #eaeaea',
  width: 80,
  height: 80,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
  borderRadius: 7,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  paper2: {
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.text.secondary,
    boxShadow: '0px 0px 1px 0px rgb(50 50 71 / 5%)',
    filter: 'drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))',
    background: '#ffffff',
    marginBottom: '30px',
    padding: 36,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down('lg')]: {
      minHeight: '0',
      padding: 24,
    },
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80px',
    width: '80px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 4,
    border: 'solid 1px #dddddd6b',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '500',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  fontLg: {
    fontSize: 16,
  },
  objectCard: {
    display: 'flex',
    alignItems: 'center',
  },
  tableRoot: {
    boxShadow: 'none',
    borderRadius: 4,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
  },
  AddButton: {
    padding: '12.5px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    minWidth: 175,
    fontSize: 16,
    marginRight: 10,
    marginTop: 15,
    fontWeight: 'normal',
  },
}));

defaults.global.defaultFontFamily = 'OpenSans';
defaults.global.defaultFontColor = '#8898aa';

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'K', 'M', 'B', 'T'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}
const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      locale="tr"
      customInput={
        <input
          autocomplete="off"
          style={{
            width: '100%',
            padding: '13px',
            borderRadius: 3,
            border: '1px solid rgb(221 221 221 / 56%)',
            boxShadow: '0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%)',
            marginTop: 10,
          }}
          type="text"
          placeholder={'mm'}
        />
      }
      showMonthYearPicker
      dateFormat="MM/yyyy"
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};
function CustomerPart(props) {
  const { index, remove } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  const [openPhoto, setOpenPhoto] = React.useState(false);

  React.useEffect(() => {
    if (props.flag) {
      setOpen(false);
      props.setNewCustomer(false);
    }
  }, [props.flag]);

  function validateLinkedinUrl(value) {
    if (value !== '' && value !== null) {
      let error;
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?',
      ); // query string
      error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
      return error;
    }
    return false;
  }
  return (
    <div>
      <Grid xs={12} container spacing={2} style={{ marginBottom: 15 }}>
        <Grid container justify="space-between">
          <Grid item alignItems="center" style={{ marginBottom: 20, display: 'flex' }}>
            {!props.customer.title || !props.customer.link ? (
              <Typography variant="h5" style={{ marginLeft: 5, color: 'rgb(255 186 0)' }}>
                {props.detail.customers && props.detail.customers[index]
                  ? props.detail.customers[index].title
                  : t('Müşteri Bilgileri')}
              </Typography>
            ) : (
              <Typography variant="h5" style={{ marginLeft: 5 }}>
                {props.detail.customers && props.detail.customers[index]
                  ? props.detail.customers[index].title
                  : t('Müşteri Bilgileri')}
              </Typography>
            )}
            {open ? (
              <ExpandLessIcon
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
              />
            )}
          </Grid>
          <DeleteOutlineIcon style={{ width: 25, cursor: 'pointer' }} onClick={() => remove(index)} />
        </Grid>
        {open && (
          <>
            <Grid item>
              <Field>
                {({ field, form, meta }) => (
                  <Dropzone
                    maxSize={20971520}
                    accept={'image/*'}
                    onDrop={(acceptedFiles) =>
                      setFiles(
                        acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })),
                        form.setFieldValue(`customers.${index}.logo`, acceptedFiles),
                        setOpenPhoto(true),
                      )
                    }
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                      <section style={{ textAlign: 'center' }}>
                        {typeof form.values.customers[index].logo !== 'string' ? (
                          <div {...getRootProps({ className: classes.baseStyle })}>
                            <input {...getInputProps()} />
                            {!form.values.customers[index].logo ? (
                              <Grid item container direction="column" justify="center" alignItems="center">
                                {form.values.customers[index].title ? (
                                  <Grid container alignItems="center" justify="center">
                                    <Typography variant="h4">{form.values.customers[index].title.charAt(0)}</Typography>
                                    <AddIcon style={{ color: '#777777', fontSize: 20 }} />
                                  </Grid>
                                ) : (
                                  <>
                                    <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                    <Typography variant="body2" style={{ display: 'flex', color: '#777777' }}>
                                      {t('Logo')}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            ) : (
                              <aside style={thumbsContainer}>
                                {acceptedFiles[0] && (
                                  <div
                                    id={form.values.customers[index].logo[0].name}
                                    style={thumb}
                                    key={form.values.customers[index].logo[0].name}
                                  >
                                    <div style={thumbInner}>
                                      <img src={form.values.customers[index].logo[0].preview} style={img} alt="file" />
                                    </div>
                                  </div>
                                )}
                                {form.values.customers[index].logo[0] && !acceptedFiles[0] && (
                                  <div
                                    id={form.values.customers[index].logo[0].name}
                                    style={thumb}
                                    key={form.values.customers[index].logo[0].name}
                                  >
                                    <div style={thumbInner}>
                                      <img src={form.values.customers[index].logo[0].preview} style={img} alt="file" />
                                    </div>
                                  </div>
                                )}
                              </aside>
                            )}
                          </div>
                        ) : (
                          <div {...getRootProps({ className: classes.baseStyle })}>
                            <img
                              src={form.values.customers[index].logo}
                              alt="avatar"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        )}
                        {form.values.customers[index].logo && (
                          <Button
                            variant="containedSecondary"
                            style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5, width: 80 }}
                            onClick={() => form.setFieldValue(`customers.${index}.logo`, null)}
                          >
                            {t('Sil')}
                          </Button>
                        )}
                      </section>
                    )}
                  </Dropzone>
                )}
              </Field>
            </Grid>
            <Field>
              {({ field, form, meta }) => (
                <ImageCrop
                  setFieldValue={form.setFieldValue}
                  files={files}
                  index={index}
                  customer={form.values.customers[index]}
                  setOpenPhoto={setOpenPhoto}
                  openPhoto={openPhoto}
                />
              )}
            </Field>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" style={{ width: 'fit-content' }}>
                {t('Şirket Adı')}
              </Typography>
              <FormikTextField name={`customers.${index}.title`} placeholder={t('Şirket adını yazınız')} type="text" />
            </Grid>
            <Grid item xs={12} md>
              <Typography variant="h6" style={{ width: 'fit-content' }}>
                {t('Şirket Websitesi')}
              </Typography>
              <FormikTextField
                name={`customers.${index}.link`}
                validate={validateLinkedinUrl}
                placeholder="https://"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <hr size="1" style={{ border: '0.2px solid #f5f5f5', marginBottom: 25 }} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
function InvestorPart(props) {
  const { index, remove, type } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  const [openPhoto, setOpenPhoto] = React.useState(false);

  React.useEffect(() => {
    if (props.flag) {
      setOpen(false);
      props.setNewInvestor(false);
    }
  }, [props.flag]);

  function validateLinkedinUrl(value) {
    if (value !== '' && value !== null) {
      let error;
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
      return error;
    }
    return false;
  }

  return (
    <div>
      <Grid xs={12} container spacing={2} style={{ marginBottom: 15 }}>
        <Grid container justify="space-between">
          <Grid item alignItems="center" style={{ marginBottom: 20, display: 'flex' }}>
            {!props.investor.title || !props.investor.link ? (
              <Typography variant="h5" style={{ marginLeft: 5, color: 'rgb(255 186 0)' }}>
                {props.detail.investors && props.detail.investors[index]
                  ? props.detail.investors[index].title
                  : t('Yatırımcı Bilgileri')}
              </Typography>
            ) : (
              <Typography variant="h5" style={{ marginLeft: 5 }}>
                {props.detail.investors && props.detail.investors[index]
                  ? props.detail.investors[index].title
                  : t('Yatırımcı Bilgileri')}
              </Typography>
            )}
            {open ? (
              <ExpandLessIcon
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => setOpen(!open)}
                style={{ cursor: 'pointer', transition: '0.5s', color: '#172b4d', fontSize: 20, marginLeft: 10 }}
              />
            )}
          </Grid>
          <DeleteOutlineIcon style={{ width: 25, cursor: 'pointer' }} onClick={() => remove(index)} />
        </Grid>
        {open && (
          <>
            <Grid item>
              <Field>
                {({ field, form, meta }) => (
                  <Dropzone
                    maxSize={20971520}
                    accept={'image/*'}
                    onDrop={(acceptedFiles) =>
                      setFiles(
                        acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })),
                        form.setFieldValue(`investors.${index}.logo`, acceptedFiles),
                        setOpenPhoto(true),
                      )
                    }
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                      <section style={{ textAlign: 'center' }}>
                        {typeof form.values.investors[index].logo !== 'string' ? (
                          <div {...getRootProps({ className: classes.baseStyle })}>
                            <input {...getInputProps()} />
                            {!form.values.investors[index].logo ? (
                              <Grid item container direction="column" justify="center" alignItems="center">
                                {form.values.investors[index].title ? (
                                  <Grid container alignItems="center" justify="center">
                                    <Typography variant="h4">{form.values.investors[index].title.charAt(0)}</Typography>
                                    <AddIcon style={{ color: '#777777', fontSize: 20 }} />
                                  </Grid>
                                ) : (
                                  <>
                                    <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                    <Typography variant="body2" style={{ display: 'flex', color: '#777777' }}>
                                      {t('Fotoğraf')}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            ) : (
                              <aside style={thumbsContainer}>
                                {acceptedFiles[0] && (
                                  <div
                                    id={form.values.investors[index].logo[0].name}
                                    style={thumb}
                                    key={form.values.investors[index].logo[0].name}
                                  >
                                    <div style={thumbInner}>
                                      <img src={form.values.investors[index].logo[0].preview} style={img} alt="file" />
                                    </div>
                                  </div>
                                )}
                                {form.values.investors[index].logo[0] && !acceptedFiles[0] && (
                                  <div
                                    id={form.values.investors[index].logo[0].name}
                                    style={thumb}
                                    key={form.values.investors[index].logo[0].name}
                                  >
                                    <div style={thumbInner}>
                                      <img src={form.values.investors[index].logo[0].preview} style={img} alt="file" />
                                    </div>
                                  </div>
                                )}
                              </aside>
                            )}
                          </div>
                        ) : (
                          <div {...getRootProps({ className: classes.baseStyle })}>
                            <img
                              src={form.values.investors[index].logo}
                              alt="avatar"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        )}
                        {form.values.investors[index].logo && (
                          <Button
                            variant="containedSecondary"
                            style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5, width: 80 }}
                            onClick={() => form.setFieldValue(`investors.${index}.logo`, null)}
                          >
                            {t('Sil')}
                          </Button>
                        )}
                      </section>
                    )}
                  </Dropzone>
                )}
              </Field>
            </Grid>
            <Field>
              {({ field, form, meta }) => (
                <ImageCrop
                  setFieldValue={form.setFieldValue}
                  files={files}
                  index={index}
                  investor={form.values.investors[index]}
                  setOpenPhoto={setOpenPhoto}
                  openPhoto={openPhoto}
                />
              )}
            </Field>
            {type === 'corporate' ? (
              <>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" style={{ width: 'fit-content' }}>
                    {t('Şirket Adı')}
                  </Typography>
                  <FormikTextField name={`investors.${index}.title`} placeholder="Kurum adını yazınız" type="text" />
                </Grid>
                <Grid item xs={12} md>
                  <Typography variant="h6" style={{ width: 'fit-content' }}>
                    {t('Şirket Websitesi')}
                  </Typography>
                  <FormikTextField
                    name={`investors.${index}.link`}
                    validate={validateLinkedinUrl}
                    placeholder="https://"
                    type="text"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" style={{ width: 'fit-content' }}>
                    {t('Ad - Soyad')}
                  </Typography>
                  <FormikTextField
                    name={`investors.${index}.title`}
                    placeholder="Adınızı ve soyadınızı yazınız"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md>
                  <Typography variant="h6" style={{ width: 'fit-content' }}>
                    {t('LinkedIn')}
                  </Typography>
                  <FormikTextField
                    name={`investors.${index}.link`}
                    validate={validateLinkedinUrl}
                    placeholder="https://linkedin.com/in/"
                    type="text"
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <hr size="1" style={{ border: '0.2px solid #f5f5f5', marginBottom: 25 }} />
      </Grid>
    </div>
  );
}

export default function FinanceTab(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const revenueModel = [
    'Abonelik',
    'Doğrudan satış',
    'Premium',
    'Komisyon',
    'Kurulum Ücreti',
    'Lisans',
    'İşlem Başına Ödeme',
    'Reklam Geliri',
    'White labeling',
  ];
  const abonelik = ['Aylık', '3 aylık', '6 aylık', '9 aylık', '1 senelik', 'Basic', 'Premium'];
  const lastFundingTypeOptions = [
    'Tohum Yatırım ',
    'Melek Yatırım',
    'Seri A',
    'Seri B',
    'Seri C',
    'Serisi bilinmeyen girişim',
    'Pre-IPO',
    'Ürün - Kitlesel Fonlama',
    'Hisse - Kitlesel Fonlama ',
    'Dönüştürülebilir tahvil',
    'Hibe / Bağış',
  ];
  const yesNo = ['Evet', 'Hayır'];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newCustomer, setNewCustomer] = React.useState(false);
  const [newInvestor, setNewInvestor] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!props.edit ? (
        <Paper className={classes.paper2}>
          <Grid container>
            {(props.detail.hasUsers || props.detail.userSize || props.detail.monthlyActiveUserSize) && (
              <>
                <Grid container xs={12}>
                  <Grid container>
                    <Typography variant="h5" style={{ width: 'fit-content' }}>
                      {t('Kullanıcılar')}
                    </Typography>
                  </Grid>
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.hasUsers === props.data.originalDetail.hasUsers ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Kullanıcısı Var Mı?')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.hasUsers}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Kullanıcısı Var Mı?')}
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: 'rgba(0, 85, 255, 0.07)',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.hasUsers}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                      <Typography variant="body1" align="left" className={classes.fontLg} style={{ color: '#A0AEC0' }}>
                        {t('Kullanıcısı Var Mı?')}
                      </Typography>
                      <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                        {props.detail.hasUsers}
                      </Typography>
                    </Grid>
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.userSize === props.data.originalDetail.userSize ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Kullanıcı Sayısı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.userSize && numberWithPeriods(props.detail.userSize)}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Kullanıcı Sayısı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: 'rgba(0, 85, 255, 0.07)',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.userSize && numberWithPeriods(props.detail.userSize)}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.userSize !== 0 && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Kullanıcı Sayısı')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.userSize && numberWithPeriods(props.detail.userSize)}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.monthlyActiveUserSize ===
                          props.data.originalDetail.monthlyActiveUserSize ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Aylık Aktif Kullanıcı Sayısı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.monthlyActiveUserSize &&
                                  numberWithPeriods(props.detail.monthlyActiveUserSize)}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Aylık Aktif Kullanıcı Sayısı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: 'rgba(0, 85, 255, 0.07)',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.monthlyActiveUserSize &&
                                  numberWithPeriods(props.detail.monthlyActiveUserSize)}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.monthlyActiveUserSize !== 0 && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Aylık Aktif Kullanıcı Sayısı')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.monthlyActiveUserSize && numberWithPeriods(props.detail.monthlyActiveUserSize)}
                        </Typography>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            {(props.detail.tractionPayingCustomerSize || props.detail.tractionHasPoCCustomer) && (
              <>
                <Grid container xs={12}>
                  <Grid container>
                    <Typography variant="h5" style={{ width: 'fit-content' }}>
                      {t('Müşteriler')}
                    </Typography>
                  </Grid>
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.tractionPayingCustomerSize ===
                          props.data.originalDetail.tractionPayingCustomerSize ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Müşteri Sayısı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.tractionPayingCustomerSize &&
                                  numberWithPeriods(props.detail.tractionPayingCustomerSize)}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Müşteri Sayısı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: 'rgba(0, 85, 255, 0.07)',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.tractionPayingCustomerSize &&
                                  numberWithPeriods(props.detail.tractionPayingCustomerSize)}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.tractionPayingCustomerSize && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Müşteri Sayısı')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.tractionPayingCustomerSize &&
                            numberWithPeriods(props.detail.tractionPayingCustomerSize)}
                        </Typography>
                      </Grid>
                    )
                  )}
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.tractionHasPoCCustomer ===
                          props.data.originalDetail.tractionHasPoCCustomer ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('POC Yapılan Müşterisi Var Mı?')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {props.detail.tractionHasPoCCustomer}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('POC Yapılan Müşterisi Var Mı?')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: 'rgba(0, 85, 255, 0.07)',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {props.detail.tractionHasPoCCustomer}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.tractionHasPoCCustomer && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('POC Yapılan Müşterisi Var Mı?')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {props.detail.tractionHasPoCCustomer}
                        </Typography>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            {props.detail.customers && props.detail.customers.length > 0 && (
              <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
                {t('Öne Çıkan Müşteriler')}
              </Typography>
            )}
            {props.detail.customers && props.detail.customers.length > 0 && (
              <>
                <Grid container spacing={3}>
                  {props.detail.customers.map((customer, index) => (
                    <>
                      {props.data ? (
                        <>
                          {props.data.newDetail && props.data.originalDetail && (
                            <>
                              {JSON.stringify(props.data.newDetail.customers[index]) ===
                              JSON.stringify(props.data.originalDetail.customers[index]) ? (
                                <Grid item xs={12} sm={6} lg={4} className={classes.objectCard}>
                                  {customer.logo ? (
                                    <img
                                      src={customer.logo}
                                      alt="avatar"
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Grid
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(0, 85, 255, 0.07)',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {customer.title && customer.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{ textAlign: 'left' }}>
                                    <Typography variant="h5" align="left" style={{ marginBottom: 5 }}>
                                      {customer.title}
                                    </Typography>
                                    <a href={customer.link} target="_blank" style={{ padding: 0 }}>
                                      <img src={worldIcon} alt="world-icon" />
                                    </a>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className={classes.objectCard}
                                  style={{ background: '#0055ff12' }}
                                >
                                  {customer.logo ? (
                                    <img
                                      src={customer.logo}
                                      alt="avatar"
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Grid
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(0, 85, 255, 0.07)',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {customer.title && customer.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{ textAlign: 'left' }}>
                                    <Typography variant="h5" align="left" style={{ marginBottom: 5 }}>
                                      {customer.title}
                                    </Typography>
                                    <a href={customer.link} target="_blank" style={{ padding: 0 }}>
                                      <img src={worldIcon} alt="world-icon" />
                                    </a>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <Grid item xs={12} sm={6} lg={4} className={classes.objectCard}>
                          {customer.logo ? (
                            <img
                              src={customer.logo}
                              alt="avatar"
                              style={{
                                maxWidth: 60,
                                height: 60,
                                borderRadius: '4px',
                                marginRight: 20,
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <Grid
                              style={{
                                maxWidth: 60,
                                height: 60,
                                borderRadius: '4px',
                                marginRight: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0, 85, 255, 0.07)',
                                width: '100%',
                              }}
                            >
                              <Typography variant="h3" style={{ color: '#fff' }}>
                                {customer.title && customer.title.charAt(0)}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item style={{ textAlign: 'left' }}>
                            <Typography variant="h5" align="left" style={{ marginBottom: 5 }}>
                              {customer.title}
                            </Typography>
                            <a href={customer.link} target="_blank" style={{ padding: 0 }}>
                              <img src={worldIcon} alt="world-icon" />
                            </a>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {props.detail.tractionRevenueSize && (
                <Grid item style={{ marginBottom: 40 }}>
                  <Typography variant="h5" align="left" style={{ width: 'fit-content', marginBottom: 10 }}>
                    {t('Gelir')}
                  </Typography>
                  {props.data ? (
                    <>
                      {props.data.newDetail && props.data.originalDetail && (
                        <>
                          {props.data.newDetail.tractionRevenueSize ===
                          props.data.originalDetail.tractionRevenueSize ? (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Aylık Gelir Miktarı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{ marginLeft: 10 }}
                              >
                                {resolveCurrencySymbol(props.detail.currency)}
                                {props.detail.tractionRevenueSize &&
                                  numberWithPeriods(props.detail.tractionRevenueSize)}
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                              <Typography
                                variant="body1"
                                align="left"
                                className={classes.fontLg}
                                style={{ color: '#A0AEC0' }}
                              >
                                {t('Aylık Gelir Miktarı')}:
                              </Typography>
                              <Typography
                                variant="h5"
                                align="left"
                                className={classes.fontLg}
                                style={{
                                  marginLeft: 10,
                                  background: '#0055ff12',
                                  padding: '0 5px',
                                  borderRadius: 4,
                                }}
                              >
                                {resolveCurrencySymbol(props.detail.currency)}
                                {props.detail.tractionRevenueSize &&
                                  numberWithPeriods(props.detail.tractionRevenueSize)}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    props.detail.tractionRevenueSize && (
                      <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          className={classes.fontLg}
                          style={{ color: '#A0AEC0' }}
                        >
                          {t('Aylık Gelir Miktarı')}:
                        </Typography>
                        <Typography variant="h5" align="left" className={classes.fontLg} style={{ marginLeft: 10 }}>
                          {resolveCurrencySymbol(props.detail.currency)}
                          {props.detail.tractionRevenueSize && numberWithPeriods(props.detail.tractionRevenueSize)}
                        </Typography>
                      </Grid>
                    )
                  )}
                </Grid>
              )}
              {props.detail.detailRevenueModels && props.detail.detailRevenueModels.length !== 0 && (
                <Typography variant="h5" align="left" style={{ width: 'fit-content', marginBottom: 20 }}>
                  {t('Gelir Modeli')}
                </Typography>
              )}
              <Grid container xs={12} spacing={3} style={{ marginBottom: 20 }}>
                {props.detail.detailRevenueModels && props.detail.detailRevenueModels.length !== 0 && (
                  <>
                    {props.detail.detailRevenueModels.map((revenue, index) => (
                      <Grid item xs={12} md={6}>
                        {props.data ? (
                          <>
                            {props.data.newDetail && props.data.originalDetail && (
                              <>
                                {get(props.data.newDetail.detailRevenueModels[index], 'title', '') ===
                                get(props.data.originalDetail.detailRevenueModels[index], 'title', '') ? (
                                  <Typography
                                    variant="h5"
                                    align="left"
                                    style={{ width: 'fit-content', marginBottom: 20, color: '#A0AEC0' }}
                                  >
                                    {revenue.title} - {t('Gelir üzerindeki yüzdesi')}:{' '}
                                    <strong style={{ color: '#425466' }}>%{revenue.revenuePercentage} </strong>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="h5"
                                    align="left"
                                    style={{
                                      width: 'fit-content',
                                      marginBottom: 20,
                                      background: '#0055ff12',
                                      padding: '0 5px',
                                      borderRadius: 4,
                                      color: '#A0AEC0',
                                    }}
                                  >
                                    {revenue.title} - {t('Gelir üzerindeki yüzdesi')}:{' '}
                                    <strong style={{ color: '#425466' }}>%{revenue.revenuePercentage} </strong>
                                  </Typography>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <Typography
                            variant="h5"
                            align="left"
                            style={{ width: 'fit-content', marginBottom: 20, color: '#A0AEC0' }}
                          >
                            {revenue.title} - {t('Gelir üzerindeki yüzdesi')}:{' '}
                            <strong style={{ color: '#425466' }}>%{revenue.revenuePercentage} </strong>
                          </Typography>
                        )}
                        {revenue.title !== 'Reklam Geliri' &&
                          revenue.title !== 'White labeling' &&
                          revenue.title !== 'Komisyon' &&
                          get(revenue.items, 'length', '') > 0 && (
                            <TableContainer
                              style={{ marginBottom: 15 }}
                              component={Paper}
                              classes={{ root: classes.tableRoot }}
                            >
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    {revenue.title === 'Abonelik' && (
                                      <StyledTableCell align="left">{t('Abonelik Türü')}</StyledTableCell>
                                    )}
                                    {(revenue.title === 'Doğrudan satış' ||
                                      revenue.title === 'Kurulum Ücreti' ||
                                      revenue.title === 'İşlem Başına Ödeme') && (
                                      <StyledTableCell align="left">{t('Ürün Adı')}</StyledTableCell>
                                    )}
                                    {revenue.title === 'Premium' && (
                                      <StyledTableCell align="left">{t('Hizmet Adı')}</StyledTableCell>
                                    )}
                                    {revenue.title === 'Lisans' && (
                                      <StyledTableCell align="left">{t('Lisans Türü')}</StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">{t('Fiyat')}</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {revenue.items.map((row, ind) => (
                                    <>
                                      {row.productName && (
                                        <StyledTableRow key={row.productName}>
                                          <StyledTableCell style={{ width: '50%' }} component="th" scope="row">
                                            <Grid item xs={6}>
                                              {props.data ? (
                                                <>
                                                  {props.data.newDetail && props.data.originalDetail && (
                                                    <>
                                                      {get(
                                                        props.data.newDetail.detailRevenueModels[index],
                                                        `items.${ind}.productName`,
                                                        '',
                                                      ) ===
                                                      get(
                                                        props.data.originalDetail.detailRevenueModels[index],
                                                        `items.${ind}.productName`,
                                                        '',
                                                      ) ? (
                                                        <Typography
                                                          variant="body1"
                                                          color="secondary"
                                                          style={{
                                                            fontWeight: 500,
                                                            fontSize: 15,
                                                            color: '#172b4d',
                                                          }}
                                                        >
                                                          {row.productName}
                                                        </Typography>
                                                      ) : (
                                                        <Typography
                                                          variant="body1"
                                                          color="secondary"
                                                          style={{
                                                            fontWeight: 500,
                                                            fontSize: 15,
                                                            color: '#172b4d',
                                                            background: '#0055ff12',
                                                            width: 'fit-content',
                                                            padding: '0 5px',
                                                            borderRadius: 4,
                                                          }}
                                                        >
                                                          {row.productName}
                                                        </Typography>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <Typography
                                                  variant="body1"
                                                  color="secondary"
                                                  style={{ fontWeight: 500, fontSize: 15, color: '#172b4d' }}
                                                >
                                                  {row.productName}
                                                </Typography>
                                              )}
                                            </Grid>
                                          </StyledTableCell>
                                          {props.data ? (
                                            <>
                                              {props.data.newDetail && props.data.originalDetail && (
                                                <>
                                                  {get(
                                                    props.data.newDetail.detailRevenueModels[index],
                                                    `items.${ind}.price`,
                                                    '',
                                                  ) ===
                                                  get(
                                                    props.data.originalDetail.detailRevenueModels[index],
                                                    `items.${ind}.price`,
                                                    '',
                                                  ) ? (
                                                    <>
                                                      {row.price && (
                                                        <StyledTableCell align="left">
                                                          <Typography
                                                            variant="h5"
                                                            style={{ fontSize: 15, color: '#172b4d' }}
                                                            color="primary"
                                                          >
                                                            {abbreviateNumber(row.price)}
                                                          </Typography>
                                                        </StyledTableCell>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {row.price && (
                                                        <StyledTableCell align="left">
                                                          <Typography
                                                            variant="h5"
                                                            style={{
                                                              fontSize: 15,
                                                              color: '#172b4d',
                                                              background: '#0055ff12',
                                                              width: 'fit-content',
                                                              padding: '0 5px',
                                                              borderRadius: 4,
                                                            }}
                                                            color="primary"
                                                          >
                                                            {abbreviateNumber(row.price)}
                                                          </Typography>
                                                        </StyledTableCell>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {row.price && (
                                                <StyledTableCell align="left">
                                                  <Typography
                                                    variant="h5"
                                                    style={{ fontSize: 15, color: '#172b4d' }}
                                                    color="primary"
                                                  >
                                                    {abbreviateNumber(row.price)}
                                                  </Typography>
                                                </StyledTableCell>
                                              )}
                                            </>
                                          )}
                                        </StyledTableRow>
                                      )}
                                    </>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <hr size="1" style={{ border: '1px solid #EDF2F7' }} />
                    </Grid>
                  </>
                )}
              </Grid>
              {(props.detail.totalInvestmentRound ||
                props.detail.totalInvestmentSize ||
                props.detail.lastValuation ||
                props.detail.lastFundingDate ||
                props.detail.lastFundingType ||
                props.detail.lastFundingSize) && (
                <>
                  <Grid container xs={12}>
                    <Grid container>
                      <Typography variant="h5" style={{ width: 'fit-content' }}>
                        {t('Yatırım Geçmişi')}
                      </Typography>
                    </Grid>
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.totalInvestmentRound ===
                            props.data.originalDetail.totalInvestmentRound ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Toplam Yatırım Turu')}:
                                </Typography>
                                <Typography variant="h5" align="left" className={classes.fontLg}>
                                  {props.detail.totalInvestmentRound}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Toplam Yatırım Turu')}:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {props.detail.totalInvestmentRound}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      props.detail.totalInvestmentRound && (
                        <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginRight: 10 }}
                          >
                            {t('Toplam Yatırım Turu')}:
                          </Typography>
                          <Typography variant="h5" align="left" className={classes.fontLg}>
                            {props.detail.totalInvestmentRound}
                          </Typography>
                        </Grid>
                      )
                    )}
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.totalInvestmentSize ===
                            props.data.originalDetail.totalInvestmentSize ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Toplam Yatırım Miktarı')}:
                                </Typography>
                                <Typography variant="h5" align="left" className={classes.fontLg}>
                                  {resolveCurrencySymbol(props.detail.currency)}
                                  {props.detail.totalInvestmentSize &&
                                    numberWithPeriods(props.detail.totalInvestmentSize)}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Toplam Yatırım Miktarı')}:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {resolveCurrencySymbol(props.detail.currency)}
                                  {props.detail.totalInvestmentSize &&
                                    numberWithPeriods(props.detail.totalInvestmentSize)}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      props.detail.totalInvestmentSize && (
                        <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginRight: 10 }}
                          >
                            {t('Toplam Yatırım Miktarı')}:
                          </Typography>
                          <Typography variant="h5" align="left" className={classes.fontLg}>
                            {resolveCurrencySymbol(props.detail.currency)}
                            {props.detail.totalInvestmentSize && numberWithPeriods(props.detail.totalInvestmentSize)}
                          </Typography>
                        </Grid>
                      )
                    )}
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.lastValuation === props.data.originalDetail.lastValuation ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Değerlemesi')}:
                                </Typography>
                                <Typography variant="h5" align="left" className={classes.fontLg}>
                                  {resolveCurrencySymbol(props.detail.currency)}
                                  {props.detail.lastValuation && numberWithPeriods(props.detail.lastValuation)}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Değerleme Miktarı')}:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {resolveCurrencySymbol(props.detail.currency)}
                                  {props.detail.lastValuation && numberWithPeriods(props.detail.lastValuation)}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      props.detail.lastValuation && (
                        <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginRight: 10 }}
                          >
                            {t('Son Değerleme Miktarı')}:
                          </Typography>
                          <Typography variant="h5" align="left" className={classes.fontLg}>
                            {resolveCurrencySymbol(props.detail.currency)}
                            {props.detail.lastValuation && numberWithPeriods(props.detail.lastValuation)}
                          </Typography>
                        </Grid>
                      )
                    )}
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.lastFundingDate === props.data.originalDetail.lastFundingDate ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Fonlama Tarihi')}:
                                </Typography>
                                <Typography variant="h5" align="left" className={classes.fontLg}>
                                  {props.detail.lastFundingDate &&
                                    moment(props.detail.lastFundingDate).format('MMMM YYYY')}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Fonlama Tarihi')}:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {props.detail.lastFundingDate &&
                                    moment(props.detail.lastFundingDate).format('MMMM YYYY')}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      props.detail.lastFundingDate && (
                        <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginRight: 10 }}
                          >
                            {t('Son Fonlama Tarihi')}:
                          </Typography>
                          <Typography variant="h5" align="left" className={classes.fontLg}>
                            {props.detail.lastFundingDate && moment(props.detail.lastFundingDate).format('MMMM YYYY')}
                          </Typography>
                        </Grid>
                      )
                    )}
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.lastFundingType === props.data.originalDetail.lastFundingType ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Fonlama Tipi')}:
                                </Typography>
                                <Typography variant="h5" align="left" className={classes.fontLg}>
                                  {props.detail.lastFundingType}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Fonlama Tipi')}:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {props.detail.lastFundingType}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      props.detail.lastFundingType && (
                        <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginRight: 10 }}
                          >
                            {t('Son Fonlama Tipi')}:
                          </Typography>
                          <Typography variant="h5" align="left" className={classes.fontLg}>
                            {props.detail.lastFundingType}
                          </Typography>
                        </Grid>
                      )
                    )}
                    {props.data ? (
                      <>
                        {props.data.newDetail && props.data.originalDetail && (
                          <>
                            {props.data.newDetail.lastFundingSize === props.data.originalDetail.lastFundingSize ? (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Fonlama Miktarı')}:
                                </Typography>
                                <Typography variant="h5" align="left" className={classes.fontLg}>
                                  {resolveCurrencySymbol(props.detail.currency)}
                                  {props.detail.lastFundingSize && numberWithPeriods(props.detail.lastFundingSize)}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}
                              >
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{ color: '#A0AEC0', marginRight: 10 }}
                                >
                                  {t('Son Fonlama Miktarı')}:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  align="left"
                                  className={classes.fontLg}
                                  style={{
                                    background: '#0055ff12',
                                    padding: '0 5px',
                                    borderRadius: 4,
                                  }}
                                >
                                  {resolveCurrencySymbol(props.detail.currency)}
                                  {props.detail.lastFundingSize && numberWithPeriods(props.detail.lastFundingSize)}
                                </Typography>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      props.detail.lastFundingSize && (
                        <Grid item xs={12} sm={6} lg={4} style={{ display: 'flex', marginTop: 20, flexWrap: 'wrap' }}>
                          <Typography
                            variant="body1"
                            align="left"
                            className={classes.fontLg}
                            style={{ color: '#A0AEC0', marginRight: 10 }}
                          >
                            {t('Son Fonlama Miktarı')}:
                          </Typography>
                          <Typography variant="h5" align="left" className={classes.fontLg}>
                            {resolveCurrencySymbol(props.detail.currency)}
                            {props.detail.lastFundingSize && numberWithPeriods(props.detail.lastFundingSize)}
                          </Typography>
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <hr size="1" style={{ border: '1px solid #EDF2F7', margin: '32px 0' }} />
                  </Grid>
                </>
              )}
              {props.detail.investors && props.detail.investors.length > 0 && (
                <Typography variant="h5" align="left" style={{ width: 'fit-content', marginBottom: 30 }}>
                  {t('Öne Çıkan Yatırımcılar')}
                </Typography>
              )}
              {props.detail.investors && (
                <Grid container spacing={3} style={{ marginBottom: 50 }}>
                  {props.detail.investors.map((investor, index) => (
                    <>
                      {props.data ? (
                        <>
                          {props.data.newDetail && props.data.originalDetail && (
                            <>
                              {JSON.stringify(props.data.newDetail.investors[index]) ===
                              JSON.stringify(props.data.originalDetail.investors[index]) ? (
                                <Grid item xs={12} sm={6} lg={4} className={classes.objectCard}>
                                  {investor.logo ? (
                                    <img
                                      src={investor.logo}
                                      alt="avatar"
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Grid
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(0, 85, 255, 0.07)',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {investor.title && investor.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{ textAlign: 'left' }}>
                                    <Typography variant="h5" align="left">
                                      {investor.title}
                                    </Typography>
                                    <a href={investor.link} target="_blank" style={{ padding: 0 }}>
                                      <img src={worldIcon} alt="world-icon" />
                                    </a>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  lg={4}
                                  className={classes.objectCard}
                                  style={{ background: '#0055ff12' }}
                                >
                                  {investor.logo ? (
                                    <img
                                      src={investor.logo}
                                      alt="avatar"
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  ) : (
                                    <Grid
                                      style={{
                                        maxWidth: 60,
                                        height: 60,
                                        borderRadius: '4px',
                                        marginRight: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: 'rgba(0, 85, 255, 0.07)',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography variant="h3" style={{ color: '#fff' }}>
                                        {investor.title && investor.title.charAt(0)}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item style={{ textAlign: 'left' }}>
                                    <Typography variant="h5" align="left">
                                      {investor.title}
                                    </Typography>
                                    <a href={investor.link} target="_blank" style={{ padding: 0 }}>
                                      <img src={worldIcon} alt="world-icon" />
                                    </a>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <Grid item xs={12} sm={6} lg={4} className={classes.objectCard}>
                          {investor.logo ? (
                            <img
                              src={investor.logo}
                              alt="avatar"
                              style={{
                                maxWidth: 60,
                                height: 60,
                                borderRadius: '4px',
                                marginRight: 20,
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <Grid
                              style={{
                                maxWidth: 60,
                                height: 60,
                                borderRadius: '4px',
                                marginRight: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0, 85, 255, 0.07)',
                                width: '100%',
                              }}
                            >
                              <Typography variant="h3" style={{ color: '#fff' }}>
                                {investor.title && investor.title.charAt(0)}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item style={{ textAlign: 'left' }}>
                            <Typography variant="h5" align="left">
                              {investor.title}
                            </Typography>
                            <a href={investor.link} target="_blank" style={{ padding: 0 }}>
                              <img src={worldIcon} alt="world-icon" />
                            </a>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper className={classes.paper2}>
            <Grid container spacing={2}>
              <Grid xs={12} container spacing={2} style={{ margin: 0 }}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                    {t('Kullanıcılar')}
                  </Typography>
                </Grid>
                <Field>
                  {({ field, form, meta }) => (
                    <Grid item xs={12} md={4}>
                      <Typography align="left" variant="h6">
                        {t('Kullanıcınız var mı?')}
                      </Typography>
                      <FormikSelect
                        handleChange={
                          form.values.hasUsers === 'Hayır'
                            ? ((form.values.userSize = null), (form.values.monthlyActiveUserSize = null))
                            : null
                        }
                        name="hasUsers"
                        options={yesNo}
                      ></FormikSelect>
                    </Grid>
                  )}
                </Field>
                <Field>
                  {({ field, form, meta }) => (
                    <>
                      {form.values.hasUsers === 'Evet' && (
                        <>
                          <Grid item xs={12} md={4}>
                            <Typography align="left" variant="h6">
                              {t('Kaç kullanıcınız var?')}
                            </Typography>
                            <FormikTextField
                              name="userSize"
                              onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                              placeholder="Yazınız"
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography align="left" variant="h6">
                              {t('Aylık Aktif Kullanıcı Sayısı')}
                            </Typography>
                            <FormikTextField
                              name="monthlyActiveUserSize"
                              onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                              placeholder="Yazınız"
                              type="number"
                              max={form.values.userSize}
                            />
                            {form.values.monthlyActiveUserSize > form.values.userSize && (
                              <Typography
                                style={{
                                  color: '#FF0000',
                                  fontSize: '12px',
                                  marginBottom: 4,
                                  marginTop: -18,
                                  textAlign: 'left',
                                }}
                              >
                                {t('Kullanıcı Sayısından Büyük Bir Değer Girildi')}
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid xs={12} container spacing={2} style={{ margin: 0 }}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                    {t('Müşteriler')}
                  </Typography>
                </Grid>
                <Field>
                  {({ field, form, meta }) => (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography align="left" variant="h6">
                          {t('Kaç müşteriniz var?')}
                        </Typography>
                        <FormikTextField
                          onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                          name="tractionPayingCustomerSize"
                          type="number"
                        />
                      </Grid>
                    </>
                  )}
                </Field>
                <Field>
                  {({ field, form, meta }) => (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography align="left" variant="h6">
                          {t('PoC yaptığınız müşteriniz var mı?')}
                        </Typography>
                        <FormikSelect name={`tractionHasPoCCustomer`} options={yesNo} />
                      </Grid>
                    </>
                  )}
                </Field>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
              {t('Öne Çıkan Müşteriler')}
            </Typography>
            <Grid xs={12} item>
              <FieldArray
                name="customers"
                render={({ insert, remove, push, unshift, form }) => (
                  <>
                    <div>
                      {form.values.customers && form.values.customers.length > 0 && (
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          {form.values.customers.map((customer, index) => (
                            <CustomerPart
                              setNewCustomer={setNewCustomer}
                              customer={customer}
                              flag={index + 1 !== form.values.customers.length && newCustomer}
                              detail={props.detail}
                              remove={remove}
                              index={index}
                            />
                          ))}
                        </Grid>
                      )}
                      {form.values.customers && form.values.customers.length < 4 && (
                        <Grid xs={12} container justify="center" style={{ marginTop: 20 }}>
                          <Button
                            variant="outlinedPrimary"
                            className={classes.AddButton}
                            onClick={() => {
                              push({ title: '', link: '', logo: null });
                              setNewCustomer(true);
                            }}
                          >
                            {t('Yeni Müşteri Ekle')}
                          </Button>
                        </Grid>
                      )}
                    </div>
                  </>
                )}
              />
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid xs={12} container spacing={2}>
              <Field>
                {({ field, form, meta }) => (
                  <Grid item xs={12} md={6}>
                    <Typography align="left" variant="h6">
                      {t('Gelir elde ediyor musun?')}
                    </Typography>
                    <FormikSelect
                      handleChange={
                        form.values.tractionHasRevenue === 'Hayır' ? (form.values.tractionRevenueSize = null) : null
                      }
                      name="tractionHasRevenue"
                      options={yesNo}
                    ></FormikSelect>
                  </Grid>
                )}
              </Field>
              <Field>
                {({ field, form, meta }) => (
                  <>
                    {form.values.tractionHasRevenue === 'Evet' && (
                      <Grid item xs={12} md={6}>
                        <Typography align="left" variant="h6">
                          {t('Aylık geliriniz ne kadar?')} ({props.detail.currency})
                        </Typography>
                        <FormikNumberField
                          onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                          name="tractionRevenueSize"
                          maxLen={15}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Field>
            </Grid>
            <Typography variant="h6" style={{ width: 'fit-content' }}>
              {t('Gelir Modeli')}
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <FieldArray
                  name="detailRevenueModels"
                  render={({ insert, remove, push, unshift, form }) => (
                    <>
                      <div>
                        <Grid xs={12} container spacing={2} style={{ margin: 0, marginBottom: 20 }}>
                          {form.values.detailRevenueModels.length > 0 && (
                            <>
                              {form.values.detailRevenueModels.map((news, index) => (
                                <Button
                                  variant="containedPrimary"
                                  className={classes.AddButton}
                                  style={{ cursor: 'auto' }}
                                >
                                  {index + 1}. {t('Gelir Modeli')}
                                  <ClearIcon
                                    onClick={() => remove(index)}
                                    style={{ color: '#ffffff', fontSize: 19, cursor: 'pointer' }}
                                  />{' '}
                                </Button>
                              ))}
                            </>
                          )}
                          <Button
                            variant="outlinedPrimary"
                            className={classes.AddButton}
                            onClick={() =>
                              unshift({
                                title: '',
                                description: '',
                                revenuePercentage: '',
                                items: [
                                  {
                                    productName: '',
                                    price: '',
                                  },
                                ],
                              })
                            }
                          >
                            {t('Gelir Modeli Ekle')}
                          </Button>
                        </Grid>
                        {form.values.detailRevenueModels.length > 0 && (
                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                            {form.values.detailRevenueModels.map((news, index) => (
                              <div>
                                <Grid xs={12} container spacing={2}>
                                  <Grid item xs={12} md={4}>
                                    <Typography variant="h6" style={{ width: 'fit-content' }}>
                                      {t('Gelir Modeli')}
                                    </Typography>
                                    <Field
                                      component={FormikNewDropdown}
                                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                      name={`detailRevenueModels.${index}.title`}
                                      options={revenueModel}
                                    />
                                  </Grid>
                                  <Field>
                                    {({ field, form, meta }) => (
                                      <>
                                        {form.values.detailRevenueModels[index].title !== '' &&
                                          form.values.detailRevenueModels[index].title && (
                                            <Grid item xs={12} md={4}>
                                              <Typography variant="h6" style={{ width: 'fit-content' }}>
                                                {t('Bu model, gelirinizin yüzde kaçını oluşturuyor?')}
                                              </Typography>
                                              <FormikPercentageField
                                                name={`detailRevenueModels.${index}.revenuePercentage`}
                                                maxLen={4}
                                                placeholder="Örn: 50%"
                                              ></FormikPercentageField>
                                            </Grid>
                                          )}
                                      </>
                                    )}
                                  </Field>
                                  <Field>
                                    {({ field, form, meta }) => (
                                      <>
                                        {form.values.detailRevenueModels[index].title !== '' &&
                                          form.values.detailRevenueModels[index].title && (
                                            <Grid item xs={12} md={4}>
                                              <Typography variant="h6" style={{ width: 'fit-content' }}>
                                                {t('Açıklama')}
                                              </Typography>
                                              <FormikTextField
                                                name={`detailRevenueModels.${index}.description`}
                                                type="text"
                                              />
                                            </Grid>
                                          )}
                                      </>
                                    )}
                                  </Field>
                                  <Field>
                                    {({ field, form, meta }) => (
                                      <>
                                        {form.values.detailRevenueModels[index].title !== '' &&
                                          form.values.detailRevenueModels[index].title &&
                                          form.values.detailRevenueModels[index].title !== 'Reklam Geliri' &&
                                          form.values.detailRevenueModels[index].title !== 'White labeling' &&
                                          form.values.detailRevenueModels[index].title !== 'Komisyon' && (
                                            <Grid item xs={12}>
                                              <FieldArray
                                                name={`detailRevenueModels.${index}.items`}
                                                render={({ insert, remove, push, form }) => (
                                                  <>
                                                    <div>
                                                      <TableContainer style={{ overflowX: 'hidden' }} component={Paper}>
                                                        <Table className={classes.table} aria-label="customized table">
                                                          <TableHead>
                                                            <TableRow>
                                                              {form.values.detailRevenueModels[index].title ===
                                                                'Abonelik' && (
                                                                <StyledTableCell align="left">
                                                                  {t('Abonelik Türü')}
                                                                </StyledTableCell>
                                                              )}
                                                              {(form.values.detailRevenueModels[index].title ===
                                                                'Doğrudan satış' ||
                                                                form.values.detailRevenueModels[index].title ===
                                                                  'Kurulum Ücreti' ||
                                                                form.values.detailRevenueModels[index].title ===
                                                                  'İşlem Başına Ödeme') && (
                                                                <StyledTableCell align="left">
                                                                  {t('Ürün Adı')}
                                                                </StyledTableCell>
                                                              )}
                                                              {form.values.detailRevenueModels[index].title ===
                                                                'Premium' && (
                                                                <StyledTableCell align="left">
                                                                  {t('Hizmet Adı')}
                                                                </StyledTableCell>
                                                              )}
                                                              {form.values.detailRevenueModels[index].title ===
                                                                'Lisans' && (
                                                                <StyledTableCell align="left">
                                                                  {t('Lisans Türü')}
                                                                </StyledTableCell>
                                                              )}
                                                              <StyledTableCell align="left">
                                                                {t('Fiyat')}({props.detail.currency}){' '}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="left"></StyledTableCell>
                                                            </TableRow>
                                                          </TableHead>

                                                          <TableBody>
                                                            {form.values.detailRevenueModels[index].items.length > 0 &&
                                                              form.values.detailRevenueModels[index].items.map(
                                                                (label, ind) => (
                                                                  <TableRow key={index}>
                                                                    <StyledTableCell
                                                                      component="th"
                                                                      scope="row"
                                                                      align="center"
                                                                      style={{ border: '1px solid #8898aa2e' }}
                                                                    >
                                                                      {form.values.detailRevenueModels[index].title ===
                                                                      'Abonelik' ? (
                                                                        <Field
                                                                          component={FormikNewDropdown}
                                                                          textFieldProps={{
                                                                            fullWidth: true,
                                                                            variant: 'outlined',
                                                                          }}
                                                                          name={`detailRevenueModels.${index}.items.${ind}.productName`}
                                                                          options={abonelik}
                                                                        />
                                                                      ) : (
                                                                        <FormikTextField
                                                                          placeholder="Ürün Adı"
                                                                          name={`detailRevenueModels.${index}.items.${ind}.productName`}
                                                                          style={{ paddingTop: 0 }}
                                                                        ></FormikTextField>
                                                                      )}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                      component="th"
                                                                      scope="row"
                                                                      align="center"
                                                                      style={{ border: '1px solid #8898aa2e' }}
                                                                    >
                                                                      <FormikNumberField
                                                                        onKeyDown={(e) =>
                                                                          (e.keyCode === 69 || e.keyCode === 190) &&
                                                                          e.preventDefault()
                                                                        }
                                                                        name={`detailRevenueModels.${index}.items.${ind}.price`}
                                                                        maxLen={15}
                                                                      ></FormikNumberField>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                      component="th"
                                                                      scope="row"
                                                                      align="center"
                                                                      style={{ border: '1px solid #8898aa2e' }}
                                                                    >
                                                                      <Button
                                                                        variant="containedSecondary"
                                                                        style={{
                                                                          padding: '4.5px 6px',
                                                                          fontSize: 14,
                                                                          minWidth: 0,
                                                                          bottom: 5,
                                                                        }}
                                                                        onClick={() => remove(ind)}
                                                                      >
                                                                        <ClearIcon style={{ color: '#ffffff' }} />
                                                                      </Button>
                                                                      {ind ===
                                                                        form.values.detailRevenueModels[index].items
                                                                          .length -
                                                                          1 && (
                                                                        <Button
                                                                          variant="containedPrimary"
                                                                          style={{
                                                                            padding: '4.5px 6px',
                                                                            fontSize: 14,
                                                                            minWidth: 0,
                                                                            bottom: 5,
                                                                            marginLeft: 10,
                                                                          }}
                                                                          onClick={() =>
                                                                            push({ productName: '', price: '' })
                                                                          }
                                                                        >
                                                                          <AddIcon style={{ color: '#ffffff' }} />
                                                                        </Button>
                                                                      )}
                                                                    </StyledTableCell>
                                                                  </TableRow>
                                                                ),
                                                              )}
                                                          </TableBody>
                                                        </Table>
                                                      </TableContainer>
                                                      {form.values.detailRevenueModels[index].items.length < 5 && (
                                                        <Button
                                                          variant="containedPrimary"
                                                          style={{ marginBottom: 30, minWidth: 150 }}
                                                          className={classes.AddButton}
                                                          onClick={() => push({ productName: '', price: '' })}
                                                        >
                                                          {t('Ekle')}
                                                        </Button>
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              />
                                            </Grid>
                                          )}
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </div>
                            ))}
                          </Grid>
                        )}
                      </div>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid xs={12} container spacing={2} style={{ margin: 0 }}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                    {t('Yatırım Geçmişi')}
                  </Typography>
                </Grid>
                <Field>
                  {({ field, form, meta }) => (
                    <Grid item xs={12} md={4}>
                      <Typography align="left" variant="h6">
                        {t('Şimdiye kadar yatırım aldınız mı?')}
                      </Typography>
                      <FormikSelect
                        handleChange={
                          form.values.hasInvestment === 'Hayır'
                            ? ((form.values.totalInvestmentRound = null),
                              (form.values.totalInvestmentSize = null),
                              (form.values.lastValuation = null),
                              (form.values.lastFundingDate = null),
                              (form.values.lastFundingType = null),
                              (form.values.lastFundingSize = null))
                            : null
                        }
                        name="hasInvestment"
                        options={yesNo}
                      ></FormikSelect>
                    </Grid>
                  )}
                </Field>
                <Field>
                  {({ field, form, meta }) => (
                    <>
                      {form.values.hasInvestment === 'Evet' && (
                        <>
                          <Grid container spacing={2} style={{ margin: 0 }}>
                            <Grid item xs={12} md={4}>
                              <Typography align="left" variant="h6">
                                {t('Toplam Yatırım Turu')}
                              </Typography>
                              <FormikTextField name="totalInvestmentRound" placeholder={t('Yazınız')} type="number" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography align="left" variant="h6">
                                {t('Toplam Yatırım Miktarı')} ({props.detail.currency})
                              </Typography>
                              <FormikNumberField
                                name="totalInvestmentSize"
                                onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                placeholder="Yazınız"
                                maxLen={15}
                              />
                              {parseInt(form.values.totalInvestmentSize) > parseInt(form.values.lastValuation) && (
                                <Typography
                                  style={{
                                    color: '#FF0000',
                                    fontSize: '12px',
                                    marginBottom: 4,
                                    marginTop: -18,
                                    textAlign: 'left',
                                  }}
                                >
                                  {t('Son Değerlemeden Büyük Değer Girildi')}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography align="left" variant="h6">
                                {t('Son Değerleme')} ({props.detail.currency})
                              </Typography>
                              <FormikNumberField
                                name="lastValuation"
                                placeholder="Yazınız"
                                onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                maxLen={15}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography align="left" variant="h6">
                              {t('Son Fonlama Tarihi')}
                            </Typography>
                            <DatePickerField name={'lastFundingDate'} />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography align="left" variant="h6">
                              {t('Son Fonlama Tipi')}
                            </Typography>
                            <Field
                              component={FormikNewDropdown}
                              textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                              name={`lastFundingType`}
                              options={lastFundingTypeOptions}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography align="left" variant="h6">
                              {t('Son Fonlama Miktarı')} ({props.detail.currency})
                            </Typography>
                            <FormikNumberField
                              name="lastFundingSize"
                              onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                              placeholder="Yazınız"
                              maxLen={15}
                            />
                            {parseInt(form.values.lastFundingSize) > parseInt(form.values.totalInvestmentSize) && (
                              <Typography
                                style={{
                                  color: '#FF0000',
                                  fontSize: '12px',
                                  marginBottom: 4,
                                  marginTop: -18,
                                  textAlign: 'left',
                                }}
                              >
                                {t('Toplam Yatırım Miktarından Büyük Değer Girildi')}
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Field>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ filter: 'none', boxShadow: '0 0 6px 0 rgb(0 0 0 / 8%)' }}>
            <Typography variant="h5" style={{ width: 'fit-content', marginBottom: 30 }}>
              {t('Öne Çıkan Yatırımcılar')}
            </Typography>
            <Grid xs={12} item>
              <FieldArray
                name="investors"
                render={({ insert, remove, push, unshift, form }) => (
                  <>
                    <div>
                      {form.values.investors && form.values.investors.length > 0 && (
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          {form.values.investors.map((investor, index) => (
                            <InvestorPart
                              setNewInvestor={setNewInvestor}
                              investor={investor}
                              flag={index + 1 !== form.values.investors.length && newInvestor}
                              detail={props.detail}
                              remove={remove}
                              index={index}
                              type={investor.type}
                            />
                          ))}
                        </Grid>
                      )}
                      <Grid xs={12} container justify="center" style={{ marginTop: 20 }}>
                        <Button
                          variant="outlinedPrimary"
                          className={classes.AddButton}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          {t('Yeni Yatırımcı Ekle')}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              push({ title: '', link: '', logo: null, type: 'individual' });
                              setNewInvestor(true);
                            }}
                          >
                            {t('Bireysel')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              push({ title: '', link: '', logo: null, type: 'corporate' });
                              setNewInvestor(true);
                            }}
                          >
                            {t('Kurumsal')}
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </div>
                  </>
                )}
              />
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
}
