
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {DebounceInput} from 'react-debounce-input';
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import moment from 'moment'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import 'moment/locale/tr'
import Pagination from '@material-ui/lab/Pagination';
import FilterButton from '../../Components/FilterButton';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  multiSelect: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  multiSelect2:{
    borderRadius:4,
    '& div': {
      background: 'none!important',
      paddingRight:'5px!important',
      paddingLeft:'5px'
    }
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
    },
  },
  ListItemText2: {
    '& span': {
      fontSize: '14px',
      color:'#003CA5',
    },
  },
  sticker: {
    marginRight:10,
    color:'#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom:10
    },
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filter: {
    height:40,
    padding: '0 15px',
    border: '1px solid rgb(221 221 221)',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
    [theme.breakpoints.up('lg')]: {
      marginRight:'20px',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  myCheckbox:{
    padding:'5px 9px'
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'2px',
    padding: 25,
    marginBottom:50,
    marginTop:50,
  },
  tabPaper: {
    marginTop:50,
    [theme.breakpoints.up('md')]: {
      paddingLeft:50
    },
  },
  addButton: {
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    color:'#ffba00',
    marginLeft:10,
    borderColor:'#ffba00',
    backgroundColor : "#fff",
    "&:hover" : {
      backgroundColor : "#ffba00",
      color:'#fff',
      borderColor:'#ffba00',
    }
  },
  textArea: {
    width: '100%',
    borderRadius: 4,
    marginTop:30,
    '& textarea': {
        padding: 16,
        paddingRight:90,
        fontSize:13,
        lineHeight:1
      },
  },
  sendButton:{
      minWidth:50,
      height:50,
      borderRadius:'50%',
      background:'#003CA5',
      marginTop:'-80px',
      marginRight:30,
      cursor:'pointer',
      "&:hover" : {
          background:'#003CA5',
        }
  },
  exportButton:{
    fontWeight:'normal',
    padding:'6.5px 11px',
    fontSize:13,
    marginLeft:10,
    border:'1px solid #003CA5',
    borderRadius:3,
    display:'flex',
    alignItems:'center',
    color:'#003CA5',
    "&:hover" : {
      backgroundColor : "#003CA5",
      color:'#fff',
      borderColor:'#003CA5',
    }
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom:'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);


export default function AdminDrafts(props){
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [sort, setSort] = React.useState([]);
    const [drafts, setDrafts] = React.useState();
    const [order, setOrder] = React.useState("lastUserEdit__DESC");
    const [search, setSearch] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState(null);


    const ALL_DRAFTS = gql`
    query allAdminApprovals($orderBy:[String], $search: String, $page: Int, $pageSize: Int, $status:[String]) {
      allAdminApprovals(orderBy:$orderBy,search:$search, pagination:{page:$page, pageSize:$pageSize}, filter:{status:$status}){
        count
        pageCount
        data{
            id
            status
            lastUserEdit
            description
            moderationStatus
            submitBy{
                firstName
                lastName
            }
            startup{
                title
                slug
            }
        }}
    }
    `;

    const { loading, data, refetch} = useQuery(ALL_DRAFTS, {
        fetchPolicy:'network-only',
        variables: {orderBy:[order], page:page, pageSize:pageSize, search:search, status: statusFilter },
    });

    const pageSizeOptions = [3, 5, 10, 20, 50]
    const options = [
      {name:'A-Z', value:'startup__title__ASC'},
      {name:'Z-A', value:'startup__title__DESC'},
      {name:'Latest', value:'id__DESC'},
      {name:'Oldest', value:'id__ASC'},
    ];

    React.useEffect(() => {
        if(!loading){
            if(data){
              setDrafts(data.allAdminApprovals.data);
              setPageCount(data.allAdminApprovals.pageCount)
            }
        }
    },
    [data, loading])

    const filterOptions = ['Statüye Göre'];
    const filterLabels = {
      'Statüye Göre': ['İncelemeye Hazır', 'Onaylandı', 'Reddedildi'],
    };
    const filterValues = {
      'Statüye Göre': ['Pending', 'Approved', 'Rejected']
    }
    const filterInitialValues = {
      'Statüye Göre': [],
    };

  const handlePageChange = (event, value) => {
    window.scrollTo(0,0);
    setPage(value);
  };
  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };
  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };

  const handleFilter = value => {
    return setStatusFilter(value['Statüye Göre']);
  }

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
            >
              <Grid item style={{ width: '100%' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item style={{ display: 'flex' }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                      <Grid item style={{ display:'flex' }}>
                        <CreateOutlinedIcon style={{ fontSize: 20 }} />
                      </Grid>
                      <Typography variant="h4">Draftlar</Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Hidden smDown>
                    <Grid item className={classes.filterArea}>
                      <DebounceInput
                        class="inputTypeSearch"
                        name="search"
                        value={search}
                        placeholder="Ara..."
                        className={classes.Debounce}
                        minLength={2}
                        debounceTimeout={800}
                        onChange={handleSearch}
                      />
                      <FilterButton 
                      filterOptions={filterOptions}
                      filterValues={filterValues}
                      filterLabels={filterLabels}
                      initialFilter={""}
                      intialValues={filterInitialValues}
                      onChange={handleFilter}
                      />
                      <FormControl placeholder="Lütfen Seçiniz" style={{ width: '100%', maxWidth: '116px' }}>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          displayEmpty
                          value={sort}
                          className={classes.multiSelect}
                          onChange={handleSort}
                          IconComponent={(props) => (
                            <ExpandMoreIcon
                              {...props}
                              style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                            />
                          )}
                          input={<Input />}
                          /*   MenuProps={MenuProps} */
                        >
                          <MenuItem value="" disabled>
                            <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                              Sırala
                            </Typography>
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                {option.name}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Hidden>
                </Grid>
                {drafts && (
                  <>
                    {drafts.length > 0 ? (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                          <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Girişim Adı{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Gönderen Kişi{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Düzenleme Tarihi{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Yönetici Durumu{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  <Typography variant="h6" color="primary">
                                    {' '}
                                    Kullanıcı Durumu{' '}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {drafts.map((draft) => (
                                <StyledTableRow key={draft.id}>
                                  <StyledTableCell component="th" scope="row">
                                    {draft.startup.slug ? (
                                      <Typography variant="h6">
                                        <Link
                                          to={`/girisim/${draft.startup.slug}`}
                                          style={{ padding: 0, fontSize: 14 }}
                                        >
                                          {draft.startup.title ? draft.startup.title : '-'}
                                        </Link>
                                      </Typography>
                                    ) : (
                                      <Typography variant="h6">
                                        <Link to={`/girisim/${draft.startup.id}`} style={{ padding: 0, fontSize: 14 }}>
                                          {draft.startup.title ? draft.startup.title : '-'}
                                        </Link>
                                      </Typography>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Typography variant="h6">
                                      {draft.submitBy.firstName} {draft.submitBy.lastName}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {draft.lastUserEdit && (
                                      <Typography variant="body2">
                                        {moment(parseInt(draft.lastUserEdit)).format('Do-MM-YYYY')}
                                      </Typography>
                                    )}
                                  </StyledTableCell>
                                  {draft.status === 'Pending' && (
                                    <StyledTableCell align="left">
                                      <Typography variant="body2">İncelemeye Hazır</Typography>
                                    </StyledTableCell>
                                  )}
                                  {draft.status === 'Rejected' && (
                                    <StyledTableCell align="left">
                                      <Typography variant="body2">Reddedildi</Typography>
                                    </StyledTableCell>
                                  )}
                                  {draft.status === 'Approved' && (
                                    <StyledTableCell align="left">
                                      <Typography variant="body2">Onaylandı</Typography>
                                    </StyledTableCell>
                                  )}
                                  {draft.moderationStatus === 'Draft' && (
                                    <StyledTableCell align="left">
                                      <Typography variant="body2">Düzenlemeye Devam Ediliyor</Typography>
                                    </StyledTableCell>
                                  )}
                                  {draft.moderationStatus === 'Ready for moderation' && (
                                    <StyledTableCell align="left">
                                      <Typography variant="body2">Onaya Gönderildi</Typography>
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell align="left">
                                    <Grid container alignItems="center">
                                      {draft.status === 'Pending' && draft.moderationStatus === 'Ready for moderation' && (
                                        <Link to={`/draft/${draft.id}`} style={{ padding: 0 }}>
                                          <VisibilityOutlinedIcon
                                            alt="sticker"
                                            style={{ marginRight: 10, fontSize: 25 }}
                                            className={classes.sticker}
                                          />
                                        </Link>
                                      )}
                                    </Grid>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {drafts.length > 0 ? (
                          <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                            <Pagination
                              count={pageCount}
                              page={page}
                              onChange={handlePageChange}
                              variant="outlined"
                              color="primary"
                            />
                          </Grid>
                        ) : (
                          <Grid container justify="center">
                            <Typography style={{ margin: '25px 0' }} variant="h5">
                              Draft bulunamadı...
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <Grid container xs={12} className={classes.whiteCard}>
                        <Grid container justify="center">
                          <Typography style={{ margin: '25px 0' }} variant="h5">
                            Draft bulunamadı...
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </>
    );
}