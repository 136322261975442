import {Grid, Typography, withStyles, withWidth} from "@material-ui/core";

import InboxCard from "./InboxCard";
import React from "react";
import {useHistory} from "react-router-dom";

const styles = theme => ({
  root: {
    height: "100%"
  },
  menu: {
    borderRight: "2px solid  #EDF2F7",
    padding: "0px 0px",
    width: 335,
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 8px",
      width: "100%"
    }
  },

  inboxList: {
    maxHeight: "100%"
  },
  menuInside: {
    height: "100%",
    overflowY: "auto"
  },
  inboxTitle: {
    height: "66px",
    padding: "0 34px",
    borderBottom: "2px solid #EDF2F7",
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 10
  },
  unreadNumberBox: {
    backgroundColor: "#C4C4C433",
    height: "24px",
    padding: "0px 15px",
    borderRadius: "28px",
    fontSize: "11px",
    color: "#7A7A9D"
  }
});
const LeftMenu = ({data, classes, selectedConversation, setSelectedConversation, unreadConversationNumber, width}) => {
  const history = useHistory();
  const handleSelectConversation = value => {
    setSelectedConversation(value);
    history.push("/mesajlar");
    setTimeout(function () {
      var objDiv = document.getElementById("text-screen");
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }, 200);
  };
  return (
    <Grid item className={classes.menu}>
      <Grid className={classes.menuInside} container alignContent="flex-start" id="menu">
        <Grid container alignItems="center" className={classes.inboxTitle}>
          <Typography variant="h3" style={{fontSize: 14, marginRight: 8}} color="primary">
            Gelen Kutusu
          </Typography>

          {unreadConversationNumber != 0 && (
            <Typography className={classes.unreadNumberBox} variant="h4">
              {unreadConversationNumber}
            </Typography>
          )}
        </Grid>

        <Grid container className={classes.inboxList}>
          {data.map((conversation, index) => (
            <InboxCard
              key={index}
              isSelected={conversation.id === selectedConversation?.id}
              data={conversation}
              onClick={() => handleSelectConversation(conversation)}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(withWidth()(LeftMenu));
