
import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {DebounceInput} from 'react-debounce-input';
import editSvg from'../../static/img/new.svg';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Pagination from "@material-ui/lab/Pagination";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField, } from 'formik';
import FormikTextField from "../../Components/FormikTextField";
import FormikMultiLine from "../../Components/FormikMultiLine";
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormikNewDropdown from '../../Components/FormikDropdown';
import { kpiSchema } from '../../yupSchemas';
import moment from 'moment'
import Avatar from '../../static/img/default-avatar.png';
import 'moment/locale/tr'
import Popover from '@material-ui/core/Popover';
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: -drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    }, 
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
  },
  cardGrid: {
      marginTop:35
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:16,
    height:'35px',
    color:'#8898aa',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'none',
    outline:'none',
    borderBottom:'1px solid #c8c8ca',
    background:'inherit',
    marginRight:'20px',
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  TabsGeneral:{
    background: '#fff',
    borderTopLeftRadius:16,
    borderTopRightRadius:16,
    paddingLeft:30
  },
  multiSelectOrder: {
    maxHeight:40,
    minWidth:116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor:'#fff',
    '& div': {
      background: 'none!important',
    }
  },
  multiSelect: {
    minWidth:116,
    maxHeight:40,
    borderRadius:0,
    '& div': {
      background: '#fff!important',
      paddingTop:'0!important',
      '& input': {
          paddingTop:'8px!important'
      }
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color:'#8898aa',
    },
  },
  myPaper: {
    padding:25,
    width:'650px'
  },
  myLabel: {
    fontSize:14
  },
  formControl: {
    marginTop:10
  },
  selected: {
    padding: '10px 20px',
    cursor: 'pointer',
  },
  search:{
    [theme.breakpoints.up('md')]: {
      padding:'0 50px'
    },
  },
  searchBox: {
    width:'100%',
    background:'#ffffff',
    borderRadius:2,
    marginTop:5,
    boxShadow:'1px 1px 2px 1px rgba(201,201,201,1)',
    maxHeight: 130,
    overflowY:'auto',
  },
  filterArea: {
    display:'flex',
    alignItems:'flex-end',
  },
  filter: {
    height:40,
    padding: '0 15px',
    border: '1px solid rgb(221 221 221)',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    justifyContent:'center',
  },
  kpiCat: {
    padding: '10px 20px',
    cursor: 'pointer',
    "&:hover" : {
      backgroundColor:'rgb(244, 249, 255)'
    }
  },
  whiteCard: {
    background:'#ffffff',
    borderRadius:'4px',
    padding: 25,
    marginBottom:50,
  },
  tabPaper: {
    marginTop:50,
  },
  sticker: {
    marginRight:10,
    color:'#636973',
    [theme.breakpoints.down('sm')]: {
      marginBottom:10
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    color: '#8492A6',
    fontSize:11,
    padding: '16px 24px',
  },
  body: {
    fontSize: 14,
    borderBottom:'none',
    padding: '12px 0px 12px 24px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
  },
}))(TableRow);

const FormikCatDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10}}
          {...props}
          {...field}
          options={props.options}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } placeholder="Lütfen Seçiniz"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

function AddCategoryDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch } = props;

  const handleSubmit = variables => {
    props.adminKPICategoryCreate({ variables })
      .then(data => {
        if (data.data.adminKPICategoryCreate) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Kategori Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        title: '',
                        order: '',
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Kategori Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Kategori Adı" />
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Kategori Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Kategori Sırası" />
                                </Grid>   
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddCategoryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddSetDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch } = props;

  const handleSubmit = variables => {
    props.adminKPISetCreate({ variables })
      .then(data => {
        if (data.data.adminKPISetCreate) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Set Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        title: '',
                        order: '',
                        description:'',
                        subtitle:''
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Set Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Set Adı" />
                                </Grid> 
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Alt Başlık</Typography> 
                                  <FormikTextField name="subtitle" style={{width:'100%',marginBottom:20}} type="text" placeholder="Alt Başlık" />
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Açıklama</Typography>  
                                  <FormikMultiLine style={{width:'100%',marginBottom:20}} name="description" rows={4} type="text" placeholder="Açıklama.."/>  
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Set Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Set Sırası" />
                                </Grid>   
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddSetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddKPIDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch } = props;
  const [cats, setCats] = React.useState();
  const frequency = ['Yıllık', 'Çeyreklik', 'Aylık', 'Haftalık', 'Günlük']

  const GET_CATEGORY = gql`
  query adminKPICategoryList($page: Int $pageSize: Int) {
    adminKPICategoryList(pagination:{page:$page, pageSize:$pageSize} ){
      count
      pageCount
      data{
        id
        title
      }
    }
  }
  `;
  const { loading:loadingCat , data:dataCat , refetch:refetchCat } = useQuery(GET_CATEGORY, {
      fetchPolicy:"network-only",
      variables: {page:1, pageSize:100},
  });

  const handleSubmit = variables => {
    variables.categoryId = variables.categoryId.id
    props.adminKPICreate({ variables })
      .then(data => {
        if (data.data.adminKPICreate) {
          refetch()
          onClose(null);
        } 
      })
  };
  React.useEffect(() => {
    if(!loadingCat){
      if(dataCat){
        setCats(dataCat.adminKPICategoryList.data);
      }
    }
  },
  [dataCat, loadingCat])

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">KPI Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      validationSchema={kpiSchema}
                      initialValues={{
                        title: '',
                        description: '',
                        categoryId:'',
                        order:'',
                        dataFrequency: '',
                        dataFormat:''
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>KPI Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="KPI Adı" />
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Açıklama</Typography>  
                                  <FormikMultiLine style={{width:'100%',marginBottom:20}} name="description" rows={4} type="text" placeholder="Açıklama.."/>  
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:40}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori</Typography>  
                                  {cats && <Field component={FormikCatDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="categoryId" options={cats}></Field> }
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Kategori Sırası" />
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Veri Girilme Sıklığı</Typography>  
                                  <Field component={FormikNewDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="dataFrequency" options={frequency}></Field>  
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Veri Girilme Formatı</Typography> 
                                  <FormikTextField name="dataFormat" style={{width:'100%',marginBottom:20}} type="text" placeholder="Veri Girilme Formatı" />
                                </Grid>          
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} disabled={!props.isValid || (Object.keys(props.touched).length === 0 && props.touched.constructor === Object)} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddKPIDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddKPIGADialog(props) {
  const classes = useStyles();
  const { onClose, open, refetchGA } = props;
  const [cats, setCats] = React.useState();
  const frequency = ['Yıllık', 'Çeyreklik', 'Aylık', 'Haftalık', 'Günlük']

  const GET_CATEGORY = gql`
  query adminKPICategoryList($page: Int $pageSize: Int) {
    adminKPICategoryList(pagination:{page:$page, pageSize:$pageSize} ){
      count
      pageCount
      data{
        id
        title
      }
    }
  }
  `;
  const { loading:loadingCat , data:dataCat , refetch:refetchCat } = useQuery(GET_CATEGORY, {
      fetchPolicy:"network-only",
      variables: {page:1, pageSize:100},
  });

  const handleSubmit = variables => {
    variables.categoryId = variables.categoryId.id
    props.adminKPIGACreate({ variables })
      .then(data => {
        if (data.data.adminKPIGACreate) {
          refetchGA()
          onClose(null);
        } 
      })
  };
  React.useEffect(() => {
    if(!loadingCat){
      if(dataCat){
        setCats(dataCat.adminKPICategoryList.data);
      }
    }
  },
  [dataCat, loadingCat])

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">GA KPI Ekle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      validationSchema={kpiSchema}
                      initialValues={{
                        title: '',
                        description: '',
                        categoryId:'',
                        order:'',
                        dataFrequency: '',
                        dataFormat:'',
                        metric:'',
                        dimension:''
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>KPI Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="KPI Adı" />
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Açıklama</Typography>  
                                  <FormikMultiLine style={{width:'100%',marginBottom:20}} name="description" rows={4} type="text" placeholder="Açıklama.."/>  
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:40}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori</Typography>  
                                  {cats && <Field component={FormikCatDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="categoryId" options={cats}></Field> }
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Kategori Sırası" />
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Veri Girilme Sıklığı</Typography>  
                                  <Field component={FormikNewDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="dataFrequency" options={frequency}></Field>  
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Veri Girilme Formatı</Typography> 
                                  <FormikTextField name="dataFormat" style={{width:'100%',marginBottom:20}} type="text" placeholder="Veri Girilme Formatı" />
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Metric</Typography> 
                                  <FormikTextField name="metric" style={{width:'100%',marginBottom:20}} type="text" placeholder="Veri Girilme Formatı" />
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Dimension</Typography> 
                                  <FormikTextField name="dimension" style={{width:'100%',marginBottom:20}} type="text" placeholder="Veri Girilme Formatı" />
                                </Grid>              
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} disabled={!props.isValid || (Object.keys(props.touched).length === 0 && props.touched.constructor === Object) || !props.values.metric || !props.values.dimension} onClick={() => handleSubmit(props.values)} >Ekle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddKPIGADialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditCategoryDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, cat } = props;

  const handleSubmit = variables => {
    props.adminKPICategoryEdit({ variables })
      .then(data => {
        if (data.data.adminKPICategoryEdit) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Kategori Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        title: cat.title,
                        categoryId:cat.id,
                        order: cat.order,
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Kategori Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Kategori Adı" />
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Kategori Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Kategori Sırası" />
                                </Grid>   
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

function EditSetDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, set } = props;

  const handleSubmit = variables => {
    props.adminKPISetEdit({ variables })
      .then(data => {
        if (data.data.adminKPISetEdit) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Set Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      initialValues={{
                        title: set.title,
                        setId:set.id,
                        order: set.order,
                        description:set.description,
                        subtitle:set.subtitle,
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Set Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="Set Adı" />
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Alt Başlık</Typography> 
                                  <FormikTextField name="subtitle" style={{width:'100%',marginBottom:20}} type="text" placeholder="Alt Başlık" />
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Açıklama</Typography>  
                                  <FormikMultiLine style={{width:'100%',marginBottom:20}} name="description" rows={4} type="text" placeholder="Açıklama.."/>  
                                </Grid>  
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Set Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Set Sırası" />
                                </Grid>   
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditSetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditKPIDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, kpi } = props;
  const [cats, setCats] = React.useState();
  const frequency = ['Yıllık', 'Çeyreklik', 'Aylık', 'Haftalık', 'Günlük']

  const GET_CATEGORY = gql`
  query adminKPICategoryList($page: Int $pageSize: Int) {
    adminKPICategoryList(pagination:{page:$page, pageSize:$pageSize} ){
      count
      pageCount
      data{
        id
        title
      }
    }
  }
  `;
  const { loading:loadingCat , data:dataCat , refetch:refetchCat } = useQuery(GET_CATEGORY, {
      fetchPolicy:"network-only",
      variables: {page:1, pageSize:100},
  });

  const handleSubmit = variables => {
    variables.categoryId = variables.categoryId.id
    props.adminKPIEdit({ variables })
      .then(data => {
        if (data.data.adminKPIEdit) {
          refetch()
          onClose(null);
        } 
      })
  };
  React.useEffect(() => {
    if(!loadingCat){
      if(dataCat){
        setCats(dataCat.adminKPICategoryList.data);
      }
    }
  },
  [dataCat, loadingCat])

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">KPI Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      validationSchema={kpiSchema}
                      initialValues={{
                        kpiId:kpi.id,
                        title: kpi.title,
                        description: kpi.description,
                        order: kpi.order,
                        categoryId:kpi.category,
                        dataFrequency: kpi.dataFrequency,
                        dataFormat:kpi.dataFormat
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>KPI Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="KPI Adı" />
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Açıklama</Typography>  
                                  <FormikMultiLine style={{width:'100%',marginBottom:20}} name="description" rows={4} type="text" placeholder="Açıklama.."/>  
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:40}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori</Typography>  
                                  {cats && <Field component={FormikCatDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="categoryId" options={cats}></Field> }
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Kategori Sırası" />
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Veri Girilme Sıklığı</Typography>  
                                  <Field component={FormikNewDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="dataFrequency" options={frequency}></Field>  
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Veri Girilme Formatı</Typography> 
                                  <FormikTextField name="dataFormat" style={{width:'100%',marginBottom:20}} type="text" placeholder="Grup Adı" />
                                </Grid>          
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" disabled={!props.isValid} style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditKPIDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditKPIGADialog(props) {
  const classes = useStyles();
  const { onClose, open, refetchGA, kpiGA } = props;
  const [cats, setCats] = React.useState();
  const frequency = ['Yıllık', 'Çeyreklik', 'Aylık', 'Haftalık', 'Günlük']

  const GET_CATEGORY = gql`
  query adminKPICategoryList($page: Int $pageSize: Int) {
    adminKPICategoryList(pagination:{page:$page, pageSize:$pageSize} ){
      count
      pageCount
      data{
        id
        title
      }
    }
  }
  `;
  const { loading:loadingCat , data:dataCat , refetch:refetchCat } = useQuery(GET_CATEGORY, {
      fetchPolicy:"network-only",
      variables: {page:1, pageSize:100},
  });

  const handleSubmit = variables => {
    variables.categoryId = variables.categoryId.id
    props.adminKPIGAEdit({ variables })
      .then(data => {
        if (data.data.adminKPIGAEdit) {
          refetchGA()
          onClose(null);
        } 
      })
  };
  React.useEffect(() => {
    if(!loadingCat){
      if(dataCat){
        setCats(dataCat.adminKPICategoryList.data);
      }
    }
  },
  [dataCat, loadingCat])

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">GA KPI Düzenle</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
                      validationSchema={kpiSchema}
                      initialValues={{
                        kpiId:kpiGA.id,
                        title: kpiGA.title,
                        description: kpiGA.description,
                        order: kpiGA.order,
                        categoryId:kpiGA.category,
                        dataFrequency: kpiGA.dataFrequency,
                        dataFormat:kpiGA.dataFormat,
                        metric:kpiGA.metric,
                        dimension:kpiGA.dimension
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>KPI Adı</Typography> 
                                  <FormikTextField name="title" style={{width:'100%',marginBottom:20}} type="text" placeholder="KPI Adı" />
                                </Grid> 
                                <Grid item container xs={12} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{width:'100%'}}>Açıklama</Typography>  
                                  <FormikMultiLine style={{width:'100%',marginBottom:20}} name="description" rows={4} type="text" placeholder="Açıklama.."/>  
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:40}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori</Typography>  
                                  {cats && <Field component={FormikCatDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="categoryId" options={cats}></Field> }
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Kategori Sırası</Typography>  
                                  <FormikTextField name="order" style={{width:'100%',marginBottom:20}} type="number" placeholder="Kategori Sırası" />
                                </Grid>
                                <Grid item container xs={12} style={{height:'fit-content',marginBottom:20}}>
                                  <Typography variant="h6" style={{width:'100%',marginBottom:10}}>Veri Girilme Sıklığı</Typography>  
                                  <Field component={FormikNewDropdown} style={{width:'100%'}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="dataFrequency" options={frequency}></Field>  
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Veri Girilme Formatı</Typography> 
                                  <FormikTextField name="dataFormat" style={{width:'100%',marginBottom:20}} type="text" placeholder="Grup Adı" />
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Metric</Typography> 
                                  <FormikTextField name="metric" style={{width:'100%',marginBottom:20}} type="text" placeholder="Grup Adı" />
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography variant="h6" style={{width:'100%'}}>Dimension</Typography> 
                                  <FormikTextField name="dimension" style={{width:'100%',marginBottom:20}} type="text" placeholder="Grup Adı" />
                                </Grid>              
                                <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" disabled={!props.isValid || !props.values.metric || !props.values.dimension} style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} >Düzenle</Button>
                                </Grid>
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditKPIGADialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteCategoryDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, cat } = props;

  const handleSubmit = () => {
    props.adminKPICategoryDelete({ variables: { categoryId: cat.id } })
      .then(data => {
        if (data.data.adminKPICategoryDelete) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {cat.kpiCount === 0 ?
      <>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Kategori Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Silme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
      :
      <>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Silinemiyor!</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="center" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Bu kategoriyi silemezsin çünkü
            kategoriye eklenmiş KPI'lar var!</Typography>
            <Grid item xs={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
            </Grid>
          </Grid>
        </Grid>
      </>
      }
    </Dialog>
  );
}

DeleteCategoryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteSetDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, set } = props;

  const handleSubmit = () => {
    props.adminKPISetDelete({ variables: { setId: set.id } })
      .then(data => {
        if (data.data.adminKPISetDelete) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {set.kpiCount === 0 ?
      <>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Set Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Silme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
      :
      <>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">Silinemiyor!</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="center" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Bu Seti silemezsin çünkü
            kategoriye eklenmiş KPI'lar var!</Typography>
            <Grid item xs={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
            </Grid>
          </Grid>
        </Grid>
      </>
      }
    </Dialog>
  );
}

DeleteSetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteKPIDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, kpi } = props;

  const handleSubmit = () => {
    props.adminKPIDelete({ variables: { kpiId: kpi.id } })
      .then(data => {
        if (data.data.adminKPIDelete) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">KPI Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Silme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Dialog>
  );
}

DeleteKPIDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function DeleteKPIGADialog(props) {
  const classes = useStyles();
  const { onClose, open, refetchGA, kpiGA } = props;

  const handleSubmit = () => {
    props.adminKPIGADelete({ variables: { kpiId: kpiGA.id } })
      .then(data => {
        if (data.data.adminKPIGADelete) {
          refetchGA()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="xs" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
          <Typography align="center" variant="h4">GA KPI Sil</Typography>
        </DialogTitle>
        <Grid container justify="center" style={{marginBottom:50}} xs={12}>
          <Grid container justify="space-between" xs={9}>
            <Typography align="center" variant="body1" style={{color:'#777777'}}>Silme onayı hakkında buraya
            gelecek iki satırlık yazı alanı.</Typography>
            <Grid container xs={12} spacing={2} style={{margin:0}}>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20}} onClick={() => onClose(null)} variant="outlinedSecondary">Vazgeç</Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button style={{fontWeight:'normal',padding:'11.5px 15px',fontSize:14,width:'100%',marginTop:20,background:'#ff5757',"&:hover" : {background : "#ff5757"}}} onClick={() => handleSubmit()} variant="containedPrimary">Sil</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Dialog>
  );
}

DeleteKPIDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AddKPISetDialog(props) {
  const classes = useStyles();
  const { onClose, open, set, refetch } = props;
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(set.kpis);
  const [KPIs, setKPIs] = React.useState([]);

const SEARCH_KPI= gql`
query getKPIListsSearch($search: String){
    getKPIListsSearch(search:$search){
      id
      title
    }
}
`;
const GET_KPI = gql`
query adminKPIList($page: Int, $pageSize: Int, $search: String) {
  adminKPIList(pagination:{page:$page, pageSize:$pageSize}, search:$search ){
    count
    pageCount
    data{
      id
      title
    }
    }
}
`;
const { loading:loadingKPI , data:dataKPI , refetch:refetchKPI } = useQuery(GET_KPI, {
    fetchPolicy:"network-only",
    variables: {page:1, pageSize:props.countKPI},
});

React.useEffect(() => {
  if(!loadingKPI){
    if(dataKPI){
      setKPIs(dataKPI.adminKPIList.data);
    }
  }
},
[loadingKPI,dataKPI])
const[getData, { loading:loadingSearch, data:dataSearch } ]= useLazyQuery(SEARCH_KPI, {
  fetchPolicy:'network-only',
  variables: {search:search},
});

  const handleSearch = (event) => {
    setSearch(event.target.value);
    getData()
  };

  const handleClose = () => {
    onClose(null);
  };

  const sendList= (value) => {
    if (value !== []){
      props.adminKPISetAddKPI({ variables: { kpiIds:value, setId:set.id } })
        .then(data => {
          if (data.data.adminKPISetAddKPI) {
            props.refetch();
            onClose()
          }
        });
    }
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="center" xs={12} style={{ padding: 35 }}>
      <Typography variant="h4" style={{ fontWeight: '500' }}>
                    {set.title}
      </Typography>
        <Grid container justify="center" style={{ marginTop: 35 }}>
          <Grid container xs={12} md={5} className={classes.search}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: 10 }}>
                KPI Ara
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DebounceInput
                class="inputTypeSearch"
                name="search"
                value={search}
                style={{maxWidth:'100%'}}
                placeholder="KPI arayınız…"
                className={classes.Debounce}
                minLength={2}
                debounceTimeout={800}
                onChange={handleSearch}
              />
              {search && dataSearch && (
                <Grid className={classes.searchBox}>
                  <List component="nav" aria-label="secondary mailbox folders">

                    {dataSearch.getKPIListsSearch.map((searchResult) => (
                      <Grid>
                        {selected.some((e) => e.id === searchResult.id) ? (
                          <ListItem
                            onClick={() => setSelected(selected.filter((x) => x.id !== searchResult.id))}
                            button
                            style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                          >
                            <Grid container>
                              <Typography variant="body1" color="primary">
                                {searchResult.title}
                              </Typography>
                            </Grid>
                          </ListItem>
                        ) : (
                          <ListItem
                            onClick={() => setSelected([...selected, searchResult])}
                            button
                            style={{ minHeight: 50 }}
                          >
                            <Grid container>
                              <Typography variant="body1" color="primary">
                                {searchResult.title}
                              </Typography>
                            </Grid>
                          </ListItem>
                        )}
                      </Grid>
                    ))}
                    {dataSearch.getKPIListsSearch.length === 0 && (
                      <ListItem>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <Typography align="center" variant="body1">
                            Aradığın KPI'yı bulamadık...
                          </Typography>
                        </Grid>
                      </ListItem>
                    )}
                  </List>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 35 }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              borderRight: 'solid 1px #eeeeee',
              borderLeft: 'solid 1px #eeeeee',
              borderBottom: 'solid 1px #eeeeee',
              marginBottom: 20,
            }}
          >
            <Typography align="center" color="primary" variant="h6" style={{ marginBottom: 10 }}>
              KPI LIST
            </Typography>
            <Grid item xs={12} style={{ borderTop: 'solid 1px #eeeeee', maxHeight: 250, overflowY: 'scroll' }}>
              {KPIs.map((detail) => (
                <>
                  {selected.some((e) => e.id === detail.id) ? (
                    <Grid
                      container
                      justify="space-between"
                      style={{ backgroundColor: 'rgb(244, 249, 255)' }}
                      className={classes.kpiCat}
                      onClick={() => setSelected(selected.filter((x) => x.id !== detail.id))}
                    >
                      <Typography variant="body2">{detail.title}</Typography>
                      <AddCircleOutlineIcon style={{ fontSize: 18 }} />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="space-between"
                      className={classes.kpiCat}
                      onClick={() => setSelected([...selected, detail])}
                    >
                      <Typography variant="body2">{detail.title}</Typography>
                      <AddCircleOutlineIcon style={{ fontSize: 18 }} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              borderRight: 'solid 1px #eeeeee',
              borderLeft: 'solid 1px #eeeeee',
              marginBottom: 20,
              borderBottom: 'solid 1px #eeeeee',
            }}
          >
            <Typography align="center" color="primary" variant="h6" style={{ marginBottom: 10 }}>
              SEÇİLİ KPILAR
            </Typography>
            <Grid item xs={12} style={{ borderTop: 'solid 1px #eeeeee', maxHeight: 250, overflowY: 'scroll' }}>
              {selected.map((select) => (
                <Grid
                  container
                  justify="space-between"
                  className={classes.selected}
                  onClick={() => setSelected(selected.filter((x) => x.id !== select.id))}
                >
                  <Typography variant="body2">{select.title}</Typography>
                  <CloseIcon style={{ fontSize: 18, color: '#ff5757' }} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Button
              variant="outlinedSecondary"
              style={{
                fontWeight: 'normal',
                marginTop: 35,
                padding: '10.5px 50px',
                fontSize: 16,
                marginRight: 15,
              }}
              onClick={() => (onClose(null), setSelected(set.kpis))}
            >
              Vazgeç
            </Button>
            <Button
              variant="containedPrimary"
              style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
              onClick={() => sendList(selected.map((a) => a.id))}
            >
              Oluştur
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddKPISetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


function AddKPICatDialog(props) {
  const classes = useStyles();
  const { onClose, open, cat, refetch } = props;
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(cat.lists);
  const [KPIs, setKPIs] = React.useState([]);

const SEARCH_KPI= gql`
query getKPIListsSearch($search: String){
    getKPIListsSearch(search:$search){
      id
      title
    }
}
`;
const GET_KPI = gql`
query adminKPIList($page: Int, $pageSize: Int, $search: String) {
  adminKPIList(pagination:{page:$page, pageSize:$pageSize}, search:$search ){
    count
    pageCount
    data{
      id
      title
    }
    }
}
`;
const { loading:loadingKPI , data:dataKPI , refetch:refetchKPI } = useQuery(GET_KPI, {
    fetchPolicy:"network-only",
    variables: {page:1, pageSize:props.countKPI},
});

React.useEffect(() => {
  if(!loadingKPI){
    if(dataKPI){
      setKPIs(dataKPI.adminKPIList.data);
    }
  }
},
[loadingKPI,dataKPI])
const[getData, { loading:loadingSearch, data:dataSearch } ]= useLazyQuery(SEARCH_KPI, {
  fetchPolicy:'network-only',
  variables: {search:search},
});

  const handleSearch = (event) => {
    setSearch(event.target.value);
    getData()
  };

  const handleClose = () => {
    onClose(null);
  };

  const sendList= (value) => {
    if (value !== []){
      props.adminKPICategoryAddKPI({ variables: { kpiIds:value, categoryId:cat.id } })
        .then(data => {
          if (data.data.adminKPICategoryAddKPI) {
            props.refetch();
            onClose()
          }
        });
    }
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="md"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Grid container justify="center" xs={12} style={{ padding: 35 }}>
      <Typography variant="h4" style={{ fontWeight: '500' }}>
                    {cat.title}
      </Typography>
        <Grid container justify="center" style={{ marginTop: 35 }}>
          <Grid container xs={12} md={5} className={classes.search}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: 10 }}>
                KPI Ara
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DebounceInput
                class="inputTypeSearch"
                name="search"
                value={search}
                style={{maxWidth:'100%'}}
                placeholder="KPI arayınız…"
                className={classes.Debounce}
                minLength={2}
                debounceTimeout={800}
                onChange={handleSearch}
              />
              {search && dataSearch && (
                <Grid className={classes.searchBox}>
                  <List component="nav" aria-label="secondary mailbox folders">
                    {dataSearch.getKPIListsSearch.map((searchResult) => (
                      <Grid>
                        {selected.some((e) => e.id === searchResult.id) ? (
                          <ListItem
                            onClick={() => setSelected(selected.filter((x) => x.id !== searchResult.id))}
                            button
                            style={{ minHeight: 50, backgroundColor: 'rgb(244, 249, 255)' }}
                          >
                            <Grid container>
                              <Typography variant="body1" color="primary">
                                {searchResult.title}
                              </Typography>
                            </Grid>
                          </ListItem>
                        ) : (
                          <ListItem
                            onClick={() => setSelected([...selected, searchResult])}
                            button
                            style={{ minHeight: 50 }}
                          >
                            <Grid container>
                              <Typography variant="body1" color="primary">
                                {searchResult.title}
                              </Typography>
                            </Grid>
                          </ListItem>
                        )}
                      </Grid>
                    ))}
                    {dataSearch.getKPIListsSearch.length === 0 && (
                      <ListItem>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <Typography align="center" variant="body1">
                            Aradığın KPI'yı bulamadık...
                          </Typography>
                        </Grid>
                      </ListItem>
                    )}
                  </List>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 35 }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              borderRight: 'solid 1px #eeeeee',
              borderLeft: 'solid 1px #eeeeee',
              borderBottom: 'solid 1px #eeeeee',
              marginBottom: 20,
            }}
          >
            <Typography align="center" color="primary" variant="h6" style={{ marginBottom: 10 }}>
              KPI LIST
            </Typography>
            <Grid item xs={12} style={{ borderTop: 'solid 1px #eeeeee', maxHeight: 250, overflowY: 'scroll' }}>
              {KPIs.map((detail) => (
                <>
                  {selected.some((e) => e.id === detail.id) ? (
                    <Grid
                      container
                      justify="space-between"
                      style={{ backgroundColor: 'rgb(244, 249, 255)' }}
                      className={classes.kpiCat}
                      onClick={() => setSelected(selected.filter((x) => x.id !== detail.id))}
                    >
                      <Typography variant="body2">{detail.title}</Typography>
                      <AddCircleOutlineIcon style={{ fontSize: 18 }} />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      justify="space-between"
                      className={classes.kpiCat}
                      onClick={() => setSelected([...selected, detail])}
                    >
                      <Typography variant="body2">{detail.title}</Typography>
                      <AddCircleOutlineIcon style={{ fontSize: 18 }} />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              borderRight: 'solid 1px #eeeeee',
              borderLeft: 'solid 1px #eeeeee',
              marginBottom: 20,
              borderBottom: 'solid 1px #eeeeee',
            }}
          >
            <Typography align="center" color="primary" variant="h6" style={{ marginBottom: 10 }}>
              SEÇİLİ KPILAR
            </Typography>
            <Grid item xs={12} style={{ borderTop: 'solid 1px #eeeeee', maxHeight: 250, overflowY: 'scroll' }}>
              {selected.map((select) => (
                <Grid
                  container
                  justify="space-between"
                  className={classes.selected}
                  onClick={() => setSelected(selected.filter((x) => x.id !== select.id))}
                >
                  <Typography variant="body2">{select.title}</Typography>
                  <CloseIcon style={{ fontSize: 18, color: '#ff5757' }} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Button
              variant="outlinedSecondary"
              style={{
                fontWeight: 'normal',
                marginTop: 35,
                padding: '10.5px 50px',
                fontSize: 16,
                marginRight: 15,
              }}
              onClick={() => (onClose(null), setSelected(cat.lists))}
            >
              Vazgeç
            </Button>
            <Button
              variant="containedPrimary"
              style={{ fontWeight: 'normal', marginTop: 35, padding: '10.5px 50px', fontSize: 16 }}
              onClick={() => sendList(selected.map((a) => a.id))}
            >
              Oluştur
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddKPICatDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


function ExpandingRowCategory(props){
  const classes = useStyles();
  const [showEditCategory, setShowEditCategory] = React.useState(false);
  const [addKPICat, setAddKPICat] = React.useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = React.useState(false);

  const { cat, countKPI } = props;
  return(
    <>
     <StyledTableRow key={cat.id}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{cat.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{cat.kpiCount}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{cat.order}</Typography></StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid style={{cursor:'pointer'}} onClick={() => setAddKPICat(true)}><AddCircleOutlineIcon alt="sticker" style={{marginRight:10,fontSize:25}} className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowEditCategory(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}} alt="sticker" className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowDeleteCategory(true)}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        {countKPI && 
        <AddKPICatDialog countKPI={countKPI} cat={cat} adminKPICategoryAddKPI={props.adminKPICategoryAddKPI} refetch={props.refetchCat} open={addKPICat} onClose={() => setAddKPICat(false)}/>  
        }
        <EditCategoryDialog adminKPICategoryEdit={props.adminKPICategoryEdit} cat={cat} refetch={props.refetchCat} open={showEditCategory} onClose={() => setShowEditCategory(false)}/>   
        <DeleteCategoryDialog adminKPICategoryDelete={props.adminKPICategoryDelete} cat={cat} refetch={props.refetchCat} open={showDeleteCategory} onClose={() => setShowDeleteCategory(false)}/>                                 
      </StyledTableRow>
    </>
  )
}

function ExpandingRowSet(props){
  const classes = useStyles();
  const [showEditSet, setShowEditSet] = React.useState(false);
  const [addKPISet, setAddKPISet] = React.useState(false);
  const [showDeleteSet, setShowDeleteSet] = React.useState(false);

  const { set, countKPI } = props;
  return(
    <>
     <StyledTableRow key={set.id}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{set.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{set.kpiCount}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{set.order}</Typography></StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid style={{cursor:'pointer'}} onClick={() => setAddKPISet(true)}><AddCircleOutlineIcon alt="sticker" style={{marginRight:10,fontSize:25}} className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowEditSet(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}} alt="sticker" className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowDeleteSet(true)}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        {countKPI && 
        <AddKPISetDialog countKPI={countKPI} set={set} adminKPISetAddKPI={props.adminKPISetAddKPI} refetch={props.refetchSet} open={addKPISet} onClose={() => setAddKPISet(false)}/>  
        }
        <EditSetDialog adminKPISetEdit={props.adminKPISetEdit} set={set} refetch={props.refetchSet} open={showEditSet} onClose={() => setShowEditSet(false)}/>   
        <DeleteSetDialog adminKPISetDelete={props.adminKPISetDelete} set={set} refetch={props.refetchSet} open={showDeleteSet} onClose={() => setShowDeleteSet(false)}/>                                 
      </StyledTableRow>
    </>
  )
}

function ExpandingRowKPI(props){
  const classes = useStyles();
  const [showEditKPI, setShowEditKPI] = React.useState(false);
  const [showDeleteKPI, setShowDeleteKPI] = React.useState(false);

  const { kpi } = props;
  
  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }
  return(
    <>
     <StyledTableRow key={kpi.id}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{kpi.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">{kpi.description && <Typography variant="h6" style={{fontSize:14}}>{truncateString(kpi.description,50)}</Typography>}</StyledTableCell>
        <StyledTableCell align="left">{kpi.category && <Typography variant="body2">{kpi.category.title}</Typography>}</StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{kpi.dataFrequency}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{kpi.dataFormat}</Typography></StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid style={{cursor:'pointer'}} onClick={() => setShowEditKPI(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}} alt="sticker" className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowDeleteKPI(true)}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        <EditKPIDialog adminKPIEdit={props.adminKPIEdit} kpi={kpi} refetch={props.refetchKPI} open={showEditKPI} onClose={() => setShowEditKPI(false)}/>   
        <DeleteKPIDialog adminKPIDelete={props.adminKPIDelete} kpi={kpi} refetch={props.refetchKPI} open={showDeleteKPI} onClose={() => setShowDeleteKPI(false)}/>                                 
      </StyledTableRow>
    </>
  )
}

function ExpandingRowKPIGA(props){
  const classes = useStyles();
  const [showEditKPI, setShowEditKPI] = React.useState(false);
  const [showDeleteKPI, setShowDeleteKPI] = React.useState(false);

  const { kpiGA } = props;
  
  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }
  return(
    <>
     <StyledTableRow key={kpiGA.id}>
        <StyledTableCell component="th" scope="row">
        <Typography variant="h6" style={{fontSize:14}}>{kpiGA.title}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">{kpiGA.description && <Typography variant="h6" style={{fontSize:14}}>{truncateString(kpiGA.description,50)}</Typography>}</StyledTableCell>
        <StyledTableCell align="left">{kpiGA.category && <Typography variant="body2">{kpiGA.category.title}</Typography>}</StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{kpiGA.dataFrequency}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{kpiGA.dataFormat}</Typography></StyledTableCell>
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid style={{cursor:'pointer'}} onClick={() => setShowEditKPI(true)}><img src={editSvg} style={{width:20,height:19,marginRight:5}} alt="sticker" className={classes.sticker} /></Grid>
            <Grid style={{cursor:'pointer'}} onClick={() => setShowDeleteKPI(true)}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25}} className={classes.sticker} /></Grid>
          </Grid>
        </StyledTableCell>
        <EditKPIGADialog adminKPIGAEdit={props.adminKPIGAEdit} kpiGA={kpiGA} refetchGA={props.refetchKPIGA} open={showEditKPI} onClose={() => setShowEditKPI(false)}/>   
        <DeleteKPIGADialog adminKPIGADelete={props.adminKPIGADelete} kpiGA={kpiGA} refetchGA={props.refetchKPIGA} open={showDeleteKPI} onClose={() => setShowDeleteKPI(false)}/>                                 
      </StyledTableRow>
    </>
  )
}

function ExpandingRowSug(props) {
  const { sug, refetch } = props;
  const sendStatus = (value) => {
    if (value !== '') {
      props.adminKPISuggestHandle({ variables: { suggestIds: [sug.id], status: value } }).then((data) => {
        if (data.data.adminKPISuggestHandle) {
          refetch();
        }
      });
    }
  };
  return (
    <>
      <StyledTableRow key={sug.id}>
        <StyledTableCell component="th" scope="row">
          <Grid container alignItems="center">
            {sug.user.profilePhoto ? (
              <img
                src={sug.user.profilePhoto}
                alt="logo"
                style={{ width: 35, height: 35, marginRight: 10, borderRadius: '25%', border: 'solid 1px #eaedf3' }}
              />
            ) : (
              <Grid
              style={{
                width: 35,
                height: 35,
                borderRadius: '25%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#003CA5',
                marginRight: 10,
              }}
            >
              <Typography variant="h3" style={{ color: '#fff' }}>
                {sug.user.firstName && sug.user.firstName.charAt(0)}
              </Typography>
            </Grid>
            )}
            <Typography variant="h6">
              {sug.user.firstName} {sug.user.lastName}
            </Typography>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">{sug.text}</Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Typography variant="body2">
            {' '}
            {moment(parseInt(sug.createdAt)).locale('tr').format('Do MMMM YYYY')}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="left">
          {sug.status === 'Unread' ? (
            <Typography variant="body2">Bekleyen Öneri</Typography>
          ) : (
            <Typography variant="body2">Onaylı Öneri</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {sug.status === 'Unread' && (
            <Grid container direction="column" style={{ width: 'fit-content' }}>
              <Button
                style={{ padding: '10.5px 20px', fontSize: 13, fontWeight: 'normal' }}
                onClick={() => {
                  sendStatus('Read');
                }}
                variant="containedPrimary"
              >
                Onayla
              </Button>
            </Grid>
          )}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

export default function AdminKPI(props){
    const classes = useStyles();
    const [pageCat, setPageCat] = React.useState(1);
    const [pageCountCat, setPageCountCat] = React.useState(1);
    const [pageSizeCat, setPageSizeCat] = React.useState(10);
    const [pageKPI, setPageKPI] = React.useState(1);
    const [pageCountKPI, setPageCountKPI] = React.useState(1);
    const [pageSizeKPI, setPageSizeKPI] = React.useState(10);
    const [pageKPIGA, setPageKPIGA] = React.useState(1);
    const [pageCountKPIGA, setPageCountKPIGA] = React.useState(1);
    const [pageSizeKPIGA, setPageSizeKPIGA] = React.useState(10);
    const [pageSet, setPageSet] = React.useState(1);
    const [pageCountSet, setPageCountSet] = React.useState(1);
    const [pageSizeSet, setPageSizeSet] = React.useState(10);
    const [pageSug, setPageSug] = React.useState(1);
    const [pageCountSug, setPageCountSug] = React.useState(1);
    const [pageSizeSug, setPageSizeSug] = React.useState(10);
    const [kpis, setKPIs] = React.useState();
    const [kpisGA, setKPIsGA] = React.useState();
    const [sets, setSets] = React.useState();
    const [countKPI, setCountKPI] = React.useState(0);
    const [cats, setCats] = React.useState();
    const [sugs, setSugs] = React.useState();
    const [searchCat, setSearchCat] = React.useState("");
    const [searchKPI, setSearchKPI] = React.useState("");
    const [searchKPIGA, setSearchKPIGA] = React.useState("");
    const [searchSet, setSearchSet] = React.useState("");
    const [searchSug, setSearchSug] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addCat, setAddCat] = React.useState(false);
    const [addKPI, setAddKPI] = React.useState(false);
    const [addKPIGA, setAddKPIGA] = React.useState(false);
    const [addSet, setAddSet] = React.useState(false);
    const [sortCat, setSortCat] = React.useState([]);
    const [sortKPI, setSortKPI] = React.useState([]);
    const [sortKPIGA, setSortKPIGA] = React.useState([]);
    const [sortSet, setSortSet] = React.useState([]);
    const [orderCat, setOrderCat] = React.useState("id__DESC");
    const [orderKPI, setOrderKPI] = React.useState("id__DESC");
    const [orderKPIGA, setOrderKPIGA] = React.useState("id__DESC");
    const [orderSet, setOrderSet] = React.useState("id__DESC");
    const [filter, setFilter] = React.useState('Statüye Göre');
    const statusOptions = ['Read','Unread']
    const [status, setStatus] = React.useState([]);
    const filterOptions = ['Statüye Göre'];

    const options = [
      {name:'A-Z', value:'title__ASC'},
      {name:'Z-A', value:'title__DESC'},
      {name:'Latest', value:'id__DESC'},
      {name:'Oldest', value:'id__ASC'},
    ];

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleSortCat = event => {
      setSortCat(event.target.value);
      setPageCat(1);
    };

    const handleSortSet = event => {
      setSortSet(event.target.value);
      setPageSet(1);
    };

    const handleSortKPI = event => {
      setSortKPI(event.target.value);
      setPageKPI(1);
    };

    const handleSortKPIGA = event => {
      setSortKPIGA(event.target.value);
      setPageKPIGA(1);
    };
  
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

    const GET_CATEGORY = gql`
    query adminKPICategoryList($page: Int, $order:String, $pageSize: Int, $search: String) {
      adminKPICategoryList(pagination:{page:$page, pageSize:$pageSize}, search:$search ,orderBy:[$order] ){
        count
        pageCount
        data{
          id
          title
          order
          kpiCount
          lists{
            id
            title
          }
        }
      }
    }
    `;
    const { loading:loadingCat , data:dataCat , refetch:refetchCat } = useQuery(GET_CATEGORY, {
        fetchPolicy:"network-only",
        variables: {page:pageCat , order:orderCat, pageSize:pageSizeCat, search:searchCat},
    });

    const GET_SUGGEST = gql`
    query adminKPISuggestList($page: Int, $pageSize: Int,$status:[String], $search: String) {
      adminKPISuggestList(pagination:{page:$page, pageSize:$pageSize}, filter : { status : $status}, search:$search ){
        count
        pageCount
        data{
          id
          createdAt
          status
          text
          user{
            id
            firstName
            lastName
            profilePhoto
          }
        }
      }
    }
    `;
    const { loading:loadingSug , data:dataSug  , refetch:refetchSug  } = useQuery(GET_SUGGEST, {
        fetchPolicy:"network-only",
        variables: {page:pageSug , pageSize:pageSizeSug, status:status, search:searchSug},
    });

    const GET_KPI = gql`
    query adminKPIList($page: Int, $pageSize: Int, $order:String, $search: String) {
      adminKPIList(pagination:{page:$page, pageSize:$pageSize}, search:$search, orderBy:[$order] ){
        count
        pageCount
        data{
          id
          title
          description
          order
          category{
            id
            title
          }
          dataFrequency
          dataFormat
        }
      }
    }
    `;
    const { loading:loadingKPI , data:dataKPI , refetch:refetchKPI } = useQuery(GET_KPI, {
        fetchPolicy:"network-only",
        variables: {page:pageKPI, pageSize:pageSizeKPI, order:orderKPI, search:searchKPI},
    });

    const GET_KPI_GA = gql`
    query adminKPIGAList($page: Int, $pageSize: Int, $order:String, $search: String) {
      adminKPIGAList(pagination:{page:$page, pageSize:$pageSize}, search:$search, orderBy:[$order] ){
        count
        pageCount
        data{
          id
          title
          description
          order
          category{
            id
            title
          }
          dataFrequency
          dataFormat
          metric
          dimension
        }
      }
    }
    `;
    const { loading:loadingKPIGA , data:dataKPIGA , refetch:refetchKPIGA } = useQuery(GET_KPI_GA, {
        fetchPolicy:"network-only",
        variables: {page:pageKPIGA, pageSize:pageSizeKPIGA, order:orderKPIGA, search:searchKPIGA},
    });

    const GET_SET = gql`
    query adminKPISetList($page: Int, $order:String, $pageSize: Int, $search: String) {
      adminKPISetList(pagination:{page:$page, pageSize:$pageSize}, search:$search ,orderBy:[$order] ){
        count
        pageCount
        data{
          id
          title
          order
          description
          subtitle
          kpiCount
          kpis{
            id
            title
          }
        }
      }
    }
    `;
    const { loading:loadingSet , data:dataSet , refetch:refetchSet } = useQuery(GET_SET, {
        fetchPolicy:"network-only",
        variables: {page:pageSet , order:orderSet, pageSize:pageSizeSet, search:searchSet},
    });

    React.useEffect(() => {
      if(!loadingCat){
        if(dataCat){
          setPageCountCat(dataCat.adminKPICategoryList.pageCount)
          setCats(dataCat.adminKPICategoryList.data);
        }
      }
      if(!loadingKPI){
        if(dataKPI){
          setKPIs(dataKPI.adminKPIList.data);
          setPageCountKPI(dataKPI.adminKPIList.pageCount)
          setCountKPI(dataKPI.adminKPIList.count);
        }
      }
      if(!loadingKPIGA){
        if(dataKPIGA){
          setKPIsGA(dataKPIGA.adminKPIGAList.data);
          setPageCountKPIGA(dataKPIGA.adminKPIGAList.pageCount)
        }
      }
      if(!loadingSet){
        if(dataSet){
          setSets(dataSet.adminKPISetList.data);
          setPageCountSet(dataSet.adminKPISetList.pageCount)
        }
      }
      if(!loadingSug){
        if(dataSug){
          setSugs(dataSug.adminKPISuggestList.data);
          setPageCountSug(dataSug.adminKPISuggestList.pageCount)
        }
      }
    },
    [dataCat, loadingCat, loadingKPI, dataKPI, loadingSet, dataSet, loadingSug, dataSug, loadingKPIGA, dataKPIGA])

  const handlePageChangeCat = (event, value) => {
    window.scrollTo(0,0);
    setPageCat(value);
  };

  const handleSearchCat = event => {
    setSearchCat(event.target.value);
    setPageCat(1);
  };

  const handlePageChangeSug = (event, value) => {
    window.scrollTo(0,0);
    setPageSug(value);
  };

  const handleSearchSug = event => {
    setSearchSug(event.target.value);
    setPageSug(1);
  };

  const handleFilter = (event) => {
    setPageSug(1);
    setFilter(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePageChangeKPI = (event, value) => {
    window.scrollTo(0,0);
    setPageKPI(value);
  };

  const handlePageChangeKPIGA = (event, value) => {
    window.scrollTo(0,0);
    setPageKPIGA(value);
  };

  const handleSearchKPI = event => {
    setSearchKPI(event.target.value);
    setPageKPI(1);
  };

  const handleSearchKPIGA = event => {
    setSearchKPIGA(event.target.value);
    setPageKPIGA(1);
  };

  const handleStatus = (event) => {
    setPageSug(1);
    if (status.includes(event.target.value)){
      let a = status.filter(e => e !== event.target.value);
      setStatus(a);
     }
     else{
      setStatus([...status, event.target.value]);
     }
  };

  const handlePageChangeSet = (event, value) => {
    window.scrollTo(0,0);
    setPageSet(value);
  };

  const handleSearchSet= event => {
    setSearchSet(event.target.value);
    setPageSet(1);
  };

    return (
      <>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: get(props, 'HeaderLeftDrawer.HeaderLeftDrawer', true),
          })}
        >
          <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
            <Grid
              className={'main-container'}
              xs={12}
              style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}
            >
              <Grid item style={{ width: '100%' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Grid item style={{ display:'flex' }}>
                      <TrendingUpIcon style={{ fontSize: 20 }} />
                    </Grid>
                    <Typography variant="h4">KPI</Typography>
                  </Breadcrumbs>
                  <Grid item className={classes.filterArea}>
                    {value === 1 && (
                      <Hidden smDown>
                        <DebounceInput
                          class="inputTypeSearch"
                          name="search"
                          value={searchCat}
                          placeholder="Ara..."
                          className={classes.Debounce}
                          minLength={2}
                          debounceTimeout={800}
                          onChange={handleSearchCat}
                        />
                      </Hidden>
                    )}
                    {value === 1 && (
                      <Hidden smDown>
                        <FormControl
                          placeholder="Lütfen Seçiniz"
                          style={{ width: '100%', maxWidth: '116px', marginRight: 20 }}
                        >
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            displayEmpty
                            value={sortCat}
                            className={classes.multiSelectOrder}
                            onChange={handleSortCat}
                            IconComponent={(props) => (
                              <ExpandMoreIcon
                                {...props}
                                style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                              />
                            )}
                            input={<Input />}
                            /*   MenuProps={MenuProps} */
                          >
                            <MenuItem value="" disabled>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                Sırala
                              </Typography>
                            </MenuItem>
                            {options.map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.value}
                                onClick={() => setOrderCat(option.value)}
                              >
                                <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                  {option.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Hidden>
                    )}
                    {value === 1 && (
                      <Button
                        className={classes.ButtonMobile}
                        style={{
                          fontWeight: 'normal',
                          padding: '11.5px 15px',
                          fontSize: 14,
                          width: '100%',
                          minWidth: 185,
                        }}
                        onClick={() => setAddCat(true)}
                        variant="containedPrimary"
                      >
                        <AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} /> Yeni Kategori
                        Ekle{' '}
                      </Button>
                    )}
                    {value === 2 && (
                      <Hidden smDown>
                        <DebounceInput
                          class="inputTypeSearch"
                          name="search"
                          value={searchKPI}
                          placeholder="Ara..."
                          className={classes.Debounce}
                          minLength={2}
                          debounceTimeout={800}
                          onChange={handleSearchKPI}
                        />
                      </Hidden>
                    )}
                    {value === 2 && (
                      <Hidden smDown>
                        <FormControl
                          placeholder="Lütfen Seçiniz"
                          style={{ width: '100%', maxWidth: '116px', marginRight: 20 }}
                        >
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            displayEmpty
                            value={sortKPI}
                            className={classes.multiSelectOrder}
                            onChange={handleSortKPI}
                            IconComponent={(props) => (
                              <ExpandMoreIcon
                                {...props}
                                style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                              />
                            )}
                            input={<Input />}
                            /*   MenuProps={MenuProps} */
                          >
                            <MenuItem value="" disabled>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                Sırala
                              </Typography>
                            </MenuItem>
                            {options.map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.value}
                                onClick={() => setOrderKPI(option.value)}
                              >
                                <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                  {option.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Hidden>
                    )}
                    {value === 2 && (
                      <Button
                        className={classes.ButtonMobile}
                        style={{
                          fontWeight: 'normal',
                          padding: '11.5px 15px',
                          fontSize: 14,
                          width: '100%',
                          minWidth: 185,
                        }}
                        onClick={() => setAddKPI(true)}
                        variant="containedPrimary"
                      >
                        <AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} /> Yeni KPI Ekle{' '}
                      </Button>
                    )}
                    {value === 2 && (
                      <Hidden smDown>
                        <DebounceInput
                          class="inputTypeSearch"
                          name="search"
                          value={searchKPIGA}
                          placeholder="Ara..."
                          className={classes.Debounce}
                          minLength={2}
                          debounceTimeout={800}
                          onChange={handleSearchKPIGA}
                        />
                      </Hidden>
                    )}
                    {value === 4 && (
                      <Hidden smDown>
                        <FormControl
                          placeholder="Lütfen Seçiniz"
                          style={{ width: '100%', maxWidth: '116px', marginRight: 20 }}
                        >
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            displayEmpty
                            value={sortKPIGA}
                            className={classes.multiSelectOrder}
                            onChange={handleSortKPIGA}
                            IconComponent={(props) => (
                              <ExpandMoreIcon
                                {...props}
                                style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                              />
                            )}
                            input={<Input />}
                            /*   MenuProps={MenuProps} */
                          >
                            <MenuItem value="" disabled>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                Sırala
                              </Typography>
                            </MenuItem>
                            {options.map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.value}
                                onClick={() => setOrderKPIGA(option.value)}
                              >
                                <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                  {option.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Hidden>
                    )}
                    {value === 4 && (
                      <Button
                        className={classes.ButtonMobile}
                        style={{
                          fontWeight: 'normal',
                          padding: '11.5px 15px',
                          fontSize: 14,
                          width: '100%',
                          minWidth: 185,
                        }}
                        onClick={() => setAddKPIGA(true)}
                        variant="containedPrimary"
                      >
                        <AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} /> Yeni GA KPI Ekle{' '}
                      </Button>
                    )}
                    {value === 0 && (
                      <Hidden smDown>
                        <DebounceInput
                          class="inputTypeSearch"
                          name="search"
                          value={searchSet}
                          placeholder="Ara..."
                          className={classes.Debounce}
                          minLength={2}
                          debounceTimeout={800}
                          onChange={handleSearchSet}
                        />
                      </Hidden>
                    )}
                    {value === 0 && (
                      <Hidden smDown>
                        <FormControl
                          placeholder="Lütfen Seçiniz"
                          style={{ width: '100%', maxWidth: '116px', marginRight: 20 }}
                        >
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            displayEmpty
                            value={sortSet}
                            className={classes.multiSelectOrder}
                            onChange={handleSortSet}
                            IconComponent={(props) => (
                              <ExpandMoreIcon
                                {...props}
                                style={{ color: '#8898aa', marginLeft: 15, cursor: 'pointer', position: 'absolute' }}
                              />
                            )}
                            input={<Input />}
                            /*   MenuProps={MenuProps} */
                          >
                            <MenuItem value="" disabled>
                              <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                Sırala
                              </Typography>
                            </MenuItem>
                            {options.map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.value}
                                onClick={() => setOrderSet(option.value)}
                              >
                                <Typography style={{ fontSize: 12, marginLeft: 8 }} variant="h6">
                                  {option.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Hidden>
                    )}
                    {value === 0 && (
                      <Button
                        className={classes.ButtonMobile}
                        style={{
                          fontWeight: 'normal',
                          padding: '11.5px 15px',
                          fontSize: 14,
                          width: '100%',
                          minWidth: 185,
                        }}
                        onClick={() => setAddSet(true)}
                        variant="containedPrimary"
                      >
                        <AddIcon style={{ color: '#fff', fontSize: 15, marginRight: 5, maxHeight: 40 }} /> Yeni Set Ekle{' '}
                      </Button>
                    )}
                    {value === 3 && (
                      <Hidden smDown>
                        <Grid className={classes.filter} onClick={handleClick} item>
                          <Typography style={{ fontSize: 16, color: '#8898aa' }} variant="body1">
                            Filtrele
                          </Typography>
                          <ExpandMoreIcon style={{ color: '#8898aa', marginLeft: 15 }} />
                        </Grid>
                        <Popover
                          id={id}
                          open={openPop}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          classes={{
                            paper: classes.myPaper,
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Grid container xs={12}>
                            <Grid item xs={4} style={{ borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                              <Typography variant="h5">Filtrele</Typography>
                              <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup onChange={handleFilter} value={filter}>
                                  {filterOptions.map((value, i) => {
                                    // Return the element. Also pass key
                                    return (
                                      <FormControlLabel
                                        classes={{ label: classes.myLabel }}
                                        control={<Radio color="primary" value={value} />}
                                        label={value}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4} style={{ paddingLeft: '30px', borderRight: '1px solid rgb(0 0 0 / 5%)' }}>
                              <Typography variant="h5">Filtre Seç</Typography>
                              {filter === 'Statüye Göre' && (
                                <FormControl component="fieldset" className={classes.formControl}>
                                  <FormGroup onChange={handleStatus} value={status}>
                                    {statusOptions.map((value, i) => {
                                      return (
                                        <FormControlLabel
                                          className="dropdown"
                                          control={
                                            <Checkbox
                                              classes={{ root: classes.myCheckbox }}
                                              color="primary"
                                              onChange={handleStatus}
                                              checked={status.includes(value)}
                                              name="filter4-1"
                                              value={value}
                                            />
                                          }
                                          label={value}
                                        />
                                      );
                                    })}
                                  </FormGroup>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                              <Typography style={{ marginBottom: 5 }} variant="h5">
                                Seçimleriniz
                              </Typography>
                              {status && (
                                <>
                                  {status.map((value, i) => {
                                    return (
                                      <Typography style={{ fontWeight: 'normal', marginBottom: 15 }} variant="h6">
                                        {value}
                                      </Typography>
                                    );
                                  })}
                                </>
                              )}
                            </Grid>
                            <Grid container justify="flex-end"></Grid>
                          </Grid>
                        </Popover>
                      </Hidden>
                    )}
                    <AddCategoryDialog
                      adminKPICategoryCreate={props.adminKPICategoryCreate}
                      refetch={refetchCat}
                      open={addCat}
                      onClose={() => setAddCat(false)}
                    />
                    <AddKPIDialog
                      adminKPICreate={props.adminKPICreate}
                      refetch={refetchKPI}
                      open={addKPI}
                      onClose={() => setAddKPI(false)}
                    />
                    <AddKPIGADialog
                      adminKPIGACreate={props.adminKPIGACreate}
                      refetchGA={refetchKPIGA}
                      open={addKPIGA}
                      onClose={() => setAddKPIGA(false)}
                    />
                    <AddSetDialog
                      adminKPISetCreate={props.adminKPISetCreate}
                      refetch={refetchSet}
                      open={addSet}
                      onClose={() => setAddSet(false)}
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  <AppBar style={{ zIndex: 0 }} className={classes.tabPaper} position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      className={classes.TabsGeneral}
                    >
                      <Tab label="Setler" {...a11yProps(0)} style={{fontWeight:600}} />
                      <Tab label="Kategoriler" {...a11yProps(1)} style={{fontWeight:600}} />
                      <Tab label="KPI'lar" {...a11yProps(2)} style={{fontWeight:600}} />
                      <Tab label="Öneriler" {...a11yProps(3)} style={{fontWeight:600}} />
                      <Tab label="Google Analytics" {...a11yProps(3)} style={{fontWeight:600}} />
                    </Tabs>
                  </AppBar>
                  <TabPanel style={{ width: '100%' }} value={value} index={1}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Kategori Adı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Kategorideki KPI Sayısı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Kategori Sıralaması{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Aksiyonlar{' '}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {cats && (
                            <TableBody>
                              {cats.map((cat) => (
                                <ExpandingRowCategory
                                  refetchCat={refetchCat}
                                  countKPI={countKPI}
                                  adminKPICategoryDelete={props.adminKPICategoryDelete}
                                  adminKPICategoryEdit={props.adminKPICategoryEdit}
                                  adminKPICategoryAddKPI={props.adminKPICategoryAddKPI}
                                  cat={cat}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {cats && (
                        <>
                          {cats.length > 0 ? (
                            <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountCat}
                                page={pageCat}
                                onChange={handlePageChangeCat}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          ) : (
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                Kategori bulunamadı...
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={2}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  KPI Adı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Açıklama{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Kategori{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Sıklık{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Format{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Aksiyonlar{' '}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {kpis && (
                            <TableBody>
                              {kpis.map((kpi) => (
                                <ExpandingRowKPI
                                  refetchKPI={refetchKPI}
                                  adminKPIEdit={props.adminKPIEdit}
                                  adminKPIDelete={props.adminKPIDelete}
                                  adminKPICategoryEdit={props.adminKPICategoryEdit}
                                  kpi={kpi}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {kpis && (
                        <>
                          {kpis.length > 0 ? (
                            <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountKPI}
                                page={pageKPI}
                                onChange={handlePageChangeKPI}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          ) : (
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                KPI bulunamadı...
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={0}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Set Adı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Setteki KPI Sayısı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Set Sıralaması{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Aksiyonlar{' '}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {sets && (
                            <TableBody>
                              {sets.map((set) => (
                                <ExpandingRowSet
                                  refetchSet={refetchSet}
                                  countKPI={countKPI}
                                  adminKPISetDelete={props.adminKPISetDelete}
                                  adminKPISetEdit={props.adminKPISetEdit}
                                  adminKPISetAddKPI={props.adminKPISetAddKPI}
                                  set={set}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {sets && (
                        <>
                          {sets.length > 0 ? (
                            <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountSet}
                                page={pageSet}
                                onChange={handlePageChangeSet}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          ) : (
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                Set bulunamadı...
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={3}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Gönderen{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Öneri{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Tarih{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Durum{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Aksiyonlar{' '}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {sugs && (
                            <TableBody>
                              {sugs.map((sug) => (
                                <ExpandingRowSug
                                  refetch={refetchSug}
                                  adminKPISuggestHandle={props.adminKPISuggestHandle}
                                  sug={sug}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {sugs && (
                        <>
                          {sugs.length > 0 ? (
                            <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountSug}
                                page={pageSug}
                                onChange={handlePageChangeSug}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          ) : (
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                Öneri bulunamadı...
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel style={{ width: '100%' }} value={value} index={4}>
                    <Grid container xs={12} className={classes.whiteCard}>
                      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  KPI Adı{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Açıklama{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Kategori{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Sıklık{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Format{' '}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography variant="h6" color="primary">
                                  {' '}
                                  Aksiyonlar{' '}
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {kpisGA && (
                            <TableBody>
                              {kpisGA.map((kpiGA) => (
                                <ExpandingRowKPIGA
                                  refetchKPIGA={refetchKPIGA}
                                  adminKPIGAEdit={props.adminKPIGAEdit}
                                  adminKPIGADelete={props.adminKPIGADelete}
                                  adminKPICategoryEdit={props.adminKPICategoryEdit}
                                  kpiGA={kpiGA}
                                />
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {kpisGA && (
                        <>
                          {kpisGA.length > 0 ? (
                            <Grid container item style={{ marginTop: '30px', padding: 0 }} justify="flex-end">
                              <Pagination
                                count={pageCountKPIGA}
                                page={pageKPIGA}
                                onChange={handlePageChangeKPIGA}
                                variant="outlined"
                                color="primary"
                              />
                            </Grid>
                          ) : (
                            <Grid container justify="center">
                              <Typography style={{ margin: '25px 0' }} variant="h5">
                                KPI bulunamadı...
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </>
    );
}