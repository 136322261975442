import {useQuery} from "@apollo/react-hooks";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Popover from "@material-ui/core/Popover";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import gql from "graphql-tag";
import get from "lodash/get";
import React from "react";
import {DebounceInput} from "react-debounce-input";
import {useTranslation} from "react-i18next";
import InvestorCard from "../../Components/InvestorCard";
import filterIcon from "../../static/icon/filterIcon.svg";
import newBag from "../../static/icon/new-bag.svg";
import newSortingIcon from "../../static/icon/newSortingIcon.svg";
import "./scroll.css";


const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  root: {
    display: "flex"
  },
  cardGrid: {
    margin: 0,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: 35,
    },
  },
  Debounce: {
    width: "100%",
    maxWidth: "180px",
    fontSize: 16,
    height: "35px",
    color: "#8898aa",
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    border: "none",
    outline: "none",
    borderBottom: "1px solid #c8c8ca",
    background: "inherit",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      height: "40px"
    }
  },
  multiSelect: {
    maxHeight: 40,
    minWidth: 116,
    background: '#FFFFFF',
    boxShadow: '0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)',
    borderRadius: '6px',
    borderColor: '#fff',
    '& div': {
      background: 'none!important',
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
      color: '#8898aa',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '1',
    },
  },
  filterArea: {
    display: "flex",
    alignItems: "flex-end"
  },
  filterPadding: {
    paddingLeft: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      marginTop: 10,
    },
  },
  headingPadding: {
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "0"
    }
  },
  filter: {
    height: 40,
    padding: "0 15px",
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2)",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center"
  },
  CheckText: {
    wordBreak: "break-all",
    marginBottom: 10,
    "& span": {
      fontSize: 15,
      lineHeight: 1.2,
    },
  },
  myPaper: {
    padding: 25,
    width: '650px',
  },
  myPaper2: {
    padding: 25
  },
  myLabel: {
    fontSize: 14,
  },
  formControl: {
    marginTop: 10,
  },
  myCheckbox: {
    padding: '5px 9px',
  },
}));

export default function DiscoverStartups(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(6);
  const [investorsCount, setInvestorsCount] = React.useState(0);
  const [edit, setEdit] = React.useState(false);
  const [sort, setSort] = React.useState([]);
  const [investors, setInvestors] = React.useState();
  const [order, setOrder] = React.useState('user__firstName__ASC');
  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState('Girişim Aşaması');
  const [stage, setStage] = React.useState([]);
  const [sector, setSector] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const handleClickSort = event => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const openPopSort = Boolean(anchorElSort);
  const idSort = openPopSort ? "simple-popover" : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;

  const ALL_INVESTORS = gql`
    query allInvestorsPaginate(
      $orderBy: String
      $search: String
      $page: Int
      $pageSize: Int
      $investmentType: [String]
      $interestedStage: [String]
      $interestedIndustries: [String]
    ) {
      allInvestorsPaginate(
        orderBy: [$orderBy]
        search: $search
        pagination: { page: $page, pageSize: $pageSize }
        filter: {
          investmentType: $investmentType
          interestedStage: $interestedStage
          interestedIndustries: $interestedIndustries
        }
      ) {
        count
        pageCount
        data {
          id
          slug
          firstName
          lastName
          userId
          companyRole
          website
          socialLinkedIn
          socialTwitter
          interestedIndustries
          interestedStage
          investmentType
          biography
          profilePhoto
        }
      }
    }
  `;
  const { loading, data, refetch } = useQuery(ALL_INVESTORS, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: order,
      search: search,
      page: page,
      pageSize: pageSize,
      investmentType: type,
      interestedStage: stage,
      interestedIndustries: sector,
    },
  });
  const options = [
    {name: "A-Z", value: "user__firstName__ASC"},
    {name: "Z-A", value: "user__firstName__DESC"},
    {name: "Eskiden Yeniye", value: "id__ASC"},
    {name: "Yeniden Eskiye", value: "id__DESC"}
  ];
  const filterOptions = ["İlgilenilen Aşama", "İlgilenilen Sektörler", "Yatırım Türü"];

  const typeOptions = [
    'Melek Yatırımcı',
    'Venture Capital',
    'Private Equity',
    'Hızlandırma Programı',
    'Yatırım Ortaklığı',
    'Micro VC',
    'Melek Yatırım Ağı',
    'Kuluçka Merkezi',
    'Yatırım Bankası',
    'Aile Yatırım Şirketi',
    'Borç Finansmanı',
    'Paylaşımlı Ofis',
    'Fon Sepeti Fonu',
    'Risk Fonu',
    'Devlet Kurumu',
    'Üniversite Programı',
    'Girişimcilik Programı',
    'İkincil Pazar Yatırımcısı',
    'Sendikasyon Yatırımı',
  ];

  const stageOptions = ["Fikir", "MVP", "Erken Aşama", "Büyüme"];

  const sectorOptions = [
    "AI / Yapay Zeka",
    "Akıllı Şehirler",
    "AR & VR / Artırılmış Gerçeklik & Sanal Gerçeklik",
    "Atık Yönetimi",
    "Bilişim Teknolojileri",
    "Biyoteknoloji",
    "Blokzinciri",
    "Data Analiz & İş zekası",
    "Donanım",
    "e-ticaret",
    "Eğitim & Eğitim Teknolojileri",
    "Eğlence & Müzik",
    "Etkinlik",
    "Ev hizmetleri ve teknolojileri ",
    "Finans & Bankacılık",
    "Finans Teknolojileri",
    "Gayrimenkul",
    "Geri Dönüşüm",
    "Giyilebilir Teknolojiler",
    "Hukuk Teknolojileri",
    "İnsan Kaynakları & İşe Alım",
    "Kriptopara",
    "Kurumsal Hizmetler",
    "Mikro Mobilite",
    "Moda & Güzellik",
    "Müşteri İlişkileri Yönetimi",
    "Nesnelerin İnterneti",
    "Oyun",
    "Perakende",
    "Regülasyon Teknolojileri",
    "Reklam & Pazarlama",
    "Robotik Teknolojileri",
    "SaaS",
    "Sağlık Hizmetleri & Sağlık Teknolojileri",
    "Seyahat & Turizm",
    "Siber Güvenlik",
    "Sigorta & Sigorta Teknolojileri",
    "Sosyal Girişimcilik",
    "Sosyal Medya",
    "Spor & Fitness",
    "Tarım & Tarım Teknolojileri",
    "Teknoloji",
    "Telekomünikasyon",
    "Temiz Teknoloji",
    "Teslimat & Lojistik",
    "Topluluk Yönetimi",
    "Tüketici Hizmetleri",
    "Ulaşım",
    "Video & Animasyon",
    "Yazılım",
    "Yenilenebilir Enerji",
    "Yiyecek & İçecek"
  ];

  React.useEffect(() => {
    if (!loading) {
      if (data) {
        setInvestors(data.allInvestorsPaginate.data);
        setPageCount(data.allInvestorsPaginate.pageCount);
        setInvestorsCount(data.allInvestorsPaginate.count);
      }
    }
  }, [data, loading]);

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setEdit(true);
    setPage(value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleSort = (event) => {
    setSort(event.target.value);
    setPage(1);
  };

  const handleFilter = event => {
    setPage(1);
    setFilter(event.target.value);
  };

  const handleStage = event => {
    setPage(1);
    if (stage.includes(event.target.value)) {
      let a = stage.filter((e) => e !== event.target.value);
      setStage(a);
    } else {
      setStage([...stage, event.target.value]);
    }
  };

  const handleSector = event => {
    setPage(1);
    if (sector.includes(event.target.value)) {
      let a = sector.filter((e) => e !== event.target.value);
      setSector(a);
    } else {
      setSector([...sector, event.target.value]);
    }
  };

  const handleType = event => {
    setPage(1);
    if (type.includes(event.target.value)) {
      let a = type.filter((e) => e !== event.target.value);
      setType(a);
    } else {
      setType([...type, event.target.value]);
    }
  };

  const localeSortByFirstnameIfOrderIsRelevant = (investors) => {
    const splittedOrder = order.split('__'); // ["user", "asc/desc"]

    if (splittedOrder[0] !== 'user') return investors;

    const mutatedInvestors = [...investors];

    //only sorts if order starts with user
    if (splittedOrder[2] === 'ASC')
      return mutatedInvestors.sort((a, b) => a.firstName.localeCompare(b.firstName, 'tr'));
    else return mutatedInvestors.sort((a, b) => -a.firstName.localeCompare(b.firstName, 'tr'));
  };

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: get(props, "HeaderLeftDrawer.HeaderLeftDrawer", true)
        })}
      >
        <Grid container style={{ background: '#f7f7fc', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid className={'main-container'} xs={12} style={{ marginTop: 140, height: 'fit-content', maxWidth: 1320 }}>
            <Grid item style={{ width: '100%' }}>
              <Grid container justify="space-between" alignItems="center" className={classes.headingPadding}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  <img src={newBag} style={{width: 18, marginTop: 3}} alt="bag" />
                  <Typography variant="h4">Yatırımcılar</Typography>
                </Breadcrumbs>
                <Grid item className={classes.filterArea}>
                  <Hidden smDown>
                    <DebounceInput
                      class="inputTypeSearch"
                      name="search"
                      value={search}
                      placeholder={t("Ara")}
                      className={classes.Debounce}
                      minLength={2}
                      debounceTimeout={800}
                      onChange={handleSearch}
                    />
                  </Hidden>
                  <Grid className={classes.filter} onClick={handleClick} item>
                    <img src={filterIcon} style={{width: 12}} alt="filter" />
                    <Hidden smDown>
                      <Typography style={{fontSize: 12, marginLeft: 8}} variant="h6">
                        {t("Filtrele")}
                      </Typography>
                      <ExpandMoreIcon style={{color: "#8898aa", marginLeft: 10, fontSize: 20}} />
                    </Hidden>
                  </Grid>
                  <Popover
                    id={id}
                    open={openPop}
                    anchorEl={anchorEl}
                    style={{marginTop: 11}}
                    onClose={handleClose}
                    classes={{
                      paper: classes.myPaper
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Grid container xs={12}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{borderRight: "1px solid rgb(0 0 0 / 5%)", maxHeight: 250, overflowY: "auto"}}
                      >
                        <Typography variant="h5">{t("Filtreler")}</Typography>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup onChange={handleFilter} value={filter}>
                            {filterOptions.map((value, i) => {
                              // Return the element. Also pass key
                              return (
                                <FormControlLabel
                                  classes={{label: classes.myLabel}}
                                  control={<Radio color="primary" value={value} />}
                                  label={value}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.filterPadding}
                        style={{
                          borderRight: "1px solid rgb(0 0 0 / 5%)",
                          maxHeight: 250,
                          overflowY: "auto"
                        }}
                      >
                        <Typography variant="h5">{t("Filtre Seç")}</Typography>
                        {filter === "İlgilenilen Aşama" && (
                          <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup onChange={handleStage} value={stage}>
                              {stageOptions.map((value, i) => {
                                return (
                                  <FormControlLabel
                                    className={classes.CheckText}
                                    control={
                                      <Checkbox
                                        classes={{root: classes.myCheckbox}}
                                        color="primary"
                                        onChange={handleStage}
                                        checked={stage.includes(value)}
                                        name="filter4-1"
                                        value={value}
                                      />
                                    }
                                    label={value}
                                  />
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        )}
                        {filter === "İlgilenilen Sektörler" && (
                          <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup onChange={handleSector} value={sector}>
                              {sectorOptions.map((value, i) => {
                                return (
                                  <FormControlLabel
                                    className={classes.CheckText}
                                    control={
                                      <Checkbox
                                        classes={{root: classes.myCheckbox}}
                                        color="primary"
                                        onChange={handleSector}
                                        checked={sector.includes(value)}
                                        name="filter4-1"
                                        value={value}
                                      />
                                    }
                                    label={value}
                                  />
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        )}
                        {filter === "Yatırım Türü" && (
                          <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup onChange={handleType} value={type}>
                              {typeOptions.map((value, i) => {
                                return (
                                  <FormControlLabel
                                    className={classes.CheckText}
                                    control={
                                      <Checkbox
                                        classes={{root: classes.myCheckbox}}
                                        color="primary"
                                        onChange={handleType}
                                        checked={type.includes(value)}
                                        name="filter4-1"
                                        value={value}
                                      />
                                    }
                                    label={value}
                                  />
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.filterPadding}
                        style={{maxHeight: 250, overflowY: "auto"}}
                      >
                        <Typography style={{marginBottom: 18}} variant="h5">
                          {t("Filtre Seçimlerim")}
                        </Typography>
                        {stage && (
                          <>
                            {stage.map((value, i) => {
                              return (
                                <Typography style={{fontWeight: "normal"}} variant="h6">
                                  <FormControlLabel
                                    className={classes.CheckText}
                                    control={
                                      <Checkbox
                                        classes={{root: classes.myCheckbox}}
                                        color="primary"
                                        onChange={handleStage}
                                        checked={stage.includes(value)}
                                        name="filter4-1"
                                        value={value}
                                      />
                                    }
                                    label={value}
                                  />
                                </Typography>
                              );
                            })}
                          </>
                        )}
                        {sector && (
                          <>
                            {sector.map((value, i) => {
                              return (
                                <Typography style={{fontWeight: "normal"}} variant="h6">
                                  <FormControlLabel
                                    className={classes.CheckText}
                                    control={
                                      <Checkbox
                                        classes={{root: classes.myCheckbox}}
                                        color="primary"
                                        onChange={handleSector}
                                        checked={sector.includes(value)}
                                        name="filter4-1"
                                        value={value}
                                      />
                                    }
                                    label={value}
                                  />
                                </Typography>
                              );
                            })}
                          </>
                        )}
                        {type && (
                          <>
                            {type.map((value, i) => {
                              return (
                                <Typography style={{fontWeight: "normal"}} variant="h6">
                                  <FormControlLabel
                                    className={classes.CheckText}
                                    control={
                                      <Checkbox
                                        classes={{root: classes.myCheckbox}}
                                        color="primary"
                                        onChange={handleType}
                                        checked={type.includes(value)}
                                        name="filter4-1"
                                        value={value}
                                      />
                                    }
                                    label={value}
                                  />
                                </Typography>
                              );
                            })}
                          </>
                        )}
                      </Grid>
                      <Grid container justify="flex-end"></Grid>
                    </Grid>
                  </Popover>
                  <Grid className={classes.filter} onClick={handleClickSort} item style={{marginLeft: 15}}>
                    <img src={newSortingIcon} style={{width: 15}} alt="filter" />
                    <Hidden smDown>
                      <Typography style={{fontSize: 12, marginLeft: 8}} variant="h6">
                        {t("Sırala")}
                      </Typography>
                      <ExpandMoreIcon style={{color: "#8898aa", marginLeft: 10, fontSize: 20}} />
                    </Hidden>
                  </Grid>
                  <Popover
                    id={idSort}
                    open={openPopSort}
                    anchorEl={anchorElSort}
                    style={{marginTop: 11}}
                    onClose={handleCloseSort}
                    classes={{
                      paper: classes.myPaper2
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{borderRight: "1px solid rgb(0 0 0 / 5%)", maxHeight: 250, overflowY: "auto"}}
                    >
                      <Typography variant="h5">{t("Sırala")}</Typography>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup onChange={handleSort} value={sort}>
                          {options.map((option, i) => {
                            // Return the element. Also pass key
                            return (
                              <FormControlLabel
                                classes={{label: classes.myLabel}}
                                control={
                                  <Radio color="primary" value={option.value} onClick={() => setOrder(option.value)} />
                                }
                                label={option.name}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Popover>
                </Grid>
              </Grid>
              {investors && (
                <Grid className={classes.cardGrid} container spacing={5} xs={12}>
                  {localeSortByFirstnameIfOrderIsRelevant(investors).map(investor => (
                    <InvestorCard
                      key={investor.id}
                      userProfileId={props.userProfileId}
                      userId = {props.userId}
                      refetch={refetch}
                      edit={edit}
                      setEdit={setEdit}
                      investor={investor}
                    />
                  ))}
                  {investors.length > 0 ? (
                    <Grid container item style={{margin: "30px 0", padding: 0}} justify="center">
                      <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                      />
                    </Grid>
                  ) : (
                    <Grid container justify="center">
                      <Typography style={{ margin: '25px 0' }} variant="h5">
                        {t('Yatırımcı bulunamadı...')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
