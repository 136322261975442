import { graphql } from 'react-apollo';
import {HEADER_LEFT_DRAWER} from '../../queries'
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import ReportRequests from './ReportRequests';

const EDIT_REQUESTS = gql`
  mutation startupHandleStartupFollow($ids: [ID!], $status: String!) {
    startupHandleStartupFollow(ids: $ids, status: $status) 
  }
`;

const JoinWithMutation = compose(
  graphql(HEADER_LEFT_DRAWER, {
    name: 'HeaderLeftDrawer',
  }),
  graphql(EDIT_REQUESTS, {
    name: 'startupHandleStartupFollow',
  }),
)(ReportRequests);
export default JoinWithMutation;